import React from 'react';
import { ISVGProps } from './ISVGProps';

export const DownloadSvg = (props: ISVGProps) => {
  const { width, height } = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 17 17.5'
    >
      <defs>
        <style>
          {
            '.a{fill:none;stroke:#212529;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}'
          }
        </style>
      </defs>
      <g transform='translate(-2 -1.5)'>
        <path
          className='a'
          d='M18,15v4a1.856,1.856,0,0,1-1.667,2H4.667A1.856,1.856,0,0,1,3,19V15'
          transform='translate(0 -3)'
        />
        <path
          className='a'
          d='M7,10l3.5,5L14,10'
          transform='translate(0 -1.615)'
        />
        <line className='a' y1='8.5' transform='translate(10.5 2.5)' />
      </g>
    </svg>
  );
};

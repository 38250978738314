import { IDataMapping } from '../../IDataMapping';

export class CommonBarChartDataModel implements IDataMapping<CommonBarChartDataModel> {
    public Label: string = '';
    public Name: string = '';
    public Color: string = '';
    public Value: number | null = Number(0);
    public Rank: number = Number(0);
    public getMappings?() {
        return {
            Label: 'indicator_name',
            Name: 'Year (aliases)',
            Color: 'Year colors',
            Value: 'SUM(indicator_value)',
            Rank: "AGG(Rank)",
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class BrandOwnershipTitleModel implements IDataMapping<BrandOwnershipTitleModel> {
    public Title: string = '';
    public SubTitle: string = '';
    public getMappings?() {
        return {
            Title: "BMC Page Titles",
            SubTitle: "AGG(Category User Profile Sub Title)",
        };
    }
}

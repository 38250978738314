import pptxgen from 'pptxgenjs';

import { DemographicsPieChartTitleModel } from '../../../models/SRC/DemographicsPieChart/DemographicsPieChartTitleModel';
import { DemographicsPieChartDataModel } from '../../../models/SRC/DemographicsPieChart/DemographicsPieChartDataModel';

import { SRCFirstSlideRenderer } from '../../Renderers/SRCFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

export class DemographicsPieChartService extends BasePptxService {
    protected chartQuestion: string = '';
    protected firstSlideRenderer: SRCFirstSlideRenderer = new SRCFirstSlideRenderer();
    protected titleSheet: string;
    protected subTitleSheet: string;
    protected firstDataSheet: string;
    protected secondDataSheet: string;
    protected thirdDataSheet: string;
    protected fourthDataSheet: string;
    protected samplesDataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, subTitleSheet: string, firstDataSheet: string, 
        secondDataSheet: string, thirdDataSheet: string, fourthDataSheet: string, samplesDataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.subTitleSheet = subTitleSheet;
        this.firstDataSheet = firstDataSheet;
        this.secondDataSheet = secondDataSheet;
        this.thirdDataSheet = thirdDataSheet;
        this.fourthDataSheet = fourthDataSheet;
        this.samplesDataSheet = samplesDataSheet;
    }

    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        // Override title's options and add filter's name in it.
        const titleData = await this.getMappedChartData(DemographicsPieChartTitleModel, this.titleSheet);
        const subTitleData = await this.getMappedChartData(DemographicsPieChartTitleModel, this.subTitleSheet);
        const samplesData = await this.getMappedChartData(DemographicsPieChartTitleModel, this.samplesDataSheet);
        let subTitle: string = "";

        titleData.map( obj => {
            return this.chartTitle = `${obj.Survey} ${obj.Title} ${obj.Category}`;
        });

        subTitleData.map( obj => {
            return subTitle = obj.SubTitle;
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.25, bold: true });
        slide.addText([{ text: subTitle, options: { color: this.colors.black, fontSize: 12 } }], { x: 0.5, y: 0.48, italic: true });

        if (samplesData.length > 0) {
            let value: any;
            if (samplesData[0].SampleValue <= 20) {
                value = samplesData[0].SampleValue.toFixed(0) + "*";
            } else {
                value = samplesData[0].SampleValue.toFixed(0);
            }
            slide.addText(
            [
                { 
                    text: `${samplesData[0].SampleDate} (n=${value})`, 
                    options: { color: samplesData[0].SampleValue < 10 ? '#FF0000' : '#000000', fontSize: 11 },
                }
            ],
            { x: 8.2, y: 5.4, italic: true });
        }
        if (samplesData.length > 0 && samplesData[0].SampleValue <= 20) {
            slide.addText(
            [
                { 
                    text: "*Note: Scores could be influenced by small sample sizes.", 
                    options: { color: this.colors.black, fontSize: 11 },
                }
            ],
            { x: 4.2, y: 5.4, italic: true });
        }
    }

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        await this.addFirstPieChart(chartSlide);
        await this.addSecondChart(chartSlide);
        await this.addThirdChart(chartSlide);
        await this.addFourthChart(chartSlide);
    }

    private async addFirstPieChart(chartSlide: pptxgen.Slide) {
        const data = await this.getMappedChartData(DemographicsPieChartDataModel, this.firstDataSheet);
        if (data.length > 0) {
            const chartTitle = data[0].Label;
            const lineColors: string[] = [];

            data.forEach((obj: any) => {
                lineColors.push(obj.Color);
            })
            const dataChartPieStat = [
                {
                    name: data[0].Label,
                    labels: data.map((x: any) => x.Name),
                    values: data.map((x: any) => x.Value ? (x.Value * 100) : 0)
                }
            ];

            chartSlide.addChart(
                this.presentation.ChartType.doughnut,
                dataChartPieStat,
                {
                showTitle: true,
                x: 0.5,
                y: 0.55,
                w: 3.5,
                h: 2.5,
                showLegend: true,
                showLeaderLines: true,
                showValue: true,
                title: chartTitle,
                chartColors: lineColors.filter(this.distinctColors),
                dataLabelColor: "FFFFFF",
                dataLabelFontSize: 8,
                titleFontSize: 12,
                legendFontSize: 6,
                dataLabelPosition: "bestFit",
                showPercent: false,
                dataLabelFormatCode: '#\\%',
                }
            );
        };
    };

    private async addSecondChart(chartSlide: pptxgen.Slide) {
        const data = await this.getMappedChartData(DemographicsPieChartDataModel, this.secondDataSheet);
        if (data.length > 0) {
            const chartTitle = data[0].Label;
            const lineColors: string[] = [];

            data.forEach((obj: any) => {
                lineColors.push(obj.Color);
            })

            const dataChartPieStat = [
                {
                    name: data[0].Label,
                    labels: data.map((x: any) => x.Name),
                    values: data.map((x: any) => x.Value ? (x.Value * 100) : 0)
                }
            ];

            chartSlide.addChart(
                this.presentation.ChartType.doughnut,
                dataChartPieStat,
                {
                showTitle: true,
                x: 5.2,
                y: 0.55,
                w: 3.5,
                h: 2.5,
                showLegend: true,
                showLeaderLines: true,
                showValue: true,
                title: chartTitle,
                chartColors: lineColors.filter(this.distinctColors),
                dataLabelColor: "FFFFFF",
                dataLabelFontSize: 8,
                titleFontSize: 12,
                legendFontSize: 6,
                dataLabelPosition: "bestFit",
                showPercent: false,
                dataLabelFormatCode: '#\\%',
                }
            );
        };
    };

    private async addThirdChart(chartSlide: pptxgen.Slide) {
        const data = await this.getMappedChartData(DemographicsPieChartDataModel, this.thirdDataSheet);
        if (data.length > 0) {
            const chartTitle = data[0].Label;
            const lineColors: string[] = [];

            data.forEach((obj: any) => {
                lineColors.push(obj.Color);
            })
            const dataChartPieStat = [
                {
                    name: data[0].Label,
                    labels: data.map((x: any) => x.Name),
                    values: data.map((x: any) => x.Value ? (x.Value * 100) : 0)
                }
            ];

            chartSlide.addChart(
                this.presentation.ChartType.doughnut,
                dataChartPieStat,
                {
                showTitle: true,
                x: 0.5,
                y: 2.9,
                w: 3.5,
                h: 2.5,
                showLegend: true,
                showLeaderLines: true,
                showValue: true,
                title: chartTitle,
                chartColors: lineColors.filter(this.distinctColors),
                dataLabelColor: "FFFFFF",
                dataLabelFontSize: 8,
                titleFontSize: 12,
                legendFontSize: 6,
                dataLabelPosition: "bestFit",
                showPercent: false,
                dataLabelFormatCode: '#\\%',
                }
            );
        };
    };

    private async addFourthChart(chartSlide: pptxgen.Slide) {
        const data = await this.getMappedChartData(DemographicsPieChartDataModel, this.fourthDataSheet);
        if (data.length > 0) {
            const chartTitle = data[0].Label;
            const lineColors: string[] = [];

            data.forEach((obj: any) => {
                lineColors.push(obj.Color);
            })

            const dataChartPieStat = [
                {
                    name: data[0].Label,
                    labels: data.map((x: any) => x.Name),
                    values: data.map((x: any) => x.Value ? (x.Value * 100) : 0)
                }
            ];

            chartSlide.addChart(
                this.presentation.ChartType.doughnut,
                dataChartPieStat,
                {
                showTitle: true,
                x: 5.2,
                y: 2.9,
                w: 3.5,
                h: 2.5,
                showLegend: true,
                showLeaderLines: true,
                showValue: true,
                title: chartTitle,
                chartColors: lineColors.filter(this.distinctColors),
                dataLabelColor: "FFFFFF",
                dataLabelFontSize: 8,
                titleFontSize: 12,
                legendFontSize: 6,
                dataLabelPosition: "bestFit",
                showPercent: false,
                dataLabelFormatCode: '#\\%',
                }
            );
        };
    };

    private distinctColors = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
    };
}

import pptxgen from 'pptxgenjs';

import { SustainabilityLineChartTitleModel } from '../../../models/Sustainability/SustainabilityLineChart/SustainabilityLineChartTitleModel';
import { SustainabilityLineChartDataModel } from '../../../models/Sustainability/SustainabilityLineChart/SustainabilityLineChartDataModel';

import { HSFirstSlideRenderer } from '../../Renderers/HSFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

export class SustainabilityLineChartService extends BasePptxService {
	protected chartQuestion: string = '';
	protected firstSlideRenderer: HSFirstSlideRenderer = new HSFirstSlideRenderer();
	protected titleSheet: string;
	protected dataSheet: string;

	constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
		super(view, chartTitle);
		this.titleSheet = titleSheet;
		this.dataSheet = dataSheet;
	}

	// Override of a super class method
	protected async setChartSlideLayout(slide: pptxgen.Slide) {
		const titleData = await this.getMappedChartData(SustainabilityLineChartTitleModel, this.titleSheet);

		let subTitle: string = "";
		titleData.forEach((obj: any) => {
			subTitle = `Sustainability - ${obj.Market} - ${obj.Audience}`;
			this.chartTitle = `${obj.SubTitle}${obj.Title}`;
		});
		slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.55, bold: true });
		slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 12, lineSpacing: 12 } }], { x: 0.6, y: 0.9 });
	}

	protected async addChartSlide(chartSlide: pptxgen.Slide) {
		const mappedData = await this.getMappedChartData(SustainabilityLineChartDataModel, this.dataSheet);

		let filteredArray = this.removeDuplicates(mappedData)
		let groupedData = this.groupByProperty(filteredArray, "Name").reverse();

		let lineChartData: any[] = [];
		let colors: string[] = [];
		groupedData.forEach((group: SustainabilityLineChartDataModel[]) => {
			let name: string = "";
			let color: string = "";
			let labels: string[] = [];
			let values: number[] = [];

			group.forEach((obj: SustainabilityLineChartDataModel) => {
				name = obj.Name;
				color = obj.Color;
				labels.push(obj.Label);
				values.push(obj.Value);
			});

			lineChartData.push({ name, labels, values });
			colors.push(color);
		});

		chartSlide.addChart(
			this.presentation.ChartType.line,
			lineChartData,
			{
				x: 1,
				y: 1.2,
				w: "70%",
				h: "70%",
				showLegend: true,
				legendPos: 'b',
				dataLabelPosition: 't',
				dataLabelFormatCode: '0%',
				valAxisLabelFormatCode: '0%',
				catAxisLabelRotate: this.labelRotateDegree(lineChartData),
				chartColors: colors,
				valAxisLabelFontSize: 10,
				catAxisLabelFontSize: 10,
				valAxisMaxVal: 1,
			}
		);
	}

	protected labelRotateDegree(array: any) {
		let degree: number = 0;
		array.forEach((obj: any) => {
			if (obj.labels.length > 10) {
				degree = 45;
			} else {
				degree = 0;
			}
		});
		return degree;
	}

	private removeDuplicates(data: SustainabilityLineChartDataModel[]) {
		let cleanData: SustainabilityLineChartDataModel[] = [];

		cleanData = data.filter((obj, index, self) =>
			index === self.findIndex((o) => (
				o.Label === obj.Label && o.Name === obj.Name &&
				o.Value === obj.Value && o.Color === obj.Color
			))
		)

		return cleanData;
	}

	private groupByProperty(array: SustainabilityLineChartDataModel[], property: string) {
		const groupData: SustainabilityLineChartDataModel[][] = [];

		// Grouping the data
		for (let obj of array) {
			let flagMakeNewGroup = true;
			// Checks If there is group/array for this object
			for (let arr of groupData) {
				for (let element of arr) {
					if (element[property as keyof SustainabilityLineChartDataModel] === obj[property as keyof SustainabilityLineChartDataModel]) {
						arr.push(obj);
						flagMakeNewGroup = false;
					}
					break;
				}
			}
			// Creates new group/array
			if (flagMakeNewGroup) {
				groupData.push([obj]);
			}
		}

		return groupData;
	}
}

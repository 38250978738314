
import { IAddUserState } from './IAddUserState';
import { ActionType } from 'typesafe-actions';
import * as actions from './AddUserActions';
import { isNumber } from 'lodash';

export type UserActions = ActionType<typeof actions>;

const mailRegex = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i

const init: IAddUserState = {
  firstName: "",
  firstNameBool: true,
  lastName: "",
  lastNameBool: true,
  email: "",
  emailBool: true,
  company: "",
  companyBool: true,
  department: "",
  departmentBool: false,
  position: "",
  positionBool: false,
  site: "",
  siteBool: false,
  userRoles: [],
  userRolesBool: true,
  userStatus: 2,
  userStatusBool: false,
  country: 0,
  countryBool: false,
};

const validateTextField = (val : string) => {
  if (!val || val.length < 1) {
    return true;
  } else {
    return false;
  } 
}

const validateCountryField = (val: number) => {
    if (!isNumber(val)) {
        return true;
    }

    return false;
}

const validateEmailField = (val : string) => {
  if (!mailRegex.test(val)) {
      return true;
  } else {
    return false;
  }
}

const validateSiteField = (val : string, status : number) => {
  if (status === 1) {
    if (val.length < 1) {
      return true;
    } else {
      return false;
    } 
  } else {
    return false;
  }
}

const validateUserRoles= (val : any) => {
  if (val.length > 0) {
    return false;
  } else {
    return true;
  } 
}

const getUserRoles = (val: any) => {
  return val
}

export function AddUserReducer(state: IAddUserState = init, 
action: UserActions): IAddUserState {
    switch (action.type) {
      case actions.UserActionTypes.SET_FIRST_NAME:
        return {
          ...state,
          firstName: action.payload,
          firstNameBool: validateTextField(action.payload)
        };
      case actions.UserActionTypes.SET_LAST_NAME:
        return {
          ...state,
          lastName: action.payload,
          lastNameBool: validateTextField(action.payload)
        };
      case actions.UserActionTypes.SET_EMAIL:
        return {
          ...state,
          email: action.payload,
          emailBool: validateEmailField(action.payload)
        };
      case actions.UserActionTypes.SET_COMPANY:
        return {
          ...state,
          company: action.payload,
          companyBool: validateTextField(action.payload)
        };
      case actions.UserActionTypes.SET_DEPARTMENT:
        return {
          ...state,
          department: action.payload,
        };
      case actions.UserActionTypes.SET_POSITION:
        return {
          ...state,
          position: action.payload,
        };
      case actions.UserActionTypes.SET_SITE:
        return {
          ...state,
          site: action.payload,
          siteBool: validateSiteField(action.payload, state.userStatus)
        };
      case actions.UserActionTypes.SET_USER_ROLES:
        return {
          ...state,
          userRoles: getUserRoles(action.payload),
          userRolesBool: validateUserRoles(action.payload)
        };
      case actions.UserActionTypes.SET_USER_STATUS:
        return {
          ...state,
          userStatus: action.payload,
          userRolesBool: validateTextField(action.payload)
      };
      case actions.UserActionTypes.SET_COUNTRY:
        return {
          ...state,
          country: action.payload,
          countryBool: validateCountryField(action.payload)
      };
      case actions.UserActionTypes.RESET_STATE:
        return init
      default:
          return state;
    }
}


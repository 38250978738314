import pptxgen from 'pptxgenjs';

import { BasePptxService } from '../../../../BasePptxService';
import { HSFirstSlideRenderer } from '../../../../Renderers/HSFirstSlideRenderer';
import { PptxRenderHelper } from '../../../../../helpers/PptxRenderHelper';
import { ChartColors } from '../../../../../philips-constants';

import { MainProductNPSChartDataModel } from '../../../../../models/HealthSystems/ProductLevelNPS/Overview/MainProductNPSChart/MainProductNPSChartDataModel';

/**
 * Note: The order of the groups depends on the order of the given endpoints. 
 * The endpoints are given in an array as a parameter for the called instance 
 * in the ChartDownloadHelper file.
 * 
 * Note: The number of groups in the tables depends on the parameters given to the buildTable method.
 */

export class MainProductNPSChartService extends BasePptxService {
    protected chartQuestion: string = 'Please select which vendor performs better regarding these attributes.';
    protected firstSlideRenderer = new HSFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheets: string[];

    private colorsByCategory: { [key: string]: string } = {
        'Philips leads competitors by more than 5%': this.colors.positive,
        'Philips is within -5%/+5% to competitors': this.colors.neutral,
        'Competitors lead by more than 5%': this.colors.negative
    };

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheets: string[]) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheets = dataSheets;
    }

    // Override of a super class method
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        let mappedChartData: MainProductNPSChartDataModel[][] = [];

        for (let data of this.dataSheets) {
            let mappedData = await this.getMappedChartData(MainProductNPSChartDataModel, data);
            mappedChartData.push(mappedData);
        }

        // left table
        this.addPptTable(
            chartSlide,
            this.buildTable(mappedChartData, 0, 3),
        );

        // mid table
        this.addPptTable(
            chartSlide,
            this.buildTable(mappedChartData, 3, 2),
            { x: '37.5%' }
        );

        // right table
        this.addPptTable(
            chartSlide,
            this.buildTable(mappedChartData, 5, 2),
            { x: '68%' }
        );

        this.addLegend(chartSlide);
    }

    private addPptTable(chartSlide: pptxgen.Slide, rows: pptxgen.TableRow[], tableProps?: pptxgen.TableProps) {
        tableProps = Object.assign({
            x: '7%',
            y: 1,
            w: '25%',
            colW: [2, 0.5],
            rowH: 0.35,
            border: { type: 'none' },
        }, tableProps);

        chartSlide.addTable(rows, tableProps);
    }

    private buildTable(data: MainProductNPSChartDataModel[][], startIndex: number, numberOfGroups: number) {
        let rows: pptxgen.TableRow[] = [];

        for (let i = startIndex; i < (startIndex + numberOfGroups); ++i) {
            if (rows.length > 0) {
                rows.push([{ text: "", options: { colspan: 2 } }]);
            }

            rows.push(...this.getRowsForGroup(data[i]))
        }

        return rows;
    }

    private getRowsForGroup(data: MainProductNPSChartDataModel[]) {
        // Getting business group name
        let businessGroup = "";
        data.forEach((obj) => {
            if (obj.businessGroup) {
                businessGroup = obj.businessGroup;
            }
        });

        // Adding 1st row
        const rows: pptxgen.TableRow[] =
            [[{
                text: businessGroup,
                options: { colspan: 2, bold: true, fontSize: 12, valign: 'middle' }
            }]];

        // Adding 2nd and 3th row
        for (const obj of data) {
            rows.push([
                { text: obj.text, options: { fontSize: 10, valign: 'middle' } },
                { text: parseFloat(obj.value).toFixed(0), options: { fill: { color: this.colorsByCategory[obj.colorCategory] }, fontSize: 10, align: 'center', valign: 'middle' } }
            ]);
        }

        return rows;
    }

    private addLegend(chartSlide: pptxgen.Slide) {
        let y = 4.5;

        for (const [key, value] of Object.entries(this.colorsByCategory)) {
            PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: key, color: value }, { x: 7, y });
            y += 0.2;
        }
    }
}

import pptxgen from 'pptxgenjs';
import { MCCPreferenceScoreTitleModel } from '../../../models/PersonalHealth/MCCPreferenceScore/MCCPreferenceScoreTitleModel';
import { MCCPreferenceScoreDataModel } from '../../../models/PersonalHealth/MCCPreferenceScore/MCCPreferenceScoreDataModel';

import { PHFirstSlideRenderer } from '../../Renderers/PHFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

export class MCCPreferenceScoreService extends BasePptxService {
    protected chartQuestion: string = '';
    protected firstSlideRenderer: PHFirstSlideRenderer = new PHFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
    }

    protected async setChartSlideLayout(slide: pptxgen.Slide) {
      // Override title's options and add filter's name in it.
      const titleData = await this.getMappedChartData(MCCPreferenceScoreTitleModel, this.titleSheet);
      let subTitle: string = "";
      titleData.map( obj => {
        this.chartTitle = obj.Title;
      return subTitle = obj.Country + ' ' + obj.Category;
      });
      slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.35, bold: true });
      slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.58, italic: true });
    }
  
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
      const mappedData = await this.getMappedChartData(MCCPreferenceScoreDataModel, this.dataSheet);
      chartSlide.addText([{ text: `${(mappedData[0].Value * 100).toFixed(1)}%`, options: { fontSize: 28 } }], { x: 4.2, y: 2.7 });
    }
}

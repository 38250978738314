import pptxgen from 'pptxgenjs';

import { DriverAnalysisTitleModel } from '../../../models/SRC/DriverAnalysis/DriverAnalysisTitleModel';
import { DriverAnalysisCommentsModel } from '../../../models/SRC/DriverAnalysis/DriverAnalysisCommentsModel';
import { DriverAnalysisDataModel } from '../../../models/SRC/DriverAnalysis/DriverAnalysisDataModel';

import { SRCFirstSlideRenderer } from '../../Renderers/SRCFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

export class KeyDriverAnalysisService extends BasePptxService {
    protected chartQuestion: string = '';
    protected firstSlideRenderer: SRCFirstSlideRenderer = new SRCFirstSlideRenderer();
    protected titleSheet: string;
    protected bubbleDataSheet: string;
    protected commentsSheet: string;
    protected verbatimsSheet: string;
    protected samplesSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, bubbleDataSheet: string,
        commentsSheet: string, verbatimsSheet: string, samplesSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.bubbleDataSheet = bubbleDataSheet;
        this.commentsSheet = commentsSheet;
        this.verbatimsSheet = verbatimsSheet;
        this.samplesSheet = samplesSheet;
    }

    protected async setChartSlideLayout(slide: pptxgen.Slide) { }

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const bubbleData = await this.getMappedChartData(DriverAnalysisDataModel, this.bubbleDataSheet);
        const titleData = await this.getMappedChartData(DriverAnalysisTitleModel, this.titleSheet);
        const commentsData = await this.getMappedChartData(DriverAnalysisCommentsModel, this.commentsSheet);
        const verbatimsData = await this.getMappedChartData(DriverAnalysisCommentsModel, this.verbatimsSheet);
        const samplesData = await this.getMappedChartData(DriverAnalysisCommentsModel, this.samplesSheet);

        titleData.forEach((obj: any) => {
            if (obj.Title) {
                this.chartTitle = obj.Title;
            }
        });

        chartSlide.addText([{ text: this.chartTitle, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.25, bold: true });

        if (bubbleData.length > 0) {
            const sortedData = [...bubbleData].sort(function (a: any, b: any) {
                return a.Rank - b.Rank;
            });

            const newArr: any = [];
            const lineColors: string[] = [];
            const labelsArr: string[] = [];

            sortedData.forEach((obj: any) => {
                lineColors.push(obj.Color);
                labelsArr.push(obj.Label);
                const item = newArr.find((item: any) => item.name === obj.Name);
                if (newArr.length > 0 && item) {
                    if (item) {
                        item.values.push(parseFloat(obj.Value));
                    }
                } else {
                    newArr.push(
                        {
                            name: obj.Name,
                            values: [parseFloat(obj.Value)],
                        }
                    )
                }
            });

            const disctinctedLabels = labelsArr.filter(this.distinctRepeatables);
            const reversedNewArr = [...newArr].reverse();

            reversedNewArr.unshift({
                name: 'X-Axis',
                values: Array.from(Array(disctinctedLabels.length + 1).keys()).slice(1),
            });

            reversedNewArr.forEach((obj: any) => {
                if (obj.name === null) {
                    obj.name = "Market Average";
                };
            });

            chartSlide.addChart(
                this.presentation.ChartType.scatter,
                reversedNewArr,
                {
                    x: 0.2,
                    y: 0.62,
                    w: "95%",
                    h: "62%",
                    lineDataSymbolLineSize: 6,
                    lineDataSymbolSize: 6,
                    lineSize: 0,
                    showLegend: true,
                    legendFontSize: 9,
                    valAxisLabelFontSize: 8,
                    valAxisLabelFormatCode: "0%",
                    catAxisHidden: true,
                    catAxisMinVal: 1,
                    catAxisMaxVal: disctinctedLabels.length,
                    valAxisMinVal: 0,
                    valAxisMaxVal: 0.81,
                    legendPos: 't',
                    chartColors: lineColors.reverse().filter(this.distinctRepeatables),
                }
            );

            if (verbatimsData.length > 0) {
                this.addVerbatimsTable(verbatimsData, chartSlide);
            }

            if (samplesData.length > 0) {
                this.addSampleTable(samplesData, chartSlide);
            }

            if (commentsData.length > 0) {
                this.addComments(commentsData, chartSlide);
            }

            this.addLegend(sortedData, chartSlide);

        } else {
            chartSlide.addText([{ text: "Low sample to run Key Driver Analysis.", options: { color: '#000000', fontSize: 8 } }], { x: 0.5, y: 4.65, bold: false });
        }
    };

    // Table with samples
    private addSampleTable(samplesData: any, chartSlide: pptxgen.Slide) {
        const sortedData = samplesData.sort(function (a: any, b: any) {
            return a.SampleRank - b.SampleRank;
        });

        // Nested arrays in tableData[] are rows in the ppt table
        let lowValue: boolean = false;
        const tableData = sortedData.map((object: any) => {
            if (object.SampleValue <= 20) {
                lowValue = true;
            };
            return [{
                text: `${object.SampleName} (n = ${object.SampleValue.toFixed(0) <= 20 ? object.SampleValue.toFixed(0) + '*' : object.SampleValue.toFixed(0)})`,
                options: { color: object.SampleValue.toFixed(0) < 10 ? '#FF0000' : this.colors.default, fontSize: 8, italic: true }
            }]
        });

        const legendProps: pptxgen.TableProps = {
            x: 8.2,
            y: 4.5,
            w: '15%',
            align: 'left',
            fontSize: 8,
            valign: "top",
            rowH: 0.1,
            color: this.colors.default,
            border: { pt: 0 }
        };

        chartSlide.addTable(tableData, legendProps);

        if (lowValue) {
            chartSlide.addText([{ text: "*Note: Scores could be influenced by small sample sizes.", options: { color: '#000000', fontSize: 8 } }], { x: 0.5, y: 4.45, bold: false });
        };
    }

    private addVerbatimsTable(verbatimsData: any, chartSlide: pptxgen.Slide) {
        const verbatims = verbatimsData.map((object: any) => {
            return [{ text: object.Verbatims, options: { color: this.colors.default, fontSize: 8, italic: true } }]
        });

        const legendProps: pptxgen.TableProps = {
            x: 0.5,
            y: 4.5,
            w: '15%',
            align: 'left',
            fontSize: 8,
            valign: "top",
            rowH: 0.1,
            colW: 7.6,
            color: this.colors.default,
            border: { pt: 0 }
        };

        chartSlide.addTable(verbatims, legendProps);
    }

    private addLegend(data: any, chartSlide: pptxgen.Slide) {
        const legend: any = [];
        const newArr: any = [];

        data.forEach((obj: any) => {
            newArr.push(
                {
                    text: obj.Label,
                    options: { color: this.colors.default }
                }
            );
        });

        const names = newArr.map((o: any) => o.text);
        const filteredNames = newArr.filter(({ text }: any, index: any) => !names.includes(text, index + 1));
        legend.push(filteredNames);

        const legendProps: pptxgen.TableProps = {
            x: 0.25,
            y: 4.04,
            w: '96%',
            align: 'center',
            bold: false,
            fontSize: 8,
            valign: "middle",
            rowH: 0.1,
            color: this.colors.white,
            border: { type: 'solid', color: this.colors.neutral, pt: 0.2 }
        };
        chartSlide.addTable(legend, legendProps);
    };

    private addComments(commentsData: any, chartSlide: pptxgen.Slide) {
        chartSlide.addText([{ text: commentsData[0].Comments, options: { color: this.colors.black, fontSize: 12 } }], { x: 0.5, y: 0.48, italic: true });
    }

    protected labelRotateDegree(array: any) {
        let degree: number = 0;
        array.forEach((obj: any) => {
            if (obj.labels.length > 10) {
                degree = 45;
            } else {
                degree = 0;
            }
        });
        return degree;
    };

    private distinctRepeatables = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
    };
}

import pptxgen from 'pptxgenjs';
import { LegendModel } from '../models/LegendModel';
import { ChartColors } from '../philips-constants';

export class PptxRenderHelper {
    public static addAvarageColorized(
        chartSlide: pptxgen.Slide,
        average: { AveragePositive: number | null, AverageNegative: number | null } | null,
        options: pptxgen.TextPropsOptions
    ) {
        if (average) {
            let value: number | null = average.AveragePositive;
            let color: string = ChartColors.default;

            if (average.AveragePositive === null) {
                value = average.AverageNegative;
                color = ChartColors.negative;
            }

            if (value !== null) {
                chartSlide.addText(`N = ${value}`, { ...options, color });
            }
        }
    }

    public static addPieChartAvarageColorized(
        chartSlide: pptxgen.Slide,
        average: { AveragePositive: number | null, AverageNegative: number | null } | null,
        chartOptions: pptxgen.IChartOpts
    ) {
        this.addAvarageColorized(chartSlide, average, {
            x: chartOptions.x,
            y: Number(chartOptions.h) * Number(chartOptions.layout?.y) + Number(chartOptions.y),
            w: Number(chartOptions.w) * Number(chartOptions.layout?.w),
            h: Number(chartOptions.h) * Number(chartOptions.layout?.h),
            align: 'center',
            valign: 'middle',
            fontSize: 14,
        });
    }

    public static addBarChartAvarageColorized(
        chartSlide: pptxgen.Slide,
        average: { AveragePositive: number | null, AverageNegative: number | null } | null,
        chartOptions: pptxgen.IChartOpts,
        barIndex: number,
        barWidth: number
    ) {
        this.addAvarageColorized(chartSlide, average, {
            x: Number(chartOptions.x) + (barIndex * barWidth),
            y: Number(chartOptions.h) * Number(chartOptions.layout?.h) + Number(chartOptions.y) - 0.2,
            w: barWidth,
            fontSize: 8,
            align: 'center'
        });
    }

    public static renderSquareLegend(
        chartSlide: pptxgen.Slide,
        { label, color }: LegendModel,
        options: pptxgen.TextPropsOptions
    ) {
        chartSlide.addText([
            { text: '■  ', options: { color, fontSize: 14, } },
            { text: label, options: { color: ChartColors.default, fontSize: 8 } },
        ], options);
    }

    public static renderShapeSquareLegend(
        presentation: pptxgen,
        chartSlide: pptxgen.Slide,
        parameters: { label: string; color: string },
        options: { x: number; y: number }
    ) {
        const shapeOptions: pptxgen.ShapeProps = {
            x: options.x,
            y: options.y,
            w: 0.1,
            h: 0.1,
            fill: { color: parameters.color },
            line: { dashType: 'solid', color: ChartColors.default, width: 0.5 },
            align: 'center'
        };
        chartSlide.addShape(presentation.ShapeType.rect, shapeOptions);
        chartSlide.addText(parameters.label,
            { x: options.x + 0.1, y: options.y, valign: 'top', fontSize: 8, color: ChartColors.default });
    }

    public static renderShapeLegend(
        chartSlide: pptxgen.Slide,
        parameters: { label: string; color: string },
        options: { x: number; y: number; fontSize: number },
        shape: string
    ) {
        const shapeOptions: pptxgen.ShapeProps = {
            x: options.x,
            y: options.y,
            w: 0.1,
            h: 0.1,
            fill: { color: parameters.color },
            line: { dashType: 'solid', color: ChartColors.default, width: 0.5 },
            align: 'center',
        };
        const shapeName: pptxgen.ShapeType = shape as pptxgen.ShapeType;
        chartSlide.addShape(shapeName, shapeOptions);
        chartSlide.addText(parameters.label,
            { x: options.x + 0.1, y: options.y, valign: 'top', fontSize: options.fontSize, color: ChartColors.default });
    }

    public static getMappedChartDataColorsSP(chartData: Array<{ Color: string }>) {
        return chartData.map(c => {
            const color = c.Color ? c.Color.trim() : '';
            if (color === '*') {
                return ChartColors.grey;
            }
            return /^#[0-9A-F]{6}$/i.test(color)
                ? color.replace('#', '')
                : /^#([0-9A-F]{3}){1,2}$/i.test(color)
                    ? color.replace('#', '')
                    : ChartColors.default;
        });
    }
}

import { IDataMapping } from '../../IDataMapping';

export class CommonLineChartTitleModel implements IDataMapping<CommonLineChartTitleModel> {
    public Level: string = '';
    public Title: string = '';
    public SubTitle: string = '';
    public getMappings?() {
        return {
            Level: 'country_name',
            Title: 'OneBlade Page Titles',
            SubTitle: 'Category Short',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class ProductNPSLineChartTitleModel implements IDataMapping<ProductNPSLineChartTitleModel> {
    public Title: string = '';
    public TitlePPT: string = '';
    
    public getMappings?() {
        return {
            Title: 'NPS Line Title',
            TitlePPT: 'NPSTitle Export',       
        };
    }
}
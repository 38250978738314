import { IDataMapping } from '../../IDataMapping';

export class PurchaseFunnelLineChartDataModel implements IDataMapping<PurchaseFunnelLineChartDataModel> {
    public Name: string = '';
    public Label: string = '';
    public Color: string = '';
    public Value: number = Number(0);
    public Rank: number = Number(0);
    public TimeRank: number = Number(0);
    public getMappings?() {
        return {
            Name: 'Brand',
            Label: 'Date',
            Color: 'AGG(BrandColors)',
            Value: 'AVG(Value)',
            Rank: 'last',
            TimeRank: 'Rank_Date_Color',
        };
    }
}
import pptxgen from 'pptxgenjs';

import { BMCSimulatorLineChartTitleModel } from '../../../models/PersonalHealth/BMCSimulatorLineChart/BMCSimulatorLineChartTitleModel';
import { BMCSimulatorCombinedChartDataModel } from '../../../models/PersonalHealth/BMCSimulatorLineChart/BMCSimulatorCombinedChartDataModel';
import { BMCSimulatorLineChartDataModel } from '../../../models/PersonalHealth/BMCSimulatorLineChart/BMCSimulatorLineChartDataModel';

import { PHFirstSlideRenderer } from '../../Renderers/PHFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

import { filterInitDuplicateData, addEmptyValuesToInitData } from "../../../../shared/utility";

export class BMCSimulatorLineChartService extends BasePptxService {
  protected chartQuestion: string = '';
  protected firstSlideRenderer: PHFirstSlideRenderer = new PHFirstSlideRenderer();
  protected titleSheet: string;
  protected combinedDataSheet: string;
  protected lineChartDataSheet: string;
  protected readonly defaultColor: string = '#D9D9D9';

  constructor(view: any, chartTitle: string, titleSheet: string, combinedDataSheet: string, lineChartDataSheet: string) {
      super(view, chartTitle);
      this.titleSheet = titleSheet;
      this.combinedDataSheet = combinedDataSheet;
      this.lineChartDataSheet = lineChartDataSheet;
  }

  protected async setChartSlideLayout(slide: pptxgen.Slide) {
    // Override title's options and add filter's name in it.
    const titleData = await this.getMappedChartData(BMCSimulatorLineChartTitleModel, this.titleSheet);
    let subTitle: string = "";
    
    titleData.map( obj => {
      this.chartTitle = obj.Title;
      return subTitle = `${obj.Level} - ${obj.SubTitle}`;
    });

    slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.35, bold: true });
    slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.58, italic: true });
  }

  protected async addChartSlide(chartSlide: pptxgen.Slide) {
    const titleData = await this.getMappedChartData(BMCSimulatorLineChartTitleModel, this.titleSheet);
    const combinedMappedData = await this.getMappedChartData(BMCSimulatorCombinedChartDataModel, this.combinedDataSheet);
    const barChartData = combinedMappedData.filter(obj => obj.BarChartName && !obj.LineChartName);
    const reversedBarChartData = barChartData.slice().reverse();
    const lineChartData = combinedMappedData.filter(obj => !obj.BarChartName && obj.LineChartName);
    const newBarChartData: any = [];
    const newLineChartData: any = [];
    const lineColors: string[] = [];
    let chartTitle: string = "";

    const getAllLabels: any = [];

    combinedMappedData.forEach((obj: any) => {
      getAllLabels.push(obj.Label);
    });

    const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

    reversedBarChartData.forEach((obj: any) => {
      chartTitle = obj.ChartTitle;
      const findBarItem = newBarChartData.find((item: any) => item.name === obj.BarChartName);
        if (newBarChartData.length > 0 && findBarItem) {
          findBarItem.values.push((obj.BarChartValue / 1000000).toFixed(1));
          findBarItem.labels.push(obj.Label);
        } else {
          if (obj.BarChartName)
          newBarChartData.push(
            {
              name: obj.BarChartName,
              labels: [obj.Label],
              values: [(obj.BarChartValue / 1000000).toFixed(1)],
            }
          );
        };
    })

    lineChartData.forEach((obj: any) => {
      lineColors.push(this.getColorPalette(obj.LineChartName));
      
      const findLineItem = newLineChartData.find((item: any) => item.name === obj.LineChartName);
      if (newLineChartData.length > 0 && findLineItem) {
        findLineItem.values.push(parseFloat(obj.LineChartValue));
        findLineItem.labels.push(obj.Label);
      } else {
        newLineChartData.push(
          {
            name: obj.LineChartName,
            labels: [obj.Label],
            values: [parseFloat(obj.LineChartValue)],
          }
        );
      }
    });

    let opts: any  = {
			x: 1,
      y: 1,
      w: "70%",
      h: "70%",
			barDir: "col",
			barGrouping: "stacked",
			showLegend: true,
      legendPos: 'b',
			showTitle: true,
      title: chartTitle,
      titleFontSize: 14,
      valAxisLabelFontSize: 10,
      catAxisLabelFontSize: 10,
      valAxes: [
				{
					showValAxisTitle: false,
					valAxisTitle: "Primary Value Axis",
          dataLabelFormatCode: '0\\%',
          valAxisLabelFormatCode:'0\\%',
				},
				{
					showValAxisTitle: true,
					valAxisTitle: "Media spend in mln",
					catAxisOrientation: "maxMin",
					valAxisMajorUnit: 1,
					valGridLine: { style: "none" },
				},
			],
			catAxes: [
				{
					catAxisTitle: "Primary Category Axis",
				},
				{
					catAxisHidden: true,
				},
			],
		};
    
    const finalLineChartData = addEmptyValuesToInitData(distinctedLabels, newLineChartData);
    const finalBarChartData = addEmptyValuesToInitData(distinctedLabels, newBarChartData);
		let chartTypes:pptxgen.IChartMulti[]  = [
			{
				type: this.presentation.ChartType.line,
        data: finalLineChartData,
				options: {
					barGrouping: "standard",
          dataLabelFormatCode: '#%',
          valAxisLabelFormatCode: '#%',
          chartColors: lineColors.filter(this.distinctRepeatables),
				},
			},
      {
				type: this.presentation.ChartType.bar,
        data: finalBarChartData,
				options: {
					barGrouping: "stacked",
          secondaryValAxis: true,
          secondaryCatAxis: true,
          barGapWidthPct: 200,
          chartColors: [this.defaultColor],
				},
			},
		];
		chartSlide.addChart(chartTypes, opts);
    
    await this.addSecondLineChart(titleData)
  }

  private async addSecondLineChart(titleData: any) {
    const mappedData = await this.getMappedChartData(BMCSimulatorLineChartDataModel, this.lineChartDataSheet);
    const slide = this.presentation.addSlide({ masterName: 'SRC_MONTHLY_CHART' });
    let subTitle: string = "";
    let filteredData = filterInitDuplicateData(mappedData);
    filteredData.sort((a: any, b: any) => a.Rank - b.Rank);
    const newArr: any = [];
    const lineColors: string[] = [];
    let chartTitle: string = "";
    const getAllLabels: any = [];
    
    filteredData.forEach((obj: any) => {
      getAllLabels.push(obj.Label);
    });

    const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

    filteredData.forEach((obj: any) => {
      lineColors.push(this.getColorPalette(obj.Name));

      chartTitle = obj.ChartTitle;
      const item = newArr.find((item: any) => item.name === obj.Name);

      if(newArr.length > 0 && item) {
        if (item) {
          item.values.push(parseFloat(obj.Value));
          item.labels.push(obj.Label);
        }
      } else {
        newArr.push(
          {
            name: obj.Name,
            labels: [obj.Label],
            values: [parseFloat(obj.Value)],
          }
        )
      }
    });

    const finalChartData = addEmptyValuesToInitData(distinctedLabels, newArr);
    slide.addChart(
      this.presentation.ChartType.line,
      finalChartData,
      {
        x: 1,
        y: 1,
        w: "70%",
        h: "70%",
        showLegend: true,
        legendPos: 'b',
        showTitle: true,
        title: chartTitle,
        titleFontSize: 14,
        dataLabelPosition: 't',
        dataLabelFormatCode: '0\\%',
        valAxisLabelFormatCode: '0\\%',
        catAxisLabelRotate: this.labelRotateDegree(newArr),
        chartColors: lineColors.filter(this.distinctRepeatables),
        valAxisLabelFontSize: 10,
        catAxisLabelFontSize: 10,
      }
    );  
    
    titleData.map( (obj: any) => {
      this.chartTitle = obj.Title;
      return subTitle = `${obj.Level} - ${obj.SubTitle}`;
    });
    slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.35, bold: true });
    slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.58, italic: true });
  }

  protected labelRotateDegree(array: any) {
    let degree: number = 0;
    array.forEach((obj: any) => {
      if (obj.labels.length > 10) {
        degree = 45;
      } else {
        degree = 0;
      }
    });
    return degree;
  }

  private distinctRepeatables = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };

  private getColorPalette = (name: string) => {
    let color = this.defaultColor;
    if (name === "Share of Media") {
      color = "#f7b6d2";
    } else if (name === "Distribution Index") {
      color = "#ffbb78";
    } else if (name === "Innovation Index") {
      color = "#ff7f0e";
    } else if (name === "Preference (MAT)") {
      color = "#1aad9c";
    } else if (name === "Preference (Q)") {
      color = "#0154a6";
    };

    return color;
  };
}

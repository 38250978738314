import { IDataMapping } from '../../../../IDataMapping';

export class CAImportanceChartSampleModel implements IDataMapping<CAImportanceChartSampleModel> {
    public SamplePositive: number | null = 0;
    public SampleNegative: number | null = 0;
    public getMappings?() {
        return {
            SamplePositive: 'AGG(Sample Size Color <50 BLACK)',
            SampleNegative: 'AGG(Sample Size Color <50 RED)',
        };
    }
}

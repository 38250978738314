import pptxgen from 'pptxgenjs';

import { NPSTrendsLineChartTitleModel } from '../../../../../models/HealthSystems/ProductLevelNPS/Trends/NPSTrendsChart/NPSTrendsLineChartTitleModel';
import { NPSTrendsLineChartDataModel } from '../../../../../models/HealthSystems/ProductLevelNPS/Trends/NPSTrendsChart/NPSTrendsLineChartDataModel';
import { NPSTrendsBarChartDataModel } from '../../../../../models/HealthSystems/ProductLevelNPS/Trends/NPSTrendsChart/NPSTrendsBarChartDataModel';
import { NPSTrendsLegendDataModel } from '../../../../../models/HealthSystems/ProductLevelNPS/Trends/NPSTrendsChart/NPSTrendsLegendDataModel';

import { BasePptxService } from '../../../../BasePptxService';
import { HSFirstSlideRenderer } from '../../../../Renderers/HSFirstSlideRenderer';
import { BaseChartOptions } from '../../../../../philips-constants';
import { LegendData } from '../../../../../models/ProductLevelNPS/LegendData';
import { addEmptyValuesToInitData, filterInitDuplicateData } from '../../../../../../shared/utility';
import { PptxRenderHelper } from '../../../../../helpers/PptxRenderHelper';

export class NPSTrendsService extends BasePptxService {
    protected firstSlideRenderer: HSFirstSlideRenderer = new HSFirstSlideRenderer();
    protected chartQuestion: string =
        `NPS question: Based on your experience on using their product and services, how likely are you to recommend this company?`;

    protected titleSheet: string;
    protected lineChartDataSheet: string;
    protected barChartDataSheet: string;
    protected legendDataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string,
        lineChartDataSheet: string, barChartDataSheet: string, legendDataSheet: string,) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.lineChartDataSheet = lineChartDataSheet;
        this.barChartDataSheet = barChartDataSheet;
        this.legendDataSheet = legendDataSheet;
    }

    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const mappedTitleData = await this.getMappedChartData(NPSTrendsLineChartTitleModel, this.titleSheet);

        mappedTitleData.forEach(obj => {
            this.chartTitle = `${obj.Title}${obj.Country} · ${obj.BG} · ${obj.Modality} · ${obj.Submodality}`;
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.55, bold: true });
        slide.addText([{ text: this.chartQuestion, options: { color: this.colors.black, fontSize: 7 } }], { x: 0.5, y: '95%', bold: false });
    }

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const lineChartData = await this.getMappedChartData(NPSTrendsLineChartDataModel, this.lineChartDataSheet);
        const barChartData = await this.getMappedChartData(NPSTrendsBarChartDataModel, this.barChartDataSheet);
        const legendData = await this.getMappedChartData(NPSTrendsLegendDataModel, this.legendDataSheet);

        if (legendData.length > 0 && lineChartData.length > 0) {

            await this.addLineChart(chartSlide, lineChartData, legendData);
        }

        if (barChartData.length > 0) {
            await this.addSecondChart(barChartData);
        }
    }

    private colorsByValue = {
        'Promoters': this.colors.positive,
        'Passive': this.colors.neutral,
        'Detractor': this.colors.negative,
    }

    protected async addLineChart(slide: pptxgen.Slide, data: any, legData: any) {
        const lineColors = this.getLineColors(legData);
        const legendData = this.processLegendData(legData);
        const lineChartData = this.processChartData(data);

        slide.addChart(this.presentation.ChartType.line, lineChartData, {
            ...BaseChartOptions,
            x: 1,
            y: 1.1,
            w: 8,
            h: 3.5,
            showValue: true,
            dataLabelPosition: 't',
            dataLabelFormatCode: '0%',
            valAxisLabelFormatCode: '0%',
            catAxisLineShow: false,
            valAxisLineShow: false,
            valAxisMajorUnit: 0.2,
            chartColors: lineColors.filter(this.distinctRepeatables),
            dataLabelColor: this.colors.default,
            dataLabelFontSize: 10,
        });

        slide.addText(legendData, { x: 1, y: '85%' });
    };

    protected async addSecondChart(data: any) {
        const chartSlide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });
        data.reverse();
        const finalBarChartData = this.processBarChartData(data);

        chartSlide.addChart(this.presentation.ChartType.bar, finalBarChartData.chartData, {
            ...BaseChartOptions,
            x: 2.5,
            y: 2,
            w: 5.7,
            h: 2,
            barDir: 'bar',
            barGrouping: 'stacked',
            showValue: true,
            valAxisLineShow: false,
            chartColors: [this.colorsByValue['Promoters'], this.colorsByValue['Passive'], this.colorsByValue['Detractor'], this.colors.white],
            dataLabelFormatCode: '0%',
            valAxisHidden: true,
            catAxisLabelFontSize: 8,
            dataLabelFontSize: 12,
            dataLabelFontBold: true,
            dataLabelColor: this.colors.white
        });

        chartSlide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.55, bold: true });
        chartSlide.addText([{ text: this.chartQuestion, options: { fontSize: 7 } }], { x: 0.5, y: '95%' });
        this.addBarLegend(chartSlide);
    }

    private processBarChartData(data: any) {
        const dataMatrix: { order: number, data: any[] }[] = [];
        const labels: { order: number, label: string }[] = [];
        let leader: string = '';

        for (const obj of data) {
            if (obj.ValueNPS || obj.Detractor || obj.Passive || obj.Promoters) {
                const percent = Math.round(obj.ValueNPS * 100) + '%';
                labels.push({ order: obj.Rank, label: `${obj.Brand} (${obj.Base})\r\n\ ${percent}` });
                dataMatrix.push({ order: obj.Rank, data: [obj.Promoters, obj.Passive, obj.Detractor] });
            }
            if (obj.Leadership) {
                leader = `Philips - ${obj.Leadership}`;
            }
        };

        labels.sort((a, b) => {
            return b.order - a.order;
        });

        dataMatrix.sort((a, b) => {
            return b.order - a.order;
        });

        const chartData: any[] = [];
        dataMatrix[0].data.forEach(() => chartData.push({ labels: labels.map(l => l.label), values: [] }));

        for (let rowIndex = 0; rowIndex < dataMatrix.length; rowIndex++) {
            const matrixRow = dataMatrix[rowIndex].data;
            for (let colIndex = 0; colIndex < matrixRow.length; colIndex++) {
                chartData[colIndex].values[rowIndex] = matrixRow[colIndex];
            }
        }

        return { chartData, leader };
    }

    private addBarLegend(chartSlide: pptxgen.Slide) {
        let x = 3.5;

        for (const [key, value] of Object.entries(this.colorsByValue)) {
            PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: '% ' + key, color: value }, { x, y: 4.5 });
            x += 1.2;
        }
    }

    private processLegendData(data: any): LegendData[] {
        const newArr: any = [];
        data.forEach((obj: any) => {
            const value = (obj.Value).toFixed();
            const baseColor = obj.BaseColor === '1' ? this.colors.default : this.colors.negative;
            const [rank, brand, color] = (obj.RankBrandColor).split('|');
            newArr.push({ rank, brand, color, value, baseColor });
        });

        newArr.sort((a: any, b: any) => a.rank.localeCompare(b.rank));

        const legendData: LegendData[] = [];
        newArr.forEach((current: any) => {
            legendData.push(
                { text: `${current.brand} `, options: { color: current.color, fontSize: 8 } },
                { text: `(${current.value})    `, options: { color: current.baseColor, fontSize: 8 } }
            );
        });

        return legendData;
    }

    protected processChartData(data: any) {
        let filteredData = filterInitDuplicateData(data);
        const newArr: any = [];
        const getAllLabels: any = [];

        filteredData.forEach((obj: any) => {
            getAllLabels.push(obj.Label);
        });
        const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

        filteredData.forEach((obj: any) => {
            const item = newArr.find((item: any) => item.name === obj.Name);
            if (newArr.length > 0 && item) {
                item.values.push(parseFloat(obj.Value));
                item.labels.push(obj.Label);
            } else {
                newArr.push(
                    {
                        name: obj.Name,
                        labels: [obj.Label],
                        values: [parseFloat(obj.Value)],
                    }
                )
            }
        });
        const finalChartData = addEmptyValuesToInitData(distinctedLabels, newArr);

        return finalChartData;
    }

    private getLineColors(data: any) {
        const lineColors: string[] = [];
        data.sort((a: any, b: any) => b.Value - a.Value);
        data.forEach((obj: any) => {
            const splittedTokens = (obj.RankBrandColor).split('|');
            lineColors.unshift(splittedTokens[2]);
        });

        return lineColors;
    }

    private distinctRepeatables = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
    };
}
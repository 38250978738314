import pptxgen from 'pptxgenjs';
import { BaseChartOptions as BaseChartOptions } from '../../../../../philips-constants';

import { UnaidedRecallSegmentCommunicationsChartTitleModel } from '../../../../../models/HealthSystems/StrategicPartnership/Marketing/UnaidedRecallSegmentCommunicationsChart/UnaidedRecallSegmentCommunicationsChartTitleModel';
import { UnaidedRecallSegmentCommunicationsChartDataModel } from '../../../../../models/HealthSystems/StrategicPartnership/Marketing/UnaidedRecallSegmentCommunicationsChart/UnaidedRecallSegmentCommunicationsChartDataModel';
import { UnaidedRecallSegmentCommunicationsChartSampleModel } from '../../../../../models/HealthSystems/StrategicPartnership/Marketing/UnaidedRecallSegmentCommunicationsChart/UnaidedRecallSegmentCommunicationsChartSampleModel';

import { BasePptxService } from '../../../../BasePptxService';
import { HSStrategicFirstSlideRenderer } from '../../../../Renderers/HSStrategicFirstSlideRenderer';
import { PptxRenderHelper } from '../../../../../helpers/PptxRenderHelper';

export class UnaidedRecallSegmentCommunicationsChartService extends BasePptxService {
    protected chartQuestion: string =
        "Q: From which of these vendors have you seen, heard or read any communication recently  about the solutions they offer recently in [-Clinical Segment]?";
    protected firstSlideRenderer: HSStrategicFirstSlideRenderer = new HSStrategicFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;
    protected sampleSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string, sampleSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
        this.sampleSheet = sampleSheet;
    }

    // Override of a super class method
    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const mappedTitleData = await this.getMappedChartData(UnaidedRecallSegmentCommunicationsChartTitleModel, this.titleSheet);

        mappedTitleData.forEach(obj => {
            this.chartTitle = `${obj.Title} ${obj.Country} - ${obj.Period}`;
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.55, bold: true });
        slide.addText([{ text: this.chartQuestion, options: { color: this.colors.black, fontSize: 7 } }], { x: 0.5, y: '95%', bold: false });
    }

    // Override of a super class method
    protected async addChartSlide(chartSlide: pptxgen.Slide): Promise<void> {
        const mappedChartData = await this.getMappedChartData(UnaidedRecallSegmentCommunicationsChartDataModel, this.dataSheet);
        const mappedSampleData = await this.getMappedChartData(UnaidedRecallSegmentCommunicationsChartSampleModel, this.sampleSheet);

        if (mappedChartData.length > 0) {
            const groupData: any[][] = [];

            // Sorting the mapped data in groups
            for (let obj of mappedChartData) {
                let makeNewGroupFlag = true;
                // Checks If there is group/array for this object
                for (let arr of groupData) {
                    for (let element of arr) {
                        if (element.Segment === obj.Segment) {
                            arr.push(obj);
                            makeNewGroupFlag = false;
                        }
                        break;
                    }
                }
                // Creates new group/array
                if (makeNewGroupFlag) {
                    groupData.push([obj]);
                }
            }

            groupData.forEach((group, index) => {
                let width = 8 / groupData.length;
                const showCatAxisLabel = index === 0;
                const x = index * width + (index >= 1 ? 1.5 : 1);
                const labelX = index === 0 ? x + 0.5 : x;

                if (index === 0) {
                    width += 0.5;
                }

                const sortedData = this.getSortedByOrder(group, (a, b) => (a.Order as number) - (b.Order as number));
                const mappedData = sortedData
                    .reduce((acc, curr) => ({
                        labels: [...acc.labels, curr.Brand],
                        values: [...acc.values, curr.Sum],
                    }), { labels: [] as string[], values: [] as number[] });

                const chartOptions: pptxgen.IChartOpts = {
                    ...BaseChartOptions,
                    x,
                    y: 1.4,
                    w: width,
                    h: 3,
                    barDir: 'bar',
                    showValue: true,
                    chartColors: PptxRenderHelper.getMappedChartDataColorsSP(group),
                    barGapWidthPct: 15,
                    valAxisLineShow: false,
                    valAxisHidden: true,
                    catAxisLabelPos: showCatAxisLabel ? 'low' : undefined,
                    catAxisHidden: !showCatAxisLabel,
                    dataLabelFormatCode: '0%',
                    dataLabelPosition: 'outEnd',
                    dataLabelFontBold: true,
                    dataLabelColor: this.colors.default,
                }

                chartSlide.addChart(this.presentation.ChartType.bar, [mappedData], chartOptions);

                // Segments
                let segment = "";
                group.forEach((obj) => {
                    if (obj.Segment) {
                        segment = obj.Segment;
                    }
                });
                chartSlide.addText(segment, { color: this.colors.default, fontSize: 8, bold: true, w: 1, h: 0.1, x: labelX + 0.25, y: 1.2, });

                // Samples
                if (mappedSampleData.length > 0) {
                    mappedSampleData.forEach((obj) => {
                        if (obj.Segment === segment) {
                            if (obj.SamplePositive) {
                                chartSlide.addText(`N = ${obj.SamplePositive}`, { color: this.colors.default, fontSize: 8, bold: false, w: 1, h: 0.1, x: labelX + 0.25, y: 1.4, });
                            }
                            if (obj.SampleNegative) {
                                chartSlide.addText(`N = ${obj.SampleNegative}`, { color: this.colors.negative, fontSize: 8, bold: false, w: 1, h: 0.1, x: labelX + 0.25, y: 1.4, });
                            }
                        }
                    });
                }
            });

            // Legend
            const legendData: UnaidedRecallSegmentCommunicationsChartDataModel[] = [];
            for (let obj of mappedChartData) {
                if (legendData.length > 0) {
                    for (let legendObj of legendData) {
                        if (obj.Segment === legendObj.Segment) {
                            legendData.push(obj);
                        }
                        break;
                    }
                } else {
                    legendData.push(obj);
                }
            }
            const sortedLegendData: UnaidedRecallSegmentCommunicationsChartDataModel[] = this.getSortedByOrder(legendData, (a, b) => (b.Order as number) - (a.Order as number));
            this.addLegend(chartSlide, sortedLegendData);
        }
    }

    private async addLegend(chartSlide: pptxgen.Slide, data: UnaidedRecallSegmentCommunicationsChartDataModel[]) {
        let y: number;
        let x = 0.5;

        const colors = PptxRenderHelper.getMappedChartDataColorsSP(data);

        data.forEach((order, index) => {
            if (index % 2 === 0) {
                y = 4.5;
                x += 1.5;
            }

            y += 0.15;

            chartSlide.addText(order.Brand, {
                color: this.colors.default,
                fontSize: 8,
                y: y,
                x: x + 0.15,
                h: 0.1,
                w: 2,
            });

            chartSlide.addText('■', {
                color: colors[index],
                fontSize: 12,
                y: y,
                x: x,
                h: 0.1,
                w: 0.1,
            });
        });
    }
}

import { IDataMapping } from '../../IDataMapping';

export class PreferenceOverTimeComparisonTitleModel implements IDataMapping<PreferenceOverTimeComparisonTitleModel> {
    public Title: string = '';
    public SubTitle: string = '';
    public Market: string = '';
    public getMappings?() {
        return {
            Title: "POT Comparison Title pipe",
            SubTitle: "AGG(POT Comaprison Sub Title pipe)",
            Market: "Answer",
        };
    }
}

import pptxgen from 'pptxgenjs';
import { SingleSPGlobalModel, SPGlobalModel } from '../../../';
import { DoughnutChartPptxService } from '../../DoughnutChartPptxService';
import { HSStrategicFirstSlideRenderer } from '../../Renderers/HSStrategicFirstSlideRenderer';
import { PptxRenderHelper } from '../../../helpers/PptxRenderHelper';

export class SPGlobalPptxService extends DoughnutChartPptxService {
    protected chartQuestion: string = 'Q: Across the entire hospital (and/or hospital network), ' +
        'are there any medical device or healthcare technology vendors that stand out for you in terms of ' +
        'makring an effort to partner with you to meet your challenges?\r' +
        'Q: Which vendor do you think of a stratetigc parnter/your main strategic partner across the entire hospital/or hospital netowork?';

    protected spAnswerColors = ['0066a1', '1e9d8b', '93eade', 'bab0ac'];
    protected firstSlideRenderer: HSStrategicFirstSlideRenderer = new HSStrategicFirstSlideRenderer();
    protected baseChartOptions: pptxgen.IChartOpts = {
        y: 1.5, w: 4.5, h: 3,
        layout: { x: 0, y: 0.2, w: 0.7, h: 0.7 },
        showTitle: true,
    };

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        await this.addFirstChart(chartSlide);
        await this.addSecondChart(chartSlide);
    }

    private async addFirstChart(chartSlide: pptxgen.Slide) {
        const data = await this.getMappedChartData(SPGlobalModel, 'Hospital/IDN level Pie');
        const chartTitle = 'Strategic Partnership';
        const chartData = data.filter(d => d.AveragePositive === null && d.AverageNegative == null);

        const dataChartPieStat = [
            {
                name: chartTitle,
                labels: chartData.map(x => x.Answer),
                values: chartData.map(x => (Math.round(x.Sum ? (x.Sum * 100) : 0)))
            }
        ];

        const chartOptions: pptxgen.IChartOpts = Object.assign(this.baseChartOptions,
            {
                x: 0.5,
                chartColors: this.spAnswerColors,
                title: chartTitle
            }
        );

        this.addChart(chartSlide, dataChartPieStat, chartOptions);
        PptxRenderHelper.addPieChartAvarageColorized(chartSlide, this.getAverage(data), chartOptions);
    }

    private async addSecondChart(chartSlide: pptxgen.Slide) {
        const data = await this.getMappedChartData(SingleSPGlobalModel, 'Single/Main SP');
        const chartTitle = 'Single/ Main Vendor Partner';
        let chartData = data.filter(d => d.AveragePositive === null && d.AverageNegative == null);
        chartData = this.getSortedByOrder(chartData);

        const dataChartPieStat = [
            {
                name: chartTitle,
                labels: chartData.map(x => x.Brand),
                values: chartData.map(x => (Math.round(x.Sum ? (x.Sum * 100) : 0)))
            }
        ];

        const chartColors = this.getMappedChartDataColors(chartData);
        const chartOptions: pptxgen.IChartOpts = Object.assign(this.baseChartOptions,
            {
                x: 5,
                chartColors,
                title: chartTitle
            }
        );

        this.addChart(chartSlide, dataChartPieStat, chartOptions);
        PptxRenderHelper.addPieChartAvarageColorized(chartSlide, this.getAverage(data), chartOptions);
    }

    private getAverage(data: Array<{ AveragePositive: number | null, AverageNegative: number | null }>) {
        return data.firstOrNull(d => d.AveragePositive !== null || d.AverageNegative !== null);
    }
}

import { IDataMapping } from '../../IDataMapping';

export class CommonBarChartTitleModel implements IDataMapping<CommonBarChartTitleModel> {
    public Title: string = '';
    public Country: string = '';
    public Category: string = '';
    public getMappings?() {
        return {
            Title: "Recommendations Titles",
            Country: "country_name",
            Category: "Category Short",
        };
    }
}

import { IDataMapping } from "../../../../IDataMapping";

export class NPSTrendsBarChartDataModel implements IDataMapping<NPSTrendsBarChartDataModel> {
    public Rank: number = Number(0);
    public Brand: string = '';
    public Leadership: string = '';
    public ValueNPS: number = Number(0);
    public Base: number = Number(0);
    public Promoters: number = Number(0);
    public Passive: number = Number(0);
    public Detractor: number = Number(0);
    public getMappings?() {
        return {
            Rank: 'rank Int',
            Brand: 'Brand',
            Leadership: 'Leadership',
            ValueNPS: 'SUM(Value)',
            Base: 'SUM(Calc Last Period)',
            Promoters: 'AVG(% Promoters)',
            Passive: 'AVG(% Passive)',
            Detractor: 'AVG(% Detractor)',
        };
    }
}
import { IDataMapping } from '../../IDataMapping';

export class ProductLevelNPSDriverDeltaDataModel implements IDataMapping<ProductLevelNPSDriverDeltaDataModel> {
    public Driver: string = '';
    public SubDriver: string = '';
    public Country: string = '';
    public CountryOrder: number | null = 0;
    public Value: number | null = 0;
    public BgColor: string = '';
    public Arrow: string = '';
    public getMappings?() {
        return {
            Driver: 'calc Driver Swap (NET)',
            SubDriver: 'calc Driver Swap',
            Country: 'Country',
            CountryOrder: 'MIN(Calc Country Order for Attributes)',
            Value: 'AVG(Calc Delta)',
            BgColor: 'AGG(Calc Coloration)',
            Arrow: 'ATTR(Shapes Up/Down)'
        };
    }
}

import { IDataMapping } from "../../../../IDataMapping";

export class BSAnalysisByAudienceTableTitleModel implements IDataMapping<BSAnalysisByAudienceTableTitleModel> {
    public Title:       string = '';
    public SubTitle:    string = '';

    public getMappings?() {
        return {
            Title:      'Brand Strength Analysis Ttitle (copy)',
            SubTitle:   'Brand Strength Analysis Sub-Ttitle & Aud'
        };
    }
}

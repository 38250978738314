
export type CustomViewFormModel = {
    name: string;
    isMyDefault: boolean;
    isPublic: boolean;
    myViews: Array<{name: string, isPublic: boolean, isDefault: boolean, author: string}>;
    otherViews: Array<{name: string, isPublic: boolean, isDefault: boolean, author: string}>;
};

export const getInitialCustomView = (): CustomViewFormModel => {
    return {
        name: '',
        isMyDefault: false,
        isPublic: false,
        myViews: [],
        otherViews: []
    };
};

import { IDataMapping } from '../../IDataMapping';

export class CompetitorsPerMarketFirstTableModel implements IDataMapping<CompetitorsPerMarketFirstTableModel> {
    public Category: string = '';
    public Brand: string = '';
    public OtherSort: number = Number(0);
    public Value: number = Number(0);
    public BrandValue: number = Number(0);
    public BrandOrder: number = Number(0);
    public TTL: string = '';
    public getMappings?() {
        return {
            Category: "Category",
            Brand: 'Brand ',
            OtherSort: 'Other sort',
            Value: 'Philips color',
            BrandValue: 'AGG(Brand RS value)',
            BrandOrder: 'AGG(INDEX())',
            TTL: "AGG(TTL)",
        };
    }
}

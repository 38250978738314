import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import history from '../../history';
import styles from './ApiAuthorization.module.scss';

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            showConfirmDialog: false,
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([
            authService.isAuthenticated(),
            authService.getUser(),
        ]);
        this.setState({
            isAuthenticated,
            userName: user && user.name,
        });
    }

    openConfirmDialog() {
        this.setState({ showConfirmDialog: true });
    }

    closeConfirmationDialog() {
        this.setState({ showConfirmDialog: false });
    }

    confirmLogout(logoutPath) {
        this.setState({ showConfirmDialog: false });
        history.push(logoutPath);
    }

    dialogButtons(logoutPath, t) {
        return (
            <div>
                <Button
                    label={t('cancel')}
                    onClick={() => this.closeConfirmationDialog()}
                    className={styles.cancel_button}
                />
                <Button
                    label={t('yes')}
                    onClick={() => this.confirmLogout(logoutPath)}
                    className={styles.ok_button}
                />
            </div>
        );
    }

    render() {
        const { isAuthenticated } = this.state;
        const { t, isMobile } = this.props;
        if (!isAuthenticated) {
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(loginPath, t);
        } else {
            const twoFactorAuthPath = `${ApplicationPaths.TwoFactorAuth}`;
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = {
                pathname: `${ApplicationPaths.LogOut}`,
                state: { local: true },
            };
            return this.authenticatedView(twoFactorAuthPath, profilePath, logoutPath, t);
        }
    }

    authenticatedView(twoFactorAuthPath, profilePath, logoutPath, t) {
        return (
            <Fragment>
                <ul className={styles.account_actions}>
                    <li>
                        <a
                            href={undefined}
                            onClick={() =>
                                history.push(`${twoFactorAuthPath}`)
                            }
                        >
                            <p>Two Factor Authentication</p>
                        </a>
                    </li>
                    <li>
                        <a
                            href={undefined}
                            onClick={() =>
                                history.push(`${profilePath}?returnUrl=${window.location.href}`)
                            }
                        >
                            <p>Change Password</p>
                        </a>
                    </li>
                    <li>
                        <a href={undefined} onClick={() => this.openConfirmDialog()}>
                            <p>Logout</p>
                        </a>
                    </li>
                </ul>
                {/* Mobile */}
                <div className={styles.mobile_account_actions}>
                    <p
                        onClick={() =>
                            history.push(`${profilePath}?returnUrl=${window.location.href}`)
                        }
                    >
                        <img src='/icons/key.svg' alt='key'></img><span>Change Password</span>
                    </p>
                    <p onClick={() => this.openConfirmDialog()}>
                        <img src='/icons/log-out.svg' alt='log-out-icon'></img>
                        <span>Logout</span>
                    </p>
                </div>
                <Dialog
                    header={'Log out'}
                    visible={this.state.showConfirmDialog}
                    modal={true}
                    onHide={() => this.closeConfirmationDialog()}
                    className={`${styles.default_dialog} ${styles.logout_dialog}`}
                    appendTo={document.body}
                    footer={this.dialogButtons(logoutPath, t)}
                >
                    Are you sure you want to log out?
                </Dialog>
            </Fragment>
        );
    }

    anonymousView(loginPath) {
        return (
            <div className={styles.button_div}>
                <Link to={loginPath}>
                    <Button
                        type='button'
                        icon='pi pi-user'
                        tooltip='Login'
                        className={styles.icon_button}
                    />
                </Link>
            </div>
        );
    }
}

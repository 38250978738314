import { IDataMapping } from '../../../../IDataMapping';

export class RelativeProductNPSChartFooterModel implements IDataMapping<RelativeProductNPSChartFooterModel> {
    public text: string = '';

    public getMappings?() {
        return {
            text: 'Calc Countrty agg info',
        };
    }
}

import pptxgen from 'pptxgenjs';
import { BaseChartOptions } from '../philips-constants';
import { BasePptxService } from '../services/BasePptxService';

export abstract class DoughnutChartPptxService extends BasePptxService {

    protected async addChart(chartSlide: pptxgen.Slide, data: any[], options?: pptxgen.IChartOpts) {

        options = Object.assign({
            ...BaseChartOptions,
            x: 0.5, y: 0, w: 6, h: 6,
            chartColors: ['0066a1', '1e9d8b', '93eade', this.colors.neutral],
            dataBorder: { pt: 0.5, color: 'F1F1F1' },
            dataLabelPosition: 'outEnd',
            legendPos: 'r',
            showPercent: false,
            dataLabelFormatCode: '#\\%',
            showValue: true,
            showLegend: true,
            holeSize: 80,
        }, options);

        chartSlide.addChart(this.presentation.ChartType.doughnut, data, options);
    }
}
// tslint:disable-next-line: max-line-length
export const EmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ConflictStatusCode = 400;

export const UserStatusOptions: Array<{ label: string; value: number }> = [
  { label: 'Active', value: 1 },
  { label: 'Inactive', value: 2 },
];

export const CountryOptions: Array<{ label: string; value: number }> = [
  { label: 'None', value: 0 },
  { label: 'Afghanistan', value: 1 },
  { label: 'Albania', value: 2 },
  { label: 'Algeria', value: 3 },
  { label: 'Andorra', value: 4 },
  { label: 'Angola', value: 5 },
  { label: 'Antigua And Barbuda', value: 6 },
  { label: 'Argentina', value: 7 },
  { label: 'Armenia', value: 8 },
  { label: 'Australia', value: 9 },
  { label: 'Austria', value: 10 },
  { label: 'Azerbaijan', value: 11 },
  { label: 'Bahamas', value: 12 },
  { label: 'Bahrain', value: 13 },
  { label: 'Bangladesh', value: 14 },
  { label: 'Barbados', value: 15 },
  { label: 'Belarus', value: 16 },
  { label: 'Belgium', value: 17 },
  { label: 'Belize', value: 18 },
  { label: 'Benin', value: 19 },
  { label: 'Bhutan', value: 20 },
  { label: 'Bolivia', value: 21 },
  { label: 'Bosnia And Herzegovina', value: 22 },
  { label: 'Botswana', value: 23 },
  { label: 'Brazil', value: 24 },
  { label: 'Brunei', value: 25 },
  { label: 'Bulgaria', value: 26 },
  { label: 'Burkina Faso', value: 27 },
  { label: 'Burundi', value: 28 },
  { label: 'Cabo Verde', value: 29 },
  { label: 'Cambodia', value: 30 },
  { label: 'Cameroon', value: 31 },
  { label: 'Canada', value: 32 },
  { label: 'Central African Republic', value: 33 },
  { label: 'Chad', value: 34 },
  { label: 'Chile', value: 35 },
  { label: 'China', value: 36 },
  { label: 'Colombia', value: 37 },
  { label: 'Comoros', value: 38 },
  { label: 'Congo', value: 39 },
  { label: 'Costa Rica', value: 40 },
  { label: 'Cote DIvoire', value: 41 },
  { label: 'Croatia', value: 42 },
  { label: 'Cuba', value: 43 },
  { label: 'Cyprus', value: 44 },
  { label: 'Czechia', value: 45 },
  { label: 'Denmark', value: 46 },
  { label: 'Djibouti', value: 47 },
  { label: 'Dominica', value: 48 },
  { label: 'Dominican Republic', value: 49 },
  { label: 'Ecuador', value: 50 },
  { label: 'Egypt', value: 51 },
  { label: 'El Salvador', value: 52 },
  { label: 'Equatorial Guinea', value: 53 },
  { label: 'Eritrea', value: 54 },
  { label: 'Estonia', value: 55 },
  { label: 'Eswatini', value: 56 },
  { label: 'Ethiopia', value: 57 },
  { label: 'Fiji', value: 58 },
  { label: 'Finland', value: 59 },
  { label: 'France', value: 60 },
  { label: 'Gabon', value: 61 },
  { label: 'Gambia', value: 62 },
  { label: 'Georgia', value: 63 },
  { label: 'Germany', value: 64 },
  { label: 'Ghana', value: 65 },
  { label: 'Greece', value: 66 },
  { label: 'Grenada', value: 67 },
  { label: 'Guatemala', value: 68 },
  { label: 'Guinea', value: 69 },
  { label: 'Guinea Bissau', value: 70 },
  { label: 'Guyana', value: 71 },
  { label: 'Haiti', value: 72 },
  { label: 'Honduras', value: 73 },
  { label: 'Hungary', value: 74 },
  { label: 'Iceland', value: 75 },
  { label: 'India', value: 76 },
  { label: 'Indonesia', value: 77 },
  { label: 'Iran', value: 78 },
  { label: 'Iraq', value: 79 },
  { label: 'Ireland', value: 80 },
  { label: 'Israel', value: 81 },
  { label: 'Italy', value: 82 },
  { label: 'Jamaica', value: 83 },
  { label: 'Japan', value: 84 },
  { label: 'Jordan', value: 85 },
  { label: 'Kazakhstan', value: 86 },
  { label: 'Kenya', value: 87 },
  { label: 'Kiribati', value: 88 },
  { label: 'Kosovo', value: 89 },
  { label: 'Kuwait', value: 90 },
  { label: 'Kyrgyzstan', value: 91 },
  { label: 'Laos', value: 92 },
  { label: 'Latvia', value: 93 },
  { label: 'Lebanon', value: 94 },
  { label: 'Lesotho', value: 95 },
  { label: 'Liberia', value: 96 },
  { label: 'Libya', value: 97 },
  { label: 'Liechtenstein', value: 98 },
  { label: 'Lithuania', value: 99 },
  { label: 'Luxembourg', value: 100 },
  { label: 'Madagascar', value: 101 },
  { label: 'Malawi', value: 102 },
  { label: 'Malaysia', value: 103 },
  { label: 'Maldives', value: 104 },
  { label: 'Mali', value: 105 },
  { label: 'Malta', value: 106 },
  { label: 'Marshall Islands', value: 107 },
  { label: 'Mauritania', value: 108 },
  { label: 'Mauritius', value: 109 },
  { label: 'Mexico', value: 110 },
  { label: 'Micronesia', value: 111 },
  { label: 'Moldova', value: 112 },
  { label: 'Monaco', value: 113 },
  { label: 'Mongolia', value: 114 },
  { label: 'Montenegro', value: 115 },
  { label: 'Morocco', value: 116 },
  { label: 'Mozambique', value: 117 },
  { label: 'Myanmar', value: 118 },
  { label: 'Namibia', value: 119 },
  { label: 'Nauru', value: 120 },
  { label: 'Nepal', value: 121 },
  { label: 'Netherlands', value: 122 },
  { label: 'New Zealand', value: 123 },
  { label: 'Nicaragua', value: 124 },
  { label: 'Niger', value: 125 },
  { label: 'Nigeria', value: 126 },
  { label: 'North Korea', value: 127 },
  { label: 'North Macedonia', value: 128 },
  { label: 'Norway', value: 129 },
  { label: 'Oman', value: 130 },
  { label: 'Pakistan', value: 131 },
  { label: 'Palau', value: 132 },
  { label: 'Palestine', value: 133 },
  { label: 'Panama', value: 134 },
  { label: 'Papua New Guinea', value: 135 },
  { label: 'Paraguay', value: 136 },
  { label: 'Peru', value: 137 },
  { label: 'Philippines', value: 138 },
  { label: 'Poland', value: 139 },
  { label: 'Portugal', value: 140 },
  { label: 'Qatar', value: 141 },
  { label: 'Romania', value: 142 },
  { label: 'Russia', value: 143 },
  { label: 'Rwanda', value: 144 },
  { label: 'Saint Kitts And Nevis', value: 145 },
  { label: 'Saint Lucia', value: 146 },
  { label: 'Saint Vincent And The Grenadines', value: 147 },
  { label: 'Samoa', value: 148 },
  { label: 'San Marino', value: 149 },
  { label: 'Sao Tome And Principe', value: 150 },
  { label: 'Saudi Arabia', value: 151 },
  { label: 'Senegal', value: 152 },
  { label: 'Serbia', value: 153 },
  { label: 'Seychelles', value: 154 },
  { label: 'Sierra Leone', value: 155 },
  { label: 'Singapore', value: 156 },
  { label: 'Slovakia', value: 157 },
  { label: 'Slovenia', value: 158 },
  { label: 'Solomon Islands', value: 159 },
  { label: 'Somalia', value: 160 },
  { label: 'South Africa', value: 161 },
  { label: 'South Korea', value: 162 },
  { label: 'South Sudan', value: 163 },
  { label: 'Spain', value: 164 },
  { label: 'Sri Lanka', value: 165 },
  { label: 'Sudan', value: 166 },
  { label: 'Suriname', value: 167 },
  { label: 'Switzerland', value: 168 },
  { label: 'Syria', value: 169 },
  { label: 'Taiwan', value: 170 },
  { label: 'Tajikistan', value: 171 },
  { label: 'Tanzania', value: 172 },
  { label: 'Thailand', value: 173 },
  { label: 'Timor Leste', value: 174 },
  { label: 'Togo', value: 175 },
  { label: 'Tonga', value: 176 },
  { label: 'Trinidad And Tobago', value: 177 },
  { label: 'Tunisia', value: 178 },
  { label: 'Turkey', value: 179 },
  { label: 'Turkmenistan', value: 180 },
  { label: 'Tuvalu', value: 181 },
  { label: 'Uganda', value: 182 },
  { label: 'Ukraine', value: 183 },
  { label: 'United Arab Emirates', value: 184 },
  { label: 'United Kingdom', value: 185 },
  { label: 'United States Of America', value: 186 },
  { label: 'Uruguay', value: 187 },
  { label: 'Uzbekistan', value: 188 },
  { label: 'Vanuatu', value: 189 },
  { label: 'Vatican City', value: 190 },
  { label: 'Venezuela', value: 191 },
  { label: 'Vietnam', value: 192 },
  { label: 'Yemen', value: 193 },
  { label: 'Zambia', value: 194 },
  { label: 'Zimbabwe', value: 195 },
  { label: 'Sweden', value: 196 },
];

export const PhilipsEnvironments = {
  Localhost: 'localhost',
  Test: 'hs-test.philips-accelerator.com',
  Prod: 'hs.philips-accelerator.com',
};

export const PersonalHealthPhilipsEnvironments = {
  Localhost: 'localhost',
  Test: 'ph-test.philips-accelerator.com',
  Prod: 'ph.philips-accelerator.com',
};

export const SrcPhilipsEnvironments = {
    Localhost: 'localhost',
    Test: 'src-test.philips-accelerator.com',
    Prod: 'src.philips-accelerator.com',
};
import React from 'react';
import { ISVGProps } from './ISVGProps';

export const WordSvg = (props: ISVGProps) => {
  const { width, height } = props;
  return (
    <svg
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
    >
      <g id='Word'>
        <g data-name='&lt;Group&gt;' id='_Group_'>
          <polygon
            data-name='&lt;Path&gt;'
            id='_Path_'
            points='13.5 0.5 0.5 3 0.5 21 13.5 23.5 13.5 0.5'
            style={{
              fill: 'none',
              stroke: '#303c42',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
          <polyline
            data-name='&lt;Path&gt;'
            id='_Path_2'
            points='13.5 3.5 23.5 3.5 23.5 20.5 13.5 20.5'
            style={{
              fill: 'none',
              stroke: '#303c42',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
          <line
            data-name='&lt;Path&gt;'
            id='_Path_3'
            style={{
              fill: 'none',
              stroke: '#303c42',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
            x1='13.5'
            x2='21.5'
            y1='6'
            y2='6'
          />
          <line
            data-name='&lt;Path&gt;'
            id='_Path_4'
            style={{
              fill: 'none',
              stroke: '#303c42',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
            x1='13.5'
            x2='21.5'
            y1='9'
            y2='9'
          />
          <line
            data-name='&lt;Path&gt;'
            id='_Path_5'
            style={{
              fill: 'none',
              stroke: '#303c42',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
            x1='13.5'
            x2='21.5'
            y1='12'
            y2='12'
          />
          <line
            data-name='&lt;Path&gt;'
            id='_Path_6'
            style={{
              fill: 'none',
              stroke: '#303c42',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
            x1='13.5'
            x2='21.5'
            y1='15'
            y2='15'
          />
          <line
            data-name='&lt;Path&gt;'
            id='_Path_7'
            style={{
              fill: 'none',
              stroke: '#303c42',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
            x1='13.5'
            x2='21.5'
            y1='18'
            y2='18'
          />
          <polyline
            data-name='&lt;Path&gt;'
            id='_Path_8'
            points='3 9 4.5 15 6.5 8.5 8.5 15.5 10.5 8'
            style={{
              fill: 'none',
              stroke: '#303c42',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </g>
      </g>
    </svg>
  );
};

import { TableCellModel } from '../TableCellModel';

export class ProductLevelNPSDriverDeltaRowModel {
    public Driver: TableCellModel = new TableCellModel();
    public SubDriver: TableCellModel = new TableCellModel();

    constructor(driver: string, subDriver: string, options: any) {
        this.Driver = new TableCellModel(driver, options);
        this.SubDriver = new TableCellModel(subDriver, options);
    }
}

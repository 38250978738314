import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ListViewControls } from '../../common/ListViewControls/ListViewControls';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RoleViewModel } from '../../../models/Roles/RoleViewModel';
import styles from './RolesView.module.scss';
import sharedStyles from '../../../shared/styles/SharedStyles.module.scss';
import { RolePermissionViewModel } from '../../../models/Roles/RolePermissionViewModel';
import RoleActionsDisplay, { RoleActions } from '../../../shared/enums/RoleActions';
import { RoleFormDialog } from '../role-form/RoleFormDialog';
import { Sections } from '../../../shared/enums/Sections';
import { CreateRoleModel } from '../../../models/Roles/CreateRoleModel';
import { EditRoleModel } from '../../../models/Roles/EditRoleModel';
import { FormType } from '../../../shared/enums/FormType';
import { getInitialFormRole } from '../../../models/Roles/CreateRoleModel';
import api from '../../../helpers/Api';
import layoutMessage from '../../../helpers/LayoutMessage';
import { Footer } from '../../common/Footer/Footer';
import { PopupMenu } from '../../common/PopupMenu/PopupMenu';
import { MenuItem } from 'primereact/components/menuitem/MenuItem';
import { ApplicationUserViewModel } from '../../../models/User/ApplicationUserViewModel';
import { IState } from '../../../store/IState';
import history from '../../../history';

export const RolesView = (): JSX.Element => {
  const dispatch = useDispatch();

  const userProfile: ApplicationUserViewModel | undefined = useSelector(
    (state: IState) => state.UserReducer.userProfile
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [globalFilter, setGlobalFilter] = useState<string>('');

  const [roles, setRoles] = useState<RoleViewModel[]>([]);

  const [formRole, setFormRole] = useState<CreateRoleModel | EditRoleModel | undefined>(undefined);

  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

  const [formType, setFormType] = useState<FormType | undefined>(undefined);

  const getRoles = async () => {
    await api.get<RoleViewModel[]>(
      'Roles/GetAll',
      (data) => {
        data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
        setRoles(data);
      },
      (errorMessage, status) => status === 403 ? history.push('/') : layoutMessage.renderError(dispatch, errorMessage)
    );

    setIsLoading(false);
  };

  const onFormOpen = (formTypeToOpen: FormType, role: CreateRoleModel | EditRoleModel | undefined) => {
    setFormRole(role);
    setFormType(formTypeToOpen);
    setIsFormOpen(true);
  };

  const onFormClose = () => {
    setIsFormOpen(false);
    setFormType(undefined);
    setFormRole(undefined);
  };

  const onGlobalFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setGlobalFilter(value);
  };

  const getRoleActionAvailability = (value: React.ReactText, roleAction: RoleActions) => {
    return (Number(value) & roleAction) !== 0 ? RoleActionsDisplay[roleAction] : '';
  };

  const getPermissionsColumnBody = (rowData: RoleViewModel, column: any): JSX.Element => {
    const permissionsObject: RolePermissionViewModel | undefined = rowData.rolePermission;
    if (permissionsObject) {
      const field: keyof RolePermissionViewModel = column.field;
      const value = permissionsObject[field];
      if (value && !isNaN(Number(value))) {
        const allowedRoleActions: string[] = Object.keys(RoleActions)
          .map(a => getRoleActionAvailability(value, RoleActions[a as keyof typeof RoleActions]))
          .filter(a => a);
        return <span>{`${allowedRoleActions.join('; ')}`}</span>;
      }
    }

    return <React.Fragment></React.Fragment>;
  };

  const hasUserPermission = (permission: RoleActions) => userProfile?.userRoles.some(x => (x.rolePermission.userRolesSection & permission) !== 0);

  const getRoleMenuItems = (rowData: any): MenuItem[] => {
    const items: MenuItem[] = [];

    if (hasUserPermission(RoleActions.Edit)) {
      items.push({ label: FormType.Edit, command: () => onFormOpen(FormType.Edit, rowData) });
    }

    if (hasUserPermission(RoleActions.Delete)) {
      items.push({ label: FormType.Delete, command: () => onFormOpen(FormType.Delete, rowData) });
    }

    return items;
  };

  useEffect(() => {
    getRoles();
  }, []);

  if (isLoading) {
    return (
      <div className={styles.centered_on_screen}>
        <ProgressSpinner />
      </div>
    );
  }

  return (
    <div>
      <ListViewControls
        searchValue={globalFilter}
        addLabel={'CREATE USER ROLE'}
        addButtonVisible={hasUserPermission(RoleActions.Add)}
        onSearch={onGlobalFilterChange}
        onAdd={() => onFormOpen(FormType.Create, getInitialFormRole())} />
      <DataTable
        className={`${styles.roles_table} ${sharedStyles.data_table}`}
        value={roles}
        responsive={true}
        rows={10}
        paginator={true}
        globalFilter={globalFilter}
        alwaysShowPaginator={false}
      >
        <Column field='name' header='NAME' />
        {Object.keys(Sections).map((k) =>
          <Column
            key={k}
            field={k}
            header={Sections[k as keyof typeof Sections].toUpperCase()}
            body={getPermissionsColumnBody}
          />)}
        <Column
          field='action'
          className={sharedStyles.small_column}
          body={(rowData: RoleViewModel, column: any) => {
            return <PopupMenu id={`role_popup_menu_${rowData.rolePermission?.id}`} menuItems={getRoleMenuItems(rowData)} />;
          }} />
      </DataTable>
      {formType && formRole
        ? (<RoleFormDialog
          formType={formType}
          isOpen={isFormOpen}
          formRole={formRole}
          roles={roles}
          onFormClose={onFormClose}
          onActionSuccess={() => {
            onFormClose();
            getRoles();
          }}
        />)
        : null}
    </div>
  );
};

import pptxgen from 'pptxgenjs';

import { BMCOverTimeLineChartTitleModel } from '../../../models/PersonalHealth/BMCOverTimeLineChart/BMCOverTimeLineChartTitleModel';
import { BMCOverTimeLineChartDataModel } from '../../../models/PersonalHealth/BMCOverTimeLineChart/BMCOverTimeLineChartDataModel';

import { PHFirstSlideRenderer } from '../../Renderers/PHFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

import { filterInitDuplicateData, addEmptyValuesToInitData } from "../../../../shared/utility";

export class BMCOverTimeLineChartService extends BasePptxService {
  protected chartQuestion: string = '';
  protected firstSlideRenderer: PHFirstSlideRenderer = new PHFirstSlideRenderer();
  protected titleSheet: string;
  protected dataSheet: string;

  constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
    super(view, chartTitle);
    this.titleSheet = titleSheet;
    this.dataSheet = dataSheet;
  }

  protected async setChartSlideLayout(slide: pptxgen.Slide) {
    // Override title's options and add filter's name in it.
    const titleData = await this.getMappedChartData(BMCOverTimeLineChartTitleModel, this.titleSheet);
    let subTitle: string = "";

    titleData.map(obj => {
      this.chartTitle = obj.Period ? `${obj.Title} - ${obj.Period}` : obj.Title;
      subTitle = obj.SubTitle;
      if (obj.Filter) {
        subTitle += " - " + obj.Filter;
      }      
      return subTitle;
    });
    slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.35, bold: true });
    slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.58, italic: true });
  }

  protected async addChartSlide(chartSlide: pptxgen.Slide) {
    const mappedData = await this.getMappedChartData(BMCOverTimeLineChartDataModel, this.dataSheet);
    let filteredData = filterInitDuplicateData(mappedData);

    const sortedLineChartData = filteredData.sort(function (a: any, b: any) {
      return a.Rank - b.Rank;
    });

    const newArr: any = [];
    const lineColors: string[] = [];
    const getAllLabels: any = [];

    sortedLineChartData.forEach((obj: any) => {
      getAllLabels.push(obj.Label);
    });

    const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

    sortedLineChartData.forEach((obj: any) => {
      lineColors.push(obj.Color)
      const item = newArr.find((item: any) => item.name === obj.Name);
      if (newArr.length > 0 && item) {
        if (item) {
          item.values.push(parseFloat(obj.Value));
          item.labels.push(obj.Label);
        }
      } else {
        newArr.push(
          {
            name: obj.Name,
            labels: [obj.Label],
            values: [parseFloat(obj.Value)],
          }
        )
      }
    });

    const finalChartData = addEmptyValuesToInitData(distinctedLabels, newArr);
    chartSlide.addChart(
      this.presentation.ChartType.line,
      finalChartData,
      {
        x: 1,
        y: 1,
        w: "70%",
        h: "70%",
        showLegend: true,
        legendPos: 'b',
        dataLabelPosition: 't',
        dataLabelFormatCode: '0%',
        valAxisLabelFormatCode: '0%',
        catAxisLabelRotate: this.labelRotateDegree(newArr),
        chartColors: lineColors.filter(this.distinctRepeatables),
        valAxisLabelFontSize: 10,
        catAxisLabelFontSize: 10,
      }
    );

  }

  protected labelRotateDegree(array: any) {
    let degree: number = 0;
    array.forEach((obj: any) => {
      if (obj.labels.length > 10) {
        degree = 45;
      } else {
        degree = 0;
      }
    });
    return degree;
  }

  private distinctRepeatables = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };
}

import { IDataMapping } from '../../IDataMapping';

export class DriverAnalysisBarChartDataModel implements IDataMapping<DriverAnalysisBarChartDataModel> {
    public Name: string = '';
    public Label: string = '';
    public Color: string = '';
    public Value: number = Number(0);
    public Rank: number = Number(0);
    public Note: string = '';
    public NoteSS: string = '';
    public getMappings?() {
        return {
            Name: 'ATTR(Answer_Text (SS))',
            Label: 'Brand',
            Color: 'AGG(BrandColors)',
            Value: 'MAX(Value)',
            Rank: 'AGG(INDEX())',
            Note: 'ATTR(Low sample label)',
            NoteSS: 'ATTR(SS)',
        };
    }
}

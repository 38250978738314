import { IDataMapping } from '../../IDataMapping';

export class MCCPreferenceScoreTitleModel implements IDataMapping<MCCPreferenceScoreTitleModel> {
    public Title: string = '';
    public Country: string = '';
    public Category: string = '';
    public getMappings?() {
        return {
            Title: 'Preference Title',
            Country: "country_name",
            Category: "Category Short",
        };
    }
}

import pptxgen from 'pptxgenjs';
import { SingleSPMarketOverviewModel } from '../../../';
import { PptxRenderHelper } from '../../../helpers/PptxRenderHelper';
import { BaseChartOptions } from '../../../philips-constants';
import { BasePptxService } from '../../BasePptxService';
import { HSStrategicFirstSlideRenderer } from '../../Renderers/HSStrategicFirstSlideRenderer';

export class SingleSPMarketOverviewPptxService extends BasePptxService {
    protected chartQuestion: string = 'Q: Which vendor do you think of a stratetigc parnter/your ' +
        'main strategic partner across the entire hospital/or hospital netowork?';
    protected firstSlideRenderer: HSStrategicFirstSlideRenderer = new HSStrategicFirstSlideRenderer();

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        let data = await this.getMappedChartData(SingleSPMarketOverviewModel, 'Single/Main SP at Hospital/IDN level');
        const averages = data.filter(x => x.Brand === null);

        data = data.filter(x => x.Brand !== null);

        if (!data || !data.length) {
            return;
        }

        const missingOrderData = data.filter(x => x.BrandOrder === null);

        if (missingOrderData) {
            const maxOrder = Math.max(
                ...data.map(x => x.BrandOrder as number)
            );

            missingOrderData.forEach((x, i) => x.BrandOrder = maxOrder - 1 + i);
        }

        let brands = data.distinctBy(d => d.Brand);
        brands = brands.sort((a, b) => (a.BrandOrder as number) - (b.BrandOrder as number));

        const countries = data.map(ch => ch.Country).distinct().filter(x => x);
        const dataChartBarStat: Array<{ name: string, labels: string[], values: Array<number | null> }> = brands.map(a => ({
            name: a.Brand,
            labels: countries,
            values: countries.map(x => null)
        }));

        data.forEach(ch => {
            const currentChartData = dataChartBarStat.firstOrNull(d => d.name === ch.Brand);
            if (currentChartData) {
                const labelIndex = currentChartData.labels.indexOf(ch.Country);
                currentChartData.values[labelIndex] = ch.Sum;
            }
        });

        const chartColors = PptxRenderHelper.getMappedChartDataColorsSP(brands);
        const chartOptions: pptxgen.IChartOpts = {
            ...BaseChartOptions,
            x: 1, y: 1, w: 8, h: 4.2,
            barDir: 'col',
            barGrouping: 'stacked',
            layout: { h: 0.8 },
            chartColors,
            catAxisOrientation: 'minMax',
            catAxisLineShow: false,
            valAxisOrientation: 'maxMin',
            showValue: true,
            dataLabelFormatCode: '#%',
            valAxisHidden: true,
            showLegend: true,
            legendPos: 'b',
        } as any;
        chartSlide.addChart(this.presentation.ChartType.bar, dataChartBarStat, chartOptions);

        const barWidth = Number(chartOptions.w) / countries.length;
        countries.forEach((country, index) => {
            const average = averages.firstOrNull(a => a.Country === country);
            PptxRenderHelper.addBarChartAvarageColorized(chartSlide, average, chartOptions, index, barWidth);
        });
    }
}

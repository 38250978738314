import { IDataMapping } from '../../IDataMapping';

export class DriverAnalysisTitleModel implements IDataMapping<DriverAnalysisTitleModel> {
    public Title: string = '';
    public TitleCategory: string = '';
    public TitleDate: string = '';
    public TitleSurvey: string = '';
    public getMappings?() {
        return {
            Title: 'Driver Analysis Title Export',
            TitleCategory: 'Category',
            TitleDate: 'Date',
            TitleSurvey: 'Survey',
        };
    }
}

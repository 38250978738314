import { IDataMapping } from '../../IDataMapping';

export class RecommendedBrandsTitleModel implements IDataMapping<RecommendedBrandsTitleModel> {
    public Title: string = '';
    public Country: string = '';
    public Category: string = '';
    public Filter: string = '';
    public getMappings?() {
        return {
            Title: 'Recommendations Titles',
            Country: "country_name",
            Category: "Category Short",            
            Filter: 'ATTR(indicator_name)',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class RecommendationsDataModel implements IDataMapping<RecommendationsDataModel> {
    public Value: number = Number(0);
    public getMappings?() {
        return {
            Value: 'SUM(indicator_value)',
        };
    }
}

import React from 'react';
import styles from './VideosComponent.module.scss';
import { IVideosComponentProps } from './IVideosComponentProps';
import { Dialog } from 'primereact/dialog';
import ReactPlayer from 'react-player';

export const VideosComponent = (props: IVideosComponentProps): JSX.Element => {
  const { playerIsOpen, video, handleTogglePlayer } = props;

  const onPlayerHide = () => {
    handleTogglePlayer(false);
  };

  return (
    <React.Fragment>
      <Dialog visible={playerIsOpen} onHide={onPlayerHide} header={video.name}>
        <div className={styles.videos_row}>
          <div className={styles.video_container}>
            <ReactPlayer
              url={encodeURI(
                `Files/GetVideoFile/${video.fullPath}`
              )}
              playing={true}
              controls={true}
              width={'100%'}
              height={'100%'}
            />
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

import pptxgen from 'pptxgenjs';

import { ProductNPSResultsTitleModel } from '../../../models/SRC/ProductNPSResults/ProductNPSResultsTitleModel';
import { ProductNPSResultsDataModel } from '../../../models/SRC/ProductNPSResults/ProductNPSResultsDataModel';
import { ProductNPSResultsTableDataModel } from '../../../models/SRC/ProductNPSResults/ProductNPSResultsTableDataModel';

import { ProductNPSResultsMonthlyDataModel } from '../../../models/SRC/ProductNPSResults/ProductNPSResultsMonthlyDataModel';
import { ProductNPSResultsTableMonthlyDataModel } from '../../../models/SRC/ProductNPSResults/ProductNPSResultsTableMonthlyDataModel';

import { SRCFirstSlideRenderer } from '../../Renderers/SRCFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

import { filterInitDuplicateData, addEmptyValuesToInitData } from "../../../../shared/utility";

export class ProductNPSResultsService extends BasePptxService {
    protected chartQuestion: string = '';
    protected firstSlideRenderer: SRCFirstSlideRenderer = new SRCFirstSlideRenderer();
    protected titleSheet: string;
    protected barNPSDataSheet: string;
    protected tableNPSDataSheet: string;
    protected titleMonthlySheet: string;
    protected barNPSMonthlyDataSheet: string;
    protected tableNPSMonthlyDataSheet: string;


    constructor(view: any, chartTitle: string, titleSheet: string, barNPSDataSheet: string, tableNPSDataSheet: string,
        titleMonthlySheet: string, barNPSMonthlyDataSheet: string, tableNPSMonthlyDataSheet: string,) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.barNPSDataSheet = barNPSDataSheet;
        this.tableNPSDataSheet = tableNPSDataSheet;
        this.titleMonthlySheet = titleMonthlySheet;
        this.barNPSMonthlyDataSheet = barNPSMonthlyDataSheet;
        this.tableNPSMonthlyDataSheet = tableNPSMonthlyDataSheet;
    }

    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        // Override title's options and add filter's name in it.
        const titleData = await this.getMappedChartData(ProductNPSResultsTitleModel, this.titleSheet);
        let subTitle: string = "";

        titleData.map((obj: any) => {
            this.chartTitle = obj.Title;
            return subTitle = obj.TitlePPT;
        });

        slide.addText([{ text: subTitle, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.35, bold: true });
        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 12 } }], { x: 0.5, y: 0.70, bold: true });
    }

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const barNPSData = await this.getMappedChartData(ProductNPSResultsDataModel, this.barNPSDataSheet);
        const tableNPSData = await this.getMappedChartData(ProductNPSResultsTableDataModel, this.tableNPSDataSheet);

        if (barNPSData.length > 0) {
            await this.addNPSBarChart(chartSlide, barNPSData, tableNPSData);
        };

        const monthlyData = await this.getMappedChartData(ProductNPSResultsMonthlyDataModel, this.barNPSMonthlyDataSheet);

        if (monthlyData.length > 0) {
            await this.addMonthlySlide();
        }
    }

    private async addMonthlySlide() {
        const slide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });
        const titleData = await this.getMappedChartData(ProductNPSResultsTitleModel, this.titleMonthlySheet);
        let subTitle: string = "";
        let note: string = "";

        const barNPSMonthlyData = await this.getMappedChartData(ProductNPSResultsMonthlyDataModel, this.barNPSMonthlyDataSheet);
        const tableNPSMonthlyData = await this.getMappedChartData(ProductNPSResultsTableMonthlyDataModel, this.tableNPSMonthlyDataSheet);

        titleData.map((obj: any) => {
            if (obj.Note) {
                note = obj.Note;
            }
            this.chartTitle = obj.Title;
            return subTitle = obj.TitlePPT;;
        });

        slide.addText([{ text: subTitle, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.35, bold: true });
        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 12 } }], { x: 0.5, y: 0.70, bold: true });
        if (note) {
            slide.addText([{ text: `${note}`, options: { color: '#000000', fontSize: 8 } }], { x: 0.5, y: 5.2, bold: false });
        }
        await this.addNPSBarChart(slide, barNPSMonthlyData, tableNPSMonthlyData);
    }

    private async addNPSBarChart(slide: pptxgen.Slide, data: any, tableData: any) {
        let filteredData = filterInitDuplicateData(data);
        const newArr: any = [];
        const lineColors: string[] = [];
        const getAllLabels: any = [];
        filteredData.forEach((obj: any) => {
            getAllLabels.push(obj.Label);
        });
        const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

        filteredData.forEach((obj: any) => {
            if (obj.Name) {
                const item = newArr.find((item: any) => item.name === obj.Name);
                lineColors.push(obj.Color);
                if (newArr && newArr.length > 0 && item) {
                    item.values.push(parseFloat(obj.Value));
                    item.labels.push(obj.Label);
                } else {
                    newArr.push(
                        {
                            name: obj.Name,
                            labels: [obj.Label],
                            values: [parseFloat(obj.Value)],
                        }
                    )
                };
            };
        });
        const finalChartData = addEmptyValuesToInitData(distinctedLabels, newArr);

        const scoreArr: any = [];
        const sampleArr: any = [];
        let lowValue: boolean = false;
        scoreArr.push([{
            text: '',
            options: { color: '#000000' }
        }]);
        sampleArr.push([{
            text: '',
            options: { color: '#000000' }
        }]);

        tableData.forEach((obj: any) => {
            if (obj.Base) {
                if (obj.Base <= 20) {
                    lowValue = true;
                };
                sampleArr.push(
                    [{
                        text: `(n=${obj.Base <= 20 ? (obj.Base).toFixed() + '*' : (obj.Base).toFixed()})`,  
                        options: { color: obj.Base < 10 ? '#FF0000' : '#000000' }
                    }],
                    [{
                        text: '',
                        options: { color: '#000000' }
                    }]
                );
                scoreArr.push(
                    [{
                        text: `\n ${((obj.AvgValue) * 100).toFixed()}%`,
                        options: { color: '#000000' }
                    }],
                    [{
                        text: `${((obj.DiffValue) * 100).toFixed()}%`,
                        options: { color: obj.Color }
                    }],
                    [{
                        text: '',
                        options: { color: '#000000' }
                    }]
                );
            }
        });

        const scoreProps: pptxgen.TableProps = {
            x: 7,
            y: 1,
            w: 1,
            h: 3.7,
            align: 'center',
            fontSize: 6,
            color: this.colors.white,
            border: { type: 'none' },
        };

        const sampleProps: pptxgen.TableProps = {
            x: 1.55,
            y: 1.4,
            w: 1,
            h: 3.7,
            align: 'center',
            fontSize: 6,
            color: this.colors.white,
            border: { type: 'none' },
        };

        slide.addChart(
            this.presentation.ChartType.bar,
            finalChartData,
            {
                x: 1,
                y: 1,
                w: "70%",
                h: "70%",
                barDir: "bar",
                barGrouping: "stacked",
                catGridLine: { style: "none" },
                valAxisHidden: true,
                valGridLine: { style: "none" },
                showLegend: true,
                showValue: true,
                legendPos: 'b',
                dataLabelFormatCode: '0%',
                valAxisLabelFormatCode: '0%',
                catAxisLabelFontSize: 9,
                dataLabelFontSize: 9,
                dataLabelColor: this.colors.white,
                chartColors: lineColors.filter(this.distinctRepeatables),
            }
        );
        slide.addTable(scoreArr, scoreProps);
        slide.addTable(sampleArr, sampleProps);

        if (lowValue) {
            slide.addText([{ text: "*Note: Scores could be influenced by small sample sizes.", options: { color: '#000000', fontSize: 8 } }], { x: 0.5, y: 5, bold: false });
        };
        slide.addText([{ text: "≥+5% Increase in Product NPS Score when compared to last period ", options: { color: this.colors.lightPositive, fontSize: 8 } }], { x: 6, y: 4.7, bold: false });
        slide.addText([{ text: "≤-5% Drop in Product NPS Score when compared to last period ", options: { color: this.colors.lightNegative, fontSize: 8 } }], { x: 6, y: 4.8, bold: false });
    };

    private distinctRepeatables = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
    };
};
import { RoleActions } from '../../shared/enums/RoleActions';

export type CreateRolePermissionModel = {
    usersSection: RoleActions;
    userRolesSection: RoleActions;
    // systemLogSection: RoleActions;
    // faqSection: RoleActions;
    trainingsSection: RoleActions;
};

export const getInitialRolePermission = (): CreateRolePermissionModel => {
    return {
        usersSection: RoleActions.None,
        userRolesSection: RoleActions.None,
        // systemLogSection: RoleActions.None,
        // faqSection: RoleActions.None,
         trainingsSection: RoleActions.None
    };
};

import { IDataMapping } from '../../IDataMapping';

export class PreferenceScoreTitleModel implements IDataMapping<PreferenceScoreTitleModel> {
    public Level: string = '';
    public Title: string = '';
    public SubTitle: string = '';
    public getMappings?() {
        return {
            Level: "Preference VS",
            Title: "KPI Title pipe",
            SubTitle: "KPI Sub Title pipe",
        };
    }
}

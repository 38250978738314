import { IDataMapping } from '../../../IDataMapping';

export class AppendixUSTitleModel implements IDataMapping<AppendixUSTitleModel> {
    public Title: string = '';
    public getMappings?() {
        return {
            Title: "AppendixUSTitle",
        };
    }
}

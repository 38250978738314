import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './UsersView.module.scss';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { MenuItem } from 'primereact/components/menuitem/MenuItem';
import { find } from 'lodash';
import { AddDialog } from '../add-dialog/AddDialog';
import { DeleteDialog } from '../delete-dialog/DeleteDialog';
import { EditDialog } from '../edit-dialog/EditDialog';
import { showMessageAction } from '../../../store/layout/LayoutActions';
import {
  ApplicationUserViewModel,
  RoleActions,
} from '../../../models/User/ApplicationUserViewModel';
import { ApplicationUserFormModel } from '../../../models/User/ApplicationUserFormModel';
import sharedStyles from '../../../shared/styles/SharedStyles.module.scss';
import { UserStatusOptions } from '../../../shared/constants';
import { FormType } from '../../../shared/enums/FormType';
import authService from '../../api-authorization/AuthorizeService';
import { ListViewControls } from '../../common/ListViewControls/ListViewControls';
import { PopupMenu } from '../../common/PopupMenu/PopupMenu';
import api from '../../../helpers/Api';
import layoutMessage from '../../../helpers/LayoutMessage';
import history from '../../../history';
import { IState } from '../../../store/IState';

import {
  resetState
} from "../../../store/addUser/AddUserActions";

export const UsersView = (): JSX.Element => {
  const dispatch = useDispatch();

  const [users, setUsers] = useState<ApplicationUserViewModel[]>([]);

  const [globalFilter, setGlobalFilter] = useState<string>('');

  const [addDialogIsOpen, setAddDialogIsOpen] = useState<boolean>(false);

  const [editDialogIsOpen, setEditDialogIsOpen] = useState<boolean>(false);

  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState<boolean>(false);

  const [resetDialogIsOpen, setResetDialogIsOpen] = useState<boolean>(false);

  const [selectedUser, setSelectedUser] = useState<
    ApplicationUserFormModel | undefined
  >(undefined);

  const userProfile: ApplicationUserViewModel | undefined = useSelector(
    (state: IState) => state.UserReducer.userProfile
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [roleOptions, setRoleOptions] = useState<any>([]);

  const getRoles = async () => {
    await api.get<any[]>(
      'Roles/GetRoleOptions',
      (data) => {
        data.sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
        );
        setRoleOptions(data);
      },
      (errorMessage) => layoutMessage.renderError(dispatch, errorMessage)
    );
  };

  const getUsers = async () => {
    await api.get<ApplicationUserViewModel[]>(
      'Users/GetAll',
      (data) => {
        data.sort((a, b) =>
          a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1
        );
        setUsers(data);
        setSelectedUser(undefined);
      },
      (errorMessage, status) =>
        status === 403
          ? history.push('/')
          : layoutMessage.renderError(dispatch, errorMessage)
    );

    setIsLoading(false);
  };

  const onGlobalFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setGlobalFilter(value);
  };

  const getStatusColumn = (rowData: ApplicationUserViewModel): JSX.Element => {
    const statusValue = rowData.userStatus;
    const statusObject = find(
      UserStatusOptions,
      (o: any) => o.value === statusValue
    );
    if (statusObject) {
      return <span>{statusObject.label}</span>;
    }

    return <span>{statusValue}</span>;
  };

  const getUserRolesColumn = (rowData: ApplicationUserViewModel) => {
    return <span>{rowData.userRoles.map((x) => x.name).join(', ')}</span>;
  };

  const sendResetPasswordEmail = async () => {
    if (selectedUser) {
      const token = await authService.getAccessToken();
      const requestHeaders: Headers = new Headers();
      requestHeaders.append('Accept', 'application/json; text/javascript, */*');
      requestHeaders.append('Content-Type', 'application/json; charset=UTF-8');
      requestHeaders.append('Authorization', `Bearer ${token}`);
      const response: Response = await fetch(`Users/SendResetPasswordEmail`, {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify(selectedUser),
      });
      if (response.ok) {
        dispatch(
          showMessageAction({
            severity: 'success',
            summary: 'Success!',
            detail: 'Email Sent!',
            life: 3000,
          })
        );
      } else {
        const errorMessage = await response.text();
        dispatch(
          showMessageAction({
            severity: 'error',
            summary: 'Error!',
            detail: errorMessage,
            sticky: true,
          })
        );
      }
      dispatch(resetState(''));
      setResetDialogIsOpen(false)
      setSelectedUser(undefined);
    }
  };

  const hideEditDialog = () => {
    setEditDialogIsOpen(false);
    setSelectedUser(undefined);
  };

  const hasUserPermission = (permission: RoleActions) =>
    userProfile?.userRoles.some(
      (x) => (x.rolePermission.usersSection & permission) !== 0
    );

  const getUserMenuItems = (rowData: ApplicationUserViewModel): MenuItem[] => {
    const formModel: any = {
      ...rowData,
      userRoles: rowData.userRoles.map((x) => x.name),
    };

    const items: MenuItem[] = [];

    if (hasUserPermission(RoleActions.Edit)) {
      items.push(
        ...[
          {
            label: FormType.Edit,
            command: () => {
              setSelectedUser(formModel);
              setEditDialogIsOpen(true);
            },
          },
          {
            label: 'Reset password',
            command: () => {
              setSelectedUser(formModel);
              setResetDialogIsOpen(true);
            },
          },
        ]
      );
    }

    if (hasUserPermission(RoleActions.Delete)) {
      items.push({
        label: FormType.Delete,
        command: () => {
          setSelectedUser(formModel);
          setDeleteDialogIsOpen(true);
        },
      });
    }

    return items;
  };

  const cancelResetPassword = () => {
    dispatch(resetState(''));
    setResetDialogIsOpen(false)
    setSelectedUser(undefined);
  }

  const resetDialogFooter = (
    <>
      <Button
        label={'CANCEL'}
        onClick={() => cancelResetPassword()}
        className={styles.cancel_button}
      />
      <Button
        label={'YES'}
        onClick={() => sendResetPasswordEmail()}
        className={styles.save_button}
      />
    </>
  );

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getRoles();
  }, []);

  if (isLoading) {
    return (
      <div className={styles.centered_on_screen}>
        <ProgressSpinner />
      </div>
    );
  }

  const hideDeleteDialog = () => {
    dispatch(resetState(''));
    setDeleteDialogIsOpen(false);
    setSelectedUser(undefined);
  }

  return (
    <React.Fragment>
      <ListViewControls
        searchValue={globalFilter}
        addLabel={'ADD NEW USER'}
        addButtonVisible={hasUserPermission(RoleActions.Add)}
        onSearch={onGlobalFilterChange}
        onAdd={() => setAddDialogIsOpen(true)}
      />
      <DataTable
        value={users}
        responsive={true}
        className={`${styles.users_table} ${sharedStyles.data_table}`}
        rows={10}
        paginator={true}
        globalFilter={globalFilter}
        resizableColumns
        columnResizeMode='fit'
        alwaysShowPaginator={false}
      >
        <Column field='fullName' header='FIRST & LAST NAME' className={styles.email_column} />
        <Column field='email' header='EMAIL/USER NAME' className={styles.email_column} />
        <Column field='company' header={'COMPANY'} />
        <Column field='position' header={'JOB TITLE'} />
        <Column
          field='userRoles'
          header={'USER ROLE'}
          body={getUserRolesColumn}
        />
        <Column field='site' header={'SITE'} />
        <Column
          field='userStatus'
          header={'STATUS'}
          body={getStatusColumn}
          style={{ width: '10%' }}
        />
        <Column
          field='action'
          className={sharedStyles.small_column}
          body={(rowData: ApplicationUserViewModel) => {
            return (
              <PopupMenu
                id={`user_popup_menu_${rowData.id}`}
                menuItems={getUserMenuItems(rowData)}
              />
            );
          }}
        />
      </DataTable>
      <AddDialog
        isOpen={addDialogIsOpen}
        roleOptions={roleOptions}
        handleCloseDialog={() => setAddDialogIsOpen(false)}
        handleRefreshUsers={getUsers}
      />
      <DeleteDialog
        isOpen={deleteDialogIsOpen}
        selectedUser={selectedUser}
        handleCloseDialog={hideDeleteDialog}
        handleRefreshUsers={getUsers}
      />
      {selectedUser ? (
        <React.Fragment>
          <EditDialog
            isOpen={editDialogIsOpen}
            roleOptions={roleOptions}
            handleCloseDialog={hideEditDialog}
            handleRefreshUsers={getUsers}
            selectedUser={selectedUser}
          />
          <Dialog
            onHide={() => setResetDialogIsOpen(false)}
            visible={resetDialogIsOpen}
            header={`Reset Password`}
            footer={resetDialogFooter}
            className={`${styles.default_dialog} ${styles.reset_dialog}`}
          >
            Are you sure you want to reset password for {selectedUser.firstName}{' '}
            {selectedUser.lastName}?
          </Dialog>
        </React.Fragment>
      ) : undefined}
    </React.Fragment>
  );
};

import pptxgen from 'pptxgenjs';

import { SRCBSFirstSlideRenderer } from '../../../../Renderers/SRCBSFirstSlideRenderer';
import { BasePptxService } from '../../../../BasePptxService';

import { SRCBSByStatementOverTimeChartTitleModel } from '../../../../../models/SRC/BrandStrength/MarketBrandStrength/SRCBSByStatementOverTimeChart/SRCBSByStatementOverTimeChartTitleModel';
import { SRCBSByStatementOverTimeChartDataModel } from '../../../../../models/SRC/BrandStrength/MarketBrandStrength/SRCBSByStatementOverTimeChart/SRCBSByStatementOverTimeChartDataModel';

import { addEmptyValuesToInitData, filterInitDuplicateData } from '../../../../../../shared/utility';

export class SRCBSByStatementOverTimeChartService extends BasePptxService {
    protected chartQuestion: string = '';
    protected firstSlideRenderer: SRCBSFirstSlideRenderer = new SRCBSFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
    }

    // Override of a super class method
    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const titleData = await this.getMappedChartData(SRCBSByStatementOverTimeChartTitleModel, this.titleSheet);

        let subTitle: string = "";
        titleData.map((obj: any) => {
            this.chartTitle = obj.Title;
            return subTitle = `${obj.SubTitle} - ${obj.Audience} - ${obj.Name} - ${obj.Indicator}`;
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.55, bold: true });
        slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 12, lineSpacing: 12 } }], { x: 0.6, y: 0.9 });
        slide.addText([{ text: this.chartQuestion, options: { color: this.colors.black, fontSize: 7 } }], { x: 0.5, y: '95%', bold: false });
    }

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const mappedData = await this.getMappedChartData(SRCBSByStatementOverTimeChartDataModel, this.dataSheet);

        const newArr: any = [];
        const lineColors: string[] = [];
        const getAllLabels: any = [];

        // Sorting the quarters
        const sortedLineChartDataByTime = mappedData.sort(function (a: any, b: any) {
            return a.Rank - b.Rank;
        });
        sortedLineChartDataByTime.forEach((obj: any) => {
            getAllLabels.push(obj.Label);
        });
        const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

        // Sorting the names/answers
        const sortedLineChartData = mappedData.sort(function (a: any, b: any) {
            return a.Name.localeCompare(b.Name);
        });
        let filteredData = filterInitDuplicateData(sortedLineChartData);

        filteredData.forEach((obj: any) => {
            const color = obj.Color ? obj.Color : this.colors.default;
            lineColors.push(color);
            const item = newArr.find((item: any) => item.name === obj.Name);
            if (newArr.length > 0 && item) {
                item.values.push(parseFloat(obj.Value));
                item.labels.push(obj.Label);
            } else {
                newArr.push(
                    {
                        name: obj.Name,
                        labels: [obj.Label],
                        values: [parseFloat(obj.Value)],
                    }
                )
            }
        });

        const finalChartData = addEmptyValuesToInitData(distinctedLabels, newArr);
        chartSlide.addChart(
            this.presentation.ChartType.line,
            finalChartData,
            {
                x: 1,
                y: 1.2,
                w: "70%",
                h: "70%",
                showLegend: true,
                legendPos: 'b',
                dataLabelPosition: 't',
                dataLabelFormatCode: '0\\%',
                legendFontSize: 8,
                valAxisLabelFormatCode: '0\\',
                catAxisLabelRotate: this.labelRotateDegree(newArr),
                chartColors: lineColors.filter(this.distinctRepeatables),
                valAxisLabelFontSize: 10,
                catAxisLabelFontSize: 10,
            }
        );

    }

    protected labelRotateDegree(array: any) {
        let degree: number = 0;
        array.forEach((obj: any) => {
            if (obj.labels.length > 10) {
                degree = 45;
            } else {
                degree = 0;
            }
        });
        return degree;
    }

    private distinctRepeatables = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
    };
}

import { IDataMapping } from '../../IDataMapping';

export class NineGLineChartAvgPriceTitleModel implements IDataMapping<NineGLineChartAvgPriceTitleModel> {
    public Title: string = '';
    public IndexTitle: string = '';
    public getMappings?() {
        return {
            Title: '9G Titles (Average Price)',
            IndexTitle: '9G Titles (Index)',
        };
    }
}

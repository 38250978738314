import pptxgen from 'pptxgenjs';
import { SPClinicalSegmentLevelLegendModel, SPClinicalSegmentLevelModel } from '../../../';
import { BasePptxService } from '../../BasePptxService';
import { HSStrategicFirstSlideRenderer } from '../../Renderers/HSStrategicFirstSlideRenderer';
import { uniqBy } from 'lodash';
import { BaseChartOptions } from '../../../philips-constants';
import { PptxRenderHelper } from '../../../helpers/PptxRenderHelper';

export class SPClinicalSegmentLevelPptxService extends BasePptxService {
  protected chartQuestion: string = 'Q: And within [-Clinical Segment] domain which vendor would you consider as your partner? ';
  protected firstSlideRenderer: HSStrategicFirstSlideRenderer = new HSStrategicFirstSlideRenderer();

  protected async addChartSlide(chartSlide: pptxgen.Slide): Promise<void> {
    await this.addCharts(chartSlide);
    await this.addLegend(chartSlide);
  }

  private async addCharts(chartSlide: pptxgen.Slide) {
    const chartDataArray = await Promise.all([
      this.getMappedChartData(SPClinicalSegmentLevelModel, 'MIA Radiology'),
      this.getMappedChartData(SPClinicalSegmentLevelModel, 'MIA Cardiology'),
      this.getMappedChartData(SPClinicalSegmentLevelModel, 'MIA Oncology'),
      this.getMappedChartData(SPClinicalSegmentLevelModel, 'MIA Enterprise IT'),
      this.getMappedChartData(SPClinicalSegmentLevelModel, 'MIA Enterprise Monitoring'),
    ]);

    const barChartWidth = 9.5 / chartDataArray.length;

    for (let i = 0; i < chartDataArray.length; i++) {
      let chartData = chartDataArray[i];

      if (!chartData || chartData.length === 0) {
        continue;
      }
      
      const x = i * barChartWidth + 0.4;

      chartData = this.getSortedByOrder(
        chartData,
        ({ Order: a }, { Order: b }) => (b as number) - (a as number)
      );

      const colors = PptxRenderHelper.getMappedChartDataColorsSP(chartData);

      const reducedData = chartData
        .reduce((acc, curr) => ({
          names: [...acc.names, curr.Brand],
          labels: [...acc.labels, curr.Brand],
          values: [...acc.values, curr.Sum],
        }), { names: [] as string[], labels: [] as string[], values: [] as number[] });

      chartSlide.addChart(this.presentation.ChartType.bar, [reducedData], {
        ...BaseChartOptions,
        x: x,
        y: 1.5,
        w: barChartWidth,
        h: 2.5,
        barDir: 'bar',
        showValue: true,
        chartColors: colors,
        barGapWidthPct: 15,
        valAxisLineShow: false,
        valAxisHidden: true,
        catAxisHidden: true,
        catAxisLabelPos: 'none',
        dataLabelFormatCode: '0%',
        dataLabelPosition: 'outEnd',
        dataLabelFontBold: true,
        dataLabelColor: this.colors.default,
      });

      const average = chartData.filter(x => x.Average).length ? chartData[0].Average : null;
      const labelTokens = chartData[0].Question.split(' ');
      const label = labelTokens.splice(1, labelTokens.length).join(' ');

      this.addAverage(chartSlide, x, average, label);
    }
  }

  private addAverage(chartSlide: pptxgen.Slide, x: number, average: number | null, label: string) {
    chartSlide.addText(`N=${average}`, {
      x: x + 0.25,
      y: 1.4,
      fontSize: 8,
      color: this.colors.default,
    });

    chartSlide.addText(label, {
      x: x + 0.25,
      y: 1.2,
      fontSize: 8,
      color: this.colors.default,
      bold: true,
    });
  }

  private async addLegend(chartSlide: pptxgen.Slide) {
    let chartData = uniqBy(
      (await this.getMappedChartData(SPClinicalSegmentLevelLegendModel, 'MIA All Legend')),
      x => x.Brand && x.Color
    );

    chartData = this.getSortedByOrder(
      chartData,
      ({ Order: a }, { Order: b }) => (a as number) - (b as number)
    );

    const colors = PptxRenderHelper.getMappedChartDataColorsSP(chartData);

    let y: number;
    let x = 0.5;
    chartData.forEach(({ Brand: label }, index) => {
      if (index % 4 === 0) {
        y = 4;
        x += 1.5;
      }

      y += 0.15;

      PptxRenderHelper.renderSquareLegend(
        chartSlide,
        { label, color: colors[index] },
        { x, y }
      );
    });
  }
}
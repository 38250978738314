import { IDataMapping } from '../../../../IDataMapping';

export class PHBSMarketStatementPerFactorChartDataModel implements IDataMapping<PHBSMarketStatementPerFactorChartDataModel> {
    public Answer:          string = '';
    public Color:           string = '';
    public Value:           number = Number(0);
    public Check:           number = Number(0);
    public BrandForChart:   string = '';
    public BrandForLegend:  string = '';
    public RowOrder:        number = Number(0);
    public BrandRank:       number = Number(0);
    public Factor:          string = '';

    public getMappings?() {
        return {
            Answer:         'Answer',
            Color:          'ATTR(Colour)',
            Value:          'AVG(Value)',
            Check:          'AGG(AVG(-1))',
            BrandForChart:  'ATTR(Brand (copy))',
            BrandForLegend: 'Brand',
            RowOrder:       'AGG(INDEX())',
            BrandRank:      'AVG(Rank BS)',
            Factor:         'ATTR(BS_statement)'
        };
    }
}

import React from 'react';
import { ISVGProps } from './ISVGProps';

export const EyeSvg = (props: ISVGProps) => {
  const { width, height } = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 18'
    >
      <defs>
        <style>
          {
            '.a{fill:none;stroke:#212529;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}'
          }
        </style>
      </defs>
      <g transform='translate(1 1)'>
        <path
          className='a'
          d='M1,12S5,4,12,4s11,8,11,8-4,8-11,8S1,12,1,12Z'
          transform='translate(-1 -4)'
        />
        <circle className='a' cx='3' cy='3' r='3' transform='translate(8 5)' />
      </g>
    </svg>
  );
};

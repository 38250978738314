import pptxgen from 'pptxgenjs';

import { PHBSMarketStatementOverTimeChartTitleModel } from '../../../../../models/PersonalHealth/BrandStrength/MarketsOverview/PHBSMarketStatementOverTimeChart/PHBSMarketStatementOverTimeChartTitleModel';
import { PHBSMarketStatementOverTimeChartDataModel } from '../../../../../models/PersonalHealth/BrandStrength/MarketsOverview/PHBSMarketStatementOverTimeChart/PHBSMarketStatementOverTimeChartDataModel';

import { BasePptxService } from '../../../../BasePptxService';
import { PHBSFirstSlideRenderer } from '../../../../Renderers/PHBSFirstSlideRenderer';

import { addEmptyValuesToInitData, filterInitDuplicateData } from '../../../../../../shared/utility';
import { PptxRenderHelper } from '../../../../../helpers/PptxRenderHelper';

export class PHBSMarketStatementOverTimeChartService extends BasePptxService {
    protected chartQuestion: string = '';
    protected firstSlideRenderer: PHBSFirstSlideRenderer = new PHBSFirstSlideRenderer();
    protected titleSheet: string;
    protected relativeDataSheet: string;
    protected absoluteDataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, relativeDataSheet: string, absoluteDataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.relativeDataSheet = relativeDataSheet;
        this.absoluteDataSheet = absoluteDataSheet;
    }

    // Override of a super class method
    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const titleData = await this.getMappedChartData(PHBSMarketStatementOverTimeChartTitleModel, this.titleSheet);

        let subTitle: string = "";
        titleData.forEach((obj: PHBSMarketStatementOverTimeChartTitleModel) => {
            this.chartTitle = obj.Title;
            subTitle = `${obj.SubTitle} - ${obj.DataTypeFilter} - ${obj.AudienceFilter} - ${obj.BrandFilter}`;
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.55, bold: true });
        slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 12, lineSpacing: 12 } }], { x: 0.6, y: 0.9 });
        slide.addText([{ text: this.chartQuestion, options: { color: this.colors.black, fontSize: 7 } }], { x: 0.5, y: '95%', bold: false });
    }

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const mappedRelativeData = await this.getMappedChartData(PHBSMarketStatementOverTimeChartDataModel, this.relativeDataSheet);
        const mappedAbsoluteData = await this.getMappedChartData(PHBSMarketStatementOverTimeChartDataModel, this.absoluteDataSheet);
        const titleData = await this.getMappedChartData(PHBSMarketStatementOverTimeChartTitleModel, this.titleSheet);

        let dataType: string = "Relative";
        titleData.forEach((obj: PHBSMarketStatementOverTimeChartTitleModel) => {
            dataType = obj.DataTypeFilter;
        });
        const mappedChartData: PHBSMarketStatementOverTimeChartDataModel[] = dataType === "Relative" ? mappedRelativeData : mappedAbsoluteData;

        const newArr: any = [];
        const lineColors: string[] = [];
        const getAllLabels: any = [];
        const names: string[] = [];

        // Sorting the quarters
        mappedChartData.sort((a, b) => a.BrandOrder - b.BrandOrder);
        mappedChartData.sort((a, b) => a.PeriodIndex - b.PeriodIndex);

        mappedChartData.forEach((obj: any) => {
            getAllLabels.push(obj.Label);
        });

        const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

        let filteredData = filterInitDuplicateData(mappedChartData);

        filteredData.forEach((obj: any) => {
            const color = obj.Color ? obj.Color : this.colors.default;
            lineColors.push(color);
            names.push(obj.Name);
            const item = newArr.find((item: any) => item.name === obj.Name);
            if (newArr.length > 0 && item) {
                item.values.push(parseFloat(obj.Value));
                item.labels.push(obj.Label);
            } else {
                newArr.push(
                    {
                        name: obj.Name,
                        labels: [obj.Label],
                        values: [parseFloat(obj.Value)],
                    }
                )
            }
        });

        const legendNames: string[] = names.filter(this.distinctRepeatables);
        const legendColors: string[] = lineColors.filter(this.distinctRepeatables);

        const finalChartData = addEmptyValuesToInitData(distinctedLabels, newArr);
        chartSlide.addChart(
            this.presentation.ChartType.line,
            finalChartData,
            {
                x: 1,
                y: 1.2,
                w: "70%",
                h: "70%",
                showLegend: legendNames.length > 4 ? false : true,
                legendPos: 'b',
                dataLabelPosition: 't',
                dataLabelFormatCode: '0\\%',
                legendFontSize: 8,
                valAxisLabelFormatCode: dataType === "Relative" ? '0\\' : '0%',
                catAxisLabelRotate: this.labelRotateDegree(newArr),
                chartColors: lineColors.filter(this.distinctRepeatables),
                valAxisLabelFontSize: 10,
                catAxisLabelFontSize: 10,
            }
        );

        if (legendNames.length > 4) {

            let currentSlide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });

            const namesPerRow = 1;

            for (let i = 0; i < legendColors.length; ++i) {
                PptxRenderHelper.renderShapeSquareLegend(this.presentation, currentSlide, { label: legendNames[i], color: legendColors[i] }, { x: 1 + (i % namesPerRow) * 1.5, y: 0.5 + 0.2 * Math.trunc(i / namesPerRow) });
            }
        }
    }

    protected labelRotateDegree(array: any) {
        let degree: number = 0;
        array.forEach((obj: any) => {
            if (obj.labels.length > 10) {
                degree = 45;
            } else {
                degree = 0;
            }
        });
        return degree;
    }

    private distinctRepeatables = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
    };
}

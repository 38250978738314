import React, { Suspense } from 'react';
import './i18n';
import { Route } from 'react-router';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Layout } from './components/Layout/Layout';
import { WorkbooksView } from './components/workbooks/workbooks-view/WorkbooksView';
import { TableauView } from './components/tableau-views/view/TableauView';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { Routes } from './shared/routes';
import styles from './shared/styles/SharedStyles.module.scss';
import { UsersView } from './components/users/users-view/UsersView';
import { DomainsPage } from './components/domains/DomainsPage';
import { RolesView } from './components/roles/roles-view/RolesView';
import { ErrorPage } from './components/common/ErrorPage/ErrorPage';
import { FilesPageContainer } from './components/files/FilesPageContainer';
import { HelpCenterContainer } from './components/help-center/HelpCenterContainer';
import authService from './components/api-authorization/AuthorizeService';
import { useIdleTimer } from 'react-idle-timer';

export const App = (): JSX.Element => {

  const handleOnIdle = (event: any) => {
    authService.signOut(window.location.href);
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 5000,
    eventsThrottle: 2000
  });

  return (
    <Suspense
      fallback={
        <div className={styles.centered_on_screen}>
          <ProgressSpinner />
        </div>
      }
    >
      <Layout>
        <AuthorizeRoute
          exact
          path={Routes.workbooks.pattern}
          component={WorkbooksView}
        />
        <AuthorizeRoute
          path={Routes.tableauView.pattern}
          component={TableauView}
        />
        <AuthorizeRoute path={Routes.usersView.pattern} component={UsersView} />
        <AuthorizeRoute path={Routes.rolesView.pattern} component={RolesView} />
        {/* <AuthorizeRoute path={Routes.filesPage.pattern} component={FilesPageContainer} /> */}
        <AuthorizeRoute path={Routes.helpCenter.pattern} component={HelpCenterContainer} />
        <AuthorizeRoute path={Routes.home.pattern} component={DomainsPage} />
        <Route
          path={ApplicationPaths.ApiAuthorizationPrefix}
          component={ApiAuthorizationRoutes}
        />
        <Route
          path={Routes.errorPage.pattern}
          component={ErrorPage}
        />
      </Layout>
    </Suspense>
  );
};

import pptxgen from 'pptxgenjs';

import { PurchaseFunnelLineChartTitleModel } from '../../../models/SRC/PurchaseFunnelLineChart/PurchaseFunnelLineChartTitleModel';
import { PurchaseFunnelLineChartDataModel } from '../../../models/SRC/PurchaseFunnelLineChart/PurchaseFunnelLineChartDataModel';

import { PurchaseFunnelLineChartMonthlyDataModel } from '../../../models/SRC/PurchaseFunnelLineChart/PurchaseFunnelLineChartMonthlyDataModel';

import { SRCFirstSlideRenderer } from '../../Renderers/SRCFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

import { filterInitDuplicateData, addEmptyValuesToInitData } from "../../../../shared/utility";

export class PurchaseFunnelLineChartService extends BasePptxService {
    protected chartQuestion: string = '';
    protected firstSlideRenderer: SRCFirstSlideRenderer = new SRCFirstSlideRenderer();
    protected titleSheet: string;
    protected funnelDataSheet: string;
    protected titleMonthlySheet: string;
    protected funnelMonthlyDataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, funnelDataSheet: string,
        titleMonthlySheet: string, funnelMonthlyDataSheet: string,) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.funnelDataSheet = funnelDataSheet;
        this.titleMonthlySheet = titleMonthlySheet;
        this.funnelMonthlyDataSheet = funnelMonthlyDataSheet;
    }

    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        // Override title's options and add filter's name in it.
        const titleData = await this.getMappedChartData(PurchaseFunnelLineChartTitleModel, this.titleSheet);
        let subTitle: string = "";

        titleData.map(obj => {
            this.chartTitle = obj.Title;
            return subTitle = obj.SubTitle + obj.Indicator + " - " + obj.Market + " - " + obj.Survey + " - " + obj.Category + " - Quarterly";
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.35, bold: true });
        slide.addText([{ text: subTitle, options: { color: this.colors.black, fontSize: 12 } }], { x: 0.5, y: 0.58, italic: true });
    }

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const funnelData = await this.getMappedChartData(PurchaseFunnelLineChartDataModel, this.funnelDataSheet);

        if (funnelData.length > 0) {
            await this.addLineChart(funnelData, chartSlide);
        };

        const monthlyData = await this.getMappedChartData(PurchaseFunnelLineChartMonthlyDataModel, this.funnelMonthlyDataSheet);

        if (monthlyData.length > 0) {
            await this.addMonthlySlide();
        }
    }

    private async addMonthlySlide() {
        const slide = this.presentation.addSlide({ masterName: 'SRC_MONTHLY_CHART' });
        const titleData = await this.getMappedChartData(PurchaseFunnelLineChartTitleModel, this.titleSheet);
        let subTitle: string = "";

        const funnelMonthlyData = await this.getMappedChartData(PurchaseFunnelLineChartMonthlyDataModel, this.funnelMonthlyDataSheet);

        titleData.map(obj => {
            this.chartTitle = obj.Title;
            return subTitle = obj.SubTitle + obj.Indicator + " - " + obj.Market + " - " + obj.Survey + " - " + obj.Category + " - Monthly";
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.35, bold: true });
        slide.addText([{ text: subTitle, options: { color: this.colors.black, fontSize: 12 } }], { x: 0.5, y: 0.58, italic: true });

        await this.addLineChart(funnelMonthlyData, slide);
    }

    private async addLineChart(array: any, chartSlide: pptxgen.Slide) {
        let filteredData = filterInitDuplicateData(array);

        const newArr: any = [];
        const lineColors: string[] = [];
        const getAllLabels: any = [];

        filteredData.forEach((obj: any) => {
            getAllLabels.push(obj.Label);
        });

        const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

        filteredData.forEach((obj: any) => {
            if (obj.Value) {
                lineColors.push(obj.Color);
            }
            const item = newArr.find((item: any) => item.name === obj.Name);
            if (newArr.length > 0 && item) {
                item.values.push(parseFloat(obj.Value));
                item.labels.push(obj.Label);
            } else {
                newArr.push(
                    {
                        name: obj.Name,
                        labels: [obj.Label],
                        values: [parseFloat(obj.Value)],
                    }
                )
            }
        });
        newArr.reverse();
        lineColors.reverse();

        const finalChartData = addEmptyValuesToInitData(distinctedLabels, newArr);
        chartSlide.addChart(
            this.presentation.ChartType.line,
            finalChartData,
            {
                x: 1,
                y: 1,
                w: "70%",
                h: "70%",
                showLegend: true,
                legendPos: 'b',
                showValue: true,
                dataLabelPosition: 't',
                dataLabelFormatCode: '0%',
                valAxisLabelFormatCode: '0%',
                catAxisLabelRotate: this.labelRotateDegree(newArr),
                chartColors: lineColors.filter(this.distinctRepeatables),
                valAxisLabelFontSize: 10,
                catAxisLabelFontSize: 10,
                dataLabelFontSize: 10,
            }
        );
    }

    protected labelRotateDegree(array: any) {
        let degree: number = 0;
        array.forEach((obj: any) => {
            if (obj.labels.length > 10) {
                degree = 45;
            } else {
                degree = 0;
            }
        });
        return degree;
    }

    private distinctRepeatables = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
    };
};
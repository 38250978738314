import { IDataMapping } from '../../../IDataMapping';

export class PHBSDeepDiveDataModel implements IDataMapping<PHBSDeepDiveDataModel> {
    public Label:       string = '';
    public Color:       string = '';
    public Name:        string = '';
    public Value:       number = 0;
    public PeriodIndex: number = 0;
    public Market:      string = '';
    public Audience:    string = '';
    public BrandRank:   number = 0;
    public Check:       number = 0;
    public Column:      number = 0;
    public Row:         number = 0;

    public getMappings?() {
        return {
            Label:       'QUARTER(Date)',
            Color:       'ATTR(Colour)',
            Name:        'Brand',
            Value:       'AVG(Value)',
            PeriodIndex: 'AVG(index_period)',
            Market:      'Country / Market',
            Audience:    'Audience',
            BrandRank:   'MIN(Rank BS)',
            Check:       'AGG(MAX(0.01))',
            Column:      'AGG(Column Divider)',
            Row:         'AGG(Row Divider)'
        };
    }
}

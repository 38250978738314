import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './SideMenu.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { LoginMenu } from '../../api-authorization/LoginMenu';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ScrollPanel } from 'primereact/scrollpanel';
import { useTranslation } from 'react-i18next';
import { IState } from '../../../store/IState';
import { TableauWorkbookViewModel } from '../../../models/Tableau/TableauWorkbookViewModel';
import history from '../../../history';
import { Routes } from '../../../shared/routes';
import { some } from 'lodash';
import {
    ApplicationUserViewModel,
    RolePermissionViewModel,
    RoleActions,
} from '../../../models/User/ApplicationUserViewModel';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { setLogoAction, setSideMenuButtonAction } from '../../../store/layout/LayoutActions';
import { NestedViewLinks } from './NestedViewLinks';
import { WorkbookWithGroupedViews } from '../../../models/Tableau/WorkbookWithGroupedViews';
import { ISideMenuButton } from '../../../store/layout/ILayoutState';

export const SideMenu = (): JSX.Element => {
    const loginMenuOverlay: any = useRef();
    const dispatch = useDispatch();
    const workbooks: TableauWorkbookViewModel[] = useSelector(
        (state: IState) => state.TableauReducer.workbooks
    );

    const selectedWorkbook: TableauWorkbookViewModel | undefined = useSelector(
        (state: IState) => state.TableauReducer.selectedWorkbook
    );

    const workbooksWithGroupedViews: WorkbookWithGroupedViews[] = useSelector(
        (state: IState) => state.TableauReducer.workbooksWithGroupedViews
    );

    const userProfile: ApplicationUserViewModel | undefined = useSelector(
        (state: IState) => state.UserReducer.userProfile
    );

    const sideMenuButton: ISideMenuButton = useSelector(
        (state: IState) => state.LayoutReducer.sideMenuButton
    );

    const [configIsOpen, setConfigIsOpen] = useState<boolean>(false);

    const { t } = useTranslation();

    const domainLogo = useSelector((state: IState) => state.LayoutReducer.logo);

    const [mobileSideBarIsOpen, setMobileSideBarIsOpen] = useState<boolean>(
        false
    );

    const getMobileViewLinks = () => {
        if (
            selectedWorkbook &&
            some(
                workbooksWithGroupedViews,
                (wb) => wb.workbookId === selectedWorkbook.id
            )
        ) {
            const nestedViewLinks = (
                <NestedViewLinks
                    workbook={selectedWorkbook}
                    workbooksWithGroupedViews={workbooksWithGroupedViews}
                    isMobile={true}
                    toggleMobileSidebar={setMobileSideBarIsOpen}
                />
            );

            return nestedViewLinks;
        } else {
            const viewLinks: JSX.Element | undefined = selectedWorkbook ? (
                <React.Fragment>
                    <ul className={styles.view_links}>
                        {selectedWorkbook.views.map((v) => (
                            <li
                                key={v.id}
                                onClick={() => {
                                    setMobileSideBarIsOpen(false);
                                }}
                            >
                                <NavLink
                                    key={v.id}
                                    to={Routes.tableauView.getPath(v.id)}
                                    activeClassName={styles.active_link}
                                >
                                    {v.name}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </React.Fragment>
            ) : undefined;

            return viewLinks;
        }
    };

    const getViewLinks = () => {
        if (
            selectedWorkbook &&
            some(
                workbooksWithGroupedViews,
                (wb) => wb.workbookId === selectedWorkbook.id
            )
        ) {
            const nestedViewLinks = (
                <NestedViewLinks
                    workbook={selectedWorkbook}
                    workbooksWithGroupedViews={workbooksWithGroupedViews}
                    isMobile={false}
                    toggleMobileSidebar={setMobileSideBarIsOpen}
                />
            );

            return nestedViewLinks;
        } else {
            // const viewsContainerHeight =
            //   selectedWorkbook &&
            //     selectedWorkbook.views.length <= 10 &&
            //     selectedWorkbook.views.length > 0
            //     ? selectedWorkbook.views.length * 32
            //     : selectedWorkbook && selectedWorkbook.views.length > 10
            //       ? 290
            //       : 0;     

            const viewLinks: JSX.Element | undefined = selectedWorkbook ? (
                <React.Fragment>
                    {/* <ScrollPanel
            style={{
              width: '100%',
              height: `${viewsContainerHeight}px`,
            }}
          > */}
                    <ul className={styles.view_links}>
                        {selectedWorkbook.views.map((v) => (
                            <li
                                key={v.id}
                                onClick={() => {
                                    setMobileSideBarIsOpen(false);
                                }}
                            >
                                <NavLink
                                    key={v.id}
                                    to={Routes.tableauView.getPath(v.id)}
                                    activeClassName={styles.active_link}
                                >
                                    {v.name}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                    {/* </ScrollPanel> */}
                </React.Fragment>
            ) : undefined;

            return viewLinks;
        }
    };

    const getWorkbooksDropdown = (mobile: boolean): JSX.Element => {
        const viewLinks = mobile ? getMobileViewLinks() : getViewLinks();
        return (
            <React.Fragment>
                {viewLinks}
            </React.Fragment>
        );
    };

    const toggleConfig = () => {
        setConfigIsOpen(!configIsOpen);
    };

    const hasPermissionToViewSection = (
        section: keyof RolePermissionViewModel
    ) => {
        const action =
            RoleActions.View |
            RoleActions.Add |
            RoleActions.Edit |
            RoleActions.Delete;
        let hasPermission = false;

        if (userProfile) {
            hasPermission = userProfile.userRoles.some(
                (x) => (x.rolePermission[section] & action) !== 0
            );
        }

        return hasPermission;
    };

    const getConfigLinks = () => {
        if (
            (hasPermissionToViewSection('usersSection') ||
                hasPermissionToViewSection('userRolesSection')) === false
        ) {
            return null;
        }

        const links = (
            <ul className={styles.config_links}>
                {hasPermissionToViewSection('usersSection') && (
                    <li key='users'>
                        <NavLink
                            key={'users_link'}
                            to={Routes.usersView.getPath()}
                            activeClassName={styles.active_link}
                        >
                            Users
            </NavLink>
                    </li>
                )}
                {hasPermissionToViewSection('userRolesSection') && (
                    <li key='user-roles'>
                        <NavLink
                            key={'users_roles_link'}
                            to={Routes.rolesView.getPath()}
                            activeClassName={styles.active_link}
                        >
                            User Roles
            </NavLink>
                    </li>
                )}
            </ul>
        );
        if (configIsOpen) {
            return (
                <React.Fragment>
                    <p onClick={toggleConfig} className={styles.config_container}>
                        <span className={styles.config_link}>Configuration</span>
                        <i className={'pi pi-chevron-up ' + styles.small_chevron} />
                    </p>
                    {links}
                </React.Fragment>
            );
        } else {
            return (
                <p onClick={toggleConfig} className={styles.config_container}>
                    <span className={styles.config_link}>Configuration</span>
                    <i className={'pi pi-chevron-down ' + styles.small_chevron} />
                </p>
            );
        }
    };

    const getLinks = () => {
        return (
            <ul className={styles.links_container}>
                {hasPermissionToViewSection('trainingsSection') ? (
                    <li key='help_center'>
                        <NavLink
                            key={'help_center_link'}
                            to={Routes.helpCenter.getPath()}
                            activeClassName={styles.active_link}
                        >
                            Documents & Reports
            </NavLink>
                    </li>
                ) : undefined}

                <a
                    className={styles.gem_seek_link}
                    href={sideMenuButton.Url}
                    rel='noopener noreferrer'
                    target={'_blank'}
                >
                    <p key='gemseek'>{sideMenuButton.Label}</p>
                </a>
            </ul>
        );
    };

    const getDomainLogo = async () => {
        const response = await fetch('/Styles/GetDomainLogo');
        if (response.ok) {
            const logoBase64 = await response.text();
            dispatch(setLogoAction(logoBase64));
        }
    };

    const getSideMenuButton = async () => {
        const response = await fetch('/Styles/GetSideMenuButton');
        if (response.ok) {
            let json = await response.json();
            dispatch(setSideMenuButtonAction(json));
        }
    };

    const getInitials = () => {
        if (userProfile && userProfile.firstName && userProfile.lastName) {
            return `${userProfile.firstName[0]}${userProfile.lastName[0]}`;
        } else {
            return '';
        }
    };

    useEffect(() => {
        getDomainLogo();
        getSideMenuButton();
    }, []);

    if (userProfile) {
        return (
            <React.Fragment>
                <Sidebar
                    visible={true}
                    position={'left'}
                    showCloseIcon={false}
                    onHide={() => undefined}
                    modal={false}
                    dismissable={false}
                    closeOnEscape={false}
                    className={styles.side_bar}
                >
                    <div className={styles.logo_container}>
                        <img
                            src={domainLogo}
                            onError={(e: any) => {
                                if (e && e.target && e.target.src) {
                                    e.target.src = '/images/gemseek.png';
                                }
                            }}
                            alt='Logo'
                            className={styles.logo}
                            onClick={() => history.push(Routes.workbooks.getPath())}
                        />
                    </div>
                    <ScrollPanel className={styles.navigation_wrapper}>
                        {getWorkbooksDropdown(false)}
                        <ul className={styles.home_container}>
                            <li key={'home'} className={styles.home_link}>
                                <NavLink
                                    key={'home_link'}
                                    to={Routes.home.getPath()}
                                    activeClassName={styles.active_link}
                                    exact
                                >
                                    Dashboards
              </NavLink>
                            </li>
                        </ul>
                        {getConfigLinks()}
                        {getLinks()}
                        <OverlayPanel
                            ref={loginMenuOverlay}
                            id='overlay_panel'
                            showCloseIcon={false}
                            className={styles.overlay_panel}
                            appendTo={document.body}
                        >
                            <LoginMenu t={t} />
                        </OverlayPanel>
                    </ScrollPanel>
                    <div className={styles.login_button_container}>
                        <div
                            className={styles.toggle_login_button}
                            onClick={(e) => loginMenuOverlay.current.toggle(e)}
                        >
                            {getInitials()}
                        </div>
                    </div>
                </Sidebar>
                {/* Mobile */}
                <Button
                    icon={'pi pi-bars'}
                    className={styles.sidebar_toggle}
                    onClick={() => setMobileSideBarIsOpen(true)}
                />
                <Sidebar
                    visible={mobileSideBarIsOpen}
                    position={'left'}
                    onHide={() => setMobileSideBarIsOpen(false)}
                    modal={false}
                    dismissable={true}
                    showCloseIcon={false}
                    className={styles.mobile_sidebar}
                    fullScreen={window.innerWidth < 600}
                >
                    <img
                        src='/icons/arrow-left.svg'
                        alt='arrow'
                        className={styles.sidebar_arrow}
                        onClick={() => setMobileSideBarIsOpen(false)}
                    />
                    {getWorkbooksDropdown(true)}
                    <ul className={styles.home_container}>
                        <li
                            key={'home_mobile'}
                            onClick={() => {
                                setMobileSideBarIsOpen(false);
                            }}
                            className={styles.home_link}
                        >
                            <NavLink
                                key={'mobile_home_link'}
                                to={Routes.home.getPath()}
                                activeClassName={styles.active_link}
                                exact
                            >
                                Dashboards
                </NavLink>
                        </li>
                    </ul>
                    {getLinks()}
                    <LoginMenu t={t} />
                </Sidebar>
            </React.Fragment>
        );
    }

    return <React.Fragment></React.Fragment>;
};

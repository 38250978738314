import { IDataMapping } from '../../IDataMapping';

export class SPClinicalSegmentLevelLegendModel implements IDataMapping<SPClinicalSegmentLevelLegendModel> {
  public Brand: string = '';
  public Color: string = '';
  public Order: number = -1;
  public Average: string = '';
  public Sum: string = '';

  public getMappings?() {
    return {
      Brand: 'Calc Other Ology Comb',
      Color: 'ATTR(Calc Brand Colors)',
      Order: 'ATTR(Calc Brand Order)',
      Average: 'AVG(Sample size)',
      Sum: 'SUM(%)'
    };
  }
}

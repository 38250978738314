import { IDataMapping } from '../../../../IDataMapping';

export class MainProductNPSChartDataModel implements IDataMapping<MainProductNPSChartDataModel> {
    public businessGroup: string = '';
    public text: string = '';
    public colorCategory: string = '';
    public value: string = '';

    public getMappings?() {
        return {
            businessGroup: 'Business group',
            text: 'Net driver',
            colorCategory: 'Leadership',
            value: 'AGG(SUM([Value])*100)'
        };
    }
}

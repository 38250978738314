import { IDataMapping } from '../../../../IDataMapping';

export class PHBSMarketAnalysisByBrandTableDataModel implements IDataMapping<PHBSMarketAnalysisByBrandTableDataModel> {
    public Factor:      string = '';
    public Category:    string = '';
    public Date:        string = '';
    public Color:       string = '';
    // public TargetColor: string = '';
    public Value:       number = 0;
    public TimeRank:    number = 0;
    public LegendText:  string = '';
    public LegendOrder: number = 0;

    public getMappings?() {
        return {
            Factor:         'Answer',
            Category:       'Brand',
            Date:           'QUARTER(Date)',
            Color:          'ATTR(Color Brand Strength Analysis (hex) (Value EXT))',
            // TargetColor:    'Color vs. Target Brand Strength',
            Value:          'Value EXT',
            TimeRank:       'MIN(index_period)',
            LegendText:     'Color Changing MAT',
            LegendOrder:    'ATTR(Color Brand Strength Analysis (rank) (Value EXT))',
        };
    }
}

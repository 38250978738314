import { IDataMapping } from '../../../../IDataMapping';

export class HospitalIDNPieChartSecondDataModel implements IDataMapping<HospitalIDNPieChartSecondDataModel> {
    public Brand: string = '';
    public Sum: number | null = 0;
    public Order: number | null = 0;
    public Color: string = '';
    public AveragePositive: number | null = 0;
    public AverageNegative: number | null = 0;

    public getMappings?() {
        return {
            Brand: 'Calc Others Pie',
            Sum: 'SUM(%)',
            Order: 'ATTR(Calc Brand Order)',
            Color: 'ATTR(Calc Brand Colors)',
            AveragePositive: 'AGG(Sample Size Color <50 BLACK)',
            AverageNegative: 'AGG(Sample Size Color <50 RED)',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class PurchaseFunnelMonthlyDataModel implements IDataMapping<PurchaseFunnelMonthlyDataModel> {
    public Name: string = '';
    public Label: string = '';
    public Color: string = '';
    public Value: number = Number(0);
    public Rank: number = Number(0);
    public LabelsRank: number = Number(0);
    public getMappings?() {
        return {
            Name: 'Brand',
            Label: 'Answer_Text',
            Color: 'AGG(BrandColors (funnel)_month)',
            Value: 'MAX(Value)',
            Rank: 'AGG(Rank_Date_Color)',
            LabelsRank: 'RankLabelsFunnel',
        };
    }
}

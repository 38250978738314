import { IDataMapping } from '../../../../IDataMapping';

export class RelativeProductNPSChartDataModel implements IDataMapping<RelativeProductNPSChartDataModel> {
    public businessGroup: string = '';
    public modality: string = '';
    public submodality: string = '';
    public country: string = '';
    public leadership: string = '';
    public leadershipValue: number = 0;
    public rowOrder: number = 0;
    public columnOrder: number = 0;
    public value: number = 0;
    public arrowValue: number = 0;

    public getMappings?() {
        return {
            businessGroup:      'Business group',
            modality:           'Modality',
            submodality:        'Submodality',
            country:            'Country (copy)',
            leadership:         'Leadership rNPS',
            leadershipValue:    'SUM(Leadership Int)',
            rowOrder:           'rNPS Matrix Order for BGs',
            columnOrder:        'rNPS Matrix Order for Coutries',
            value:              'MIN(rNPS)',
            arrowValue:         'ATTR(Up/Down arrow)',
        };
    }
}

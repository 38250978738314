import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'primereact/resources/themes/nova-light/theme.css';
import './shared/styles/CustomPrimeReactStyles.css';
import 'primeicons/primeicons.css';
import './shared/styles/index.scss';
import './shared/extensions';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { App } from './App';
import { configureStore } from './store/configureStore';
import history from './history';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Router basename={baseUrl} history={history}>
      <App />
    </Router>
  </Provider>,
  rootElement
);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();


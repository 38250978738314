import { IDataMapping } from '../../IDataMapping';

export class GlobalLeadershipStatusModel implements IDataMapping<GlobalLeadershipStatusModel> {
  public Leadership: string = '';
  public Year: string = '';
  public Sum: string = '';

  public getMappings?() {
    return {
      Leadership: 'Leadership',
      Year: 'Year',
      Sum: 'SUM(Value)',
    };
  }
}

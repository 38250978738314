import pptxgen from 'pptxgenjs';

import { BasePptxService } from '../../../../BasePptxService';
import { HSFirstSlideRenderer } from '../../../../Renderers/HSFirstSlideRenderer';
import { PptxRenderHelper } from '../../../../../helpers/PptxRenderHelper';

import { NpsByBusinessGroupChartDataModel } from '../../../../../models/HealthSystems/ProductLevelNPS/LeadershipStatus/NpsByBusinessGroupChart/NpsByBusinessGroupChartDataModel';
import { NpsByBusinessGroupChartLegendModel } from '../../../../../models/HealthSystems/ProductLevelNPS/LeadershipStatus/NpsByBusinessGroupChart/NpsByBusinessGroupChartLegendModel';
import { NpsByBusinessGroupChartFooterModel } from '../../../../../models/HealthSystems/ProductLevelNPS/LeadershipStatus/NpsByBusinessGroupChart/NpsByBusinessGroupChartFooterModel';

export class NpsByBusinessGroupChartService extends BasePptxService {
    protected chartQuestion: string = 'NPS question: Based on your experience on using their product and services, how likely are you to recommend this company?';
    protected firstSlideRenderer = new HSFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;
    protected legendSheet: string;
    protected footerSheet: string;

    private colorsByValue: string[] = [
        this.colors.negative,
        this.colors.white,
        this.colors.positive
    ];

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string, legendSheet: string, footerSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
        this.legendSheet = legendSheet;
        this.footerSheet = footerSheet;
    }

    // Override of a super class method
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const mappedChartData = await this.getMappedChartData(NpsByBusinessGroupChartDataModel, this.dataSheet);
        const mappedLegendData = await this.getMappedChartData(NpsByBusinessGroupChartLegendModel, this.legendSheet);
        const mappedFooterData = await this.getMappedChartData(NpsByBusinessGroupChartFooterModel, this.footerSheet);

        if (mappedChartData.length > 0) {

            // tables
            const headerData = this.getHeaderTableRow(mappedChartData);

            const businessGroups = this.getBusinessGroups(mappedChartData)
            const numberOfBusinessGroups = businessGroups.length;

            const groupData = this.groupDataByRows(mappedChartData);

            const tables = this.splitRowsByTables(groupData);

            // options
            const colW: number[] = [1, 1, ...Array(numberOfBusinessGroups).fill(0.6)];
            const tableWidth = colW.reduce((a: number, b: number) => a + b, 0);
            const x = (10 - tableWidth) / 2;

            const options: pptxgen.TableProps = {
                x,
                y: 1,
                w: tableWidth,
                align: 'center',
                valign: 'middle',
                border: { type: 'solid', pt: 0.5, color: this.colors.border },
                fontSize: 7,
                rowH: 0.2,
                autoPage: true,
                autoPageRepeatHeader: true,
                newSlideStartY: 0.8,
                autoPageCharWeight: 1,
                margin: [0, 0, 0, 5],
                colW
            };

            // execution
            tables.forEach((table, index) => {
                const currentSlide = this.getCurrentSlide(chartSlide, index);
                const bodyData = this.getBodyTableRows(table, numberOfBusinessGroups);

                currentSlide.addTable([...headerData, ...bodyData], options);
                this.addLegendAndFooter(currentSlide, x, mappedLegendData, mappedFooterData);
            });
        }
    }

    private getCurrentSlide(chartSlide: pptxgen.Slide, index: number) {
        let currentSlide = chartSlide;

        if (index !== 0) {
            currentSlide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });
            this.setChartSlideLayout(currentSlide);
        }

        return currentSlide;
    }

    private groupDataByRows(data: NpsByBusinessGroupChartDataModel[]) {
        // Sorting by rows
        data.sort((a, b) => a.rowOrder - b.rowOrder)

        // 1st step - Grouping the data by business groups
        const businessGroupsData: NpsByBusinessGroupChartDataModel[][] = [];
        for (let obj of data) {
            let flagMakeNewGroup = true;
            // Checks If there is group/array for this object
            for (let arr of businessGroupsData) {
                for (let element of arr) {
                    if (element.rowOrder === obj.rowOrder) {
                        arr.push(obj);
                        flagMakeNewGroup = false;
                    }
                    break;
                }
            }
            // Creates new group/array
            if (flagMakeNewGroup) {
                businessGroupsData.push([obj]);
            }
        }

        // 2nd step - Grouping the data in rows
        const groupData: NpsByBusinessGroupChartDataModel[][] = [];
        for (let businessGroup of businessGroupsData) {
            let firstRow: NpsByBusinessGroupChartDataModel[] = [];
            let secondRow: NpsByBusinessGroupChartDataModel[] = [];

            businessGroup.forEach(obj => {
                (obj.subRowOrder === 1) ? firstRow.push(obj) : secondRow.push(obj);
            });

            groupData.push(firstRow);
            groupData.push(secondRow);
        }

        // Sorting by columns
        groupData.forEach(arr => {
            arr.sort((a, b) => a.columnOrder - b.columnOrder);
        })

        return groupData;
    }

    private getBusinessGroups(data: NpsByBusinessGroupChartDataModel[]) {
        data.sort((a, b) => a.columnOrder - b.columnOrder)

        const businessGroups: string[] = [];

        data.forEach(obj => {
            if (!businessGroups.includes(obj.businessGroup)) {
                businessGroups.push(obj.businessGroup);
            }
        });

        return businessGroups;
    }

    private getHeaderTableRow(data: NpsByBusinessGroupChartDataModel[]) {
        const businessGroups = ["Country", "Year", ...this.getBusinessGroups(data)];

        const tableRow: pptxgen.TableRow[] = [];
        const tableCells: pptxgen.TableCell[] = [];

        // header row
        businessGroups.forEach((businessGroupName: string) => {
            tableCells.push(
                {
                    text: businessGroupName,
                    options: {
                        bold: true,
                        fill: { color: this.colors.default },
                        color: this.colors.white,
                        border:
                            [
                                { type: 'solid', pt: 0.5, color: this.colors.border },
                                { type: 'solid', pt: 0.5, color: this.colors.white },
                                { type: 'solid', pt: 0.5, color: this.colors.border },
                                { type: 'solid', pt: 0.5, color: this.colors.white },
                            ]
                    }
                }
            );
        });
        tableRow.push(tableCells);

        return tableRow;
    }

    private splitRowsByTables(groupData: NpsByBusinessGroupChartDataModel[][]) {
        const tables: NpsByBusinessGroupChartDataModel[][][] = [];

        // full tables
        const rowsPerTable = 14; // <- has to be an even number because of table methodology
        const numberOfFullTables = Math.trunc(groupData.length / rowsPerTable);
        for (let i = 0; i < numberOfFullTables; ++i) {
            tables.push(groupData.slice((i * rowsPerTable), (i * rowsPerTable) + rowsPerTable));
        }

        // last smaller table
        const lastTable = groupData.length % rowsPerTable;
        if (lastTable) {
            tables.push(groupData.slice((groupData.length - lastTable), groupData.length));
        }

        return tables;
    }

    private getBodyTableRows(groupData: NpsByBusinessGroupChartDataModel[][], numberOfBusinessGroups: number) {
        let tableRows: pptxgen.TableRow[] = [];

        groupData.forEach((row, index) => {
            let country: string = "";
            let year: string = "";
            let subRowOrder: number = 0;

            // Array initialized with default/empty values, which will be filled with real values later
            const values: pptxgen.TableCell[] = Array(numberOfBusinessGroups).fill(
                {
                    text: "",
                    options: { margin: [0, 3, 0, 0], color: this.colors.black, fill: { color: this.colors.white }, align: 'center' }
                }
            );

            // value rows
            row.forEach(obj => {
                if (obj.value) {
                    values[obj.columnOrder - 1] =
                    {
                        text: `${(obj.value * 100).toFixed()}%`,
                        options: { margin: [0, 3, 0, 0], color: this.colors.black, fill: { color: this.colorsByValue[obj.leadershipValue - 1] }, align: 'center' }
                    }
                }

                country = obj.country;
                year = obj.period;
                subRowOrder = obj.subRowOrder;
            });

            // Adding country and year cells to the rows
            let tableCells: pptxgen.TableCell[] = [];

            if (subRowOrder === 1) {
                tableCells.push(
                    {
                        text: country,
                        options: { fill: { color: this.colors.white }, align: 'center', rowspan: 2 }
                    },
                );
            } else if (tableRows.length === 0 && subRowOrder === 2) {
                tableCells.push(
                    {
                        text: country,
                        options: { fill: { color: this.colors.white }, align: 'center', rowspan: 1 }
                    },
                );
            }

            tableCells.push(
                {
                    text: year,
                    options: { fill: { color: this.colors.white }, align: 'center' }
                },
                ...values
            );

            tableRows.push(tableCells);
        });

        return tableRows;
    }

    private addLegendAndFooter(chartSlide: pptxgen.Slide, optionX: number, mappedLegendData: NpsByBusinessGroupChartLegendModel[], mappedFooterData: NpsByBusinessGroupChartFooterModel[]) {
        // legend
        let legend: string | pptxgen.TextProps[] = [];
        mappedLegendData.forEach((obj: NpsByBusinessGroupChartLegendModel) => {
            legend = [{ text: `${obj.text}`, options: { breakLine: true } }]
        });
        chartSlide.addText(legend, { x: optionX, y: 4.3, w: 6.5, fontSize: 8, color: this.colors.default });

        PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: `Neutral`, color: this.colors.white }, { x: 5.5, y: 4.3 });
        PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: `Leader`, color: this.colors.positive }, { x: 6.5, y: 4.3 });
        PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: `Follower`, color: this.colors.negative }, { x: 7.5, y: 4.3 });

        // footer
        let footer: string | pptxgen.TextProps[] = [];
        mappedFooterData.forEach((obj: NpsByBusinessGroupChartFooterModel) => {
            footer = [{ text: `${obj.text}`, options: { breakLine: true } }]
        });
        chartSlide.addText(footer, { x: optionX, y: 5, w: 6.5, fontSize: 8, color: this.colors.default, bold: true });
    }
}

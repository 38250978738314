import pptxgen from 'pptxgenjs';
import { UnaidedRecallRadiologyCommAverageModel, UnaidedRecallRadiologyCommModel } from '../../../';
import { groupBy, uniqBy } from 'lodash';
import { BasePptxService } from '../../BasePptxService';
import { HSStrategicFirstSlideRenderer } from '../../Renderers/HSStrategicFirstSlideRenderer';
import { PptxRenderHelper } from '../../../helpers/PptxRenderHelper';
import { BaseChartOptions } from '../../../philips-constants';

export class UnaidedRecallRadiologyCommPptxService extends BasePptxService {
  protected chartQuestion: string = 'Q: From which of these vendors have you seen, heard or read any communication recently  about the solutions they offer recently in [-Clinical Segment]?';
  protected firstSlideRenderer: HSStrategicFirstSlideRenderer = new HSStrategicFirstSlideRenderer();

  protected async addChartSlide(chartSlide: pptxgen.Slide): Promise<void> {
    const chartData = await this.getMappedChartData(UnaidedRecallRadiologyCommModel, 'URC Ology');
    const averageData = await this.getMappedChartData(UnaidedRecallRadiologyCommAverageModel, 'URC Ology Sample Size');

    const groups = Object.values(groupBy(chartData, x => x.Segment));

    groups.forEach((group, index) => {
      let width = 8 / groups.length;
      const showCatAxisLabel = index === 0;
      const x = index * width + (index >= 1 ? 1.5 : 1);

      if (index === 0) {
        width += 0.5;
      }

      const sortedData = this.getSortedByOrder(group, ({Order: a}, {Order: b}) => (b as number) - (a as number));

      const mappedData = sortedData
        .reduce((acc, curr) => ({
          labels: [...acc.labels, curr.Brand],
          values: [...acc.values, curr.Sum],
        }), { labels: [] as string[], values: [] as number[] });

      chartSlide.addChart(this.presentation.ChartType.bar, [mappedData], {
        ...BaseChartOptions,
        x,
        y: 1.4,
        w: width,
        h: 3,
        barDir: 'bar',
        showValue: true,
        chartColors: this.getMappedChartDataColors(group),
        barGapWidthPct: 15,
        valAxisLineShow: false,
        valAxisHidden: true,
        catAxisLabelPos: showCatAxisLabel ? 'low' : undefined,
        catAxisHidden: !showCatAxisLabel,
        dataLabelFormatCode: '0%',
        dataLabelPosition: 'outEnd',
        dataLabelFontBold: true,
        dataLabelColor: this.colors.default,
      });

      const labelX = index === 0 ? x + 0.5 : x;
      const segment = group[0].Segment;

      const average = averageData.firstOrNull(x => x.Segment === segment);

      const options = {
        w: 1,
        h: 0.1,
        x: labelX + 0.25,
        y: 1.4,
        fontSize: 8,
      };


      PptxRenderHelper.addAvarageColorized(chartSlide, average, options);

      this.addLabels(chartSlide, segment, options);
    });

    const unique = uniqBy(this.getSortedByOrder(chartData), x => x.Color);

    this.addLegend(chartSlide, unique);
  }

  private addLabels(chartSlide: pptxgen.Slide, label: string, options: pptxgen.TextPropsOptions) {
    options = Object.assign(options, {
      bold: true,
      color: this.colors.default,
      y: 1.2
    });

    chartSlide.addText(label, options);
  }

  private async addLegend(chartSlide: pptxgen.Slide, data: UnaidedRecallRadiologyCommModel[]) {
    let y: number;
    let x = 0;

    const colors = this.getMappedChartDataColors(data);

    data.forEach((order, index) => {
      if (index % 4 === 0) {
        y = 4.3;
        x += 1.5;
      }
      y += 0.15;

      chartSlide.addText(order.Brand, {
        color: this.colors.default,
        fontSize: 8,
        y: y,
        x: x + 0.15,
        h: 0.1,
        w: 2,
      });

      chartSlide.addText('■', {
        color: colors[index],
        fontSize: 12,
        y: y,
        x: x,
        h: 0.1,
        w: 0.1,
      });
    });
  }
}

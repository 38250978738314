import { IDataMapping } from '../../IDataMapping';

export class CompetitorsGloballyTitleModel implements IDataMapping<CompetitorsGloballyTitleModel> {
    public Title: string = '';
    public SurveyType: string = '';
    public getMappings?() {
        return {
            Title: 'Title Table',
            SurveyType: 'Survey',
        };
    }
}

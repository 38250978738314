import { IDataMapping } from '../../IDataMapping';

export class UnaidedChannelRecallModel implements IDataMapping<UnaidedChannelRecallModel> {
  Brand: string = '';
  Answer: string = '';
  Score: number = 0;
  Color: string = '';
  BrandOrder: number = -1;
  DriverOrder: number = -1;
  Average: number = -1;

  public getMappings?() {
    return {
      Brand: 'Brand',
      Score: 'SUM(%)',
      Answer: 'Answer',
      Color: 'ATTR(Calc Brand Colors)',
      BrandOrder: 'ATTR(Calc Brand Order)',
      DriverOrder: 'ATTR(Calc Drivers Order)',
      Average: 'SUM(Sample size)',
    };
  }
}

import { IDataMapping } from '../../../../IDataMapping';

export class CommunicationChannelsChartDataModel implements IDataMapping<CommunicationChannelsChartDataModel> {
    public Answer: string = '';
    public Order: number | null = 0;
    public Shape: string = '';
    public Color: string = '';
    public Sample: number | null = 0;
    public Favourability: number = 0;
    public Impact: number = 0;

    public getMappings?() {
        return {
            Answer: 'Answer',
            Order: 'Calc Answer Order ',
            Shape: 'Calc Answer Shape',
            Color: 'Q2_Marketing Answer Colors',
            Sample: 'AVG(Sample size)',
            Favourability: 'SUM(Favourability)',
            Impact: 'SUM(Impact)',
        };
    }
}

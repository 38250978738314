import pptxgen from 'pptxgenjs';

import { CompetitorsGloballyNPSTitleModel } from '../../../models/SRC/CompetitorsGloballyNPS/CompetitorsGloballyNPSTitleModel';
import { CompetitorsGloballyNPSDataModel } from '../../../models/SRC/CompetitorsGloballyNPS/CompetitorsGloballyNPSDataModel';
import { CompetitorsGloballyNPSFooterDataModel } from '../../../models/SRC/CompetitorsGloballyNPS/CompetitorsGloballyNPSFooterDataModel';

import { SRCFirstSlideRenderer } from '../../Renderers/SRCFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

export class CompetitorsGloballyNPSService extends BasePptxService {
    protected chartQuestion: string = '';
    protected firstSlideRenderer: SRCFirstSlideRenderer = new SRCFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;
    protected footerDataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string, footerDataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
        this.footerDataSheet = footerDataSheet;
    }

    // Override of a super class method
    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const titleData = await this.getMappedChartData(CompetitorsGloballyNPSTitleModel, this.titleSheet);
        const footerData = await this.getMappedChartData(CompetitorsGloballyNPSFooterDataModel, this.footerDataSheet);

        await Promise.all(titleData.map((obj: any) => {
            return this.chartTitle = obj.Title;
        }));
        slide.addText([{ text: this.chartTitle, options: { color: '#000000', fontSize: 14 } }], { x: 0.5, y: 0.35, bold: true });

        if (footerData.length > 0) {
            slide.addText([{ text: footerData[0].TextFooter, options: { color: '#000000', fontSize: 10 } }], { x: 4.9, y: 5, bold: false });
        };
    }

    // Override of a super class method
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const tableData = await this.getMappedChartData(CompetitorsGloballyNPSDataModel, this.dataSheet);
        const tableProps: pptxgen.TableProps = {
            x: 0.4,
            y: 1,
            w: '90%',
            align: 'center',
            bold: false,
            fontSize: 8,
            valign: 'middle',
            rowH: 0.35,
            color: this.colors.white,
            border: { type: 'solid', color: this.colors.white, pt: 1 },
        };

        const createRowTitles = this.generateTitleRows(tableData);
        const createRowSubTitles = this.generateSubTitleRows(tableData);
        const createRows = this.generateBodyRows(tableData);

        chartSlide.addTable([...createRowTitles, ...createRowSubTitles, ...createRows], tableProps);
        this.addLegend(chartSlide);
    }

    private addLegend(chartSlide: pptxgen.Slide) {
        const legend = [
            [
                {
                    text: ">+5%",
                    options: { fill: { color: "#8ccd81" } }
                },
                {
                    text: "<-5%",
                    options: { fill: { color: "#ef7c7f" } }
                },
                {
                    text: "No sig. difference",
                    options: { fill: { color: "#fff2ee" } }
                },
                {
                    text: "Sample size <10",
                    options: { fill: { color: "#bfbfbf" } }
                },
            ]
        ];

        const legendProps: pptxgen.TableProps = {
            x: '1',
            y: '4.85',
            w: '30%',
            align: 'left',
            valign: 'middle',
            bold: false,
            fontSize: 8,
            rowH: 0.1,
            color: '#000000',
            border: { type: 'solid', color: this.colors.neutral, pt: 0.2 }
        };

        chartSlide.addTable(legend, legendProps);
    };

    private generateTitleRows = (tableData: any) => {
        const markets = tableData.map((o: any) => o.Market);
        const filteredMarkets = tableData.filter(({ Market }: any, index: any) => !markets.includes(Market, index + 1));
        const result = [] as any;
        filteredMarkets.forEach((obj: any) => {
            result.push({ text: obj.Market, options: { color: '#000000', fill: { color: '#BED8EF' }, colspan: 2, } },)
        })

        const rowTitles: pptxgen.TableRow[] = []
        rowTitles.push(result)
        rowTitles[0].unshift({
            text: '', options: { fill: { color: '#BED8EF' } }
        });

        return rowTitles;
    }

    private generateSubTitleRows = (tableData: any) => {
        const reversedData = tableData.slice().reverse();
        const checkMarketForCategory: any = [];
        reversedData.forEach((obj: any) => {
            const item = checkMarketForCategory.find((item: any) => item.market === obj.Market);
            if (checkMarketForCategory.length > 0 && item) {
                if (item) {
                    item.subMarket = obj.SubMarket;
                }
            } else {
                checkMarketForCategory.push(
                    {
                        market: obj.Market,
                        subMarket: obj.SubMarket
                    },
                    {
                        market: obj.Market,
                        subMarket: obj.SubMarket
                    }
                )
            }
        })

        const result: any = [];
        checkMarketForCategory.forEach((obj: any) => {
            result.push({ text: obj.subMarket, options: { color: '#000000', fill: { color: '#BED8EF' } }, border: { type: 'solid', color: "black", pt: 1 } },)
        });

        const subTitles: pptxgen.TableRow[] = []
        subTitles.push(result)

        subTitles[0].unshift({
            text: '', options: { fill: { color: '#BED8EF' } }
        });
        return subTitles;
    }

    private generateBodyRows = (tableData: any) => {
        // Separate Markets and add the categories in array to use it later.
        const checkMarketForCategory: any = [];
        tableData.forEach((obj: any) => {
            const item = checkMarketForCategory.find((item: any) => item.market === obj.MarketAndSubTitle);
            if (checkMarketForCategory.length > 0 && item) {
                if (item) {
                    item.categories.push(obj.Category);
                }
            } else {
                checkMarketForCategory.push(
                    {
                        market: obj.MarketAndSubTitle,
                        categories: [obj.Category],
                        color: obj.Color,
                    },
                )
            }
        })

        // Separate Categories and add them array with their values.
        const newArr: any = [];
        tableData.forEach((obj: any) => {
            const item = newArr.find((item: any) => item.name === obj.Category);
            if (newArr.length > 0 && item) {
                if (item) {
                    const result = item.values.find((market: any) => {
                        return market.marketName === obj.MarketAndSubTitle;
                    })
                    if (result) {
                        result.value = obj.Value;
                        result.brandAbbreviation = obj.BrandAbbreviation;
                        result.color = obj.Value !== '' ? obj.Color : "#FFFFFF";
                    }
                }
            } else {
                // Generate empty placeholders for the exact amount of object so we could
                // match the empty ones later.
                const dummyValues = checkMarketForCategory.map((innerObj: any) => {
                    return {
                        marketName: innerObj.market,
                        value: '',
                        color: obj.Color,
                        brandAbbreviation: '',
                    }
                });
                const placeholders = {
                    name: obj.Category,
                    values: dummyValues,
                };
                const result = placeholders.values.find((market: any) => {
                    return market.marketName === obj.MarketAndSubTitle;
                });
                if (result) {
                    result.value = obj.Value;
                    result.brandAbbreviation = obj.BrandAbbreviation;
                };

                newArr.push(placeholders)
            }
        });

        const reversedNewArr = newArr.slice().reverse();

        // Generate the rows.
        const createRows: any = [];
        reversedNewArr.map((obj: any, index: any) => {
            const arr: any = [];
            arr.push({
                text: obj.name, options: { color: '#000000', fill: { color: '#BED8EF' } }
            })
            obj.values.forEach((val: any) => {
                arr.push({
                    text: val.value !== '' ? `${(parseFloat(val.value) * 100).toFixed(0)}% \n ${val.brandAbbreviation}` : "",
                    options: { color: '#000000', fill: { color: val.value !== '' ? val.color : "#FFFFFF" } }
                })
            });
            return createRows.push(arr);
        })

        return createRows;
    };
}

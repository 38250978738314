import { IDataMapping } from '../../IDataMapping';

export class BMCSimulatorLineChartTitleModel implements IDataMapping<BMCSimulatorLineChartTitleModel> {
    public Level: string = '';
    public Title: string = '';
    public SubTitle: string = '';
    public getMappings?() {
        return {
            Level: 'Country / Market',
            Title: '9G Titles (Preference)',
            SubTitle: 'Category',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class RecommendationPatternsTitleModel implements IDataMapping<RecommendationPatternsTitleModel> {
    public Title: string = '';
    public TitlePPT: string = '';
    public QuarterTitle: string = '';
    public SubTitle: string = '';
    public SubTitlePer: string = '';
    public Comments: string = '';
    public SampleDate: string = '';
    public SampleValue: number = Number(0);
    public SecondComment: string = '';
    public getMappings?() {
        return {
            Title: 'RecPatternsTitle Export',
            TitlePPT: "RecPatternsTitle",
            QuarterTitle: 'Category',
            SubTitle: 'JapTitle3',
            SubTitlePer: 'Title Japan',
            Comments: "Comments",
            SampleDate: 'Date',
            SampleValue: 'AVG(Count)',
            SecondComment: '●'
        };
    }
}

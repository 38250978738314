import { IDataMapping } from '../../IDataMapping';

export class SnapshotBPLineDataModel implements IDataMapping<SnapshotBPLineDataModel> {
    public Label: string = '';
    public Name: string = '';
    public Value: number = Number(0);
    public Rank: number = Number(0);
    public Color: string = '';
    public Base: number = Number(0);
    public getMappings?() {
        return {
            Label: 'Date',
            Name: 'Brand',
            Value: 'AVG(Value)',
            Rank: 'AGG(INDEX())',
            Color: 'AGG(BrandColors)',
            Base: 'AVG(Count)',
        };
    }
}

// tslint:disable: indent
import { TableauApiHelper } from './TableauApiHelper';
import { getViewEnum } from './ViewHelper';

import { PHBSMarketPerformanceChartTitleModel } from '../models/PersonalHealth/BrandStrength/MarketsOverview/PHBSMarketPerformanceChart/PHBSMarketPerformanceChartTitleModel';
import { PHBSMarketPerformanceChartService } from '../services/PersonalHealth/BrandStrength/MarketsOverview/PHBSMarketPerformanceChart/PHBSMarketPerformanceChartService';

import { PHBSMarketStatementPerBrandChartTitleModel } from '../models/PersonalHealth/BrandStrength/MarketsOverview/PHBSMarketStatementPerBrandChart/PHBSMarketStatementPerBrandChartTitleModel';
import { PHBSMarketStatementPerBrandChartService } from '../services/PersonalHealth/BrandStrength/MarketsOverview/PHBSMarketStatementPerBrandChart/PHBSMarketStatementPerBrandChartService';

import { PHBSMarketStatementOverTimeChartTitleModel } from '../models/PersonalHealth/BrandStrength/MarketsOverview/PHBSMarketStatementOverTimeChart/PHBSMarketStatementOverTimeChartTitleModel';
import { PHBSMarketStatementOverTimeChartService } from '../services/PersonalHealth/BrandStrength/MarketsOverview/PHBSMarketStatementOverTimeChart/PHBSMarketStatementOverTimeChartService';

import { PHBSMarketStatementPerFactorChartTitleModel } from '../models/PersonalHealth/BrandStrength/MarketsOverview/PHBSMarketStatementPerFactorChart/PHBSMarketStatementPerFactorChartTitleModel';
import { PHBSMarketStatementPerFactorChartService } from '../services/PersonalHealth/BrandStrength/MarketsOverview/PHBSMarketStatementPerFactorChart/PHBSMarketStatementPerFactorChartService';

import { PHBSMarketAudiencePerBrandChartTitleModel } from '../models/PersonalHealth/BrandStrength/MarketsOverview/PHBSMarketAudiencePerBrandChart/PHBSMarketAudiencePerBrandChartTitleModel';
import { PHBSMarketAudiencePerBrandChartService } from '../services/PersonalHealth/BrandStrength/MarketsOverview/PHBSMarketAudiencePerBrandChart/PHBSMarketAudiencePerBrandChartService';

import { PHBSMarketAnalysisByBrandTableTitleModel } from '../models/PersonalHealth/BrandStrength/MarketsOverview/PHBSMarketAnalysisByBrandTable/PHBSMarketAnalysisByBrandTableTitleModel';
import { PHBSMarketAnalysisByBrandTableService } from '../services/PersonalHealth/BrandStrength/MarketsOverview/PHBSMarketAnalysisByBrandTable/PHBSMarketAnalysisByBrandTableService';

import { PHBSGlobalStatementPerFactorChartTitleModel } from '../models/PersonalHealth/BrandStrength/GlobalOverview/PHBSGlobalStatementPerFactorChart/PHBSGlobalStatementPerFactorChartTitleModel';
import { PHBSGlobalStatementPerFactorChartService } from '../services/PersonalHealth/BrandStrength/GlobalOverview/PHBSGlobalStatementPerFactorChart/PHBSGlobalStatementPerFactorChartService';

import { PHBSGlobalStatementOverTimeChartTitleModel } from '../models/PersonalHealth/BrandStrength/GlobalOverview/PHBSGlobalStatementOverTimeChart/PHBSGlobalStatementOverTimeChartTitleModel';
import { PHBSGlobalStatementOverTimeChartService } from '../services/PersonalHealth/BrandStrength/GlobalOverview/PHBSGlobalStatementOverTimeChart/PHBSGlobalStatementOverTimeChartService';

import { PHBSGlobalAudiencePerBrandChartTitleModel } from '../models/PersonalHealth/BrandStrength/GlobalOverview/PHBSGlobalAudiencePerBrandChart/PHBSGlobalAudiencePerBrandChartTitleModel';
import { PHBSGlobalAudiencePerBrandChartService } from '../services/PersonalHealth/BrandStrength/GlobalOverview/PHBSGlobalAudiencePerBrandChart/PHBSGlobalAudiencePerBrandChartService';

import { PHBSAnalysisTableTitleModel } from '../models/PersonalHealth/BrandStrength/PHBSAnalysisTable/PHBSAnalysisTableTitleModel';
import { PHBSAnalysisTableService } from '../services/PersonalHealth/BrandStrength/PHBSAnalysisTable/PHBSAnalysisTableService';

import { PHBSFactorOverTimeChartTitleModel } from '../models/PersonalHealth/BrandStrength/PHBSFactorOverTimeChart/PHBSFactorOverTimeChartTitleModel';
import { PHBSFactorOverTimeChartService } from '../services/PersonalHealth/BrandStrength/PHBSFactorOverTimeChart/PHBSFactorOverTimeChartService';

import { PHBSAudienceOverTimeChartTitleModel } from '../models/PersonalHealth/BrandStrength/PHBSAudienceOverTimeChart/PHBSAudienceOverTimeChartTitleModel';
import { PHBSAudienceOverTimeChartService } from '../services/PersonalHealth/BrandStrength/PHBSAudienceOverTimeChart/PHBSAudienceOverTimeChartService';

import { PHBSTotalOverTimeChartTitleModel } from '../models/PersonalHealth/BrandStrength/PHBSTotalOverTimeChart/PHBSTotalOverTimeChartTitleModel';
import { PHBSTotalOverTimeChartService } from '../services/PersonalHealth/BrandStrength/PHBSTotalOverTimeChart/PHBSTotalOverTimeChartService';

import { PHBSTotalChartTitleModel } from '../models/PersonalHealth/BrandStrength/PHBSTotalChart/PHBSTotalChartTitleModel';
import { PHBSTotalChartService } from '../services/PersonalHealth/BrandStrength/PHBSTotalChart/PHBSTotalChartService';

import { PHBSDeepDiveTitleModel } from '../models/PersonalHealth/BrandStrength/DeepDive/PHBSDeepDiveTitleModel';
import { PHBSDeepDiveService } from '../services/PersonalHealth/BrandStrength/DeepDive/PHBSDeepDiveService';

import { PHBSEmployeeDeepDiveOverTimeTitleModel } from '../models/PersonalHealth/BrandStrength/EmployeeDeepDive/FactorsByStatementsOverTimeChart/PHBSEmployeeDeepDiveOverTimeTitleModel';
import { PHBSEmployeeDeepDiveOverTimeService } from '../services/PersonalHealth/BrandStrength//EmployeeDeepDive/FactorsByStatementsOverTimeChart/PHBSEmployeeDeepDiveOverTimeService';

import { PHBSEmployeeDeepDiveTableTitleModel } from '../models/PersonalHealth/BrandStrength/EmployeeDeepDive/FactorsByStatementsTable/PHBSEmployeeDeepDiveTableTitleModel';
import { PHBSEmployeeDeepDiveTableService } from '../services/PersonalHealth/BrandStrength//EmployeeDeepDive/FactorsByStatementsTable/PHBSEmployeeDeepDiveTableService';

import { SRCBSPerformanceChartTitleModel } from '../models/SRC/BrandStrength/MarketBrandStrength/SRCBSPerformanceChart/SRCBSPerformanceChartTitleModel';
import { SRCBSPerformanceChartService } from '../services/SRC/BrandStrength/MarketBrandStrength/SRCBSPerformanceChart/SRCBSPerformanceChartService';

import { SRCBSByStatementChartTitleModel } from '../models/SRC/BrandStrength/MarketBrandStrength/SRCBSByStatementChart/SRCBSByStatementChartTitleModel';
import { SRCBSByStatementChartService } from '../services/SRC/BrandStrength/MarketBrandStrength/SRCBSByStatementChart/SRCBSByStatementChartService';

import { SRCBSAnalysisByAudienceTableTitleModel } from '../models/SRC/BrandStrength/MarketBrandStrength/SRCBSAnalysisByAudienceTable/SRCBSAnalysisByAudienceTableTitleModel';
import { SRCBSAnalysisByAudienceTableService } from '../services/SRC/BrandStrength/MarketBrandStrength/SRCBSAnalysisByAudienceTable/SRCBSAnalysisByAudienceTableService';

import { SRCBSByStatementOverTimeChartTitleModel } from '../models/SRC/BrandStrength/MarketBrandStrength/SRCBSByStatementOverTimeChart/SRCBSByStatementOverTimeChartTitleModel'; 
import { SRCBSByStatementOverTimeChartService } from '../services/SRC/BrandStrength/MarketBrandStrength/SRCBSByStatementOverTimeChart/SRCBSByStatementOverTimeChartService';

import { SRCBSAnalysisTableTitleModel } from '../models/SRC/BrandStrength/SRCBSAnalysisTable/SRCBSAnalysisTableTitleModel';
import { SRCBSAnalysisTableService } from '../services/SRC/BrandStrength/SRCBSAnalysisTable/SRCBSAnalysisTableService';

import { SRCBSTotalOverTimeChartTitleModel } from '../models/SRC/BrandStrength/SRCBSTotalOverTimeChart/SRCBSTotalOverTimeChartTitleModel';
import { SRCBSTotalOverTimeChartService } from '../services/SRC/BrandStrength/SRCBSTotalOverTimeChart/SRCBSTotalOverTimeChartService';

import { SRCBSTotalChartTitleModel } from '../models/SRC/BrandStrength/SRCBSTotalChart/SRCBSTotalChartTitleModel';
import { SRCBSTotalChartService } from '../services/SRC/BrandStrength/SRCBSTotalChart/SRCBSTotalChartService';

import { BSPerformanceChartTitleModel } from '../models/HealthSystems/BrandStrength/MarketBrandStrength/BSPerformanceChart/BSPerformanceChartTitleModel';
import { BSPerformanceChartService } from '../services/HealthSystems/BrandStrength/MarketBrandStrength/BSPerformanceChart/BSPerformanceChartService';

import { BSByStatementChartTitleModel } from '../models/HealthSystems/BrandStrength/MarketBrandStrength/BSByStatementChart/BSByStatementChartTitleModel';
import { BSByStatementChartService } from '../services/HealthSystems/BrandStrength/MarketBrandStrength/BSByStatementChart/BSByStatementChartService';

import { BSAnalysisTableTitleModel } from '../models/HealthSystems/BrandStrength/BSAnalysisTable/BSAnalysisTableTitleModel';
import { BSAnalysisTableService } from '../services/HealthSystems/BrandStrength/BSAnalysisTable/BSAnalysisTableService';

import { BSTotalChartTitleModel } from '../models/HealthSystems/BrandStrength/BSTotalChart/BSTotalChartTitleModel';
import { BSTotalChartService } from '../services/HealthSystems/BrandStrength/BSTotalChart/BSTotalChartService';

import { BSTotalOverTimeChartTitleModel } from '../models/HealthSystems/BrandStrength/BSTotalOverTimeChart/BSTotalOverTimeChartTitleModel';
import { BSTotalOverTimeChartService } from '../services/HealthSystems/BrandStrength/BSTotalOverTimeChart/BSTotalOverTimeChartService';

import { BSAnalysisByAudienceTableTitleModel } from '../models/HealthSystems/BrandStrength/MarketBrandStrength/BSAnalysisByAudienceTable/BSAnalysisByAudienceTableTitleModel';
import { BSAnalysisByAudienceTableService } from '../services/HealthSystems/BrandStrength/MarketBrandStrength/BSAnalysisByAudienceTable/BSAnalysisByAudienceTableService';

import { BSByStatementOverTimeChartTitleModel } from '../models/HealthSystems/BrandStrength/MarketBrandStrength/BSByStatementOverTimeChart/BSByStatementOverTimeChartTitleModel'
import { BSByStatementOverTimeChartService } from '../services/HealthSystems/BrandStrength/MarketBrandStrength/BSByStatementOverTimeChart/BSByStatementOverTimeChartService'

import { NpsAttributesDeltaChartTitleModel } from '../models/HealthSystems/ProductLevelNPS/Attributes/NpsAttributesDeltaChart/NpsAttributesDeltaChartTitleModel';
import { NpsAttributesDeltaChartService } from '../services/HealthSystems/ProductLevelNPS/Attributes/NpsAttributesDeltaChart/NpsAttributesDeltaChartService';

import { NpsByBusinessGroupChartService } from '../services/HealthSystems/ProductLevelNPS/LeadershipStatus/NpsByBusinessGroupChart/NpsByBusinessGroupChartService';
import { NpsByBusinessGroupChartTitleModel } from '../models/HealthSystems/ProductLevelNPS/LeadershipStatus/NpsByBusinessGroupChart/NpsByBusinessGroupChartTitleModel';

import { RelativeProductNPSChartService } from '../services/HealthSystems/ProductLevelNPS/Overview/RelativeProductNPSChart/RelativeProductNPSChartService';
import { RelativeProductNPSChartTitleModel } from '../models/HealthSystems/ProductLevelNPS/Overview/RelativeProductNPSChart/RelativeProductNPSChartTitleModel';

import { CommunicationChannelsChartService } from '../services/HealthSystems/StrategicPartnership/Marketing/CommunicationChannelsChart/CommunicationChannelsChartService';
import { CommunicationChannelsChartTitleModel } from '../models/HealthSystems/StrategicPartnership/Marketing/CommunicationChannelsChart/CommunicationChannelsChartTitleModel';

import { MainProductNPSChartService } from '../services/HealthSystems/ProductLevelNPS/Overview/MainProductNPSChart/MainProductNPSChartService';
import { MainProductNPSChartTitleModel } from '../models/HealthSystems/ProductLevelNPS/Overview/MainProductNPSChart/MainProductNPSChartTitleModel';

import { UnaidedRecallSegmentCommunicationsChartTitleModel } from '../models/HealthSystems/StrategicPartnership/Marketing/UnaidedRecallSegmentCommunicationsChart/UnaidedRecallSegmentCommunicationsChartTitleModel';
import { UnaidedRecallSegmentCommunicationsChartService } from '../services/HealthSystems/StrategicPartnership/Marketing/UnaidedRecallSegmentCommunicationsChart/UnaidedRecallSegmentCommunicationsChartService';

import { UnaidedRecallCommunicationsChartTitleModel } from '../models/HealthSystems/StrategicPartnership/Marketing/UnaidedRecallCommunicationsChart/UnaidedRecallCommunicationsChartTitleModel';
import { UnaidedRecallCommunicationsChartService } from '../services/HealthSystems/StrategicPartnership/Marketing/UnaidedRecallCommunicationsChart/UnaidedRecallCommunicationsChartService';

import { UnaidedChannelRecallChartTitleModel } from '../models/HealthSystems/StrategicPartnership/Marketing/UnaidedChannelRecallChart/UnaidedChannelRecallChartTitleModel';
import { UnaidedChannelRecallChartService } from '../services/HealthSystems/StrategicPartnership/Marketing/UnaidedChannelRecallChart/UnaidedChannelRecallChartService';

import { SPVendorPieChartTitleModel } from '../models/HealthSystems/StrategicPartnership/ClinicalSegment/SPVendorPieChart/SPVendorPieChartTitleModel';
import { SPVendorPieChartService } from '../services/HealthSystems/StrategicPartnership/ClinicalSegment/SPVendorPieChart/SPVendorPieChartService';

import { CAImportanceChartTitleModel } from '../models/HealthSystems/StrategicPartnership/ClinicalSegment/CAImportanceChart/CAImportanceChartTitleModel';
import { CAImportanceChartService } from '../services/HealthSystems/StrategicPartnership/ClinicalSegment/CAImportanceChart/CAImportanceChartService';

import { HospitalIDNPieChartMainTitleModel } from '../models/HealthSystems/StrategicPartnership/HospitalIDNStrategicPartner/HospitalIDNPieChart/HospitalIDNPieChartMainTitleModel';
import { HospitalIDNPieChartService } from '../services/HealthSystems/StrategicPartnership/HospitalIDNStrategicPartner/HospitalIDNPieChart/HospitalIDNPieChartService';

import { NPSTrendsLineChartTitleModel } from '../models/HealthSystems/ProductLevelNPS/Trends/NPSTrendsChart/NPSTrendsLineChartTitleModel';
import { NPSTrendsService } from '../services/HealthSystems/ProductLevelNPS/Trends/NPSTrendsChart/NPSTrendsService';

import { NPSTrendsQuarterlyTitleModel } from '../models/HealthSystems/ProductLevelNPS/TrendsQuarterly/NPSTrendsQuarterlyTitleModel';
import { NPSTrendsQuarterlyService } from '../services/HealthSystems/ProductLevelNPS/TrendsQuarterly/NPSTrendsQuarterlyService';

import { SustainabilityLineChartTitleModel } from '../models/Sustainability/SustainabilityLineChart/SustainabilityLineChartTitleModel';
import { SustainabilityLineChartService } from '../services/Sustainability/SustainabilityLineChart/SustainabilityLineChartService';

import { ContributorsTitleModel } from '../models/PersonalHealth/Contributors/ContributorsTitleModel';
import { ContributorService } from '../services/PersonalHealth/Contributors/ContributorService';

import { PreferenceScoreTitleModel } from '../models/PersonalHealth/PreferenceScore/PreferenceScoreTitleModel';
import { PreferenceScoreService } from '../services/PersonalHealth/PreferenceScore/PreferenceScoreService';

import { PreferenceOverTimeTitleModel } from '../models/PersonalHealth/PreferenceOverTime/PreferenceOverTimeTitleModel';
import { PreferenceOverTimeService } from '../services/PersonalHealth/PreferenceOverTime/PreferenceOverTimeService';

import { CommonLineChartTitleModel } from '../models/PersonalHealth/CommonLineChart/CommonLineChartTitleModel';
import { CommonLineChartService } from '../services/PersonalHealth/CommonLineChart/CommonLineChartService';
import { IndicatorLineChartTitleModel } from '../models/PersonalHealth/CommonLineChart/IndicatorLineChartTitleModel';
import { IndicatorLineChartService } from '../services/PersonalHealth/CommonLineChart/IndicatorLineChartService';

import { FamiliarityTitleModel } from '../models/PersonalHealth/Familiarity/FamiliarityTitleModel';
import { FamiliarityService } from '../services/PersonalHealth/Familiarity/FamiliarityService';

import { CommonOverTimeLineChartTitleModel } from '../models/PersonalHealth/CommonOverTimeLineChart/CommonOverTimeLineChartTitleModel';
import { CommonOverTimeLineChartService } from '../services/PersonalHealth/CommonOverTimeLineChart/CommonOverTimeLineChartService';

import { PreferenceOverTimeComparisonTitleModel } from '../models/PersonalHealth/PreferenceOverTimeComparison/PreferenceOverTimeComparisonTitleModel';
import { PreferenceOverTimeComparisonService } from '../services/PersonalHealth/PreferenceOverTimeComparison/PreferenceOverTimeComparisonService';

import { BrandOwnershipTitleModel } from '../models/PersonalHealth/BrandOwnership/BrandOwnershipTitleModel';
import { BrandOwnershipService } from '../services/PersonalHealth/BrandOwnership/BrandOwnershipService';

import { CategoryUserProfileTitleModel } from '../models/PersonalHealth/CategoryUserProfile/CategoryUserProfileTitleModel';
import { CategoryUserProfileService } from '../services/PersonalHealth/CategoryUserProfile/CategoryUserProfileService';

import { NPSScoreOverTimeTitleModel } from '../models/PersonalHealth/NPSScoreOverTime/NPSScoreOverTimeTitleModel';
import { NPSScoreOverTimeService } from '../services/PersonalHealth/NPSScoreOverTime/NPSScoreOverTimeService';

import { ProductExperienceTitleModel } from '../models/PersonalHealth/ProductExperience/ProductExperienceTitleModel';
import { ProductExperienceService } from '../services/PersonalHealth/ProductExperience/ProductExperienceService';

import { BMCOverTimeLineChartTitleModel } from '../models/PersonalHealth/BMCOverTimeLineChart/BMCOverTimeLineChartTitleModel';
import { BMCOverTimeLineChartService } from '../services/PersonalHealth/BMCOverTimeLineChart/BMCOverTimeLineChartService';

import { NineGLineChartValueTitleModel } from '../models/PersonalHealth/NineGLineChart/NineGLineChartValueTitleModel';
import { NineGLineChartValueService } from '../services/PersonalHealth/NineGLineChart/NineGLineChartValueService';
import { NineGLineChartVolumeTitleModel } from '../models/PersonalHealth/NineGLineChart/NineGLineChartVolumeTitleModel';
import { NineGLineChartVolumeService } from '../services/PersonalHealth/NineGLineChart/NineGLineChartVolumeService';
import { NineGLineChartAvgPriceTitleModel } from '../models/PersonalHealth/NineGLineChart/NineGLineChartAvgPriceTitleModel';
import { NineGLineChartAvgPriceService } from '../services/PersonalHealth/NineGLineChart/NineGLineChartAvgPriceService';
import { NineGLineChartIndexTitleModel } from '../models/PersonalHealth/NineGLineChart/NineGLineChartIndexTitleModel';
import { NineGLineChartIndexService } from '../services/PersonalHealth/NineGLineChart/NineGLineChartIndexService';
import { NineGLineChartDistributionTitleModel } from '../models/PersonalHealth/NineGLineChart/NineGLineChartDistributionTitleModel';
import { NineGLineChartDistributionService } from '../services/PersonalHealth/NineGLineChart/NineGLineChartDistributionService';
import { NineGLineChartRnRTitleModel } from '../models/PersonalHealth/NineGLineChart/NineGLineChartRnRTitleModel';
import { NineGLineChartRnRService } from '../services/PersonalHealth/NineGLineChart/NineGLineChartRnRService';
import { NineGLineChartTotalTitleModel } from '../models/PersonalHealth/NineGLineChart/NineGLineChartTotalTitleModel';
import { NineGLineChartTotalService } from '../services/PersonalHealth/NineGLineChart/NineGLineChartTotalService';
import { NineGLineChartShareTitleModel } from '../models/PersonalHealth/NineGLineChart/NineGLineChartShareTitleModel';
import { NineGLineChartShareService } from '../services/PersonalHealth/NineGLineChart/NineGLineChartShareService';
import { NineGLineChartCampaignsTitleModel } from '../models/PersonalHealth/NineGLineChart/NineGLineChartCampaignsTitleModel';
import { NineGLineChartCampaignsService } from '../services/PersonalHealth/NineGLineChart/NineGLineChartCampaignsService';

import { BMCPreferenceOverTimeTitleModel } from '../models/PersonalHealth/BMCPreferenceOverTime/BMCPreferenceOverTimeTitleModel';
import { BMCPreferenceOverTimeService } from '../services/PersonalHealth/BMCPreferenceOverTime/BMCPreferenceOverTimeService';

import { BMCPreferenceScoreTitleModel } from '../models/PersonalHealth/BMCPreferenceScore/BMCPreferenceScoreTitleModel';
import { BMCPreferenceScoreService } from '../services/PersonalHealth/BMCPreferenceScore/BMCPreferenceScoreService';

import { BMCSimulatorLineChartTitleModel } from '../models/PersonalHealth/BMCSimulatorLineChart/BMCSimulatorLineChartTitleModel';
import { BMCSimulatorLineChartService } from '../services/PersonalHealth/BMCSimulatorLineChart/BMCSimulatorLineChartService';

import { SummaryTitleModel } from '../models/SRC/Summary/SummaryTitleModel';
import { SummaryService } from '../services/SRC/Summary/SummaryService';

import { SnapshotTitleModel } from '../models/SRC/Snapshot/SnapshotTitleModel';
import { SnapshotService } from '../services/SRC/Snapshot/SnapshotService';

import { FirstChoiceTitleModel } from '../models/SRC/FirstChoice/FirstChoiceTitleModel';
import { FirstChoiceTTLTitleModel } from '../models/SRC/FirstChoice/FirstChoiceTTLTitleModel';
import { FirstChoiceChartService } from '../services/SRC/FirstChoice/FirstChoiceChartService';

import { MarketOverviewTitleModel } from '../models/SRC/MarketOverview/MarketOverviewTitleModel';
import { MarketOverviewTableService } from '../services/SRC/MarketOverview/MarketOverviewTableService';

import { CompetitorsPerMarketTitleModel } from '../models/SRC/CompetitorsPerMarket/CompetitorsPerMarketTitleModel';
import { CompetitorsPerMarketService } from '../services/SRC/CompetitorsPerMarket/CompetitorsPerMarketService';

import { CompetitorsGloballyTitleModel } from '../models/SRC/CompetitorsGlobally/CompetitorsGloballyTitleModel';
import { CompetitorsGloballyService } from '../services/SRC/CompetitorsGlobally/CompetitorsGloballyService';

import { CompetitorsGloballyNPSTitleModel } from '../models/SRC/CompetitorsGloballyNPS/CompetitorsGloballyNPSTitleModel';
import { CompetitorsGloballyNPSService } from '../services/SRC/CompetitorsGloballyNPS/CompetitorsGloballyNPSService';

import { DemographicsPieChartTitleModel } from '../models/SRC/DemographicsPieChart/DemographicsPieChartTitleModel';
import { DemographicsPieChartService } from '../services/SRC/DemographicsPieChart/DemographicsPieChartService';

import { AppendixUSTitleModel } from '../models/SRC/Appendix/AppendixUS/AppendixUSTitleModel';
import { AppendixUSService } from '../services/SRC/Appendix/AppendixUS/AppendixUSService';

import { AppendixJapanTitleModel } from '../models/SRC/Appendix/AppendixJapan/AppendixJapanTitleModel';
import { AppendixJapanService } from '../services/SRC/Appendix/AppendixJapan/AppendixJapanService';

import { RecommendationPatternsTitleModel } from '../models/SRC/RecommendationPatterns/RecommendationPatternsTitleModel';
import { RecommendationPatternsService } from '../services/SRC/RecommendationPatterns/RecommendationPatternsService';

import { PurchaseFunnelTitleModel } from '../models/SRC/PurchaseFunnel/PurchaseFunnelTitleModel';
import { PurchaseFunnelService } from '../services/SRC/PurchaseFunnel/PurchaseFunnelService';

import { PurchaseFunnelLineChartTitleModel } from '../models/SRC/PurchaseFunnelLineChart/PurchaseFunnelLineChartTitleModel';
import { PurchaseFunnelLineChartService } from '../services/SRC/PurchaseFunnelLineChart/PurchaseFunnelLineChartService';

import { DriverAnalysisTitleModel } from '../models/SRC/DriverAnalysis/DriverAnalysisTitleModel';
import { KeyDriverAnalysisService } from '../services/SRC/DriverAnalysis/KeyDriverAnalysisService';
import { DriverScoresService } from '../services/SRC/DriverAnalysis/DriverScoresService';

import { CommonBarChartTitleModel } from '../models/PersonalHealth/CommonBarChart/CommonBarChartTitleModel';
import { CommonBarChartService } from '../services/PersonalHealth/CommonBarChart/CommonBarChartService';

import { CommonPieChartTitleModel } from '../models/PersonalHealth/CommonPieChart/CommonPieChartTitleModel';
import { CommonPieChartService } from '../services/PersonalHealth/CommonPieChart/CommonPieChartService';

import { MCCLineChartTitleModel } from '../models/PersonalHealth/MCCLineChart/MCCLineChartTitleModel';
import { MCCLineChartService } from '../services/PersonalHealth/MCCLineChart/MCCLineChartService';

import { RecommendedBrandsTitleModel } from '../models/PersonalHealth/RecommendedBrands/RecommendedBrandsTitleModel';
import { RecommendedBrandsService } from '../services/PersonalHealth/RecommendedBrands/RecommendedBrandsService';

import { MCCPreferenceScoreTitleModel } from '../models/PersonalHealth/MCCPreferenceScore/MCCPreferenceScoreTitleModel';
import { MCCPreferenceScoreService } from '../services/PersonalHealth/MCCPreferenceScore/MCCPreferenceScoreService';

import { RecommendationsTitleModel } from '../models/PersonalHealth/Recommendations/RecommendationsTitleModel';
import { RecommendationsService } from '../services/PersonalHealth/Recommendations/RecommendationsService';

import { PHSustainabilityTitleModel } from '../models/PersonalHealth/PHSustainability/PHSustainabilityTitleModel';
import { PHSustainabilityService } from '../services/PersonalHealth/PHSustainability/PHSustainabilityService';

import { PHBMCSustainabilityTitleModel } from '../models/PersonalHealth/PHSustainability/PHBMCSustainabilityTitleModel';
import { PHBMCSustainabilityService } from '../services/PersonalHealth/PHSustainability/PHBMCSustainabilityService';

import { ProductNPSLineChartTitleModel } from '../models/SRC/ProductNPSResultsLineChart/ProductNPSLineChartTitleModel';
import { ProductNPSLineChartService } from '../services/SRC/ProductNPSResultsLineChart/ProductNPSLineChartService';

import { ProductNPSResultsTitleModel } from '../models/SRC/ProductNPSResults/ProductNPSResultsTitleModel';
import { ProductNPSResultsService } from '../services/SRC/ProductNPSResults/ProductNPSResultsService';

import {
	GlobalBMCMatrixPptxService,
	TopDriversPptxService,
	BasePptxService,
	RelativeNPSLeaderFollowerPptxService,
	ProductLevelNPSDriversDeltaPptxService,
	ProductLevelNPSDriversOverviewPptxService,
	SPMarketOverviewPptxService,
	SPGlobalPptxService,
	SingleSPMarketOverviewPptxService,
	SPVendorPptxService,
	VendorSPGlobalPprxService,
	ClinicalAttributePptxService,
	GlobalLeadershipStatusPptxService,
	SPClinicalSegmentLevelPptxService,
	CommunicationChannelsPptxService,
	UnaidedChannelRecallPptxService,
	UnaidedRecallCommPptxService,
	UnaidedRecallRadiologyCommPptxService,
} from '../';

const tableauView = getViewEnum();

export type Handler = { [name: string]: (view: any, chartTitle: string) => BasePptxService };

export class ChartDownloadHelper {
	public static async getViewChartNames(view: any, viewId: string) {
		const genericViewSheetTitleHandler = ChartDownloadHelper.genericViewTitleSheetMap[viewId];
		if (genericViewSheetTitleHandler) {
			return await genericViewSheetTitleHandler(view);
		} else {
			return Object.keys(ChartDownloadHelper.viewChartMap[viewId]);
		}
	}

	public static getChartPptxService(view: any, viewId: string, chartForDownload: string) {
		let chartPptxServiceInstance = null;
		let chartPptxService = null;
		const chartDownloadHandlers = ChartDownloadHelper.viewChartMap[viewId];
		if (chartDownloadHandlers) {
			chartPptxService = chartDownloadHandlers[chartForDownload];
		} else {
			const genericChartTitle = Object.keys(ChartDownloadHelper.genericViewChartMap[viewId]).find(x => chartForDownload.startsWith(x));
			if (genericChartTitle) {
				chartPptxService = ChartDownloadHelper.genericViewChartMap[viewId][genericChartTitle];
			}
		}
		chartPptxServiceInstance = chartPptxService && chartPptxService(view, chartForDownload);
		return chartPptxServiceInstance;
	}

	private static viewChartMap: { [name: string]: Handler } = {};

	private static genericViewChartMap: { [name: string]: Handler } = {
		[tableauView.Overview]: {
			'Relative Product NPS* and Leadership Status': (view: any, chartTitle: string) => new RelativeProductNPSChartService(
				view, 
				chartTitle,
				'Philips Relative NPS_Title',
				'Philips Relative NPS',
				'Matrix text legend',
				'Calc Countrty agg info'),
			'Main Product NPS Attributes per BG: Global': (view: any, chartTitle: string) => new MainProductNPSChartService(
				view,
				chartTitle,
				"TITLE_Main Product NPS Attributes per BG: Global",
				[
            		'Top2 Drivers IGTs',
					'Top2 Drivers IGTd',
            		'Top2 Drivers EDI',
            		'Top2 Drivers DI',
            		'Top2 Drivers US',
            		'Top2 Drivers HPM',
            		'Top2 Drivers TC'
				]
			),
		},
		[tableauView.NPSDrivers]: {
			'Product NPS Attributes Delta': (view: any, chartTitle: string) => new NpsAttributesDeltaChartService(
				view, 
				chartTitle,
				'Philips NPS Title',
				'Drivers Table (2)'),
			'Product NPS Attributes Competitive Overview': (view: any, chartTitle: string) => new ProductLevelNPSDriversOverviewPptxService(view, chartTitle),
		},
		[tableauView.NPSTrend]: {
			'Product NPS Trends': (view: any, chartTitle: string) => new NPSTrendsService(
			view, 
			chartTitle,
			'Country_Trend Line Title',
			'Country_Trend Line',
			'Country_Trend Stacked bar NPS+Leadership',
			'Country_Trend Line (legend)',
			),
		},
		[tableauView.NPSTrendQuarterly]: {
			'Product NPS Trends Quarterly': (view: any, chartTitle: string) => new NPSTrendsQuarterlyService(
			view, 
			chartTitle,
			'Country_Trend Line Title (Q)',
			'Country_Trend Line (Q)',
			'Country_Trend Line (legend) (Q)',
			),
		},
		[tableauView.HospitalIDNStrategicPartner]: {
			'Hospital/ IDN Strategic Partner': (view: any, chartTitle: string) => new HospitalIDNPieChartService(
			view, 
			chartTitle, 
			'Pie charts Main title_Exports_Hospital/IDN',
			'SP at Hospital/IDN level Pie TEXT',
			'Single/Main VP at Hospital/IDN level Pie TEXT',
			'Hospital/IDN level Pie',
			'Single/Main SP'
			),
			'Strategic Partnership at Hospital/IDN Level: Market Overview': (view: any, chartTitle: string) => new SPMarketOverviewPptxService(view, chartTitle),
			'Single/Main Strategic Partner at Hospital/IDN Level: Market Overview': (view: any, chartTitle: string) => new SingleSPMarketOverviewPptxService(view, chartTitle),
			'Reasons why Vendor is considered to be a Single/Main Strategic Partner': (view: any, chartTitle: string) => new VendorSPGlobalPprxService(view, chartTitle),
			'Strategic Partnership at Clinical Segment Level': (view: any, chartTitle: string) => new SPClinicalSegmentLevelPptxService(view, chartTitle),
		},
		[tableauView.ClinicalSegment]: {
			'Strategic Partner Vendor in': (view: any, chartTitle: string) => new SPVendorPieChartService(
				view, 
				chartTitle,
				'MIA Ology Pie TEXT',
				'MIA Ology Pie'
			),
			"Clinical Attribute Importance": (view: any, chartTitle: string) => new CAImportanceChartService(
				view, 
				chartTitle,
				'Areas of Focus TEXT',
				'Areas of Focus',
				'Areas of Focus TEXT Sample Size'
			),
		},
		[tableauView.Marketing]: {
			"Unaided Recall of Communications about Hospital/IDN Level Solutions": (view: any, chartTitle: string) => new UnaidedRecallCommunicationsChartService(
				view,
				chartTitle,
				'URC hospital/IDN level TEXT',
				'URC hospital/IDN level',
				'URC hospital/IDN level Sample Size'
			),
			"Unaided Channel Recall per Brand": (view: any, chartTitle: string) => new UnaidedChannelRecallChartService(
				view, 
				chartTitle, 
				'UCR per brand TEXT', 
				'UCR per brand'),
			'Communication Channels: Favourability and Impact': (view: any, chartTitle: string) => new CommunicationChannelsChartService(
				view, 
				chartTitle,
				'Comm Channels TEXT',
				'Comm Channels'),
			'Unaided Recall of ': (view: any, chartTitle: string) => new UnaidedRecallSegmentCommunicationsChartService(
				view, 
				chartTitle,
				'URC Ology TEXT',
				'URC Ology',
				'URC Ology Sample Size'),
		},
		[tableauView.NPSLeadershipStatus]: {
				'Philips Product NPS by Business Group': (view: any, chartTitle: string) => new NpsByBusinessGroupChartService(
					view, 
					chartTitle,
					'Product NPS Leadership Title',
					'Global_BMC Matrix',
					'Matrix text legend',
					'Calc Countrty agg info'),
				// 'Product NPS: Global Leadership Status Weighted by Philips Health Systems Revenue': (view: any, chartTitle: string) => new GlobalLeadershipStatusPptxService(view, chartTitle)
		},
		[tableauView.HSGlobalBrandStrength]: {
			"2. Brand Strength Total Over Time": (view: any, chartTitle: string) => new BSTotalOverTimeChartService(
				view,
				chartTitle,
				"Global Brand Strenght Total Over Time Title",
				"Global Brand Strenght Total Over Time"
			),
			"1. Brand Strength Total": (view: any, chartTitle: string) => new BSTotalChartService(
				view,
				chartTitle,
				"Global Brand Strenght Total Title",
				"Global Brand Strenght Total"
			),
			'5. Brand Strength Analysis': (view: any, chartTitle: string) => new BSAnalysisTableService(
				view,
				chartTitle,
				'Global Brand Strenght Title',
				'Global Brand Strenght Shapes',
				'Global Brand Strength Analysis Factors',
				'Global Brand Strength Analysis Score',
				'Global Brand Strength Analysis vs Target',
			),
		},
		[tableauView.HSMarketBrandStrength]: {
			"2. Brand Strength Total Over Time": (view: any, chartTitle: string) => new BSTotalOverTimeChartService(
				view,
				chartTitle,
				"Market Brand Strenght Total Over Time Title",
				"Market Brand Strenght Total Over Time"
			),
			"1. Brand Strength Total": (view: any, chartTitle: string) => new BSTotalChartService(
				view,
				chartTitle,
				"Market Brand Strenght Total Title",
				"Market Brand Strenght Total"
			),
			"9. Brand Strength by Statement per Factor Over Time": (view: any, chartTitle: string) => new BSByStatementOverTimeChartService(
				view,
				chartTitle,
				'Market Brand Strength by Statement Over Time Title',
				'Market Brand Strenght by Statement Over Time',
			),
			"8. Brand Strength by Statement per Factor": (view: any, chartTitle: string) => new BSByStatementChartService(
				view,
				chartTitle,
				'Market Brand Strength Analysis by Statement Title',
				'Market Brand Strenght Analys by Statament',
			),
			"6. Brand Strength Analysis by Brand": (view: any, chartTitle: string) => new BSAnalysisByAudienceTableService(
				view,
				chartTitle,
				'Market Brand Strenght Title by Aud',
				'Market Brand Strenght Shapes by Aud',
				'Market Brand Strength Analysis by Audience',
				'Market Brand Strength Analysis Score by Aud',
				'Market Brand Strength Analysis vs Target by Aud',
			),
			'5. Brand Strength Analysis': (view: any, chartTitle: string) => new BSAnalysisTableService(
				view,
				chartTitle,
				'Market Brand Strenght Title',
				'Market Brand Strenght Shapes',
				'Market Brand Strength Analysis Factors',
				'Market Brand Strength Analysis Score',
				'Market Brand Strength Analysis vs Target',
			),
			'10. Brand Strength Absolute Performance': (view: any, chartTitle: string) => new BSPerformanceChartService(
				view,
				chartTitle,
				'Market Brand KPIs Title',
				'Market Brand KPIs Tornado',
				'Market Brand KPIs Bubble',
				'Market Brand KPIs Bar (Bars)',
			),
		},
		[tableauView.HSSustainability]: {
			"Access to Care: Helps improve access to HC in underserved communities": (view: any, chartTitle: string) => new SustainabilityLineChartService(
				view,
				chartTitle,
				'SST_title_1',
				'SST_trend line_1',
			),
			"Circular economy: Reducing its environmental footprint by promoting circular business models, repair, refurbishment, reuse, upgrades, and recycling": (view: any, chartTitle: string) => new SustainabilityLineChartService(
				view,
				chartTitle,
				'SST_title_2',
				'SST_trend line_2',
			),
			"Eco design: Making a significant effort in reducing the environmental footprint on its products and packaging": (view: any, chartTitle: string) => new SustainabilityLineChartService(
				view,
				chartTitle,
				'SST_title_3',
				'SST_trend line_3',
			),
			"Climate action: Making changes across its business to help combat climate change": (view: any, chartTitle: string) => new SustainabilityLineChartService(
				view,
				chartTitle,
				'SST_title_4',
				'SST_trend line_4',
			),
		},
		[tableauView.PersonalHealthGlobal]: {
			'Preference Score': (view: any, chartTitle: string) => new PreferenceScoreService(
				view,
				chartTitle,
				'Global Preference Score Title',
				'Global Preference Score',
				''
			),
			"Preference Over Time Comparison": (view: any, chartTitle: string) => new PreferenceOverTimeComparisonService(
				view,
				chartTitle,
				'Global POT Comparison Title',
				'Global Preference Over Time Comparison',
			),
			'Preference Over Time': (view: any, chartTitle: string) => new PreferenceOverTimeService(
				view,
				chartTitle,
				'Global POT Title',
				'Global Preference Over Time',
			),
			'Contributors to Preference Change': (view: any, chartTitle: string) => new ContributorService(
				view,
				chartTitle,
				'Global Tornado Title',
				'Global Contributors to Preference Change',
			),
			'Sustainability': (view: any, chartTitle: string) => new PHSustainabilityService(
				view,
				chartTitle,
				'Global_Sustainability_title',
				'Global_Sustainability',
			),
		},
		[tableauView.PersonalHealthCountry]: {
			'Preference Score': (view: any, chartTitle: string) => new PreferenceScoreService(
				view,
				chartTitle,
				'Country Preference Score Title',
				'Country Preference Score',
				'Country Preference Score Global results',
			),
			"Preference Over Time Comparison": (view: any, chartTitle: string) => new PreferenceOverTimeComparisonService(
				view,
				chartTitle,
				'Country POT Comparison Title',
				'Country Preference Over Time Comparison',
			),
			'Preference Over Time': (view: any, chartTitle: string) => new PreferenceOverTimeService(
				view,
				chartTitle,
				'Country POT Title',
				'Country Preference Over Time',
			),
			'Contributors to Preference Change': (view: any, chartTitle: string) => new ContributorService(
				view,
				chartTitle,
				'Country Tornado Title',
				'Country Contributors to Preference Change',
			),
			'Sustainability': (view: any, chartTitle: string) => new PHSustainabilityService(
				view,
				chartTitle,
				'Country_Sustainability_title',
				'Country_Sustainability',
			),
		},
		[tableauView.PersonalHealthCategory]: {
			'Preference Score': (view: any, chartTitle: string) => new PreferenceScoreService(
				view,
				chartTitle,
				'Category Preference Score Title',
				'Category Preference Score',
				'Category Preference Score Global results',
			),
			"Preference Over Time Comparison": (view: any, chartTitle: string) => new PreferenceOverTimeComparisonService(
				view,
				chartTitle,
				'Category POT Comparison Title',
				'Category Preference Over Time Comparison',
			),
			'Preference Over Time': (view: any, chartTitle: string) => new PreferenceOverTimeService(
				view,
				chartTitle,
				'Category POT Title',
				'Category Preference Over Time',
			),
			'Contributors to Preference Change': (view: any, chartTitle: string) => new ContributorService(
				view,
				chartTitle,
				'Category Tornado Title',
				'Category Contributors to Preference Change',
			),
			'Sustainability': (view: any, chartTitle: string) => new PHSustainabilityService(
				view,
				chartTitle,
				'Category_Sustainability_title',
				'Category_Sustainability',
			),
		},
		[tableauView.PersonalHealthIPLAdditional]: {
			'IPL Familiarity': (view: any, chartTitle: string) => new FamiliarityService(
				view,
				chartTitle,
				'IPL_A Familiarity Title',
				'IPL_A Familiarity',
			),
			'Preference Over Time': (view: any, chartTitle: string) => new CommonOverTimeLineChartService(
				view,
				chartTitle,
				'IPL_A Preference Title',
				'IPL_A Preference Over Time',
			),
			'Aided Awareness Over Time': (view: any, chartTitle: string) => new CommonOverTimeLineChartService(
				view,
				chartTitle,
				'IPL_A Aided Awareness Title',
				'IPL_A Aided Awereness Over Time',
			),
			'Consideration': (view: any, chartTitle: string) => new CommonOverTimeLineChartService(
				view,
				chartTitle,
				'IPL_A Consideration Title',
				'IPL_A Consideration Over Time',
			),
		},
		[tableauView.PersonalHealthBMC]: {
			'Preference Score': (view: any, chartTitle: string) => new BMCPreferenceScoreService(
				view,
				chartTitle,
				'BMC - PS Title',
				'BMC - Preference Score',
				'BMC - PS (Global)',
				'BMC - PS (Country)',
				'BMC - PS (Category)',
			),
			'Preference Over Time': (view: any, chartTitle: string) => new BMCPreferenceOverTimeService(
				view,
				chartTitle,
				'BMC Preference Over Time (Title)',
				'BMC Preference Over Time',
			),
			'Awareness Over Time': (view: any, chartTitle: string) => new BMCOverTimeLineChartService(
				view,
				chartTitle,
				'BMC -AA Title',
				'BMC - Aided Awareness',
			),
			'Consideration Over Time': (view: any, chartTitle: string) => new BMCOverTimeLineChartService(
				view,
				chartTitle,
				'BMC - Consideration Title',
				'BMC - Consideration',
			),
			'Brand Ownership': (view: any, chartTitle: string) => new BrandOwnershipService(
				view,
				chartTitle,
				'BMC Brand Ownership Title',
				'Brand Ownership',
			),
			'Category User Profile': (view: any, chartTitle: string) => new CategoryUserProfileService(
				view,
				chartTitle,
				'BMC Category User Profile Title',
				'BMC Category User Profile',
			),
			'NPS Score Over Time': (view: any, chartTitle: string) => new NPSScoreOverTimeService(
				view,
				chartTitle,
				'Sub-name (NPS)',
				'NPS Score (Bar)',
				'NPS Score',
				'MAT NPS',
			),
			'Product Experience': (view: any, chartTitle: string) => new ProductExperienceService(
				view,
				chartTitle,
				'Sub-name (PE)',
				'Tornado',
				'Bubble',
				'Bar chart',
			),
			'Sustainability': (view: any, chartTitle: string) => new PHBMCSustainabilityService(
				view,
				chartTitle,
				'BMC_Sustainability_title',
				'BMC_Sustainability',
			),
			'Market Share Value': (view: any, chartTitle: string) => new NineGLineChartValueService(
				view,
				chartTitle,
				'BMC - 9G (MSVal) title',
				'BMC - 9G (MSVal)',
			),
			'Market Share Volume': (view: any, chartTitle: string) => new NineGLineChartVolumeService(
				view,
				chartTitle,
				'BMC - 9G (MSVol) title',
				'BMC - 9G (MSVol)',
			),
			'Average Price': (view: any, chartTitle: string) => new NineGLineChartAvgPriceService(
				view,
				chartTitle,
				'BMC - 9G (Avg Price) title',
				'BMC - 9G (Avg Price)',
			),
			'Innovation Index': (view: any, chartTitle: string) => new NineGLineChartIndexService(
				view,
				chartTitle,
				'BMC - 9G (Index) title',
				'BMC - 9G (Index)',
			),
			'Weighted Distribution': (view: any, chartTitle: string) => new NineGLineChartDistributionService(
				view,
				chartTitle,
				'BMC - 9G (WDistr) title',
				'BMC - 9G (WDistr)',
			),
			'Ratings & Reviews': (view: any, chartTitle: string) => new NineGLineChartRnRService(
				view,
				chartTitle,
				'BMC - 9G (R&R) title',
				'BMC - 9G (R&R)',
			),
			'Media Spend Total': (view: any, chartTitle: string) => new NineGLineChartTotalService(
				view,
				chartTitle,
				'BMC - 9G (MS Total) title',
				'BMC - 9G (MS Total)',
			),
			'Share of Media Spend': (view: any, chartTitle: string) => new NineGLineChartShareService(
				view,
				chartTitle,
				'BMC - 9G (Share MS) title',
				'BMC - 9G (Share MS)',
			),
			'Philips Campaign TPs OT': (view: any, chartTitle: string) => new NineGLineChartCampaignsService(
				view,
				chartTitle,
				'BMC - 9G (Campaigns) title',
				'BMC - 9G (Campaigns)',
			),
			'Main Shaving Method': (view: any, chartTitle: string) => new CommonPieChartService(
				view,
				chartTitle,
				'Main Shaving Methods title',
				'Main Shaving Methods',
			),
			'Product Ownership': (view: any, chartTitle: string) => new CommonBarChartService(
				view,
				chartTitle,
				'Product Ownership title',
				'Product Ownership',
			),
			'Purchase Triggers': (view: any, chartTitle: string) => new CommonBarChartService(
				view,
				chartTitle,
				'Purchase triggers title',
				'Purchase triggers',
				'Purchase triggers title (2)',
				'Purchase triggers (2)',
			),
			'Touchpoints': (view: any, chartTitle: string) => new CommonBarChartService(
				view,
				chartTitle,
				'Touchpoints title (2)',
				'Touchpoints (2)',
				'Touchpoints title',
				'Touchpoints',
			),
			'Online/Offline PoP': (view: any, chartTitle: string) => new CommonPieChartService(
				view,
				chartTitle,
				'Online/Offline title',
				'Online/Offline PoP',
				'Online/Offline title (2)',
				'Online/Offline PoP (2)',
			),
			'Place of Purchase': (view: any, chartTitle: string) => new CommonBarChartService(
				view,
				chartTitle,
				'Place of Purchase title (2)',
				'Place of Purchase (2)',
				'Place of Purchase title',
				'Place of Purchase',
			),
			'Product Preference Score': (view: any, chartTitle: string) => new MCCPreferenceScoreService(
				view,
				chartTitle,
				'MCC - Preference Score title',
				'MCC - Preference Score',
			),
			'Product Preference Over Time': (view: any, chartTitle: string) => new MCCLineChartService(
				view,
				chartTitle,
				'MCC - POT title',
				'MCC - POT',
			),
			'Product Aided Awareness Over Time': (view: any, chartTitle: string) => new MCCLineChartService(
				view,
				chartTitle,
				'MCC - Awareness title',
				'MCC - Awareness',
			),
			'Product Consideration Over Time': (view: any, chartTitle: string) => new MCCLineChartService(
				view,
				chartTitle,
				'MCC - Consideration title',
				'MCC - Consideration',
			),
			'Recommendations': (view: any, chartTitle: string) => new RecommendationsService(
				view,
				chartTitle,
				'Recommendations title',
				'Recommendations',
			),
			'Recommended and Purchased Brands': (view: any, chartTitle: string) => new RecommendedBrandsService(
				view,
				chartTitle,
				'R&P Brands title',
				'R&P Brands',
			),
			'OneBlade Awareness': (view: any, chartTitle: string) => new IndicatorLineChartService(
				view,
				chartTitle,
				'OneBlade Awareness Title',
				'OneBlade Awareness',
			),
			'Source of Information': (view: any, chartTitle: string) => new IndicatorLineChartService(
				view,
				chartTitle,
				'OneBlade Source of Information Title',
				'OneBlade Source of Information',
			),
			'Linkage': (view: any, chartTitle: string) => new IndicatorLineChartService(
				view,
				chartTitle,
				'OneBlade Linkage Title',
				'OneBlade Linkage',
			),
			'Share of Ownership': (view: any, chartTitle: string) => new IndicatorLineChartService(
				view,
				chartTitle,
				'OneBlade Share of Ownership Title',
				'OneBlade Share Of Ownership',
			),
			'Preference MAT': (view: any, chartTitle: string) => new CommonLineChartService(
				view,
				chartTitle,
				'OneBlade Preference MAT Title',
				'OneBlade Preference MAT',
			),
			'IPL Familiarity': (view: any, chartTitle: string) => new FamiliarityService(
				view,
				chartTitle,
				'IPL Familiarity Title',
				'IPL Familiarity',
			),
			'IPL Preference Over Time': (view: any, chartTitle: string) => new CommonOverTimeLineChartService(
				view,
				chartTitle,
				'IPL Preference Title',
				'IPL Preference Over Time',
			),
			'IPL Aided Awareness Over Time': (view: any, chartTitle: string) => new CommonOverTimeLineChartService(
				view,
				chartTitle,
				'IPL Aided Awareness Title',
				'IPL Aided Awereness Over Time',
			),
			'IPL Consideration': (view: any, chartTitle: string) => new CommonOverTimeLineChartService(
				view,
				chartTitle,
				'IPL Consideration Title',
				'IPL Consideration Over Time',
			),
			'Market Data Comparison': (view: any, chartTitle: string) => new BMCSimulatorLineChartService(
				view,
				chartTitle,
				'MD Summary title',
				'MD Summary Philips',
				'MD Summary BestComp'
			),
		},
		[tableauView.PersonalHealthBSGlobal]: {
			'1. Brand Strength Total': (view: any, chartTitle: string) => new PHBSTotalChartService(
				view,
				chartTitle,
				'Global Brand Strength Total Title',
				'Global Brand Strength Total'
			),
			'2. Brand Strength Total Over Time': (view: any, chartTitle: string) => new PHBSTotalOverTimeChartService(
				view,
				chartTitle,
				'Global Brand Strength Total Over Time Title',
				'Global Brand Strength Total Over Time'
			),
			'3. Brand Strength per Audience Over Time': (view: any, chartTitle: string) => new PHBSAudienceOverTimeChartService(
				view,
				chartTitle,
				'Global Brand Strength per Aud Over Time Title',
				'Global Brand Strength per Aud Over Time'
			),
			'4. Brand Strength per Factor Over Time': (view: any, chartTitle: string) => new PHBSFactorOverTimeChartService(
				view,
				chartTitle,
				'Global Brand Strength per Factor Over Time Title',
				'Global Brand Strength per Factor Over Time'
			),
			'5. Brand Strength Analysis': (view: any, chartTitle: string) => new PHBSAnalysisTableService(
				view,
				chartTitle,
				'Global Brand Strength Title',
				'Global Brand Strength Analysis Factors',
				'Global Brand Strength Analysis Score',
				'Global Brand Strength Analysis vs Target',
				'Table Legend',
			),
			'6. Brand Strength by Audience per Brand': (view: any, chartTitle: string) => new PHBSGlobalAudiencePerBrandChartService(
				view,
				chartTitle,
				'Global Brand Strength by Audience per Brand Title',
				'Global Brand Strength by Audience per Brand',
			),
			'7. Brand Strength by Statement per Factor': (view: any, chartTitle: string) => new PHBSGlobalStatementPerFactorChartService(
				view,
				chartTitle,
				'Global Brand Strength by Statement per Factor Title',
				'Global Brand Strength Analysis by Statement per Factor (Relative)',
				'Global Brand Strength Analysis by Statement per Factor (Absolute)',
			),
			'8. Brand Strength by Statement per Factor Over Time': (view: any, chartTitle: string) => new PHBSGlobalStatementOverTimeChartService(
				view,
				chartTitle,
				'Global Brand Strength by Statement per Factor Over Time Title',
				'Global Brand Strength by Statement per Factor Over Time (Relative)',
				'Global Brand Strength by Statement per Factor Over Time (Absolute)',
			),
		},
		[tableauView.PersonalHealthBSMarket]: {
			'1. Brand Strength Total': (view: any, chartTitle: string) => new PHBSTotalChartService(
				view,
				chartTitle,
				'Market Brand Strength Total Title',
				'Market Brand Strength Total'
			),
			'2. Brand Strength Total Over Time': (view: any, chartTitle: string) => new PHBSTotalOverTimeChartService(
				view,
				chartTitle,
				'Market Brand Strength Total Over Time Title',
				'Market Brand Strength Total Over Time'
			),
			'3. Brand Strength per Audience Over Time': (view: any, chartTitle: string) => new PHBSAudienceOverTimeChartService(
				view,
				chartTitle,
				'Market Brand Strength per Audience Over Time Title',
				'Market Brand Strength per Audience Over Time'
			),
			'4. Brand Strength per Factor Over Time': (view: any, chartTitle: string) => new PHBSFactorOverTimeChartService(
				view,
				chartTitle,
				'Market Brand Strength per Factor Over Time Title',
				'Market Brand Strength per Factor Over Time'
			),
			'5. Brand Strength Analysis': (view: any, chartTitle: string) => new PHBSAnalysisTableService(
				view,
				chartTitle,
				'Market Brand Strength Title',
				'Market Brand Strength Analysis Factors',
				'Market Brand Strength Analysis Score',
				'Market Brand Strength Analysis vs Target',
				'Table Legend',
			),
			'6. Brand Strength Analysis by Brand': (view: any, chartTitle: string) => new PHBSMarketAnalysisByBrandTableService(
				view,
				chartTitle,
				'Market Brand Strength Title by Aud',
				'Market Brand Strength Analysis by Audience',
				'Market Brand Strength Analysis Score by Aud',
				'Table Legend',
			),
			'7. Brand Strength by Audience per Brand': (view: any, chartTitle: string) => new PHBSMarketAudiencePerBrandChartService(
				view,
				chartTitle,
				'Market Brand Strength by Audience per Brand Title',
				'Market Brand Strength by Audience per Brand',
			),
			'8. Brand Strength by Statement per Factor': (view: any, chartTitle: string) => new PHBSMarketStatementPerFactorChartService(
				view,
				chartTitle,
				'Market Brand Strength Analysis by Statement Title',
				'Market Brand Strength Analys by Statament (Relative)',
				'Market Brand Strength Analys by Statament (Absolute)',
			),
			'9. Brand Strength by Statement per Factor Over Time': (view: any, chartTitle: string) => new PHBSMarketStatementOverTimeChartService(
				view,
				chartTitle,
				'Market Brand Strength by Statement Over Time Title',
				'Market Brand Strength by Statement Over Time (Relative)',
				'Market Brand Strength by Statement Over Time (Absolute)',
			),
			'10. Brand Strength Absolute Performance': (view: any, chartTitle: string) => new PHBSMarketPerformanceChartService(
				view,
				chartTitle,
				'Market Brand KPIs Title',
				'Market Brand KPIs Tornado',
				'Market Brand KPIs Bubble',
				'Market Brand KPIs Bar (Bars)',
			),
			'11. Brand Strength by Statement per Brand': (view: any, chartTitle: string) => new PHBSMarketStatementPerBrandChartService(
				view,
				chartTitle,
				'Market Brand Strength by Statement per Brand Title',
				'Market Brand Strength by Statement per Brand',
			),
		},
		[tableauView.PersonalHealthBSDeepDiveByMarkets]: {
			'1. Deep Dive by Markets (Total Brand Strength Score)': (view: any, chartTitle: string) => new PHBSDeepDiveService(
				view,
				chartTitle,
				'Deep Dive by Market Title',
				'Deep Dive by Market'
			),
		},
		[tableauView.PersonalHealthBSDeepDiveByAudiences]: {
			'1. Deep Dive by Audiences (Total Brand Strength Score)': (view: any, chartTitle: string) => new PHBSDeepDiveService(
				view,
				chartTitle,
				'Deep Dive by Audience Title',
				'Deep Dive by Audience'
			),
		},
		[tableauView.PersonalHealthBSEmployeeDeepDive]: {
			'1. Employee Factors by Statement': (view: any, chartTitle: string) => new PHBSEmployeeDeepDiveTableService(
				view,
				chartTitle,
				'Employee Factors by Statement Title',
				'Employee Factors by Statement (Factors)',
				'Employee Factors by Statement (Statements)',
				'Table Legend',
			),
			'2. Employee Factors by Statement Over Time': (view: any, chartTitle: string) => new PHBSEmployeeDeepDiveOverTimeService(
				view,
				chartTitle,
				'Employee Factors by Statement Over Time Title',
				'Employee Factors by Statement Over Time (Factor)',
				'Employee Factors by Statement Over Time (Statement)'
			),
		},
		[tableauView.SRCSummary]: {
			'Executive Summary': (view: any, chartTitle: string) => new SummaryService(
				view,
				chartTitle,
				'Summary Title',
				'Summary All',
			),
		},
		[tableauView.SRCSnapshot]: {
			'Snapshot': (view: any, chartTitle: string) => new SnapshotService(
				view,
				chartTitle,
				'Title Snapshot',
				'BP bar Snapshot Title',
				'BP bar Snapshot',
				'NPS Snapshot Title',
				'NPS bar Snapshot (2)',
				'NPS Table Snapshot',
				'BP Line Snapshot Title',
				'BP Line Snapshot',
				'Most Recommended Bar Snapshot Title',
				'Most Recommended Bar Snapshot (2)',
				'Note Bases Snapshot',
			),
		},
		[tableauView.SRCGlobalResults]: {
			'Philips Brand Preference Score': (view: any, chartTitle: string) => new FirstChoiceChartService(
				view,
				chartTitle,
				'First Choice % Title',
				'First Choice %',
				'First Choice Line chart',
			),
			'Brand Preference Sample Size': (view: any, chartTitle: string) => new FirstChoiceChartService(
				view,
				chartTitle,
				'First Choice Base size Title',
				'First Choice Base size',
				'',
			),
			'Philips Clinical Metric': (view: any, chartTitle: string) => new FirstChoiceChartService(
				view,
				chartTitle,
				'First Choice % Title',
				'First Choice %',
				'First Choice Line chart',
			),
			'Clinical Metric Sample Size': (view: any, chartTitle: string) => new FirstChoiceChartService(
				view,
				chartTitle,
				'First Choice Base size Title',
				'First Choice Base size',
				'',
			),
			'Philips Product NPS': (view: any, chartTitle: string) => new FirstChoiceChartService(
				view,
				chartTitle,
				'First Choice % Title',
				'First Choice %',
				'First Choice Line chart',
			),
			'Product NPS Sample Size': (view: any, chartTitle: string) => new FirstChoiceChartService(
				view,
				chartTitle,
				'First Choice Base size Title',
				'First Choice Base size',
				'',
			),
		},
		[tableauView.SRCCompetitorsGlobally]: {
			"Brand Preference Score": (view: any, chartTitle: string) => new CompetitorsGloballyService(
				view,
				chartTitle,
				'Title ',
				'First Choice % w Competitors (5)',
				'Text Physicians',
			),
		},
		[tableauView.SRCCompetitorsGloballyNPS]: {
			"Product NPS Score": (view: any, chartTitle: string) => new CompetitorsGloballyNPSService(
				view,
				chartTitle,
				'Title NPS',
				'NPS w Competitors',
				'Text Physicians NPS',
			),
		},
		[tableauView.SRCMarketOverview]: {
			"Brand Analysis": (view: any, chartTitle: string) => new MarketOverviewTableService(
				view,
				chartTitle,
				'MO Title',
				'First Choice % w Competitors_Export',
			),
		},
		[tableauView.SRCCompetitorsPerMarket]: {
			"Brand Preference Score": (view: any, chartTitle: string) => new CompetitorsPerMarketService(
				view,
				chartTitle,
				'CompPerMarketTitle (2)',
				'First Choice % w Competitors (2)',
				'First Choice % w Competitors (3)',
			),
		},
		[tableauView.SRCDemographics]: {
			"Demographics": (view: any, chartTitle: string) => new DemographicsPieChartService(
				view,
				chartTitle,
				'Demo_Title',
				'Comments_Demo',
				'Demo_1 (2)',
				'Demo_2 (3)',
				'Demo_3 (2)',
				'Demo_4 (3)',
				'Demo_count',
			),
		},
		[tableauView.SRCAppendixUS]: {
			"KDA Results Comparison": (view: any, chartTitle: string) => new AppendixUSService(
				view,
				chartTitle,
				'AppUSTitle',
				'Comments_KDA_Sleep_USA',
				'KDA_with BC Comparison USA',
			),
		},
		[tableauView.SRCAppendixJapan]: {
			"Overview per Disease": (view: any, chartTitle: string) => new AppendixJapanService(
				view,
				chartTitle,
				'Japan Title',
				'Japan Comments',
				'Japan Pie chart',
				'Japan Table',
				'noteBasesApJap',
			),
			"Overview per Specialty": (view: any, chartTitle: string) => new AppendixJapanService(
				view,
				chartTitle,
				'Japan Title',
				'Japan Comments',
				'Japan Pie chart',
				'Japan Table',
				'noteBasesApJap',
			),
		},
		[tableauView.SRCRecommendationPatterns]: {
			"Recommendation Patterns": (view: any, chartTitle: string) => new RecommendationPatternsService(
				view,
				chartTitle,
				'RS_Title',
				'Comments_RecommPatterns',
				'Most Often Pie',
				'Most Often Bar (2)',
				'Most Often Sample',
			),
		},
		[tableauView.SRCPurchaseFunnel]: {
			"Brand Preference": (view: any, chartTitle: string) => new PurchaseFunnelService(
				view,
				chartTitle,
				'PH_Title',
				'Comments_Purchase funnel',
				'Comments_Purchase funnel_Month',
				'Funnel PH',
				'Funnel BC_2',
				'Ratios_Philips_table',
				'Ratios_BC 1_table',
				'Ratios_BC 2_table',
				'Familiarity PH',
				'Familiarity',
				'Familiarity BC 2',
				// Monthly data.
				'PH_Title (Month)',
				'Funnel PH_Month',
				'Funnel BC_2 (2)',
				'Ratios_Philips_table_Month',
				'Ratios_BC 1_table_Month',
				'Ratios_BC 2_table_Month',
				'Familiarity PH_Month',
				'Familiarity_Month',
				'Familiarity BC 2_Month',
				'Funnel Sample',
				'Funnel Sample_Month',
			),
			"Purchase Funnel - Trend Lines": (view: any, chartTitle: string) => new PurchaseFunnelLineChartService(
				view,
				chartTitle,
				'Funnel Line Title',
				'Funnel Line',
				// Monthly data.
				'Funnel Line Month Title',
				'Funnel Line Month',				
			),
		},
		[tableauView.SRCDriverAnalysis]: {
			"Key Driver Analysis": (view: any, chartTitle: string) => new KeyDriverAnalysisService(
				view,
				chartTitle,
				'KDA_Title',
				'KDA_with BC',
				'Comments_KDA',
				'Verbatims',
				'KDA Sample (2)',
			),
			"Brand Preference Driver Scores": (view: any, chartTitle: string) => new DriverScoresService(
				view,
				chartTitle,
				'KDA_Title',
				'Comments_KDA',
				'KDA Sample (2)',
				'KDA_with BC_Barchart',
			),
		},
		[tableauView.SRCProductNPSResults]: {			
			"Product NPS Trend": (view: any, chartTitle: string) => new ProductNPSLineChartService(
				view,
				chartTitle,
				'NPS line Title',
				'NPS Line',
				// Monthly data.
				'NPS line Title (M)',
				'NPS Line (Month)',		
				'NPS Note',		
			),
			"Product NPS Score": (view: any, chartTitle: string) => new ProductNPSResultsService(
				view,
				chartTitle,
				'NPS bar Title',
				'NPS bar (2)',
				'NPS Table',
				// Monthly data.
				'NPS bar Title (M)',
				'NPS bar (Month) (2)',	
				'NPS Table (Month)',			
			),
		},
		[tableauView.SRCGlobalBrandStrength]: {
			"2. Brand Strength Total Over Time": (view: any, chartTitle: string) => new SRCBSTotalOverTimeChartService(
				view,
				chartTitle,
				"Global Brand Strenght Total Over Time Title",
				"Global Brand Strenght Total Over Time"
			),
			"1. Brand Strength Total": (view: any, chartTitle: string) => new SRCBSTotalChartService(
				view,
				chartTitle,
				"Global Brand Strenght Total Title",
				"Global Brand Strenght Total"
			),
			'5. Brand Strength Analysis': (view: any, chartTitle: string) => new SRCBSAnalysisTableService(
				view,
				chartTitle,
				'Global Brand Strenght Title',
				'Global Brand Strenght Shapes',
				'Global Brand Strength Analysis Factors',
				'Global Brand Strength Analysis Score',
				'Global Brand Strength Analysis vs Target',
			),
		},
		[tableauView.SRCMarketBrandStrength]: {
			"2. Brand Strength Total Over Time": (view: any, chartTitle: string) => new SRCBSTotalOverTimeChartService(
				view,
				chartTitle,
				"Market Brand Strenght Total Over Time Title",
				"Market Brand Strenght Total Over Time"
			),
			"1. Brand Strength Total": (view: any, chartTitle: string) => new SRCBSTotalChartService(
				view,
				chartTitle,
				"Market Brand Strenght Total Title",
				"Market Brand Strenght Total"
			),
			"9. Brand Strength by Statement per Factor Over Time": (view: any, chartTitle: string) => new SRCBSByStatementOverTimeChartService(
				view,
				chartTitle,
				'Market Brand Strength by Statement Over Time Title',
				'Market Brand Strenght by Statement Over Time',
			),
			"8. Brand Strength by Statement per Factor": (view: any, chartTitle: string) => new SRCBSByStatementChartService(
				view,
				chartTitle,
				'Market Brand Strength Analysis by Statement Title',
				'Market Brand Strenght Analys by Statament',
			),
			"6. Brand Strength Analysis by Brand": (view: any, chartTitle: string) => new SRCBSAnalysisByAudienceTableService(
				view,
				chartTitle,
				'Market Brand Strenght Title by Aud',
				'Market Brand Strenght Shapes by Aud',
				'Market Brand Strength Analysis by Audience',
				'Market Brand Strength Analysis Score by Aud',
				'Market Brand Strength Analysis vs Target by Aud',
			),
			'5. Brand Strength Analysis': (view: any, chartTitle: string) => new SRCBSAnalysisTableService(
				view,
				chartTitle,
				'Market Brand Strenght Title',
				'Market Brand Strenght Shapes',
				'Market Brand Strength Analysis Factors',
				'Market Brand Strength Analysis Score',
				'Market Brand Strength Analysis vs Target',
			),
			'10. Brand Strength Absolute Performance': (view: any, chartTitle: string) => new SRCBSPerformanceChartService(
				view,
				chartTitle,
				'Market Brand KPIs Title',
				'Market Brand KPIs Tornado',
				'Market Brand KPIs Bubble',
				'Market Brand KPIs Bar (Bars)',
			),
		},
	};

	private static genericViewTitleSheetMap: { [name: string]: (view: any) => Promise<string[]> } = {
		[tableauView.Overview]: ChartDownloadHelper.getOverviewChartNames,
		[tableauView.NPSDrivers]: ChartDownloadHelper.getNPSDriversChartNames,
		[tableauView.NPSTrend]: ChartDownloadHelper.getNPSTrendsChartNames,
		[tableauView.NPSTrendQuarterly]: ChartDownloadHelper.getNPSTrendsQuarterlyChartNames,
		[tableauView.HospitalIDNStrategicPartner]: ChartDownloadHelper.getSPChartNames,
		[tableauView.ClinicalSegment]: ChartDownloadHelper.getClinicalSegmentChartNames,
		[tableauView.Marketing]: ChartDownloadHelper.getMarketingChartNames,
		[tableauView.NPSLeadershipStatus]: ChartDownloadHelper.getNPSLeadershipStatusChartName,
		[tableauView.HSGlobalBrandStrength]: ChartDownloadHelper.getHSGlobalBrandStrengthChartNames,
		[tableauView.HSMarketBrandStrength]: ChartDownloadHelper.getHSMarketBrandStrengthChartNames,
    	[tableauView.PersonalHealthGlobal]: ChartDownloadHelper.getGlobalPersonalHealthChartNames,
		[tableauView.PersonalHealthCountry]: ChartDownloadHelper.getCountryPersonalHealthChartNames,
		[tableauView.PersonalHealthCategory]: ChartDownloadHelper.getCategoryPersonalHealthChartNames,
		[tableauView.PersonalHealthIPLAdditional]: ChartDownloadHelper.getIPLAdditionalPersonalHealthChartNames,
		[tableauView.PersonalHealthBMC]: ChartDownloadHelper.getBrandBMCHealthChartNames,
		[tableauView.PersonalHealthBSGlobal]: ChartDownloadHelper.getPersonalHealthBSGlobalNames,
		[tableauView.PersonalHealthBSMarket]: ChartDownloadHelper.getPersonalHealthBSMarketNames,
		[tableauView.PersonalHealthBSDeepDiveByMarkets]: ChartDownloadHelper.getPersonalHealthBSDeepDiveByMarketsNames,
		[tableauView.PersonalHealthBSDeepDiveByAudiences]: ChartDownloadHelper.getPersonalHealthBSDeepDiveByAudiencesNames,
		[tableauView.PersonalHealthBSEmployeeDeepDive]: ChartDownloadHelper.getPersonalHealthBSEmployeeDeepDiveNames,
		[tableauView.SRCSummary]: ChartDownloadHelper.getSRCSummary,
		[tableauView.SRCSnapshot]: ChartDownloadHelper.getSRCSnapshot,
		[tableauView.SRCGlobalResults]: ChartDownloadHelper.getSRCGlobalResults,
		[tableauView.SRCMarketOverview]: ChartDownloadHelper.getSRCMarketOverview,
		[tableauView.SRCCompetitorsPerMarket]: ChartDownloadHelper.getSRCCompetitorsPerMarket,
		[tableauView.SRCCompetitorsGlobally]: ChartDownloadHelper.getSRCCompetitorsGlobally,
		[tableauView.SRCCompetitorsGloballyNPS]: ChartDownloadHelper.getSRCCompetitorsGloballyNPSNames,
		[tableauView.HSSustainability]: ChartDownloadHelper.getHSSustainability,
		[tableauView.SRCDemographics]: ChartDownloadHelper.getSRCDemographics,
		[tableauView.SRCAppendixUS]: ChartDownloadHelper.getSRCAppendixUS,
		[tableauView.SRCAppendixJapan]: ChartDownloadHelper.getSRCAppendixJapan,
		[tableauView.SRCRecommendationPatterns]: ChartDownloadHelper.getSRCRecommendationPatterns,
		[tableauView.SRCPurchaseFunnel]: ChartDownloadHelper.getSRCPurchaseFunnel,
		[tableauView.SRCDriverAnalysis]: ChartDownloadHelper.getSRCDriverAnalysis,
		[tableauView.SRCProductNPSResults]: ChartDownloadHelper.getSRCProductNPSResults,
		[tableauView.SRCGlobalBrandStrength]: ChartDownloadHelper.getSRCGlobalBrandStrengthChartNames,
		[tableauView.SRCMarketBrandStrength]: ChartDownloadHelper.getSRCMarketBrandStrengthChartNames,
	};

	// HS pages
	// HS - Product level NPS - Product NPS Overview
	private static async getOverviewChartNames(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(RelativeProductNPSChartTitleModel, view, 'Philips Relative NPS_Title'),
			TableauApiHelper.getMappedChartData(MainProductNPSChartTitleModel, view, 'TITLE_Main Product NPS Attributes per BG: Global'),
		]);

		const titles: string[] = [];

		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.forEach((obj: any) => {
				titles.push(`${obj.title} - ${obj.period}`);
			});
		});

		return titles;
	}

	// HS - Product level NPS - Product NPS Leadership Status
	private static async getNPSLeadershipStatusChartName(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(NpsByBusinessGroupChartTitleModel, view, 'Product NPS Leadership Title'),
		]);

		const titles: string[] = [];

		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.forEach((obj: any) => {
				titles.push(`${obj.title}${obj.period}`);
			});
		});

		return titles;
	}

	// HS - Product level NPS - Product NPS Attributes
	private static async getNPSDriversChartNames(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(NpsAttributesDeltaChartTitleModel, view, 'Philips NPS Title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.forEach( obj => {
				titles.push(`${obj.title} · ${obj.country} · ${obj.businessGroup} · ${obj.modality} · ${obj.subModality} · ${obj.subPeriod} ${obj.period}`);
			});
		});

		// bubble chart
		let titleData = await TableauApiHelper.getChartData(view, 'Philips NPS Title (Bubble)');

		let titleIndex = 3;
		let countryIndex = 6;
		let bgIndex = 0;
		let modalityIndex = 2;
		let subModalityFilterIndex = 5;
		let periodIndex = 4;
		let subPeriodIndex = 1;

		titleData.forEach((rowArray: any[]) => {
			const title = rowArray[titleIndex].formattedValue;
			const country = rowArray[countryIndex].formattedValue;
			const businessGroup = rowArray[bgIndex].formattedValue;
			const modality = rowArray[modalityIndex].formattedValue;
			const subModalityFilter = rowArray[subModalityFilterIndex].formattedValue;
			const period = rowArray[periodIndex].formattedValue;
			const subPeriod = rowArray[subPeriodIndex].formattedValue;
			titles.push(`${title} · ${country} · ${businessGroup} · ${modality} · ${subModalityFilter} · ${subPeriod} ${period}`);
		});

		return titles;
	}

	// HS - Product level NPS - Product NPS Trends
	private static async getNPSTrendsChartNames(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(NPSTrendsLineChartTitleModel, view, 'Country_Trend Line Title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.forEach( obj => {
				titles.push(`${obj.Title}${obj.Country} · ${obj.BG} · ${obj.Modality} · ${obj.Submodality}`);
			});
		});

		return titles;	    
	}

	// HS - Product level NPS - Product NPS Trends Quarterly
	private static async getNPSTrendsQuarterlyChartNames(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(NPSTrendsQuarterlyTitleModel, view, 'Country_Trend Line Title (Q)'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.forEach(obj => {
				titles.push(`${obj.Title}${obj.Country} · ${obj.BG} · ${obj.Modality} · ${obj.Submodality}`);
			});
		});

		return titles;
	}

	// HS - Strategic Partnership - Hospital/IDN Strategic Partner
	private static async getSPChartNames(view: any) {			
		const titlesData = await Promise.all([
			TableauApiHelper.getChartData(view, 'SP at Hospital/IDN level TEXT'),
			TableauApiHelper.getChartData(view, 'Single/Main SP at Hospital/IDN level TEXT'),
			TableauApiHelper.getChartData(view, 'Most Important Attributes Driving Partner Choice_Label TEXT'),
			TableauApiHelper.getChartData(view, 'MIA All Legend TEXT'),
		]);
		const level = titlesData[2][0][1].formattedValue;
		const temp_period: string = titlesData[0][0][1].formattedValue
		const titles: string[] = [`Hospital/ IDN Strategic Partner: ${level} - ${temp_period}`];

		titlesData.forEach((rowArrays: any[], index: number) => {
			const rowArray = rowArrays[0];
			const periodIndex: number = rowArray.length - 1;
			const period: string = rowArray[periodIndex].formattedValue;
			switch (index) {
				case 0:
				case 1:
					if (level === 'Global') {
						titles.push(`${rowArray[0].formattedValue} Market Overview - ${period || ''}`);
					}
					break;
				case 3:
						titles.push(`${rowArray[1].formattedValue} ${level} - ${period || ''}`);
					break;
				case 2:
						titles.push(`${rowArray[0].formattedValue} ${level} - ${period || ''}`);
					break;
				default:
					break;
			}
		});

		return titles;
	}

	// HS - Strategic Partnership - ClinicalSegment
	private static async getClinicalSegmentChartNames(view: any) {
		const titles: string[] = [];

		const SPVendorChartTitle = await TableauApiHelper.getMappedChartData(SPVendorPieChartTitleModel, view, 'MIA Ology Pie TEXT');
		SPVendorChartTitle.forEach((obj: any) => {
			titles.push(`${obj.Title}`);
		});

		const CAImportanceChartTitle = await TableauApiHelper.getMappedChartData(CAImportanceChartTitleModel, view, 'Areas of Focus TEXT');
		CAImportanceChartTitle.forEach((obj: any) => {
			titles.push(`${obj.Title} ${obj.ClinicalSegment}`);
		});

		return titles;
	}

	// HS - Strategic Partnership - Marketing
	private static async getMarketingChartNames(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(UnaidedRecallCommunicationsChartTitleModel, view, 'URC hospital/IDN level TEXT'),
			TableauApiHelper.getMappedChartData(UnaidedRecallSegmentCommunicationsChartTitleModel, view, 'URC Ology TEXT'),
			TableauApiHelper.getMappedChartData(UnaidedChannelRecallChartTitleModel, view, 'UCR per brand TEXT'),
		]);
		
		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.forEach((obj: any) => {
				if (obj.Segment) {
					titles.push(`${obj.Title} ${obj.Country} - ${obj.Segment} - ${obj.Period}`);
				} else {
					titles.push(`${obj.Title} ${obj.Country} - ${obj.Period}`);
				}
			});
		});

		const communicationChannels = await TableauApiHelper.getMappedChartData(CommunicationChannelsChartTitleModel, view, 'Comm Channels TEXT');
		communicationChannels.forEach((obj: any) => {
				titles.push(`${obj.Title}: ${obj.Country} - ${obj.Period}`);
		});

		return titles;
	}

	// HS - Sustainability
	private static async getHSSustainability(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(SustainabilityLineChartTitleModel, view, 'SST_title_1'),
			TableauApiHelper.getMappedChartData(SustainabilityLineChartTitleModel, view, 'SST_title_2'),
			TableauApiHelper.getMappedChartData(SustainabilityLineChartTitleModel, view, 'SST_title_3'),
			TableauApiHelper.getMappedChartData(SustainabilityLineChartTitleModel, view, 'SST_title_4'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map( obj => {
				return titles.push(`${obj.SubTitle}${obj.Title}`);
			})
		})
		return titles;
	}

	// HS - Global Brand Strength
	private static async getHSGlobalBrandStrengthChartNames(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(BSTotalChartTitleModel, view, 'Global Brand Strenght Total Title'),
			TableauApiHelper.getMappedChartData(BSTotalOverTimeChartTitleModel, view, 'Global Brand Strenght Total Over Time Title'),
			TableauApiHelper.getMappedChartData(BSAnalysisTableTitleModel, view, 'Global Brand Strenght Title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map(obj => {
				return titles.push(`${obj.Title}`);
			})
		});
		
		return titles;
	}

	// HS - Market Brand Strength
	private static async getHSMarketBrandStrengthChartNames(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(BSTotalChartTitleModel, view, 'Market Brand Strenght Total Title'),
			TableauApiHelper.getMappedChartData(BSTotalOverTimeChartTitleModel, view, 'Market Brand Strenght Total Over Time Title'),
			TableauApiHelper.getMappedChartData(BSAnalysisTableTitleModel, view, 'Market Brand Strenght Title'),
			TableauApiHelper.getMappedChartData(BSAnalysisByAudienceTableTitleModel, view, 'Market Brand Strenght Title by Aud'),
			TableauApiHelper.getMappedChartData(BSByStatementChartTitleModel, view, 'Market Brand Strength Analysis by Statement Title'),
			TableauApiHelper.getMappedChartData(BSByStatementOverTimeChartTitleModel, view, 'Market Brand Strength by Statement Over Time Title'),
			TableauApiHelper.getMappedChartData(BSPerformanceChartTitleModel, view, 'Market Brand KPIs Title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map(obj => {
				return titles.push(`${obj.Title}`);
			})
		});
		
		return titles;
	}

	// PH pages.
  	private static async getGlobalPersonalHealthChartNames(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(PreferenceScoreTitleModel, view, 'Global Preference Score Title'),
			TableauApiHelper.getMappedChartData(PreferenceOverTimeTitleModel, view, 'Global POT Title'),
			TableauApiHelper.getMappedChartData(ContributorsTitleModel, view, 'Global Tornado Title'),
			TableauApiHelper.getMappedChartData(PreferenceOverTimeComparisonTitleModel, view, 'Global POT Comparison Title'),
			TableauApiHelper.getMappedChartData(PHSustainabilityTitleModel, view, 'Global_Sustainability_title'),
		]);

		// Example to find the properties for the new models - just change the endpoint.
		// Also, look in TableauApiHelper file - inside getChartData for the incomming raw data titles.
		// const rawData = await TableauApiHelper.getChartData(view , 'Global Preference Score Title');
		
		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map( obj => {
				return titles.push(`${obj.Title} - Global`);
			})
		})
		return titles;
	}

	private static async getCountryPersonalHealthChartNames(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(PreferenceScoreTitleModel, view, 'Country Preference Score Title'),
			TableauApiHelper.getMappedChartData(PreferenceOverTimeTitleModel, view, 'Country POT Title'),
			TableauApiHelper.getMappedChartData(ContributorsTitleModel, view, 'Country Tornado Title'),
			TableauApiHelper.getMappedChartData(PreferenceOverTimeComparisonTitleModel, view, 'Country POT Comparison Title'),
			TableauApiHelper.getMappedChartData(PHSustainabilityTitleModel, view, 'Country_Sustainability_title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map( obj => {
				return titles.push(`${obj.Title} - ${obj.SubTitleCountry ? obj.SubTitleCountry : obj.SubTitle}`);
			})
		})
		return titles;
	}

	private static async getCategoryPersonalHealthChartNames(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(PreferenceScoreTitleModel, view, 'Category Preference Score Title'),
			TableauApiHelper.getMappedChartData(PreferenceOverTimeTitleModel, view, 'Category POT Title'),
			TableauApiHelper.getMappedChartData(ContributorsTitleModel, view, 'Category Tornado Title'),
			TableauApiHelper.getMappedChartData(PreferenceOverTimeComparisonTitleModel, view, 'Category POT Comparison Title'),
			TableauApiHelper.getMappedChartData(PHSustainabilityTitleModel, view, 'Category_Sustainability_title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map( obj => {
				return titles.push(`${obj.Title} - ${obj.SubTitleCategory ? obj.SubTitleCategory : obj.SubTitle}`);
			})
		})
		return titles;
	}

	private static async getIPLAdditionalPersonalHealthChartNames(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(FamiliarityTitleModel, view, 'IPL_A Familiarity Title'),
			TableauApiHelper.getMappedChartData(CommonOverTimeLineChartTitleModel, view, 'IPL_A Preference Title'),
			TableauApiHelper.getMappedChartData(CommonOverTimeLineChartTitleModel, view, 'IPL_A Aided Awareness Title'),
			TableauApiHelper.getMappedChartData(CommonOverTimeLineChartTitleModel, view, 'IPL_A Consideration Title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map( obj => {
				return titles.push(obj.Title);
			})
		})
		return titles;
	}

	private static async getBrandBMCHealthChartNames(view: any) {
		const firstChunkTitles = await Promise.all([
			TableauApiHelper.getMappedChartData(BMCPreferenceScoreTitleModel, view, 'BMC - PS Title'),
			TableauApiHelper.getMappedChartData(BMCPreferenceOverTimeTitleModel, view, 'BMC Preference Over Time (Title)'),
			TableauApiHelper.getMappedChartData(BMCOverTimeLineChartTitleModel, view, 'BMC -AA Title'),
			TableauApiHelper.getMappedChartData(BMCOverTimeLineChartTitleModel, view, 'BMC - Consideration Title'),
		]);

		const secondChunkTitles = await Promise.all([
			TableauApiHelper.getMappedChartData(BrandOwnershipTitleModel, view, 'BMC Brand Ownership Title'),
			TableauApiHelper.getMappedChartData(CategoryUserProfileTitleModel, view, 'BMC Category User Profile Title'),
			TableauApiHelper.getMappedChartData(NPSScoreOverTimeTitleModel, view, 'Sub-name (NPS)'),
			TableauApiHelper.getMappedChartData(ProductExperienceTitleModel, view, 'Sub-name (PE)'),
			TableauApiHelper.getMappedChartData(PHBMCSustainabilityTitleModel, view, 'BMC_Sustainability_title'),
			TableauApiHelper.getMappedChartData(IndicatorLineChartTitleModel, view, 'OneBlade Awareness Title'),
			TableauApiHelper.getMappedChartData(IndicatorLineChartTitleModel, view, 'OneBlade Source of Information Title'),
			TableauApiHelper.getMappedChartData(IndicatorLineChartTitleModel, view, 'OneBlade Linkage Title'),
			TableauApiHelper.getMappedChartData(IndicatorLineChartTitleModel, view, 'OneBlade Share of Ownership Title'),
			TableauApiHelper.getMappedChartData(CommonLineChartTitleModel, view, 'OneBlade Preference MAT Title'),
			TableauApiHelper.getMappedChartData(FamiliarityTitleModel, view, 'IPL Familiarity Title'),
			TableauApiHelper.getMappedChartData(CommonOverTimeLineChartTitleModel, view, 'IPL Preference Title'),
	 		TableauApiHelper.getMappedChartData(CommonOverTimeLineChartTitleModel, view, 'IPL Aided Awareness Title'),
	 		TableauApiHelper.getMappedChartData(CommonOverTimeLineChartTitleModel, view, 'IPL Consideration Title'),
		]);

		const nineGTitlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(NineGLineChartValueTitleModel, view, 'BMC - 9G (MSVal) title'),
			TableauApiHelper.getMappedChartData(NineGLineChartVolumeTitleModel, view, 'BMC - 9G (MSVol) title'),
			TableauApiHelper.getMappedChartData(NineGLineChartAvgPriceTitleModel, view, 'BMC - 9G (Avg Price) title'),
			TableauApiHelper.getMappedChartData(NineGLineChartIndexTitleModel, view, 'BMC - 9G (Index) title'),
			TableauApiHelper.getMappedChartData(NineGLineChartDistributionTitleModel, view, 'BMC - 9G (WDistr) title'),
			TableauApiHelper.getMappedChartData(NineGLineChartRnRTitleModel, view, 'BMC - 9G (R&R) title'),
			TableauApiHelper.getMappedChartData(NineGLineChartTotalTitleModel, view, 'BMC - 9G (MS Total) title'),
			TableauApiHelper.getMappedChartData(NineGLineChartShareTitleModel, view, 'BMC - 9G (Share MS) title'),
			TableauApiHelper.getMappedChartData(NineGLineChartCampaignsTitleModel, view, 'BMC - 9G (Campaigns) title'),
		]);

		const productsPage = await Promise.all([
			TableauApiHelper.getMappedChartData(CommonPieChartTitleModel, view, 'Main Shaving Methods title'),
			TableauApiHelper.getMappedChartData(CommonBarChartTitleModel, view, 'Product Ownership title'),
			TableauApiHelper.getMappedChartData(CommonBarChartTitleModel, view, 'Purchase triggers title (2)'),
			TableauApiHelper.getMappedChartData(CommonBarChartTitleModel, view, 'Touchpoints title (2)'),
			TableauApiHelper.getMappedChartData(CommonPieChartTitleModel, view, 'Online/Offline title (2)'),
			TableauApiHelper.getMappedChartData(CommonBarChartTitleModel, view, 'Place of Purchase title (2)'),
			TableauApiHelper.getMappedChartData(MCCPreferenceScoreTitleModel, view, 'MCC - Preference Score title'),
			TableauApiHelper.getMappedChartData(MCCLineChartTitleModel, view, 'MCC - POT title'),
			TableauApiHelper.getMappedChartData(MCCLineChartTitleModel, view, 'MCC - Awareness title'),
			TableauApiHelper.getMappedChartData(MCCLineChartTitleModel, view, 'MCC - Consideration title'),
			TableauApiHelper.getMappedChartData(CommonBarChartTitleModel, view, 'Purchase triggers title'),
			TableauApiHelper.getMappedChartData(CommonBarChartTitleModel, view, 'Touchpoints title'),
			TableauApiHelper.getMappedChartData(CommonPieChartTitleModel, view, 'Online/Offline title'),
			TableauApiHelper.getMappedChartData(CommonBarChartTitleModel, view, 'Place of Purchase title'),
			TableauApiHelper.getMappedChartData(RecommendationsTitleModel, view, 'Recommendations title'),
			TableauApiHelper.getMappedChartData(RecommendedBrandsTitleModel, view, 'R&P Brands title'),
		]);
		
		const bmcSimulatorPage = await Promise.all([
			TableauApiHelper.getMappedChartData(BMCSimulatorLineChartTitleModel, view, 'MD Summary title'),
		]);
		
		const combineTitles = [...firstChunkTitles, ...secondChunkTitles, ...nineGTitlesData, ...productsPage, ...bmcSimulatorPage]
		const titles: string[] = [];
		combineTitles.forEach((rowArrays: any[]) => {
			rowArrays.forEach(obj => {
				if (obj.Title) {
					titles.push(obj.Title);
				}
			})
		})
		return titles;
	}

	// PH - Global Brand Strength
	private static async getPersonalHealthBSGlobalNames(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(PHBSTotalChartTitleModel, view, 'Global Brand Strength Total Title'),
			TableauApiHelper.getMappedChartData(PHBSTotalOverTimeChartTitleModel, view, 'Global Brand Strength Total Over Time Title'),
			TableauApiHelper.getMappedChartData(PHBSAudienceOverTimeChartTitleModel, view, 'Global Brand Strength per Aud Over Time Title'),
			TableauApiHelper.getMappedChartData(PHBSFactorOverTimeChartTitleModel, view, 'Global Brand Strength per Factor Over Time Title'),
			TableauApiHelper.getMappedChartData(PHBSAnalysisTableTitleModel, view, 'Global Brand Strength Title'),
			TableauApiHelper.getMappedChartData(PHBSGlobalAudiencePerBrandChartTitleModel, view, 'Global Brand Strength by Audience per Brand Title'),
			TableauApiHelper.getMappedChartData(PHBSGlobalStatementPerFactorChartTitleModel, view, 'Global Brand Strength by Statement per Factor Title'),
			TableauApiHelper.getMappedChartData(PHBSGlobalStatementOverTimeChartTitleModel, view, 'Global Brand Strength by Statement per Factor Over Time Title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map(obj => {
				return titles.push(`${obj.Title}`);
			})
		});
		
		return titles;
	}

	// PH - Market Brand Strength
	private static async getPersonalHealthBSMarketNames(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(PHBSTotalChartTitleModel, view, 'Market Brand Strength Total Title'),
			TableauApiHelper.getMappedChartData(PHBSTotalOverTimeChartTitleModel, view, 'Market Brand Strength Total Over Time Title'),
			TableauApiHelper.getMappedChartData(PHBSAudienceOverTimeChartTitleModel, view, 'Market Brand Strength per Audience Over Time Title'),
			TableauApiHelper.getMappedChartData(PHBSFactorOverTimeChartTitleModel, view, 'Market Brand Strength per Factor Over Time Title'),
			TableauApiHelper.getMappedChartData(PHBSAnalysisTableTitleModel, view, 'Market Brand Strength Title'),
			TableauApiHelper.getMappedChartData(PHBSMarketAnalysisByBrandTableTitleModel, view, 'Market Brand Strength Title by Aud'),
			TableauApiHelper.getMappedChartData(PHBSMarketAudiencePerBrandChartTitleModel, view, 'Market Brand Strength by Audience per Brand Title'),
			TableauApiHelper.getMappedChartData(PHBSMarketStatementPerFactorChartTitleModel, view, 'Market Brand Strength Analysis by Statement Title'),
			TableauApiHelper.getMappedChartData(PHBSMarketStatementOverTimeChartTitleModel, view, 'Market Brand Strength by Statement Over Time Title'),
			TableauApiHelper.getMappedChartData(PHBSMarketPerformanceChartTitleModel, view, 'Market Brand KPIs Title'),
			TableauApiHelper.getMappedChartData(PHBSMarketStatementPerBrandChartTitleModel, view, 'Market Brand Strength by Statement per Brand Title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map(obj => {
				return titles.push(`${obj.Title}`);
			})
		});
		
		return titles;
	}

		// PH Brand Strength - Deep Dive by Markets
		private static async getPersonalHealthBSDeepDiveByMarketsNames(view: any) {
			const titlesData = await Promise.all([
				TableauApiHelper.getMappedChartData(PHBSDeepDiveTitleModel, view, 'Deep Dive by Market Title'),				
			]);
	
			const titles: string[] = [];
			titlesData.forEach((rowArrays: any[]) => {
				rowArrays.map(obj => {
					return titles.push(`${obj.TitleMarket}`);
				})
			});
			
			return titles;
		}

		// PH Brand Strength - Deep Dive by Audiences
	private static async getPersonalHealthBSDeepDiveByAudiencesNames(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(PHBSDeepDiveTitleModel, view, 'Deep Dive by Audience Title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map(obj => {
				return titles.push(`${obj.TitleAudiences}`);
			})
		});
		
		return titles;
	}

	// PH Brand Strength - Employee Deep Dive
	private static async getPersonalHealthBSEmployeeDeepDiveNames(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(PHBSEmployeeDeepDiveTableTitleModel, view, 'Employee Factors by Statement Title'),
			TableauApiHelper.getMappedChartData(PHBSEmployeeDeepDiveOverTimeTitleModel, view, 'Employee Factors by Statement Over Time Title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map(obj => {
				return titles.push(`${obj.Title}`);
			})
		});
		
		return titles;
	}

	// SRC pages.
	private static async getSRCSummary(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(SummaryTitleModel, view, 'Summary Title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map( obj => {
				return titles.push(obj.Title);
			})
		})
		return titles;
	}

	private static async getSRCSnapshot(view: any) {

		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(SnapshotTitleModel, view, 'Title Snapshot'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map( obj => {
				return titles.push(obj.Title);
			})
		})
		return titles;
	}

	private static async getSRCGlobalResults(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(FirstChoiceTitleModel, view, 'First Choice % Title'),
			TableauApiHelper.getMappedChartData(FirstChoiceTTLTitleModel, view, 'First Choice Base size Title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map( obj => {
				return titles.push(obj.Title);
			})
		})
		return titles;
	}

	private static async getSRCCompetitorsGlobally(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(CompetitorsGloballyTitleModel, view, 'Title '),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map( obj => {
				return titles.push(obj.Title);
			})
		})
		return titles;
	}

	// SRC - Competitors Globally - Product NPS 
	private static async getSRCCompetitorsGloballyNPSNames(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(CompetitorsGloballyNPSTitleModel, view, 'Title NPS'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map( obj => {
				return titles.push(obj.Title);
			})
		})
		return titles;
	}

	private static async getSRCMarketOverview(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(MarketOverviewTitleModel, view, 'MO Title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map( obj => {
				return titles.push(obj.Title);
			})
		})
		return titles;
	}

	private static async getSRCCompetitorsPerMarket(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(CompetitorsPerMarketTitleModel, view, 'CompPerMarketTitle (2)'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map( obj => {
				return titles.push(obj.Title);
			})
		})
		return titles;
	}

	private static async getSRCDemographics(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(DemographicsPieChartTitleModel, view, 'Demo_Title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map( obj => {
				return titles.push(obj.Title);
			})
		})
		return titles;
	}

	private static async getSRCAppendixUS(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(AppendixUSTitleModel, view, 'AppUSTitle'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map( obj => {
				return titles.push(obj.Title);
			})
		})
		return titles;
	}

	private static async getSRCAppendixJapan(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(AppendixJapanTitleModel, view, 'Japan Title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map( obj => {
				return titles.push(obj.Title);
			})
		})
		return titles;
	}

	private static async getSRCRecommendationPatterns(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(RecommendationPatternsTitleModel, view, 'RS_Title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map( obj => {
				return titles.push(obj.Title);
			})
		})
		return titles;
	}

	private static async getSRCPurchaseFunnel(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(PurchaseFunnelTitleModel, view, 'PH_Title'),
			TableauApiHelper.getMappedChartData(PurchaseFunnelLineChartTitleModel, view, 'Funnel Line Title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map( obj => {
				return titles.push(`${obj.Title} ${obj.QuarterTitle ? obj.QuarterTitle : ''}`);
			})
		})

		return titles;
	}

	private static async getSRCProductNPSResults(view: any) {
		const titlesData = await Promise.all([			
			TableauApiHelper.getMappedChartData(ProductNPSResultsTitleModel, view, 'NPS bar Title'),
			TableauApiHelper.getMappedChartData(ProductNPSLineChartTitleModel, view, 'NPS line Title'),

		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map( obj => {
				return titles.push(obj.Title);
			})
		})

		return titles;
	}

	private static async getSRCDriverAnalysis(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(DriverAnalysisTitleModel, view, 'KDA_Title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.forEach(obj => {
				if (obj.Title) {
					titles.push(obj.Title);
				}
			})
		})

		return titles;
	}

	// SRC - Global Brand Strength
	private static async getSRCGlobalBrandStrengthChartNames(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(SRCBSTotalChartTitleModel, view, 'Global Brand Strenght Total Title'),
			TableauApiHelper.getMappedChartData(SRCBSTotalOverTimeChartTitleModel, view, 'Global Brand Strenght Total Over Time Title'),
			TableauApiHelper.getMappedChartData(SRCBSAnalysisTableTitleModel, view, 'Global Brand Strenght Title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map(obj => {
				return titles.push(`${obj.Title}`);
			})
		});
		
		return titles;
	}

	// SRC - Market Brand Strength
	private static async getSRCMarketBrandStrengthChartNames(view: any) {
		const titlesData = await Promise.all([
			TableauApiHelper.getMappedChartData(SRCBSTotalChartTitleModel, view, 'Market Brand Strenght Total Title'),
			TableauApiHelper.getMappedChartData(SRCBSTotalOverTimeChartTitleModel, view, 'Market Brand Strenght Total Over Time Title'),
			TableauApiHelper.getMappedChartData(SRCBSAnalysisTableTitleModel, view, 'Market Brand Strenght Title'),
			TableauApiHelper.getMappedChartData(SRCBSAnalysisByAudienceTableTitleModel, view, 'Market Brand Strenght Title by Aud'),
			TableauApiHelper.getMappedChartData(SRCBSByStatementChartTitleModel, view, 'Market Brand Strength Analysis by Statement Title'),
			TableauApiHelper.getMappedChartData(SRCBSByStatementOverTimeChartTitleModel, view, 'Market Brand Strength by Statement Over Time Title'),
			TableauApiHelper.getMappedChartData(SRCBSPerformanceChartTitleModel, view, 'Market Brand KPIs Title'),
		]);

		const titles: string[] = [];
		titlesData.forEach((rowArrays: any[]) => {
			rowArrays.map(obj => {
				return titles.push(`${obj.Title}`);
			})
		});
		
		return titles;
	}
}

import { IDataMapping } from '../../../../IDataMapping';

export class NpsAttributesDeltaChartTitleModel implements IDataMapping<NpsAttributesDeltaChartTitleModel> {
    public title: string = '';
    public period: string = '';
    public subPeriod: string = '';
    public country: string = '';
    public businessGroup: string = '';
    public modality: string = '';
    public subModality: string = '';

    public getMappings?() {
        return {
            title: 'NPS Drivers Title',
            period: 'Q9 Year/Period',
            subPeriod: 'MAT',
            country: 'Prm Country Drivers',
            businessGroup: 'Business Group',
            modality: 'Modality',
            subModality: 'Submodality'
        };
    }
}

import pptxgen from 'pptxgenjs';
import { PreferenceOverTimeComparisonTitleModel } from '../../../models/PersonalHealth/PreferenceOverTimeComparison/PreferenceOverTimeComparisonTitleModel';
import { PreferenceOverTimeComparisonDataModel } from '../../../models/PersonalHealth/PreferenceOverTimeComparison/PreferenceOverTimeComparisonDataModel';

import { PHFirstSlideRenderer } from '../../Renderers/PHFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

import { filterInitDuplicateData, addEmptyValuesToInitData } from "../../../../shared/utility";

export class PreferenceOverTimeComparisonService extends BasePptxService {
  protected chartQuestion: string = '';
  protected firstSlideRenderer: PHFirstSlideRenderer = new PHFirstSlideRenderer();
  protected titleSheet: string;
  protected dataSheet: string;

  constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
      super(view, chartTitle);
      this.titleSheet = titleSheet;
      this.dataSheet = dataSheet;
  }

  protected async setChartSlideLayout(slide: pptxgen.Slide) {
    // Override title's options and add filter's name in it.
    const titleData = await this.getMappedChartData(PreferenceOverTimeComparisonTitleModel, this.titleSheet);
    let subTitle: string = "";
    
    titleData.map( obj => {
      this.chartTitle = obj.Title;
      return subTitle = obj.Market ? `${obj.SubTitle} - ${obj.Market}` : obj.SubTitle;;
    });
    slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.35, bold: true });
    slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.58, italic: true });
  }

  protected async addChartSlide(chartSlide: pptxgen.Slide) {
    const mappedData = await this.getMappedChartData(PreferenceOverTimeComparisonDataModel, this.dataSheet);

    const newArr: any = [];
    const lineColors: string[] = [];
    const getAllLabels: any = [];

    // Sorting the quarters
    const sortedDataByTime = mappedData.sort(function (a: any, b: any) {
      return a.TimeRank - b.TimeRank;
    });

    sortedDataByTime.forEach((obj: any) => {
      getAllLabels.push(obj.Label);
    });

    const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);
    
    // Sorting the names/answers
    const sortedData = mappedData.sort(function(a: any, b: any) { 
      let rankA: number = Number(0);
      let rankB: number = Number(0);
      if (a.RankGlobal) {
        rankA = a.RankGlobal;
        rankB = b.RankGlobal;
      } else if (a.RankCountry) {
        rankA = a.RankCountry;
        rankB = b.RankCountry;
      } else if (a.RankCategory) {
        rankA = a.RankCategory;
        rankB = b.RankCategory;
      }
      return rankA - rankB;
    });

    sortedData.forEach((obj: any) => {
      let color: string = "";
      if (obj.NameGlobal) {
        color = obj.ColorGlobal;
      } else if (obj.NameCountry) {
        color = obj.ColorCountry;
      } else if (obj.NameCategory) {
        color = obj.ColorCategory;
      }
      lineColors.push(color);
      const item = newArr.find((item: any) => {
        if (obj.NameGlobal) {
          return item.name === obj.NameGlobal;
        } else if (obj.NameCountry) {
          return item.name === obj.NameCountry;
        } else if (obj.NameCategory) {
          return item.name === obj.NameCategory;
        } return false;
      });
      if(newArr.length > 0 && item) {
        if (item) {
          item.values.push(parseFloat(obj.Value));
          item.labels.push(obj.Label);
        }
      } else {
        newArr.push(
          {
            name: function() {
              if (obj.NameGlobal) {
                return obj.NameGlobal;
              } else if (obj.NameCountry) {
                return obj.NameCountry;
              } else if (obj.NameCategory) {
                return obj.NameCategory;
              } return false;
            }(),
            labels: [obj.Label],
            values: [parseFloat(obj.Value)],
          }
        )
      }
    });

    const finalChartData = addEmptyValuesToInitData(distinctedLabels, newArr);
    chartSlide.addChart(
      this.presentation.ChartType.line,
      finalChartData,
      {
        x: 1,
        y: 1,
        w: "70%",
        h: "70%",
        showLegend: true,
        legendPos: 'b',
        dataLabelPosition: 't',
        dataLabelFormatCode: '0%',
        valAxisLabelFormatCode: '0%',
        catAxisLabelRotate: this.labelRotateDegree(newArr),
        chartColors: lineColors.filter(this.distinctRepeatables),
        valAxisLabelFontSize: 10,
        catAxisLabelFontSize: 10,
      }
    );    
  }

  protected labelRotateDegree(array: any) {
    let degree: number = 0;
    array.forEach((obj: any) => {
      if (obj.labels.length > 10) {
        degree = 45;
      } else {
        degree = 0;
      }
    });
    return degree;
  }

  private distinctRepeatables = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };
}

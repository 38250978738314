import { IDataMapping } from '../../IDataMapping';

export class ProductExperienceBubbleDataModel implements IDataMapping<ProductExperienceBubbleDataModel> {
    public Name: string = '';
    public Label: string = '';
    public Color: string = '';
    public Value: number = Number(0);
    public Rank: number = Number(0);
    public Filter: string = '';
    public getMappings?() {
        return {
            Name: "Indicator Name",
            Label: 'Brand Name',
            Color: 'ATTR(Brand name color)',
            Value: 'SUM(Indicator value)',
            Rank: 'AGG(PE rank)',
            Filter: 'Product Experience',
        };
    }
}

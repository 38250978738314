import pptxgen from 'pptxgenjs';

import { BasePptxService } from '../../../../BasePptxService';
import { PHBSFirstSlideRenderer } from '../../../../Renderers/PHBSFirstSlideRenderer';

import { PHBSEmployeeDeepDiveOverTimeTitleModel } from '../../../../../models/PersonalHealth/BrandStrength/EmployeeDeepDive/FactorsByStatementsOverTimeChart/PHBSEmployeeDeepDiveOverTimeTitleModel';
import { PHBSEmployeeDeepDiveOverTimeDataModel } from '../../../../../models/PersonalHealth/BrandStrength/EmployeeDeepDive/FactorsByStatementsOverTimeChart/PHBSEmployeeDeepDiveOverTimeDataModel';

export class PHBSEmployeeDeepDiveOverTimeService extends BasePptxService {
    protected firstSlideRenderer: PHBSFirstSlideRenderer = new PHBSFirstSlideRenderer();
    protected chartQuestion: string = '';
    protected titleSheet: string;
    protected dataFactorSheet: string;
    protected dataStatementSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, dataFactorSheet: string, dataStatementSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataFactorSheet = dataFactorSheet;
        this.dataStatementSheet = dataStatementSheet;
    }

    // Override of a super class method
    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const titleData = await this.getMappedChartData(PHBSEmployeeDeepDiveOverTimeTitleModel, this.titleSheet);

        let note: string = "";
        titleData.forEach((obj: any) => {
            this.chartTitle = `${obj.Title}`;
            note = obj.Note;
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.55, bold: true });
        slide.addText([{ text: note, options: { color: '#861106', fontSize: 12 } }], { x: 0.6, y: 0.9, italic: true });
    }

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const factorMappedData = await this.getMappedChartData(PHBSEmployeeDeepDiveOverTimeDataModel, this.dataFactorSheet);
        const statementMappedData = await this.getMappedChartData(PHBSEmployeeDeepDiveOverTimeDataModel, this.dataStatementSheet);

        const titleData = await this.getMappedChartData(PHBSEmployeeDeepDiveOverTimeTitleModel, this.titleSheet);
        let dataFromFactor: string = "";
        titleData.forEach((obj: any) => {
            dataFromFactor = obj.DataType;
        });

        const mappedData: any = dataFromFactor === "True" ? factorMappedData : statementMappedData;
        const subTitle: string = dataFromFactor === "True" ? mappedData[0].Factor : mappedData[0].Statement;

        let groupedData = this.groupByProperty(mappedData, "Name").reverse();

        let lineChartData: any[] = [];
        let colors: string[] = [];
        groupedData.forEach((group: PHBSEmployeeDeepDiveOverTimeDataModel[]) => {
            group.sort((a, b) => a.PeriodIndex - b.PeriodIndex);

            let name: string = "";
            let color: string = "";
            let labels: string[] = [];
            let values: number[] = [];

            group.forEach((obj: PHBSEmployeeDeepDiveOverTimeDataModel) => {
                name = obj.Name;
                color = obj.Color;
                labels.push(obj.Label);
                values.push(obj.Value);
            });

            lineChartData.push({ name, labels, values });
            colors.push(color);
        });

        chartSlide.addChart(
            this.presentation.ChartType.line,
            lineChartData,
            {
                x: 1,
                y: 1,
                w: "80%",
                h: "75%",
                showLegend: true,
                legendPos: 'b',
                legendFontSize: 8,
                dataLabelPosition: 't',
                catAxisLabelRotate: this.labelRotateDegree(lineChartData),
                chartColors: colors,
                valAxisLabelFontSize: 10,
                catAxisLabelFontSize: 10,
                showTitle: true,
                title: subTitle,
                titleFontSize: 10,
            }
        );
    }

    protected labelRotateDegree(array: any) {
        let degree: number = 0;
        array.forEach((obj: any) => {
            if (obj.labels.length > 10) {
                degree = 45;
            } else {
                degree = 0;
            }
        });
        return degree;
    }

    private groupByProperty(array: PHBSEmployeeDeepDiveOverTimeDataModel[], property: string) {
        const groupData: PHBSEmployeeDeepDiveOverTimeDataModel[][] = [];

        // Grouping the data
        for (let obj of array) {
            let flagMakeNewGroup = true;
            // Checks If there is group/array for this object
            for (let arr of groupData) {
                for (let element of arr) {
                    if (element[property as keyof PHBSEmployeeDeepDiveOverTimeDataModel] === obj[property as keyof PHBSEmployeeDeepDiveOverTimeDataModel]) {
                        arr.push(obj);
                        flagMakeNewGroup = false;
                    }
                    break;
                }
            }
            // Creates new group/array
            if (flagMakeNewGroup) {
                groupData.push([obj]);
            }
        }

        return groupData;
    }
}

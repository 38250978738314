import { IDataMapping } from '../../IDataMapping';

export class ProductExperienceTitleModel implements IDataMapping<ProductExperienceTitleModel> {
    public Title: string = '';
    public SubTitle: string = '';
    public Level: string = '';
    public getMappings?() {
        return {
            Title: "BMC Page Titles",
            SubTitle: "Category",
            Level: "Country / Market",
        };
    }
}

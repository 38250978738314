import pptxgen from 'pptxgenjs';

import { CompetitorsPerMarketTitleModel } from '../../../models/SRC/CompetitorsPerMarket/CompetitorsPerMarketTitleModel';
import { CompetitorsPerMarketFirstTableModel } from '../../../models/SRC/CompetitorsPerMarket/CompetitorsPerMarketFirstTableModel';

import { SRCFirstSlideRenderer } from '../../Renderers/SRCFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

export class CompetitorsPerMarketService extends BasePptxService {
  protected chartQuestion: string = '';
  protected firstSlideRenderer: SRCFirstSlideRenderer = new SRCFirstSlideRenderer();
  protected titleSheet: string;
  protected firstTableDataSheet: string;
  protected secondTableDataSheet: string;

  constructor(view: any, chartTitle: string, titleSheet: string, firstTableDataSheet: string, secondTableDataSheet: string) {
      super(view, chartTitle);
      this.titleSheet = titleSheet;
      this.firstTableDataSheet = firstTableDataSheet;
      this.secondTableDataSheet = secondTableDataSheet;
  }

  protected async setChartSlideLayout(slide: pptxgen.Slide) {
    // Override title.
    this.chartTitle = this.titleSheet;
    let subTitle: string = "";
    const titleData = await this.getMappedChartData(CompetitorsPerMarketTitleModel, this.titleSheet);
    
    titleData.map( obj => {
      this.chartTitle = obj.Title;
      return subTitle = obj.SubTitle;
    });
    
    slide.addText([{ text: this.chartTitle, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.35, bold: true });
    slide.addText([{ text: subTitle, options: { color: this.colors.black, fontSize: 12 } }], { x: 0.5, y: 0.58, italic: true });
  }

  protected async addChartSlide(chartSlide: pptxgen.Slide) {
    const firstTableDataSheet = await this.getMappedChartData(CompetitorsPerMarketFirstTableModel, this.firstTableDataSheet);
    const secondTableDataSheet = await this.getMappedChartData(CompetitorsPerMarketFirstTableModel, this.secondTableDataSheet);
    
    const firstTableProps: pptxgen.TableProps = {
      y: 1,
      w: '45%',
      h: '70%',
      align: 'center',
      fontSize: 8,
      valign: 'middle',
      rowH: 0.25,
      color: this.colors.white,
      autoPage: true,
      newSlideStartY: 1,
      border: { type: 'solid', color: this.colors.neutral, pt: 0.2 },
      autoPageLineWeight: 0.5
    };

    const secondTableProps: pptxgen.TableProps = {
      x: 5.3,
      y: 1,
      w: '45%',
      h: '70%',
      align: 'center',
      fontSize: 8,
      valign: 'middle',
      rowH: 0.25,
      color: this.colors.white,
      autoPage: true,
      newSlideStartY: 1,
      border: { type: 'solid', color: this.colors.neutral, pt: 0.2 },
      autoPageLineWeight: 0.5
    };

    const firstTableTitleRow = this.generateTitleRows();
    const firstTableBodyRows = this.generateBodyRows(firstTableDataSheet);

    const secondTableTitleRow = this.generateTitleRows();
    const secondTableBodyRows = this.generateBodyRows(secondTableDataSheet);
    chartSlide.addTable([...firstTableTitleRow, ...firstTableBodyRows], firstTableProps);
    chartSlide.addTable([...secondTableTitleRow, ...secondTableBodyRows], secondTableProps);

    // Add the sample text only if there is a smaller or equal value than 20 in the objects.
    let lowValue: boolean = false;
    secondTableDataSheet.forEach((obj: any) => {
      if (obj.TTL.replace(/[^0-9]/g, '') <= 20) {
        lowValue = true;
      };
    })

    if (lowValue) {
      chartSlide.addText([{ text: "*Note: Scores could be influenced by small sample sizes.", options: { color: '#000000', fontSize: 10 } }], { x: 4.9, y: 5, bold: false});
    };
  }

  private generateTitleRows = () => {
    const arrMarkets = ["Category", "TTL", "", ""];
    const result = [] as  any;
    arrMarkets.forEach((market: any) => {
      result.push({ text: market, options: { fill: { color: '#0153A5' } } },)
    });
    
    const rowTitles: pptxgen.TableRow[] = [];
    rowTitles.push(result);

    return rowTitles;
  }

  private generateBodyRows = (tableData: any) => {
    // Sort and reorder the data into new array.
    tableData.sort((a: any, b: any) => a.BrandOrder - b.BrandOrder);

    const checkCategoryForBrand: any = [];
    tableData.forEach((obj: any) => {
      const item = checkCategoryForBrand.find((item: any) => item.category === obj.Category);
      if(checkCategoryForBrand.length > 0 && item) {
        if (item) {
          item.brands.push({
            brandName: obj.Brand,
            brandValue: obj.BrandValue,
            orderValue: obj.Value,
          });
          item.ttl = obj.TTL;
          item.value = obj.Value;
        }
      } else {
        checkCategoryForBrand.push(
          {
            category: obj.Category,
            brands: [{
              brandName: obj.Brand,
              brandValue: obj.BrandValue,
              orderValue: obj.Value,
            }],
            ttl: obj.TTL,
            value: obj.Value,
          }
        )
      }
    }) 


    checkCategoryForBrand.forEach((obj: any) => {
      const byValue = obj.brands.slice(0);
      // Sort values by descending order.
      byValue.sort(function(a: any, b: any) {
          return b.brandValue - a.brandValue;
      });
      obj.brands = byValue;
      // Put the "Other" to the last place in the column.
      obj.brands.push(obj.brands.splice(obj.brands.findIndex((v: any) => v.brandName === 'Other'), 1)[0]);
    });

    const sortedArr = [] as any;
    checkCategoryForBrand.forEach((outerObj: any) => {
      outerObj.brands.forEach((innerObj: any, index: any) => {
        sortedArr.push({
          category: outerObj.category,
          ttl: outerObj.ttl,
          brandName: innerObj.brandName,
          brandValue: innerObj.brandValue,
          orderValue: innerObj.orderValue,
          firstRow: index === 0 ? true : false
        })
      });
    });

    const createRows: any = [];
    sortedArr.map((obj: any) => {
      
      const arr: any = [];
        arr.push({
          text: obj.firstRow ? obj.category : "", options: { color: '#000000', fill: { color: '#FFFFFF' }}
        });
        arr.push({
          text: obj.firstRow ? obj.ttl : "", options: { color: obj.ttl.replace(/[^0-9]/g, '') < 10 ? '#FF0000' : '#000000', fill: { color: '#FFFFFF' }}
        });
        arr.push({
          text: obj.brandName, options: { color: '#000000', fill: { color: obj.orderValue ? '#c5c5c5' : '#FFFFFF' }}
        });
        arr.push({
          text: `${(parseFloat(obj.brandValue)* 100).toFixed(0)}%`, options: { color: '#000000', fill: { color: obj.orderValue ? '#c5c5c5' : '#FFFFFF' }}
        });
      return createRows.push(arr);
    })
    return createRows;
  };
}

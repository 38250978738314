import { IDataMapping } from "../../../IDataMapping";

export class NPSTrendsQuarterlyDataModel implements IDataMapping<NPSTrendsQuarterlyDataModel> {
    public Rank: string = '';
    public Name: string = '';
    public Label: string = '';
    public Value: number = Number(0);
    public getMappings?() {
        return {
            Rank: 'rank Int',
            Name: 'Brand',
            Label: 'QUARTER(year/period (date))',
            Value: 'SUM(Value)',
        };
    }
}

import pptxgen from 'pptxgenjs';

import { PHBSMarketStatementPerFactorChartTitleModel } from '../../../../../models/PersonalHealth/BrandStrength/MarketsOverview/PHBSMarketStatementPerFactorChart/PHBSMarketStatementPerFactorChartTitleModel';
import { PHBSMarketStatementPerFactorChartDataModel } from '../../../../../models/PersonalHealth/BrandStrength/MarketsOverview/PHBSMarketStatementPerFactorChart/PHBSMarketStatementPerFactorChartDataModel';

import { BasePptxService } from '../../../../BasePptxService';
import { PHBSFirstSlideRenderer } from '../../../../Renderers/PHBSFirstSlideRenderer';

import { PptxRenderHelper } from '../../../../../helpers/PptxRenderHelper';

export class PHBSMarketStatementPerFactorChartService extends BasePptxService {
    protected firstSlideRenderer: PHBSFirstSlideRenderer = new PHBSFirstSlideRenderer();
    protected chartQuestion: string = '';
    protected titleSheet: string;
    protected relativeDataSheet: string;
    protected absoluteDataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, relativeDataSheet: string, absoluteDataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.relativeDataSheet = relativeDataSheet;
        this.absoluteDataSheet = absoluteDataSheet;
    }

    // Override of a super class method
    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const titleData = await this.getMappedChartData(PHBSMarketStatementPerFactorChartTitleModel, this.titleSheet);

        let subTitle: string = "";
        titleData.forEach((obj: any) => {
            this.chartTitle = `${obj.Title}`;
            subTitle = `${obj.SubTitle} - ${obj.DataTypeFilter} - ${obj.AudienceFilter}`;
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 16, lineSpacing: 15 } }], { x: 0.6, y: 0.55, bold: true, w: 8.6 });
        slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 12, lineSpacing: 12 } }], { x: 0.6, y: 0.9 });
        slide.addText([{ text: this.chartQuestion, options: { color: this.colors.black, fontSize: 7 } }], { x: 0.5, y: '95%', bold: false });
    }

    // Override of a super class method
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const mappedRelativeData = await this.getMappedChartData(PHBSMarketStatementPerFactorChartDataModel, this.relativeDataSheet);
        const mappedAbsoluteData = await this.getMappedChartData(PHBSMarketStatementPerFactorChartDataModel, this.absoluteDataSheet);
        const titleData = await this.getMappedChartData(PHBSMarketStatementPerFactorChartTitleModel, this.titleSheet);

        let dataType: string = "Relative";
        titleData.forEach((obj: PHBSMarketStatementPerFactorChartTitleModel) => {
            dataType = obj.DataTypeFilter;
        });
        const mappedChartData: PHBSMarketStatementPerFactorChartDataModel[] = dataType === "Relative" ? mappedRelativeData : mappedAbsoluteData;

        if (mappedChartData.length > 0) {

            // filtering and sorting data
            mappedChartData.sort((a: any, b: any) => b.BrandRank - a.BrandRank);
            const chartdata: PHBSMarketStatementPerFactorChartDataModel[] = this.filterByCheckProperty(mappedChartData);

            // grouping data
            const groupData: PHBSMarketStatementPerFactorChartDataModel[][] = this.groupByProperty(chartdata, "Answer");
            const slides: PHBSMarketStatementPerFactorChartDataModel[][][] = this.splitRowsBySlides(groupData);

            // grouping data for legend
            chartdata.sort((a: any, b: any) => a.BrandRank - b.BrandRank);
            const legendGroupData: PHBSMarketStatementPerFactorChartDataModel[][] = this.groupByProperty(chartdata, "Answer");

            const colors: string[] = this.getAllByProperty(legendGroupData, "Color");
            const brands: string[] = this.getAllByProperty(legendGroupData, "BrandForLegend");

            // execution
            slides.forEach((slide, index) => {
                const currentSlide = this.getCurrentSlide(chartSlide, index);

                // add table - part 1
                this.addTable(slide, currentSlide);

                // add scatter chart - part 2
                this.addChart(slide, currentSlide, dataType);

                if (brands.length < 19)
                    this.addLegend(colors, brands, currentSlide);
            });

            if (brands.length > 18) {
                let currentSlide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });
                this.setChartSlideLayout(currentSlide);

                const brandsPerRow = 6;

                for (let i = 0; i < colors.length; ++i) {
                    PptxRenderHelper.renderShapeSquareLegend(this.presentation, currentSlide, { label: brands[i], color: colors[i] }, { x: 1 + (i % brandsPerRow) * 1.5, y: 1.1 + 0.2 * Math.trunc(i / brandsPerRow) });
                }
            }
        }
    }

    private getCurrentSlide(chartSlide: pptxgen.Slide, index: number) {
        let currentSlide = chartSlide;

        if (index !== 0) {
            currentSlide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });
            this.setChartSlideLayout(currentSlide);
        }

        return currentSlide;
    }

    private filterByCheckProperty(data: PHBSMarketStatementPerFactorChartDataModel[]) {
        let cleanData: PHBSMarketStatementPerFactorChartDataModel[] = [];

        // Filtering
        cleanData = data.filter((obj) => {
            return obj.Check == null
        });

        // Sorting by rows
        cleanData.sort((a, b) => a.RowOrder - b.RowOrder)

        return cleanData;
    }

    private groupByProperty(array: PHBSMarketStatementPerFactorChartDataModel[], property: string) {
        const groupData: PHBSMarketStatementPerFactorChartDataModel[][] = [];

        // Grouping the data in rows
        for (let obj of array) {
            let flagMakeNewGroup = true;
            // Checks If there is group/array for this object
            for (let arr of groupData) {
                for (let element of arr) {
                    if (element[property as keyof PHBSMarketStatementPerFactorChartDataModel] === obj[property as keyof PHBSMarketStatementPerFactorChartDataModel]) {
                        arr.push(obj);
                        flagMakeNewGroup = false;
                    }
                    break;
                }
            }
            // Creates new group/array
            if (flagMakeNewGroup) {
                groupData.push([obj]);
            }
        }

        return groupData;
    }

    private splitRowsBySlides(groupData: PHBSMarketStatementPerFactorChartDataModel[][]) {
        const slides: PHBSMarketStatementPerFactorChartDataModel[][][] = [];

        // full slides
        const rowsPerSlide = 10;
        const numberOfFullSlides = Math.trunc(groupData.length / rowsPerSlide);
        for (let i = 0; i < numberOfFullSlides; ++i) {
            slides.push(groupData.slice((i * rowsPerSlide), (i * rowsPerSlide) + rowsPerSlide));
        }

        // last smaller slide
        const lastSlide = groupData.length % rowsPerSlide;
        if (lastSlide) {
            slides.push(groupData.slice((groupData.length - lastSlide), groupData.length));
        }

        return slides;
    }

    private getAllByProperty(data: PHBSMarketStatementPerFactorChartDataModel[][], property: string) {
        let array: any[] = [];

        data.forEach((row) => {
            row.forEach((obj) => {
                if (!array.includes(obj[property as keyof PHBSMarketStatementPerFactorChartDataModel])) {
                    array.push(obj[property as keyof PHBSMarketStatementPerFactorChartDataModel]);
                }
            });
        });

        return array;
    }

    private addTable(data: PHBSMarketStatementPerFactorChartDataModel[][], chartSlide: pptxgen.Slide) {
        let answers: string[] = [];
        let factors: string[] = [];

        data.forEach((row) => {
            row.forEach((obj) => {
                if (!answers.includes(obj.Answer)) {
                    answers.push(obj.Answer);
                    factors.push(obj.Factor);
                }
            });
        });

        let tableRows: pptxgen.TableRow[] = [];

        answers.forEach((answer, index) => {
            const tableCells: pptxgen.TableCell[] =
                [
                    {
                        text: answer,
                        options: { bold: false }
                    },
                    {
                        text: factors[index],
                        options: { align: "center" }
                    },
                    {
                        text: ''
                    }
                ];

            tableRows.push(tableCells);
        });

        const tableProps: pptxgen.TableProps = {
            x: 0.5, y: 1.38, w: '5%', colW: [3.2, 0.7, 5.1], rowH: 0.37,
            margin: [2, 0, 2, 2],
            align: 'left',
            valign: 'middle',
            autoPageCharWeight: 1.5,
            fontSize: 7,
            border: { type: 'solid', color: this.colors.neutral, pt: 0.2 },
        };

        chartSlide.addTable(tableRows, tableProps);
    }

    private addChart(data: PHBSMarketStatementPerFactorChartDataModel[][], chartSlide: pptxgen.Slide, dataType: string) {
        const scatterData: any[] = [];

        let indexOfValues = 0;
        let numberOfValues = 0;

        data.forEach((row) => {
            numberOfValues += row.length;
        });

        data.forEach((row, rowIndex) => {
            row.forEach((obj) => {

                // x values
                if (scatterData.length === 0) {
                    scatterData.push(
                        {
                            name: "X-Axis",
                            values: Array(numberOfValues).fill(null)
                        }
                    );
                }
                scatterData.forEach((axis) => {
                    if (axis.name === "X-Axis") {
                        axis.values[indexOfValues] = obj.Value;
                    }
                });

                // y values
                let flagMakeNewAxis = true;

                scatterData.forEach((axis) => {
                    if (axis.name === obj.BrandForChart) {
                        axis.values[indexOfValues] = rowIndex + 1;
                        flagMakeNewAxis = false;
                    }
                });

                if (flagMakeNewAxis) {
                    scatterData.push(
                        {
                            name: obj.BrandForChart,
                            values: Array(numberOfValues).fill(null)
                        }
                    );

                    scatterData.forEach((axis) => {
                        if (axis.name === obj.BrandForChart) {
                            axis.values[indexOfValues] = rowIndex + 1;
                        }
                    });
                }

                ++indexOfValues;
            });
        });

        const chartHeight = ((this.getAllByProperty(data, "Answer").length + 1) * 0.37) + 0.43;
        const colors: string[] = this.getAllByProperty(data, "Color");

        const options: pptxgen.IChartOpts = {
            x: 4.22, y: 0.92, w: 5.5, h: chartHeight,
            chartColors: colors,
            showLabel: false,
            catAxisMajorUnit: 10,
            catAxisLabelFrequency: 10,
            valAxisHidden: true,
            catAxisOrientation: 'minMax',
            valAxisOrientation: 'maxMin',
            valAxisMajorUnit: 1,
            lineDataSymbolSize: 12,
            lineSize: 0,
            catAxisLabelFontSize: 8,
            catGridLine: { style: 'none' },
            valGridLine: { style: 'none' },
            dataLabelFontSize: 6,
            dataLabelFontBold: true,
            valAxisLabelFormatCode: dataType === "Relative" ? '0\\' : '0%',
            catAxisMinVal: 0,
            catAxisMaxVal: dataType === "Relative" ? 10 : 1,
        } as any;

        chartSlide.addChart(this.presentation.ChartType.scatter, scatterData, options);
    }

    private addLegend(colors: string[], brands: string[], chartSlide: pptxgen.Slide) {
        // max of 18 brands
        const brandsPerRow = 6;

        for (let i = 0; i < colors.length; ++i) {
            if ((i / brandsPerRow) < 1) {
                PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: brands[i], color: colors[i] }, { x: 1 + (i % brandsPerRow) * 1.5, y: 5.1 });

            } else if (1 <= (i / brandsPerRow) && (i / brandsPerRow) < 2) {
                PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: brands[i], color: colors[i] }, { x: 1 + (i % brandsPerRow) * 1.5, y: 5.3 });

            } else if (2 <= (i / brandsPerRow)) {
                PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: brands[i], color: colors[i] }, { x: 1 + (i % brandsPerRow) * 1.5, y: 5.5 });
            }
        }
    }
}

import { clone } from 'lodash';
import produce from 'immer';
import { IUserState } from './IUserState';
import { UserActions, UserActionTypes } from './UserActions';
import { UserDevice } from '../../shared/enums/UserDevice';

const initialUserState: IUserState = {
  userProfile: undefined,
  userDevice: UserDevice.Desktop,
};

export const UserReducer: (
  state: IUserState | undefined,
  action: UserActions
) => IUserState = (
  state: IUserState = initialUserState,
  action: UserActions
): IUserState => {
  let newState: IUserState = clone(state);
  switch (action.type) {
    case UserActionTypes.SET_USER_PROFILE:
      newState = produce(state, (draftState: IUserState) => {
        draftState.userProfile = action.userProfile;
      });
      break;
    case UserActionTypes.SET_USER_DEVICE:
      newState = produce(state, (draftState: IUserState) => {
        draftState.userDevice = action.device;
      });
      break;
    default:
      return state;
  }

  return newState;
};

import { IDataMapping } from '../../IDataMapping';

export class NineGLineChartCombinedTitleModel implements IDataMapping<NineGLineChartCombinedTitleModel> {
    public Country: string = '';
    public Brand: string = '';
    public getMappings?() {
        return {
            Country: 'Country / Market',
            Brand: 'Category',
        };
    }
}

import { IDataMapping } from '../../../IDataMapping';

export class PHBSAnalysisTableTitleModel implements IDataMapping<PHBSAnalysisTableTitleModel> {
    public Title: string = '';
    public SubTitle: string = '';

    public getMappings?() {
        return {
            Title:      'Brand Strength Analysis Title',
            SubTitle:   'Brand Strength Analysis Subtitle'
        };
    }
}

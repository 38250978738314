import pptxgen from 'pptxgenjs';
import { CommunicationChannelsModel } from '../../../';
import { BaseChartOptions } from '../../../philips-constants';
import { BasePptxService } from '../../BasePptxService';
import { HSStrategicFirstSlideRenderer } from '../../Renderers/HSStrategicFirstSlideRenderer';

export class CommunicationChannelsPptxService extends BasePptxService {
    protected chartQuestion: string = 'Q: We’d like you to rate the different ways that you experienced these communication channels.' +
        'Please click on and drag the icon for at least 5 different experiences, to the place in the box that best reflects' +
        ' the Impact (Up and Down) and the Favourability (Left and Right) in terms of overall experience you had through this channel';
    protected firstSlideRenderer: HSStrategicFirstSlideRenderer = new HSStrategicFirstSlideRenderer();

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        let data = await this.getMappedChartData(CommunicationChannelsModel, 'Comm Channels');
        if (!data || !data.length) {
            return;
        }

        data = this.getSortedByOrder(data);

        const averageData = data.find(d => d.Average);
        chartSlide.addText(`N = ${averageData?.Average}`, { x: 1, y: 1, fontSize: 10, color: this.colors.default });

        this.addChart(data, chartSlide);
    }

    private addChart(data: CommunicationChannelsModel[], chartSlide: pptxgen.Slide) {
        const fractionDigits = 3;
        const steps = Math.pow(10, fractionDigits);
        const valAxisMajorUnit = 0.5;
        data.forEach(d => {
            d.Favourability = Number(d.Favourability?.toFixed(fractionDigits));
            d.Impact = Number(d.Impact?.toFixed(fractionDigits));
        });
        const minImpact = Math.min(...data.map(d => Number(d.Impact)));
        let valAxisMinVal = Math.round(minImpact);
        if (minImpact <= valAxisMinVal) {
            valAxisMinVal -= valAxisMajorUnit;
        }

        const options: pptxgen.IChartOpts = {
            ...BaseChartOptions,
            x: 0.5, y: 1.2, w: 9, h: 3.8,
            showCatAxisTitle: true,
            catAxisTitle: 'Favourability',
            showValAxisTitle: true,
            catAxisLabelFrequency: (steps / 2).toString(),
            valAxisTitle: 'Impact',
            valAxisMinVal,
            valAxisMajorUnit,
            showLegend: true,
            legendPos: 'r',
            lineSize: 0,
            lineDataSymbolSize: 15,
        };

        const multiChartData = this.getMultiChartData(data, steps, valAxisMajorUnit);
        chartSlide.addChart(multiChartData, options as any);
    }

    private getMultiChartData(data: CommunicationChannelsModel[], steps: number, valAxisMajorUnit: number) {
        const favourabilityValues = data.map(d => Number(d.Favourability));
        const minFavourability = Math.min(...favourabilityValues);
        let roundedMinFavourability = Math.round(minFavourability);
        if (minFavourability <= roundedMinFavourability) {
            roundedMinFavourability -= valAxisMajorUnit;
        }
        const labelsMinVal = roundedMinFavourability * steps;

        const maxFavourability = Math.max(...favourabilityValues);
        let roundedMaxFavourability = Math.round(maxFavourability);
        if (maxFavourability >= roundedMaxFavourability) {
            roundedMaxFavourability += valAxisMajorUnit;
        }
        const labelsMaxVal = roundedMaxFavourability * steps;

        const labels = Array.createWithNullValues((labelsMaxVal - labelsMinVal)).map((x, i) => (i + labelsMinVal) / steps);
        const multiChart = data.filter(d => d.Favourability !== null).map(d => {
            const values: Array<number | null> = labels.map(d => null);
            values[Number(d.Favourability) * steps - labelsMinVal] = d.Impact;
            return {
                type: this.presentation.ChartType.line,
                data: [{ name: d.Answer, labels, values }],
                options: { lineDataSymbol: d.Shape, chartColors: [d.Color] }
            };
        });
        return multiChart;
    }
}

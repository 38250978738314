import { IDataMapping } from '../../../IDataMapping';

export class AppendixJapanTitleModel implements IDataMapping<AppendixJapanTitleModel> {
    public Title: string = '';
    public Comments: string = '';
    public SampleSizeNote: string = '';
    public getMappings?() {
        return {
            Title: 'JapTitle1',
            Comments: "Comments",
            SampleSizeNote: 'noteBasesJapan',
        };
    }
}

import { IDataMapping } from '../../../../IDataMapping';

export class PHBSEmployeeDeepDiveTableDataModel implements IDataMapping<PHBSEmployeeDeepDiveTableDataModel> {
    public Market:          string = '';
    public Factor:          string = '';
    public Statement:       string = '';
    public Date:            string = '';
    public Color:           string = '';
    public Value:           number = 0;
    public TimeRank:        number = 0;
    public LegendText:      string = '';
    public LegendOrder:     string = '';
    
    public getMappings?() {
        return {
            Market:         'Market & Global Employees',
            Factor:         'Factor',
            Statement:      'Statement',
            Date:           'Date',
            Color:          'AGG(Table Color (hex))',
            Value:          'ATTR(Value)',
            TimeRank:       'ATTR(Period Index)',
            LegendText:     'AGG(Table Colors)',
            LegendOrder:    'AGG(Table Color (rank))',
        };
    }
}

import { IDataMapping } from '../../../../IDataMapping';

export class VendorSPTitleModel implements IDataMapping<VendorSPTitleModel> {
    public Title: string = '';
    public Country: string = '';
    public Period: string = '';
    public WarningText: string = '';

    public getMappings?() {
        return {
            Title: 'Calc MIA TEXT',
            Country: 'Country',
            Period: 'Period',
            WarningText: 'no1'
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class SummaryTableDataModel implements IDataMapping<SummaryTableDataModel> {
    public TableTitle: string = '';
    public TableText: string = '';
    public TitleRank: number = Number(0);
    public TextRank: number = Number(0);
    public getMappings?() {
        return {
            TableTitle: 'Survey',
            TableText: 'Answer_TEXT',
            TitleRank: 'rankSurvey',
            TextRank: 'SUM(rankAnalyses)',
        };
    }
}

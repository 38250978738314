import { IDataMapping } from '../../IDataMapping';

export class ProductNPSResultsTitleModel implements IDataMapping<ProductNPSResultsTitleModel> {
    public Title: string = '';
    public TitlePPT: string = '';
    public Note: string = '';
 
    public getMappings?() {
        return {
            Title: 'NPS Title',
            TitlePPT: 'NPSTitle Export',   
            Note: 'ATTR(NoteRollingDate)',    
        };
    }
}
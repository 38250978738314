import { IDataMapping } from "../../../IDataMapping";

export class PHBSAudienceOverTimeChartTitleModel implements IDataMapping<PHBSAudienceOverTimeChartTitleModel> {
    public Title:           string = '';
    public SubTitle:        string = '';
    public Brand:           string = '';
    public FactorFilter:    string = '';

    public getMappings?() {
        return {
            Title:          'Brand Strength per Audience Over Time Title',
            SubTitle:       'Brand Strength Analysis Subtitle',
            Brand:          'ATTR(Brand)',
            FactorFilter:   'ATTR(BS_statement)'
        };
    }
}

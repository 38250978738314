import { IDataMapping } from '../../IDataMapping';

export class NPSScoreOverTimeLineDataModel implements IDataMapping<NPSScoreOverTimeLineDataModel> {
    public Label: string = '';
    public Name: string = '';
    public Value: number = Number(0);
    public Rank: number = Number(0);
    public Color: string = '';
    public getMappings?() {
        return {
            Label: 'QUARTER(Date_calc)',
            Name: 'Brand Name',
            Value: 'SUM(Indicator value)',
            Rank: 'MAX(Rank_unique (NPS))',
            Color: 'Brand name color',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class SnapshotBPBarDataModel implements IDataMapping<SnapshotBPBarDataModel> {
    public Label: string = '';
    public Name: string = '';
    public Color: string = '';
    public Value: number | null = Number(0);
    public Rank: number = Number(0);
    public getMappings?() {
        return {
            Label: 'Brand',
            Name: 'Date',
            Color: 'AGG(BrandColors)',
            Value: 'AVG(Value)',
            Rank: 'MAX(pref value check rank)',
        };
    }
}

import pptxgen from 'pptxgenjs';

import { DriverAnalysisTitleModel } from '../../../models/SRC/DriverAnalysis/DriverAnalysisTitleModel';
import { DriverAnalysisCommentsModel } from '../../../models/SRC/DriverAnalysis/DriverAnalysisCommentsModel';
import { DriverAnalysisBarChartDataModel } from '../../../models/SRC/DriverAnalysis/DriverAnalysisBarChartDataModel';

import { SRCFirstSlideRenderer } from '../../Renderers/SRCFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

export class DriverScoresService extends BasePptxService {
    protected chartQuestion: string = '';
    protected firstSlideRenderer: SRCFirstSlideRenderer = new SRCFirstSlideRenderer();
    protected titleSheet: string;
    protected commentsSheet: string;
    protected samplesSheet: string;
    protected barChartSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, commentsSheet: string, samplesSheet: string, barChartSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.commentsSheet = commentsSheet;
        this.samplesSheet = samplesSheet;
        this.barChartSheet = barChartSheet;
    }

    protected async setChartSlideLayout(slide: pptxgen.Slide) { }

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const barChartData = await this.getMappedChartData(DriverAnalysisBarChartDataModel, this.barChartSheet);
        const titleData = await this.getMappedChartData(DriverAnalysisTitleModel, this.titleSheet);
        const commentsData = await this.getMappedChartData(DriverAnalysisCommentsModel, this.commentsSheet);
        const samplesData = await this.getMappedChartData(DriverAnalysisCommentsModel, this.samplesSheet);

        titleData.forEach((obj: any) => {
            if (obj.Title) {
                this.chartTitle = obj.Title;
            }
        });

        chartSlide.addText([{ text: this.chartTitle, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.25, bold: true });

        if (barChartData.length > 0) {
            this.addBarChartSlide(barChartData, chartSlide, samplesData, commentsData);
        } else {
            chartSlide.addText([{ text: "No data", options: { color: '#000000', fontSize: 8 } }], { x: 0.5, y: 4.65, bold: false });
        }
    };

    private async addBarChartSlide(barChartData: any, chartSlide: pptxgen.Slide, samplesData: any, commentsData: any) {
        const slide = chartSlide;

        if (samplesData.length > 0) {
            this.addSampleTable(samplesData, chartSlide);
        }

        if (commentsData.length > 0 && commentsData[0].Comments) {
            chartSlide.addText([{ text: commentsData[0].Comments, options: { color: this.colors.black, fontSize: 12 } }], { x: 0.5, y: 0.48, italic: true });
        }

        const newArr: any = [];
        const lineColors: string[] = [];
        let noteNoKDA: string = "";
        let noteSS: string = "";

        barChartData.sort((a: any, b: any) => a.Rank - b.Rank);
        barChartData.forEach((obj: any) => {
            lineColors.push(obj.Color);
            if (obj.Note) {
                noteNoKDA = obj.Note;
            }
            if (obj.NoteSS) {
                noteSS = obj.NoteSS;
            }
            const item = newArr.find((item: any) => item.rank === obj.Rank);
            if (newArr.length > 0 && item) {
                if (item) {
                    item.values.push(parseFloat(obj.Value));
                    item.names.push(obj.Label);
                    item.labels.push(obj.Name);
                }
            } else {
                newArr.push(
                    {
                        rank: obj.Rank,
                        values: [parseFloat(obj.Value)],
                        names: [obj.Label],
                        labels: [obj.Name],
                        color: obj.Color,
                    }
                )
            }
        });

        chartSlide.addText([{ text: noteNoKDA, options: { color: '#000000', fontSize: 8 } }], { x: 0.5, y: 4.65, bold: false });
        chartSlide.addText([{ text: noteSS, options: { color: '#FF0000', fontSize: 8 } }], { x: 0.5, y: 4.85, bold: false });

        const data: any = [];
        // We need to know how many values fo a label we got so we can make the same number of objets.
        const valuesLength = newArr[0].values.length

        for (let i = 0; i < valuesLength; i++) {
            let name = "";
            const labels: string[] = [];
            const values: number[] = [];

            newArr.forEach((innerObj: any) => {
                name = innerObj.names[i];
                labels.push(innerObj.labels[i]);
                values.push(innerObj.values[i]);
            });

            const newObject = {
                name: name,
                labels: labels,
                values: values,
            };

            const getObj = Object.assign({}, newObject);
            data.push(getObj);
        };

        slide.addChart(
            this.presentation.ChartType.bar,
            data,
            {
                x: 0.2,
                y: 0.8,
                w: "90%",
                h: "65%",
                barDir: "col",
                dataLabelPosition: "outEnd",
                dataLabelFontSize: 7,
                catAxisLabelFontSize: 8,
                valAxisLabelFontSize: 8,
                dataLabelFormatCode: "#.0",
                showValue: true,
                catGridLine: { style: "none" },
                valAxisMinVal: 0,
                valAxisMaxVal: 7.5,
                valAxisDisplayUnitLabel: true,
                valGridLine: { style: "none" },
                showLegend: true,
                legendPos: "b",
                showTitle: false,
                chartColors: lineColors.filter(this.distinctRepeatables),
            }
        );
    };

    // Table with samples
    private addSampleTable(samplesData: any, chartSlide: pptxgen.Slide) {
        const sortedData = samplesData.sort(function (a: any, b: any) {
            return a.SampleRank - b.SampleRank;
        });

        // Nested arrays in tableData[] are rows in the ppt table
        let lowValue: boolean = false;
        const tableData = sortedData.map((object: any) => {
            if (object.SampleValue <= 20) {
                lowValue = true;
            };
            return [{
                text: `${object.SampleName} (n = ${object.SampleValue.toFixed(0) <= 20 ? object.SampleValue.toFixed(0) + '*' : object.SampleValue.toFixed(0)})`,
                options: { color: object.SampleValue.toFixed(0) < 10 ? '#FF0000' : this.colors.default, fontSize: 8, italic: true }
            }]
        });

        const legendProps: pptxgen.TableProps = {
            x: 8.2,
            y: 4.5,
            w: '15%',
            align: 'left',
            fontSize: 8,
            valign: "top",
            rowH: 0.1,
            color: this.colors.default,
            border: { pt: 0 }
        };

        chartSlide.addTable(tableData, legendProps);

        if (lowValue) {
            chartSlide.addText([{ text: "*Note: Scores could be influenced by small sample sizes.", options: { color: '#000000', fontSize: 8 } }], { x: 0.5, y: 4.45, bold: false });
        };
    }

    protected labelRotateDegree(array: any) {
        let degree: number = 0;
        array.forEach((obj: any) => {
            if (obj.labels.length > 10) {
                degree = 45;
            } else {
                degree = 0;
            }
        });
        return degree;
    };

    private distinctRepeatables = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
    };
}

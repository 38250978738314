import pptxgen from 'pptxgenjs';

import { BasePptxService } from '../../../BasePptxService';
import { PHBSFirstSlideRenderer } from '../../../Renderers/PHBSFirstSlideRenderer';
import { addEmptyValuesToInitData } from '../../../../../shared/utility';
import { PptxRenderHelper } from '../../../../helpers/PptxRenderHelper';

import { PHBSDeepDiveTitleModel } from '../../../../models/PersonalHealth/BrandStrength/DeepDive/PHBSDeepDiveTitleModel';
import { PHBSDeepDiveDataModel } from '../../../../models/PersonalHealth/BrandStrength/DeepDive/PHBSDeepDiveDataModel';

export class PHBSDeepDiveService extends BasePptxService {
    protected firstSlideRenderer: PHBSFirstSlideRenderer = new PHBSFirstSlideRenderer();
    protected chartQuestion: string = '';
    protected titleSheet: string;
    protected dataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
    }

    // Override of a super class method
    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const titleData = await this.getMappedChartData(PHBSDeepDiveTitleModel, this.titleSheet);

        let subTitle: string = "";
        titleData.forEach((obj: any) => {
            this.chartTitle = `${obj.TitleMarket ? obj.TitleMarket : obj.TitleAudiences}`;
            subTitle = `${obj.SubTitle ? obj.SubTitle + ' - ' : ''}${obj.Audience ? obj.Audience : obj.Market} - ${obj.Filter}`;
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.4, w: 8.42, bold: true });
        slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 12, lineSpacing: 12 } }], { x: 0.6, y: 0.65 });
    }

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const mappedData: any = await this.getMappedChartData(PHBSDeepDiveDataModel, this.dataSheet);

        // filtering data and getting max rows and columns
        const chartdata: PHBSDeepDiveDataModel[] = this.filterByCheckProperty(mappedData);
        const [maxRow, maxColumn] = this.getMaxRowColumn(chartdata);

        // getting all periods
        const getAllLabels: any = [];
        chartdata.forEach((obj: any) => {
            getAllLabels.push(obj.Label);
        });
        const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

        // grouping data by Market or Audience
        const prop = chartdata[0].Market ? "Market" : "Audience";
        const groupedDataByProperty = this.groupByProperty(chartdata, prop);

        // executing every line chart
        groupedDataByProperty.forEach((groupedData: any) => {
            this.addLineChart(chartSlide, groupedData, distinctedLabels, maxRow, maxColumn);
        });

        // adding legend
        this.addLegend(chartdata, chartSlide);
    }

    private addLineChart(chartSlide: pptxgen.Slide, groupedData: PHBSDeepDiveDataModel[], distinctedLabels: any, maxRow: number, maxColumn: number) {
        const lineChartData: any = [];
        const colors: string[] = [];

        // getting current row, column and title
        const currentRow = groupedData[0].Row;
        const currentColumn = groupedData[0].Column;
        const title = groupedData[0].Market ? groupedData[0].Market : groupedData[0].Audience;

        // sorting data by brands
        const sortedLineChartData = groupedData.sort(function (a: any, b: any) {
            return b.BrandRank - a.BrandRank;
        });

        // getting data
        sortedLineChartData.forEach((obj: any) => {
            colors.push(obj.Color);
            let item: any = lineChartData.find((item: any) => item.name === obj.Name);
            if (lineChartData.length > 0 && item) {
                item.values.push(obj.Value);
                item.labels.push(obj.Label);
            } else {
                lineChartData.push(
                    {
                        name: obj.Name,
                        labels: [obj.Label],
                        values: [obj.Value],
                    }
                )
            }
        });

        // adding missing values
        const finalChartData = addEmptyValuesToInitData(distinctedLabels, lineChartData);

        // setting chartProps
        const valAxisHidden = currentColumn === 0 || currentColumn === maxColumn ? false : true;
        const valAxisLabelPos = currentColumn === maxColumn ? 'high' : 'nextTo';
        const wStandart = 7.334 / (maxColumn + 1);
        const wAxis = 0.236;
        const xStart = currentColumn === 0 ? 0.3 : 0.3 + wAxis;
        const w = valAxisHidden === false ? wStandart + wAxis : wStandart;
        const h = 4.56 / (maxRow + 1);
        const x = xStart + (currentColumn * wStandart);
        const y = 0.75 + (currentRow * h);
        const xText = currentColumn === 0 ? x + wAxis : x;
        const yText = y + (h - 0.25);

        // setting table with periods
        const labelArr: any = [];
        const labelData: any = [];
        distinctedLabels.forEach((period: any) => {
            labelArr.push(
                {
                    text: `${period}`,
                    options: { color: this.colors.default }
                }
            );
        });

        labelData.push(labelArr);
        labelData.forEach((obj: any) => {
            obj.forEach((o: any) => o.text = (o.text).substring(2, 4) + "'" + (o.text).substring(4));
        });

        const labelProps: pptxgen.TableProps = {
            x: xText,
            y: 5.3,
            w: wStandart,
            h: wAxis,
            fontSize: 6,
            align: 'center',
        };

        // adding chart and title
        chartSlide.addChart(
            this.presentation.ChartType.line,
            finalChartData,
            {
                x: x,
                y: y,
                w: w,
                h: h,
                chartColors: colors.filter(this.distinctRepeatables),
                layout: { x: 0, y: 0, w: 1, h: 1 },
                lineSize: 1.5,
                lineDataSymbolSize: 4,
                catAxisHidden: true,
                catAxisLabelRotate: this.labelRotateDegree(lineChartData),
                catGridLine: { style: 'dot', size: 0.5, color: this.colors.border },
                valAxisHidden: valAxisHidden,
                valAxisLabelFontSize: 6,
                valAxisLabelPos: valAxisLabelPos,
                valAxisMajorTickMark: 'none',
                valAxisMaxVal: 100,
                valAxisMinVal: 0,
                valAxisMajorUnit: 20,
                valAxisLineShow: false,
                valAxisLabelColor: this.colors.default,
                valGridLine: { style: 'none' },
            }
        );

        chartSlide.addText([{ text: title, options: { color: this.colors.black, fontSize: 8, align: 'right' } }], { x: xText, y: yText, w: wStandart, h: 0.25 });

        // adding lines between charts and tables with periods
        if (currentColumn === 0 && currentRow === 0) {
            chartSlide.addShape(this.presentation.ShapeType.line, { x: 7.87, y: 0.75, w: 0, h: 4.75, line: { color: this.colors.border, width: 1 } });
            chartSlide.addShape(this.presentation.ShapeType.line, { x: 0.32, y: 5.3, w: 7.77, h: 0, line: { color: this.colors.border, width: 1 } });
        }
        if (currentColumn === 0) {
            chartSlide.addShape(this.presentation.ShapeType.line, { x: 0.32, y: y - 0.01, w: 7.77, h: 0, line: { color: this.colors.border, width: 1 } });
        }
        if (currentRow === 0) {
            chartSlide.addShape(this.presentation.ShapeType.line, { x: xText, y: 0.75, w: 0, h: 4.75, line: { color: this.colors.border, width: 1 } });
            chartSlide.addTable(labelData, labelProps);
        }
    }

    private getMaxRowColumn(data: PHBSDeepDiveDataModel[]) {
        const getAllRows: any = [];
        const getAllColumns: any = [];
        let maxRowColumn: any = [];

        // get all rows and columns
        data.forEach((obj: any) => {
            getAllRows.push(obj.Row);
            getAllColumns.push(obj.Column);
        });

        // get max row and column
        maxRowColumn.push(Math.max(...getAllRows));
        maxRowColumn.push(Math.max(...getAllColumns));

        return maxRowColumn;
    }

    private filterByCheckProperty(data: PHBSDeepDiveDataModel[]) {
        let cleanData: PHBSDeepDiveDataModel[] = [];

        // Filtering
        cleanData = data.filter((obj) => {
            return obj.Check === null
        });

        // Sorting by period
        cleanData.sort((a, b) => a.PeriodIndex - b.PeriodIndex)

        return cleanData;
    }

    private groupByProperty(array: PHBSDeepDiveDataModel[], property: string) {
        const groupData: PHBSDeepDiveDataModel[][] = [];

        // Grouping the data
        for (let obj of array) {
            let flagMakeNewGroup = true;
            // Checks If there is group/array for this object
            for (let arr of groupData) {
                for (let element of arr) {
                    if (element[property as keyof PHBSDeepDiveDataModel] === obj[property as keyof PHBSDeepDiveDataModel]) {
                        arr.push(obj);
                        flagMakeNewGroup = false;
                    }
                    break;
                }
            }
            // Creates new group/array
            if (flagMakeNewGroup) {
                groupData.push([obj]);
            }
        }

        return groupData;
    }

    private addLegend(data: any, chartSlide: pptxgen.Slide) {
        const groupedDataByBrand = this.groupByProperty(data, "Name");
        let legendData: any[] = [];

        groupedDataByBrand.forEach((group: PHBSDeepDiveDataModel[]) => {
            let brand: string = "";
            let color: string = "";
            let ranks: number[] = [];

            group.forEach((obj: PHBSDeepDiveDataModel) => {
                brand = obj.Name;
                color = obj.Color;
                ranks.push(obj.BrandRank);
            });
            const rank = Math.min(...ranks);
            legendData.push({ brand, color, rank });
        });

        legendData.sort((a: any, b: any) => a.rank - b.rank || a.brand.localeCompare(b.brand));

        let x = 8.13;
        let y = 0.772;
        legendData.forEach((obj: any) => {
            PptxRenderHelper.renderShapeLegend(chartSlide, { label: obj.brand, color: obj.color as string }, { x, y, fontSize: 6 }, 'rect');
            if (y > 5.4) {
                x += 0.96;
                y = 0.772;
            } else {
                y += 0.133;
            }
        })
    };

    protected labelRotateDegree(array: any) {
        let degree: number = 0;
        array.forEach((obj: any) => {
            if (obj.labels.length > 10) {
                degree = 45;
            } else {
                degree = 0;
            }
        });
        return degree;
    }

    private distinctRepeatables = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
    };
}

import React from 'react';
import { format } from 'date-fns';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { IListViewProps } from './IListViewProps';
import styles from './ListView.module.scss';
import { TableauWorkbookViewModel } from '../../../models/Tableau/TableauWorkbookViewModel';
import history from '../../../history';
import { Routes } from '../../../shared/routes';
import { useDispatch } from 'react-redux';
import { setSelectedWorkbook } from '../../../store/tableau/TableauActions';

export const ListView = (props: IListViewProps): JSX.Element => {
  const { workbooks } = props;
  const dispatch = useDispatch();

  const goToDefaultView = (workbook: TableauWorkbookViewModel) => {
    if (workbook.views[0]) {
      history.push(Routes.tableauView.getPath(workbook.views[0].id));
      dispatch(setSelectedWorkbook(workbook));
    }
  };

  const getDateBody = (
    rowData: TableauWorkbookViewModel,
    column: any
  ): JSX.Element => {
    const columnKey = column.field as keyof TableauWorkbookViewModel;
    const dateValue: Date = rowData[columnKey] as Date;
    const formattedDate = format(new Date(dateValue), 'dd-MM-yyyy HH:mm');
    return <span><span className={styles.last_modified}>Last Modified on: </span>{formattedDate}</span>;
  };

  const getTagsBody = (
    rowData: TableauWorkbookViewModel,
    column: any
  ): JSX.Element => {
    const value = rowData.tags.map((t) => (
      <span key={t} className={styles.tag}>
        {t}
      </span>
    ));

    return <div className={styles.tags_container}>{value}</div>;
  };

  return (
    <React.Fragment>
      <DataTable
        value={workbooks}
        responsive={true}
        className={`${styles.wb_table}`}
        onRowClick={(e) => goToDefaultView(e.data)}
        id={styles.wb_list_view}
        paginator={true}
        rows={10}
        alwaysShowPaginator={false}
      >
        <Column field='name' header='Project Name' className={styles.project_title} />
        <Column field='updatedAt' header={'Last Modified'} body={getDateBody} />
        <Column field='tags' header={'Tags'} body={getTagsBody} />
      </DataTable>
    </React.Fragment>
  );
};

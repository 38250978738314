import React from 'react';
import { ISVGProps } from './ISVGProps';

export const VideoSvg = (props: ISVGProps) => {
  const { width, height } = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 16'
    >
      <defs>
        <style>
          {
            '.a{fill:rgba(0,0,0,0);stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}'
          }
        </style>
      </defs>
      <g transform='translate(0 -4)'>
        <path className='a' d='M23,7l-7,5,7,5Z' />
        <rect
          className='a'
          width='15'
          height='14'
          rx='2'
          transform='translate(1 5)'
        />
      </g>
    </svg>
  );
};

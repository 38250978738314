import pptxgen from 'pptxgenjs';
import { FamiliarityTitleModel } from '../../../models/PersonalHealth/Familiarity/FamiliarityTitleModel';
import { FamiliarityDataModel } from '../../../models/PersonalHealth/Familiarity/FamiliarityDataModel';

import { PHFirstSlideRenderer } from '../../Renderers/PHFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

export class FamiliarityService extends BasePptxService {
    protected chartQuestion: string = '';
    protected firstSlideRenderer: PHFirstSlideRenderer = new PHFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
    }

    protected async setChartSlideLayout(slide: pptxgen.Slide) {
      // Override title's options and add filter's name in it.
      const titleData = await this.getMappedChartData(FamiliarityTitleModel, this.titleSheet);
      let subTitle: string = "";
      titleData.map( obj => {
        this.chartTitle = obj.Title;
        return subTitle = obj.SubTitle;
      });
      slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.35, bold: true });
      slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.58, italic: true });
    }
  
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
      let KPIValue: string = '';
      const mappedData = await this.getMappedChartData(FamiliarityDataModel, this.dataSheet);

      mappedData.forEach((obj) => {
        if (obj.KPIValue) {
          KPIValue = (obj.KPIValue * 100).toFixed(1);
        }
      })

      chartSlide.addText([{ text: `${KPIValue}%`, options: { fontSize: 35 } }], { x: 4.1, y: 2.7 });
    }
}

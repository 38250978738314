import { IDataMapping } from '../../IDataMapping';

export class SPMarketOverviewModel implements IDataMapping<SPMarketOverviewModel> {
    public Country: string = '';
    public Answer: string = '';
    public Sum: number | null = 0;
    public AveragePositive: number | null = 0;
    public AverageNegative: number | null = 0;
    public Order: number | null = 0;

    public getMappings?() {
        return {
            Country: 'Country',
            Answer: 'Answer',
            Sum: 'SUM(%)',
            AveragePositive: 'AGG(Sample Size Color <50 BLACK)',
            AverageNegative: 'AGG(Sample Size Color <50 RED)',
            Order: 'ATTR(Calc Country Order)',
        };
    }
}

import { IDataMapping } from '../../../IDataMapping';

export class AppendixJapanTableDataModel implements IDataMapping<AppendixJapanTableDataModel> {
    public Category: string = '';
    public CategoryVal: string = '';
    public Other: string = '';
    public Brand: string = '';
    public Value: number = Number(0);
    public PhilipsColor: number = Number(0);
    public getMappings?() {
        return {
            Category: 'Answer_Text',
            CategoryVal: 'AGG(BaseAppJap)',
            Other: "Other sort",
            Brand: 'Brand',
            Value: 'MAX(Value)',
            PhilipsColor: 'Philips color',
        };
    }
}

import { IDataMapping } from "../../../../IDataMapping";

export class SRCBSAnalysisByAudienceTableHeaderRowModel implements IDataMapping<SRCBSAnalysisByAudienceTableHeaderRowModel> {
    public Answer: string = '';

    public getMappings?() {
        return {
            Answer: 'Answer BS legend',
        };
    }
}

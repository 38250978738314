import pptxgen from 'pptxgenjs';

import { SnapshotTitleModel } from '../../../models/SRC/Snapshot/SnapshotTitleModel';
import { SnapshotBPBarDataModel } from '../../../models/SRC/Snapshot/SnapshotBPBarDataModel';
import { SnapshotNPSBarDataModel } from '../../../models/SRC/Snapshot/SnapshotNPSBarDataModel';
import { SnapshotNPSBarTableDataModel } from '../../../models/SRC/Snapshot/SnapshotNPSBarTableDataModel';
import { SnapshotBPLineDataModel } from '../../../models/SRC/Snapshot/SnapshotBPLineDataModel';
import { SnapshotMRBarDataModel } from '../../../models/SRC/Snapshot/SnapshotMRBarDataModel';
import { SnapshotNoteDataModel } from '../../../models/SRC/Snapshot/SnapshotNoteDataModel';

import { SRCFirstSlideRenderer } from '../../Renderers/SRCFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

import { filterInitDuplicateData, addEmptyValuesToInitData } from "../../../../shared/utility";

export class SnapshotService extends BasePptxService {
  protected chartQuestion: string = '';
  protected firstSlideRenderer: SRCFirstSlideRenderer = new SRCFirstSlideRenderer();
  protected titleSheet: string;
  protected brandPreferenceTitleSheet: string;
  protected brandPreferenceDataSheet: string;
  protected NPSTitleSheet: string;
  protected NPSDataSheet: string;
  protected NPSTableDataSheet: string;
  protected BPLineTitleSheet: string;
  protected BPLineDataSheet: string;
  protected MRTitleSheet: string;
  protected MRDataSheet: string;
  protected noteDataSheet: string;

  constructor(view: any, chartTitle: string, titleSheet: string,
    brandPreferenceTitleSheet: string, brandPreferenceDataSheet: string,
    NPSTitleSheet: string, NPSDataSheet: string, NPSTableDataSheet: string,
    BPLineTitleSheet: string, BPLineDataSheet: string,
    MRTitleSheet: string, MRDataSheet: string, noteDataSheet: string,
  ) {
    super(view, chartTitle);
    this.titleSheet = titleSheet;
    this.brandPreferenceTitleSheet = brandPreferenceTitleSheet;
    this.brandPreferenceDataSheet = brandPreferenceDataSheet;
    this.NPSTitleSheet = NPSTitleSheet;
    this.NPSDataSheet = NPSDataSheet;
    this.NPSTableDataSheet = NPSTableDataSheet;
    this.BPLineTitleSheet = BPLineTitleSheet;
    this.BPLineDataSheet = BPLineDataSheet;
    this.MRTitleSheet = MRTitleSheet;
    this.MRDataSheet = MRDataSheet;
    this.noteDataSheet = noteDataSheet;
  }

  protected async setChartSlideLayout(slide: pptxgen.Slide) {
    const titleData = await this.getMappedChartData(SnapshotTitleModel, this.titleSheet);

    titleData.map(obj => {
      this.chartTitle = obj.Title;
    });

    slide.addText([{ text: this.chartTitle, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.35, bold: true });
  }

  protected async addChartSlide(chartSlide: pptxgen.Slide) {
    const bpBarData = await this.getMappedChartData(SnapshotBPBarDataModel, this.brandPreferenceDataSheet);
    const npsBarData = await this.getMappedChartData(SnapshotNPSBarDataModel, this.NPSDataSheet);
    const npsBarTableData = await this.getMappedChartData(SnapshotNPSBarTableDataModel, this.NPSTableDataSheet);
    const bpLineData = await this.getMappedChartData(SnapshotBPLineDataModel, this.BPLineDataSheet);
    const mrBarData = await this.getMappedChartData(SnapshotMRBarDataModel, this.MRDataSheet);
    const noteData = await this.getMappedChartData(SnapshotNoteDataModel, this.noteDataSheet);

    if (bpBarData.length > 0) {
      await this.addBPBarChart(chartSlide, bpBarData);
    };
    if (npsBarData.length > 0) {
      await this.addNPSBarChart(chartSlide, npsBarData, npsBarTableData);
    };
    if (bpLineData.length > 0) {
      await this.addBPLineChart(chartSlide, bpLineData);
    };
    if (mrBarData.length > 0) {
      await this.addMRBarChart(chartSlide, mrBarData);
    };
    if (noteData.length > 0) {
      chartSlide.addText([{
        text: "*Note: Scores could be influenced by small sample sizes.",
        options: { color: this.colors.black, fontSize: 8 }
      }], { x: 8.6, y: 2.9, bold: false, w: 1.25, h: 0.6 });
    }
  }

  protected async addBPBarChart(slide: pptxgen.Slide, data: any) {
    const titleData = await this.getMappedChartData(SnapshotTitleModel, this.brandPreferenceTitleSheet);
    let filteredData = filterInitDuplicateData(data);
    // filteredData.sort((a: any, b: any) => a.Rank - b.Rank); - Skip for now, otherwise we have to reverse the array.
    let chartTitle: string = "";
    const lineColors: string[] = [];
    const labels: any[] = [];
    const values: any[] = [];

    titleData.map((obj: any) => {
      return chartTitle = obj.BPBarTitle;
    });

    const newArr = [
      {
        name: "Chart",
        labels: labels,
        values: values,
      },
    ];

    filteredData.forEach((obj: any) => {
      lineColors.push(obj.Color.replace('#', ''));
      labels.push(obj.Label);
      values.push(obj.Value);
    });

    slide.addChart(
      this.presentation.ChartType.bar,
      newArr,
      {
        showTitle: true,
        title: chartTitle,
        titleFontSize: 10,
        x: 0.5,
        y: 0.5,
        w: 4,
        h: 2.5,
        barDir: "bar",
        barGapWidthPct: 120,
        catGridLine: { style: "none" },
        valAxisHidden: true,
        valGridLine: { style: "none" },
        showLegend: false,
        showValue: true,
        dataLabelPosition: "outEnd",
        dataLabelFormatCode: '0%',
        valAxisLabelFormatCode: '0%',
        catAxisLabelFontSize: 8,
        dataLabelFontSize: 8,
        chartColors: lineColors.filter(this.distinctRepeatables),
      }
    );
  };

  protected async addNPSBarChart(slide: pptxgen.Slide, data: any, tableData: any) {
    const titleData = await this.getMappedChartData(SnapshotTitleModel, this.NPSTitleSheet);
    let filteredData = filterInitDuplicateData(data);
    // filteredData.sort((a: any, b: any) => a.Rank - b.Rank); - Skip for now, otherwise we have to reverse the array.
    let chartTitle: string = "";
    const newArr: any = [];
    const lineColors: string[] = [];

    titleData.map((obj: any) => {
      return chartTitle = obj.NPSBarTitle;
    });

    const getAllLabels: any = [];
    filteredData.forEach((obj: any) => {
      getAllLabels.push(obj.Label);
    });
    const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

    filteredData.forEach((obj: any) => {
      if (obj.Name) {
        const item = newArr.find((item: any) => item.name === obj.Name);
        lineColors.push(obj.Color);
        if (newArr && newArr.length > 0 && item) {
          item.values.push(parseFloat(obj.Value));
          item.labels.push(obj.Label);
        } else {
          newArr.push(
            {
              name: obj.Name,
              labels: [obj.Label],
              values: [parseFloat(obj.Value)],
            }
          )
        };
      };
    });

    const finalChartData = addEmptyValuesToInitData(distinctedLabels, newArr);

    const scoreArr: any = [];
    const sampleArr: any = [];
    scoreArr.push([{
      text: '',
      options: { color: '#000000' }
    }]);
    sampleArr.push([{
      text: '',
      options: { color: '#000000' }
    }]);

    tableData.forEach((obj: any) => {
      if (obj.Base) {
        sampleArr.push(
          [{
            text: `(n=${obj.Base <= 20 ? (obj.Base).toFixed() + '*' : (obj.Base).toFixed()})`,
            options: { color: obj.Base < 10 ? '#FF0000' : '#000000' }
          }],
          [{
            text: '',
            options: { color: '#000000' }
          }]
        );
        scoreArr.push(
          [{
            text: `${((obj.AvgValue) * 100).toFixed()}%`,
            options: { color: '#000000' }
          }],
          [{
            text: `${((obj.DiffValue) * 100).toFixed()}%`,
            options: { color: obj.Color }
          }],
          [{
            text: '',
            options: { color: '#000000' }
          }]
        );
      }
    });

    const scoreProps: pptxgen.TableProps = {
      x: 7.9,
      y: 0.9,
      w: 1,
      h: 2.1,
      align: 'center',
      fontSize: 5,
      color: this.colors.white,
      border: { type: 'none' },
      valign: 'middle',
    };

    const sampleProps: pptxgen.TableProps = {
      x: 5.3,
      y: 1.15,
      w: 1,
      h: 2.1,
      align: 'center',
      fontSize: 5,
      color: this.colors.white,
      border: { type: 'none' },
    };

    slide.addChart(
      this.presentation.ChartType.bar,
      finalChartData,
      {
        showTitle: true,
        title: chartTitle,
        titleFontSize: 10,
        x: 4.9,
        y: 0.5,
        w: 4.5,
        h: 2.5,
        barDir: "bar",
        barGrouping: "stacked",
        barGapWidthPct: 120,
        catGridLine: { style: "none" },
        valAxisHidden: true,
        valGridLine: { style: "none" },
        valAxisMaxVal: 1.1,
        showLegend: true,
        showValue: true,
        legendPos: 'r',
        legendFontSize: 8,
        dataLabelFormatCode: '0%',
        valAxisLabelFormatCode: '0%',
        catAxisLabelFontSize: 8,
        dataLabelFontSize: 8,
        chartColors: lineColors.filter(this.distinctRepeatables),
        dataLabelColor: this.colors.white,
      }
    );

    slide.addText([{
      text: "≥+5% Increase in Product NPS Score when compared to last period ",
      options: { color: this.colors.lightPositive, fontSize: 5 }
    }], { x: 8.6, y: 2.1, bold: false, w: 1.25, h: 0.4 });
    slide.addText([{
      text: "≤-5% Drop in Product NPS Score when compared to last period ",
      options: { color: this.colors.lightNegative, fontSize: 5 }
    }], { x: 8.6, y: 2.3, bold: false, w: 1.25, h: 0.4 });

    slide.addTable(scoreArr, scoreProps);
    slide.addTable(sampleArr, sampleProps);
  };

  protected async addBPLineChart(slide: pptxgen.Slide, data: any) {
    const titleData = await this.getMappedChartData(SnapshotTitleModel, this.BPLineTitleSheet);
    const filteredData = filterInitDuplicateData(data);
    let chartTitle: string = "";
    const newArr: any = [];
    const lineColors: string[] = [];

    titleData.map((obj: any) => {
      return chartTitle = obj.BPLineTitle;
    });

    const getAllLabels: any = [];

    filteredData.forEach((obj: any) => {
      getAllLabels.push(obj.Label);
    });

    const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

    filteredData.forEach((obj: any) => {
      if (obj.Value) {
        lineColors.push(obj.Color);
      }
      const item = newArr.find((item: any) => item.name === obj.Name);

      if (newArr.length > 0 && item) {
        item.values.push(parseFloat(obj.Value));
        item.labels.push(obj.Label);
      } else {
        newArr.push(
          {
            name: obj.Name,
            labels: [obj.Label],
            values: [parseFloat(obj.Value)],
          }
        )
      }
    });
    const finalChartData = addEmptyValuesToInitData(distinctedLabels, newArr);

    const sampleData: any = [];
    const sampleArr: any = [];

    data.forEach((obj: any) => {
      if (obj.Base) {
        sampleArr.push(
          {
            text: `${obj.Rank}(n=${obj.Base <= 20 ? (obj.Base).toFixed() + '*' : (obj.Base).toFixed()})`,
            options: { color: obj.Base < 10 ? '#FF0000' : '#000000' }
          }
        );
      }
    });

    const samples = sampleArr.map((o: any) => o.text);
    const filteredSamples = sampleArr.filter(({ text }: any, index: any) => !samples.includes(text, index + 1));
    sampleData.push(filteredSamples);
    sampleData.forEach((obj: any) => {
      obj.forEach((o: any) => o.text = (o.text).substring(1));
    });

    const sampleProps: pptxgen.TableProps = {
      x: 0.9,
      y: 5.25,
      w: 3.43,
      align: 'center',
      bold: false,
      fontSize: 6,
      valign: "middle",
      rowH: 0.1,
      color: this.colors.white,
      border: { type: 'none' }
    };

    slide.addChart(
      this.presentation.ChartType.line,
      finalChartData,
      {
        showTitle: true,
        title: chartTitle,
        titleFontSize: 10,
        x: 0.5,
        y: 2.9,
        w: 4,
        h: 2.5,
        showValue: true,
        dataLabelPosition: 't',
        dataLabelFormatCode: '0%',
        valAxisLabelFormatCode: '0%',
        catAxisLabelRotate: this.labelRotateDegree(newArr),
        chartColors: lineColors.filter(this.distinctRepeatables),
        valAxisLabelFontSize: 8,
        catAxisLabelFontSize: 8,
        dataLabelFontSize: 8,
      }
    );

    slide.addTable(sampleData, sampleProps);
  };

  protected async addMRBarChart(slide: pptxgen.Slide, data: any) {
    const titleData = await this.getMappedChartData(SnapshotTitleModel, this.MRTitleSheet);
    const filteredData = filterInitDuplicateData(data);
    let chartTitle: string = "";
    const newArr: any = [];
    const lineColors: string[] = [];

    titleData.map((obj: any) => {
      return chartTitle = obj.MRBarTitle;
    });

    const getAllLabels: any = [];

    filteredData.forEach((obj: any) => {
      getAllLabels.push(obj.Label);
    });
    const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

    filteredData.forEach((obj: any) => {
      if (obj.Value) {
        lineColors.push(obj.Color);
      }
      const item = newArr.find((item: any) => item.name === obj.Name);
      if (newArr.length > 0 && item) {
        if (item) {
          item.values.push(parseFloat(obj.Value));
          item.labels.push(obj.Label);
        }
      } else {
        newArr.push(
          {
            name: obj.Name,
            labels: [obj.Label],
            values: [parseFloat(obj.Value)],
          }
        )
      }
    });

    const finalChartData = addEmptyValuesToInitData(distinctedLabels, newArr);

    slide.addChart(
      this.presentation.ChartType.bar,
      finalChartData,
      {
        showTitle: true,
        title: chartTitle,
        titleFontSize: 10,
        x: 4.5,
        y: 2.9,
        w: 5.3,
        h: 2.5,
        barDir: "col",
        dataLabelFormatCode: '0%',
        valAxisLabelFormatCode: '0%',
        valAxisLabelFontSize: 8,
        chartColors: lineColors.filter(this.distinctRepeatables),
        catAxisLabelPos: "low",
        catAxisLabelFontSize: 8,
        showLegend: true,
        legendPos: 'r',
        legendFontSize: 8,
        showValue: true,
        dataLabelPosition: "outEnd",
        valGridLine: { style: "none" },
        dataLabelFontSize: 8,
      }
    );
  };

  protected labelRotateDegree(array: any) {
    let degree: number = 0;
    array.forEach((obj: any) => {
      if (obj.labels.length > 10) {
        degree = 45;
      } else {
        degree = 0;
      }
    });
    return degree;
  }

  private distinctRepeatables = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };
};

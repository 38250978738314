import React, { createRef } from 'react';
import styles from './PopupMenu.module.scss';
import { IPopupMenuProps } from './IPopupMenuProps';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';

export const PopupMenu = (props: IPopupMenuProps): JSX.Element => {
    const { id, menuItems } = props;
    const menu = createRef<any>();

    return (
        <React.Fragment>
            <Menu
                className={styles.popupMenu}
                model={menuItems}
                popup={true}
                ref={menu}
                id={id}
                appendTo={document.body} />
            <Button
                icon='pi pi-ellipsis-v'
                className={styles.default_button}
                aria-controls={id}
                aria-haspopup={true}
                onClick={(event) => {
                    if (menu) {
                        (menu as any).current.toggle(event);
                    }
                }} />
        </React.Fragment>
    );
};

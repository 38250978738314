import { IDataMapping } from '../../IDataMapping';

export class PHBMCSustainabilityTitleModel implements IDataMapping<PHBMCSustainabilityTitleModel> {
    public Title: string = '';
    public SubTitle: string = '';
    public Filter: string = '';
    public getMappings?() {
        return {
            Title: 'BMC Page Titles',
            SubTitle: 'AGG(Category User Profile Sub Title)',
            Filter: 'ATTR(Indicator Name)',
        };
    }
}

import { IDataMapping } from '../../../../IDataMapping';

export class NpsByBusinessGroupChartTitleModel implements IDataMapping<NpsByBusinessGroupChartTitleModel> {
    public title: string = '';
    public period: string = '';

    public getMappings?() {
        return {
            title: 'Product NPS Leadership Title',
            period: 'Prm_Title_BMC_periods',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class CompetitorsPerMarketTitleModel implements IDataMapping<CompetitorsPerMarketTitleModel> {
    public Title: string = '';
    public SubTitle: string = '';
    public getMappings?() {
        return {
            Title: "CompPerMarkTitle",
            SubTitle: 'CompPerMarkTitle (2)',
        };
    }
}

import pptxgen from 'pptxgenjs';

import { HospitalIDNPieChartMainTitleModel } from '../../../../../models/HealthSystems/StrategicPartnership/HospitalIDNStrategicPartner/HospitalIDNPieChart/HospitalIDNPieChartMainTitleModel';
import { HospitalIDNPieChartFirstTitleModel } from '../../../../../models/HealthSystems/StrategicPartnership/HospitalIDNStrategicPartner/HospitalIDNPieChart/HospitalIDNPieChartFirstTitleModel';
import { HospitalIDNPieChartSecondTitleModel } from '../../../../../models/HealthSystems/StrategicPartnership/HospitalIDNStrategicPartner/HospitalIDNPieChart/HospitalIDNPieChartSecondTitleModel';
import { HospitalIDNPieChartFirstDataModel } from '../../../../../models/HealthSystems/StrategicPartnership/HospitalIDNStrategicPartner/HospitalIDNPieChart/HospitalIDNPieChartFirstDataModel';
import { HospitalIDNPieChartSecondDataModel } from '../../../../../models/HealthSystems/StrategicPartnership/HospitalIDNStrategicPartner/HospitalIDNPieChart/HospitalIDNPieChartSecondDataModel';

import { HSStrategicFirstSlideRenderer } from '../../../../Renderers/HSStrategicFirstSlideRenderer';
import { BasePptxService } from '../../../../BasePptxService';
import { PptxRenderHelper } from '../../../../../helpers/PptxRenderHelper';

export class HospitalIDNPieChartService extends BasePptxService {
    protected chartQuestion: string = "Q: Across the entire hospital (and/or hospital network), " +
        "are there any medical device or healthcare technology vendors that stand out for you in terms of " +
        "makring an effort to partner with you to meet your challenges?\r" +
        "Q: Which vendor do you think of a stratetigc parnter/your main strategic partner across the entire hospital/or hospital netowork?";

    protected firstSlideRenderer: HSStrategicFirstSlideRenderer = new HSStrategicFirstSlideRenderer();
    protected mainTitleSheet: string;
    protected firstTitleSheet: string;
    protected secondTitleSheet: string;
    protected firstDataSheet: string;
    protected secondDataSheet: string;

    constructor(view: any, chartTitle: string, mainTitleSheet: string, firstTitleSheet: string,
        secondTitleSheet: string, firstDataSheet: string, secondDataSheet: string) {
        super(view, chartTitle);
        this.mainTitleSheet = mainTitleSheet;
        this.firstTitleSheet = firstTitleSheet;
        this.secondTitleSheet = secondTitleSheet;
        this.firstDataSheet = firstDataSheet;
        this.secondDataSheet = secondDataSheet;
    }

    // Override of a super class method
    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const mappedMainTitleData = await this.getMappedChartData(HospitalIDNPieChartMainTitleModel, this.mainTitleSheet);

        mappedMainTitleData.forEach(obj => {
            this.chartTitle = `${obj.Title} ${obj.Country} - ${obj.Segment} - ${obj.Period}`;
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.55, bold: true });
        slide.addText([{ text: this.chartQuestion, options: { color: this.colors.black, fontSize: 7 } }], { x: 0.5, y: '95%', bold: false });
    }

    // Override of a super class method
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        await this.addFirstPieChart(chartSlide);
        await this.addSecondPieChart(chartSlide);
    }

    private async addFirstPieChart(chartSlide: pptxgen.Slide) {
        const mappedTitleData = await this.getMappedChartData(HospitalIDNPieChartFirstTitleModel, this.firstTitleSheet);
        const mappedChartData = await this.getMappedChartData(HospitalIDNPieChartFirstDataModel, this.firstDataSheet);

        let chartTitle = "";
        mappedTitleData.forEach(obj => {
            chartTitle = `${obj.Title}`;
        });

        if (mappedChartData.length > 0) {

            // Getting the sample data from object with an AverageNegative value or an AveragePositive value
            mappedChartData.forEach((obj) => {
                if (obj.AverageNegative) {
                    chartSlide.addText([{ text: `N = ${obj.AverageNegative}`, options: { color: this.colors.negative, fontSize: 14, align: 'center', valign: 'middle' } }], { x: 0.5, y: 2.1, w: 3.15, h: 2.1, bold: false });
                } else if (obj.AveragePositive) {
                    chartSlide.addText([{ text: `N = ${obj.AveragePositive}`, options: { color: this.colors.default, fontSize: 14, align: 'center', valign: 'middle' } }], { x: 0.5, y: 2.1, w: 3.15, h: 2.1, bold: false });
                }
            });

            // Creating an array of objects with only valid Answer properties
            const filteredChartData = mappedChartData.filter((obj) => obj.Answer && obj.Answer !== " ");

            const lineColors = PptxRenderHelper.getMappedChartDataColorsSP(filteredChartData);

            const dataChartPieStat = [
                {
                    name: chartTitle,
                    labels: filteredChartData.map(x => x.Answer),
                    values: filteredChartData.map(x => (Math.round(x.Sum ? (x.Sum * 100) : 0)))
                }
            ];

            const chartOptions: pptxgen.IChartOpts = {
                x: 0.5, y: 1.5, w: 4.5, h: 3,
                layout: { x: 0, y: 0.2, w: 0.7, h: 0.7 },
                showTitle: true,
                title: chartTitle,
                titleFontSize: 10,
                titleColor: this.colors.default,
                holeSize: 80,
                chartColors: lineColors,
                dataBorder: { pt: 0.5, color: 'F1F1F1' },
                showValue: true,
                showPercent: false,
                dataLabelFormatCode: '#\\%',
                dataLabelPosition: 'outEnd',
                dataLabelColor: "FFFFFF",
                dataLabelFontSize: 6,
                showLegend: true,
                legendFontSize: 8,
                legendPos: 'r'
            };

            chartSlide.addChart(this.presentation.ChartType.doughnut, dataChartPieStat, chartOptions);
        };
    };

    private async addSecondPieChart(chartSlide: pptxgen.Slide) {
        const mappedTitleData = await this.getMappedChartData(HospitalIDNPieChartSecondTitleModel, this.secondTitleSheet);
        const mappedChartData = await this.getMappedChartData(HospitalIDNPieChartSecondDataModel, this.secondDataSheet);

        let chartTitle = "";
        mappedTitleData.forEach(obj => {
            chartTitle = `${obj.Title}`;
        });

        if (mappedChartData.length > 0) {

            // Getting the sample data from object with an AverageNegative value or an AveragePositive value
            mappedChartData.forEach((obj) => {
                if (obj.AverageNegative) {
                    chartSlide.addText([{ text: `N = ${obj.AverageNegative}`, options: { color: this.colors.negative, fontSize: 14, align: 'center', valign: 'middle' } }], { x: 5, y: 2.1, w: 3.15, h: 2.1, bold: false });
                } else if (obj.AveragePositive) {
                    chartSlide.addText([{ text: `N = ${obj.AveragePositive}`, options: { color: this.colors.default, fontSize: 14, align: 'center', valign: 'middle' } }], { x: 5, y: 2.1, w: 3.15, h: 2.1, bold: false });
                }
            });

            // Creating an array without the object with null brand
            const filteredChartData = mappedChartData.filter((obj) => obj.Brand);

            const lineColors = PptxRenderHelper.getMappedChartDataColorsSP(filteredChartData);

            const dataChartPieStat = [
                {
                    name: chartTitle,
                    labels: filteredChartData.map(x => x.Brand),
                    values: filteredChartData.map(x => (Math.round(x.Sum ? (x.Sum * 100) : 0)))
                }
            ];

            const chartOptions: pptxgen.IChartOpts = {
                x: 5, y: 1.5, w: 4.5, h: 3,
                layout: { x: 0, y: 0.2, w: 0.7, h: 0.7 },
                showTitle: true,
                title: chartTitle,
                titleFontSize: 10,
                titleColor: this.colors.default,
                holeSize: 80,
                chartColors: lineColors,
                dataBorder: { pt: 0.5, color: 'F1F1F1' },
                showValue: true,
                showPercent: false,
                dataLabelFormatCode: '#\\%',
                dataLabelPosition: 'outEnd',
                dataLabelColor: "FFFFFF",
                dataLabelFontSize: 6,
                showLegend: true,
                legendFontSize: 8,
                legendPos: 'r',
            }

            chartSlide.addChart(this.presentation.ChartType.doughnut, dataChartPieStat, chartOptions);
        };
    };
}

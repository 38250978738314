import { IDataMapping } from '../../IDataMapping';

export class CommunicationChannelsModel implements IDataMapping<CommunicationChannelsModel> {
    public Answer: string = '';
    public Order: number | null = 0;
    public Favourability: number | null = 0;
    public Impact: number | null = 0;
    public Average: number | null = 0;
    public Color: string = '';
    public Shape: string = '';

    public getMappings?() {
        return {
            Answer: 'Answer',
            Order: '"Calc Answer Order "',
            Favourability: 'SUM(Favourability)',
            Impact: 'SUM(Impact)',
            Average: 'AVG(Sample size)',
            Color: 'ATTR(Calc Answer Color)',
            Shape: 'Calc Answer Shape',
        };
    }
}


export const Routes = {
  workbooks: {
    pattern: '/',
    getPath: (): string => `/`,
  },
  tableauView: {
    pattern: '/TableauView/:viewId',
    getPath: (id: string): string => `/TableauView/${id}`,
  },
  usersView: {
    pattern: '/Users',
    getPath: (): string => '/Users',
  },
  rolesView: {
    pattern: '/Roles',
    getPath: (): string => '/Roles',
  },
  errorPage: {
    pattern: '/Error',
    getPath: (): string => '/Error',
  },
  filesPage: {
    pattern: '/Files',
    getPath: (): string => '/Files',
  },
  helpCenter: {
    pattern: '/HelpCenter',
    getPath: (): string => '/HelpCenter',
  },
  home: {
    pattern: '/Home',
    getPath: (): string => '/Home',
  },
};

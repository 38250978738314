import pptxgen from 'pptxgenjs';

import { NPSTrendsQuarterlyTitleModel } from '../../../../models/HealthSystems/ProductLevelNPS/TrendsQuarterly/NPSTrendsQuarterlyTitleModel';
import { NPSTrendsQuarterlyDataModel } from '../../../../models/HealthSystems/ProductLevelNPS/TrendsQuarterly/NPSTrendsQuarterlyDataModel';
import { NPSTrendsQuarterlyLegendDataModel } from '../../../../models/HealthSystems/ProductLevelNPS/TrendsQuarterly/NPSTrendsQuarterlyLegendDataModel';

import { BasePptxService } from '../../../BasePptxService';
import { HSFirstSlideRenderer } from '../../../Renderers/HSFirstSlideRenderer';
import { BaseChartOptions } from '../../../../philips-constants';
import { LegendData } from '../../../../models/ProductLevelNPS/LegendData';
import { addEmptyValuesToInitData, filterInitDuplicateData } from '../../../../../shared/utility';


export class NPSTrendsQuarterlyService extends BasePptxService {
    protected firstSlideRenderer: HSFirstSlideRenderer = new HSFirstSlideRenderer();
    protected chartQuestion: string =
        `NPS question: Based on your experience on using their product and services, how likely are you to recommend this company?`;

    protected titleSheet: string;
    protected lineChartDataSheet: string;
    protected legendDataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string,
        lineChartDataSheet: string, legendDataSheet: string,) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.lineChartDataSheet = lineChartDataSheet;
        this.legendDataSheet = legendDataSheet;
    }

    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const mappedTitleData = await this.getMappedChartData(NPSTrendsQuarterlyTitleModel, this.titleSheet);

        mappedTitleData.forEach(obj => {
            this.chartTitle = `${obj.Title}${obj.Country} · ${obj.BG} · ${obj.Modality} · ${obj.Submodality}`;
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.55, bold: true });
        slide.addText([{ text: this.chartQuestion, options: { color: this.colors.black, fontSize: 7 } }], { x: 0.5, y: '95%', bold: false });
    }

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const lineChartData = await this.getMappedChartData(NPSTrendsQuarterlyDataModel, this.lineChartDataSheet);
        const legendData = await this.getMappedChartData(NPSTrendsQuarterlyLegendDataModel, this.legendDataSheet);

        if (legendData.length > 0 && lineChartData.length > 0) {

            await this.addLineChart(chartSlide, lineChartData, legendData);
        }
    }

    protected async addLineChart(slide: pptxgen.Slide, data: any, legData: any) {
        const lineColors = this.getLineColors(legData);
        const legendData = this.processLegendData(legData);
        const lineChartData = this.processChartData(data);

        slide.addChart(this.presentation.ChartType.line, lineChartData, {
            ...BaseChartOptions,
            x: 1,
            y: 1.1,
            w: 8,
            h: 3.5,
            showValue: true,
            dataLabelPosition: 't',
            dataLabelFormatCode: '0%',
            valAxisLabelFormatCode: '0%',
            catAxisLineShow: false,
            valAxisLineShow: false,
            valAxisMajorUnit: 0.2,
            chartColors: lineColors.filter(this.distinctRepeatables),
            dataLabelColor: this.colors.default,
            dataLabelFontSize: 10,
        });

        slide.addText(legendData, { x: 1, y: '85%' });
    };

    private getLineColors(data: any) {
        const lineColors: string[] = [];
        data.sort((a: any, b: any) => b.Value - a.Value);
        data.forEach((obj: any) => {
            const splittedTokens = (obj.RankBrandColor).split('|');
            lineColors.unshift(splittedTokens[2]);
        });

        return lineColors;
    }

    private processLegendData(data: any): LegendData[] {
        const newArr: any = [];
        data.forEach((obj: any) => {
            const [rank, brand, color] = (obj.RankBrandColor).split('|');
            const value = (obj.Value).toFixed();
            const baseColor = obj.BaseColor === '1' ? this.colors.default : this.colors.negative;
            newArr.push({ rank, brand, color, value, baseColor });
        });

        newArr.sort((a: any, b: any) => a.rank.localeCompare(b.rank));

        const legendData: LegendData[] = [];
        newArr.forEach((current: any) => {
            legendData.push(
                { text: `${current.brand} `, options: { color: current.color, fontSize: 8 } },
                { text: `(${current.value})    `, options: { color: current.baseColor, fontSize: 8 } }
            );
        });

        return legendData;
    }

    protected processChartData(data: any) {
        let filteredData = filterInitDuplicateData(data);
        const newArr: any = [];
        const getAllLabels: any = [];

        filteredData.forEach((obj: any) => {
            getAllLabels.push(obj.Label);
        });
        const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

        filteredData.forEach((obj: any) => {
            const item = newArr.find((item: any) => item.name === obj.Name);
            if (newArr.length > 0 && item) {
                item.values.push(parseFloat(obj.Value));
                item.labels.push(obj.Label);
            } else {
                newArr.push(
                    {
                        name: obj.Name,
                        labels: [obj.Label],
                        values: [parseFloat(obj.Value)],
                    }
                )
            }
        });
        const finalChartData = addEmptyValuesToInitData(distinctedLabels, newArr);

        return finalChartData;
    }

    private distinctRepeatables = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
    };
}
import { TableCellModel } from '../TableCellModel';

export class NPSDataModel {
    public Country: TableCellModel = new TableCellModel();
    public Year: TableCellModel = new TableCellModel();

    constructor(country: string, year: string, options: any) {
        this.Country = new TableCellModel(country, options);
        this.Year = new TableCellModel(year, options);
    }
}

import pptxgen from 'pptxgenjs';
import { NPSDataModel, TableCellModel } from '../../../';
import { TablePptxService } from '../../TablePptxService';
import { HSFirstSlideRenderer } from '../../Renderers/HSFirstSlideRenderer';
import { PPTSlidesCount } from '../../../../shared/enums/PPTSlidesCount';

export class GlobalBMCMatrixPptxService extends TablePptxService {
    protected firstSlideRenderer = new HSFirstSlideRenderer();
    protected chartQuestion: string = "";
    protected maxRowsCountOnSlide: number = 18;

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const sheetData = await this.getChartData('Global_BMC Matrix');
        const matrixTextLegend = await this.getChartData('Matrix text legend');

        if (!sheetData) {
            return;
        }

        const npsDataModels: NPSDataModel[] = this.processData(sheetData);
        const statusText: string = this.processFooterText(matrixTextLegend);

        await this.ensureAdditionalChartSlides(npsDataModels.length, PPTSlidesCount.MinSlidesCount, statusText);

        const headerRow: pptxgen.TableRow = this.getHeaderRow(Object.keys(npsDataModels[0]));
        const bodyRows: pptxgen.TableRow[] = this.getBodyRows(npsDataModels);
        
        this.addTable(chartSlide, [headerRow, ...bodyRows], { x: '15%', w: '70%', h: '55%' });
        await this.addLegend(chartSlide, statusText);
    }

    private async ensureAdditionalChartSlides(rowsCount: number, slidesCount: number, statusText: string) {
        if (rowsCount > slidesCount * this.maxRowsCountOnSlide) {
            const additionalChartSlide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });
            this.setChartSlideLayout(additionalChartSlide);
            await this.addLegend(additionalChartSlide, statusText);
            await this.ensureAdditionalChartSlides(rowsCount, slidesCount + 1, statusText);
        }
    }

    private processData(data: any) {
        const countryIndex: number = 0;
        const countryOrderIndex: number = 4;
        const periodIndex: number = 1;
        const periodOrderIndex: number = 7;
        const businessGroupNameIndex: number = 2;
        const businessGroupOrderIndex: number = 3;
        const colorIndex = 8;
        const valueIndex = 9;
        
        const dataModels: NPSDataModel[] = [];
        const businessGroupsOrder = this.getBusinessGroupsOrder(data, businessGroupNameIndex, businessGroupOrderIndex);

        data.forEach((rowArray: any[]) => {
            const country = rowArray[countryIndex].formattedValue;
            const year = rowArray[periodIndex].formattedValue;
            const records = dataModels.filter((x: NPSDataModel) => x.Country.text === country && x.Year.text === year);
            let current: NPSDataModel;
            if (records.length < 1) {
                current = new NPSDataModel(
                    country,
                    year,
                    {
                        fill: { color: this.colors.white },
                        countryOrder: parseInt(rowArray[countryOrderIndex].formattedValue),
                        periodOrder: parseInt(rowArray[periodOrderIndex].formattedValue)
                    }
                );
                businessGroupsOrder.map(bg => { current[bg as keyof NPSDataModel] = new TableCellModel() })
                dataModels.push(current);
            } else {
                current = records[0];
            }
            
            const prop = rowArray[businessGroupNameIndex].formattedValue as keyof NPSDataModel;
            const colorIndexValue = rowArray[colorIndex].value;
            const value = rowArray[valueIndex].value;
            const color = colorIndexValue && !isNaN(+colorIndexValue)
                ? +colorIndexValue < 2 ? this.colors.negative
                    : +colorIndexValue === 2 ? this.colors.white : this.colors.positive : this.colors.white;

            const displayValue = value && !isNaN(+value) ? `${(+value * 100).toFixed()}%` : '';
            current[prop].text = displayValue;
            current[prop].options = { fill: { color } };
        });
        return dataModels;
    }

    private processFooterText(sheetData: any[]) {
      let text: string = "";

      sheetData.forEach((rowArray: any[]) => { 
        text = rowArray[0].formattedValue;
      })
      return text
    }

    private getBodyRows(npsDataModels: NPSDataModel[]) {
        const grouped: any = {};
        let groupOrder: [string, number][] = [];
        
        npsDataModels.forEach((d) => {
            if (!grouped[d.Country.text]) {
                grouped[d.Country.text] = [];
                groupOrder.push([d.Country.text, d.Country.options.countryOrder]);
            }

            grouped[d.Country.text].push(d);
        });

        let groupOrderNames = groupOrder.sort((a, b) => a[1] - b[1]).map(x => { return x[0]; });

        const rows: pptxgen.TableRow[] = [];
        for (const groupName in groupOrderNames) {
            const key = groupOrderNames[groupName];
            if (Object.prototype.hasOwnProperty.call(grouped, key)) {
                grouped[key] = grouped[key]
                    .sort((a: NPSDataModel, b: NPSDataModel) => (Number(a.Year.options.periodOrder) < Number(b.Year.options.periodOrder)) ? -1 : 1);
                const currentRows: pptxgen.TableRow[] = grouped[key].map((d: NPSDataModel, index: number) => {
                    if (index === 0) {
                        return Object.keys(d).map(k => {
                            const prop: TableCellModel = d[k as keyof NPSDataModel];
                            return k === 'Country' ? { text: prop.text, options: { ...prop.options, rowspan: 2 } } : { ...prop };
                        });
                    } else {
                        return Object.keys(d).filter(x => x !== 'Country').map(k => {
                            const prop: TableCellModel = d[k as keyof NPSDataModel];
                            return { ...prop };
                        });
                    }
                });

                rows.push(...currentRows);
            }
        }

        return rows;
    }

    private async addLegend(npsOvertimeSlide: pptxgen.Slide, statusText: string) {
      const sheetData = await this.getChartData('Calc Countrty agg info');
      let footerText: string = "";

      sheetData.forEach((rowArray: any[]) => { 
        footerText = rowArray[0].formattedValue;
      })

        const legend: pptxgen.TableRow[] = [[
            { text: 'Leader', options: { fill: { color: this.colors.positive } } },
            { text: '', options: { fill: { color: this.colors.white } } },
            { text: 'Follower', options: { fill: { color: this.colors.negative } } },
        ]];

        const legendProps: pptxgen.TableProps = {
            x: '25%',
            y: '75%',
            w: '50%',
            align: 'center',
            bold: true,
            fontSize: 8,
            rowH: 0.1,
            color: this.colors.white,
            border: { type: 'solid', color: this.colors.neutral, pt: 0.2 }
        };

        npsOvertimeSlide.addText(
          [
              { text: statusText, options: { breakLine: true } },
              { text: '', options: { breakLine: true } },
              { text: footerText, options: { breakLine: true}, },
              { text: ' NPS question: Based on your experience on using their product and services, how likely are you to recommend this company?', options: { breakLine: true}, },
          ],
          { x: 0.5, y: 5, w: 6.5, fontSize: 7 }
      );

        npsOvertimeSlide.addTable(legend, legendProps);
    }
    private getBusinessGroupsOrder(sheetData: any[], bgNameIndex: number, bgOrderIndex: number) {
        const businessGroupsOrder: [string, number][] = [];
        sheetData.forEach((rowArray: any[]) => {
            const businessGroupName = rowArray[bgNameIndex].formattedValue;
            var result = businessGroupsOrder.find(x => x[0] == businessGroupName)
            if (!result) {
                businessGroupsOrder.push([businessGroupName, Number(rowArray[bgOrderIndex].formattedValue)]);
            }
        });

        return businessGroupsOrder.sort((a, b) => a[1] - b[1]).map(x => { return x[0]; });
    }
}

import { IDataMapping } from '../../IDataMapping';

export class BMCSimulatorLineChartDataModel implements IDataMapping<BMCSimulatorLineChartDataModel> {
    public Label: string = '';
    public Name: string = '';
    public ChartTitle: string = '';
    public Rank: number = Number(0);
    public Value: number = Number(0);
    public Color: string = '';
    public getMappings?() {
        return {
            Label: 'QUARTER(Date_calc (AA/Cons)_cont)',
            Name: 'Measure Names',
            ChartTitle: 'Brand Name',
            Rank: 'SUM(Rank (9G 2nd level))',
            Value: 'Measure Values',
            Color: 'ATTR(Brand name color)',
        };
    }
}

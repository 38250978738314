import pptxgen from 'pptxgenjs';
import { PptxRenderHelper } from '../../../helpers/PptxRenderHelper';
import { SPMarketOverviewModel } from '../../../models/StrategicPartnership/Hospital/SPMarketOverviewModel';
import { BaseChartOptions } from '../../../philips-constants';
import { BasePptxService } from '../../BasePptxService';
import { HSStrategicFirstSlideRenderer } from '../../Renderers/HSStrategicFirstSlideRenderer';

export class SPMarketOverviewPptxService extends BasePptxService {
    protected chartQuestion: string = 'Q: Across the entire hospital (and/or hospital network), ' +
        'are there any medical device or healthcare technology vendors that stand out for you ' +
        'in terms of marking an effort to partner with you to meet your challenges?';
    protected spAnswerColors = ['0066a1', '1e9d8b', '93eade', 'bab0ac'];
    protected firstSlideRenderer: HSStrategicFirstSlideRenderer = new HSStrategicFirstSlideRenderer();

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        let data = await this.getMappedChartData(SPMarketOverviewModel, 'SP at Hospital/IDN level');
        const averages = data.filter(x => x.Answer === null);
        data = data.filter(x => x.Answer !== null);

        if (!data || !data.length) {
            return;
        }

        data = this.getSortedByOrder(data);

        const answers = data.map(ch => ch.Answer).distinct().filter(x => x);
        answers.reverse();

        const countries = data.map(ch => ch.Country).distinct().filter(x => x);
        const dataChartBarStat: Array<{ name: string, labels: string[], values: Array<number | null> }> = answers.map(a => ({
            name: a,
            labels: countries,
            values: countries.map(x => null)
        }));

        data.forEach(ch => {
            const currentChartData = dataChartBarStat.firstOrNull(d => d.name === ch.Answer);
            if (currentChartData) {
                const labelIndex = currentChartData.labels.indexOf(ch.Country);
                currentChartData.values[labelIndex] = ch.Sum;
            }
        });

        const chartOptions: pptxgen.IChartOpts = {
            ...BaseChartOptions,
            x: 0.75, y: 1, w: 8.5, h: 4.2,
            barDir: 'col',
            barGrouping: 'stacked',
            layout: { h: 0.8 },
            chartColors: this.spAnswerColors,
            catAxisLabelFontSize: 8,
            catAxisOrientation: 'minMax',
            catAxisLineShow: false,
            valAxisOrientation: 'maxMin',
            showValue: true,
            dataLabelFormatCode: '#%',
            valAxisHidden: true,
            showLegend: true,
            legendPos: 'b',
        } as any;
        chartSlide.addChart(this.presentation.ChartType.bar, dataChartBarStat, chartOptions);

        const barWidth = Number(chartOptions.w) / countries.length;
        countries.forEach((country, index) => {
            const average = averages.firstOrNull(a => a.Country === country);
            PptxRenderHelper.addBarChartAvarageColorized(chartSlide, average, chartOptions, index, barWidth);
        });
    }
}

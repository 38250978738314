import { IDataMapping } from '../../IDataMapping';

export class PurchaseFunnelLineChartTitleModel implements IDataMapping<PurchaseFunnelLineChartTitleModel> {
    public Title: string = '';
    public TitlePPT: string = '';
    public Market: string = '';
    public Survey: string = '';
    public Category: string = '';
    public SubTitle: string = '';
    public Indicator: string = '';

    public getMappings?() {
        return {
            Title: 'PF Line Title',
            TitlePPT: 'Funnel Line Title',
            Market: 'Market',
            Survey: 'Survey',
            Category: 'Category',
            SubTitle: 'PF Line Title (copy)',
            Indicator: 'Answer_Text (copy)'
        };
    }
}

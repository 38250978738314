import { IDataMapping } from '../../../../IDataMapping';

export class UnaidedRecallCommunicationsChartSampleModel implements IDataMapping<UnaidedRecallCommunicationsChartSampleModel> {
    public Segment: string = '';
    public SamplePositive: number | null = 0;
    public SampleNegative: number | null = 0;

    public getMappings?() {
        return {
            Segment: 'Segment',
            SamplePositive: 'AGG(Sample Size Color <50 BLACK)',
            SampleNegative: 'AGG(Sample Size Color <50 RED)',
        };
    }
}

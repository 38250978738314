import { IDataMapping } from '../../IDataMapping';

export class SustainabilityLineChartDataModel implements IDataMapping<SustainabilityLineChartDataModel> {
    public Label: string = '';
    public Name: string = '';
    public Value: number = Number(0);
    public Color: string = '';
    public getMappings?() {
        return {
            Label: 'Period',
            Name: 'Brand Color_SST',
            Value: 'SUM(%)',
            Color: 'Brand_color_exports',
        };
    }
}

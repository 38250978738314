import { IDataMapping } from "../../../../IDataMapping";

export class PHBSMarketStatementOverTimeChartTitleModel implements IDataMapping<PHBSMarketStatementOverTimeChartTitleModel> {
    public Title:           string = '';
    public SubTitle:        string = '';
    public BrandFilter:     string = '';
    public AudienceFilter:  string = '';
    public DataTypeFilter:  string = '';

    public getMappings?() {
        return {
            Title:          'Brand Strength by Statement Over Time Title',
            SubTitle:       'Brand Strength Analysis Subtitle',
            BrandFilter:    'ATTR(Brand)',
            AudienceFilter: 'ATTR(Audience)',
            DataTypeFilter: 'Data Type Market 9',
        };
    }
}

import { IDataMapping } from "../../../../IDataMapping";

export class NPSTrendsLineChartDataModel implements IDataMapping<NPSTrendsLineChartDataModel> {
    public Rank: string = '';
    public Name: string = '';
    public Label: string = '';
    public Value: number = Number(0);
    public getMappings?() {
        return {
            Rank: 'rank Int',
            Name: 'Brand',
            Label: 'Year',
            Value: 'SUM(Value)',
        };
    }
}

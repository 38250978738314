import { IDataMapping } from '../../../IDataMapping';

export class SRCBSTotalChartTitleModel implements IDataMapping<SRCBSTotalChartTitleModel> {
    public Title: string = '';
    public SubTitle: string = '';
    public Audience: string = '';
    public Period: string = '';
    public Filter: string = '';

    public getMappings?() {
        return {
            Title:      'Brand Strength Total Ttitle',
            SubTitle:   'Brand Strength Analysis Sub-Ttitle',
            Audience: 'ATTR(Audience_tbl)',
            Period: 'ATTR(Current Q vs Last FY_BSS)',
            Filter: 'Brand Stregth VS'
        };
    }
}

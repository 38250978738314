import { IDataMapping } from "../../../IDataMapping";

export class PHBSFactorOverTimeChartDataModel implements IDataMapping<PHBSFactorOverTimeChartDataModel> {
    public Label:       string = '';
    public Name:        string = '';
    public Value:       number = Number(0);
    public Color:       string = '';
    public PeriodIndex: number = Number(0);
    public BrandOrder:  number = Number(0);

    public getMappings?() {
        return {
            Label:          'QUARTER(Date)',
            Name:           'BS_statement',
            Value:          'AVG(Value)',
            Color:          'ATTR(statement_color_code)',
            PeriodIndex:    'MIN(index_period)',
            BrandOrder:     'ATTR(Rank Factor/Statement)'
        };
    }
}

import pptxgen from 'pptxgenjs';
import { TopDriversDataModel, DriverModel } from '../../../';
import { PptxRenderHelper } from '../../../helpers/PptxRenderHelper';
import { LegendModel } from '../../../models/LegendModel';
import { BasePptxService } from '../../BasePptxService';
import { HSFirstSlideRenderer } from '../../Renderers/HSFirstSlideRenderer';

export class TopDriversPptxService extends BasePptxService {
    protected firstSlideRenderer = new HSFirstSlideRenderer();
    protected chartQuestion: string = 'Please select which vendor performs better in regards to these attributes.';
    private driverColorsByValue: { [key: string]: string } = {
        'Philips leads competitors by more than 5%': this.colors.positive,
        'Philips is within -5%/+5% to competitors': this.colors.neutral,
        'Competitors lead by more than 5%': this.colors.negative
    };

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const data = await this.getAllChartsData();

        if (!data) {
            return;
        }

        const driversValueToFormattedValue: Map<string, string> = new Map();
        const drivers = this.processData(data, driversValueToFormattedValue);

        this.addTable(chartSlide, [
            ...this.getDriverRows(drivers, driversValueToFormattedValue, 'IGTS'),
            [{ text: '', options: { colspan: 2 } }],
            ...this.getDriverRows(drivers, driversValueToFormattedValue, 'IGTD'),
            [{ text: '', options: { colspan: 2 } }],
            ...this.getDriverRows(drivers, driversValueToFormattedValue, 'EDI')
        ]);

        this.addTable(chartSlide, [
            ...this.getDriverRows(drivers, driversValueToFormattedValue, 'DI'),
            [{ text: '', options: { colspan: 2 } }],
            ...this.getDriverRows(drivers, driversValueToFormattedValue, 'US'),
        ],
            { x: '37.5%' });

        this.addTable(chartSlide, [
            ...this.getDriverRows(drivers, driversValueToFormattedValue, 'MA'),
            [{ text: '', options: { colspan: 2 } }],
            ...this.getDriverRows(drivers, driversValueToFormattedValue, 'TC'),
        ],
            { x: '68%' });

        this.addLegend(chartSlide);
    }

    protected async getAllChartsData(): Promise<any> {
        const sheetNames = [
            'Top2 Drivers IGTd',
            'Top2 Drivers DI',
            'Top2 Drivers IGTs',
            'Top2 Drivers EDI',
            'Top2 Drivers US',
            'Top2 Drivers MA',
            'Top2 Drivers TC'
        ];

        const sheetsData = [];

        for (const sheetName of sheetNames) {
            const data = await super.getChartData(sheetName);
            sheetsData.push(...data);
        }

        return sheetsData;
    }

    private processData(data: any[], driversValueToFormattedValue: Map<string, string>) {
        const drivers: TopDriversDataModel = new TopDriversDataModel();
        for (const d of data) {
            const driverName = d[0].value;
            if (!driversValueToFormattedValue.get(driverName)) {
                driversValueToFormattedValue.set(driverName, d[0].formattedValue);
            }

            const driver = new DriverModel(d[1].formattedValue, (+d[3].value).toFixed(0), this.driverColorsByValue[d[2].formattedValue]);

            if (drivers.hasOwnProperty(driverName)) {
                drivers[driverName as keyof TopDriversDataModel].push(driver);
            }
        }
        return drivers;
    }

    private getDriverRows(drivers: TopDriversDataModel, driversValueToFormattedValue: Map<string, string>, currentDriverName: keyof TopDriversDataModel) {
        const rows: pptxgen.TableRow[] = [[{ text: driversValueToFormattedValue.get(currentDriverName), options: { colspan: 2, bold: true, fontSize: 12, valign: 'middle' } }]];
        const subdrivers = drivers[currentDriverName].sort((a, b) => a.text.localeCompare(b.text));
        for (const driver of subdrivers) {
            rows.push([
                { text: driver.text, options: { fontSize: 10, valign: 'middle' } },
                { text: driver.value, options: { fill: { color: driver.color }, fontSize: 10, align: 'center', valign: 'middle' } }
            ]);
        }

        return rows;
    }

    private addLegend(chartSlide: pptxgen.Slide) {
        let y = 4.5;

        const legendDataArray: LegendModel[] = Object.entries(this.driverColorsByValue).map(([label, color]) => ({ label, color }));
        legendDataArray.forEach((legendData) => {
            PptxRenderHelper.renderSquareLegend(chartSlide, legendData, { x: 7, y });
            y += 0.2;
        });
    }

    private addTable(slide: pptxgen.Slide, rows: pptxgen.TableRow[], tableProps?: pptxgen.TableProps) {

        tableProps = Object.assign({
            x: '7%',
            y: 1,
            w: '25%',
            colW: [2, 0.5],
            rowH: 0.35,
            border: { type: 'none' },
        }, tableProps);

        slide.addTable(rows, tableProps);
    }
}

import { IDataMapping } from '../../IDataMapping';

export class FamiliarityDataModel implements IDataMapping<FamiliarityDataModel> {
    public KPIValue: number = 0;
    public getMappings?() {
        return {
            KPIValue: 'AVG(indicator_value)',
        };
    }
}

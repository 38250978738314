import { IDataMapping } from '../../IDataMapping';

export class IndicatorLineChartDataModel implements IDataMapping<IndicatorLineChartDataModel> {
    public Label: string = '';
    public Name: string = '';
    public Value: number = Number(0);
    public Color: string = '';
    public getMappings?() {
        return {
            Label: 'QUARTER(Date (copy))',
            Name: 'indicator_name',
            Value: 'AVG(indicator_value)',
            Color: 'brand_color',
        };
    }
}

import React from 'react';
import { ISVGProps } from './ISVGProps';

export const ListSvg = (props: ISVGProps) => {
  const { width, height } = props;
  return (
    <svg
      stroke={'currentColor'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 33 23'
    >
      <defs>
        <style>
          {'.a{stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}'}
        </style>
      </defs>
      <g transform='translate(1 1)'>
        <g transform='translate(-3 -6)'>
          <line className='a' x2='22' transform='translate(12 6)' />
          <line className='a' x2='22' transform='translate(12 17)' />
          <line className='a' x2='22' transform='translate(12 27)' />
          <line className='a' x2='0.018' transform='translate(3 6)' />
          <line className='a' x2='0.018' transform='translate(3 16.5)' />
          <line className='a' x2='0.018' transform='translate(3 27)' />
        </g>
      </g>
    </svg>
  );
};

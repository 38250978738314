import { IDataMapping } from '../../../IDataMapping';

export class AppendixUSSubTitleModel implements IDataMapping<AppendixUSSubTitleModel> {
    public Type: string = '';
    public SubTitle: string = '';
    public getMappings?() {
        return {
            Type: "Type",
            SubTitle: "Comments",
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class NineGLineChartValueTitleModel implements IDataMapping<NineGLineChartValueTitleModel> {
    public Title: string = '';
    public IndexTitle: string = '';
    public getMappings?() {
        return {
            Title: '9G Titles (Market Share Value)',
            IndexTitle: '9G Titles (Index)',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class FirstChoiceLineChartDataModel implements IDataMapping<FirstChoiceLineChartDataModel> {
    public Name: string = '';
    public Label: string = '';
    public Color: string = '';
    public Value: number = Number(0);
    public Country: string = '';
    public getMappings?() {
        return {
            Name: "Category",
            Label: "Date",
            Color: 'AGG(GlobalResultsColors)',
            Value: "AVG(Value)",
            Country: "ATTR(Market)",
        };
    }
}

import pptxgen from 'pptxgenjs';

import { BasePptxService } from '../../../../BasePptxService';
import { PptxRenderHelper } from '../../../../../helpers/PptxRenderHelper';
import { PHBSFirstSlideRenderer } from '../../../../Renderers/PHBSFirstSlideRenderer';

import { PHBSEmployeeDeepDiveTableTitleModel } from '../../../../../models/PersonalHealth/BrandStrength/EmployeeDeepDive/FactorsByStatementsTable/PHBSEmployeeDeepDiveTableTitleModel';
import { PHBSEmployeeDeepDiveTableDataModel } from '../../../../../models/PersonalHealth/BrandStrength/EmployeeDeepDive/FactorsByStatementsTable/PHBSEmployeeDeepDiveTableDataModel';
import { PHBSEmployeeDeepDiveTableLegendModel } from '../../../../../models/PersonalHealth/BrandStrength/EmployeeDeepDive/FactorsByStatementsTable/PHBSEmployeeDeepDiveTableLegendModel';

export class PHBSEmployeeDeepDiveTableService extends BasePptxService {
    protected firstSlideRenderer: PHBSFirstSlideRenderer = new PHBSFirstSlideRenderer();
    protected chartQuestion: string = '';
    protected titleSheet: string;
    protected dataFactorSheet: string;
    protected dataStatementSheet: string;
    protected legendDataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, dataFactorSheet: string, dataStatementSheet: string, legendDataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataFactorSheet = dataFactorSheet;
        this.dataStatementSheet = dataStatementSheet;
        this.legendDataSheet = legendDataSheet;
    }

    // Override of a super class method
    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const titleData = await this.getMappedChartData(PHBSEmployeeDeepDiveTableTitleModel, this.titleSheet);

        let note: string = "";
        titleData.forEach((obj: any) => {
            this.chartTitle = `${obj.Title}`;
            note = obj.Note;
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.55, bold: true });
        slide.addText([{ text: note, options: { color: '#861106', fontSize: 12 } }], { x: 0.6, y: 0.9, italic: true });
    }

    // Override of a super class method
    protected async addChartSlide(chartSlide: pptxgen.Slide) {

        const factorMappedData = await this.getMappedChartData(PHBSEmployeeDeepDiveTableDataModel, this.dataFactorSheet);
        const statementMappedData = await this.getMappedChartData(PHBSEmployeeDeepDiveTableDataModel, this.dataStatementSheet);
        const mappedlegendDataSheet = await this.getMappedChartData(PHBSEmployeeDeepDiveTableLegendModel, this.legendDataSheet);

        const titleData = await this.getMappedChartData(PHBSEmployeeDeepDiveTableTitleModel, this.titleSheet);
        let dataFromFactor: string = "";
        titleData.forEach((obj: any) => {
            dataFromFactor = obj.DataType;
        });

        const mappedData: any = dataFromFactor === "True" ? factorMappedData : statementMappedData;

        if (mappedData.length > 0) {

            // sorting and filtering table data
            const markets = this.getAllValuesByProperty(mappedData, "Market");
            const groupData = this.groupDataByRows(mappedData, dataFromFactor);

            const tables = this.splitRowsByTables(groupData, 4);

            // execution
            tables.forEach((table, index) => {
                const currentSlide = this.getCurrentSlide(chartSlide, index);

                this.addTable(currentSlide, table, markets, dataFromFactor);
                this.addLegend(currentSlide, mappedlegendDataSheet);
            });
        }
    }

    private getCurrentSlide(chartSlide: pptxgen.Slide, index: number) {
        let currentSlide = chartSlide;

        if (index !== 0) {
            currentSlide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });
            this.setChartSlideLayout(currentSlide);
        }

        return currentSlide;
    }

    private getAllValuesByProperty(data: PHBSEmployeeDeepDiveTableDataModel[], property: string) {
        const values: any[] = [];

        data.forEach((obj: PHBSEmployeeDeepDiveTableDataModel) => {
            if (!values.includes(obj[property as keyof PHBSEmployeeDeepDiveTableDataModel])) {
                values.push(obj[property as keyof PHBSEmployeeDeepDiveTableDataModel]);
            }
        });

        return values;
    }

    private groupByProperty(array: PHBSEmployeeDeepDiveTableDataModel[], property: string) {
        const groupData: PHBSEmployeeDeepDiveTableDataModel[][] = [];

        // Grouping the data in rows
        for (let obj of array) {
            let flagMakeNewGroup = true;
            // Checks If there is group/array for this object
            for (let arr of groupData) {
                for (let element of arr) {
                    if (element[property as keyof PHBSEmployeeDeepDiveTableDataModel] === obj[property as keyof PHBSEmployeeDeepDiveTableDataModel]) {
                        arr.push(obj);
                        flagMakeNewGroup = false;
                    }
                    break;
                }
            }
            // Creates new group/array
            if (flagMakeNewGroup) {
                groupData.push([obj]);
            }
        }

        return groupData;
    }

    private getHeaderTableRow(data: string[], dataFromFactor: string) {
        const header = dataFromFactor === "True" ? ["", "MAT", ...data] : ["", "", "MAT", ...data];

        const tableRow: pptxgen.TableRow[] = [];
        const tableCells: pptxgen.TableCell[] = [];

        // header row
        header.forEach((headerName: string) => {
            tableCells.push(
                {
                    text: headerName,
                    options: {
                        bold: true,
                        fill: { color: this.colors.default },
                        color: this.colors.white,
                        border:
                            [
                                { type: 'solid', pt: 0.5, color: this.colors.border },
                                { type: 'solid', pt: 0.5, color: this.colors.white },
                                { type: 'solid', pt: 0.5, color: this.colors.border },
                                { type: 'solid', pt: 0.5, color: this.colors.white },
                            ]
                    }
                }
            );
        });
        tableRow.push(tableCells);

        return tableRow;
    }

    private groupDataByRows(mappedData: PHBSEmployeeDeepDiveTableDataModel[], dataFromFactor: string) {

        // 1st step - Grouping the data by Factor or Statement property

        // adding market data
        const factorOrStatementGroupsData: PHBSEmployeeDeepDiveTableDataModel[][] = [];
        for (let obj of mappedData) {
            let flagMakeNewGroup = true;
            // Checks If there is group/array for this object
            for (let arr of factorOrStatementGroupsData) {
                for (let element of arr) {
                    if ((dataFromFactor === "True" && element.Factor === obj.Factor) || (dataFromFactor === "False" && element.Statement === obj.Statement)) {
                        arr.push(obj);
                        flagMakeNewGroup = false;
                    }
                    break;
                }
            }
            // Creates new group/array
            if (flagMakeNewGroup) {
                factorOrStatementGroupsData.push([obj]);
            }
        }

        // 2nd step - Grouping the data in rows
        const groupData: PHBSEmployeeDeepDiveTableDataModel[][] = [];
        for (let factorOrStatementGroup of factorOrStatementGroupsData) {
            factorOrStatementGroup.sort((a, b) => b.TimeRank - a.TimeRank);
            const rows: PHBSEmployeeDeepDiveTableDataModel[][] = this.groupByProperty(factorOrStatementGroup, "TimeRank");

            rows.forEach((row) => {
                groupData.push(row);
            });
        }

        return groupData;
    }

    private splitRowsByTables(groupData: PHBSEmployeeDeepDiveTableDataModel[][], rowsPerTable: number) {
        const tables: PHBSEmployeeDeepDiveTableDataModel[][][] = [];

        // full tables
        const numberOfFullTables = Math.trunc(groupData.length / rowsPerTable);
        for (let i = 0; i < numberOfFullTables; ++i) {
            tables.push(groupData.slice((i * rowsPerTable), (i * rowsPerTable) + rowsPerTable));
        }

        // last smaller table
        const lastTable = groupData.length % rowsPerTable;
        if (lastTable) {
            tables.push(groupData.slice((groupData.length - lastTable), groupData.length));
        }

        return tables;
    }

    private countRowspan(groupData: PHBSEmployeeDeepDiveTableDataModel[][], currentIndex: number, objectKey: string) {
        let rowspan = 1;

        let currentGroup;
        groupData[currentIndex].forEach(obj => {
            currentGroup = obj[objectKey as keyof PHBSEmployeeDeepDiveTableDataModel];
        })

        for (let row = (currentIndex + 1); row < groupData.length; ++row) {
            let nextGroup;
            groupData[row].forEach(obj => {
                nextGroup = obj[objectKey as keyof PHBSEmployeeDeepDiveTableDataModel];
            })

            if (currentGroup === nextGroup) {
                ++rowspan;
            }
        }

        return rowspan;
    }

    private getBodyTableRows(groupData: PHBSEmployeeDeepDiveTableDataModel[][], dataFromFactor: string) {
        let tableRows: pptxgen.TableRow[] = [];
        let usedFactor: string;
        let usedStatement: string;

        groupData.forEach((row, index) => {
            let currentFactor: string = "";
            let currentStatement: string = "";
            let currentPeriod: string = "";
            const values: pptxgen.TableCell[] = [];

            // value rows
            row.forEach(obj => {
                if (obj.Value) {
                    let backGroundColor = obj.Color ? obj.Color : this.colors.white;

                    values.push(
                        {
                            text: obj.Value.toFixed(1),
                            options: { margin: [0, 3, 0, 0], color: this.colors.black, fill: { color: backGroundColor }, align: 'center' }
                        }
                    );
                } else {
                    values.push(
                        {
                            text: "",
                            options: { margin: [0, 3, 0, 0], align: 'center' }
                        }
                    );
                }

                currentFactor = obj.Factor;
                currentStatement = obj.Statement;
                currentPeriod = obj.Date;
            });

            // Adding factor (and Statement) and period cells to the rows
            let tableCells: pptxgen.TableCell[] = [];

            if (tableRows.length === 0) {

                // first row only
                const rowspanFactor = this.countRowspan(groupData, index, "Factor");
                const rowspanStatement = this.countRowspan(groupData, index, "Statement");

                tableCells.push(
                    {
                        text: currentFactor,
                        options: { color: this.colors.black, fill: { color: this.colors.white }, align: 'center', rowspan: rowspanFactor }
                    }
                );

                if (dataFromFactor === "False") {
                    tableCells.push(
                        {
                            text: currentStatement,
                            options: { color: this.colors.black, fill: { color: this.colors.white }, align: 'center', rowspan: rowspanStatement }
                        }
                    )
                }

                tableCells.push(
                    {
                        text: currentPeriod,
                        options: { color: this.colors.black, fill: { color: this.colors.white }, align: 'center' }
                    },
                    ...values
                );

            } else {
                if (currentFactor !== usedFactor) {
                    const rowspan = this.countRowspan(groupData, index, "Factor");
                    tableCells.push(
                        {
                            text: currentFactor,
                            options: { color: this.colors.black, fill: { color: this.colors.white }, align: 'center', rowspan }
                        }
                    );
                }

                if (dataFromFactor === "False" && currentStatement !== usedStatement) {
                    const rowspan = this.countRowspan(groupData, index, "Statement");
                    tableCells.push(
                        {
                            text: currentStatement,
                            options: { color: this.colors.black, fill: { color: this.colors.white }, align: 'center', rowspan }
                        }
                    )
                }

                tableCells.push(
                    {
                        text: currentPeriod,
                        options: { color: this.colors.black, fill: { color: this.colors.white }, align: 'center' }
                    },
                    ...values
                );
            }

            tableRows.push(tableCells);
            usedFactor = currentFactor;
            usedStatement = currentStatement;
        });

        return tableRows;
    }

    private addTable(chartSlide: pptxgen.Slide, table: PHBSEmployeeDeepDiveTableDataModel[][], markets: string[], dataFromFactor: string) {
        const headerData = this.getHeaderTableRow(markets, dataFromFactor);
        const bodyData = this.getBodyTableRows(table, dataFromFactor);

        const statementColW = dataFromFactor === "False" ? 1.45 : 0;
        const equalColW = (9.2 - statementColW) / (markets.length + 2);
        const allColW = dataFromFactor === "False" ? [equalColW, statementColW, ...Array(markets.length + 1).fill(equalColW)] : [...Array(markets.length + 2).fill(equalColW)];

        const options: pptxgen.TableProps = {
            x: 0.4,
            y: 1.1,
            w: 9.2,
            align: 'center',
            valign: 'middle',
            border: { type: 'solid', color: this.colors.neutral, pt: 0.2 },
            fontSize: 7,
            rowH: 0.35,
            colW: allColW,
        };

        chartSlide.addTable([...headerData, ...bodyData], options);
    }

    private addLegend(chartSlide: pptxgen.Slide, data: PHBSEmployeeDeepDiveTableLegendModel[]) {
        data.sort((a, b) => a.Order - b.Order);

        const legend: any = [];
        data.forEach((obj: PHBSEmployeeDeepDiveTableLegendModel) => {

            let addToLegendFlag = true;
            for (let legendObj of legend) {
                if (obj.LegendText === legendObj.legendText || legendObj.legendText === 'Previous MAT') {
                    addToLegendFlag = false;
                    break;
                }
            }

            if (addToLegendFlag) {
                if (obj.LegendText === ' ') {
                    legend.push({
                        legendText: 'Previous MAT',
                        legendColor: '#b6b6b6',
                    });
                } else {
                    legend.push({
                        legendText: obj.LegendText,
                        legendColor: obj.Color,
                    });
                }
            }
            addToLegendFlag = true;
        });

        legend.forEach((obj: any, index: number) => {
            PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: obj.legendText, color: obj.legendColor }, { x: (0.5 + index * 1.2), y: 5.1 });
        })
    }
}

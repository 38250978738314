import { IDataMapping } from '../../IDataMapping';

export class CompetitorsGloballyDataModel implements IDataMapping<CompetitorsGloballyDataModel> {
    public Category: string = '';
    public Market: string = '';
    public BrandAbbreviation: string = '';
    public SubMarket: string = '';
    public Table: number = Number(0);
    public Value: number = Number(0);
    public MarketAndSubTitle: string = '';
    public Color: string = '';
    public getMappings?() {
        return {
            Category: "Category",
            Market: 'Market',
            BrandAbbreviation: 'Brand Abbreviation (copy)',
            SubMarket: 'AGG(Philips_BC)',
            Table: "Table",
            Value: "AVG(Value)",
            MarketAndSubTitle: "AGG(concMarketAndPhOrComp)",
            Color: "AGG(CompGloPhiColors)",
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class PurchaseFunnelTableMonthlyDataModel implements IDataMapping<PurchaseFunnelTableMonthlyDataModel> {
    public Name: string = '';
    public Label: string = '';
    public Date: string = '';
    public Color: string = '';
    public FirstTableValue: number = Number(0);
    public SecondTableValue: number = Number(0);
    public FamiliarityTableValue: number = Number(0);
    public Rank: number = Number(0);
    public getMappings?() {
        return {
            Name: 'Brand',
            Label: 'Answer_Text',
            Date: 'Month',
            Color: 'AGG(BrandColors)',
            FirstTableValue: 'SUM(Value)',
            SecondTableValue: 'MAX(Value)',
            FamiliarityTableValue: 'AVG(Value)',
            Rank: 'AGG(Rank_Date_Color_Month)',
        };
    }
}

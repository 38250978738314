export enum RoleActions {
  None = 0,

  View = 1,

  Add = 2,

  Edit = 4,

  Delete = 8,
}

const RoleActionsDisplay: { [index: number]: string } = {};
RoleActionsDisplay[RoleActions.None] = 'None';
RoleActionsDisplay[RoleActions.View] = 'View';
RoleActionsDisplay[RoleActions.Add] = 'Add';
RoleActionsDisplay[RoleActions.Edit] = 'Edit';
RoleActionsDisplay[RoleActions.Delete] = 'Delete';

export default RoleActionsDisplay;

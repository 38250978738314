import { IDataMapping } from '../../IDataMapping';

export class CompetitorsGloballyFooterDataModel implements IDataMapping<CompetitorsGloballyFooterDataModel> {
    public Survey: string = '';
    public TextFooter: string = '';
    public getMappings?() {
        return {
            Survey: "Survey",
            TextFooter: 'Text in legend',
        };
    }
}

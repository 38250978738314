import pptxgen from 'pptxgenjs';
import { PreferenceScoreDataModel } from '../../../models/PersonalHealth/PreferenceScore/PreferenceScoreDataModel';
import { PreferenceScoreTitleModel } from '../../../models/PersonalHealth/PreferenceScore/PreferenceScoreTitleModel';
import { PreferenceScoreGlobalResultsDataModel } from '../../../models/PersonalHealth/PreferenceScore/PreferenceScoreGlobalResultsDataModel';

import { PHFirstSlideRenderer } from '../../Renderers/PHFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

import { ChartsBase64 } from '../../../../shared/enums/ChartsBase64';

export class PreferenceScoreService extends BasePptxService {
    protected chartQuestion: string = '';
    protected firstSlideRenderer: PHFirstSlideRenderer = new PHFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;
    protected globalDataSheet: string | null;

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string, globalDataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
        this.globalDataSheet = globalDataSheet;
    }

    protected async setChartSlideLayout(slide: pptxgen.Slide) {
      // Override title's options and add filter's name in it.
      const titleData = await this.getMappedChartData(PreferenceScoreTitleModel, this.titleSheet);
      let subTitle: string = "";
      titleData.map( obj => {
        this.chartTitle = `${obj.Title} - ${obj.Level}`;
        return subTitle = obj.SubTitle;
      });
      slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.35, bold: true });
      slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.58, italic: true });
    }
  
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
      let circle: string = "";
      let circleValue: string = "";
      let KPIColor: string = "";
      let KPIValue: string = "";
      const mappedData = await this.getMappedChartData(PreferenceScoreDataModel, this.dataSheet);

      mappedData.forEach((obj) => {
        if (obj.KPIValue) {
          KPIValue = parseFloat(obj.KPIValue).toFixed(1);
        }
        if (obj.Color) {
          KPIColor = obj.Color;
        }
        if (obj.ValueNegative) {
          circle = ChartsBase64.NegativeCircle;
          circleValue = obj.ValueNegative;
        } else if (obj.ValuePositive) {
          circle = ChartsBase64.PositiveCircle;
          circleValue = obj.ValuePositive;
        } else if (obj.ValueZero) {
          circle = ChartsBase64.ZeroCircle;
          circleValue = obj.ValueZero;
        } else return false;
      })

      chartSlide.addImage({x: 3.3, y: 1.9, w: 1.5, h: 1.5, data: circle });
      chartSlide.addText([{ text: circleValue, options: { color: KPIColor, fontSize: 16 } }], { x: 3.8, y: 3.6 });
      chartSlide.addText([{ text: `${KPIValue}%`, options: { fontSize: 28 } }], { x: 5, y: 2.7 });

      if (this.globalDataSheet) {
        await this.addGlobalResultsData(chartSlide)
      };
    }

    // Used for the Country Page.
    protected async addGlobalResultsData(chartSlide: pptxgen.Slide) {
        let circleValue: string = "";
        let KPIColor: string = "";
        let KPIValue: string = "";
        let globalScoreText: string = "";

        if (!!this.globalDataSheet) {
            const data = await this.getMappedChartData(PreferenceScoreGlobalResultsDataModel, this.globalDataSheet);
            data.forEach((obj) => {
                if (obj.GlobalScore) {
                    globalScoreText = obj.GlobalScore;
                }
                if (obj.KPIValue) {
                    KPIValue = parseFloat(obj.KPIValue).toFixed(1);
                }
                if (obj.KPIValue) {
                  KPIValue = parseFloat(obj.KPIValue).toFixed(1);
                }
                if (obj.Color) {
                  KPIColor = obj.Color;
                }
                if (obj.ValueNegative) {
                  circleValue = obj.ValueNegative;
                } else if (obj.ValuePositive) {
                  circleValue = obj.ValuePositive;
                } else if (obj.ValueZero) {
                  circleValue = obj.ValueZero;
                } else return false;
              })

              chartSlide.addText([{ text: `${globalScoreText} ${KPIValue}%`, options: { fontSize: 20 } }], { x: 2.5, y: 4.7 });
              chartSlide.addText([{ text: circleValue, options: { color: KPIColor, fontSize: 16 } }], { x: 5.2, y: 4.7 });
        } else {
            return false;
        }
    }
}

import React from 'react';
import { ICreateCustomViewDialogProps } from './ICreateCustomViewDialogProps';
import styles from './CreateCustomViewDialog.module.scss';

export const CreateCustomViewDialog = (
  props: ICreateCustomViewDialogProps
): JSX.Element => {
  const {
    view,
    customViewModel,
    handleCustomViewFieldChange,
    handleSaveCustomView,
    handleDialogClose,
  } = props;

  const isNameEmpty = /^\s*$/.test(customViewModel.name);

  const loadCustomView = async (viewName: string) => {
    const workbook = view.getWorkbook();
    if (view && viewName !== 'Original') {
      handleDialogClose();
      await workbook.showCustomViewAsync(viewName);
    } else if (viewName === 'Original') {
      handleDialogClose();
      await workbook.showCustomViewAsync();
    }
  };

  return (
    <React.Fragment>
      <div className={styles.custom_views_content}>
        <div className={styles.save_custom_view_container}>
          <h3 className={styles.custom_view_section_header}>
            Save Custom View
          </h3>
          <div className={styles.name_input_container}>
            <div className={styles.name_input_wrapper}>
              <label className={styles.input_label} htmlFor='custom_view_name'>
                Name this view
              </label>
              <div className={styles.input_wrapper}>
                <input
                  type='text'
                  name='custom_view_name'
                  value={customViewModel.name}
                  className={styles.name_input}
                  onChange={(e) =>
                    handleCustomViewFieldChange('name', e.target.value)
                  }
                />
                <div
                  className={styles.clear_button}
                  onClick={(e) => handleCustomViewFieldChange('name', '')}
                ></div>
              </div>
            </div>
          </div>
          <div className={styles.checkbow_row}>
            <label
              htmlFor='DefaultCheckbox'
              className={
                customViewModel.isMyDefault ? styles.checked : undefined
              }
            >
              <input
                type='checkbox'
                name='DefaultCheckbox'
                checked={customViewModel.isMyDefault}
                onChange={(e) =>
                  handleCustomViewFieldChange('isMyDefault', e.target.checked)
                }
              />
              <div className={styles.label_wrapper}>Make it my default</div>
            </label>
            <div
              title={'Anyone with access to this view can see this custom view'}
            >
              <label
                htmlFor='isPublic'
                className={
                  customViewModel.isPublic ? styles.checked : undefined
                }
              >
                <input
                  type='checkbox'
                  name='isPublic'
                  checked={customViewModel.isPublic}
                  onChange={(e) =>
                    handleCustomViewFieldChange('isPublic', e.target.checked)
                  }
                />
                <div className={styles.label_wrapper}>
                  Make visible to others
                </div>
              </label>
            </div>
          </div>
          <div className={styles.button_row}>
            <button
              disabled={isNameEmpty}
              className={isNameEmpty ? styles.disabled : undefined}
              onClick={handleSaveCustomView}
            >
              Save
            </button>
          </div>
        </div>

        <div className={styles.views_container}>
          <h3 className={styles.views_header}>My views</h3>
          {customViewModel.myViews.length === 0 ? (
            <div className={styles.empty_container}>Nothing saved yet</div>
          ) : (
            customViewModel.myViews.map((v) => (
              <div
                className={styles.view_row}
                onClick={(e) => loadCustomView(v.name)}
                key={v.name}
              >
                <div
                  className={
                    v.name === customViewModel.name ||
                    (v.name === 'Original' && customViewModel.name === '')
                      ? `${styles.active_row_wrapper} ${styles.row_wrapper}`
                      : styles.row_wrapper
                  }
                >
                  <span className={styles.row_span}>
                    {v.isDefault ? (
                      <>
                        <i
                          className={styles.default_icon}
                          title={'This is your current default'}
                        ></i>
                        <span className={styles.view_name} title={v.name}>
                          {v.name} (default)
                        </span>
                      </>
                    ) : (
                      <>
                        <i className={styles.icon_placeholder}></i>
                        <span className={styles.view_name} title={v.name}>
                          {v.name}
                        </span>
                      </>
                    )}
                    <div className={styles.row_action_buttons}>
                      {v.isPublic ? (
                        <i
                          className={styles.eye_icon}
                          title={'This view is public'}
                        ></i>
                      ) : undefined}
                    </div>
                  </span>
                </div>
              </div>
            ))
          )}
        </div>

        <div className={styles.views_container}>
          <h3 className={styles.views_header}>Other views</h3>
          {customViewModel.otherViews.map((v) => (
            <div
              className={styles.view_row}
              onClick={(e) => loadCustomView(v.name)}
              key={v.name}
            >
              <div
                className={
                  v.name === customViewModel.name ||
                  (v.name === 'Original' && customViewModel.name === '')
                    ? `${styles.active_row_wrapper} ${styles.row_wrapper}`
                    : styles.row_wrapper
                }
              >
                <span className={styles.row_span}>
                  {v.isDefault ? (
                    <>
                      <i
                        className={styles.default_icon}
                        title={'This is your current default'}
                      ></i>
                      <span className={styles.view_name} title={v.name}>
                        {v.name} (default)
                      </span>
                    </>
                  ) : (
                    <>
                      <i className={styles.icon_placeholder}></i>
                      <span className={styles.view_name} title={v.name}>
                        {v.name}
                      </span>
                    </>
                  )}
                  <span className={styles.author}>
                    <span className={styles.author_label}>{v.author}</span>
                  </span>
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

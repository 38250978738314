import { IDataMapping } from '../../IDataMapping';

export class ProductNPSResultsTableMonthlyDataModel implements IDataMapping<ProductNPSResultsTableMonthlyDataModel> {
    public Name: string = '';
    public Color: string = '';
    public AvgValue: number = Number(0);
    public DiffValue: number = Number(0);
    public Rank: number = Number(0);
    public Base: number = Number(0);
    public getMappings?() {
        return {
            Name: 'Brand',
            Color: 'Diff NPS Rank Month',
            AvgValue: 'AVG(Value)',
            DiffValue: 'SUM(Diff NPS (month))',
            Rank: 'MAX(pref value check rank (Month) (NPS))',
            Base: 'AVG(Count)',
        };
    }
}

import { IDataMapping } from '../../../../IDataMapping';

export class RelativeProductNPSChartTitleModel implements IDataMapping<RelativeProductNPSChartTitleModel> {
    public title: string = '';
    public period: string = '';

    public getMappings?() {
        return {
            title: 'Title_Relative NPS',
            period: 'TitlePeriod_Relative Product NPS',
        };
    }
}

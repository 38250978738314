import { useEffect, useState } from 'react';
import { CountryOptions } from '../../../shared/constants';

export const useQueryCountries = (query: string, delay: number): any[] => {
  const [queryResults, setQueryResults] = useState<any[]>([]);

  const queryCountries = async (q: string): Promise<void> => {
    if (!q) {
      setQueryResults([]);

      return;
    }

    try {
      const filteredCountries = CountryOptions.filter((c) =>
        c.label.toLowerCase().startsWith(q.toLowerCase())
      );

      setQueryResults(filteredCountries);
    } catch (ex) {
      setQueryResults([]);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
        queryCountries(query);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  return queryResults.length > 0
    ? queryResults
    : [{ label: 'No countries found', value: 0 }];
};

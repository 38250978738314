import { IDataMapping } from '../../IDataMapping';

export class CommonLineChartDataModel implements IDataMapping<CommonLineChartDataModel> {
    public Label: string = '';
    public Name: string = '';
    public Value: number = Number(0);
    public Color: string = '';
    public getMappings?() {
        return {
            Label: 'QUARTER(Date_calculation)',
            Name: 'brand_name',
            Value: 'AVG(indicator_value)',
            Color: 'brand_color',
        };
    }
}

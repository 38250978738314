import { IDataMapping } from '../../../../IDataMapping';

export class BSByStatementChartDataModel implements IDataMapping<BSByStatementChartDataModel> {
    public Answer:      string = '';
    public Color:       string = '';
    public Value:       number = Number(0);
    public Check:       number = Number(0);
    public Brand:       string = '';
    public RowOrder:    number = Number(0);
    public BrandRank:   number = Number(0);
    public Factor:      string = '';

    public getMappings?() {
        return {
            Answer:     'Answer',
            Color:      'ATTR(Colour)',
            Value:      'AVG(Value)',
            Check:      'AGG(AVG(-1))',
            Brand:      'Brand',
            RowOrder:   'AGG(INDEX())',          
            BrandRank:  'AVG(Rank BS)', 
            Factor:     'BS_statement'
        };
    }
}

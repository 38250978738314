import { UserDevice } from './enums/UserDevice';

export const getDeviceType = (): UserDevice => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return UserDevice.Tablet;
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return UserDevice.Phone;
  }
  return UserDevice.Desktop;
};

export const fileIsVideo = (extension: string): boolean => {
  return (
    extension === '.wmv' ||
    extension === '.mov' ||
    extension === '.avi' ||
    extension === '.mp4'
  );
};

export const canViewInBrowser = (extension: string) => {
  return (
    extension === '.txt' ||
    extension === '.pdf' ||
    extension === '.jpg' ||
    extension === '.jpeg' ||
    extension === '.ico' ||
    extension === '.png'
  );
};

export const BASE64TOARRAYBUFFER = (base64: any) => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }

  return bytes;
};

export const SAVEBYTEARRAY = (fileName: string, byte: any, fileType: string) => {
  const blob = new Blob([byte], { type: fileType });

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};

export const filterInitDuplicateData = (array: any) => {
  let filteredArr = array.filter((e: any, i: any) => {
    return array.findIndex((x: any) => {
    return x.Label === e.Label && x.Value === e.Value && x.Name === e.Name;}) === i;
  });
  return filteredArr;
};

export const addEmptyValuesToInitData = (labels: any, arr: any) => {
  // Since we use labels as a constant values - to compare the new data and add null values in their respected places,
  // we have to be sure that the labels are properly distincted beforehand.
  // The arr stands for the already sorted and filtered data for the chart.
  if (labels.length > 0 && arr.length > 0) {
    labels.forEach((dLabel: any, index: number) => {
      arr.forEach((obj: any) => {
        if (obj.labels[index] !== dLabel) {
          obj.labels.splice(index, 0, dLabel);
          obj.values.splice(index, 0, null);
        };
      })
    });
  };
  return arr;
}
import { IDataMapping } from "../../../../IDataMapping";

export class PHBSMarketStatementPerBrandChartDataModel implements IDataMapping<PHBSMarketStatementPerBrandChartDataModel> {
    public Label:       string = '';
    public Name:        string = '';
    public Value:       number = Number(0);
    public Color:       string = '';
    public PeriodIndex: number = 0;
    public BrandOrder:  number = 0;

    public getMappings?() {
        return {
            Label:      'QUARTER(Date)',
            Name:       'Brand',
            Value:      'AVG(Value)',
            Color:      'ATTR(Colour)',
            PeriodIndex:'MIN(index_period)',
            BrandOrder: 'AVG(Rank BS)'
        };
    }
}

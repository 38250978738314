import { IDataMapping } from '../../../../IDataMapping';

export class PHBSEmployeeDeepDiveOverTimeTitleModel implements IDataMapping<PHBSEmployeeDeepDiveOverTimeTitleModel> {
    public Title:       string = '';
    public Note:        string = '';
    public DataType:    string = '';

    public getMappings?() {
        return {
            Title:      'Employee Factors by Statement Over Time Title',
            Note:       'ATTR(Note Internal)',
            DataType:   'ATTR(Factor Employee 2)'
        };
    }
}

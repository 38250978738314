import { IDataMapping } from '../../../../IDataMapping';

export class UnaidedChannelRecallChartDataModel implements IDataMapping<UnaidedChannelRecallChartDataModel> {
    public Brand: string = '';
    public BrandOrder: number | null = 0;
    public Answer: string = '';
    public AnswerOrder: number | null = 0;
    public Color: string = '';
    public Score: number = 0;
    public Sample: number | null = 0;

    public getMappings?() {
        return {
            Brand: 'Brand',
            BrandOrder: 'ATTR(Calc Brand Order)',
            Answer: 'Answer',
            AnswerOrder: 'ATTR(Calc Answer Order )',
            Color: 'ATTR(Calc Brand Colors)',
            Score: 'SUM(%)',
            Sample: 'SUM(Sample size)',
        };
    }
}

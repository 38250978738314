import { IDataMapping } from '../../IDataMapping';

export class ProductNPSResultsMonthlyDataModel implements IDataMapping<ProductNPSResultsMonthlyDataModel> {
    public Label: string = '';
    public Name: string = '';
    public Color: string = '';
    public Value: number | null = Number(0);
    public Rank: number = Number(0);
    public getMappings?() {
        return {
            Label: 'Brand',
            Name: 'Answer_Text',
            Color: 'PrescribedForDiseaseColor',
            Value: 'AVG(Value)',
            Rank: 'MAX(pref value check rank (Month) (NPS))',
        };
    }
}
import React, { useEffect, useState } from 'react';
import { some, orderBy, find } from 'lodash';
import styles from './WorkbooksView.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../../../store/IState';
import { TableauWorkbookViewModel } from '../../../models/Tableau/TableauWorkbookViewModel';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SearchControl } from '../../common/SearchControl/SearchControl';
import { ViewType } from '../../../shared/enums/ViewType';
import { CardsView } from '../cards-view/CardsView';
import { ListView } from '../list-view/ListView';
import { setSelectedWorkbook } from '../../../store/tableau/TableauActions';
import { ListSvg } from '../../common/Svgs/ListSvg';
import { TilesSvg } from '../../common/Svgs/TilesSvg';
import { ApplicationUserViewModel } from '../../../models/User/ApplicationUserViewModel';

export const WorkbooksView = (): JSX.Element => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [viewType, setViewType] = useState<ViewType>(ViewType.Cards);

  const [oderedBy, setOrderedBy] = useState<any>(undefined);

  const [searchValue, setSearchValue] = useState<string>('');

  const workbooks: TableauWorkbookViewModel[] = useSelector(
    (state: IState) => state.TableauReducer.workbooks
  );

  const workbooksLoaded: boolean = useSelector(
    (state: IState) => state.TableauReducer.workbooksLoaded
  );

  const userProfile: ApplicationUserViewModel | undefined = useSelector(
    (state: IState) => state.UserReducer.userProfile
  );

  const [filteredWorkbooks, setFilteredWorkbooks] = useState<
    TableauWorkbookViewModel[]
  >(workbooks);

  const hasErrorWithTableauAccount: boolean = useSelector(
    (state: IState) => state.LayoutReducer.showTableauUserError
  );

  const searchWorkbooks = (ev: any) => {
    const value: string = ev.target.value;
    if (value.length > 0) {
      const filteredWbs: TableauWorkbookViewModel[] = workbooks.filter(
        (wb) =>
          wb.name.toLowerCase().includes(value.toLowerCase()) ||
          some(wb.tags, (t) => t.toLowerCase().includes(value.toLowerCase()))
      );
      setFilteredWorkbooks(filteredWbs);
    } else {
      setFilteredWorkbooks(workbooks);
    }
    setSearchValue(value);
  };

  const orderOptions: Array<{
    label: string;
    value: number;
    key: keyof TableauWorkbookViewModel;
    order: 'asc' | 'desc';
  }> = [
    { label: 'Name A-Z', value: 0, key: 'name', order: 'asc' },
    { label: 'Name Z-A', value: 1, key: 'name', order: 'desc' },
    {
      label: 'Modified Older to Newer',
      value: 2,
      key: 'updatedAt',
      order: 'asc',
    },
    {
      label: 'Modified Newer to Older',
      value: 3,
      key: 'updatedAt',
      order: 'desc',
    },
  ];

  const orderWorkbooks = (ev: any) => {
    let orderedWbs: TableauWorkbookViewModel[] = [];
    const selectedOption = find(orderOptions, (o) => o.value === ev.value);
    if (selectedOption) {
      const fieldKey: keyof TableauWorkbookViewModel = selectedOption.key;
      const order: 'asc' | 'desc' = selectedOption.order;
      orderedWbs = orderBy(filteredWorkbooks, (wb) => wb[fieldKey], order);
      setFilteredWorkbooks(orderedWbs);
      setOrderedBy(ev.value);
    }
  };

  useEffect(() => {
    if (workbooks.length > 0 || workbooksLoaded) {
      setIsLoading(false);
      setFilteredWorkbooks(workbooks);
    } else if (hasErrorWithTableauAccount) {
      setIsLoading(false);
    }
  }, [workbooks, hasErrorWithTableauAccount]);

  useEffect(() => {
    dispatch(setSelectedWorkbook(undefined));
  }, []);

  const isActive = (type: ViewType) => (viewType === type ? styles.active : '');

  if (isLoading) {
    return (
      <div className={styles.centered_on_screen}>
        <ProgressSpinner />
      </div>
    );
  }

  if (hasErrorWithTableauAccount) {
    return (
      <React.Fragment>
        <div className={styles.error_container}>
          There is a problem with your Tableau account. Please contact site
          administrator.
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className={styles.page_header_components}>
        <div className={styles.wb_controls}>
          <div className={styles.search_container}>
            <SearchControl
              onChange={(ev) => searchWorkbooks(ev)}
              value={searchValue}
            />
          </div>
          <div className={styles.dropdown_container}>
            <Dropdown
              options={orderOptions}
              onChange={orderWorkbooks}
              value={oderedBy}
              placeholder={'Order by'}
            />
          </div>
          <div className={styles.icons_container}>
            <i
              className={`${styles.list_icon} ${isActive(ViewType.List)}`}
              onClick={() => setViewType(ViewType.List)}
            >
              <ListSvg width={'33'} height={'23'} />
            </i>
            <i
              className={`${styles.tiles_icon} ${isActive(ViewType.Cards)}`}
              onClick={() => setViewType(ViewType.Cards)}
            >
              <TilesSvg width={'30'} height={'30'} />
            </i>
          </div>
        </div>
      </div>
      {viewType === ViewType.Cards ? (
        <CardsView workbooks={filteredWorkbooks} />
      ) : (
        <ListView workbooks={filteredWorkbooks} />
      )}
    </React.Fragment>
  );
};

import pptxgen from 'pptxgenjs';
import { TableauApiHelper } from '../helpers/TableauApiHelper';
import { BaseFirstSlideRenderer } from '../services/Renderers/BaseFirstSlideRenderer';
import { ChartColors } from '../philips-constants';

export abstract class BasePptxService {
    protected colors = ChartColors;
    protected chartTitle: string;
    protected abstract chartQuestion: string;
    protected presentation: pptxgen;
    protected view: any;
    protected abstract firstSlideRenderer: BaseFirstSlideRenderer;

    constructor(view: any, chartTitle: string) {
        this.view = view;
        this.presentation = new pptxgen();
        this.chartTitle = chartTitle;
    }

    public async generate() {
        this.createMasterSlides(this.presentation);
        this.firstSlideRenderer.renderFirstSlide(this.presentation, this.chartTitle);
        const chartSlide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });
        await this.setChartSlideLayout(chartSlide);
        await this.addChartSlide(chartSlide);
        this.presentation.addSlide({ masterName: 'PHILIPS_LAST_MASTER' });

        const fileOptions: pptxgen.WriteFileProps = { compression: false, fileName: this.chartTitle + '.pptx' };
        await this.presentation.writeFile(fileOptions);
    }

    protected abstract addChartSlide(chartSlide: pptxgen.Slide): Promise<void>;

    protected async getChartData(sheetName: string) {
        return TableauApiHelper.getChartData(this.view, sheetName);
    }

    protected async getMappedChartData<T>(mappedType: new () => T, sheetName: string) {
        return TableauApiHelper.getMappedChartData(mappedType, this.view, sheetName);
    }

    protected getSortedByOrder<T extends { Order: number | null }>(
        data: T[],
        order: (a: T, b: T) => number = (a, b) => (a.Order as number) - (b.Order as number),
    ) {
        const missingOrderData = data.filter(x => x.Order === null);

        if (missingOrderData) {
            const maxOrder = Math.max(
                ...data.map(x => x.Order as number)
            );

            missingOrderData.forEach((x, i) => x.Order = maxOrder - 1 + i);
        }

        return data.sort(order);
    }

    protected getMappedChartDataColors(chartData: Array<{ Color: string }>) {
        return chartData.map(c => {
            const color = c.Color ? c.Color.trim() : '';
            if (color === '*') {
                return this.colors.neutral;
            }
            return /^#[0-9A-F]{6}$/i.test(color)
                ? color.replace('#', '')
                : /^#([0-9A-F]{3}){1,2}$/i.test(color)
                    ? color.replace('#', '')
                    : this.colors.default;
        });
    }

    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.55, bold: true, w: 8.6 });
        slide.addText([{ text: this.chartQuestion, options: { fontSize: 7 } }], { x: 0.5, y: '95%' });
    }

    protected createMasterSlides(pptx: pptxgen) {
        // Title slide - standart Philips projects
        pptx.defineSlideMaster({
            title: 'PHILIPS_FIRST_MASTER',
            background: { path: '/assets/pptx/images/background.jpg' },
            objects: [{image: {path: '/assets/pptx/images/logo_firstSlide.png', h: 5.625, w: 10}}]
        });

        // Title slide - Brand Strength Philips projects
        pptx.defineSlideMaster({
            title: 'PHILIPS_FIRST_BS_MASTER',
            background: { path: '/assets/pptx/images/background.jpg' },
            objects: [
                { image: { path: '/assets/pptx/images/logo_firstSlide.png', h: 5.625, w: 10 } },
                { image: { path: '/assets/pptx/images/bs_squares.png', x: 6.59, y: 0.42, h: 2.074, w: 2.854 } },
                { text: { text: "Philips", options: { x: 0.81, y: 1.625, color: "FFFFFF", fontSize: 23, fontFace: "Calibri (Body)" } } },
                { text: { text: "Brand", options: { x: 0.763, y: 2.12, color: "FFFFFF", fontSize: 56, fontFace: "Calibri (Body)" } } },
                { text: { text: "Strength", options: { x: 0.78, y: 2.877, color: "FFFFFF", fontSize: 47, fontFace: "Microsoft YaHei", bold: true } } }
            ]
        });

        // Plain slides (with charts) - all Philips projects
        pptx.defineSlideMaster({
            title: 'PHILIPS_MASTER',
            objects: [{image: {path: '/assets/pptx/images/ph_logo_blue.jpg', x: 9.055, y: 0.236, w: 0.393, h: 0.5}}],
            slideNumber: { x: 0.3, y: '93.2%', fontSize: 7 }
        });

        // Final slide - all Philips projects
        pptx.defineSlideMaster({
            title: 'PHILIPS_LAST_MASTER',
            background: { path: '/assets/pptx/images/background.jpg' },
            objects: [{image: {path: '/assets/pptx/images/ph_logo_white.png', x: 4.362, y: 2, w: 1.25, h: 1.594}}]
        });        
    };
}

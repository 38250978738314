import { IDataMapping } from '../../../IDataMapping';

export class SRCBSAnalysisTableTitleModel implements IDataMapping<SRCBSAnalysisTableTitleModel> {
    public Title: string = '';
    public SubTitle: string = '';

    public getMappings?() {
        return {
            Title:      'Brand Strength Analysis Ttitle',
            SubTitle:   'Brand Strength Analysis Sub-Ttitle'
        };
    }
}

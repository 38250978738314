import { IDataMapping } from '../../../../IDataMapping';

export class UnaidedRecallSegmentCommunicationsChartDataModel implements IDataMapping<UnaidedRecallSegmentCommunicationsChartDataModel> {
    public Brand: string = '';
    public Segment: string = '';
    public Color: string = '';
    public Order: number = 0;
    public Sum: number = 0;

    public getMappings?() {
        return {
            Brand: 'Calc Others Pie',
            Segment: 'Segment',
            Color: 'ATTR(Calc Brand Colors)',
            Order: 'SUM(Total Segment %)',
            Sum: 'SUM(%)',
        };
    }
}

import pptxgen from 'pptxgenjs';

import { FirstChoiceTitleModel } from '../../../models/SRC/FirstChoice/FirstChoiceTitleModel';
import { FirstChoiceTTLTitleModel } from '../../../models/SRC/FirstChoice/FirstChoiceTTLTitleModel';
import { FirstChoiceTableDataModel } from '../../../models/SRC/FirstChoice/FirstChoiceTableDataModel';
import { FirstChoiceLineChartDataModel } from '../../../models/SRC/FirstChoice/FirstChoiceLineChartDataModel';

import { SRCFirstSlideRenderer } from '../../Renderers/SRCFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';
import { addEmptyValuesToInitData, filterInitDuplicateData } from '../../../../shared/utility';

export class FirstChoiceChartService extends BasePptxService {
  protected chartQuestion: string = '';
  protected firstSlideRenderer: SRCFirstSlideRenderer = new SRCFirstSlideRenderer();
  protected titleSheet: string;
  protected dataSheet: string;
  protected lineChartDataSheet: string;

  constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string, lineChartDataSheet: string) {
      super(view, chartTitle);
      this.titleSheet = titleSheet;
      this.dataSheet = dataSheet;
      this.lineChartDataSheet = lineChartDataSheet;
  }

  protected async setChartSlideLayout(slide: pptxgen.Slide) {
    // Override title.
    let subTitle: string = "";
    if (this.titleSheet === "First Choice Base size Title") {
      const titleData = await this.getMappedChartData(FirstChoiceTTLTitleModel, this.titleSheet);
      titleData.map((obj: any) => {
        return this.chartTitle = obj.Title;
      });
    } else {
      const titleData = await this.getMappedChartData(FirstChoiceTitleModel, this.titleSheet);
      titleData.map((obj: any) => {
        this.chartTitle = obj.Title;
        return subTitle = obj.SubTitle;
      });
    }

    slide.addText([{ text: this.chartTitle, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.35, bold: true });
    slide.addText([{ text: subTitle, options: { color: this.colors.black, fontSize: 12 } }], { x: 0.5, y: 0.58, italic: true });
  }

  protected async addChartSlide(chartSlide: pptxgen.Slide) {
    const tableData = await this.getMappedChartData(FirstChoiceTableDataModel, this.dataSheet);
    const reversedTableData = tableData.slice().reverse();
    let lowValue: boolean = false;
    const tableProps: pptxgen.TableProps = {
        x: 1,
        y: 1,
        w: '80%',
        align: 'center',
        bold: true,
        fontSize: 8,
        valign: 'middle',
        rowH: 0.35,
        color: this.colors.white,
        border: { type: 'solid', color: this.colors.neutral, pt: 0.2 },
    };

    tableData.forEach((obj: any) => {
      if (obj.LowNumIndicator) {
        lowValue = true;
      };
    })

    if (lowValue) {
      chartSlide.addText([{ text: "*Note: Scores could be influenced by small sample sizes.", options: { color: '#000000', fontSize: 10 } }], { x: 4.9, y: 5, bold: false});
    };
    const createRowTitles = this.generateTitleRows(reversedTableData);
    const createRows = this.generateBodyRows(reversedTableData);
    chartSlide.addTable([...createRowTitles, ...createRows], tableProps);

    await this.additionalChartViewSlide();
  }

  private generateTitleRows = (tableData: any) => {
    const markets = tableData.map((o: any) => o.Market);
    const filteredMarkets = tableData.filter(({Market}: any, index: any) => !markets.includes(Market, index + 1));
    const result = [] as  any;
    filteredMarkets.forEach((obj: any) => {
      result.push({ text: obj.Market, options: { fill: { color: '#0153A5' } } },)
    })
    
    const rowTitles: pptxgen.TableRow[] = []
    rowTitles.push(result)
    rowTitles[0].unshift({
      text: '',
    });

    return rowTitles;
  }

  private generateBodyRows = (tableData: any) => {
    // Separate Markets and add the categories in array to use it later.
    const checkMarketForCategory: any = [];
    tableData.forEach((obj: any) => {
      const item = checkMarketForCategory.find((item: any) => item.market === obj.Market);
      if(checkMarketForCategory.length > 0 && item) {
        if (item) {
          item.categories.push(obj.Category);
        }
      } else {
        checkMarketForCategory.push(
          {
            market: obj.Market,
            categories: [obj.Category],
          }
        )
      }
    })

    // Separate Categories and add them array with their values.
    const newArr: any = [];
    tableData.forEach((obj: any) => {
      const item = newArr.find((item: any) => item.name === obj.Category);
      if(newArr.length > 0 && item) {
        if (item) {
          const result = item.values.find((market: any) => {
            return market.marketName === obj.Market;
          })
          if (result) {
            result.value = obj.Value ? `${(parseFloat(obj.Value)* 100).toFixed(0)}%` : obj.Count;
          }
        }
      } else {
        // Generate empty placeholders for the exact amount of object so we could
        // match the empty ones later.
        const dummyValues = checkMarketForCategory.map((obj: any) => {
          return {
            marketName: obj.market,
            value: '',
          }
        });
        const placeholders = {
          name: obj.Category,
          market: dummyValues.marketName,
          values: dummyValues,
        };
        const result = placeholders.values.find((market: any) => {
          return market.marketName === obj.Market;
        });
        if (result) {
          result.value = obj.Value ? `${(parseFloat(obj.Value)* 100).toFixed(0)}%` : obj.Count;
        };
        newArr.push(placeholders)
      }
    });

    // Generate the rows.
    const createRows: any = [];
    newArr.map((obj: any, index: any) => {
      const arr: any = [];
      arr.push({
        text: obj.name, options: { color: '#000000', fill: { color: index % 2 === 0 ? '#FFFFFF' : '#c5c5c5' }}
      })
      obj.values.forEach((val: any) => {
        arr.push({
          text: val.value && val.value <= 20 ? `${val.value}*` : val.value, options: { color: val.value < 10 ? '#FF0000' : '#000000', fill: { color: index % 2 === 0 ? '#FFFFFF' : '#c5c5c5' }}
        })
      });
      return createRows.push(arr);
    })

    return createRows;
  };

  protected async additionalChartViewSlide() {
    if(this.lineChartDataSheet) {
      const slide =this.presentation.addSlide({ masterName: 'SRC_MASTER' });

      let subTitle: string = "";
      if (this.titleSheet !== "First Choice Base size Title") {
        const titleData = await this.getMappedChartData(FirstChoiceTitleModel, this.titleSheet);
        titleData.map((obj: any) => {
          return subTitle = obj.SubTitle;
        });
      };

      slide.addText([{ text: this.chartTitle, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.35, bold: true });
      slide.addText([{ text: subTitle, options: { color: this.colors.black, fontSize: 12 } }], { x: 0.5, y: 0.58, italic: true });
      const chartData = await this.getMappedChartData(FirstChoiceLineChartDataModel, this.lineChartDataSheet);
      let filteredData = filterInitDuplicateData(chartData);

      const newArr: any = [];
      const lineColors: string[] = [];
      const getAllLabels: any = [];
      let country: string = "";

      filteredData.forEach((obj: any) => {
        getAllLabels.push(obj.Label);
        country = obj.Country;
      });

      const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

      filteredData.forEach((obj: any) => {
        if (obj.Value) {
          lineColors.push(obj.Color);
        }
        const item = newArr.find((item: any) => item.name === obj.Name);
        if (newArr.length > 0 && item) {
          item.values.push(parseFloat(obj.Value));
          item.labels.push(obj.Label);
        } else {
          newArr.push(
            {
              name: obj.Name,
              labels: [obj.Label],
              values: [parseFloat(obj.Value)],
            }
          )
        }
      });
      newArr.reverse();
      lineColors.reverse();

      const finalChartData = addEmptyValuesToInitData(distinctedLabels, newArr);

      slide.addChart(
        this.presentation.ChartType.line,
        finalChartData,
        {
          x: 1,
          y: 1,
          w: "80%",
          h: "70%",
          showLegend: true,
          legendPos: 'r',
          dataLabelPosition: 't',
          dataLabelFormatCode: '0%',
          valAxisLabelFormatCode: '0%',
          catAxisLabelRotate: this.labelRotateDegree(newArr),
          chartColors: lineColors.filter(this.distinctRepeatables),
          valAxisLabelFontSize: 10,
          catAxisLabelFontSize: 10,
        }
      );

      slide.addText([{ text: country, options: { color: this.colors.black, fontSize: 12 } }], { x: 0.5, y: 0.75, italic: true });
    }
  }

  protected labelRotateDegree(array: any) {
    let degree: number = 0;
    array.forEach((obj: any) => {
      if (obj.labels.length > 10) {
        degree = 45;
      } else {
        degree = 0;
      }
    });
    return degree;
  }

  private distinctRepeatables = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };
}

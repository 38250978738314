import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IRoleFormDialogProps } from './IRoleFormDialogProps';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import styles from './RoleFormDialog.module.scss';
import { CreateRoleModel } from '../../../models/Roles/CreateRoleModel';
import { CreateRolePermissionModel } from '../../../models/Roles/CreateRolePermissionModel';
import { ModelStateErrorModel } from '../../../models/ModelStateErrorModel';
import { clone } from 'lodash';
import api from '../../../helpers/Api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RoleActions } from '../../../shared/enums/RoleActions';
import { Sections } from '../../../shared/enums/Sections';
import { FormType } from '../../../shared/enums/FormType';
import { EditRoleModel } from '../../../models/Roles/EditRoleModel';
import layoutMessage from '../../../helpers/LayoutMessage';
import roleFormValidator from './RoleFormValidator';

export const RoleFormDialog = (props: IRoleFormDialogProps): JSX.Element => {
  const dispatch = useDispatch();

  const { formType, isOpen, roles, onFormClose, onActionSuccess } = props;

  const [errors, setErrors] = useState<ModelStateErrorModel[]>([]);

  const [formRole, setRoleModel] = useState<CreateRoleModel | EditRoleModel>(
    props.formRole
  );

  const [
    isConfirmationButtonDisabled,
    setIsConfirmationButtonDisabled,
  ] = useState<boolean>(false);

  const onDialogHide = () => {
    onFormClose();
    setErrors([]);
    setIsConfirmationButtonDisabled(false);
  };

  const onConfirmAction = async () => {
    if (formType !== FormType.Delete) {
      if (isValid()) {
        api.post(
          `Roles/${formType}`,
          JSON.stringify(formRole),
          onActionSuccess,
          setErrors
        );
      }
    } else {
      api.post(
        `Roles/${formType}`,
        (formRole as EditRoleModel).rolePermission.id?.toString(),
        () => {
          onActionSuccess();
          layoutMessage.renderSuccess(
            dispatch,
            'The User Role was deleted successfully'
          );
        },
        undefined,
        (errorMessage) => {
          setIsConfirmationButtonDisabled(true);
          layoutMessage.renderError(dispatch, errorMessage);
        }
      );
    }
  };

  const onSimplePropChange = (
    fieldName: keyof CreateRoleModel,
    fieldValue: any
  ) => {
    const currentModel: CreateRoleModel = clone(formRole);
    currentModel[fieldName] = fieldValue;
    setRoleModel(currentModel);
  };

  const isValid = () => {
    const errorObjects: ModelStateErrorModel[] = roleFormValidator.validate(
      formRole,
      roles,
      formType
    );

    if (errorObjects && errorObjects.length) {
      setErrors(errorObjects);
      return false;
    }

    return true;
  };

  const getErrors = () => {
    return (
      <div>
        {errors.map((e) =>
          e.errors.map((m) => <p className={styles.error_text}>{m}</p>)
        )}
      </div>
    );
  };

  const buildFormPermissionsColumns = (
    rowData: keyof CreateRolePermissionModel,
    currentRoleAction: RoleActions
  ) => {
    const roleSectionPermissions = formRole.rolePermission[rowData];
    const checked = (roleSectionPermissions & currentRoleAction) !== 0;
    return (
      <div className='checkbox-container'> <input type='checkbox' checked={checked} onClick={() => {
        const currentModel: CreateRoleModel = clone(formRole);
        let aggregatedRoleActions: number = currentRoleAction;
        if (currentRoleAction === RoleActions.Delete && !checked) {
          aggregatedRoleActions =
            currentRoleAction |
            RoleActions.Edit |
            RoleActions.Add |
            RoleActions.View;
        } else if (currentRoleAction === RoleActions.Edit && !checked) {
          aggregatedRoleActions =
            currentRoleAction | RoleActions.Add | RoleActions.View;
        } else if (currentRoleAction === RoleActions.Add && !checked) {
          aggregatedRoleActions = currentRoleAction | RoleActions.View;
        } else if (currentRoleAction === RoleActions.View && checked) {
          aggregatedRoleActions =
            currentRoleAction ^
            RoleActions.Add ^
            RoleActions.Edit ^
            RoleActions.Delete;
        } else if (currentRoleAction === RoleActions.Add && checked) {
          aggregatedRoleActions =
            currentRoleAction ^ RoleActions.Edit ^ RoleActions.Delete;
        } else if (currentRoleAction === RoleActions.Edit && checked) {
          aggregatedRoleActions = currentRoleAction ^ RoleActions.Delete;
        }

        currentModel.rolePermission[rowData] = !checked
          ? roleSectionPermissions | aggregatedRoleActions
          : roleSectionPermissions & ~aggregatedRoleActions;
        setRoleModel(currentModel);
      }}/><span></span><img src='/images/check-square.svg' alt=''/></div>
    );
  };

  const updateRoleForm = (
    <div>
      {errors.length > 0 ? getErrors() : undefined}
      <div className={styles.form_row}>
        <div className={styles.form_col}>
          <label>User Role Name*</label>
          <InputText
            value={formRole.name}
            onChange={(e: any) => onSimplePropChange('name', e.target.value)}
          />
        </div>
        <DataTable
          className={styles.form_permissions}
          value={Object.keys(Sections)}
          responsive={true}
          rows={10}
        >
          <Column
            field='section'
            style={{ width: '6em' }}
            body={(rowData: any) => (
              <div>{Sections[rowData as keyof typeof Sections]}</div>
            )}
          />
          {Object.keys(RoleActions)
            .filter((k) => RoleActions[k as keyof typeof RoleActions] > 0)
            .map((k) => {
              const currentRoleAction: RoleActions =
                RoleActions[k as keyof typeof RoleActions];
              return (
                <Column
                  key={k}
                  field={k}
                  header={k}
                  style={{ textAlign: 'center', width: '0.5em' }}
                  body={(rowData: any) =>
                    buildFormPermissionsColumns(rowData, currentRoleAction)
                  }
                />
              );
            })}
        </DataTable>
      </div>
    </div>
  );

  const footer = (
    <>
      <Button
        label={'CANCEL'}
        onClick={onDialogHide}
        className={styles.cancel_button}
      />
      <Button
        label={formType !== FormType.Delete ? formType.toUpperCase() : 'YES'}
        onClick={onConfirmAction}
        className={styles.save_button}
        disabled={isConfirmationButtonDisabled}
      />
    </>
  );

  const deleteRoleConfirmationMessage = (
    <div>Are you sure you want to delete the user role '{formRole.name}'?</div>
  );

  return (
    <Dialog
      onHide={onDialogHide}
      visible={isOpen}
      header={`${formType} User Role`}
      footer={footer}
      className={styles.default_dialog}
      style={{ width: '550px' }}
    >
      {formType !== FormType.Delete
        ? updateRoleForm
        : deleteRoleConfirmationMessage}
    </Dialog>
  );
};

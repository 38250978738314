import { IDataMapping } from '../../IDataMapping';

export class CommonOverTimeLineChartTitleModel implements IDataMapping<CommonOverTimeLineChartTitleModel> {
    public Title: string = '';
    public SubTitle: string = '';
    public Level: string = '';
    public Period: string = '';
    public getMappings?() {
        return {
            Title: "IPL Page Titles",
            SubTitle: "IPL Page Sub Titles",
            Level: "Answer1 (IPL)",            
            Period: 'ATTR(MAT/Quarter)',
        };
    }
}

import React from 'react';
import { ISVGProps } from './ISVGProps';

export const ExcelSvg = (props: ISVGProps) => {
  const { width, height } = props;
  return (
    <svg
      id='Layer_1'
      width={width}
      height={height}
      version='1.1'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <g>
          <path d='M13.5,23.5c-0.025,0-0.051-0.002-0.076-0.006l-13-2C0.18,21.456,0,21.246,0,21V4c0-0.233,0.161-0.435,0.388-0.487l13-3    c0.148-0.033,0.305,0.001,0.424,0.096C13.931,0.703,14,0.847,14,1v22c0,0.146-0.063,0.285-0.174,0.379    C13.734,23.458,13.619,23.5,13.5,23.5z M1,20.571l12,1.846V1.628L1,4.398V20.571z' />
        </g>
        <g>
          <path d='M23.5,21.5h-10c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H23v-17h-9.5C13.224,3.5,13,3.276,13,3s0.224-0.5,0.5-0.5h10    C23.776,2.5,24,2.723,24,3v18C24,21.276,23.776,21.5,23.5,21.5z' />
        </g>
        <g>
          <path d='M9.5,16.5c-0.167,0-0.33-0.083-0.424-0.235l-5-8C3.93,8.03,4.001,7.722,4.235,7.576C4.47,7.429,4.778,7.5,4.924,7.735l5,8    c0.146,0.234,0.075,0.543-0.159,0.689C9.683,16.475,9.591,16.5,9.5,16.5z' />
        </g>
        <g>
          <path d='M4.5,16.5c-0.09,0-0.182-0.024-0.265-0.076c-0.234-0.146-0.305-0.455-0.159-0.689l5-8C9.222,7.5,9.53,7.429,9.765,7.576    C9.999,7.722,10.07,8.03,9.924,8.265l-5,8C4.829,16.417,4.666,16.5,4.5,16.5z' />
        </g>
        <g>
          <path d='M17.5,21.5c-0.276,0-0.5-0.224-0.5-0.5V3c0-0.276,0.224-0.5,0.5-0.5S18,2.723,18,3v18C18,21.276,17.776,21.5,17.5,21.5z' />
        </g>
        <g>
          <path d='M23.5,18.5h-10c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h10c0.276,0,0.5,0.224,0.5,0.5S23.776,18.5,23.5,18.5z' />
        </g>
        <g>
          <path d='M23.5,15.5h-10c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h10c0.276,0,0.5,0.224,0.5,0.5S23.776,15.5,23.5,15.5z' />
        </g>
        <g>
          <path d='M23.5,12.5h-10c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h10c0.276,0,0.5,0.224,0.5,0.5S23.776,12.5,23.5,12.5z' />
        </g>
        <g>
          <path d='M23.5,9.5h-10C13.224,9.5,13,9.276,13,9s0.224-0.5,0.5-0.5h10C23.776,8.5,24,8.723,24,9S23.776,9.5,23.5,9.5z' />
        </g>
        <g>
          <path d='M23.5,6.5h-10C13.224,6.5,13,6.276,13,6s0.224-0.5,0.5-0.5h10C23.776,5.5,24,5.723,24,6S23.776,6.5,23.5,6.5z' />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};

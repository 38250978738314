import { IDataMapping } from '../../IDataMapping';

export class SnapshotTitleModel implements IDataMapping<SnapshotTitleModel> {
    public Title: string = '';
    public BPBarTitle: string = '';
    public NPSBarTitle: string = '';
    public BPLineTitle: string = '';
    public MRBarTitle: string = '';
    public getMappings?() {
        return {
            Title: 'SnapshotTitle Export',
            BPBarTitle: 'Brand Pref Title',
            NPSBarTitle: "NPS Title",
            BPLineTitle: 'Brand Pref Trend Title',
            MRBarTitle: 'Most Recommended Title',
        };
    }
}

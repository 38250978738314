import { IDataMapping } from '../../IDataMapping';

export class PreferenceOverTimeDataModel implements IDataMapping<PreferenceOverTimeDataModel> {
    public Label: string = '';
    public GlobalName: string = '';
    public Name: string = '';
    public Value: number = Number(0);
    public Color: string = '';
    public TimeRank: number = Number(0);
    public getMappings?() {
        return {
            Label: 'QUARTER(Date)',
            GlobalName: 'Answer for POT',
            Name: 'Answer',
            Value: "AVG(Value)",
            Color: "Colour",
            TimeRank: 'MIN(index_period)'
        };
    }
}

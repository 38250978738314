import pptxgen from 'pptxgenjs';

import { RecommendationPatternsTitleModel } from '../../../models/SRC/RecommendationPatterns/RecommendationPatternsTitleModel';
import { RecommendationPatternsPieDataModel } from '../../../models/SRC/RecommendationPatterns/RecommendationPatternsPieDataModel';
import { RecommendationPatternsBarDataModel } from '../../../models/SRC/RecommendationPatterns/RecommendationPatternsBarDataModel';

import { SRCFirstSlideRenderer } from '../../Renderers/SRCFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

import { filterInitDuplicateData, addEmptyValuesToInitData  } from "../../../../shared/utility";

export class RecommendationPatternsService extends BasePptxService {
  protected chartQuestion: string = '';
  protected firstSlideRenderer: SRCFirstSlideRenderer = new SRCFirstSlideRenderer();
  protected titleSheet: string;
  protected commentsSheet: string;
  protected pieDataSheet: string;
  protected tableDataSheet: string;
  protected samplesDataSheet: string;

  constructor(view: any, chartTitle: string, titleSheet: string, commentsSheet: string,
    pieDataSheet: string, tableDataSheet: string, samplesDataSheet: string) {
    super(view, chartTitle);
    this.titleSheet = titleSheet;
    this.commentsSheet = commentsSheet;
    this.pieDataSheet = pieDataSheet;
    this.tableDataSheet = tableDataSheet;
    this.samplesDataSheet = samplesDataSheet;
  }

  protected async setChartSlideLayout(slide: pptxgen.Slide) {
    // Override title's options and add filter's name in it.
    const titleData = await this.getMappedChartData(RecommendationPatternsTitleModel, this.titleSheet);
    const comments = await this.getMappedChartData(RecommendationPatternsTitleModel, this.commentsSheet);
    const samplesData = await this.getMappedChartData(RecommendationPatternsTitleModel, this.samplesDataSheet);
    
    let lowValue: boolean = false;
    
    titleData.map( obj => {
      return this.chartTitle = obj.Title;
    });

    slide.addText([{ text: this.chartTitle, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.35, bold: true });
    slide.addText([{ text: comments[0].Comments, options: { color: this.colors.black, fontSize: 12 } }], { x: 0.5, y: 0.58, italic: true });
    slide.addText([{ text: comments[0].SecondComment, options: { color: this.colors.black, fontSize: 12 } }], { x: 0.5, y: 0.95, italic: true });

    samplesData.forEach((obj: any) => {
      if (obj.SampleValue <= 20) {
        lowValue = true;
      }
    });

    if (lowValue) {
      slide.addText([{ text: "*Note: Scores could be influenced by small sample sizes.", options: { color: '#000000', fontSize: 10 } }], { x: 0.5, y: 5.4, bold: false});
    };
  
    if (samplesData.length > 0) {
      if (samplesData[1]) {
        slide.addText(
          [
              { 
                  text: `${samplesData[1].SampleDate} (n=${samplesData[1].SampleValue < 20 ? samplesData[1].SampleValue.toFixed(0) + '*' : samplesData[1].SampleValue.toFixed(0)})`, 
                  options: { color: samplesData[1].SampleValue < 10 ? '#FF0000' : '#000000', fontSize: 11 },
              },
          ],
          { x: 8.7, y: 5.2, italic: true });
      } if (samplesData[0]) {
          slide.addText(
            [
                { 
                    text: `${samplesData[0].SampleDate} (n=${samplesData[0].SampleValue < 20 ? samplesData[0].SampleValue.toFixed(0) + '*' : samplesData[0].SampleValue.toFixed(0)})`, 
                    options: { color: samplesData[0].SampleValue < 10 ? '#FF0000' : '#000000', fontSize: 11 },
                },
            ],
            { x: 8.7, y: 5.4, italic: true });
      }
    }
  }

  protected async addChartSlide(chartSlide: pptxgen.Slide) {
    await this.addPieChart(chartSlide);
  }

  private async addPieChart(chartSlide: pptxgen.Slide) {
    const pieData = await this.getMappedChartData(RecommendationPatternsPieDataModel, this.pieDataSheet);
    const barData = await this.getMappedChartData(RecommendationPatternsBarDataModel, this.tableDataSheet);

    let filteredData = filterInitDuplicateData(pieData);
    if (filteredData.length > 0) {
      const lineColors: string[] = [];
      const pieLabels: string[] = [];
      const pieValues: number[] = [];

      const dataChartPieStat = [
          {
              name: "",
              labels: pieLabels,
              values: pieValues,
          }
      ];

      filteredData.forEach((obj: any) => {
        lineColors.push(obj.Color);
        pieLabels.push(obj.Name);
        pieValues.push(obj.Value ? (obj.Value * 100) : 0);
      });

      chartSlide.addChart(
        this.presentation.ChartType.doughnut,
        dataChartPieStat,
        {
          showTitle: true,
          x: 0.5,
          y: 1.8,
          w: '30%',
          h: '60%',
          showLegend: true,
          showLeaderLines: true,
          showValue: true,
          title: 'Most Often Recommended',
          chartColors: lineColors.filter(this.distinctRepeatables),
          dataLabelColor: "FFFFFF",
          dataLabelFontSize: 10,
          titleFontSize: 12,
          legendFontSize: 8,
          dataLabelPosition: "bestFit",
          showPercent: false,
          dataLabelFormatCode: '#\\%',
          legendPos: 'b',
        }
      );
    };

    const barDataNewArr: any = [];
    const barLineColors: string[] = [];
    const getAllLabels: any = [];

    barData.forEach((obj: any) => {
      getAllLabels.push(obj.Label);
    });
    const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

    barData.forEach((obj: any) => {
      if (obj.Value) {
      barLineColors.push(obj.Color);
      }
      const item = barDataNewArr.find((item: any) => item.name === obj.Name);
      if(barDataNewArr.length > 0 && item) {
        if (item) {
          item.values.push(parseFloat(obj.Value));
          item.labels.push(obj.Label);
        }
      } else {
        barDataNewArr.push(
          {
            name: obj.Name,
            labels: [obj.Label],
            values: [parseFloat(obj.Value)],
          }
        )
      }
    });

    const finalBarChartData = addEmptyValuesToInitData(distinctedLabels, barDataNewArr);

    chartSlide.addChart(
      this.presentation.ChartType.bar,
      finalBarChartData,
      {
        x: pieData.length > 0 ? 4 : 0.5,
        y: 1.8,
        w: "50%",
        h: "60%",
        showTitle: true,
        title: 'Philips vs. Competitor',
        dataLabelFontSize: 10,
        titleFontSize: 12,
        legendFontSize: 8,
        catAxisLabelFontSize: 8,
        barDir: "col",
        legendPos: 'b',
        dataLabelFormatCode: '0%',
        valAxisLabelFormatCode: '0%',
        valAxisLabelFontSize: 10,
        chartColors: barLineColors.filter(this.distinctRepeatables),
        catAxisLabelPos: "high",
        showLegend: true,
        showValue: true,
        dataLabelPosition: "outEnd",
        valGridLine: { style: "none" },
      }
    );
  };

  private distinctRepeatables = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };
};
import { IDataMapping } from "../../../../IDataMapping";

export class PHBSMarketStatementOverTimeChartDataModel implements IDataMapping<PHBSMarketStatementOverTimeChartDataModel> {
    public Label:       string = '';
    public Name:        string = '';
    public Value:       number = Number(0);
    public Color:       string = '';
    public PeriodIndex: number = Number(0);
    public BrandOrder:  number = Number(0);

    public getMappings?() {
        return {
            Label:      'QUARTER(Date)',
            Name:       'ATTR(Answer)',
            Value:      'AVG(Value)',
            Color:      'ATTR(statement_color_code)',
            PeriodIndex:'MIN(index_period)',
            BrandOrder: 'AGG(INDEX())',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class NineGLineChartTotalTitleModel implements IDataMapping<NineGLineChartTotalTitleModel> {
    public Title: string = '';
    public getMappings?() {
        return {
            Title: '9G Titles (MS Total)',
        };
    }
}

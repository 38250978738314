import pptxgen from 'pptxgenjs';
import { RelativeNPSRowDataModel, TableCellModel} from '../../../';
import { TablePptxService } from '../../TablePptxService';
import { HSFirstSlideRenderer } from '../../Renderers/HSFirstSlideRenderer';
import { PptxRenderHelper } from '../../../helpers/PptxRenderHelper';

export class RelativeNPSLeaderFollowerPptxService extends TablePptxService {
    protected firstSlideRenderer = new HSFirstSlideRenderer();
    protected chartQuestion: string = 'NPS question: Based on your experience on using their product and services, how likely are you to recommend this company?';
    private rowsCountOnSlide: number = 14;
    private businessGroupLabel: string = 'BusinessGroup';
    private modalityLabel: string = 'Modality';
    private colorsByValue: any = {
        'Leader': this.colors.positive,
        'Co-Leader': this.colors.white,
        'Follower': this.colors.negative
    };

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const sheetData = await this.getChartData('Philips Relative NPS');
        const chartFooterText = await this.getChartData('Calc Countrty agg info');
        const matrixTextLegend = await this.getChartData('Matrix text legend');

        if (!sheetData) {
            return;
        }

        const npsDataModels: RelativeNPSRowDataModel[] = this.processData(sheetData);

        const headers = Object.keys(npsDataModels[0]).map(x => {
            if (x === this.businessGroupLabel) {
                return 'Business group';
            } else if (x === 'SubModality') {
                return 'Sub Modality';
            }

            return x;
        });

        const colW = [1.5, 1.5, ...Array.createWithNullValues(Object.keys(npsDataModels[0]).length - 2).map(x => 0.5)];
        const tableWidth = colW.sum();
        const options: pptxgen.TableProps = {
            x: (10 - tableWidth) / 2,
            y: 1,
            w: tableWidth,
            rowH: 0.15,
            margin: [0, 0, 0, 5],
            colW
        };

        const splitedData: any[] = this.getSplitedData(npsDataModels);
        const headerRow: pptxgen.TableRow = this.getHeaderRow(headers);
        const footerText: string = this.processFooterText(chartFooterText);
        const statusText: string = this.processFooterText(matrixTextLegend);

        splitedData.forEach((d, index) => {
            const currentSlide = this.getCurrentSlide(chartSlide, index);
            const bodyRows: pptxgen.TableRow[] = this.getBodyRows(d);
            this.addTable(currentSlide, [headerRow, ...bodyRows], options);
            this.addLegend(currentSlide, Number(options.x), footerText, statusText);
        });

    }

    private getCurrentSlide(chartSlide: pptxgen.Slide, index: number) {
        let currentSlide = chartSlide;
        if (index !== 0) {
            currentSlide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });
            this.setChartSlideLayout(currentSlide);
        }
        return currentSlide;
    }

    private getSplitedData(npsDataModels: RelativeNPSRowDataModel[]) {
        const groupedByBusinessGroup: any = {};

        npsDataModels.forEach((d) => {
            if (!groupedByBusinessGroup[d.BusinessGroup.text]) {
                groupedByBusinessGroup[d.BusinessGroup.text] = [];
            }

            groupedByBusinessGroup[d.BusinessGroup.text].push(d);
        });

        var sortedGroupContainer = [];
        for (var m in groupedByBusinessGroup) {
            var elements: RelativeNPSRowDataModel[] = groupedByBusinessGroup[m];
            var sortedElements: RelativeNPSRowDataModel[] =
                elements
                    .sort((a, b) => Number(a.SubModality.options.rowIndex) > Number(b.SubModality.options.rowIndex) ? 1 : -1);
            sortedGroupContainer.push({ order: sortedElements.find((e: RelativeNPSRowDataModel) => { return e.SubModality.options.rowIndex != "null" })?.BusinessGroup.options.rowIndex, groupName: m, elements: groupedByBusinessGroup[m] });
        }

        const groupedByBusinessGroupSorted: any = {};

        sortedGroupContainer.sort((a, b) => (Number(a.order) > Number(b.order)) ? 1 : -1).forEach(x => {
            groupedByBusinessGroupSorted[x.groupName] = x.elements;
        });

        const splitedData: any[] = [{}];
        let slide = 0;
        let currentRowsCountOnSlide = 0;

        for (const key in groupedByBusinessGroupSorted) {
            if (Object.prototype.hasOwnProperty.call(groupedByBusinessGroupSorted, key)) {
                if (currentRowsCountOnSlide + groupedByBusinessGroupSorted[key].length > this.rowsCountOnSlide) {
                    const leftRowsCountOnSlide = this.rowsCountOnSlide - currentRowsCountOnSlide;
                    if (leftRowsCountOnSlide !== 0) {
                        const current = groupedByBusinessGroupSorted[key].slice(0, leftRowsCountOnSlide);
                        splitedData[slide][key] = current;
                    }

                    const next = groupedByBusinessGroupSorted[key].slice(leftRowsCountOnSlide);
                    slide++;
                    splitedData.push({});
                    splitedData[slide][key] = next;
                    currentRowsCountOnSlide = next.length;
                } else {
                    splitedData[slide][key] = groupedByBusinessGroupSorted[key];
                    currentRowsCountOnSlide += groupedByBusinessGroupSorted[key].length;
                }
            }
        }

        return splitedData;
    }

    private processFooterText(sheetData: any[]) {
      let text: string = "";

      sheetData.forEach((rowArray: any[]) => { 
        text = rowArray[0].formattedValue;
      })
      return text
    }

    private processData(sheetData: any[]) {
        const dataModels: RelativeNPSRowDataModel[] = [];
        const businessGroupIndex: number = 0;
        const modalityIndex: number = 1;
        const subModalityIndex: number = 2;
        const countryNameIndex: number = 3;
        const bgColorIndex: number = 5;
        const subModalityOrderIndex: number = 6;
        const countryOrderIndex: number = 7;
        const arrowIndex: number = 10;
        const valueIndex: number = 12;

        const countryOrder = this.getCountryOrder(sheetData, countryNameIndex, countryOrderIndex);
        sheetData.forEach((rowArray: any[]) => {
            const businessGroup = rowArray[businessGroupIndex].formattedValue;
            const modality = rowArray[modalityIndex].formattedValue;
            const subModality = rowArray[subModalityIndex].formattedValue;

            const records = dataModels.filter((x: RelativeNPSRowDataModel) => x.BusinessGroup.text === businessGroup && x.Modality.text === modality && x.SubModality.text == subModality);
            let current: RelativeNPSRowDataModel;
            if (records.length < 1) {
                current = new RelativeNPSRowDataModel(businessGroup,
                    modality,
                    subModality,
                    { fill: { color: this.colors.white }, align: 'left', rowIndex: rowArray[subModalityOrderIndex].value });
                countryOrder.map(c => { current[c as keyof RelativeNPSRowDataModel] = new TableCellModel() });

                dataModels.push(current);
            } else {
                current = records[0];
            }
            const countryName = rowArray[countryNameIndex].formattedValue
            const country = countryName as keyof RelativeNPSRowDataModel;

            const bgColor = this.colorsByValue[rowArray[bgColorIndex].value];
            const character = this.arrowsByValue[rowArray[arrowIndex].formattedValue];
            if (!current[country]) {
                current[country] = new TableCellModel();
            }

            if (!current[country].options) {
                current[country].options = { margin: [0, 3, 0, 0], color: undefined, fill: { color: bgColor }, align: 'right', bullet: false };
            }

            if (character) {
                const bullet = { characterCode: character.code, indent: 15 };
                current[country].options.bullet = bullet;
            } else {
                const value = rowArray[valueIndex].value;
                const displayValue = value !== null && !isNaN(+value) ? `${(+value * 100).toFixed()}%` : '';
                current[country].text = displayValue;
            }
        });

        return dataModels;
    }

    private getBodyRows(npsDataModelsGrouped: any[]) {
        const grouped: any = {};

        for (const key in npsDataModelsGrouped) {
            var npsModels: RelativeNPSRowDataModel[] = npsDataModelsGrouped[key];
            npsModels = npsModels.sort((a, b) => (Number(a.SubModality.options.rowIndex) > Number(b.SubModality.options.rowIndex)) ? 1 : -1);

            const modalityGroup: any = {};
            npsModels.forEach((d) => {
                if (!modalityGroup[d.Modality.text]) {
                    modalityGroup[d.Modality.text] = [];
                }

                modalityGroup[d.Modality.text].push(d);
            });

            grouped[key] = modalityGroup;
        }

        const rows: pptxgen.TableRow[] = [];
        for (const bgKey in grouped) {
            if (Object.prototype.hasOwnProperty.call(grouped, bgKey)) {
                let subModalitiesCount = 0;
                for (const modalityKey in grouped[bgKey]) {
                    subModalitiesCount += grouped[bgKey][modalityKey].length;
                }

                var setParent = false;
                for (const modalityKey in grouped[bgKey]) {
                    const currentRows: pptxgen.TableRow[] = grouped[bgKey][modalityKey].map((d: RelativeNPSRowDataModel, index: number) => {
                        var subModalitySet = false;
                        if (index === 0) {
                            var dataObjects = Object.keys(d);
                            if (setParent) {
                                dataObjects = Object.keys(d).filter(x => x !== this.businessGroupLabel);
                            }

                            if (subModalitySet) {
                                dataObjects = dataObjects.filter(x => x !== this.modalityLabel);
                            }

                            return dataObjects.map(k => {
                                const prop: TableCellModel = d[k as keyof RelativeNPSRowDataModel];
                                if (k === this.businessGroupLabel && !setParent) {
                                    setParent = true;
                                    return { text: prop.text, options: { ...prop.options, rowspan: subModalitiesCount } }
                                }
                                else if (k === this.modalityLabel && !subModalitySet) {
                                    subModalitySet = true;
                                    return { text: prop.text, options: { ...prop.options, rowspan: grouped[bgKey][modalityKey].length } }
                                }

                                return { ...prop };
                            });
                        }
                        else {
                            return Object.keys(d).filter(x => x !== this.businessGroupLabel && x !== this.modalityLabel).map(k => {
                                const prop: TableCellModel = d[k as keyof RelativeNPSRowDataModel];

                                return { ...prop };
                            });
                        }
                    });

                    rows.push(...currentRows);
                }
            }
        }

        return rows;
    }

    private addLegend(chartSlide: pptxgen.Slide, legendX: number, footerText: string, statusText: string) {
        chartSlide.addText(
            [
                { text: statusText, options: { breakLine: true } },
                { text: '', options: { breakLine: true } },
                { text: ' ', options: { bullet: { characterCode: this.bullets.Up.code }, color: this.bullets.Up.color } },
                { text: 'Philips shows improved leadership status since last FY period (is no longer Follower or has become Leader)', options: { breakLine: true } },
                { text: ' ', options: { bullet: { characterCode: this.bullets.Down.code }, color: this.bullets.Down.color } },
                { text: 'Philips shows deteriorated leadership status since last FY period (is no longer Leader or has become Follower', options: { breakLine: true } },
                { text: footerText, options: { bold: true }},
            ],
            { x: legendX, y: 4.5, w: 6.5, fontSize: 8, color: this.colors.default }
        );

        PptxRenderHelper.renderSquareLegend(chartSlide, { label: `Leader`, color: this.colorsByValue.Leader }, { x: 7, y: 4.53 });
        PptxRenderHelper.renderSquareLegend(chartSlide, { label: `Follower`, color: this.colorsByValue.Follower }, { x: 8, y: 4.53 });
    }

    private getCountryOrder(sheetData: any[], countryNameIndex: number, countryOrderIndex: number) {
        const countryOrder: [string, number][] = [];
        sheetData.forEach((rowArray: any[]) => {
            const countryName = rowArray[countryNameIndex].formattedValue;
            var result = countryOrder.find(x => x[0] == countryName);
            if (!result) {
                countryOrder.push([countryName, Number(rowArray[countryOrderIndex].formattedValue)]);
            }
        });

        return countryOrder.sort((a, b) => a[1] - b[1]).map(x => { return x[0]; });
    }
}

import { IDataMapping } from '../../../../IDataMapping';

export class PHBSMarketPerformanceChartBarDataModel implements IDataMapping<PHBSMarketPerformanceChartBarDataModel> {
    public Answer:          string = '';
    public FilterAudience:  string = '';
    public Color:           string = '';
    public Period:          string = '';
    public Brand:           string = '';
    public Value:           number = Number(0);
    public Factor:          string = '';

    public getMappings?() {
        return {
            Answer:         'Answer',
            FilterAudience: 'ATTR(Audience)',
            Color:          'ATTR(KPIs_bar_color)',
            Period:         'year_period',
            Brand:          'ATTR(Brand)',
            Value:          'AVG(Value)',
            Factor:         'ATTR(BS_statement)'
        };
    }
}

import React from 'react';
import { ISVGProps } from './ISVGProps';

export const PDFSvg = (props: ISVGProps) => {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 11H8V9H9C9.28333 9 9.521 8.904 9.713 8.712C9.90433 8.52067 10 8.28333 10 8V7C10 6.71667 9.90433 6.479 9.713 6.287C9.521 6.09567 9.28333 6 9 6H7V11ZM8 8V7H9V8H8ZM11 11H13C13.2833 11 13.521 10.904 13.713 10.712C13.9043 10.5207 14 10.2833 14 10V7C14 6.71667 13.9043 6.479 13.713 6.287C13.521 6.09567 13.2833 6 13 6H11V11ZM12 10V7H13V10H12ZM15 11H16V9H17V8H16V7H17V6H15V11ZM6 16C5.45 16 4.97933 15.8043 4.588 15.413C4.196 15.021 4 14.55 4 14V2C4 1.45 4.196 0.979 4.588 0.587C4.97933 0.195667 5.45 0 6 0H18C18.55 0 19.021 0.195667 19.413 0.587C19.8043 0.979 20 1.45 20 2V14C20 14.55 19.8043 15.021 19.413 15.413C19.021 15.8043 18.55 16 18 16H6ZM6 14H18V2H6V14ZM2 20C1.45 20 0.979333 19.8043 0.588 19.413C0.196 19.021 0 18.55 0 18V4H2V18H16V20H2ZM6 2V14V2Z" fill="var(--ribbon-button-color, #0055A6)" />
    </svg>
  );
};

import pptxgen from 'pptxgenjs';

export const ChartColors = {
  default: '7f7f7f',
  border: 'cccccc',
  main: '4fb5ae',
  white: 'ffffff',
  black: '000000',
  neutral: 'd9d9d9',
  positive: '8ccd81',
  lightPositive: 'a2be82',
  ultraLightPositive: 'b5cb9e',
  negative: 'ef7c7f',
  lightNegative: 'ec9fa1',
  ultraLightNegative: 'e6c1c3',
  yellow: 'ffa600',
  grey: 'aca29e'
};
const fontFace = 'Calibri (Body)';
const chartTextFontSize = 8;

export const FontOptions: pptxgen.IChartOpts = {
  titleFontFace: fontFace,
  legendFontFace: fontFace,
  dataLabelFontFace: fontFace,
  catAxisLabelFontFace: fontFace,
  catAxisTitleFontFace: fontFace,
  serAxisLabelFontFace: fontFace,
  serAxisTitleFontFace: fontFace,
  valAxisLabelFontFace: fontFace,
  valAxisTitleFontFace: fontFace,
  titleFontSize: 12,
  catAxisTitleFontSize: chartTextFontSize,
  catAxisLabelFontSize: chartTextFontSize,
  valAxisTitleFontSize: chartTextFontSize,
  valAxisLabelFontSize: chartTextFontSize,
  legendFontSize: chartTextFontSize,
  dataLabelColor: ChartColors.white,
  dataLabelFontSize: 6,
  dataLabelFontBold: true,
  titleColor: ChartColors.default,
};

export const ChartGridOptions: pptxgen.IChartOpts = {
  catGridLine: { style: 'none' },
  valGridLine: { style: 'none' },
};

export const ChartPercentageOptions: pptxgen.IChartOpts = {
  catLabelFormatCode: '#%',
  valAxisLabelFormatCode: '#%',
  dataLabelFormatCode: '#%',
};

export const BaseChartOptions: pptxgen.IChartOpts = {
  ...FontOptions,
  ...ChartGridOptions
};

import { IDataMapping } from '../../../../IDataMapping';

export class SPVendorPieChartDataModel implements IDataMapping<SPVendorPieChartDataModel> {
    public Question: string = '';
    public Brand: string = '';
    public AveragePositive: number | null = 0;
    public AverageNegative: number | null = 0;
    public Color: string = '';
    public Order: number | null = 0;
    public Value: number | null = 0;
    public Sum: number | null = 0;
    public getMappings?() {
        return {
            Question: 'Question',
            Brand: 'Calc Others Pie',
            AveragePositive: 'AGG(Sample Size Color <50 BLACK)',
            AverageNegative: 'AGG(Sample Size Color <50 RED)',
            Color: 'ATTR(Calc Brand Colors)',
            Order: 'ATTR(Calc Brand Order)',
            Value: 'MIN(1)',
            Sum: 'SUM(%)',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class BMCPreferenceScoreGlobalDataModel implements IDataMapping<BMCPreferenceScoreGlobalDataModel> {
    public Text: string = '';
    public Value: string = '';
    public getMappings?() {
        return {
            Text: 'Global',
            Value: 'SUM(Value (Parameter))',
        };
    };
};

import { TableauSheetType } from '../../shared/enums/TableauSheetType';
import { IDataMapping } from '../models/IDataMapping';

export class TableauApiHelper {
    public static async getMappedChartData<T extends IDataMapping<{}>>(mappedType: new () => T, view: any, sheetName: string): Promise<T[]> {
        const dataTable = await TableauApiHelper.getDataTable(view, sheetName);
        if (dataTable) {
            const columns = dataTable.getColumns();
            const columnIndices: { [key: string]: number } = {};
            columns.forEach((c: any) => columnIndices[c.getFieldName()] = c.getIndex());
            const data = dataTable.getData();

            const result: T[] = [];

            for (const d of data) {
                const mappedInstance: T = new mappedType();
                for (const key in mappedInstance) {
                    if (mappedInstance.hasOwnProperty(key) && mappedInstance.getMappings) {
                        const mapping = mappedInstance.getMappings();
                        const columnName = mapping.hasOwnProperty(key) ? mapping[key as never] : '';
                        if (columnIndices.hasOwnProperty(columnName)) {
                            const type = typeof mappedInstance[key];
                            const value = (type == 'number' || type == 'bigint') ? d[columnIndices[columnName]].value : d[columnIndices[columnName]].formattedValue;
                            mappedInstance[key] = TableauApiHelper.mapSimpleValue(mappedInstance[key], value);
                        }
                    }
                }

                result.push(mappedInstance);
            }

            return result;
        } else {
            return [];
        }
    }

    public static async getChartData(view: any, sheetName: string) {
        const dataTable = await TableauApiHelper.getDataTable(view, sheetName);
        console.log("dataTable", dataTable)
        if (dataTable) {
            const data = dataTable.getData();
            return data;
        } else {
            return [];
        }
    }

    public static async getFilterOptions(view: any, sheetName: string, filterName: string) {
        const sheet = await TableauApiHelper.getSheet(view, sheetName);
        if (sheet) {
            const filters: any[] = await sheet.getFiltersAsync();
            const currentFilter = filters.find(f => f.getFieldName() === filterName);
            if (currentFilter && currentFilter.$9 && Array.isArray(currentFilter.$9) && currentFilter.$9.length) {
                return (currentFilter.$9 as any[]).map(f => f.formattedValue);
            }
        }

        return [];
    }

    public static async getSheet(view: any, sheetName: string) {
        const sheets = await TableauApiHelper.getSheets(view);
        return sheets.find(x => x.getName() === sheetName);
    }

    public static async getDataTable(view: any, sheetName: string) {
        const sheet = await TableauApiHelper.getSheet(view, sheetName);
        if (sheet) {
            const options = {
                maxRows: 0,
                ignoreAliases: false,
                ignoreSelection: false,
                includeAllColumns: false,
            };

            const dataTable = await sheet.getSummaryDataAsync(options);
            return dataTable;
        } else {
            return null;
        }
    }

    public static async getSheets(view: any) {
        const workbook = view.getWorkbook();

        const activeSheet = workbook.getActiveSheet();
        const sheetType = activeSheet.getSheetType();

        let sheets: any[] = [];
        if (sheetType === TableauSheetType.Dashboard) {
            sheets = activeSheet.getWorksheets();

        } else if (sheetType === TableauSheetType.Story) {
            const activeStoryPoint = activeSheet.getActiveStoryPoint();
            const containedSheet = activeStoryPoint.getContainedSheet();
            sheets = containedSheet.getWorksheets();
        }

        return sheets;
    }

    private static mapSimpleValue(destinationValue: any, sourceValue: any): any {
        switch (typeof destinationValue) {
            case 'string':
                return sourceValue && sourceValue !== 'Null' ? sourceValue.toString() : null;
            case 'number':
            case 'bigint':
                const unformatted = sourceValue ? sourceValue.replace(/,/g, '').replace(/%/g, '') : null;
                return unformatted !== null && !isNaN(+unformatted) ? +unformatted : null;
            case 'boolean':
                return sourceValue ? sourceValue : false;
            default:
                return null;
        }
    }
}

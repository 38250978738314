import { IDataMapping } from '../../IDataMapping';

export class MarketOverviewTitleModel implements IDataMapping<MarketOverviewTitleModel> {
    public Title: string = '';
    public SubTitle: string = '';
    public getMappings?() {
        return {
            Title: 'MOTitle',
            SubTitle: 'MOTitle (2)',
        };
    }
}

import React from 'react';
import { ISVGProps } from './ISVGProps';

export const FolderSvg = (props: ISVGProps) => {
  const { width, height } = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 22 20'
    >
      <defs>
        <style>
          {
            '.a{fill:#fff;stroke:#212529;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}'
          }
        </style>
      </defs>
      <path
        className='a'
        d='M22,19a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V5A2,2,0,0,1,4,3H9l2,3h9a2,2,0,0,1,2,2Z'
        transform='translate(-1 -2)'
      />
    </svg>
  );
};

import { IDataMapping } from '../../../../IDataMapping';

export class NpsByBusinessGroupChartDataModel implements IDataMapping<NpsByBusinessGroupChartDataModel> {
    public country: string = '';
    public period: string = '';
    public businessGroup: string = '';
    public columnOrder: number = 0;
    public rowOrder: number = 0;
    public subRowOrder: number = 0;
    public leadershipValue: number = 0;
    public value: number = 0;

    public getMappings?() {
        return {
            country: 'Country',
            period: 'Interval',
            businessGroup: 'Business group ',
            columnOrder: 'Global BMC Matrix Order for BGs',
            rowOrder: 'Global BMC Matrix Order for Coutries',
            subRowOrder: 'AGG(Index Interval)',
            leadershipValue: 'AVG(TC_Leadership Color)',
            value: 'AVG(Value)',
        };
    }
}

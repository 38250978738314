import pptxgen from 'pptxgenjs';

import { BasePptxService } from '../../../../BasePptxService';
import { HSBSFirstSlideRenderer } from '../../../../Renderers/HSBSFirstSlideRenderer';
import { PptxRenderHelper } from '../../../../../helpers/PptxRenderHelper';

import { BSAnalysisByAudienceTableTitleModel } from '../../../../../models/HealthSystems/BrandStrength/MarketBrandStrength/BSAnalysisByAudienceTable/BSAnalysisByAudienceTableTitleModel';
import { BSAnalysisByAudienceTableHeaderRowModel } from '../../../../../models/HealthSystems/BrandStrength/MarketBrandStrength/BSAnalysisByAudienceTable/BSAnalysisByAudienceTableHeaderRowModel';
import { BSAnalysisByAudienceTableDataModel } from '../../../../../models/HealthSystems/BrandStrength/MarketBrandStrength/BSAnalysisByAudienceTable/BSAnalysisByAudienceTableDataModel';

export class BSAnalysisByAudienceTableService extends BasePptxService {
    protected firstSlideRenderer: HSBSFirstSlideRenderer = new HSBSFirstSlideRenderer();
    protected chartQuestion: string = '';
    protected titleSheet: string;
    protected shapesSheet: string;
    protected factorDataSheet: string;
    protected scoreDataSheet: string;
    protected targetDataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, shapesSheet: string,
        factorDataSheet: string, scoreDataSheet: string, targetDataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.shapesSheet = shapesSheet;
        this.factorDataSheet = factorDataSheet;
        this.scoreDataSheet = scoreDataSheet;
        this.targetDataSheet = targetDataSheet;
    }

    // Override of a super class method
    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const titleData = await this.getMappedChartData(BSAnalysisByAudienceTableTitleModel, this.titleSheet);

        let subTitle: string = "";
        titleData.forEach(obj => {
            this.chartTitle = `${obj.Title}`;
            subTitle = obj.SubTitle;
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.55, bold: true });
        slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 12, lineSpacing: 12 } }], { x: 0.6, y: 0.9 });
        slide.addText([{ text: this.chartQuestion, options: { color: this.colors.black, fontSize: 7 } }], { x: 0.5, y: '95%', bold: false });
    }

    // Override of a super class method
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const headerRowData = await this.getMappedChartData(BSAnalysisByAudienceTableHeaderRowModel, this.shapesSheet);
        headerRowData.reverse();

        const mappedFactorData = await this.getMappedChartData(BSAnalysisByAudienceTableDataModel, this.factorDataSheet);
        const mappedScoreData = await this.getMappedChartData(BSAnalysisByAudienceTableDataModel, this.scoreDataSheet);
        const mappedTargetData = await this.getMappedChartData(BSAnalysisByAudienceTableDataModel, this.targetDataSheet);

        if (mappedFactorData.length > 0) {

            // sorting and filtering table data
            const factors = this.getAllValuesByProperty(mappedFactorData, "Factor");

            const groupData = this.groupDataByRows(mappedFactorData, mappedScoreData, mappedTargetData);

            const numberOfPeriods = this.getAllValuesByProperty(mappedFactorData, "Date").length;
            const tables = this.splitRowsByTables(groupData, numberOfPeriods === 1 ? 8 : 10);

            // execution
            tables.forEach((table, index) => {
                const currentSlide = this.getCurrentSlide(chartSlide, index);

                this.addTable(currentSlide, table, factors);
                this.addLegend(currentSlide);
            });
        }
    }

    private getCurrentSlide(chartSlide: pptxgen.Slide, index: number) {
        let currentSlide = chartSlide;

        if (index !== 0) {
            currentSlide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });
            this.setChartSlideLayout(currentSlide);
        }

        return currentSlide;
    }

    private getAllValuesByProperty(data: BSAnalysisByAudienceTableDataModel[], property: string) {
        const values: any[] = [];

        data.forEach((obj: BSAnalysisByAudienceTableDataModel) => {
            if (!values.includes(obj[property as keyof BSAnalysisByAudienceTableDataModel])) {
                values.push(obj[property as keyof BSAnalysisByAudienceTableDataModel]);
            }
        });

        return values;
    }

    private groupByProperty(array: BSAnalysisByAudienceTableDataModel[], property: string) {
        const groupData: BSAnalysisByAudienceTableDataModel[][] = [];

        // Grouping the data in rows
        for (let obj of array) {
            let flagMakeNewGroup = true;
            // Checks If there is group/array for this object
            for (let arr of groupData) {
                for (let element of arr) {
                    if (element[property as keyof BSAnalysisByAudienceTableDataModel] === obj[property as keyof BSAnalysisByAudienceTableDataModel]) {
                        arr.push(obj);
                        flagMakeNewGroup = false;
                    }
                    break;
                }
            }
            // Creates new group/array
            if (flagMakeNewGroup) {
                groupData.push([obj]);
            }
        }

        return groupData;
    }

    private getHeaderTableRow(data: string[]) {
        const factors = ["", "MAT", ...data, "Brand Strength Score", "vs Target"];

        const tableRow: pptxgen.TableRow[] = [];
        const tableCells: pptxgen.TableCell[] = [];

        // header row
        factors.forEach((factorName: string) => {
            tableCells.push(
                {
                    text: factorName,
                    options: {
                        bold: true,
                        fill: { color: this.colors.default },
                        color: this.colors.white,
                        border:
                            [
                                { type: 'solid', pt: 0.5, color: this.colors.border },
                                { type: 'solid', pt: 0.5, color: this.colors.white },
                                { type: 'solid', pt: 0.5, color: this.colors.border },
                                { type: 'solid', pt: 0.5, color: this.colors.white },
                            ]
                    }
                }
            );
        });
        tableRow.push(tableCells);

        return tableRow;
    }

    private groupDataByRows(factorData: BSAnalysisByAudienceTableDataModel[], scoreData: BSAnalysisByAudienceTableDataModel[], targetData: BSAnalysisByAudienceTableDataModel[]) {

        // 1st step - Grouping the data by Category property

        // adding factor data
        const categoryGroupsData: BSAnalysisByAudienceTableDataModel[][] = [];
        for (let obj of factorData) {
            let flagMakeNewGroup = true;
            // Checks If there is group/array for this object
            for (let arr of categoryGroupsData) {
                for (let element of arr) {
                    if (element.Category === obj.Category) {
                        arr.push(obj);
                        flagMakeNewGroup = false;
                    }
                    break;
                }
            }
            // Creates new group/array
            if (flagMakeNewGroup) {
                categoryGroupsData.push([obj]);
            }
        }

        // adding score data
        scoreData.forEach((obj) => {

            for (let arr of categoryGroupsData) {
                for (let element of arr) {
                    if (element.Category === obj.Category) {
                        arr.push(obj);
                    }
                    break;
                }
            }
        });

        // adding target data
        targetData.forEach((obj) => {

            for (let arr of categoryGroupsData) {
                for (let element of arr) {
                    if (element.Category === obj.Category) {
                        arr.push(obj);
                    }
                    break;
                }
            }

        });

        // 2nd step - Grouping the data in rows
        const groupData: BSAnalysisByAudienceTableDataModel[][] = [];
        for (let categoryGroup of categoryGroupsData) {
            categoryGroup.sort((a, b) => b.TimeRank - a.TimeRank);
            const rows: BSAnalysisByAudienceTableDataModel[][] = this.groupByProperty(categoryGroup, "TimeRank");

            rows.forEach((row) => {
                groupData.push(row);
            });
        }

        return groupData;
    }

    private splitRowsByTables(groupData: BSAnalysisByAudienceTableDataModel[][], rowsPerTable: number) {
        const tables: BSAnalysisByAudienceTableDataModel[][][] = [];

        // full tables
        const numberOfFullTables = Math.trunc(groupData.length / rowsPerTable);
        for (let i = 0; i < numberOfFullTables; ++i) {
            tables.push(groupData.slice((i * rowsPerTable), (i * rowsPerTable) + rowsPerTable));
        }

        // last smaller table
        const lastTable = groupData.length % rowsPerTable;
        if (lastTable) {
            tables.push(groupData.slice((groupData.length - lastTable), groupData.length));
        }

        return tables;
    }

    private countRowspan(groupData: BSAnalysisByAudienceTableDataModel[][], currentIndex: number, objectKey: string) {
        let rowspan = 1;

        let currentGroup;
        groupData[currentIndex].forEach(obj => {
            currentGroup = obj[objectKey as keyof BSAnalysisByAudienceTableDataModel];
        })

        for (let row = (currentIndex + 1); row < groupData.length; ++row) {
            let nextGroup;
            groupData[row].forEach(obj => {
                nextGroup = obj[objectKey as keyof BSAnalysisByAudienceTableDataModel];
            })

            if (currentGroup === nextGroup) {
                ++rowspan;
            }
        }

        return rowspan;
    }

    private getBodyTableRows(groupData: BSAnalysisByAudienceTableDataModel[][]) {
        let tableRows: pptxgen.TableRow[] = [];
        let usedCategory: string;

        groupData.forEach((row, index) => {
            let currentCategory: string = "";
            let currentPeriod: string = "";
            const values: pptxgen.TableCell[] = [];

            // value rows
            row.forEach(obj => {
                if (obj.Value) {
                    let fontColor = obj.TargetColor ? obj.TargetColor : this.colors.black;
                    let backGroundColor = obj.Color ? obj.Color : this.colors.white;

                    values.push(
                        {
                            text: obj.Value.toFixed(1),
                            options: { margin: [0, 3, 0, 0], color: fontColor, fill: { color: backGroundColor }, align: 'center' }
                        }
                    );
                } else {
                    values.push(
                        {
                            text: "",
                            options: { margin: [0, 3, 0, 0], align: 'center' }
                        }
                    );
                }

                currentCategory = obj.Category;
                currentPeriod = obj.Date;
            });

            // Adding category and period cells to the rows
            let tableCells: pptxgen.TableCell[] = [];

            if (tableRows.length === 0) {

                // first row only
                const rowspan = this.countRowspan(groupData, index, "Category");
                tableCells.push(
                    {
                        text: currentCategory,
                        options: { color: this.colors.black, fill: { color: this.colors.white }, align: 'center', rowspan: rowspan }
                    },
                    {
                        text: currentPeriod,
                        options: { color: this.colors.black, fill: { color: this.colors.white }, align: 'center' }
                    },
                    ...values
                );

            } else {
                if (currentCategory !== usedCategory) {
                    const rowspan = this.countRowspan(groupData, index, "Category")
                    tableCells.push(
                        {
                            text: currentCategory,
                            options: { color: this.colors.black, fill: { color: this.colors.white }, align: 'center', rowspan }
                        }
                    );
                }

                tableCells.push(
                    {
                        text: currentPeriod,
                        options: { color: this.colors.black, fill: { color: this.colors.white }, align: 'center' }
                    },
                    ...values
                );
            }

            tableRows.push(tableCells);
            usedCategory = currentCategory;
        });

        return tableRows;
    }

    private addTable(chartSlide: pptxgen.Slide, table: BSAnalysisByAudienceTableDataModel[][], factors: string[]) {
        const headerData = this.getHeaderTableRow(factors);
        const bodyData = this.getBodyTableRows(table);

        const options: pptxgen.TableProps = {
            y: 1.1,
            w: '92%',
            align: 'center',
            valign: 'middle',
            border: { type: 'solid', color: this.colors.neutral, pt: 0.2 },
            fontSize: 7,
            rowH: 0.35,
        };

        chartSlide.addTable([...headerData, ...bodyData], options);
    }

    private addLegend(chartSlide: pptxgen.Slide) {
        PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: "≤ 6.0", color: this.colors.negative }, { x: 1, y: 5.1 });
        PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: "> 6.0 < 7.5", color: "#FDE497" }, { x: 2, y: 5.1 });
        PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: "≥ 7.5", color: this.colors.positive }, { x: 3, y: 5.1 });
        PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: "Previous MAT", color: "#B6B6B6" }, { x: 4, y: 5.1 });
    }
}

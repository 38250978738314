import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IState } from '../../../store/IState';
import { UserStatus } from '../../../shared/enums/UserStatus';

import { IAddDialogProps } from './IAddDialogProps';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import TextFieldWrapper from "../../../shared/UI/TextFieldWrapper";
import AutoCompleteWrapper from "../../../shared/UI/AutoCompleteWrapper";
import MultiSelectWrapper from "../../../shared/UI/MultiSelectWrapper";
import SingleSelectWrapper from "../../../shared/UI/SingleSelectWrapper";

import validateHelper from "../../../shared/UI/validateHelper";

import styles from './AddDialog.module.scss';
import { ModelStateErrorModel } from '../../../models/ModelStateErrorModel';
import { UserStatusOptions } from '../../../shared/constants';
import { useQueryCountries } from '../hooks/useQueryCountries';
import api from '../../../helpers/Api';
import layoutMessage from '../../../helpers/LayoutMessage';

import {
  setFirstName,
  setLastName,
  setEmail,
  setCompany,
  setDepartment,
  setPosition,
  setSite,
  setUserRoles,
  setUserStatus,
  setCountry,
  resetState
} from "../../../store/addUser/AddUserActions";

export const AddDialog = (props: IAddDialogProps): JSX.Element => {
  const dispatch = useDispatch();

  const { isOpen, roleOptions, handleCloseDialog, handleRefreshUsers } = props;
  const [errors, setErrors] = useState<ModelStateErrorModel[]>([]);
  const [countryQuery, setCountryQuery] = useState<any>(undefined);
  const [countryValue, setCountryValue] = useState<string>("");
  const [validateFirstName, setValidateFirstName] = useState<boolean>(false);
  const [validateLastName, setValidateLastName] = useState<boolean>(false);
  const [validateEmail, setValidateEmail] = useState<boolean>(false);
  const [validateCompany, setValidateCompany] = useState<boolean>(false);
  const [validateUserRoles, setValidateUserRoles] = useState<boolean>(false);

  const countriesResults = useQueryCountries(countryQuery, 400);

  const AddUserReducer: any = useSelector(
    (state: IState) => state.AddUserReducer
  );

  const onDialogHide = () => {
    handleCloseDialog();
    setCountryQuery(undefined);
    setErrors([]);
    dispatch(resetState(''));
    setValidateFirstName(false);
    setValidateLastName(false);
    setValidateEmail(false);
    setValidateCompany(false);
    setValidateUserRoles(false);
  };

  const onCountryChange = (country: any) => {
    if(country === null) {
      setCountryQuery("");
      dispatch(setCountry(0))
    } else {
      if (typeof country === 'string') {
        setCountryQuery(country);
      } else {
        setCountryQuery(country.label);
        dispatch(setCountry(country.value))
      }
    }
  };

  const onCreateUserSuccess = () => {
    handleCloseDialog();
    handleRefreshUsers();
  };

  const onModelStateErrors = (errorObjects: any) => {
    setErrors(errorObjects);
  };

  const createUser = async () => {
    dispatch(setFirstName(AddUserReducer.firstName));
    setValidateFirstName(true);
    dispatch(setLastName(AddUserReducer.lastName));
    setValidateLastName(true);
    dispatch(setEmail(AddUserReducer.email));
    setValidateEmail(true);
    dispatch(setCompany(AddUserReducer.company));
    setValidateCompany(true);
    dispatch(setSite(AddUserReducer.site));
    dispatch(setUserRoles(AddUserReducer.userRoles));
    setValidateUserRoles(true);

    const getBooleansFromReducer = Object.fromEntries(
      Object.entries(AddUserReducer).filter(([key, value]) => typeof value === "boolean") 
    )
    const isError = Object.keys(getBooleansFromReducer).some(k => getBooleansFromReducer[k]);

    let invalidRole = false;
    if (AddUserReducer.userRoles.length !== 0) {
      const validRoleNames = roleOptions.map((role) => role.value);

      AddUserReducer.userRoles.forEach( (role: any) => {
        if (!validRoleNames.includes(role)) {
          invalidRole = true;
        }
      });
    }

    if (isError) {
      return false
    } else if (invalidRole) {
      layoutMessage.renderError(dispatch, `Invalid role selected`);
      return false;
    } else {
      api.post(
        'Users/Create',
        JSON.stringify(AddUserReducer),
        onCreateUserSuccess,
        onModelStateErrors,
        (errorMessage) => layoutMessage.renderError(dispatch, errorMessage)
      );
    }
  };

  const getErrors = () => {
    return (
      <div>
        {errors.map((e) =>
          e.errors.map((m) => <p className={styles.error_text}>{m}</p>)
        )}
      </div>
    );
  };

  const formFields = (
    <>
      {errors.length > 0 ? getErrors() : undefined}
      <div className={styles.form_row}>
        <div className={styles.form_col}>
          <TextFieldWrapper
            value={AddUserReducer.firstName}
            label="First Name*"
            onChange={(e: any) => {
              dispatch(setFirstName(e.target.value));
              setValidateFirstName(true);
            }}
            error={AddUserReducer.firstNameBool && validateFirstName}
            helperText={validateHelper('firstName', AddUserReducer.firstNameBool, validateFirstName)}
          />
        </div>
        <div className={styles.form_col}>
          <TextFieldWrapper
            value={AddUserReducer.lastName}
            label="Last Name*"
            onChange={(e: any) => {
              dispatch(setLastName(e.target.value));
              setValidateLastName(true);
            }}
            error={AddUserReducer.lastNameBool && validateLastName}
            helperText={validateHelper('lastName', AddUserReducer.lastNameBool, validateLastName)}
          />
        </div>
      </div>
      <div className={styles.form_row}>
        <div className={styles.form_col}>
          <TextFieldWrapper
            value={AddUserReducer.email}
            label="Email/Username*"
            onChange={(e: any) => {
              dispatch(setEmail(e.target.value));
              setValidateEmail(true);
            }}
            error={AddUserReducer.emailBool && validateEmail}
            helperText={validateHelper('email', AddUserReducer.emailBool, validateEmail)}
          />
        </div>
        <div className={styles.form_col}>
          <TextFieldWrapper
            value={AddUserReducer.company}
            label="Company*"
            onChange={(e: any) => {
              dispatch(setCompany(e.target.value));
              setValidateCompany(true);
            }}
            error={AddUserReducer.companyBool && validateCompany}
            helperText={validateHelper('company', AddUserReducer.companyBool, validateCompany)}
          />
        </div>
      </div>
      <div className={styles.form_row}>
        <div className={styles.form_col}>
          <TextFieldWrapper
            value={AddUserReducer.department}
            label="Department"
            onChange={(e: any) => {
              dispatch(setDepartment(e.target.value));
            }}
          />
        </div>
        <div className={styles.form_col}>
          <TextFieldWrapper
            value={AddUserReducer.position}
            label="Job Title"
            onChange={(e: any) => {
              dispatch(setPosition(e.target.value));
            }}
          />
        </div>
      </div>
      <div className={styles.form_row}>
        <div className={styles.form_col}>
          <AutoCompleteWrapper
            options={countriesResults}
            value={countryValue}
            onChange={onCountryChange}
            setValue={setCountryValue}
            label="Country"
          />
        </div>
        <div className={styles.form_col}>
          <MultiSelectWrapper
            htmlFor="userRoles"
            label="User Roles*"
            value={AddUserReducer.userRoles}
            onError={AddUserReducer.userRolesBool}
            validateError={validateUserRoles}
            validateHelper={validateHelper}
            roleOptions={roleOptions}
            setValidate={setValidateUserRoles}
            onChange={(event:React.ChangeEvent<{ value: any }>) => {
              dispatch(setUserRoles(event.target.value));
              setValidateUserRoles(true);
            }}
          />
        </div>
      </div>
      <div className={styles.form_row}>
        <div className={styles.form_col}>
          <TextFieldWrapper
            value={AddUserReducer.site}
            label={AddUserReducer.userStatus === UserStatus.Active ? 'Site*' : 'Site'}
            onChange={(e: any) => {
              dispatch(setSite(e.target.value));
            }}
            error={AddUserReducer.siteBool}
            helperText={validateHelper('site', AddUserReducer.siteBool, null)}
          />
        </div>
        <div className={styles.form_col}>
          <SingleSelectWrapper
            label="Status"
            value={AddUserReducer.userStatus}
            onChange={(event:React.ChangeEvent<{ value: any }>) => {
              dispatch(setUserStatus(event.target.value));
            }}
            options={UserStatusOptions}
          />
        </div>
      </div>
    </>
  );

  const footer = (
    <div className={styles.button_group}>
      <Button
        label={'CANCEL'}
        onClick={onDialogHide}
        className={styles.cancel_button}
      />
      <Button
        label={'ADD'}
        onClick={() => createUser()}
        className={styles.save_button}
      />
    </div>
  );

  return (
    <Dialog
      onHide={onDialogHide}
      visible={isOpen}
      header={'Add new user'}
      footer={footer}
      className={styles.default_dialog}
    >
      {formFields}
    </Dialog>
  );
};

import { IDataMapping } from '../../../IDataMapping';

export class PHBSTotalOverTimeChartDataModel implements IDataMapping<PHBSTotalOverTimeChartDataModel> {
    public Label: string = '';
    public Color: string = '';
    public Name: string = '';
    public Value: number = 0;
    public PeriodIndex: number = 0;
    public BrandOrder: number = 0;

    public getMappings?() {
        return {
            Label:          'QUARTER(Date)',
            Color:          'ATTR(Audience_color)',
            Name:           'Audience',
            Value:          'AVG(Value)',
            PeriodIndex:    'MIN(index_period)',
            BrandOrder:     'ATTR(Rank Audience)'
        };
    }
}

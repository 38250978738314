import pptxgen from 'pptxgenjs';

import { BMCPreferenceScoreTitleModel } from '../../../models/PersonalHealth/BMCPreferenceScore/BMCPreferenceScoreTitleModel';
import { BMCPreferenceScoreDataModel } from '../../../models/PersonalHealth/BMCPreferenceScore/BMCPreferenceScoreDataModel';

import { BMCPreferenceScoreGlobalDataModel } from '../../../models/PersonalHealth/BMCPreferenceScore/BMCPreferenceScoreGlobalDataModel';
import { BMCPreferenceScoreCountryDataModel } from '../../../models/PersonalHealth/BMCPreferenceScore/BMCPreferenceScoreCountryDataModel';
import { BMCPreferenceScoreCategoryDataModel } from '../../../models/PersonalHealth/BMCPreferenceScore/BMCPreferenceScoreCategoryDataModel';

import { PHFirstSlideRenderer } from '../../Renderers/PHFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

import { ChartsBase64 } from '../../../../shared/enums/ChartsBase64';

export class BMCPreferenceScoreService extends BasePptxService {
    protected chartQuestion: string = '';
    protected firstSlideRenderer: PHFirstSlideRenderer = new PHFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;
    protected globalDataSheet: string;
    protected countryDataSheet: string;
    protected categoryDataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string, 
        globalDataSheet: string, countryDataSheet: string, categoryDataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
        this.globalDataSheet = globalDataSheet;
        this.countryDataSheet = countryDataSheet;
        this.categoryDataSheet = categoryDataSheet;
    }

    protected async setChartSlideLayout(slide: pptxgen.Slide) {
      // Override title's options and add filter's name in it.
      const titleData = await this.getMappedChartData(BMCPreferenceScoreTitleModel, this.titleSheet);
      let subTitle: string = "";
      titleData.map( obj => {
        this.chartTitle = obj.Title;
        return subTitle = `${obj.SubTitle} ${obj.Level} ${obj.Age ? obj.Age : ""} ${obj.Gender ? obj.Gender : ""}`;
      });
      slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.35, bold: true });
      slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.58, italic: true });
    }
  
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
      let circle: string = "";
      let circleValue: string = "";
      let KPIColor: string = "";
      let KPIValue: string = "";
      const data = await this.getMappedChartData(BMCPreferenceScoreDataModel, this.dataSheet);
      const globalData = await this.getMappedChartData(BMCPreferenceScoreGlobalDataModel, this.globalDataSheet);
      const countryData = await this.getMappedChartData(BMCPreferenceScoreCountryDataModel, this.countryDataSheet);
      const categoryData = await this.getMappedChartData(BMCPreferenceScoreCategoryDataModel, this.categoryDataSheet);

      data.forEach((obj) => {
        if (obj.KPIValue) {
          KPIValue = (parseFloat(obj.KPIValue) * 100).toFixed(1);
        }
        if (obj.Color) {
          KPIColor = obj.Color;
        }
        if (obj.ValueNegative) {
          circle = ChartsBase64.NegativeCircle;
          circleValue = (parseFloat(obj.ValueNegative) * 100).toFixed(1);
        } else if (obj.ValuePositive) {
          circle = ChartsBase64.PositiveCircle;
          circleValue = (parseFloat(obj.ValuePositive) * 100).toFixed(1);
        } else if (obj.ValueZero) {
          circle = ChartsBase64.ZeroCircle;
          circleValue = (parseFloat(obj.ValueZero) * 100).toFixed(1);
        } else return false;
      })

      chartSlide.addImage({x: 3.3, y: 1.9, w: 1.5, h: 1.5, data: circle });
      if (circleValue) {
        chartSlide.addText([{ text: `${circleValue}%`, options: { color: KPIColor, fontSize: 16 } }], { x: 3.8, y: 3.6 });
      }
      chartSlide.addText([{ text: `${KPIValue}%`, options: { fontSize: 28 } }], { x: 5, y: 2.7 });

      // Global.
      chartSlide.addText([{ text: `${globalData[0].Text}:`, options: { fontSize: 16 } }], { x: 2.4, y: 4.6 });
      chartSlide.addText([{ text: `${(parseFloat(globalData[0].Value) * 100).toFixed(1)}%`, options: { fontSize: 12 } }], { x: 2.5, y: 4.8 });

      // Country
      chartSlide.addText([{ text: `${countryData[0].Text}:`, options: { fontSize: 16 } }], { x: 4.4, y: 4.6 });
      chartSlide.addText([{ text: `${(parseFloat(countryData[0].Value) * 100).toFixed(1)}%`, options: { fontSize: 12 } }], { x: 4.5, y: 4.8 });

      // Category
      chartSlide.addText([{ text: `${categoryData[0].Text}:`, options: { fontSize: 16 } }], { x: 6.4, y: 4.6 });
      chartSlide.addText([{ text: `${(parseFloat(categoryData[0].Value) * 100).toFixed(1)}%`, options: { fontSize: 12 } }], { x: 6.5, y: 4.8 });
    };
}

import pptxgen from 'pptxgenjs';

import { BrandOwnershipTitleModel } from '../../../models/PersonalHealth/BrandOwnership/BrandOwnershipTitleModel';
import { BrandOwnershipDataModel } from '../../../models/PersonalHealth/BrandOwnership/BrandOwnershipDataModel';

import { PHFirstSlideRenderer } from '../../Renderers/PHFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';
import { addEmptyValuesToInitData } from '../../../../shared/utility';

export class BrandOwnershipService extends BasePptxService {
  protected chartQuestion: string = '';
  protected firstSlideRenderer: PHFirstSlideRenderer = new PHFirstSlideRenderer();
  protected titleSheet: string;
  protected dataSheet: string;

  constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
      super(view, chartTitle);
      this.titleSheet = titleSheet;
      this.dataSheet = dataSheet;
  }

  protected async setChartSlideLayout(slide: pptxgen.Slide) {
    // Override title's options and add filter's name in it.
    const titleData = await this.getMappedChartData(BrandOwnershipTitleModel, this.titleSheet);
    let subTitle: string = "";
    
    titleData.map( obj => {
      this.chartTitle = obj.Title;
      return subTitle = obj.SubTitle;
    });
    slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.35, bold: true });
    slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.58, italic: true });
  }

  protected async addChartSlide(chartSlide: pptxgen.Slide) {
    const data = await this.getMappedChartData(BrandOwnershipDataModel, this.dataSheet);
    const reversedData = data.slice().reverse();
    const newArr: any = [];
    const lineColors: string[] = [];
    const getAllLabels: any = [];

    reversedData.forEach((obj: any) => {
      getAllLabels.push(obj.Label);
    });

    const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);
    reversedData.sort((a: any, b: any) => a.Name.substring(3) - b.Name.substring(3));
    reversedData.forEach((obj: any) => {
      const item = newArr.find((item: any) => item.name === obj.Name);
      lineColors.push(obj.Color);
      if(newArr && newArr.length > 0 && item) {
        if (item) {
          item.values.push(parseFloat(obj.Value));
          item.labels.push(obj.Label);
        }
      } else {
        newArr.push(
          {
            name: obj.Name,
            labels: [obj.Label],
            values: [parseFloat(obj.Value)],
          }
        )
      }
    });

    const finalChartData = addEmptyValuesToInitData(distinctedLabels, newArr);

    chartSlide.addChart(
      this.presentation.ChartType.bar,
      finalChartData,
      {
        x: 1,
        y: 1,
        w: "70%",
        h: "70%",
        barDir: "bar",
        showLegend: true,
        legendPos: 'b',
        dataLabelFormatCode: '0%',
        valAxisLabelFormatCode: '0%',
        valAxisLabelFontSize: 8,
        catAxisLabelFontSize: 8,
        valAxisMinVal: 0,
        valAxisMaxVal: 1,
        chartColors: lineColors.filter(this.distinctRepeatables),
      }
    );
  }

  private distinctRepeatables = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };
}

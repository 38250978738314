import { IDataMapping } from '../../IDataMapping';

export class RecommendationPatternsPieDataModel implements IDataMapping<RecommendationPatternsPieDataModel> {
    public Name: string = '';
    public Label: string = '';
    public Color: string = '';
    public Rank: string = '';
    public Value: number = Number(0);
    public ObjIdentify: string = '';
    public getMappings?() {
        return {
            Name: 'Brand RS_Piechart',
            Label: '',
            Color: 'AGG(Brand RS_Piechart Color)',
            Rank: 'Rank',
            Value: 'AGG(Brand RS value)',
            ObjIdentify: 'BrandsPieChartExp',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class SustainabilityLineChartTitleModel implements IDataMapping<SustainabilityLineChartTitleModel> {
    public Title: string = '';
    public SubTitle: string = '';
    public Audience: string = '';
    public Period: string = '';
    public Market: string = '';

    public getMappings?() {
        return {
            Title: 'Statement_short',
            SubTitle: 'Statement_short 1st part of the titles',
            Audience: 'Position',
            Period: 'Period',
            Market: 'Market'
        };
    }
}

import pptxgen from 'pptxgenjs';
import { ProductLevelNPSDriverOverviewBrandDataModel, ProductLevelNPSDriverOverviewDataModel } from '../../../';
import { TablePptxService } from '../../TablePptxService';
import { HSFirstSlideRenderer } from '../../Renderers/HSFirstSlideRenderer';
import { BaseChartOptions } from '../../../philips-constants';

export class ProductLevelNPSDriversOverviewPptxService extends TablePptxService {
    protected firstSlideRenderer = new HSFirstSlideRenderer();
    protected chartQuestion: string = 'Please select which vendor performs better in regards to these attributes.';
    private rowsCountOnSlide: number = 14;
    private chartColors: string[] = [];

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        let isTable = true;
        let sheetData = await this.getChartData('Philips NPS Table');
        if (!sheetData || !sheetData.length) {
            sheetData = await this.getChartData('Philips NPS Bubble');
            isTable = false;
        }

        const legendData = await this.getChartData('Philips NPS_base size_Legend');
        const brandHexColor = await this.getChartData('Brand hex colors Attributes');
        const orderedLegendModels = this.getBrandData(legendData);

        // Match the already ordered brands with the brands in the hex endpoint
        // to get and populate the colors array.
        orderedLegendModels.forEach((rowArray) => {
          const brand = rowArray.Brand;
          brandHexColor.forEach((arr: any[]) => {
            if (arr[0].value === brand) {
                this.chartColors.push(arr[1].value);
            }
          });
        });

        if (!sheetData || !sheetData.length) {
            return;
        }
        const dataModels: ProductLevelNPSDriverOverviewDataModel[] = this.processData(sheetData, isTable, orderedLegendModels);
        let chartSlideIndex = 0;
        const hasSubdrivers = dataModels.every(d => this.getSubdrivers(d.BrandsData).every(l => l));
        if (hasSubdrivers) {
            chartSlideIndex = this.addSubdriverCharts(dataModels, chartSlide, chartSlideIndex, orderedLegendModels);
        } else {
            chartSlideIndex = this.addDriversChart(dataModels, chartSlide, orderedLegendModels, chartSlideIndex);
        }

        const splitedData = this.getSplitedData(dataModels);
        const headerRow: pptxgen.TableRow = this.getHeaderRow(['', '', ...orderedLegendModels.map(x => x.Brand)]);
        for (const data of splitedData) {
            const tableChartSlide = this.getCurrentChartSlide(chartSlide, chartSlideIndex);
            const bodyRows: pptxgen.TableRow[] = this.getBodyRows(data, orderedLegendModels.map(x => x.Brand));

            const colW = [2.5, 2.5, ...Array.from(Array(orderedLegendModels.length).keys()).map(x => 1)];
            const tableOptions: pptxgen.TableProps = {
                x: 1, h: '40%', colW, rowH: 0.25,
                margin: [0, 0, 0, 2],
                autoPage: false,
                autoPageCharWeight: 1.5
            };
            this.addTable(tableChartSlide, [headerRow, ...bodyRows], tableOptions);
        }

    }

    private getSplitedData(dataModels: ProductLevelNPSDriverOverviewDataModel[]) {
        const splitedData: any[] = [[]];
        let slide = 0;
        let currentRowsCountOnSlide = 0;

        for (const currentData of dataModels) {
            const chartDataKeys = Object.keys(currentData.BrandsData[0].ChartDataPoints);

            if (currentRowsCountOnSlide + chartDataKeys.length > this.rowsCountOnSlide) {
                const leftRowsCountOnSlide = this.rowsCountOnSlide - currentRowsCountOnSlide;
                if (leftRowsCountOnSlide !== 0) {
                    const currentKeys = chartDataKeys.slice(0, leftRowsCountOnSlide);
                    const current = new ProductLevelNPSDriverOverviewDataModel(currentData.Driver);
                    const next = new ProductLevelNPSDriverOverviewDataModel(currentData.Driver);
                    currentData.BrandsData.forEach(b => {
                        const currentBrand = new ProductLevelNPSDriverOverviewBrandDataModel(b.Brand);
                        const nextBrand = new ProductLevelNPSDriverOverviewBrandDataModel(b.Brand);
                        for (const key in b.ChartDataPoints) {
                            if (Object.prototype.hasOwnProperty.call(b.ChartDataPoints, key)) {
                                if (currentKeys.some(k => k === key)) {
                                    currentBrand.ChartDataPoints[key] = b.ChartDataPoints[key];
                                } else {
                                    nextBrand.ChartDataPoints[key] = b.ChartDataPoints[key];
                                }

                            }
                        }

                        current.BrandsData.push(currentBrand);
                        next.BrandsData.push(nextBrand);
                    });
                    splitedData[slide].push(current);
                    slide++;
                    splitedData.push([]);
                    splitedData[slide].push(next);
                    currentRowsCountOnSlide = chartDataKeys.length - currentKeys.length;
                } else {
                    slide++;
                    splitedData.push([]);
                    splitedData[slide].push(currentData);
                    currentRowsCountOnSlide = chartDataKeys.length;
                }
            } else {
                splitedData[slide].push(currentData);
                currentRowsCountOnSlide += chartDataKeys.length;
            }
        }

        return splitedData;
    }

    private addDriversChart(
        dataModels: ProductLevelNPSDriverOverviewDataModel[],
        chartSlide: pptxgen.Slide,
        orderedLegendModels: Array<{ Brand: string; DisplayValue: string; }>,
        chartSlideIndex: number
    ) {
        const labels: string[] = dataModels.map(d => d.Driver).distinct();
        const labelsChunks = this.chunkArray(labels.filter(l => l !== 'NET'), 5);

        // eslint-disable-next-line no-loop-func
        labelsChunks.forEach(currentLabels => {
            const currentSlide = this.getCurrentChartSlide(chartSlide, chartSlideIndex);
            this.addDriversTable(currentLabels, currentSlide);
            const dataModel: ProductLevelNPSDriverOverviewDataModel = {
                Driver: '',
                BrandsData: []
            };

            dataModels.forEach(d => {
                d.BrandsData.forEach(b => {
                    let currentBrandData: ProductLevelNPSDriverOverviewBrandDataModel;
                    const filtered: ProductLevelNPSDriverOverviewBrandDataModel[] = dataModel.BrandsData.filter(x => x.Brand === b.Brand);
                    if (!filtered || !filtered.length) {
                        currentBrandData = new ProductLevelNPSDriverOverviewBrandDataModel(b.Brand);
                        dataModel.BrandsData.push(currentBrandData);
                    } else {
                        currentBrandData = filtered[0];
                    }

                    currentBrandData.ChartDataPoints[d.Driver] = b.ChartDataPoints[''];
                });
            });

            this.addChart(dataModel, currentLabels, currentSlide);

            this.addLegend(currentSlide, orderedLegendModels, '85%');
            chartSlideIndex++;
        });
        return chartSlideIndex;
    }

    private addSubdriverCharts(
        dataModels: ProductLevelNPSDriverOverviewDataModel[],
        chartSlide: pptxgen.Slide,
        chartSlideIndex: number,
        orderedLegendModels: Array<{ Brand: string; DisplayValue: string; }>
    ) {
        for (const driverModel of dataModels) {

            const labels: string[] = this.getSubdrivers(driverModel.BrandsData);
            const labelsChunks = this.chunkArray(labels.filter(l => l !== 'NET'), 5);

            // eslint-disable-next-line no-loop-func
            labelsChunks.forEach(currentLabels => {
                const currentSlide = this.getCurrentChartSlide(chartSlide, chartSlideIndex);
                this.addDriversTable(currentLabels, currentSlide);
                this.addChart(driverModel, currentLabels, currentSlide);
                this.addLegend(currentSlide, orderedLegendModels, '85%');
                chartSlideIndex++;
            });
        }
        return chartSlideIndex;
    }

    private chunkArray(arr: any[], chunkSize: number) {
        const arrayLength = arr.length;
        const tempArray = [];

        for (let index = 0; index < arrayLength; index += chunkSize) {
            const myChunk = arr.slice(index, index + chunkSize);
            tempArray.push(myChunk);
        }

        return tempArray;
    }

    private addDriversTable(labels: string[], currentSlide: pptxgen.Slide) {
        const rows: pptxgen.TableRow[] = labels.map(k => ([{ text: k, options: { bold: true } }, { text: '' }]));
        this.addTable(currentSlide, rows, {
            x: 0.5, y: 2.1, w: '95%', colW: [1.5, 7.5], rowH: 0.45,
            margin: [2, 0, 2, 2],
            align: 'left',
            autoPageCharWeight: 1.5
        });
    }

    private addChart(driverModel: ProductLevelNPSDriverOverviewDataModel, labels: string[], currentSlide: pptxgen.Slide) {
        const chartData: Array<{ name: string; values: Array<number | null>; sizes?: number[]; }> = this.getLines(driverModel.BrandsData, labels);

        currentSlide.addText(driverModel.Driver, {
            x: 0.5,
            y: 1,
            color: this.chartColors[0],
            fontSize: 16,
            bold: true
        });

        const options: pptxgen.IChartOpts = {
            ...BaseChartOptions,
            x: 1.8, y: 1.6, w: 7.95, h: 4,
            chartColors: this.chartColors,
            showLabel: false,
            catAxisMinVal: 0,
            catAxisMaxVal: 100,
            catAxisMajorUnit: 10,
            catAxisLabelFrequency: 10,
            valAxisHidden: true,
            valAxisMinVal: 0,
            valAxisMaxVal: 8,
            catAxisOrientation: 'minMax',
            valAxisOrientation: 'maxMin',
            valAxisMajorUnit: 0.2,
            lineDataSymbolSize: 10,
            lineSize: 0,
        } as any;
        currentSlide.addChart(this.presentation.ChartType.scatter, chartData, options);
    }

    private getBodyRows(models: ProductLevelNPSDriverOverviewDataModel[], brands: string[]) {
        const rows: pptxgen.TableRow[] = [];

        models.forEach(m => {
            const subdrivers: string[] = this.getSubdrivers(m.BrandsData);
            const hasSubdrivers = subdrivers.filter(x => x).length > 0;

            subdrivers.forEach((subDriver, subdriverIndex) => {
                const row: pptxgen.TableRow = [];
                if (subdriverIndex === 0) {
                    row.push({ text: m.Driver, options: { align: 'left', rowspan: subdrivers.length, colspan: hasSubdrivers ? 0 : 2 } });
                }

                if (hasSubdrivers) {
                    row.push({ text: subDriver, options: { align: 'left' } });
                }
                brands.forEach(l => {
                    const brandData = m.BrandsData.filter(b => b.Brand === l);
                    const value = brandData[0].ChartDataPoints[subDriver as any];
                    if (brandData && brandData.length) {
                        row.push({ text: value !== null ? `${brandData[0].ChartDataPoints[subDriver as any]}%` : '' });
                    }
                });

                rows.push(row);
            });

        });

        return rows;
    }

    private getCurrentChartSlide(chartSlide: pptxgen.Slide, index: number) {
        let currentSlide = chartSlide;
        if (index !== 0) {
            currentSlide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });
            this.setChartSlideLayout(currentSlide);
        }
        return currentSlide;
    }

    private processData(sheetData: any, isTable: boolean, orderedLegendModels: any) {
        const dataModels: ProductLevelNPSDriverOverviewDataModel[] = [];

        sheetData.forEach((rowArray: any[]) => {
            const driver = rowArray[0].formattedValue;
            const records = dataModels.filter((x: ProductLevelNPSDriverOverviewDataModel) => x.Driver === driver);
            let current: ProductLevelNPSDriverOverviewDataModel;
            if (records.length === 0) {
                current = new ProductLevelNPSDriverOverviewDataModel(driver);
                dataModels.push(current);
            } else {
                current = records[records.length - 1];
            }

            const brand = rowArray[isTable ? 2 : 3].formattedValue;
            const orderObj = orderedLegendModels.find((x: any) => {
              return x.Brand === brand
            })
            const brandRecords = current.BrandsData.filter((x: ProductLevelNPSDriverOverviewBrandDataModel) => x.Brand === brand);
            let currentBrand: ProductLevelNPSDriverOverviewBrandDataModel;
            if (brandRecords.length === 0) {
                currentBrand = new ProductLevelNPSDriverOverviewBrandDataModel(brand);
                current.BrandsData.push(currentBrand);
            } else {
                currentBrand = brandRecords[0];
            }

            let subDriver = rowArray[1].formattedValue;
            subDriver = subDriver.length > 80 ? subDriver.substr(0, 50) + '...' : subDriver;
            const value = rowArray[6].value;
            const displayValue = value && !isNaN(+value) ? (+value * 100).toFixed() : null;
            currentBrand.ChartDataPoints[subDriver] = displayValue;
            currentBrand.Order = orderObj.OrderValue;
        });

        dataModels.forEach(d => {
            d.BrandsData = d.BrandsData.sort((a, b) => a.Order - b.Order);
        });

        return dataModels;
    }

    private getLines(brandsData: ProductLevelNPSDriverOverviewBrandDataModel[], labels: string[]) {
        const initialValues: Array<number | null> = Array.from(Array((brandsData.length) * labels.length).keys()).map(x => null);
        const lines: Array<{ name: string, values: Array<number | null>, sizes?: number[] }> = brandsData.map(b => {
            return {
                name: b.Brand,
                values: [...initialValues],
            };
        });

        const xAxisValues: Array<number | null> = [];
        brandsData.forEach((b, brandIndex) => {
            labels.forEach((l, labelIndex) => {
                let value = null;
                if (b.ChartDataPoints[l] !== null) {
                    value = Number(b.ChartDataPoints[l]);
                }
                xAxisValues.push(value);
                lines[brandIndex].values[labelIndex + (brandIndex * labels.length)] = labelIndex + 1;
            });
        });

        return [{ name: 'X-Values', values: xAxisValues }, ...lines];
    }

    private getSubdrivers(models: ProductLevelNPSDriverOverviewBrandDataModel[]) {
        let subdrivers: string[] = [];
        models.forEach(d => subdrivers.push(...Object.keys(d.ChartDataPoints)));
        subdrivers = subdrivers.filter((value, index, self) => self.indexOf(value) === index);
        subdrivers.reverse();
        return subdrivers;
    }

    private addLegend(chartSlide: pptxgen.Slide, legendData: Array<{ Brand: string; DisplayValue: string; }>, yPos: string | number) {
        const xValues = [30, 50, 70];
        for (let index = 0; index < legendData.length; index++) {
            const model = legendData[index];
            const text = `${model.Brand} (${model.DisplayValue})`;
            chartSlide.addText([
                { text, options: { fontSize: 10, bold: true, color: this.chartColors[index] } }],
                { x: `${xValues[index]}%`, y: yPos });
        }
    }

    private getBrandData(legendData: any[]): Array<{ Brand: string; DisplayValue: string; OrderValue: number }> {
        const legendModels: Array<{ Brand: string; DisplayValue: string; OrderValue: number }> = [];
        legendData.forEach((rowArray: any[]) => {
            const brand = rowArray[1].formattedValue;
            const orderValue = rowArray[2].value;
            const value = rowArray[3].value;
            const displayValue = value && !isNaN(+value) ? (+value).toFixed() : '';
            legendModels.push({ Brand: brand, DisplayValue: displayValue, OrderValue: orderValue });
        });

        const orderedLegendModels = this.getOrderedByBrand(legendModels);
        return orderedLegendModels;
    }

    private getOrderedByBrand(data: any[]) {
      const philips = data.filter(d => d.Brand === 'Philips');
      const otherBrands = data.filter(d => d.Brand !== 'Philips').sort((a, b) => a.OrderValue - b.OrderValue);
      return [...philips, ...otherBrands];
  }
}
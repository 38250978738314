import React, { useEffect, useState } from 'react';
import { INestedViewLinksProps } from './INestedViewLinksProps';
import styles from './SideMenu.module.scss';
import { find, findIndex, some } from 'lodash';
import { NavLink } from 'react-router-dom';
import { Routes } from '../../../shared/routes';
import { TableauViewViewModel } from '../../../models/Tableau/TableauViewViewModel';
import { NavMenuGroup } from '../../../models/Tableau/NavMenuGroup';

export const NestedViewLinks = (props: INestedViewLinksProps): JSX.Element => {
  const {
    workbook,
    workbooksWithGroupedViews,
    toggleMobileSidebar,
  } = props;

  const [groups, setGroups] = useState<NavMenuGroup[]>([]);

  const toggleGroup = (groupIndex: number) => {
    const index = findIndex(groups, g => g.index === groupIndex);

    if (index > -1) {
      const newGroups = [...groups];
      newGroups[index].isOpen = !newGroups[index].isOpen;
      setGroups(newGroups);
    }
  };

  const initGroups = () => {
    const workbookGroups = workbooksWithGroupedViews.filter(
      (wg) => wg.workbookId === workbook.id
    );

    const groupObjects: NavMenuGroup[] = [];

    for (const workbookGroup of workbookGroups) {
      const firstViewOfGroupIndex = findIndex(
        workbook.views,
        (v) => v.id === workbookGroup.groupedViewsIds[0]
      );
      const currentGroupViews = workbook.views.filter((v) =>
        some(workbookGroup.groupedViewsIds, (vid) => vid === v.id)
      );
      groupObjects.push({
        label: workbookGroup.groupLabel,
        index: firstViewOfGroupIndex,
        isOpen: workbookGroup.initiallyOpen,
        views: currentGroupViews,
      });
    }
    setGroups(groupObjects);
  };

  const renderGroup = (groupIndex: number): JSX.Element => {
    const groupToRender = find(groups, (g) => g.index === groupIndex);

    if (groupToRender) {
      const groupLabel = (
        <li
          onClick={() => toggleGroup(groupToRender.index)}
          className={styles.toggle_link}
          key={'group_label'}
        >
          <span className={styles.group_label}>{groupToRender.label}</span>
          <i
            className={
              groupToRender.isOpen
                ? `pi pi-chevron-up ${styles.small_chevron}`
                : `pi pi-chevron-down ${styles.small_chevron}`
            }
          />
        </li>
      );

      return (
        <React.Fragment key={`group_items-${groupIndex}`}>
          {groupLabel}
          {groupToRender.isOpen
            ? groupToRender.views.map((v) => (
                <li
                  key={v.id}
                  onClick={() => {
                    toggleMobileSidebar(false);
                  }}
                  className={styles.nested_link}
                >
                  <NavLink
                    to={Routes.tableauView.getPath(v.id)}
                    activeClassName={styles.active_link}
                  >
                    {v.name}
                  </NavLink>
                </li>
              ))
            : undefined}
        </React.Fragment>
      );
    }

    return <React.Fragment></React.Fragment>;
  };

  const getViewLinks = (): JSX.Element => {
    const nonGroupedViews: TableauViewViewModel[] = workbook.views.filter(
      (v) => {
        const viewIsPartOfGroup = some(groups, (go) =>
          some(go.views, (gv) => gv.id === v.id)
        );
        return !viewIsPartOfGroup;
      }
    );

    return (
      <ul className={styles.view_links}>
        {workbook.views.map((v, index) => {
          if (some(groups, (g) => g.index === index)) {
            return renderGroup(index);
          } else if (some(nonGroupedViews, (ngv) => ngv.id === v.id)) {
            return (
              <li
                key={v.id}
                onClick={() => {
                  toggleMobileSidebar(false);
                }}
              >
                <NavLink
                  to={Routes.tableauView.getPath(v.id)}
                  activeClassName={styles.active_link}
                >
                  {v.name}
                </NavLink>
              </li>
            );
          }
        })}
      </ul>
    );
  };

  useEffect(() => {
    initGroups();
  }, [workbooksWithGroupedViews]);

  return (
    <React.Fragment>
      {getViewLinks()}
    </React.Fragment>
  );
};

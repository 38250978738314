import { IDataMapping } from '../../IDataMapping';

export class PHSustainabilityTitleModel implements IDataMapping<PHSustainabilityTitleModel> {
    public Title: string = '';
    public SubTitleCountryCategory: string = '';
    public SubTitleCountry: string = '';
    public SubTitleCategory: string = '';
    public SubtitleGlobal: string = '';
    public Category: string = '';
    public Country: string = '';
    public Filter: string = '';
    public getMappings?() {
        return {
            Title: 'Label',
            SubTitleCountryCategory: 'KPI Sub Title pipe',
            SubTitleCountry: 'KPI Sub Title pipe',
            SubTitleCategory: 'KPI Sub Title pipe',            
            SubtitleGlobal: 'POT Sub Title pipe',
            Category: 'Category',
            Country: 'Country / Market',
            Filter: 'ATTR(Answer)',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class BMCOverTimeLineChartTitleModel implements IDataMapping<BMCOverTimeLineChartTitleModel> {
    public Filter: string = '';
    public Title: string = '';
    public SubTitle: string = '';
    public Period: string = '';
    public getMappings?() {
        return {
            Filter: 'ATTR(Indicator Name (copy))',
            Title: 'BMC Page Titles',
            SubTitle: 'AGG(Category User Profile Sub Title)',
            Period: 'ATTR(Quarter/MAT)',
        };
    }
}

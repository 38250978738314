import { IDataMapping } from '../../IDataMapping';

export class PreferenceOverTimeTitleModel implements IDataMapping<PreferenceOverTimeTitleModel> {
    public Title: string = '';
    public SubTitle: string = '';
    public getMappings?() {
        return {
            Title: "POT Title pipe",
            SubTitle: "POT Sub Title pipe",
        };
    }
}

import { IDataMapping } from '../../../../IDataMapping';

export class NpsByBusinessGroupChartFooterModel implements IDataMapping<NpsByBusinessGroupChartFooterModel> {
    public text: string = '';

    public getMappings?() {
        return {
            text: 'Calc Countrty agg info',
        };
    }
}

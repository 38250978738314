import { IDataMapping } from '../../../IDataMapping';

export class PHBSTotalOverTimeChartTitleModel implements IDataMapping<PHBSTotalOverTimeChartTitleModel> {
    public Title: string = '';
    public SubTitle: string = '';

    public getMappings?() {
        return {
            Title:      'Brand Strength Total Over Time Title',
            SubTitle:   'Brand Strength Analysis Subtitle'
        };
    }
}

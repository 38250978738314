import { IDataMapping } from '../../IDataMapping';

export class NineGLineChartRnRTitleModel implements IDataMapping<NineGLineChartRnRTitleModel> {
    public Title: string = '';
    public getMappings?() {
        return {
            Title: '9G Titles (R&R)',
        };
    }
}

import pptxgen from 'pptxgenjs';

import { AppendixUSTitleModel } from '../../../../models/SRC/Appendix/AppendixUS/AppendixUSTitleModel';
import { AppendixUSSubTitleModel } from '../../../../models/SRC/Appendix/AppendixUS/AppendixUSSubTitleModel';
import { AppendixUSDataModel } from '../../../../models/SRC/Appendix/AppendixUS/AppendixUSDataModel';

import { SRCFirstSlideRenderer } from '../../../Renderers/SRCFirstSlideRenderer';
import { BasePptxService } from '../../../BasePptxService';
import { PptxRenderHelper } from '../../../../helpers/PptxRenderHelper';
import { LegendData } from '../../../../models/ProductLevelNPS/LegendData';

export class AppendixUSService extends BasePptxService {
  protected chartQuestion: string = '';
  protected firstSlideRenderer: SRCFirstSlideRenderer = new SRCFirstSlideRenderer();
  protected titleSheet: string;
  protected subTitleSheet: string;
  protected dataSheet: string;
  private rowsCountOnSlide: number = 16;

  constructor(view: any, chartTitle: string, titleSheet: string, subTitleSheet: string, dataSheet: string) {
    super(view, chartTitle);
    this.titleSheet = titleSheet;
    this.subTitleSheet = subTitleSheet;
    this.dataSheet = dataSheet;
  }

  protected async setChartSlideLayout(slide: pptxgen.Slide) {
  }

  protected async addChartSlide(chartSlide: pptxgen.Slide) {
    const titleData = await this.getMappedChartData(AppendixUSTitleModel, this.titleSheet);
    const subTitleData = await this.getMappedChartData(AppendixUSSubTitleModel, this.subTitleSheet);
    const data = await this.getMappedChartData(AppendixUSDataModel, this.dataSheet);

    titleData.map((obj: any) => {
      return this.chartTitle = obj.Title;
    });
    
    let subTitle: string = "";
    subTitleData.map((obj: any) => {
      return subTitle = obj.SubTitle;
    });

    let lowValue: boolean = false;
    data.forEach((obj: any) => {
      if (obj.ValueBase <= 20) {
        lowValue = true;
      }
    });

    data.sort((a: any, b: any) => a.Rank - b.Rank);
    const groupBy = (key: any) => (result: any, current: any) => {
      const item = Object.assign({}, current);
      if (typeof result[current[key]] === 'undefined') {
        result[current[key]] = [item];
      } else {
        result[current[key]].push(item);
      }
      return result;
    };

    const groupByCategory = data.reduce(groupBy('Category'), {});
    const categoriesCount = Object.keys(groupByCategory).length;
    const groupByCategoryArr = Object.values(groupByCategory).map((a: any) => a);
    let groupByCategoryBySlides: any = [];

    while (groupByCategoryArr[0].length > 0) {
      for (let i = 0; i < categoriesCount; i++) {
        const brandsArr = groupByCategoryArr[i].map((a: any) => a.Label).filter(this.distinctRepeatables);
        const chunk = groupByCategoryArr[i].splice(0, brandsArr.length * this.rowsCountOnSlide);
        if (groupByCategoryBySlides[i]) {
          groupByCategoryBySlides[i].push(chunk);
        } else {
          groupByCategoryBySlides.push([chunk]);
        }
      }
    };

    for (let slideIndex = 0; slideIndex < groupByCategoryBySlides[0].length; slideIndex++) {
      let slide: any;
      if (slideIndex === 0) {
        slide = chartSlide;
      } else {
        slide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });
      };
      groupByCategoryBySlides.forEach((categoryData: any, categoryIndex: number) => {
        // With first category we also add the table with drivers, title and subtitle, legend, note
        if (categoryIndex === 0) {
          this.addDriversTable(categoryData[slideIndex], slide, categoriesCount);
          this.addLegend(data, slide);
          slide.addText([{ text: this.chartTitle, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.35, bold: true });
          slide.addText([{ text: subTitle, options: { color: this.colors.black, fontSize: 12 } }], { x: 0.5, y: 0.65 });
          if (lowValue) {
            slide.addText([{ text: "*Note: Scores could be influenced by small sample sizes.", options: { color: '#000000', fontSize: 7 } }], { x: 7.34, y: 5.45, bold: false });
          };
        }
        this.addBubbleChart(categoryData[slideIndex], slide, categoryIndex, categoriesCount);
      });
    };   
  };

  private addBubbleChart(chunkData: any, chunkSlide: any, chunkPosition: number, categoriesCount: number) {
    const newArr: any = [];
    const lineColors: string[] = [];
    const labelsArr: string[] = [];
    const xAxisValues: number[] = [];
    let chartTitle = "";

    // Create newarr with empty values and a name.
    chunkData.forEach((obj: any) => {
      chartTitle = obj.Category;
      const item = newArr.find((item: any) => item.name === obj.Label);
      lineColors.push(obj.Color);
      if (newArr && newArr.length > 0 && item) {
        return {
          name: obj.Label,
          values: [],
        };
      } else {
        newArr.push(
          {
            name: obj.Label,
            values: [],
          }
        );
      }
    })

    // Convert values into simple and ordered numbers (alongside nulls) and add them to the new array.
    newArr.forEach((newObj: any) => {
      let flag = 1;
      chunkData.forEach((oldObj: any) => {
        if (newObj.name === oldObj.Label) {
          newObj.values.push(flag);
          flag++;
        } else {
          newObj.values.push(null);
        };
      });
    });

    // Add the X-Axis object with all the values from the raw data.
    chunkData.forEach((obj: any) => {
      xAxisValues.push(obj.Value);
      labelsArr.push(obj.Name);
    });

    newArr.unshift({
      name: 'X-Axis',
      values: xAxisValues,
    });

    const disctinctedLabels = labelsArr.filter(this.distinctRepeatables);
    const bubbleChartHeight = this.getLineHeight(disctinctedLabels);
    const bubbleChartWidth = this.getLineWidth(categoriesCount);
    const xPosition = this.getChartXAxis(chunkPosition, bubbleChartWidth);
    const basesData = this.processBasesData(chunkData);

    const options: pptxgen.IChartOpts = {
      x: xPosition,
      y: 1.028,
      w: bubbleChartWidth,
      h: bubbleChartHeight,
      showLabel: false,
      showTitle: false,
      titleFontSize: 10,
      catAxisMajorUnit: 10,
      catAxisLabelFrequency: 10,
      valAxisHidden: true,
      catAxisOrientation: 'minMax',
      valAxisOrientation: 'maxMin',
      valAxisMajorUnit: 1,
      lineDataSymbolSize: 8,
      lineSize: 0,
      catGridLine: { style: 'none' },
      valGridLine: { style: 'none' },
      dataLabelFontSize: 6,
      dataLabelFontBold: true,
      chartColors: lineColors.filter(this.distinctRepeatables),
      catAxisHidden: true,
      catAxisMinVal: 0,
      catAxisMaxVal: 0.8,
    } as any;

    chunkSlide.addText(basesData, { x: xPosition, y: (bubbleChartHeight + 0.95) });
    chunkSlide.addText([{ text: chartTitle, options: { color: '#000000', fontSize: 10, align: 'center' } }], { x: xPosition, y: 1.18, w: bubbleChartWidth });
    chunkSlide.addChart(this.presentation.ChartType.scatter, newArr, options);
  };

  private getChartXAxis(index: number, width: number) {
    return 1.595 + (index * width);
  };

  private getLineHeight(array: any) {
    return ((array.length) * 0.25) + 0.623;
  };

  private getLineWidth(count: number) {
    return 8 / count;
  };

  private addDriversTable(data: any, chartSlide: pptxgen.Slide, categoriesCount: number) {
    let tableProps: pptxgen.TableProps;
    const labelsArr: string[] = [];

    data.forEach((obj: any) => {
      labelsArr.push(obj.Name);
    });

    const disctinctedLabels = labelsArr.filter(this.distinctRepeatables);
    const rows = disctinctedLabels.map((k: any, index: number) => ([{ text: k, options: { bold: true, fill: index % 2 == 0 ? '#F2F2F2' : '#FFFFFF' } }, ...Array(categoriesCount).fill({ text: '', options: { fill: index % 2 == 0 ? '#F2F2F2' : '#FFFFFF' } })]));

    tableProps = {
      x: 0.4, y: 1.3, w: '3%', colW: [1.2, ...Array(categoriesCount).fill(this.getLineWidth(categoriesCount))], rowH: 0.25,
      margin: 5,
      align: 'left',
      autoPageCharWeight: 1.5,
      fontSize: 7,
      border: { type: 'solid', color: this.colors.white, pt: 2 },
      valign: 'middle',
    };

    chartSlide.addTable(rows, tableProps);
  };

  private addLegend(data: any, chartSlide: pptxgen.Slide) {
    data.sort((a: any, b: any) => a.RankBase - b.RankBase || a.LabelLegend.localeCompare(b.LabelLegend));
    const legend: any = {};
    data.forEach((obj: any) => {
      legend[obj.LabelLegend] = obj.Color;
    });

    let x = 1.595;
    for (const [key, value] of Object.entries(legend)) {
      PptxRenderHelper.renderShapeLegend(chartSlide, { label: key, color: value as string }, { x, y: 0.95, fontSize: 7 }, 'ellipse');
      x += 1.2;
    }
  };

  private processBasesData(data: any): LegendData[] {
    data.sort((a: any, b: any) => a.RankBase - b.RankBase);
    const newArr: any = [];

    data.forEach((obj: any) => {
      const item = newArr.find((item: any) => item.brand === obj.BrandBase);
      if (!item) {
        newArr.push(
          {
            brand: obj.BrandBase,
            baseColor: obj.ValueBase < 10 ? '#FF0000' : '#000000',
            value: obj.ValueBase < 20 ? obj.ValueBase + '*' : obj.ValueBase,
          }
        )
      }
    });

    const basesData: LegendData[] = [];
    newArr.forEach((current: any) => {
      basesData.push(
        { text: `${current.brand}(n=${current.value})  `, options: { color: current.baseColor, fontSize: 8 } },
      );
    });

    return basesData;
  };

  private distinctRepeatables = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };
}

import React, { useState } from 'react';
import styles from './ManageViewsDialog.module.scss';
import { IManageViewsDialogProps } from './IManageViewsDialogProps';
import { find } from 'lodash';

export const ManageViewsDialog = (
  props: IManageViewsDialogProps
): JSX.Element => {
  const { view, customViewModel, handleRefreshCustomViews } = props;

  const [selectedCustomView, setSelectedCustomView] = useState<any>(undefined);

  const [deleteIsVisible, setDeleteIsVisible] = useState<boolean>(false);

  const [editViewNameIsVisible, setEditViewNameIsVisible] = useState<boolean>(
    false
  );

  const [newViewName, setNewViewName] = useState<string>('');

  const handleCustomViewNameChange = (value: string) => {
    setNewViewName(value);
  };

  const handleEditCustomViewName = async () => {
    if (newViewName && selectedCustomView) {
      const workbook = view.getWorkbook();
      const tableauCustomViews = await workbook.getCustomViewsAsync();
      const selectedTableauView = find(
        tableauCustomViews,
        (cv) => cv.getName() === selectedCustomView.name
      );

      if (selectedTableauView) {
        selectedTableauView.setName(newViewName);
        await selectedTableauView.saveAsync();
        await handleRefreshCustomViews();
        setNewViewName('');
        setSelectedCustomView(undefined);
        setEditViewNameIsVisible(false);
      }
    }
  };

  const toggleCustomViewVisibility = async (
    viewName: string,
    isPublic: boolean
  ) => {
    const workbook = view.getWorkbook();
    const tableauCustomViews = await workbook.getCustomViewsAsync();
    const selectedTableauView = find(
      tableauCustomViews,
      (cv) => cv.getName() === viewName
    );

    if (selectedTableauView) {
      selectedTableauView.setAdvertised(isPublic);
      await selectedTableauView.saveAsync();
      await handleRefreshCustomViews();
    }
  };

  const makeCustomViewDefault = async (viewName: string) => {
    const workbook: any = view.getWorkbook();
    if (viewName !== 'Original') {
      await workbook.showCustomViewAsync(viewName);
      await workbook.setActiveCustomViewAsDefaultAsync();
      await handleRefreshCustomViews();
    } else {
      await workbook.showCustomViewAsync();
      await workbook.setActiveCustomViewAsDefaultAsync();
      await handleRefreshCustomViews();
    }
  };

  const deleteCustomView = async () => {
    if (selectedCustomView) {
      const workbook: any = view.getWorkbook();
      await workbook.removeCustomViewAsync(selectedCustomView.name);
      setDeleteIsVisible(false);
      await handleRefreshCustomViews();
    }
  };

  return (
    <React.Fragment>
      <div
        className={styles.custom_views_content}
        onClick={(e: any) => {
          if (editViewNameIsVisible && e.target.id !== 'edit_name_input') {
            handleEditCustomViewName();
          }
        }}
      >
        <div className={styles.manage_views_container}>
          <h3 className={styles.custom_view_section_header}>Manage Views</h3>
        </div>
        <div className={styles.views_container}>
          <h3 className={styles.views_header}>My views</h3>
          {customViewModel.myViews.length === 0 ? (
            <div className={styles.empty_container}>Nothing saved yet</div>
          ) : (
            customViewModel.myViews.map((v) => (
              <div className={styles.view_row} key={v.name}>
                {editViewNameIsVisible &&
                selectedCustomView &&
                selectedCustomView.name === v.name ? (
                  <div className={styles.name_input_container}>
                    <div className={styles.name_input_wrapper}>
                      <input
                        type='text'
                        autoFocus
                        value={newViewName}
                        className={styles.name_input}
                        id={'edit_name_input'}
                        onChange={(e) =>
                          handleCustomViewNameChange(e.target.value)
                        }
                        onKeyUp={(e) => {
                          if (e.key === 'Enter') {
                            handleEditCustomViewName();
                          }
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className={
                      v.name === customViewModel.name ||
                      (v.name === 'Original' && customViewModel.name === '')
                        ? `${styles.active_row_wrapper} ${styles.row_wrapper}`
                        : styles.row_wrapper
                    }
                  >
                    <span className={styles.row_span}>
                      {v.isDefault ? (
                        <>
                          <i
                            className={styles.default_icon}
                            title={'This is your current default'}
                          ></i>
                          <span className={styles.view_name} title={v.name}>
                            {v.name} (default)
                          </span>
                        </>
                      ) : (
                        <>
                          <i
                            className={styles.non_default_icon}
                            onClick={() => makeCustomViewDefault(v.name)}
                            title={'Set this view as your default'}
                          ></i>
                          <span className={styles.view_name} title={v.name}>
                            {v.name}
                          </span>
                        </>
                      )}
                      <div className={styles.row_action_buttons}>
                        <i
                          className={`${styles.icon_button} ${styles.edit_icon}`}
                          onClick={(e) => {
                            if (selectedCustomView) {
                              setSelectedCustomView(undefined);
                            } else {
                              setSelectedCustomView(v);
                              setNewViewName(v.name);
                              setEditViewNameIsVisible(true);
                            }
                          }}
                          title={'Rename this view'}
                        ></i>
                        <i
                          onClick={() => {
                            setSelectedCustomView(v);
                            setDeleteIsVisible(true);
                          }}
                          className={`${styles.icon_button} ${styles.delete_icon}`}
                          title={'Delete this view'}
                        ></i>
                        {v.isPublic ? (
                          <i
                            className={`${styles.icon_button} ${styles.eye_icon}`}
                            onClick={() =>
                              toggleCustomViewVisibility(v.name, !v.isPublic)
                            }
                            title={'This view is public'}
                          ></i>
                        ) : (
                          <i
                            className={`${styles.icon_button} ${styles.crossed_eye_icon}`}
                            onClick={() =>
                              toggleCustomViewVisibility(v.name, !v.isPublic)
                            }
                            title={'This view is private'}
                          ></i>
                        )}
                      </div>
                    </span>
                  </div>
                )}
              </div>
            ))
          )}
        </div>

        <div className={styles.views_container}>
          <h3 className={styles.views_header}>Other views</h3>
          {customViewModel.otherViews.map((v) => (
            <div className={styles.view_row} key={v.name}>
              <div
                className={
                  v.name === customViewModel.name ||
                  (v.name === 'Original' && customViewModel.name === '')
                    ? `${styles.active_row_wrapper} ${styles.row_wrapper}`
                    : styles.row_wrapper
                }
              >
                <span className={styles.row_span}>
                  {v.isDefault ? (
                    <>
                      <i
                        className={styles.default_icon}
                        title={'This is your current default'}
                      ></i>
                      <span className={styles.view_name} title={v.name}>
                        {v.name} (default)
                      </span>
                    </>
                  ) : (
                    <>
                      <i
                        className={styles.non_default_icon}
                        onClick={() => makeCustomViewDefault(v.name)}
                        title={'Set this view as your default'}
                      ></i>
                      <span className={styles.view_name} title={v.name}>
                        {v.name}
                      </span>
                    </>
                  )}

                  <span className={styles.author}>
                    <span className={styles.author_label}>{v.author}</span>
                  </span>
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      {deleteIsVisible ? (
        <div className={styles.delete_window}>
          <div className={styles.delete_message}>Are you sure?</div>
          <div className={styles.delete_buttons_container}>
            <button
              className={styles.cancel}
              onClick={(e) => {
                setDeleteIsVisible(false);
                setSelectedCustomView(undefined);
              }}
            >
              Cancel
            </button>
            <button
              className={styles.delete}
              onClick={() => deleteCustomView()}
            >
              Delete
            </button>
          </div>
        </div>
      ) : undefined}
    </React.Fragment>
  );
};

import React, { useEffect, useState } from 'react';
import { ICustomViewContainerProps } from './ICustomViewContainerProps';
import styles from './CustomViewContainer.module.scss';
import {
  getInitialCustomView,
  CustomViewFormModel,
} from '../../../models/Tableau/CustomViewFormModel';
import { clone, cloneDeep } from 'lodash';
import { ApplicationUserViewModel } from '../../../models/User/ApplicationUserViewModel';
import { useSelector } from 'react-redux';
import { IState } from '../../../store/IState';
import { CreateCustomViewDialog } from './CreateCustomViewDialog/CreateCustomViewDialog';
import { ManageViewsDialog } from './ManageCustomViewsDialog/ManageViewsDialog';

export const CustomViewContainer = (
  props: ICustomViewContainerProps
): JSX.Element => {
  const { view, handleDialogClose, handleRefreshCustomViews } = props;

  const userProfile: ApplicationUserViewModel | undefined = useSelector(
    (state: IState) => state.UserReducer.userProfile
  );

  const [customView, setCustomView] = useState<CustomViewFormModel>(
    getInitialCustomView()
  );

  const [manageViewsIsVisible, setManageViewIsVisible] = useState<boolean>(
    false
  );

  let isOriginalViewDefault: boolean = true;

  const setCustomViewsOfView = async () => {
    if (view && userProfile && userProfile.userName) {
      const workbook = view.getWorkbook();
      const workbookCustomViews = await workbook.getCustomViewsAsync();

      const myViews: Array<{
        name: string;
        isPublic: boolean;
        isDefault: boolean;
        author: string;
      }> = [];

      const otherViews: Array<{
        name: string;
        isPublic: boolean;
        isDefault: boolean;
        author: string;
      }> = [];

      for (const currentCustomView of workbookCustomViews) {
        const isCurrentViewDefault = currentCustomView.getDefault();
        const isCurrentViewPublic = currentCustomView.getAdvertised();
        if (isCurrentViewDefault) {
          isOriginalViewDefault = false;
        }
        const viewName: string = currentCustomView.getName();
        const viewAuthor: string = currentCustomView.getOwnerName();
        const isCurrenUserTheAuthor =
          viewAuthor &&
          userProfile.userName.toLowerCase().includes(viewAuthor.toLowerCase());
        if (isCurrenUserTheAuthor) {
          myViews.push({
            name: viewName,
            isPublic: isCurrentViewPublic,
            isDefault: isCurrentViewDefault,
            author: viewAuthor,
          });
        } else {
          otherViews.push({
            name: viewName,
            isPublic: isCurrentViewPublic,
            isDefault: isCurrentViewDefault,
            author: viewAuthor,
          });
        }
      }

      otherViews.unshift({
        name: 'Original',
        isDefault: isOriginalViewDefault,
        isPublic: true,
        author: 'BI TEAM',
      });

      const currentCustomViewModel: CustomViewFormModel = cloneDeep(customView);
      currentCustomViewModel.myViews = myViews;
      currentCustomViewModel.otherViews = otherViews;

      const currentActiveCustomView = workbook.getActiveCustomView();

      if (currentActiveCustomView) {
        currentCustomViewModel.isMyDefault = currentActiveCustomView.getDefault();
        currentCustomViewModel.isPublic = currentActiveCustomView.getAdvertised();
        currentCustomViewModel.name = currentActiveCustomView.getName();
      }

      setCustomView(currentCustomViewModel);
    }
  };

  const handleCustomViewFieldChange = (
    fieldName: keyof CustomViewFormModel,
    fieldValue: any
  ) => {
    const currentModel: CustomViewFormModel = clone(customView);
    (currentModel as any)[fieldName] = fieldValue;
    setCustomView(currentModel);
  };

  const saveCustomView = async () => {
    if (customView && view) {
      const workbook: any = view.getWorkbook();
      const savedView = await workbook.rememberCustomViewAsync(customView.name);
      savedView.setAdvertised(customView.isPublic);
      if (customView.isMyDefault && !savedView.getDefault()) {
        await workbook.showCustomViewAsync(customView.name);
        await workbook.setActiveCustomViewAsDefaultAsync();
      }

      await savedView.saveAsync();
      handleDialogClose();
      await handleRefreshCustomViews();
    }
  };

  useEffect(() => {
    setCustomViewsOfView();
  }, [view]);

  return (
    <React.Fragment>
      <div className={styles.glass}></div>
      <div className={styles.dialog_floater}>
        <div className={styles.dialog_wrapper}>
          <div>
            <div className={styles.dialog_content}>
              <div className={styles.dialog_header}>
                <div className={styles.title_bar}>
                  <div className={styles.dialog_label}>Custom Views</div>
                  <div
                    className={styles.close_icon_container}
                    title={'Close dialog'}
                  >
                    <div className={styles.close_icon}>
                      <button
                        className={styles.close_button}
                        onClick={(e) => handleDialogClose()}
                      ></button>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.dialog_body}>
                <div className={styles.custom_views_content}>
                  {manageViewsIsVisible ? (
                    <ManageViewsDialog
                      view={view}
                      customViewModel={customView}
                      handleRefreshCustomViews={setCustomViewsOfView}
                    />
                  ) : (
                    <CreateCustomViewDialog
                      view={view}
                      customViewModel={customView}
                      handleCustomViewFieldChange={handleCustomViewFieldChange}
                      handleSaveCustomView={saveCustomView}
                      handleDialogClose={handleDialogClose}
                    />
                  )}
                  {customView.myViews.length > 0 ? (
                    <div className={styles.paginator}>
                      {manageViewsIsVisible ? (
                        <button
                          className={styles.button_left}
                          onClick={(e) => setManageViewIsVisible(false)}
                        >
                          <i className={styles.left_arrow}></i>
                          <span>Back</span>
                        </button>
                      ) : (
                        <button
                          className={styles.button_right}
                          onClick={(e) => setManageViewIsVisible(true)}
                        >
                          <span>Manage Views</span>
                          <i className={styles.right_arrow}></i>
                        </button>
                      )}
                    </div>
                  ) : undefined}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

import pptxgen from 'pptxgenjs';
import { ProductLevelNPSDriverDeltaDataModel, ProductLevelNPSDriverDeltaRowModel, TableCellModel } from '../../../';
import { TablePptxService } from '../../TablePptxService';
import { HSFirstSlideRenderer } from '../../Renderers/HSFirstSlideRenderer';
import { LegendModel } from '../../../models/LegendModel';
import { PptxRenderHelper } from '../../../helpers/PptxRenderHelper';

export class ProductLevelNPSDriversDeltaPptxService extends TablePptxService {
    protected firstSlideRenderer = new HSFirstSlideRenderer();
    protected chartQuestion: string = 'Please select which vendor performs better in regards to these attributes.';
    private rowsCountOnSlide: number = 10;
    private colorsByValue: { [key: string]: string } = {
        'Philips leads by > 20%': this.colors.positive,
        'Philips leads by > 10% and ≤ 20%': this.colors.lightPositive,
        'Philips leads by ≤ 10%': this.colors.ultraLightPositive,
        'Philips following by > 20%': this.colors.negative,
        'Philips following by > 10% and ≤ 20%': this.colors.lightNegative,
        'Philips following by ≤ 10%': this.colors.ultraLightNegative,
    };

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const data = await this.getMappedChartData(ProductLevelNPSDriverDeltaDataModel, 'Drivers Table (2)');
        if (!data || !data.length) {
            return;
        }

        const npsDataModels: ProductLevelNPSDriverDeltaRowModel[] = this.processData(data);
        const headers = Object.keys(npsDataModels[0]).map(x => x === 'Driver' || x === 'SubDriver' ? '' : x);
        const headerRow: pptxgen.TableRow = this.getHeaderRow(headers);
        const tableOptions: pptxgen.TableProps = this.getTableOptions(npsDataModels[0]);

        const splitedData: any[] = this.getSplitedData(npsDataModels);
        splitedData.forEach((d, index) => {
            const currentSlide = this.getCurrentSlide(chartSlide, index);
            const bodyRows: pptxgen.TableRow[] = this.getBodyRows(d);
            this.addTable(currentSlide, [headerRow, ...bodyRows], tableOptions);
            this.addLegend(currentSlide);
        });
    }

    private getTableOptions(npsDataModel: ProductLevelNPSDriverDeltaRowModel) {
        const dataCellsCountOnRow = Object.keys(npsDataModel).length - 2;
        const colW = [1.5, 1.5, ...Array.from(Array(dataCellsCountOnRow).keys()).map(x => 6 / dataCellsCountOnRow)];
        const allColumnsWidth = colW.reduce((a, b) => a + b);
        const xPos = (10 - allColumnsWidth) / 2;
        const tableOptions: pptxgen.TableProps = {
            x: xPos, y: 0.85, h: '40%', colW, rowH: 0.25,
            margin: [0, 0, 0, 2],
            autoPage: false,
            autoPageCharWeight: 1.5
        };

        return tableOptions;
    }

    private getCurrentSlide(chartSlide: pptxgen.Slide, index: number) {
        let currentSlide = chartSlide;
        if (index !== 0) {
            currentSlide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });
            this.setChartSlideLayout(currentSlide);
        }
        return currentSlide;
    }

    private processData(sheetData: ProductLevelNPSDriverDeltaDataModel[]) {
        const dataModels: ProductLevelNPSDriverDeltaRowModel[] = [];

        const dataGroupedByCountry = sheetData.reduce((r: any, a: any) => {
            r[a.Country] = [...r[a.Country] || [], a];
            return r;
        }, {});

        const countriesWithOrder: [string, number][] = Object.keys(dataGroupedByCountry).map(key => { return [key, parseInt(dataGroupedByCountry[key][0].CountryOrder)] });
        countriesWithOrder
            .sort((first, second) => {
                return first[1] - second[1];
            });

        const countries: string[] = countriesWithOrder.map(r => r[0]);

        sheetData.forEach((model: ProductLevelNPSDriverDeltaDataModel) => {
            const driver: string = model.Driver;
            let subDriver: string = model.SubDriver;

            subDriver = subDriver.length > 50 ? subDriver.substr(0, 50) + '...' : subDriver;
            const records = dataModels.filter((x: ProductLevelNPSDriverDeltaRowModel) => x.Driver.text === driver && x.SubDriver.text === subDriver);
            let current: ProductLevelNPSDriverDeltaRowModel;
            if (records.length < 1) {
                current = new ProductLevelNPSDriverDeltaRowModel(driver, subDriver, { fill: { color: this.colors.white }, align: 'left' });
                countries.map(c => { current[c as keyof ProductLevelNPSDriverDeltaRowModel] = new TableCellModel() });
                dataModels.push(current);
            } else {
                current = records[0];
            }

            const country = model.Country as keyof ProductLevelNPSDriverDeltaRowModel;
            const bgColor = this.colorsByValue[model.BgColor];
            const value = model.Value;
            const options = { margin: [0, 3, 0, 0], fill: { color: bgColor }, align: 'right', bullet: false };
            const displayValue = value && !isNaN(+value) ? `${(+value * 100).toFixed()}%` : '';

            current[country] = new TableCellModel(displayValue, options);
            const character = this.arrowsByValue[model.Arrow];
            if (character) {
                const bulletOptions = { characterCode: character.code, indent: 15 };
                current[country].options.bullet = bulletOptions;
            }
        });
        return dataModels;
    }

    private getSplitedData(npsDataModels: ProductLevelNPSDriverDeltaRowModel[]) {
        const grouped: any = {};

        npsDataModels.forEach((d) => {
            if (!grouped[d.Driver.text]) {
                grouped[d.Driver.text] = [];
            }

            grouped[d.Driver.text].push(d);
        });

        const splitedData: any[] = [{}];
        let slide = 0;
        let currentRowsCountOnSlide = 0;

        for (const key in grouped) {
            if (Object.prototype.hasOwnProperty.call(grouped, key)) {
                if (currentRowsCountOnSlide + grouped[key].length > this.rowsCountOnSlide) {
                    const leftRowsCountOnSlide = this.rowsCountOnSlide - currentRowsCountOnSlide;
                    if (leftRowsCountOnSlide !== 0) {
                        const current = grouped[key].slice(0, leftRowsCountOnSlide);
                        splitedData[slide][key] = current;
                    }

                    const next = grouped[key].slice(leftRowsCountOnSlide);
                    slide++;
                    splitedData.push({});
                    splitedData[slide][key] = next;
                    currentRowsCountOnSlide = next.length;
                } else {
                    splitedData[slide][key] = grouped[key];
                    currentRowsCountOnSlide += grouped[key].length;
                }
            }
        }

        return splitedData;
    }

    private getBodyRows(slideData: any) {
        const rows: pptxgen.TableRow[] = [];

        for (const key in slideData) {
            if (Object.prototype.hasOwnProperty.call(slideData, key)) {
                slideData[key] = slideData[key].reverse();
                const currentRows: pptxgen.TableRow[] = slideData[key].map((d: ProductLevelNPSDriverDeltaRowModel, index: number) => {
                    if (index === 0) {
                        return Object.keys(d).map(k => {
                            const prop: TableCellModel = d[k as keyof ProductLevelNPSDriverDeltaRowModel];
                            return k === 'Driver' ? { text: prop.text, options: { ...prop.options, rowspan: slideData[key].length } } : { ...prop };
                        });
                    } else {
                        return Object.keys(d).filter(x => x !== 'Driver').map(k => {
                            const prop: TableCellModel = d[k as keyof ProductLevelNPSDriverDeltaRowModel];
                            return { ...prop };
                        });
                    }
                });

                rows.push(...currentRows);
            }
        }

        return rows;
    }

    private addLegend(chartSlide: pptxgen.Slide) {
        let x = 1.5;
        let y = 4.5;

        const legendDataArray: LegendModel[] = Object.entries(this.colorsByValue).map(([label, color]) => ({ label, color }));
        legendDataArray.forEach((legendData, index) => {
            if (index % 3 === 0) {
                x = 1.5;
                y += 0.2;
            }
            PptxRenderHelper.renderSquareLegend(chartSlide, legendData, { x, y });
            x += 2.5;
        });
    }
}

import { IDataMapping } from '../../../IDataMapping';

export class BSTotalOverTimeChartDataModel implements IDataMapping<BSTotalOverTimeChartDataModel> {
    public Label: string = '';
    public Color: string = '';
    public Name: string = '';
    public Value: number = 0;
    public PeriodIndex: number = 0;

    public getMappings?() {
        return {
            Label: 'QUARTER(Date)',
            Color: 'Audience_color',
            Name: 'Audience_tbl',
            Value: 'AVG(Value)',
            PeriodIndex: 'MIN(index_period)'
        };
    }
}

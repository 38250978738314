const validateHelper = (fieldName, reducerErr, localStateErr) => {
	switch (fieldName) {
    case 'firstName':
      if (reducerErr && localStateErr) {
        return "First Name is required.";
      } else {
        return ""
      }
    case 'lastName':
      if (reducerErr && localStateErr) {
        return "Last Name is required.";
      } else {
        return "";
      }
    case 'email':
      if (reducerErr && localStateErr) {
        return "Email is required.";
      } else {
        return ""
      }
    case 'company':
      if (reducerErr && localStateErr) {
        return "Company is required.";
      } else {
        return ""
      }
    case 'position':
      if (reducerErr && localStateErr) {
        return "Job Title is required.";
      } else {
        return ""
      }
    case 'site':
      if (reducerErr) {
        return "Site is required.";
      } else {
        return ""
      }
    case 'userRoles':
      if (reducerErr && localStateErr) {
        return "User Roles are required.";
      } else {
        return ""
      }
    case 'userStatus':
      if (reducerErr) {
        return "User Status is required.";
      } else {
        return ""
      }
    default:
        return "Field is required.";
  }
};

export default validateHelper;

import { IDataMapping } from '../../../../IDataMapping';

export class CAImportanceChartTitleModel implements IDataMapping<CAImportanceChartTitleModel> {
    public Title: string = '';
    public Period: string = '';
    public Country: string = '';
    public Segment: string = '';
    public ClinicalSegment: string = '';
    public WarningText: string = '';

    public getMappings?() {
        return {
            Title: 'Calc Area of Focus TEXT',
            Period: 'Period',
            Country: 'Country',
            Segment: 'Area of focus_Segment',
            ClinicalSegment: 'Prm Ology',
            WarningText: 'no2'
        };
    }
}

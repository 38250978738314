import { IDataMapping } from '../../../../IDataMapping';

export class BSPerformanceChartTornadoDataModel implements IDataMapping<BSPerformanceChartTornadoDataModel> {
    public Answer:      string = '';
    public Value:       number = Number(0);
    public FilterBrand: string = '';
    public Check:       number = Number(0);
    public Factor:      string = '';

    public getMappings?() {
        return {
            Answer:         'Answer',
            Value:          'SUM(Value Difference)',
            FilterBrand:    'Brand',
            Check:          'AGG(AVG(-1))',
            Factor:         'ATTR(BS_statement)'
        };
    }
}

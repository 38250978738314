import pptxgen from 'pptxgenjs';

import { SummaryTitleModel } from '../../../models/SRC/Summary/SummaryTitleModel';
import { SummaryTableDataModel } from '../../../models/SRC/Summary/SummaryTableDataModel';

import { SRCFirstSlideRenderer } from '../../Renderers/SRCFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

export class SummaryService extends BasePptxService {
  protected chartQuestion: string = '';
  protected firstSlideRenderer: SRCFirstSlideRenderer = new SRCFirstSlideRenderer();
  protected titleSheet: string;
  protected dataSheet: string;

  constructor(view: any, chartTitle: string, titleSheet: string,
    dataSheet: string,
    ) {
      super(view, chartTitle);
      this.titleSheet = titleSheet;
      this.dataSheet = dataSheet;
  }

  protected async setChartSlideLayout(slide: pptxgen.Slide) {
    // Override title.
    const titleData = await this.getMappedChartData(SummaryTitleModel, this.titleSheet);
    titleData.map((obj: any) => {
        return this.chartTitle = obj.Title;
      });

    slide.addText([{ text: this.chartTitle, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.35, bold: true });
  }

  protected async addChartSlide(chartSlide: pptxgen.Slide) {
    const tableData = await this.getMappedChartData(SummaryTableDataModel, this.dataSheet);
    const sortedTableData = tableData.sort((a: any, b: any)=>(a.TitleRank - b.TitleRank || a.TextRank - b.TextRank));
    const newArr: any = [];

    sortedTableData.forEach((obj: any) => {
      const item = newArr.find((item: any) => item.name === obj.TableTitle);
      if(newArr && newArr.length > 0 && item) {
        item.values.push(obj.TableText);
      } else {
        newArr.push(
          {
            name: obj.TableTitle,
            values: [obj.TableText],
          }
        );
      }
    });
    this.generateTable(chartSlide, newArr);
  }

  private generateTable = (chartSlide: pptxgen.Slide, data: any) => {
    const rowTitles: pptxgen.TableRow[] = [];
    data.forEach((obj: any) => {
      rowTitles.push(
        [
          { text: obj.name, options: { fill: { color: '#0153A5' }, fontSize: 11 } }
        ],
        [
          { text: this.convertArrayToString(obj.values), options: { color: '#000000', fill: { color: '#FFFFFF' }, fontSize: 9  } }
        ],
      )
    });
    
    const tableProps: pptxgen.TableProps = {
      x: 0.5,
      y: 0.8,
      newSlideStartY: 1,
      w: '90%',
      h: "90%",
      align: 'left',
      fontSize: 12,
      valign: 'middle',
      rowH: 0.25,
      color: this.colors.white,
      autoPage: true,
      border: { type: 'solid', color: this.colors.neutral, pt: 0.2 },
    };

    const rowText: pptxgen.TableRow[] = [];
    chartSlide.addTable([...rowTitles, ...rowText], tableProps);
  };

  // Used for the text bullets in the rows, so we can still add them in one single row with new lines in between.
  private convertArrayToString = (arr: any) => {
    let str: string = "";
    arr.forEach((obj: any) => {
      str = str + obj + '\r\n'
    });
    return str;
  };
};

import { IDataMapping } from '../../IDataMapping';

export class NPSScoreOverTimeBarDataModel implements IDataMapping<NPSScoreOverTimeBarDataModel> {
    public Label: string = '';
    public Name: string = '';
    public IndicatorName: string = '';
    public Rank: string = '';
    public Value: number = Number(0);
    public Color: string = '';
    public Year: number = Number(0);
    public getMappings?() {
        return {
            Label: "Brand Name",
            Name: "FY_years",
            IndicatorName: "Indicator Name",
            Rank: "MAX(Rank_unique (NPS))",
            Value: "SUM(Indicator value)",
            Color: "NPS Score color",
            Year: 'Year',
        };
    }
}

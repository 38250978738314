import pptxgen from 'pptxgenjs';

import { CommonBarChartTitleModel } from '../../../models/PersonalHealth/CommonBarChart/CommonBarChartTitleModel';
import { CommonBarChartDataModel } from '../../../models/PersonalHealth/CommonBarChart/CommonBarChartDataModel';

import { PHFirstSlideRenderer } from '../../Renderers/PHFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

export class CommonBarChartService extends BasePptxService {
    protected chartQuestion: string = '';
    protected firstSlideRenderer: PHFirstSlideRenderer = new PHFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;
    protected alternativeTitleSheet: string | undefined;
    protected alternativeDataSheet: string | undefined;

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string, alternativeTitleSheet?: string, alternativeDataSheet?: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;

        if (alternativeTitleSheet && alternativeDataSheet) {
            this.alternativeTitleSheet = alternativeTitleSheet;
            this.alternativeDataSheet = alternativeDataSheet;
        }
    }

    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        // Override title's options and add filter's name in it.
        let titleData = await this.getMappedChartData(CommonBarChartTitleModel, this.titleSheet);
        if (titleData.length === 0 && this.alternativeTitleSheet) {
            titleData = await this.getMappedChartData(CommonBarChartTitleModel, this.alternativeTitleSheet);
        }

        let subTitle: string = "";

        titleData.map(obj => {
            this.chartTitle = obj.Title;
            return subTitle = obj.Country + " " + obj.Category;
        });
        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.35, bold: true });
        slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.58, italic: true });
    }

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        let data = await this.getMappedChartData(CommonBarChartDataModel, this.dataSheet);
        if (data.length === 0 && this.alternativeDataSheet) {
            data = await this.getMappedChartData(CommonBarChartDataModel, this.alternativeDataSheet);
        }

        const dataGroupedByRank = data.reduce((r: any, a: any) => {
            r[a.Rank] = [...r[a.Rank] || [], a];
            return r;
        }, {});

        const uniqueSortedItems: any[] = data
            .filter((value: CommonBarChartDataModel, index: any, self: any) => {
                return self.findIndex((e: CommonBarChartDataModel) => (e.Name === value.Name)) === index; })
            .sort((a, b) => a.Name.localeCompare(b.Name));

        const sortedNames = uniqueSortedItems.map(e => e.Name);

        // Add missing data - for the years without data a row with value 'null' would have to be added so that the data on the chart is not displaced
        for (let rank in dataGroupedByRank) {
            const entities = dataGroupedByRank[rank];
            const entity = entities[0];
            if (entities.length < uniqueSortedItems.length) {
                uniqueSortedItems.map(uniqueItem => {
                    if (entities.filter((e: CommonBarChartDataModel) => { return e.Name === uniqueItem.Name; }).length == 0) {
                        let newEntity = new CommonBarChartDataModel();
                        newEntity.Name = uniqueItem.Name;
                        newEntity.Rank = entity.Rank;
                        newEntity.Color = uniqueItem.Color;
                        newEntity.Label = entity.Label;
                        newEntity.Value = null;
                        data.push(newEntity);
                    }
                    return;
                });
            }
        }

        const sortedData = data.slice().sort((a, b) => b.Rank - a.Rank || sortedNames.indexOf(a.Name) - sortedNames.indexOf(b.Name));
        const newArr: any = sortedNames.map(name => ({
            name: name,
            labels: [],
            values: []
        }));

        const lineColors: string[] = uniqueSortedItems.map(obj => obj.Color);

        sortedData.forEach((obj: any) => {
            const item = newArr.find((item: any) => item.name === obj.Name);
            if (newArr && newArr.length > 0 && item) {
                if (item) {
                    item.values.push(parseFloat(obj.Value));
                    item.labels.push(obj.Label);
                }
            }
        })

        chartSlide.addChart(
            this.presentation.ChartType.bar,
            newArr,
            {
                x: 0.5,
                y: 0.7,
                w: "90%",
                h: "70%",
                barDir: "bar",
                showLegend: false,
                legendPos: 'b',
                dataLabelFormatCode: '0%',
                valAxisLabelFormatCode: '0%',
                valAxisLabelFontSize: 10,
                catAxisLabelFontSize: 8,
                chartColors: lineColors,
            }
        );

        this.addLegend(data, chartSlide);
    }

    private addLegend(data: any, chartSlide: pptxgen.Slide) {
        const legend: any = [];
        const newArr: any = [];

        data.forEach((obj: any) => {
            newArr.push(
                {
                    text: obj.Name,
                    options: { fill: { color: obj.Color } }
                }
            )
        });

        const names = newArr.map((o: any) => o.text);
        const filteredNames = newArr.filter(({ text }: any, index: any) => !names.includes(text, index + 1));
        const reversedNames = filteredNames.slice().reverse();
        legend.push(reversedNames);

        const legendProps: pptxgen.TableProps = {
            x: '25%',
            y: '5',
            w: '50%',
            align: 'center',
            bold: true,
            fontSize: 10,
            rowH: 0.1,
            color: this.colors.white,
            border: { type: 'solid', color: this.colors.neutral, pt: 0.2 }
        };

        chartSlide.addTable(legend, legendProps);
    };
}

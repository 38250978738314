import { IDataMapping } from '../../IDataMapping';

export class SPClinicalSegmentLevelModel implements IDataMapping<SPClinicalSegmentLevelModel> {
  public Brand: string = '';
  public Color: string = '';
  public Order: number = -1;
  public Average: number = 0;
  public Question: string = '';
  public Sum: number = 0;

  public getMappings?() {
    return {
      Brand: 'Calc Other Ology Comb',
      Color: 'ATTR(Calc Brand Colors)',
      Order: 'ATTR(Calc Brand Order)',
      Average: 'AVG(Sample size)',
      Question: 'Question',
      Sum: 'SUM(%)'
    };
  }
}

import { IDataMapping } from '../../IDataMapping';

export class PHSustainabilityDataModel implements IDataMapping<PHSustainabilityDataModel> {
    public Label: string = '';
    public Name: string = '';
    public Value: number = Number(0);
    public Color: string = '';
    public TimeRank: number = Number(0);
    public getMappings?() {
        return {
            Label: 'QUARTER(Date)',
            Name: 'Brand',
            Value: 'MAX(Value)',
            Color: 'Colour',
            TimeRank: 'MIN(index_period)',
        };
    }
}

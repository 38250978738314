import pptxgen from 'pptxgenjs';
import { VendorSPGlobalModel } from '../../../';
import { BasePptxService } from '../../BasePptxService';
import { HSStrategicFirstSlideRenderer } from '../../Renderers/HSStrategicFirstSlideRenderer';
import { groupBy } from 'lodash';
import { BaseChartOptions } from '../../../philips-constants';
import { VendorSPTitleModel } from '../../../models/HealthSystems/StrategicPartnership/HospitalIDNStrategicPartner/VendorSP/VendorSPTitleModel';
import { PptxRenderHelper } from '../../../helpers/PptxRenderHelper';

export class VendorSPGlobalPprxService extends BasePptxService {
    protected chartQuestion: string = 'Q: In making the selection of a strategic healthcare technology partner for the entire hospital please select the 3 most important reasons for your choice.';
    protected firstSlideRenderer: HSStrategicFirstSlideRenderer = new HSStrategicFirstSlideRenderer();

    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const mappedTitleData = await this.getMappedChartData(VendorSPTitleModel, 'Most Important Attributes Driving Partner Choice_Label TEXT');

        let customTitle: string | pptxgen.TextProps[] = [];
        mappedTitleData.forEach(obj => {
            customTitle = [
                { text: `${obj.Title}${obj.Country} - ` },
                {
                    text: `${obj.Period}`,
                    options: { color: this.colors.negative }
                },
                {
                    text: `${obj.WarningText}`,
                    options: { color: this.colors.negative }
                }
            ];
        });

        slide.addText(customTitle, { x: 0.6, y: 0.55, bold: true, color: this.colors.default, fontSize: 18 });
        slide.addText([{ text: this.chartQuestion, options: { color: this.colors.black, fontSize: 7 } }], { x: 0.5, y: '95%', bold: false });
    }

    protected async addChartSlide(chartSlide: pptxgen.Slide): Promise<void> {
        const data = await this.getMappedChartData(VendorSPGlobalModel, 'Most Important Attributes Driving Partner Choice');
        if (!data || !data.length) {
            return;
        }
        const groups = Object.values(groupBy(data, d => d.Brand))
            .sort((a, b) => a[0].BrandOrder - b[0].BrandOrder);
        groups.forEach((group, index) => {
            let width = 8 / groups.length;
            let showCatAxisLabel = false;
            const x = index * width + (index >= 1 ? 1.5 : 0.5);
            if (index === 0) {
                width += 1;
                showCatAxisLabel = true;
            }
            const colors = PptxRenderHelper.getMappedChartDataColorsSP(group);
            const chartData = group
                .sort((a, b) => a.DriverOrder - b.DriverOrder)
                .reduce((acc, curr) => ({
                    labels: [curr.Selection, ...acc.labels],
                    values: [curr.Score, ...acc.values],
                }), { labels: [] as string[], values: [] as number[] });

            chartSlide.addChart(
                this.presentation.ChartType.bar,
                [chartData],
                {
                    ...BaseChartOptions,
                    x,
                    y: 1.2,
                    w: width,
                    h: 3.7,
                    barDir: 'bar',
                    showValue: true,
                    chartColors: colors,
                    barGapWidthPct: 15,
                    valAxisLineShow: false,
                    valAxisHidden: true,
                    catAxisLabelPos: showCatAxisLabel ? 'low' : undefined,
                    catAxisHidden: !showCatAxisLabel,
                    dataLabelFormatCode: '0%',
                    dataLabelPosition: 'outEnd',
                    dataLabelFontBold: true,
                    dataLabelColor: this.colors.default,
                }
            );

            chartSlide.addText(group[0].Brand, {
                x: x + (index === 0 ? width / 2.5 : 0) + (width * 0.05),
                y: 1.15,
                color: colors[0],
                bold: true,
                fontSize: 12,
            });

            const average = group.filter(x => x.Average !== null).first().Average.toString() as string;

            chartSlide.addText(`N= ${average}`, {
                x: x + (index === 0 ? width / 2.5 : 0) + (width * 0.05),
                y: 5,
                color: this.colors.default,
                fontSize: 8
            });
        });
    }
}

import { IDataMapping } from '../../IDataMapping';

export class CommonOverTimeLineChartDataModel implements IDataMapping<CommonOverTimeLineChartDataModel> {
    public Label: string = '';
    public Name: string = '';
    public Value: number = Number(0);
    public Rank: number = Number(0);
    public Color: string = '';
    public getMappings?() {
        return {
            Label: 'QUARTER(Date_calculation)',
            Name: 'brand_name',
            Value: 'AVG(indicator_value)',
            Rank: 'AGG(IPL Brands Rank)',
            Color: 'ATTR(brand_color)',
        };
    }
}

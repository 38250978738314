import { IDataMapping } from '../../IDataMapping';

export class BMCPreferenceScoreTitleModel implements IDataMapping<BMCPreferenceScoreTitleModel> {
    public Level: string = '';
    public Title: string = '';
    public SubTitle: string = '';
    public Age: string = '';
    public Gender: string = '';
    public getMappings?() {
        return {
            Level: 'Category',
            Title: 'Preference Score',
            SubTitle: 'Country / Market',
            Age: 'age_group_blank',
            Gender: 'Agender Name_blank',
        };
    }
}

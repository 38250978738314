import { IDataMapping } from '../../IDataMapping';

export class MarketOverviewDataModel implements IDataMapping<MarketOverviewDataModel> {
    public Category: string = '';
    public Other: string = '';
    public Brand: string = '';
    public Value: number = Number(0);
    public PhilipsColor: number = Number(0);
    public Date: string = '';
    public DifferenceColor: string = '';
    public FirstQuarterDate: string = '';
    public getMappings?() {
        return {
            Category: "Category",
            Other: "Other sort",
            Brand: 'Brand Other_MO',
            Value: 'Measure Values',
            Date: 'ATTR(Selected period Title)',
            PhilipsColor: 'Philips color',
            DifferenceColor: "AGG(Color Diff)",
            FirstQuarterDate: 'ATTR(Selected period-1 Title_Export)',
        };
    }
}

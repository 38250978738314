import pptxgen from 'pptxgenjs';
import { BaseFirstSlideRenderer } from './BaseFirstSlideRenderer';

export class PHBSFirstSlideRenderer extends BaseFirstSlideRenderer {
    private options = {
        x: '8%',
        color: this.color,
        fontSize: 11
    };

    renderFirstSlide(presentation: pptxgen, chartTitle: string): void {
        const firstSlide = presentation.addSlide({ masterName: 'PHILIPS_FIRST_BS_MASTER' });

        firstSlide.addText('Brand Team, MI&A Brand & Customer Experience IA', { y: '65%', bold: true, ...this.options });

        firstSlide.addText('Marketing & E-Commerce', { y: '70%', ...this.options });

        firstSlide.addText('Personal Health', { y: '75%', ...this.options });

        firstSlide.addText(chartTitle, { y: '80%', ...this.options });
    };
}

import { IDataMapping } from '../../../IDataMapping';

export class AppendixUSDataModel implements IDataMapping<AppendixUSDataModel> {
    public Name: string = '';
    public Label: string = '';
    public Color: string = '';
    public Category: string = '';
    public Value: number = Number(0);
    public Rank: number = Number(0);
    public LabelLegend: string = '';
    public BrandBase: string = '';
    public ValueBase: number = Number(0);
    public RankBase: number = Number(0);
    public getMappings?() {
        return {
            Name: 'Answer_Text',
            Label: 'ATTR(Brand (copy))',
            Color: 'AGG(BrandColors)',
            Category: 'Category',
            Value: "SUM(Value)",
            Rank: 'AGG(INDEX())',
            LabelLegend: 'Brand',
            BrandBase: 'ATTR(Brand Abbreviation (copy))',
            ValueBase: 'AVG(KDA Base Count)',
            RankBase: 'MAX(pref value check rank (Q))',
        };
    }
}

import pptxgen from 'pptxgenjs';
import { BasePptxService } from '../../BasePptxService';
import { HSFirstSlideRenderer } from '../../Renderers/HSFirstSlideRenderer';
import { groupBy } from 'lodash';
import { GlobalLeadershipStatusModel } from '../../../';

export class GlobalLeadershipStatusPptxService extends BasePptxService {
  protected firstSlideRenderer = new HSFirstSlideRenderer();

  protected chartQuestion: string = '';

  private colorsByValue: any = {
    'Leader': this.colors.positive,
    'Co-Leader': this.colors.neutral,
    'Follower': this.colors.negative
  };

  protected async addChartSlide(chartSlide: pptxgen.Slide): Promise<void> {
    const data = (await this.getMappedChartData(GlobalLeadershipStatusModel, 'Global_stacked bar chart'))
      .reverse();

    const chartData = Object.values(groupBy(data, x => x.Leadership))
      .map(x => x.reduce((acc, curr) => ({
        labels: [...acc.labels, curr.Year],
        values: [...acc.values, curr.Sum]
      }), { labels: [] as string[], values: [] as string[] }))

    chartSlide.addChart(
      this.presentation.ChartType.bar,
      chartData,
      {
        x: 2,
        y: 1.5,
        w: 5.7,
        h: 3,
        barDir: 'col',
        barGrouping: 'stacked',
        showValue: true,
        valAxisLineShow: false,
        chartColors: [this.colorsByValue['Leader'], this.colorsByValue['Co-Leader'], this.colorsByValue['Follower']],
        valGridLine: { style: 'none' },
        dataLabelFormatCode: '0%',
        valAxisHidden: true,
        catAxisLabelFontSize: 8,
        dataLabelFontSize: 12,
        dataLabelFontBold: true,
        dataLabelColor: this.colors.white
      }
    );

    this.addLegend(chartSlide);
  }

  private addLegend(slide: pptxgen.Slide) {
    slide.addText(
      [{ text: 'Leader', options: { color: this.colorsByValue['Leader'], fontSize: 10, bold: true } }],
      { x: 3, y: 4.7 }
    );

    slide.addText(
      [{ text: 'Follower', options: { color: this.colorsByValue['Follower'], fontSize: 10, bold: true } }],
      { x: 4, y: 4.7 }
    );
  }
}

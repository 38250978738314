import { IDataMapping } from '../IDataMapping';

export class SPGlobalModel implements IDataMapping<SPGlobalModel>{
    public Answer: string = '';
    public Sum: number | null = 0;
    public AveragePositive: number | null = 0;
    public AverageNegative: number | null = 0;

    public getMappings?() {
        return {
            Answer: 'Answer',
            Sum: 'SUM(%)',
            AveragePositive: 'AGG(Sample Size Color <50 BLACK)',
            AverageNegative: 'AGG(Sample Size Color <50 RED)',
        };
    }
}

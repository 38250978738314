import { IDataMapping } from '../../IDataMapping';

export class NineGLineChartVolumeTitleModel implements IDataMapping<NineGLineChartVolumeTitleModel> {
    public Title: string = '';
    public IndexTitle: string = '';
    public getMappings?() {
        return {
            Title: '9G Titles (Market Share Volume)',
            IndexTitle: '9G Titles (Index)',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class NPSScoreOverTimeAdditionalBarDataModel implements IDataMapping<NPSScoreOverTimeAdditionalBarDataModel> {
    public Label: string = '';
    public Name: string = '';
    public Date: number = Number(0);
    public AggPositive: number = Number(0);
    public AggNegative: number = Number(0);
    public Rank: number = Number(0);
    public RankWindow: number = Number(0);
    public Value: number = Number(0);
    public RelativeNps: number = Number(0);
    public getMappings?() {
        return {
            Label: 'NPS (label)',
            Name: 'Brand Name',
            Date: 'Year',
            AggPositive: 'AGG(Min 1)',
            AggNegative: 'AGG(Min(-1))',
            Rank: 'MAX(Rank_unique (NPS))',
            RankWindow: 'AGG(Rank_window 2)',
            Value: 'SUM(Indicator value)',
            RelativeNps: 'SUM(Relative_NPS_calc)',
        };
    }
}

import { ApplicationUserViewModel } from '../../models/User/ApplicationUserViewModel';
import { UserDevice } from '../../shared/enums/UserDevice';

export enum UserActionTypes {
  SET_USER_PROFILE = 'SET_USER_PROFILE',
  SET_USER_DEVICE = 'SET_USER_DEVICE',
}

export type UserActions =
  | ISetUserProfileAction
  | ISetUserDeviceAction;

export interface ISetUserProfileAction {
  type: UserActionTypes.SET_USER_PROFILE;
  userProfile: ApplicationUserViewModel;
}

export const setUserProfile = (
  userProfile: ApplicationUserViewModel
): ISetUserProfileAction => ({
  type: UserActionTypes.SET_USER_PROFILE,
  userProfile,
});

export interface ISetUserDeviceAction {
  type: UserActionTypes.SET_USER_DEVICE;
  device: UserDevice;
}

export const setUserDevice = (
  device: UserDevice
): ISetUserDeviceAction => ({
  type: UserActionTypes.SET_USER_DEVICE,
  device,
});

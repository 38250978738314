import { IDataMapping } from '../../../../IDataMapping';

export class PHBSMarketPerformanceChartBubbleDataModel implements IDataMapping<PHBSMarketPerformanceChartBubbleDataModel> {
    public Answer:          string = '';
    public BrandForChart:   string = '';
    public BrandForLegend:  string = '';
    public Color:           string = '';
    public RowOrder:        number = Number(0);
    public Value:           number = Number(0);
    public FilterAudience:  string = '';
    public Check:           number = Number(0);
    public Factor:          string = '';

    public getMappings?() {
        return {
            Answer:         'Answer',
            Color:          'ATTR(Colour)',
            Value:          'AVG(Value)',
            FilterAudience: 'ATTR(Audience)',
            BrandForChart:  'ATTR(Brand (copy))',
            BrandForLegend: 'Brand',
            RowOrder:       'AGG(INDEX())',
            Check:          'AGG(AVG(-0.05))',
            Factor:         'ATTR(BS_statement)'
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class MCCLineChartTitleModel implements IDataMapping<MCCLineChartTitleModel> {
    public Title: string = '';
    public Country: string = '';
    public Category: string = '';
    public Period: string = '';
    public getMappings?() {
        return {
            Title: 'MCC Titles',
            Country: "country_name",
            Category: "Category Short",
            Period: 'ATTR(MAT/Quarter)',
        };
    }
}

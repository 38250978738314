import { IDataMapping } from "../../../IDataMapping";

export class NPSTrendsQuarterlyLegendDataModel implements IDataMapping<NPSTrendsQuarterlyLegendDataModel> {
    public Value: number = Number(0);
    public RankBrandColor: string = '';
    public BaseColor: string = '';
    public getMappings?() {
        return {
            Value: 'SUM(Calc Last Period)',
            RankBrandColor: 'AGG(rank + brand)',
            BaseColor: 'AGG(Base color RED (NPS))',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class NineGLineChartIndexTitleModel implements IDataMapping<NineGLineChartIndexTitleModel> {
    public Title: string = '';
    public getMappings?() {
        return {
            Title: '9G Titles (Index)',
        };
    }
}

import pptxgen from 'pptxgenjs';

import { MarketOverviewTitleModel } from '../../../models/SRC/MarketOverview/MarketOverviewTitleModel';
import { MarketOverviewDataModel } from '../../../models/SRC/MarketOverview/MarketOverviewDataModel';

import { SRCFirstSlideRenderer } from '../../Renderers/SRCFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

export class MarketOverviewTableService extends BasePptxService {
  protected chartQuestion: string = '';
  protected firstSlideRenderer: SRCFirstSlideRenderer = new SRCFirstSlideRenderer();
  protected titleSheet: string;
  protected dataSheet: string;

  constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
      super(view, chartTitle);
      this.titleSheet = titleSheet;
      this.dataSheet = dataSheet;
  }

  protected async setChartSlideLayout(slide: pptxgen.Slide) {
    // Override title.
    this.chartTitle = this.titleSheet;
    const titleData = await this.getMappedChartData(MarketOverviewTitleModel, this.titleSheet);
    let subTitle: string = "";

    titleData.map( (obj: any) => {
      this.chartTitle = obj.Title;
      return subTitle = obj.SubTitle;
    });
    
    slide.addText([{ text: this.chartTitle, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.35, bold: true });
    slide.addText([{ text: subTitle, options: { color: this.colors.black, fontSize: 12 } }], { x: 0.5, y: 0.58, italic: true });
  }

  protected async addChartSlide(chartSlide: pptxgen.Slide) {
    const dataSheet = await this.getMappedChartData(MarketOverviewDataModel, this.dataSheet);
    const sortCategories: any = [];
    dataSheet.forEach((obj: any) => {
      const item = sortCategories.find((item: any) => item.category === obj.Category);
      if(sortCategories.length > 0 && item) {
        const hasBrand = item.brandWithValues.find((brandObj: any) => brandObj.brand === obj.Brand);
        if (hasBrand) {
          hasBrand.value.push(obj.Value);
        } else {
          item.brandWithValues.push(
            {
              brand: obj.Brand,
              value: [obj.Value],
              philipsColor: obj.PhilipsColor,
              differenceColor: obj.DifferenceColor,
            }
          );
        }
      } else {
        sortCategories.push(
          {
            category: obj.Category,
            brandWithValues: [
              {
                brand: obj.Brand,
                value: [obj.Value],
                philipsColor: obj.PhilipsColor,
                differenceColor: obj.DifferenceColor,
              }
            ]
          }
        )
      }
    });

    const tableProps: pptxgen.TableProps = {
      y: 0.78,
      w: '92%',
      h: '78%',
      align: 'center',
      fontSize: 7,
      valign: 'middle',
      rowH: 0.2,
      color: this.colors.white,
      autoPage: true,
      newSlideStartY: 0.78,
      border: { type: 'solid', color: this.colors.neutral, pt: 0.2 },
      autoPageCharWeight: 1,
      autoPageRepeatHeader: true,
    };

    const createTitleRows = this.generateTitleRows(dataSheet);
    const createBodyRows = this.generateBodyRows(sortCategories);
    chartSlide.addTable([...createTitleRows, ...createBodyRows], tableProps);
    chartSlide.addText([{ text: "≥+3% when compared to previous period", options: { color: this.colors.positive, fontSize: 7 } }], { x: 6, y: 5.3, bold: false });
    chartSlide.addText([{ text: "≤-3% when compared to previous period", options: { color: this.colors.negative, fontSize: 7 } }], { x: 6, y: 5.4, bold: false });
}

  private generateTitleRows = (quarterTitles: any) => {
    const rowTitles: pptxgen.TableRow[] = []
    let firstQuarterDate = "";
    let secondQuarterDate = "";

    quarterTitles.forEach((obj: any) => {
      if (obj.FirstQuarterDate) {
        firstQuarterDate = obj.FirstQuarterDate;
      }
      if (obj.Date) {
        secondQuarterDate = obj.Date;
      }
    })
    
    const arr = [
      {
        text: 'Category', options: { fill: { color: '#0153A5' }, colspan:2,} ,
      },
      {
        text: firstQuarterDate, options: { fill: { color: '#0153A5' } } ,
      },
      {
        text: secondQuarterDate, options: { fill: { color: '#0153A5' } } ,
      },
      {
        text: 'Difference in Score with Previous Period', options: { fill: { color: '#0153A5' } } ,
      }
    ];
    rowTitles.push(arr);
    return rowTitles;
  }

  private generateBodyRows = (tableData: any) => {
    const createRows: any = [];
    tableData.forEach((obj: any) => {
      const arr: any = [];
      obj.brandWithValues.forEach((brandObj: any, index: number) => {
        const brands: any = [];
        // Add category before all.
        if (index === 0) {
          brands.push({
            text: obj.category,
            options: { color: '#000000', },
          });
        } else {
          brands.push({
            text: "",
            options: { color: '#000000', },
          });
        }
        // Add brand.
        brands.push({
          text: brandObj.brand,
          options: { color: '#000000', fill: brandObj.philipsColor ? '#CBD3E0' : '#FFFFFF'},
        });
        // Last - add values.
        const values: any = [];
        brandObj.value.forEach((val: any, indexValue: number) => {
          let diffValueColor = '#000000';
          // Assign the specific color only for the 0 index value 
          // since we reverse the array later and make it last - for the difference table.
          if (indexValue === 0) {
            diffValueColor = this.getColorByDifferenceValue(brandObj.differenceColor)
          } 

          values.push({
            text: val ? `${(parseFloat(val)* 100).toFixed(0)}%` : "",
            options: { color: diffValueColor, fill: brandObj.philipsColor ? '#CBD3E0' : '#FFFFFF'},
          });
        });

        const reversedValues = values.slice().reverse();
        brands.push(...reversedValues);
        return arr.push(brands);
      });

      return createRows.push(...arr);
    })

    return createRows;
  }

  private getColorByDifferenceValue = (value: string) => {
    let color = '#000000'
    if (value === "Neutral") {
      color = '#000000';
    } else if (value === "Positive") {
      color = '#0e7605';
    } else if (value === "Negative") {
      color = '#FF0000';
    }
    return color;
  }
}

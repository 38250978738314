import { IDataMapping } from '../../IDataMapping';

export class RecommendationsTitleModel implements IDataMapping<RecommendationsTitleModel> {
    public Title: string = '';
    public Country: string = '';
    public Category: string = '';
    public Filter: string = '';
    public getMappings?() {
        return {
            Title: 'Recommendations Titles',
            Country: "country_name",
            Category: "Category Short",
            Filter: 'ATTR(Recommendation filter)',
        };
    }
}

import { IDataMapping } from '../../../../IDataMapping';

export class SPVendorPieChartTitleModel implements IDataMapping<SPVendorPieChartTitleModel> {
    public Title: string = '';
    public Period: string = '';
    public Country: string = '';
    public Segment: string = '';
    public getMappings?() {
        return {
            Title: 'Calc MIA Ology Pie TEXT',
            Period: 'Period',
            Country: 'Calc Country',
            Segment: 'Segment',
        };
    }
}

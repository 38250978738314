import pptxgen from 'pptxgenjs';

import { BasePptxService } from '../../../../BasePptxService';
import { HSFirstSlideRenderer } from '../../../../Renderers/HSFirstSlideRenderer';
import { PptxRenderHelper } from '../../../../../helpers/PptxRenderHelper';

import { NpsAttributesDeltaChartDataModel } from '../../../../../models/HealthSystems/ProductLevelNPS/Attributes/NpsAttributesDeltaChart/NpsAttributesDeltaChartDataModel';

/**
 * Note: The order of the table rows highly depends on the order of the incoming data.
 */

export class NpsAttributesDeltaChartService extends BasePptxService {
    protected chartQuestion: string = 'Please select which vendor performs better regarding these attributes.';
    protected firstSlideRenderer = new HSFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;

    private colorsByValue: any = {
        'Philips leads by > 20%': this.colors.positive,
        'Philips leads by > 10% and ≤ 20%': this.colors.lightPositive,
        'Philips leads by ≤ 10%': this.colors.ultraLightPositive,
        'Philips following by > 20%': this.colors.negative,
        'Philips following by > 10% and ≤ 20%': this.colors.lightNegative,
        'Philips following by ≤ 10%': this.colors.ultraLightNegative,
    };

    protected bullets: any = {
        Up: { code: '2B08', color: '1a660f' },
        Down: { code: '2B0A', color: 'bf0103' }
    };

    protected arrowsByValue: any = {
        '1': this.bullets.Up,
        '-1': this.bullets.Down
    };

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
    }

    // Override of a super class method
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const mappedChartData = await this.getMappedChartData(NpsAttributesDeltaChartDataModel, this.dataSheet);

        if (mappedChartData.length > 0) {
            const chartData = this.removeDuplicates(mappedChartData);

            // tables
            const countries = this.getCountries(chartData)
            const numberOfCountries = countries.length;

            const headerData = this.getHeaderTableRow(chartData);

            const groupData = this.groupDataByRows(chartData);

            const tables = this.splitRowsByTables(groupData);

            // options
            const colW: number[] = [1.5, 1.5, ...Array(numberOfCountries).fill(0.5)];
            const tableWidth = colW.reduce((a: number, b: number) => a + b, 0);
            const x = (10 - tableWidth) / 2;

            const options: pptxgen.TableProps = {
                x,
                y: 1,
                w: tableWidth,
                align: 'center',
                valign: 'middle',
                border: { type: 'solid', pt: 0.5, color: this.colors.border },
                fontSize: 7,
                rowH: 0.25,
                autoPage: true,
                autoPageRepeatHeader: true,
                newSlideStartY: 0.8,
                autoPageCharWeight: 1,
                margin: [0, 0, 0, 5],
                colW
            };

            // execution
            tables.forEach((table, index) => {
                const currentSlide = this.getCurrentSlide(chartSlide, index);
                const bodyData = this.getBodyTableRows(table, numberOfCountries, countries);

                currentSlide.addTable([...headerData, ...bodyData], options);
                this.addLegend(currentSlide, x);
            });
        }
    }

    private getCurrentSlide(chartSlide: pptxgen.Slide, index: number) {
        let currentSlide = chartSlide;

        if (index !== 0) {
            currentSlide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });
            this.setChartSlideLayout(currentSlide);
        }

        return currentSlide;
    }

    private removeDuplicates(data: NpsAttributesDeltaChartDataModel[]) {
        let cleanData: NpsAttributesDeltaChartDataModel[] = [];

        cleanData = data.filter((obj, index, self) =>
            index === self.findIndex((o) => (
                o.value === obj.value && o.countryOrder === obj.countryOrder &&
                o.driver === obj.driver && o.subDriver === obj.subDriver
            ))
        )

        return cleanData;
    }

    private getCountries(data: NpsAttributesDeltaChartDataModel[]) {
        data.sort((a, b) => a.countryOrder - b.countryOrder)

        const countries: string[] = [];

        data.forEach(obj => {
            if (!countries.includes(obj.country)) {
                countries.push(obj.country);
            }
        });

        return countries;
    }

    private getHeaderTableRow(data: NpsAttributesDeltaChartDataModel[]) {
        const countries = ["", "", ...this.getCountries(data)];

        const tableRow: pptxgen.TableRow[] = [];
        const tableCells: pptxgen.TableCell[] = [];

        // header row
        countries.forEach((countryName: string) => {
            tableCells.push(
                {
                    text: countryName,
                    options: {
                        bold: true,
                        fill: { color: this.colors.default },
                        color: this.colors.white,
                        border:
                            [
                                { type: 'solid', pt: 0.5, color: this.colors.border },
                                { type: 'solid', pt: 0.5, color: this.colors.white },
                                { type: 'solid', pt: 0.5, color: this.colors.border },
                                { type: 'solid', pt: 0.5, color: this.colors.white },
                            ]
                    }
                }
            );
        });
        tableRow.push(tableCells);

        return tableRow;
    }

    private groupByProperty(array: NpsAttributesDeltaChartDataModel[], property: string) {

        const groupData: NpsAttributesDeltaChartDataModel[][] = [];

        // Grouping the data in rows
        for (let obj of array) {
            let flagMakeNewGroup = true;
            // Checks If there is group/array for this object
            for (let arr of groupData) {
                for (let element of arr) {
                    if (element[property as keyof NpsAttributesDeltaChartDataModel] === obj[property as keyof NpsAttributesDeltaChartDataModel]) {
                        arr.push(obj);
                        flagMakeNewGroup = false;
                    }
                    break;
                }
            }
            // Creates new group/array
            if (flagMakeNewGroup) {
                groupData.push([obj]);
            }
        }

        return groupData;
    }

    private groupDataByRows(data: NpsAttributesDeltaChartDataModel[]) {
        const groupData: NpsAttributesDeltaChartDataModel[][] = [];

        // Grouping the data by driver
        const driverGroups: NpsAttributesDeltaChartDataModel[][] = this.groupByProperty(data, "driver");

        // Grouping the data by subDriver/rows
        for (let driverGroup of driverGroups) {
            let rows = this.groupByProperty(driverGroup, "subDriver");
            rows.reverse();

            groupData.push(...rows);
        }

        // sorting by columns
        groupData.forEach(arr => {
            arr.sort((a, b) => a.countryOrder - b.countryOrder);
        })

        return groupData;
    }

    private splitRowsByTables(groupData: NpsAttributesDeltaChartDataModel[][]) {
        const tables: NpsAttributesDeltaChartDataModel[][][] = [];

        // full tables
        const rowsPerTable = 10;
        const numberOfFullTables = Math.trunc(groupData.length / rowsPerTable);
        for (let i = 0; i < numberOfFullTables; ++i) {
            tables.push(groupData.slice((i * rowsPerTable), (i * rowsPerTable) + rowsPerTable));
        }

        // last smaller table
        const lastTable = groupData.length % rowsPerTable;
        if (lastTable) {
            tables.push(groupData.slice((groupData.length - lastTable), groupData.length));
        }

        return tables;
    }

    private countRowspan(groupData: NpsAttributesDeltaChartDataModel[][], currentIndex: number, objectKey: string) {
        let rowspan = 1;

        let currentGroup;
        groupData[currentIndex].forEach(obj => {
            currentGroup = obj[objectKey as keyof NpsAttributesDeltaChartDataModel];
        })

        for (let row = (currentIndex + 1); row < groupData.length; ++row) {
            let nextGroup;
            groupData[row].forEach(obj => {
                nextGroup = obj[objectKey as keyof NpsAttributesDeltaChartDataModel];
            })

            if (currentGroup === nextGroup) {
                ++rowspan;
            }
        }

        return rowspan;
    }

    private getBodyTableRows(groupData: NpsAttributesDeltaChartDataModel[][], numberOfCountries: number, countries: any) {
        let tableRows: pptxgen.TableRow[] = [];

        let usedDriver: string;

        groupData.forEach((row, index) => {
            let currentDriver: string = "";
            let currentSubDriver: string = "";

            // array initialized with default/empty values, which will be filled with real values later
            const values: pptxgen.TableCell[] = Array(numberOfCountries).fill(
                {
                    text: "",
                    options: { margin: [0, 3, 0, 0], color: this.colors.black, fill: { color: this.colors.white }, align: 'right', bullet: false }
                }
            );

            // value rows
            row.forEach(obj => {
                if (obj.value || obj.value === 0) {
                    if (obj.arrow) {
                        // bullet
                        values[countries.indexOf(obj.country)] =
                        {
                            text: `${(obj.value * 100).toFixed()}%` !== '-0%' ? `${(obj.value * 100).toFixed()}%` : '0%',
                            options: {
                                bullet: { characterCode: this.arrowsByValue[obj.arrow].code, indent: 15 },
                                margin: [0, 3, 0, 0], color: this.colors.black, fill: { color: this.colorsByValue[obj.color] }, align: 'right'
                            }
                        }
                    } else {
                        values[countries.indexOf(obj.country)] =
                        {
                            text: `${(obj.value * 100).toFixed()}%` !== '-0%' ? `${(obj.value * 100).toFixed()}%` : '0%',
                            options: { margin: [0, 3, 0, 0], color: this.colors.black, fill: { color: this.colorsByValue[obj.color] }, align: 'right' }
                        }
                    }
                }

                currentDriver = obj.driver;
                currentSubDriver = obj.subDriver;
            });

            // Driver and subDriver
            if (tableRows.length === 0) {

                // first row only
                const rowspan = this.countRowspan(groupData, index, "driver");
                const tableCells: pptxgen.TableCell[] =
                    [
                        {
                            text: currentDriver,
                            options: { fill: { color: this.colors.white }, align: 'left', rowspan: rowspan }
                        },
                        {
                            text: currentSubDriver,
                            options: { fill: { color: this.colors.white }, align: 'left' }
                        },
                        ...values
                    ];
                tableRows.push(tableCells);
                usedDriver = currentDriver;

            } else {
                let tableCells: pptxgen.TableCell[] = [];

                if (currentDriver !== usedDriver) {
                    const rowspan = this.countRowspan(groupData, index, "driver")
                    tableCells.push(
                        {
                            text: currentDriver,
                            options: { fill: { color: this.colors.white }, align: 'left', rowspan }
                        }
                    );
                }

                tableCells.push(
                    {
                        text: currentSubDriver,
                        options: { fill: { color: this.colors.white }, align: 'left' }
                    },
                    ...values
                );
                tableRows.push(tableCells);
                usedDriver = currentDriver;
            }
        });

        return tableRows;
    }

    private addLegend(chartSlide: pptxgen.Slide, optionX: number) {
        PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: `Philips leads by > 20%`, color: this.colorsByValue["Philips leads by > 20%"] }, { x: optionX, y: 4 });
        PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: `Philips leads by > 10% and ≤ 20%`, color: this.colorsByValue["Philips leads by > 10% and ≤ 20%"] }, { x: optionX, y: 4.2 });
        PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: `Philips leads by ≤ 10%`, color: this.colorsByValue["Philips leads by ≤ 10%"] }, { x: optionX, y: 4.4 });
        PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: `Philips following by ≤ 10%`, color: this.colorsByValue["Philips following by ≤ 10%"] }, { x: optionX, y: 4.6 });
        PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: `Philips following by > 10% and ≤ 20%`, color: this.colorsByValue["Philips following by > 10% and ≤ 20%"] }, { x: optionX, y: 4.8 });
        PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: `Philips following by > 20%`, color: this.colorsByValue["Philips following by > 20%"] }, { x: optionX, y: 5.0 });
    }
}

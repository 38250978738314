import { IDataMapping } from '../../IDataMapping';

export class ProductExperienceTornadoDataModel implements IDataMapping<ProductExperienceTornadoDataModel> {
    public Name: string = '';
    public ChartTitle: string = '';
    public Color: string = '';
    public Value: number = Number(0);
    public Brand: string = '';
    public getMappings?() {
        return {
            Name: "Indicator Name",
            ChartTitle: 'Header_tornado chart',
            Color: "PE colors",
            Value: 'SUM(Difference_total)',
            Brand: 'ATTR(Brand Name ranked)',
        };
    }
}

import { IDataMapping } from '../../../../IDataMapping';

export class SRCBSPerformanceChartTornadoDataModel implements IDataMapping<SRCBSPerformanceChartTornadoDataModel> {
    public Answer:          string = '';
    public Value:           number = Number(0);
    public FilterBrand:     string = '';
    public Check:           number = Number(0);
    public Factor:          string = '';

    public getMappings?() {
        return {
            Answer:         'Answer',
            Value:          'SUM(Value Difference)',
            FilterBrand:    'ATTR(Brand)',
            Check:          'AGG(AVG(-1))',
            Factor:         'ATTR(BS_statement)'
        };
    }
}

import { IDataMapping } from '../../../IDataMapping';

export class BSAnalysisTableHeaderRowModel implements IDataMapping<BSAnalysisTableHeaderRowModel> {
    public Answer: string = '';

    public getMappings?() {
        return {
            Answer: 'Answer',
        };
    }
}

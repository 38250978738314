export enum UserActionTypes {
  SET_FIRST_NAME = 'SET_FIRST_NAME',
  SET_LAST_NAME = 'SET_LAST_NAME',
  SET_EMAIL = 'SET_EMAIL',
  SET_COMPANY = 'SET_COMPANY',
  SET_DEPARTMENT = 'SET_DEPARTMENT',
  SET_POSITION = 'SET_POSITION',
  SET_SITE = 'SET_SITE',
  SET_USER_ROLES = 'SET_USER_ROLES',
  SET_USER_STATUS = 'SET_USER_STATUS',
  SET_COUNTRY = 'SET_COUNTRY',
  RESET_STATE = 'RESET_STATE',
}

export const setFirstName = (payload : string) => {
	return {
		type: UserActionTypes.SET_FIRST_NAME,
		payload,
	};
};

export const setLastName = (payload : string) => {
	return {
		type: UserActionTypes.SET_LAST_NAME,
		payload,
	};
};

export const setEmail = (payload : string) => {
	return {
		type: UserActionTypes.SET_EMAIL,
		payload,
	};
};

export const setCompany = (payload : string) => {
	return {
		type: UserActionTypes.SET_COMPANY,
		payload,
	};
};

export const setDepartment = (payload : string) => {
	return {
		type: UserActionTypes.SET_DEPARTMENT,
		payload,
	};
};

export const setPosition = (payload : string) => {
	return {
		type: UserActionTypes.SET_POSITION,
		payload,
	};
};

export const setSite = (payload : string) => {
	return {
		type: UserActionTypes.SET_SITE,
		payload,
	};
};

export const setUserRoles = (payload : string[]) => {
	return {
		type: UserActionTypes.SET_USER_ROLES,
		payload,
	};
};

export const setUserStatus = (payload : any) => {
	return {
		type: UserActionTypes.SET_USER_STATUS,
		payload,
	};
};

export const setCountry = (payload : any) => {
	return {
		type: UserActionTypes.SET_COUNTRY,
		payload,
	};
};

export const resetState = (payload : string) => {
	return {
		type: UserActionTypes.RESET_STATE,
    payload,
	};
};
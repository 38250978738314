export class TopDriversDataModel {
    public IGTS: DriverModel[] = [];
    public IGTD: DriverModel[] = [];
    public EDI: DriverModel[] = [];
    public DI: DriverModel[] = [];
    public US: DriverModel[] = [];
    public MA: DriverModel[] = [];
    public TC: DriverModel[] = [];
}

// tslint:disable-next-line: max-classes-per-file
export class DriverModel {
    public text: string;
    public value: string;
    public color: string;

    constructor(text: string, value: string, color: string) {
        this.text = text;
        this.value = value;
        this.color = color;
    }
}

import { IDataMapping } from '../../../../IDataMapping';

export class HospitalIDNPieChartFirstDataModel implements IDataMapping<HospitalIDNPieChartFirstDataModel> {
    public Answer: string = '';
    public AveragePositive: number | null = 0;
    public AverageNegative: number | null = 0;
    public Sum: number | null = 0;
    public Color: string = '';

    public getMappings?() {
        return {
            Answer: 'Answer',
            AveragePositive: 'AGG(Sample Size Color <50 BLACK)',
            AverageNegative: 'AGG(Sample Size Color <50 RED)',
            Sum: 'SUM(%)',
            Color: 'ATTR(Hospital/IDN level Pie_Color_Exports)',
        };
    }
}

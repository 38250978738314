import { IDataMapping } from "../../../../IDataMapping";

export class BSByStatementOverTimeChartTitleModel implements IDataMapping<BSByStatementOverTimeChartTitleModel> {
    public Title:       string = '';
    public SubTitle:    string = '';
    public Name:        string = '';
    public Indicator:   string = '';
    public Audience:    string = '';

    public getMappings?() {
        return {
            Title:      'Brand Strength by Statement Over Time Title',
            SubTitle:   'Brand Strength Analysis Sub-Ttitle',
            Name:       'ATTR(Brand)',
            Indicator:  'ATTR(BS_statement)',
            Audience:   'ATTR(Audience_tbl)',
        };
    }
}

import { IDataMapping } from '../../../IDataMapping';

export class PHBSTotalChartTitleModel implements IDataMapping<PHBSTotalChartTitleModel> {
    public Title: string = '';
    public SubTitle: string = '';
    public Audience: string = '';
    public Period: string = '';
    public Filter: string = '';

    public getMappings?() {
        return {
            Title:      'Brand Strength Total Title',
            SubTitle:   'Brand Strength Analysis Subtitle',
            Audience:   'ATTR(Audience)',
            Period:     'ATTR(Current Q vs Last FY_BSS)',
            Filter:     'Brand Stregth VS'
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class ProductExperienceBarChartDataModel implements IDataMapping<ProductExperienceBarChartDataModel> {
    public Name: string = '';
    public ChartTitle: string = '';
    public Label: string = '';
    public Color: string = '';
    public Value: number = Number(0);
    public Rank: number = Number(0);
    public Brand: string = '';
    public getMappings?() {
        return {
            Name: "Year",
            ChartTitle: 'Header_bar chart',
            Label: 'Indicator Name',
            Color: 'PE colors vs Last Year',
            Value: 'Measure Values',
            Rank: 'AGG(PE rank)',
            Brand: 'ATTR(Brand Name)',
        };
    }
}

import { IDataMapping } from "../../../../IDataMapping";

export class BSAnalysisByAudienceTableHeaderRowModel implements IDataMapping<BSAnalysisByAudienceTableHeaderRowModel> {
    public Answer: string = '';

    public getMappings?() {
        return {
            Answer: 'Answer BS legend',
        };
    }
}

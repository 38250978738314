import { IDataMapping } from "../../../../IDataMapping";

export class PHBSMarketStatementPerBrandChartTitleModel implements IDataMapping<PHBSMarketStatementPerBrandChartTitleModel> {
    public Title:           string = '';
    public SubTitle:        string = '';
    public AudienceFilter:  string = '';
    public FactorFilter:    string = '';
    public StatementFilter: string = '';

    public getMappings?() {
        return {
            Title:          'Brand Strength by Statement per Brand Title',
            SubTitle:       'Brand Strength Analysis Subtitle',
            AudienceFilter: 'ATTR(Audience)',
            FactorFilter:   'ATTR(BS_statement)',
            StatementFilter:'ATTR(Answer)',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class PreferenceScoreGlobalResultsDataModel implements IDataMapping<PreferenceScoreGlobalResultsDataModel> {
    public GlobalScore: string = '';
    public Color: string = '';
    public KPIValue: string = '';
    public ValueNegative: string = '';
    public ValuePositive: string = '';
    public ValueZero: string = '';
    public getMappings?() {
        return {
            GlobalScore: "\"Global Score:\"",
            Color: "KPI Shapes Color",
            KPIValue: "AVG(KPI Value_summary)",
            ValueNegative: "vs. MAT/vs. Target ⯆",
            ValuePositive: "vs. MAT/vs. Target ⯅",
            ValueZero: "vs. MAT/vs. Target ⯈",
        };
    }
}

import pptxgen from 'pptxgenjs';
import { RecommendedBrandsTitleModel } from '../../../models/PersonalHealth/RecommendedBrands/RecommendedBrandsTitleModel';
import { RecommendedBrandsDataModel } from '../../../models/PersonalHealth/RecommendedBrands/RecommendedBrandsDataModel';

import { PHFirstSlideRenderer } from '../../Renderers/PHFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

import { filterInitDuplicateData, addEmptyValuesToInitData } from "../../../../shared/utility";

export class RecommendedBrandsService extends BasePptxService {
    protected chartQuestion: string = '';
    protected firstSlideRenderer: PHFirstSlideRenderer = new PHFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;
    protected readonly defaultColor: string = '#D9D9D9';

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
    }

    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        // Override title's options and add filter's name in it.
        const titleData = await this.getMappedChartData(RecommendedBrandsTitleModel, this.titleSheet);
        let subTitle: string = "";

        titleData.map(obj => {
            this.chartTitle = obj.Title;
            return subTitle = `${obj.Country} ${obj.Category} - ${obj.Filter}`;
        });
        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.35, bold: true });
        slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.58, italic: true });
    }

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const mappedData = await this.getMappedChartData(RecommendedBrandsDataModel, this.dataSheet);
        let filteredData = filterInitDuplicateData(mappedData);
        filteredData.forEach((obj: any) => {
            if (obj.Name == "Philips") {
                obj.Rank = 5;
            }
        })
        filteredData.sort((a: any, b: any) => b.Rank - a.Rank);

        const newArr: any = [];
        const lineColors: string[] = [];
        const getAllLabels: any = [];

        filteredData.forEach((obj: any) => {
            getAllLabels.push(obj.Label);
        });

        const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

        filteredData.forEach((obj: any) => {
            const item = newArr.find((item: any) => item.name === obj.Name);

            if (newArr.length > 0 && item) {
                item.values.push(parseFloat(obj.Value));
                item.labels.push(obj.Label);
            } else {
                lineColors.push(obj.Color ? obj.Color : this.defaultColor)
                newArr.push(
                    {
                        name: obj.Name,
                        labels: [obj.Label],
                        values: [parseFloat(obj.Value)],
                    }
                )
            }
        })

        const finalChartData = addEmptyValuesToInitData(distinctedLabels, newArr);
        chartSlide.addChart(
            this.presentation.ChartType.line,
            finalChartData,
            {
                x: 1,
                y: 1,
                w: "70%",
                h: "70%",
                showLegend: true,
                legendPos: 'b',
                dataLabelPosition: 't',
                dataLabelFormatCode: '0%',
                valAxisLabelFormatCode: '0%',
                catAxisLabelRotate: this.labelRotateDegree(newArr),
                chartColors: lineColors,
                valAxisLabelFontSize: 10,
                catAxisLabelFontSize: 10,
            }
        );
    }

    protected labelRotateDegree(array: any) {
        let degree: number = 0;
        array.forEach((obj: any) => {
            if (obj.labels.length > 10) {
                degree = 45;
            } else {
                degree = 0;
            }
        });
        return degree;
    }

    private distinctRepeatables = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
    };
}

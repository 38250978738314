import pptxgen from 'pptxgenjs';
import { UnaidedChannelRecallModel } from '../../../';
import { BasePptxService } from '../../BasePptxService';
import { HSStrategicFirstSlideRenderer } from '../../Renderers/HSStrategicFirstSlideRenderer';
import { groupBy } from 'lodash';
import { BaseChartOptions as BaseChartOptions } from '../../../philips-constants';

export class UnaidedChannelRecallPptxService extends BasePptxService {
  protected chartQuestion: string = 'Q: Through which of the following channels have you seen, heard or read communications for [Brand] recently?';
  protected firstSlideRenderer: HSStrategicFirstSlideRenderer = new HSStrategicFirstSlideRenderer();

  protected async addChartSlide(chartSlide: pptxgen.Slide): Promise<void> {
    const data = await this.getMappedChartData(UnaidedChannelRecallModel, 'UCR per brand');    
    const groups = Object.values(groupBy(data, d => d.Brand))
      .sort((a, b) => a[0].BrandOrder - b[0].BrandOrder);
    groups.forEach((group, index) => {
      let width = 8 / groups.length;
      let showCatAxisLabel = false;
      const x = index * width + (index >= 1 ? 1.5 : 0.5);
      if (index === 0) {
        width += 1;
        showCatAxisLabel = true;
      }
      const colors = this.getMappedChartDataColors(group);
      const chartData = group
        .sort((a, b) => a.DriverOrder - b.DriverOrder)
        .reduce((acc, curr) => ({
          labels: [...acc.labels, curr.Answer],
          values: [...acc.values, curr.Score],
        }), { labels: [] as string[], values: [] as number[] });

      chartSlide.addChart(this.presentation.ChartType.bar, [chartData], {
        ...BaseChartOptions,
        x,
        y: 1.2,
        w: width,
        h: 3.7,
        barDir: 'bar',
        showValue: true,
        chartColors: colors,
        barGapWidthPct: 15,
        valAxisLineShow: false,
        valAxisHidden: true,
        catAxisLabelPos: showCatAxisLabel ? 'low' : undefined,
        catAxisHidden: !showCatAxisLabel,
        dataLabelFormatCode: '0%',
        dataLabelPosition: 'outEnd',
        dataLabelFontBold: true,
        dataLabelColor: this.colors.default,
      });

      chartSlide.addText(group[0].Brand, {
        x: x + (index === 0 ? width / 2.5 : 0) + (width * 0.05),
        y: 1.15,
        color: colors[0],
        bold: true,
        fontSize: 12,
      });

      const average = group.filter(x => x.Average !== null).first().Average.toString() as string;

      chartSlide.addText(`N= ${average}`, {
        x: x + (index === 0 ? width / 2.5 : 0) + (width * 0.05),
        y: 5,
        color: this.colors.default,
        fontSize: 8
      });
    });
  }
}

import { IDataMapping } from '../../IDataMapping';

export class VendorSPGlobalModel implements IDataMapping<VendorSPGlobalModel> {
  Brand: string = '';
  Selection: string = '';
  Score: number = -1;
  Color: string = '';
  BrandOrder: number = -1;
  DriverOrder: number = -1;
  Average: number = -1;

  public getMappings?() {
    return {
      Brand: 'Brand',
      Score: 'SUM(%)',
      Selection: 'Selection Drivers',
      Color: 'ATTR(Calc Brand Colors)',
      BrandOrder: 'AGG(Rank brand Drivers)',
      DriverOrder: 'AGG(Rank brand Drivers)',
      Average: 'AVG(Sample size)',
    };
  }
}

import { IDataMapping } from '../../../../IDataMapping';

export class UnaidedRecallSegmentCommunicationsChartTitleModel implements IDataMapping<UnaidedRecallSegmentCommunicationsChartTitleModel> {
    public Title: string = '';
    public Period: string = '';
    public Country: string = '';

    public getMappings?() {
        return {
            Title: 'Calc URC Ology TEXT',
            Period: 'Period',
            Country: 'Calc Country',
        };
    }
}

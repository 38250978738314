export class ProductLevelNPSDriverOverviewDataModel {
    public Driver: string = '';
    public BrandsData: ProductLevelNPSDriverOverviewBrandDataModel[] = [];

    constructor(driver: string = '') {
        this.Driver = driver;
    }
}

// tslint:disable-next-line: max-classes-per-file
export class ProductLevelNPSDriverOverviewBrandDataModel {
    public Brand: string = '';
    public Order: number = 0;
    public ChartDataPoints: {[label: string]: string | null} = {};

    constructor(brand: string) {
        this.Brand = brand;
    }
}

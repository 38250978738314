import { IDataMapping } from '../../../IDataMapping';

export class SRCBSAnalysisTableDataModel implements IDataMapping<SRCBSAnalysisTableDataModel> {
    public Factor:      string = '';
    public Category:    string = '';
    public Date:        string = '';
    public Color:       string = '';
    public TargetColor: string = '';
    public Value:       number = 0;
    public TimeRank:    number = 0;
    public LegendText:  string = '';
    public LegendOrder: number = 0;

    public getMappings?() {
        return {
            Factor:         'Answer',
            Category:       'Audience_tbl',
            Date:           'QUARTER(Date)',
            Color:          'ATTR(Color Brand Strength Analysis (hex))',
            TargetColor:    'Color vs. Target Brand Strength',
            Value:          'SUM(Value)',
            TimeRank:       'MIN(index_period)',
            LegendText:     'Color Changing MAT',
            LegendOrder:    'Color Brand Strength Analysis (rank)',
        };
    }
}

import React from 'react';
import styles from './Footer.module.scss';

export const Footer = () => {
  return (
    <h6 className={styles.footer}>
      <span>NextGen Dashboard</span>
      by GemSeek
    </h6>
  );
};

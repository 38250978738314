import React from 'react';
import { ISVGProps } from './ISVGProps';

export const FilterSvg = (props: ISVGProps) => {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 9V15C11 15.2833 10.9043 15.5207 10.713 15.712C10.521 15.904 10.2833 16 10 16H8C7.71667 16 7.47933 15.904 7.288 15.712C7.096 15.5207 7 15.2833 7 15V9L1.2 1.6C0.949999 1.26667 0.912666 0.916667 1.088 0.55C1.26267 0.183334 1.56667 0 2 0H16C16.4333 0 16.7377 0.183334 16.913 0.55C17.0877 0.916667 17.05 1.26667 16.8 1.6L11 9ZM9 8.3L13.95 2H4.05L9 8.3Z" fill="var(--ribbon-button-color, #0055A6)" />
    </svg>
  );
};

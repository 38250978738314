import React from "react";
import { TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";


const TextFieldWrapper = (props: any) => {

  const useStyles = makeStyles(() => ({
    cssOutlinedInput: {
      "&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline": {
        borderColor: "#00AAB5" // hovered
      },
      "&$cssFocused $notchedOutline": {
        borderColor: "#00AAB5" // focused
      },
    },
    cssOutlinedInputErr: {
      borderColor: "#B00020 !important" 
    },
    notchedOutline: {},
    styles: {
      "& .MuiFormLabel-root": {
        fontSize: "14px !important",
      },
      "& .MuiFormLabel-root.Mui-error": {
        color: "#B00020 !important",
      },
      "& .MuiInput-underline.Mui-error:after": {
        borderBottomColor: "#B00020 !important"
      },
      "& .MuiFormHelperText-root.Mui-error": {
        color: "#B00020 !important"
      },
      "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-filled": {
        color: props.error ? "#B00020" : "black !important",
        fontSize: "16px !important",
      },
    },
    cssFocused: {
      color: "black !important" ,// focused,
    },
    error: {},
    disabled: {},
  }));
  const classes = useStyles();

	return (
		<TextField
      className={classes.styles}
      InputLabelProps={{
        classes: {
          focused: classes.cssFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.cssFocused,
          notchedOutline: props.error ? classes.cssOutlinedInputErr : classes.notchedOutline,
        },
      }}
      size="small"
      fullWidth={true}
      id="outlined-basic"
      variant="outlined"
      // just incase we want onfocus onFocus={(e: any) => handleFormChange('lastName', e.target.value)} 
      {...props}
    />
	);
};

export default TextFieldWrapper;

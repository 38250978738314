import { showMessageAction } from '../store/layout/LayoutActions';

export class LayoutMessage {
    public renderSuccess(dispatch: any, message: string) {
        dispatch(
            showMessageAction({
                severity: 'success',
                summary: 'Success!',
                detail: message,
                life: 3000,
            })
        );
    }

    public renderError(dispatch: any, message: string) {
        dispatch(
            showMessageAction({
                severity: 'error',
                summary: 'Error!',
                detail: message,
                sticky: true,
            })
        );
    }
}

const layoutMessage = new LayoutMessage();

export default layoutMessage;

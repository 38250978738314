import { IDataMapping } from '../../IDataMapping';

export class BrandOwnershipDataModel implements IDataMapping<BrandOwnershipDataModel> {
    public Label: string = '';
    public Name: string = '';
    public Value: number = Number(0);
    public Color: string = '';
    public getMappings?() {
        return {
            Label: "Brand Name",
            Name: "Full Year",
            Value: "SUM(Indicator value)",
            Color: 'Donut Colours',
        };
    }
}

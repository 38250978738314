import { clone } from 'lodash';
import produce from 'immer';
import { ILayoutState, ISideMenuButton } from './ILayoutState';
import { ISetSideMenuButtonAction, LayoutActions, LayoutActionTypes } from './LayoutActions';

const initialLayoutState: ILayoutState = {
    message: undefined,
    showTableauUserError: false,
    logo: '',
    host: window.location.hostname,
    sideMenuButton: {} as ISideMenuButton
};

export const LayoutReducer: (
    state: ILayoutState | undefined,
    action: LayoutActions
) => ILayoutState = (
    state: ILayoutState = initialLayoutState,
    action: LayoutActions
): ILayoutState => {
        let newState: ILayoutState = clone(state);
        switch (action.type) {
            case LayoutActionTypes.SHOW_MESSAGE:
                newState = produce(state, (draftState: ILayoutState) => {
                    draftState.message = action.message;
                });
                window.scrollTo(0, 0);
                break;
            case LayoutActionTypes.SHOW_TABLEAU_USER_ERROR:
                newState = produce(state, (draftState: ILayoutState) => {
                    draftState.showTableauUserError = action.show;
                });
                break;
            case LayoutActionTypes.SET_LOGO:
                newState = produce(state, (draftState: ILayoutState) => {
                    draftState.logo = action.logo;
                });
                break;
            case LayoutActionTypes.SET_SIDEMENU_BUTTON:
                newState = produce(state, (draftState: ISetSideMenuButtonAction) => {
                    draftState.sideMenuButton = action.sideMenuButton;
                });
                break;
            default:
                return state;
        }

        return newState;
    };

import { IDataMapping } from '../../IDataMapping';

export class NPSScoreOverTimeTitleModel implements IDataMapping<NPSScoreOverTimeTitleModel> {
    public Title: string = '';
    public SubTitle: string = '';
    public Level: string = '';
    public ChartType: string = '';
    public getMappings?() {
        return {
            Title: "BMC Page Titles",
            SubTitle: "Category",
            Level: "Country / Market",
            ChartType: "NPS Score/MAT NPS",
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class BMCPreferenceScoreDataModel implements IDataMapping<BMCPreferenceScoreDataModel> {
    public Color: string = '';
    public KPIValue: string = '';
    public ValueNegative: string = '';
    public ValuePositive: string = '';
    public ValueZero: string = '';
    public getMappings?() {
        return {
            Color: 'KPI Shapes colors',
            KPIValue: 'SUM(Indicator value (parameter))',
            ValueNegative: 'SUM(Negative)',
            ValuePositive: 'SUM(Positive)',
            ValueZero: 'SUM(Neutral)',
        };
    }
}

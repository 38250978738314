import { IDataMapping } from '../../IDataMapping';

export class RecommendationPatternsBarDataModel implements IDataMapping<RecommendationPatternsBarDataModel> {
    public Name: string = '';
    public Label: string = '';
    public Color: string = '';
    public Value: number = Number(0);
    public Rank: number = Number(0);
    public getMappings?() {
        return {
            Name: "Brand",
            Label: 'Date',
            Color: 'AGG(BrandColors)',
            Value: 'AVG(Value)',
            Rank: 'AGG(rankBarRec)',
        };
    }
}

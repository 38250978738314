import { IDataMapping } from '../../IDataMapping';

export class ContributorsTitleModel implements IDataMapping<ContributorsTitleModel> {
    public Level: string = '';
    public Title: string = '';
    public SubTitle: string = '';
    public getMappings?() {
        return {
            Level: 'Level',
            Title: 'Tornado Title pipe',
            SubTitle: 'Tornado Sub Title pipe',
        };
    }
}

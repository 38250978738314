import { IDataMapping } from '../../IDataMapping';

export class ProductNPSResultsTableDataModel implements IDataMapping<ProductNPSResultsTableDataModel> {
    public Name: string = '';
    public Color: string = '';
    public AvgValue: number = Number(0);
    public DiffValue: number = Number(0);
    public Rank: number = Number(0);
    public Base: number = Number(0);
    public getMappings?() {
        return {
            Name: 'Brand',
            Color: 'Diff NPS Rank (copy)',
            AvgValue: 'AVG(Value)',
            DiffValue: 'SUM(Diff NPS (copy))',
            Rank: 'MAX(pref value check rank (Q) (NPS))',
            Base: 'AVG(Count)',
        };
    }
}

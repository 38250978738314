import pptxgen from 'pptxgenjs';
import { RecommendationsTitleModel } from '../../../models/PersonalHealth/Recommendations/RecommendationsTitleModel';
import { RecommendationsDataModel } from '../../../models/PersonalHealth/Recommendations/RecommendationsDataModel';

import { PHFirstSlideRenderer } from '../../Renderers/PHFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

export class RecommendationsService extends BasePptxService {
    protected chartQuestion: string = '';
    protected firstSlideRenderer: PHFirstSlideRenderer = new PHFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
    }

    protected async setChartSlideLayout(slide: pptxgen.Slide) {
      // Override title's options and add filter's name in it.
      const titleData = await this.getMappedChartData(RecommendationsTitleModel, this.titleSheet);
      let subTitle: string = "";
      titleData.map( obj => {
        this.chartTitle = obj.Title;
      return subTitle = `${obj.Country} ${obj.Category} - ${obj.Filter}`;
      });
      slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.35, bold: true });
      slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.58, italic: true });
    }
  
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
      const mappedData = await this.getMappedChartData(RecommendationsDataModel, this.dataSheet);
      chartSlide.addText([{ text: `${(mappedData[0].Value * 100).toFixed(1)}%`, options: { fontSize: 28 } }], { x: 4.2, y: 2.7 });
    }
}

import pptxgen from 'pptxgenjs';

import { ProductExperienceTitleModel } from '../../../models/PersonalHealth/ProductExperience/ProductExperienceTitleModel';
import { ProductExperienceTornadoDataModel } from '../../../models/PersonalHealth/ProductExperience/ProductExperienceTornadoDataModel';
import { ProductExperienceBubbleDataModel } from '../../../models/PersonalHealth/ProductExperience/ProductExperienceBubbleDataModel';
import { ProductExperienceBarChartDataModel } from '../../../models/PersonalHealth/ProductExperience/ProductExperienceBarChartDataModel';

import { PHFirstSlideRenderer } from '../../Renderers/PHFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';
import { addEmptyValuesToInitData } from '../../../../shared/utility';

export class ProductExperienceService extends BasePptxService {
  protected chartQuestion: string = '';
  protected firstSlideRenderer: PHFirstSlideRenderer = new PHFirstSlideRenderer();
  protected titleSheet: string;
  protected tornadoDataSheet: string;
  protected bubbleDataSheet: string;
  protected barChartDataSheet: string;
  private rowsCountOnSlide: number = 10;
  protected readonly defaultColor: string = '#D9D9D9';

  constructor(view: any, chartTitle: string, titleSheet: string, tornadoDataSheet: string, bubbleDataSheet: string, barChartDataSheet: string) {
      super(view, chartTitle);
      this.titleSheet = titleSheet;
      this.tornadoDataSheet = tornadoDataSheet;
      this.bubbleDataSheet = bubbleDataSheet;
      this.barChartDataSheet = barChartDataSheet;
  }

  protected async setChartSlideLayout(slide: pptxgen.Slide) {
    // Override title's options and add filter's name in it.
    const titleData = await this.getMappedChartData(ProductExperienceTitleModel, this.titleSheet);
    let subTitle: string = "";
    
    titleData.map( obj => {
      this.chartTitle = obj.Title;
      return subTitle = `${obj.Level} ${obj.SubTitle}`;
    });
    slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.35, bold: true });
    slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.58, italic: true });
  }

  protected async addChartSlide(chartSlide: pptxgen.Slide) {
    const tornadoData = await this.getMappedChartData(ProductExperienceTornadoDataModel, this.tornadoDataSheet);
    const bubbleData = await this.getMappedChartData(ProductExperienceBubbleDataModel, this.bubbleDataSheet);
    const barChartData = await this.getMappedChartData(ProductExperienceBarChartDataModel, this.barChartDataSheet);

    if (tornadoData.length > 0) {
      const labels: string[] = [];
      const values: string[] = [];
      const lineColors: string[] = [];
      let chartTitle: string = "";

      const data = [
        {
          name: "Low",
          labels: labels,
          values: values,
        },
      ];
      
      tornadoData.forEach((obj: any) => {
        lineColors.push(obj.Color);
        labels.push(obj.Name);
        values.push(obj.Value);
        chartTitle = `${obj.ChartTitle}  -  ${obj.Brand}`;
      })
  
      chartSlide.addChart(
        this.presentation.ChartType.bar,
        data,
        {
          x: 0.5,
          y: 0.7,
          w: "90%",
          h: "80%",
          barDir: "bar",
          valAxisMinVal: -1,
          valAxisMaxVal: 1,
          catAxisLineShow: false,
          valAxisLineShow: false,
          catAxisLabelPos: "low",
          valAxisLabelFontSize: 7,
          catAxisLabelFontSize: 7,
          showValue: true,
          dataLabelFormatCode: "0.0%",
          dataLabelFontSize: 7,
          dataLabelPosition: "outEnd",
          valAxisLabelFormatCode: '0%',
          shadow: { type: "none" },
          chartColors: lineColors.map(s => s.slice(1)),
          catGridLine: { color: "D8D8D8", style: "dash", size: 1 },
          valGridLine: { color: "D8D8D8", style: "dash", size: 1 },
          showTitle: true,
          title: chartTitle,
          titleFontSize: 10,
        }
      );
    } else if (bubbleData.length > 0) {
        await this.addBubbleSlide(bubbleData, chartSlide);
    } else if (barChartData.length > 0) {
        await this.addBarChartSlide(barChartData, chartSlide);
    };
  }

  protected async addBubbleSlide(data: any, chartSlide: pptxgen.Slide) {
    const titleData = await this.getMappedChartData(ProductExperienceTitleModel, this.titleSheet);
    const reversedData = data.slice().reverse();
    reversedData.sort((a: any, b: any) => a.Rank - b.Rank);
    const newArr: any = [];

    reversedData.forEach((obj: any) => {
      const item = newArr.find((item: any) => item.name === obj.Label);
      if(newArr && newArr.length > 0 && item) {
        return {
          name: obj.Label,
          values: [],
        };
      } else {
        newArr.push(
          {
            name: obj.Label,
            values: [],
          }
        );
      }
    });

    let splitToChunksArr = [];

    while (reversedData.length > 0) {
      const chunk = reversedData.splice(0, newArr.length * this.rowsCountOnSlide);
      splitToChunksArr.push(chunk);
    };

    splitToChunksArr.forEach((arr: any, index) => {
      this.addBubbleChart(arr, titleData, index, chartSlide)
    });
  }

  private addBubbleChart(initData: any, titleData: any, index: number, chartSlide: pptxgen.Slide) {
    let subTitle: string = "";
    let slide: any;
    if (index === 0) {
      slide = chartSlide;
    } else {
      slide = this.presentation.addSlide({ masterName: 'PH_BUBBLE_CHART' });
    }

    titleData.map( (obj: any) => {
      this.chartTitle = obj.Title;
      return subTitle = `${obj.Level} ${obj.SubTitle}`;
    });

    slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.35, bold: true });
    slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.58, italic: true });

    const newArr: any = [];
    const lineColors: string[] = [];
    const labelsArr: string[] = [];
    const xAxisValues: number[] = [];
    let titleChart: string = "";

    // Create newarr with empty values and a name.
    initData.forEach((obj: any) => {
      const item = newArr.find((item: any) => item.name === obj.Label);
      titleChart = obj.Filter;
      lineColors.push(obj.Color ? obj.Color : this.defaultColor);
      if(newArr && newArr.length > 0 && item) {
        return {
          name: obj.Label,
          values: [],
        };
      } else {
        newArr.push(
          {
            name: obj.Label,
            values: [],
          }
        );
      }
    })

    // Convert values into simple and ordered numbers (alongside nulls) and add them to the new array.
    newArr.forEach((newObj: any) => {
      let flag = 1;
      initData.forEach((oldObj: any) => {
        if (newObj.name === oldObj.Label) {
          newObj.values.push(flag);
          flag++;
        } else {
          newObj.values.push(null);
        };
      });
    });

    // Add the X-Axis object with all the values from the raw data.
    initData.forEach((obj: any) => {
      xAxisValues.push(obj.Value);
      labelsArr.push(obj.Name);
    });

    newArr.unshift({
      name: 'X-Axis',
      values: xAxisValues,
    });

    const disctinctedLabels = labelsArr.filter(this.distinctRepeatables);
    const bubbleChartHeight = this.getLineHeight(disctinctedLabels);

    const options: pptxgen.IChartOpts = {
      x: 2.65, y: 0.74, w: 7.15, h: bubbleChartHeight,
      chartColors: lineColors.map(s => s.slice(1)),
      showLabel: false,
      catAxisMajorUnit: 10,
      catAxisLabelFrequency: 10,
      valAxisHidden: true,
      catAxisOrientation: 'minMax',
      valAxisOrientation: 'maxMin',
      valAxisMajorUnit: 1,
      valAxisLabelFormatCode: '0%',
      catAxisMinVal: 0,
      catAxisMaxVal: 1,
      catAxisLabelFontSize: 8,
      lineDataSymbolSize: 12,
      lineSize: 0,
      catGridLine: { style: 'none' },
      valGridLine: { style: 'none' },
      dataLabelFontSize: 6,
      dataLabelFontBold: true,
    } as any;

    this.addDriversTable(disctinctedLabels, slide)
    slide.addChart(this.presentation.ChartType.scatter, newArr, options);
    this.addLegend(initData, slide);
    slide.addText([{ text: titleChart, options: { color: this.colors.black, fontSize: 12 } }], { x: 5.47, y: 0.45, w: 1.5, h: 0.4 });
  }

  private getLineHeight(array: any) {
    return ((array.length + 1) * 0.37) + 0.43;
  }

  // Used for the bubble chart.
  private addDriversTable(disctinctedLabels: any, chartSlide: pptxgen.Slide) {
    const rows: pptxgen.TableRow[] = disctinctedLabels.map((k: any) => ([{ text: k, options: { bold: false } }, { text: '' }]));

    const tableProps: pptxgen.TableProps = {
      x: 0.5, y: 1.2, w: '5%', colW: [2.39, 6.61], rowH: 0.37,
      margin: [2, 0, 2, 2],
      align: 'left',
      valign: 'middle',
      autoPageCharWeight: 1.5,
      fontSize: 8,
      border: { type: 'solid', color: this.colors.neutral, pt: 0.2 },
    };

    chartSlide.addTable(rows, tableProps);
  }

  // Used for the bubble chart.
  private addLegend(data: any, chartSlide: pptxgen.Slide) {
    const legend: any = [];
    const newArr: any = [];

    data.forEach((obj: any) => {
      newArr.push(
        {
          text: obj.Label,
          options: { fill: { color: obj.Color } }
        }
      )
    });

    const names = newArr.map((o: any) => o.text);
    const filteredNames = newArr.filter(({text}: any, index: any) => !names.includes(text, index + 1));
    legend.push(filteredNames);

    const legendProps: pptxgen.TableProps = {
        x: 1.5,
        y: '5',
        w: '70%',
        align: 'center',
        bold: true,
        fontSize: 8,
        rowH: 0.1,
        color: this.colors.white,
        border: { type: 'solid', color: this.colors.neutral, pt: 0.2 }
    };

    chartSlide.addTable(legend, legendProps);
  };

  protected async addBarChartSlide(initData: any, chartSlide: pptxgen.Slide) {
    initData.sort((a: any, b: any) => (b.Label).localeCompare(a.Label));

    const newArr: any = [];
    const lineColors: string[] = [];
    let chartTitle: string = "";
    const getAllLabels: any = [];

    initData.forEach((obj: any) => {
        getAllLabels.push(obj.Label);
    });
    const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

    initData.forEach((obj: any) => {
      if (obj.Label) {
        if (obj.Value && !isNaN(obj.Value)) {
          lineColors.push(obj.Color);
        }
        chartTitle = `${obj.ChartTitle}  -  ${obj.Brand}`;
        const item = newArr.find((item: any) => item.name === obj.Name);

        if (newArr && newArr.length > 0 && item) {
          if (item && obj.Value && !isNaN(obj.Value)) {
            item.values.push(parseFloat(obj.Value));
            item.labels.push(obj.Label);
          }
        } else {
          if (obj.Value) {
            newArr.push(
              {
                name: obj.Name,
                labels: [obj.Label],
                values: [parseFloat(obj.Value)],
              }
            )
          }
        }
      }
    });
    const finalChartData = addEmptyValuesToInitData(distinctedLabels, newArr);
    
    chartSlide.addChart(
      this.presentation.ChartType.bar,
      finalChartData,
      {
        x: 1,
        y: 0.7,
        w: "80%",
        h: "80%",
        showTitle: true,
        title: chartTitle,
        titleFontSize: 10,
        catAxisLabelFontSize: 8,
        barDir: "bar",
        showLegend: true,
        legendPos: 'b',
        dataLabelFormatCode: '0%',
        valAxisLabelFormatCode: '0%',
        valAxisLabelFontSize: 10,
        valAxisMinVal: 0,
        valAxisMaxVal: 1,
        chartColors: lineColors.filter(this.distinctRepeatables),
      }
    );
  };

  protected labelRotateDegree(array: any) {
    let degree: number = 0;
    array.forEach((obj: any) => {
      if (obj.labels.length > 10) {
        degree = 45;
      } else {
        degree = 0;
      }
    });
    return degree;
  };

  private distinctRepeatables = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };

  private reorderObjects = (arr: any) => {
    const reorderedData: any = [];
    for(let i = 0; i < arr.length; i++) {
        if (i % 2 === 0) {
          reorderedData.push(arr[i + 1]);
          reorderedData.push(arr[i]);
        }
    }
    return reorderedData;
  };
}

import { IDeleteDialogProps } from './IDeleteDialogProps';
import React from 'react';
import { useDispatch } from 'react-redux';
import { showMessageAction } from '../../../store/layout/LayoutActions';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import styles from './DeleteDialog.module.scss';
import authService from '../../api-authorization/AuthorizeService';

export const DeleteDialog = (props: IDeleteDialogProps): JSX.Element => {
  const { isOpen, selectedUser, handleCloseDialog, handleRefreshUsers } = props;

  const dispatch = useDispatch();

  const deleteUser = async () => {
    if (selectedUser && selectedUser.id) {
      const token = await authService.getAccessToken();
      const requestHeaders: Headers = new Headers();
      requestHeaders.append('Accept', 'application/json; text/javascript, */*');
      requestHeaders.append('Content-Type', 'application/json; charset=UTF-8');
      requestHeaders.append('Authorization', `Bearer ${token}`);
      const response = await fetch('Users/Delete', {
        method: 'POST',
        body: JSON.stringify(selectedUser.id),
        headers: requestHeaders,
      });

      if (response.ok) {
        handleCloseDialog();
        handleRefreshUsers();
        dispatch(
          showMessageAction({
            severity: 'success',
            summary: 'Success!',
            detail: 'User Deleted',
            life: 3000,
          })
        );
      } else {
        const errorMessage = await response.text();
        dispatch(
          showMessageAction({
            severity: 'error',
            summary: 'Error!',
            detail: errorMessage,
            sticky: true,
          })
        );
      }
    }
  };

  const footer = (
    <>
      <Button label={'CANCEL'} onClick={handleCloseDialog} className={styles.cancel_button} />
      <Button
        label={'DELETE'}
        onClick={() => deleteUser()}
        className={styles.save_button}
      />
    </>
  );

  return (
    <React.Fragment>
      {selectedUser ? (
        <div>
          <Dialog
            onHide={handleCloseDialog}
            visible={isOpen}
            header={`Delete user!`}
            footer={footer}
            className={`${styles.default_dialog} ${styles.delete_dialog}`}
          >
            Are you sure you want to delete {selectedUser.firstName} {selectedUser.lastName}?
          </Dialog>
        </div>
      ) : undefined}
    </React.Fragment>
  );
};

import pptxgen from 'pptxgenjs';
import { BaseChartOptions, ChartPercentageOptions } from '../../../../../philips-constants';

import { CAImportanceChartTitleModel } from '../../../../../models/HealthSystems/StrategicPartnership/ClinicalSegment/CAImportanceChart/CAImportanceChartTitleModel';
import { CAImportanceChartDataModel } from '../../../../../models/HealthSystems/StrategicPartnership/ClinicalSegment/CAImportanceChart/CAImportanceChartDataModel';
import { CAImportanceChartSampleModel } from '../../../../../models/HealthSystems/StrategicPartnership/ClinicalSegment/CAImportanceChart/CAImportanceChartSampleModel';

import { BasePptxService } from '../../../../BasePptxService';
import { HSStrategicFirstSlideRenderer } from '../../../../Renderers/HSStrategicFirstSlideRenderer';

export class CAImportanceChartService extends BasePptxService {
    protected chartQuestion: string = 'Please divide 100 points across these categories according to your willingness to invest in each one of them.' +
        'You can give one category all 100 points or divide them as you please.';
    protected firstSlideRenderer: HSStrategicFirstSlideRenderer = new HSStrategicFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;
    protected sampleSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string, sampleSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
        this.sampleSheet = sampleSheet;
    }

    // Override of a super class method
    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const mappedTitleData = await this.getMappedChartData(CAImportanceChartTitleModel, this.titleSheet);

        let customTitle: string | pptxgen.TextProps[] = [];
        mappedTitleData.forEach(obj => {
            customTitle = [
                { text: `${obj.Title} ${obj.ClinicalSegment}: ${obj.Country} - ${obj.Segment} - ` },
                {
                    text: `${obj.Period}`,
                    options: { color: this.colors.negative }
                },
                {
                    text: `${obj.WarningText}`,
                    options: { color: this.colors.negative }
                }
            ];
        });

        slide.addText(customTitle, { x: 0.6, y: 0.55, bold: true, color: this.colors.default, fontSize: 18 });
        slide.addText([{ text: this.chartQuestion, options: { color: this.colors.black, fontSize: 7 } }], { x: 0.5, y: '95%', bold: false });
    }

    // Override of a super class method
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        await this.addChart(chartSlide);
        await this.addChartLayout(chartSlide);
    }

    private async addChartLayout(chartSlide: pptxgen.Slide) {
        const mappedSampleData = await this.getMappedChartData(CAImportanceChartSampleModel, this.sampleSheet);

        if (mappedSampleData.length > 0) {
            let sampleData = 0;
            mappedSampleData.forEach((obj) => {
                if (obj.SamplePositive) {
                    sampleData = obj.SamplePositive;
                } else if (obj.SampleNegative) {
                    sampleData = obj.SampleNegative;
                }
            })

            // Chart methodology for sample values under 50
            chartSlide.addText([{ text: `N = ${sampleData}`, options: { color: sampleData < 50 ? this.colors.negative : this.colors.default, fontSize: 9, align: 'left' } }], { x: 8.8, y: 5.2, bold: false });
        }

        const line = { color: this.colors.default, width: 0.5 };
        chartSlide.addShape(this.presentation.ShapeType.line, { x: 1.3, y: 2.9, w: 8, h: 0, line });
        chartSlide.addShape(this.presentation.ShapeType.line, { x: 3.8, y: 2.9, w: 2.8, h: 0, rotate: 90, line });

        const firstRow = 1.5;
        const secondRow = 4.2;
        const firstCol = 1.2;
        const secondCol = 8.6;
        chartSlide.addText('Maintain', { x: firstCol, y: firstRow, color: 'f68620', fontSize: 10 });
        chartSlide.addText('Leverage', { x: secondCol, y: firstRow, color: '469438', fontSize: 10 });
        chartSlide.addText('Observe', { x: firstCol, y: secondRow, color: 'ea2c31', fontSize: 10 });
        chartSlide.addText('Improve', { x: secondCol, y: secondRow, color: '7e0f6a', fontSize: 10 });
    }

    private async addChart(chartSlide: pptxgen.Slide) {
        const mappedChartData = await this.getMappedChartData(CAImportanceChartDataModel, this.dataSheet);

        if (mappedChartData.length > 0) {
            mappedChartData.reverse();

            const chartData = [
                { name: 'X-Axis', values: mappedChartData.map(d => d.Importance) },
                ...this.getChartSeries(mappedChartData)
            ];

            const advantageValues = mappedChartData.map(d => Number(Number(d.Advantage).toFixed(2)));
            const valAxisMinVal = Math.min(...advantageValues) - 0.2;
            const valAxisMaxVal = Math.max(...advantageValues) + 0.2;
            const maxVal = Math.max(Math.abs(Number(valAxisMinVal.toFixed(1))), Math.abs(Number(valAxisMaxVal.toFixed(1))));

            const options: pptxgen.IChartOpts = {
                ...BaseChartOptions,
                ...ChartPercentageOptions,
                x: 0.5, y: 1.4, w: 9, h: 3.7,
                chartColorsOpacity: 80,
                showCatAxisTitle: true,
                catAxisTitle: 'Attribute Importance',
                catAxisMinVal: 0,
                catAxisMaxVal: 1,
                catAxisLineShow: false,
                showValAxisTitle: true,
                valAxisTitle: 'Competitive Advantage',
                valAxisMinVal: -1 * maxVal,
                valAxisMaxVal: maxVal,
                valAxisLineShow: false,
                showLabel: true,
                lineSize: 0,
                showLegend: true,
                legendPos: 'b',
                valAxisCrossesAt: -0.5,
                lineDataSymbolSize: 10,
            };

            chartSlide.addChart(this.presentation.ChartType.scatter, chartData, options);
        }
    }

    private getChartSeries(data: CAImportanceChartDataModel[]) {
        const initial: Array<number | null> = data.map(d => null);
        return data.map((d, i) => {
            const values: Array<number | null> = [...initial];
            values[i] = d.Advantage;
            return {
                name: d.Answer,
                values
            };
        });
    }
}

import React from 'react';
import { IconType } from '../../../shared/enums/IconType';
import { ICustomIconProps } from './ICustomIconProps';
import { CSVSvg } from './CSVSvg';
import { CheckboxSvg } from './CheckboxSvg';
import { DeleteSvg } from './DeleteSvg';
import { DownloadSvg } from './DownloadSvg';
import { EllipsisSvg } from './EllipsisSvg';
import { EyeSvg } from './EyeSvg';
import { FilterSvg } from './FilterSvg';
import { FileSvg } from './FileSvg';
import { ListSvg } from './ListSvg';
import { NonCheckedCheckboxSvg } from './NonCheckedCheckboxSvg';
import { PDFSvg } from './PDFSvg';
import { PPTSvg } from './PPTSvg';
import { RevertSvg } from './RevertSvg';
import { TilesSvg } from './TilesSvg';
import { ImageSvg } from './ImageSvg';
import { WordSvg } from './WordSvg';
import { ExcelSvg } from './ExcelSvg';
import { FolderSvg } from './FolderSvg';
import { VideoSvg } from './VideoSvg';
import { ArrowRight } from './ArrowRight';

export const CustomIcon = (props: ICustomIconProps): JSX.Element => {
  const { width, height, iconType } = props;

  switch (iconType) {
    case IconType.CSV:
      return <CSVSvg width={width} height={height} />;
    case IconType.Checkbox:
      return <CheckboxSvg width={width} height={height} />;
    case IconType.Delete:
      return <DeleteSvg width={width} height={height} />;
    case IconType.Download:
      return <DownloadSvg width={width} height={height} />;
    case IconType.Ellipsis:
      return <EllipsisSvg width={width} height={height} />;
    case IconType.Eye:
      return <EyeSvg width={width} height={height} />;
    case IconType.Filter:
      return <FilterSvg width={width} height={height} />;
    case IconType.List:
      return <ListSvg width={width} height={height} />;
    case IconType.PDF:
      return <PDFSvg width={width} height={height} />;
    case IconType.PPT:
      return <PPTSvg width={width} height={height} />;
    case IconType.Revert:
      return <RevertSvg width={width} height={height} />;
    case IconType.Tiles:
      return <TilesSvg width={width} height={height} />;
    case IconType.NonCheckedCheckbox:
      return <NonCheckedCheckboxSvg width={width} height={height} />;
    case IconType.File:
      return <FileSvg width={width} height={height} />;
    case IconType.Image:
      return <ImageSvg width={width} height={height} />;
    case IconType.Excel:
      return <ExcelSvg width={width} height={height} />;
    case IconType.Word:
      return <WordSvg width={width} height={height} />;
    case IconType.Folder:
      return <FolderSvg width={width} height={height} />;
    case IconType.Video:
      return <VideoSvg width={width} height={height} />;
    case IconType.ArrowRight:
      return <ArrowRight width={width} height={height} />;
    default:
      return <i></i>;
  }
};

export const mapExtensionToIconType = (extension: string): IconType => {
  switch (extension) {
    case '.doc':
    case '.docx':
      return IconType.Word;
    case '.xls':
    case '.xlsx':
      return IconType.Excel;
    case '.pdf':
      return IconType.PDF;
    case '.ppt':
    case '.pptx':
      return IconType.PPT;
    case '.avi':
    case '.mp4':
    case '.mov':
    case '.wmv':
      return IconType.Video;
    case '.png':
    case '.jpg':
    case '.jpeg':
    case '.ico':
    case '.gif':
    case '.svg':
    case '.ai':
    case 'tiff':
      return IconType.Image;
    default:
      return IconType.File;
  }
};

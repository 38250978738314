
import { TableCellModel } from '../TableCellModel';

export class RelativeNPSRowDataModel {
    public BusinessGroup: TableCellModel = new TableCellModel();
    public Modality: TableCellModel = new TableCellModel();
    public SubModality: TableCellModel = new TableCellModel();

    constructor(businessGroup: string, modality: string, subModality: string, options: any) {

        this.BusinessGroup = new TableCellModel(businessGroup, options);
        this.Modality = new TableCellModel(modality, options);
        this.SubModality = new TableCellModel(subModality, options);
    }
}


import { IDataMapping } from '../../IDataMapping';

export class ProductNPSLineChartMonthlyDataModel implements IDataMapping<ProductNPSLineChartMonthlyDataModel> {
    public Name: string = '';
    public Label: string = '';
    public Color: string = '';
    public Value: number = Number(0);
    public Rank: number = Number(0);
    public TimeRank: number = Number(0);
    public Base: number = Number(0);
    public getMappings?() {
        return {
            Name: 'Brand',
            Label: 'MONTH(Month as Date (NPS))',
            Color: 'AGG(BrandColors)',
            Value: 'AVG(Value)',
            Rank: 'INDEX()',
            TimeRank: 'last',
            Base: 'AVG(Count)',
        };
    }
}
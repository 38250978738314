import { IDataMapping } from '../../../../IDataMapping';

export class NpsAttributesDeltaChartDataModel implements IDataMapping<NpsAttributesDeltaChartDataModel> {
    public driver: string = '';
    public subDriver: string = '';
    public country: string = '';
    public countryOrder: number = 0;
    public color: string = '';
    public arrow: number = 0;
    public value: number = 0;

    public getMappings?() {
        return {
            driver: 'calc Driver Swap (NET)',
            subDriver: 'calc Driver Swap',
            country: 'Country',
            countryOrder: 'MIN(Calc Country Order for Attributes)',
            color: 'AGG(Calc Coloration)',
            arrow: 'ATTR(Shapes Up/Down)',
            value: 'AVG(Calc Delta)',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class NineGLineChartIndexDataModel implements IDataMapping<NineGLineChartIndexDataModel> {
    public Label: string = '';
    public Name: string = '';
    public Value: number = Number(0);
    public Rank: number = Number(0);
    public Color: string = '';
    public getMappings?() {
        return {
            Label: 'QUARTER(Date_calc (Index))',
            Name: 'Brand Name',
            Value: 'MAX(Brand Index (cap 100))',
            Rank: 'MAX(Rank_unique (MarketInnov))',
            Color: 'Brand name color',
        };
    }
}

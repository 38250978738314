import pptxgen from 'pptxgenjs';

import { BasePptxService } from '../../../../BasePptxService';
import { PHBSFirstSlideRenderer } from '../../../../Renderers/PHBSFirstSlideRenderer';
import { PptxRenderHelper } from '../../../../../helpers/PptxRenderHelper';

import { PHBSMarketAnalysisByBrandTableTitleModel } from '../../../../../models/PersonalHealth/BrandStrength/MarketsOverview/PHBSMarketAnalysisByBrandTable/PHBSMarketAnalysisByBrandTableTitleModel';
import { PHBSMarketAnalysisByBrandTableDataModel } from '../../../../../models/PersonalHealth/BrandStrength/MarketsOverview/PHBSMarketAnalysisByBrandTable/PHBSMarketAnalysisByBrandTableDataModel';
import { PHBSMarketAnalysisByBrandTableLegendModel } from '../../../../../models/PersonalHealth/BrandStrength/MarketsOverview/PHBSMarketAnalysisByBrandTable/PHBSMarketAnalysisByBrandTableLegendModel';

export class PHBSMarketAnalysisByBrandTableService extends BasePptxService {
    protected firstSlideRenderer: PHBSFirstSlideRenderer = new PHBSFirstSlideRenderer();
    protected chartQuestion: string = '';
    protected titleSheet: string;
    protected factorDataSheet: string;
    protected scoreDataSheet: string;
    protected legendDataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string,
        factorDataSheet: string, scoreDataSheet: string, legendDataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.factorDataSheet = factorDataSheet;
        this.scoreDataSheet = scoreDataSheet;
        this.legendDataSheet = legendDataSheet;
    }

    // Override of a super class method
    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const titleData = await this.getMappedChartData(PHBSMarketAnalysisByBrandTableTitleModel, this.titleSheet);

        let subTitle: string = "";
        let note: string = "";
        titleData.forEach(obj => {
            this.chartTitle = `${obj.Title}`;
            subTitle = `${obj.SubTitle} - ${obj.AudienceFilter}`;
            note = `${obj.Note}`;
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.55, bold: true });
        slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 12, lineSpacing: 12 } }], { x: 0.59, y: 0.80 });
        slide.addText([{ text: note, options: { color: '#861106', fontSize: 12 } }], { x: 0.40, y: 0.96, italic: true });
        slide.addText([{ text: this.chartQuestion, options: { color: this.colors.black, fontSize: 7 } }], { x: 0.5, y: '95%', bold: false });
    }

    // Override of a super class method
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const mappedFactorData = await this.getMappedChartData(PHBSMarketAnalysisByBrandTableDataModel, this.factorDataSheet);
        const mappedScoreData = await this.getMappedChartData(PHBSMarketAnalysisByBrandTableDataModel, this.scoreDataSheet);
        const mappedlegendDataSheet = await this.getMappedChartData(PHBSMarketAnalysisByBrandTableLegendModel, this.legendDataSheet);

        if (mappedFactorData.length > 0) {

            // sorting and filtering table data
            const factors = this.getAllValuesByProperty(mappedFactorData, "Factor");

            const groupData = this.groupDataByRows(mappedFactorData, mappedScoreData);

            const numberOfPeriods = this.getAllValuesByProperty(mappedFactorData, "Date").length;
            const tables = this.splitRowsByTables(groupData, numberOfPeriods === 1 ? 8 : 10);

            // execution
            tables.forEach((table, index) => {
                const currentSlide = this.getCurrentSlide(chartSlide, index);

                this.addTable(currentSlide, table, factors);
                this.addLegend(currentSlide, mappedlegendDataSheet);
            });
        }
    }

    private getCurrentSlide(chartSlide: pptxgen.Slide, index: number) {
        let currentSlide = chartSlide;

        if (index !== 0) {
            currentSlide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });
            this.setChartSlideLayout(currentSlide);
        }

        return currentSlide;
    }

    private getAllValuesByProperty(data: PHBSMarketAnalysisByBrandTableDataModel[], property: string) {
        const values: any[] = [];

        data.forEach((obj: PHBSMarketAnalysisByBrandTableDataModel) => {
            if (!values.includes(obj[property as keyof PHBSMarketAnalysisByBrandTableDataModel])) {
                values.push(obj[property as keyof PHBSMarketAnalysisByBrandTableDataModel]);
            }
        });

        return values;
    }

    private groupByProperty(array: PHBSMarketAnalysisByBrandTableDataModel[], property: string) {
        const groupData: PHBSMarketAnalysisByBrandTableDataModel[][] = [];

        // Grouping the data in rows
        for (let obj of array) {
            let flagMakeNewGroup = true;
            // Checks If there is group/array for this object
            for (let arr of groupData) {
                for (let element of arr) {
                    if (element[property as keyof PHBSMarketAnalysisByBrandTableDataModel] === obj[property as keyof PHBSMarketAnalysisByBrandTableDataModel]) {
                        arr.push(obj);
                        flagMakeNewGroup = false;
                    }
                    break;
                }
            }
            // Creates new group/array
            if (flagMakeNewGroup) {
                groupData.push([obj]);
            }
        }

        return groupData;
    }

    private getHeaderTableRow(data: string[]) {
        let factors = ["", "MAT", ...data, "Brand Strength Score"];

        const tableRow: pptxgen.TableRow[] = [];
        const tableCells: pptxgen.TableCell[] = [];

        // header row
        factors.forEach((factorName: string) => {
            tableCells.push(
                {
                    text: factorName,
                    options: {
                        bold: true,
                        fill: { color: this.colors.default },
                        color: this.colors.white,
                        border:
                            [
                                { type: 'solid', pt: 0.5, color: this.colors.border },
                                { type: 'solid', pt: 0.5, color: this.colors.white },
                                { type: 'solid', pt: 0.5, color: this.colors.border },
                                { type: 'solid', pt: 0.5, color: this.colors.white },
                            ]
                    }
                }
            );
        });
        tableRow.push(tableCells);

        return tableRow;
    }

    private groupDataByRows(factorData: PHBSMarketAnalysisByBrandTableDataModel[], scoreData: PHBSMarketAnalysisByBrandTableDataModel[]) {

        // 1st step - Grouping the data by Category property

        // adding factor data
        const categoryGroupsData: PHBSMarketAnalysisByBrandTableDataModel[][] = [];
        for (let obj of factorData) {
            let flagMakeNewGroup = true;
            // Checks If there is group/array for this object
            for (let arr of categoryGroupsData) {
                for (let element of arr) {
                    if (element.Category === obj.Category) {
                        arr.push(obj);
                        flagMakeNewGroup = false;
                    }
                    break;
                }
            }
            // Creates new group/array
            if (flagMakeNewGroup) {
                categoryGroupsData.push([obj]);
            }
        }

        // adding score data
        scoreData.forEach((obj) => {

            for (let arr of categoryGroupsData) {
                for (let element of arr) {
                    if (element.Category === obj.Category) {
                        arr.push(obj);
                    }
                    break;
                }
            }
        });

        // 2nd step - Grouping the data in rows
        const groupData: PHBSMarketAnalysisByBrandTableDataModel[][] = [];
        for (let categoryGroup of categoryGroupsData) {
            categoryGroup.sort((a, b) => b.TimeRank - a.TimeRank);
            const rows: PHBSMarketAnalysisByBrandTableDataModel[][] = this.groupByProperty(categoryGroup, "TimeRank");

            rows.forEach((row) => {
                groupData.push(row);
            });
        }

        return groupData;
    }

    private splitRowsByTables(groupData: PHBSMarketAnalysisByBrandTableDataModel[][], rowsPerTable: number) {
        const tables: PHBSMarketAnalysisByBrandTableDataModel[][][] = [];

        // full tables
        const numberOfFullTables = Math.trunc(groupData.length / rowsPerTable);
        for (let i = 0; i < numberOfFullTables; ++i) {
            tables.push(groupData.slice((i * rowsPerTable), (i * rowsPerTable) + rowsPerTable));
        }

        // last smaller table
        const lastTable = groupData.length % rowsPerTable;
        if (lastTable) {
            tables.push(groupData.slice((groupData.length - lastTable), groupData.length));
        }

        return tables;
    }

    private countRowspan(groupData: PHBSMarketAnalysisByBrandTableDataModel[][], currentIndex: number, objectKey: string) {
        let rowspan = 1;

        let currentGroup;
        groupData[currentIndex].forEach(obj => {
            currentGroup = obj[objectKey as keyof PHBSMarketAnalysisByBrandTableDataModel];
        })

        for (let row = (currentIndex + 1); row < groupData.length; ++row) {
            let nextGroup;
            groupData[row].forEach(obj => {
                nextGroup = obj[objectKey as keyof PHBSMarketAnalysisByBrandTableDataModel];
            })

            if (currentGroup === nextGroup) {
                ++rowspan;
            }
        }

        return rowspan;
    }

    private getBodyTableRows(groupData: PHBSMarketAnalysisByBrandTableDataModel[][]) {
        let tableRows: pptxgen.TableRow[] = [];
        let usedCategory: string;

        groupData.forEach((row, index) => {
            let currentCategory: string = "";
            let currentPeriod: string = "";
            const values: pptxgen.TableCell[] = [];

            // value rows
            row.forEach(obj => {
                if (obj.Value) {
                    let backGroundColor = obj.Color ? obj.Color : this.colors.white;

                    values.push(
                        {
                            text: obj.Value.toFixed(1),
                            options: { margin: [0, 3, 0, 0], color: this.colors.black, fill: { color: backGroundColor }, align: 'center' }
                        }
                    );
                } else {
                    values.push(
                        {
                            text: "",
                            options: { margin: [0, 3, 0, 0], align: 'center' }
                        }
                    );
                }

                currentCategory = obj.Category;
                currentPeriod = obj.Date;
            });

            // Adding category and period cells to the rows
            let tableCells: pptxgen.TableCell[] = [];

            if (tableRows.length === 0) {

                // first row only
                const rowspan = this.countRowspan(groupData, index, "Category");
                tableCells.push(
                    {
                        text: currentCategory,
                        options: { color: this.colors.black, fill: { color: this.colors.white }, align: 'center', rowspan: rowspan }
                    },
                    {
                        text: currentPeriod,
                        options: { color: this.colors.black, fill: { color: this.colors.white }, align: 'center' }
                    },
                    ...values
                );

            } else {
                if (currentCategory !== usedCategory) {
                    const rowspan = this.countRowspan(groupData, index, "Category")
                    tableCells.push(
                        {
                            text: currentCategory,
                            options: { color: this.colors.black, fill: { color: this.colors.white }, align: 'center', rowspan }
                        }
                    );
                }

                tableCells.push(
                    {
                        text: currentPeriod,
                        options: { color: this.colors.black, fill: { color: this.colors.white }, align: 'center' }
                    },
                    ...values
                );
            }

            tableRows.push(tableCells);
            usedCategory = currentCategory;
        });

        return tableRows;
    }

    private addTable(chartSlide: pptxgen.Slide, table: PHBSMarketAnalysisByBrandTableDataModel[][], factors: string[]) {
        const headerData = this.getHeaderTableRow(factors);
        const bodyData = this.getBodyTableRows(table);

        const options: pptxgen.TableProps = {
            y: 1.1,
            w: '92%',
            align: 'center',
            valign: 'middle',
            border: { type: 'solid', color: this.colors.neutral, pt: 0.2 },
            fontSize: 7,
            rowH: 0.35,
        };

        chartSlide.addTable([...headerData, ...bodyData], options);
    }

    private addLegend(chartSlide: pptxgen.Slide, data: PHBSMarketAnalysisByBrandTableLegendModel[]) {
        data.sort((a, b) => a.Order - b.Order);

        const legend: any = [];
        data.forEach((obj: PHBSMarketAnalysisByBrandTableLegendModel) => {

            let addToLegendFlag = true;
            for (let legendObj of legend) {
                if (obj.LegendText === legendObj.legendText || legendObj.legendText === 'Previous MAT') {
                    addToLegendFlag = false;
                    break;
                }
            }

            if (addToLegendFlag) {
                if (obj.LegendText === ' ') {
                    legend.push({
                        legendText: 'Previous MAT',
                        legendColor: '#b6b6b6',
                    });
                } else {
                    legend.push({
                        legendText: obj.LegendText,
                        legendColor: obj.Color,
                    });
                }
            }
            addToLegendFlag = true;
        });

        legend.forEach((obj: any, index: number) => {
            PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: obj.legendText, color: obj.legendColor }, { x: (0.5 + index * 1.2), y: 5.1 });
        })
    }
}

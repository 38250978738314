import { IDataMapping } from '../../IDataMapping';

export class NineGLineChartDistributionTitleModel implements IDataMapping<NineGLineChartDistributionTitleModel> {
    public Title: string = '';
    public getMappings?() {
        return {
            Title: "9G Titles (Weighted Distribution)",
        };
    }
}

import pptxgen from 'pptxgenjs';

import { CategoryUserProfileTitleModel } from '../../../models/PersonalHealth/CategoryUserProfile/CategoryUserProfileTitleModel';
import { CategoryUserProfileDataModel } from '../../../models/PersonalHealth/CategoryUserProfile/CategoryUserProfileDataModel';

import { PHFirstSlideRenderer } from '../../Renderers/PHFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

export class CategoryUserProfileService extends BasePptxService {
  protected chartQuestion: string = '';
  protected firstSlideRenderer: PHFirstSlideRenderer = new PHFirstSlideRenderer();
  protected titleSheet: string;
  protected dataSheet: string;

  constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
      super(view, chartTitle);
      this.titleSheet = titleSheet;
      this.dataSheet = dataSheet;
  }

  protected async setChartSlideLayout(slide: pptxgen.Slide) {
    // Override title's options and add filter's name in it.
    const titleData = await this.getMappedChartData(CategoryUserProfileTitleModel, this.titleSheet);
    let subTitle: string = "";
    
    titleData.map( obj => {
      this.chartTitle = obj.Title;
      return subTitle = obj.SubTitle;
    });
    slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.35, bold: true });
    slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.58, italic: true });
  }

  protected async addChartSlide(chartSlide: pptxgen.Slide) {
    const data = await this.getMappedChartData(CategoryUserProfileDataModel, this.dataSheet);
    const newArr: any = [];
    const lineColors: string[] = [];

    data.forEach((obj: any) => {
        if (obj.Name) {
            lineColors.push(obj.Color)
            if(newArr.length > 0) {
                newArr[0].values.push(parseFloat(obj.Value));
                newArr[0].labels.push(obj.Label);
            } else { 
                newArr.push(
                    {
                        name: obj.Name,
                        labels: [obj.Label],
                        values: [parseFloat(obj.Value)],
                    }
                )
            }
        }
    })

    chartSlide.addChart(
      this.presentation.ChartType.doughnut,
      newArr,
      {
        x: 1,
        y: 1,
        w: "70%",
        h: "70%",
        legendPos: "b",
        showLabel: false,
        showValue: false,
        showPercent: true,
        showLegend: true,
        showTitle: false,
        chartColors: lineColors,
        holeSize: 70
      }
    );
  }
}

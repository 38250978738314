import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from "@material-ui/core/OutlinedInput";

const MultiSelectWrapper = (props: any) => {
  const useStyles = makeStyles({
    labelRoot: {
     lineHeight: "0 !important",
      background: "white",
      color: props.onError && props.validateError? "#B00020 !important" : "rgba(0, 0, 0, 0.54)",
      fontSize: "14px !important",
    },
    labelFocused: {
      lineHeight: "1 !important",
      background: "white",
      color: props.onError && props.validateError ? "#B00020 !important" : "black !important",
      fontSize: "16px !important",
    },
  });

  const useOutlinedInputStylesErr = makeStyles(theme => ({
    root: {
      "& $notchedOutline": {
        borderColor:  "#B00020 !important",
        color: "#B00020 !important"
      },
    },
    focused: {},
    notchedOutline: {}
  }));

  const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
      "&:hover $notchedOutline": {
        borderColor:  "#00AAB5"
      },
      "&$focused $notchedOutline": {
        borderColor: "#00AAB5"
      }
    },
    focused: {},
    notchedOutline: {}
  }));

  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const outlinedInputClassesErr = useOutlinedInputStylesErr();
	return (
		<FormControl style={{maxWidth: "210px"}} variant="outlined" fullWidth>
      <InputLabel 
        id="demo-mutiple-checkbox-label"
        htmlFor={props.htmlFor}
        classes={{ 
          root: classes.labelRoot,
          focused: classes.labelFocused,
          shrink: classes.labelFocused,
        }}
        >
          {props.label}
      </InputLabel>
      <Select
        SelectDisplayProps={{ style: { paddingTop: 10, paddingBottom: 8 } }}
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        multiple
        value={props.value}
        onChange={props.onChange}
        input={
          <OutlinedInput 
          name="userRoles"
          id="userRoles"
          classes={props.onError && props.validateError ? outlinedInputClassesErr : outlinedInputClasses}/>
        }
        renderValue={(selected : any) => selected.join(', ')}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        error={props.onError && props.validateError}
      >
        {props.roleOptions.map((option: any) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={props.value.indexOf(option.value) > -1} />
            <ListItemText primary={option.value} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText style={{color: "#B00020"}} className="Mui-error">{props.validateHelper('userRoles', props.onError, props.validateError)}</FormHelperText>
    </FormControl>
	);
};

export default MultiSelectWrapper;

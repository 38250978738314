import React from 'react';
import { ISVGProps } from './ISVGProps';

export const NonCheckedCheckboxSvg = (props: ISVGProps) => {
  const { width, height } = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox={`0 0 21.414 20`}
    >
      <defs>
        <style>
          {
            '.a{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}'
          }
        </style>
      </defs>
      <g transform='translate(1 1)'>
        <path
          className='a'
          d='M21,12v7a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H16'
          transform='translate(-3 -3)'
        />
      </g>
    </svg>
  );
};

import { IDataMapping } from '../../../../IDataMapping';

export class BSPerformanceChartBubbleDataModel implements IDataMapping<BSPerformanceChartBubbleDataModel> {
    public Answer:          string = '';
    public Brand:           string = '';
    public Color:           string = '';
    public RowOrder:        number = Number(0);
    public Value:           number = Number(0);
    public FilterAudience:  string = '';
    public Check:           number = Number(0);
    public Factor:          string = '';

    public getMappings?() {
        return {
            Answer:         'Answer',
            Color:          'Colour',
            Value:          'AVG(Value)',
            FilterAudience: 'Audience_tbl',
            Brand:          'Brand',
            RowOrder:       'AGG(INDEX())',
            Check:          'AGG(AVG(-1))',
            Factor:         'ATTR(BS_statement)'
        };
    }
}

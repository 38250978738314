import React, { useState } from 'react';
import styles from './DocumentsTable.module.scss';
import { IDocumentsTableProps } from './IDocumentsTableProps';
import { BreadCrumb } from 'primereact/breadcrumb';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DownloadSvg } from '../../common/Svgs/DownloadSvg';
import { format } from 'date-fns';
import { MenuItem } from 'primereact/components/menuitem/MenuItem';
import { FilesTableRowWrapper } from '../../../models/FilesTableRowWrapper';
import {
  CustomIcon,
  mapExtensionToIconType,
} from '../../common/Svgs/CustomIcon';
import { canViewInBrowser, fileIsVideo } from '../../../shared/utility';
import { IconType } from '../../../shared/enums/IconType';
import { find, some } from 'lodash';
import authService from '../../api-authorization/AuthorizeService';

export const DocumentsTable = (props: IDocumentsTableProps): JSX.Element => {
  const mainBreadCrumbLabel = 'Documents & Reports';

  const { folderTree, handleTogglePlayer, handleSetVideo } = props;

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const [openedFolderName, setOpenedFolderName] = useState<string | undefined>(
    undefined
  );

  const [breadCrumbItems, setBreadCrumbItems] = useState<MenuItem[]>([
    {
      label: mainBreadCrumbLabel,
      command: () => onBreadCrumbClicked(mainBreadCrumbLabel, 0),
    },
  ]);

  const openVideoPlayer = (videoPath: string): void => {
    if (folderTree) {
      const videoFile = find(folderTree.files, (f) => f.fullPath === videoPath);
      if (videoFile) {
        handleSetVideo(videoFile);
        handleTogglePlayer(true);
      }
    }
  };

  const onBreadCrumbClicked = (label: string | undefined, index: number) => {
    const currentBreadCrumbs = breadCrumbItems;
    currentBreadCrumbs.length = index + 1;
    setBreadCrumbItems(currentBreadCrumbs);

    setOpenedFolderName(label);
  };

  const downloadOrViewFile = async (
    filePath: string,
    fileName: string,
    fileExtension: string | undefined,
    download: boolean
  ) => {
    if (fileExtension && !download && !canViewInBrowser(fileExtension)) {
      return;
    }

    if (download) {
      setIsDownloading(true);
    }

    const token = await authService.getAccessToken();

    const fileResponse: Response = await fetch('Files/DownloadFile', {
      method: 'POST',
      body: JSON.stringify(filePath),
      headers: !token
        ? { 'Content-Type': 'application/json' }
        : {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
    });

    if (fileResponse.ok) {
      const fileContent: Blob = await fileResponse.blob();
      const blobUrl = URL.createObjectURL(fileContent);
      const viewLink = document.createElement('a');
      viewLink.target = '_blank';

      viewLink.href = blobUrl;

      if (download) {
        viewLink.download = fileName;
      }

      document.body.appendChild(viewLink);

      viewLink.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      document.body.removeChild(viewLink);

      if (download) {
        setIsDownloading(false);
      }
    }
  };

  const onTitleClick = async (rowData: FilesTableRowWrapper): Promise<void> => {
    if (rowData.isFile && rowData.extension) {
      if (fileIsVideo(rowData.extension)) {
        openVideoPlayer(rowData.fullPath);
      } else {
        await downloadOrViewFile(
          rowData.fullPath,
          rowData.name,
          rowData.extension,
          false
        );
      }
    } else if (!rowData.isFile) {
      const currentBreadCrumbs = breadCrumbItems;
      const index = currentBreadCrumbs.length;

      currentBreadCrumbs.push({
        label: rowData.name,
        command: () => onBreadCrumbClicked(rowData.name, index),
      });
      setOpenedFolderName(rowData.name);
      setBreadCrumbItems(currentBreadCrumbs);
    }
  };

  const getTitleBody = (rowData: FilesTableRowWrapper): JSX.Element => {
    return <span onClick={(e) => onTitleClick(rowData)}>{rowData.name}</span>;
  };

  const getDataTableRows = (): FilesTableRowWrapper[] => {
    const result: FilesTableRowWrapper[] = [];

    if (folderTree) {
      if (openedFolderName && openedFolderName !== mainBreadCrumbLabel) {
        const filteredFiles = folderTree.files.filter(
          (f) => f.directoryName === openedFolderName
        );
        const filteredFolders = folderTree.folders.filter(
          (fol) => fol.parentDirectory === openedFolderName
        );

        result.push(
          ...filteredFolders.map((ff) => ({
            name: ff.name,
            modified: ff.modified,
            parentDirectory: ff.parentDirectory,
            isFile: false,
            directoryName: ff.name,
            extension: undefined,
            fullPath: ff.fullPath,
          }))
        );

        result.push(
          ...filteredFiles.map((ff) => ({
            name: ff.name,
            modified: ff.modified,
            extension: ff.extension,
            isFile: true,
            directoryName: ff.directoryName,
            parentDirectory: undefined,
            fullPath: ff.fullPath,
          }))
        );
      } else {
        const filteredFiles = folderTree.files.filter(
          (f) =>
            !some(folderTree.folders, (fol) => fol.name === f.directoryName)
        );

        const filteredFolders = folderTree.folders.filter(
          (f) =>
            !some(folderTree.folders, (fol) => fol.name === f.parentDirectory)
        );

        result.push(
          ...filteredFolders.map((fol) => ({
            name: fol.name,
            modified: fol.modified,
            parentDirectory: fol.parentDirectory,
            isFile: false,
            directoryName: fol.name,
            extension: undefined,
            fullPath: fol.fullPath,
          }))
        );

        result.push(
          ...filteredFiles.map((ff) => ({
            name: ff.name,
            modified: ff.modified,
            extension: ff.extension,
            isFile: true,
            directoryName: ff.directoryName,
            parentDirectory: undefined,
            fullPath: ff.fullPath,
          }))
        );
      }
    }

    return result;
  };

  const getActionButtons = (rowData: FilesTableRowWrapper): JSX.Element => {
    if (rowData.isFile) {
      return (
        <div className={styles.actions_column_body}>
          <i
            className={styles.action_icon}
            onClick={(e) =>
              downloadOrViewFile(
                rowData.fullPath,
                rowData.name,
                rowData.extension,
                true
              )
            }
          >
            <DownloadSvg width={'17'} height={'17.5'} />
          </i>
        </div>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const iconColumnHeader: JSX.Element = (
    <i className={`pi pi-file ${styles.icon_header}`}></i>
  );

  const getIconColumnBody = (rowData: FilesTableRowWrapper): JSX.Element => {
    if (rowData.isFile) {
      const extension = rowData.extension;
      if (extension) {
        const extensionIconType: IconType = mapExtensionToIconType(extension);
        return (
          <CustomIcon iconType={extensionIconType} width={'20'} height={'20'} />
        );
      }
      return <CustomIcon iconType={IconType.File} width={'20'} height={'20'} />;
    } else {
      return (
        <CustomIcon iconType={IconType.Folder} width={'20'} height={'20'} />
      );
    }
  };

  const getDateBody = (
    rowData: FilesTableRowWrapper,
    column: any
  ): JSX.Element => {
    const formattedDate: string = format(new Date(rowData.modified), 'dd-MM-yyyy HH:mm:ss');
    return <span>{formattedDate}</span>;
  };

  return (
    <React.Fragment>
      {isDownloading ? (
        <div className={styles.download_glass}></div>
      ) : undefined}
      <BreadCrumb
        model={breadCrumbItems}
        className={styles.bread_crumb_component}
      />
      <div className={styles.files_table_container}>
        <DataTable
          responsive={true}
          rows={10}
          paginator={true}
          alwaysShowPaginator={false}
          className={`${styles.files_table} ${styles.data_table}`}
          value={getDataTableRows()}
        >
          <Column
            className={styles.icon_column}
            header={iconColumnHeader}
            body={getIconColumnBody}
          />
          <Column
            field='name'
            header={'Name'}
            className={`${styles.left_aligned_column} ${styles.document_name_column}`}
            body={getTitleBody}
          />
          <Column
            field='modified'
            header={'Modified'}
            body={getDateBody}
            className={`${styles.center_aligned_column}`}
          />
          <Column
            header={'Action'}
            body={getActionButtons}
            className={`${styles.actions_column}`}
          />
        </DataTable>
      </div>
    </React.Fragment>
  );
};

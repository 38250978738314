import { IDataMapping } from '../../IDataMapping';

export class NineGLineChartShareDataModel implements IDataMapping<NineGLineChartShareDataModel> {
    public Label: string = '';
    public Name: string = '';
    public Value: number = Number(0);
    public Rank: number = Number(0);
    public Color: string = '';
    public getMappings?() {
        return {
            Label: 'QUARTER(Date_calc (R&R/MS))',
            Name: 'Brand Name',
            Value: 'MAX(SOV (cap 100))',
            Rank: 'MAX(Rank_unique (SOV))',
            Color: 'Brand name color',
        };
    }
}

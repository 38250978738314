import { ISideMenuButton } from "./ILayoutState";

export enum LayoutActionTypes {
    SHOW_MESSAGE = 'SHOW_MESSAGE',
    SHOW_TABLEAU_USER_ERROR = 'SHOW_TABLEAU_USER_ERROR',
    SET_LOGO = 'SET_LOGO',
    SET_SIDEMENU_BUTTON = 'SET_SIDEMENU_BUTTON',
}

export type LayoutActions =
    | IShowMessageAction
    | IShowTableauUserErrorAction
    | ISetLogoAction
    | ISetSideMenuButtonAction;

export interface IShowMessageAction {
    type: LayoutActionTypes.SHOW_MESSAGE;
    message: any;
}
export const showMessageAction = (message: any): IShowMessageAction => ({
    type: LayoutActionTypes.SHOW_MESSAGE,
    message,
});

export interface IShowTableauUserErrorAction {
    type: LayoutActionTypes.SHOW_TABLEAU_USER_ERROR;
    show: boolean;
}
export const showTableauUserErrorAction = (
    show: boolean
): IShowTableauUserErrorAction => ({
    type: LayoutActionTypes.SHOW_TABLEAU_USER_ERROR,
    show,
});

export interface ISetLogoAction {
    type: LayoutActionTypes.SET_LOGO;
    logo: string;
}
export const setLogoAction = (logo: string): ISetLogoAction => ({
    type: LayoutActionTypes.SET_LOGO,
    logo,
});

export interface ISetSideMenuButtonAction {
    type: LayoutActionTypes.SET_SIDEMENU_BUTTON;
    sideMenuButton: ISideMenuButton;
}
export const setSideMenuButtonAction = (sideMenuButton: ISideMenuButton): ISetSideMenuButtonAction => ({
    type: LayoutActionTypes.SET_SIDEMENU_BUTTON,
    sideMenuButton
});

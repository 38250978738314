import React from 'react';
import { ISVGProps } from './ISVGProps';

export const ArrowRight = (props: ISVGProps) => {
  const { width, height } = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 16 16.828'
    >
      <defs>
        <style>
          {
            '.a{fill:none;stroke:#212529;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}'
          }
        </style>
      </defs>
      <g transform='translate(-4 -3.586)'>
        <line className='a' x2='14' transform='translate(5 12)' />
        <path className='a' d='M12,5l7,7-7,7' />
      </g>
    </svg>
  );
};

import { IDataMapping } from '../../../IDataMapping';

export class AppendixJapanPieDataModel implements IDataMapping<AppendixJapanPieDataModel> {
    public Name: string = '';
    public Label: string = '';
    public Color: string = '';
    public Value: number = Number(0);
    public getMappings?() {
        return {
            Name: 'Answer_Text',
            Label: 'Title Pie chart Japan',
            Color: 'PrescribedForDiseaseColor',
            Value: 'AVG(Value)',
        };
    }
}

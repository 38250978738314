import { IDataMapping } from '../../IDataMapping';

export class ContributorsCategoryDataModel implements IDataMapping<ContributorsCategoryDataModel> {
    public Color: string = '';
    public LabelsNegative: string = '';
    public LabelsPositive: string = '';
    public Legend: string = '';
    public Rank: number = 0;
    public ValueNegative: number = Number(0);
    public ValuePositive: number = Number(0);
    public getMappings?() {
        return {
            Color: "Colour Tornado",
            LabelsNegative: "Labels Negative Category",
            LabelsPositive: "Labels Positive Category",
            Legend: "Legend Tornado",
            Rank: "AGG(Rank Tornado)",
            ValueNegative: "AVG(Value Negative)",
            ValuePositive: "AVG(Value Positive)",
        };
    }
}

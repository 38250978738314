
import { RoleViewModel } from '../../../models/Roles/RoleViewModel';
import { CreateRoleModel } from '../../../models/Roles/CreateRoleModel';
import { EditRoleModel } from '../../../models/Roles/EditRoleModel';
import { CreateRolePermissionModel } from '../../../models/Roles/CreateRolePermissionModel';
import { ModelStateErrorModel } from '../../../models/ModelStateErrorModel';
import { FormType } from '../../../shared/enums/FormType';

export class RoleFormValidator {
    public validate(
        formRole: CreateRoleModel | EditRoleModel,
        roles: RoleViewModel[],
        formType: FormType) {
        const errorObjects: ModelStateErrorModel[] = [];
        this.validateName(errorObjects, formRole, roles, formType);
        this.validatePermissions(errorObjects, formRole);
        return errorObjects;
    }

    private validateName(
        errorObjects: ModelStateErrorModel[],
        formRole: CreateRoleModel | EditRoleModel,
        roles: RoleViewModel[],
        formType: FormType) {
        if (formRole.name === undefined || formRole.name === null || formRole.name.trim() === '') {
            errorObjects.push({ key: 'name', errors: [`Please provide a role name.`] });
            return;
        }

        let hasError = false;
        const roleWithTheSameName = roles.find(r => r.name === formRole.name);
        if (roleWithTheSameName) {
            if (formType === FormType.Create) {
                hasError = true;
            } else if (formType === FormType.Edit) {
                hasError = roleWithTheSameName.rolePermission?.id !== (formRole as EditRoleModel).rolePermission.id;
            }
        }

        if (hasError) {
            errorObjects.push({ key: 'name', errors: [`The role '${formRole.name}' already exists.`] });
        }
    }

    private validatePermissions(errorObjects: ModelStateErrorModel[], roleModel: CreateRoleModel | EditRoleModel) {
        let hasError = true;
        for (const key in roleModel.rolePermission) {
            if (Object.prototype.hasOwnProperty.call(roleModel.rolePermission, key)) {
                const permission = roleModel.rolePermission[key as keyof CreateRolePermissionModel];
                if (permission > 0) {
                    hasError = false;
                    return;
                }
            }
        }

        if (hasError) {
            errorObjects.push({ key: 'rolePermission', errors: ['Please select at least one Action for at least one Section.'] });
        }
    }
}

const roleFormValidator = new RoleFormValidator();

export default roleFormValidator;

/* eslint-disable no-extend-native */
declare global {
    /* tslint:disable */
    interface Array<T> {
        firstOrNull(predicate?: (val: T, index?: number, arr?: T[]) => boolean): T | null;

        first(predicate?: (val: T, index?: number, arr?: T[]) => boolean): T;

        distinct(): T[];

        distinctBy(func: (x: T) => boolean | number | string | null | undefined): T[];

        sortBySimpleProp<T>(prop: keyof T): T[];
        sum(this: number[]): number
    }

    interface StringConstructor {
        isNullOrEmpty(value: string | undefined | null): boolean;
    }

    interface ArrayConstructor {
        createWithNullValues(newLenght: number): null[];
    }

    interface String {
        contains(value: string): boolean;
    }
}

Array.createWithNullValues = function (newLenght: number): null[] {
    if (newLenght) {
        return Array.from(Array(newLenght).keys()).map((_) => null)
    } else {
        return [];
    }
};

Array.prototype.firstOrNull = function <T>(predicate?: (val: T, index?: number, arr?: T[]) => T): T | null {
    if (!predicate) {
        if (this && this.length > 0) {
            // tslint:disable-next-line: no-unsafe-any
            return this[0];
        } else {
            return null;
        }
    }
    const results: T[] = this.filter(predicate);
    if (results.length > 0) {
        return results[0];
    } else {
        return null;
    }
};

Array.prototype.first = function <T>(predicate?: (val: T, index?: number, arr?: T[]) => T): T {
    if (!predicate) {
        // tslint:disable-next-line: no-unsafe-any
        return this[0];
    }
    const results: T[] = this.filter(predicate);

    return results[0];
};

Array.prototype.distinct = function <T>(): T[] {
    if (this && this.length > 0) {
        return this.filter((v: T, i: number, arr: T[]) => {
            return arr.indexOf(v) === i;
        });
    } else {
        return this;
    }
};

Array.prototype.distinctBy = function <T>(func: (x: T) => boolean | number | string | null | undefined): T[] {
    if (this && this.length > 0) {
        const primitiveValues: (boolean | number | string | null | undefined)[] = this.map(func);
        const uniqueIndexes: number[] = [];
        const processedItems: (boolean | number | string | null | undefined)[] = [];
        primitiveValues.forEach((en: boolean | number | string | null | undefined, ind: number) => {
            if (processedItems.indexOf(en) < 0) {
                processedItems.push(en);
                uniqueIndexes.push(ind);
            }
        });

        return this.filter((_: T, ind: number) => {
            return uniqueIndexes.indexOf(ind) >= 0;
        });
    } else {
        return this;
    }
};

Array.prototype.sortBySimpleProp = function <T>(prop: keyof T): T[] {
    if (typeof prop !== "string" && typeof prop !== "number") {
        throw new Error(`Array.prototype.sortBy expect string or number.`);
    }

    const newInstance = [...this];
    if (typeof prop === "string") {
        newInstance.sort((a, b) => {
            return a[prop] < b[prop] ? -1 : 1;
        });
    } else {
        newInstance.sort((a, b) => {
            return a[prop] - b[prop];
        });
    }

    return newInstance;
};

Array.prototype.sum = function (this: number[]): number {
    return this.reduce((a: number, b: number) => a + b, 0);
};

String.isNullOrEmpty = (value: string | undefined | null): boolean => {
    if (value === undefined || value === null) {
        return true;
    } else {
        return value.length === 0;
    }
};

String.prototype.contains = function (value: string): boolean {
    return this.indexOf(value) !== -1;
};

export { };

export const nameof = <T>(name: keyof T) => name;



import { IDataMapping } from "../../../../IDataMapping";

export class PHBSGlobalAudiencePerBrandChartTitleModel implements IDataMapping<PHBSGlobalAudiencePerBrandChartTitleModel> {
    public Title:           string = '';
    public SubTitle:        string = '';
    public FactorFilter:    string = '';
    public AudienceFilter:  string = '';
    public Note:            string = '';

    public getMappings?() {
        return {
            Title:          'Brand Strength by Audience per Brand Title (Global)',
            SubTitle:       'Brand Strength Analysis Subtitle',
            FactorFilter:   'ATTR(Answer)',
            AudienceFilter: 'ATTR(Audience)',
            Note:           'ATTR(Note External)',
        };
    }
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

const AutoCompleteWrapper = (props: any) => {
  const useStyles = makeStyles({
    styles: {
      "& .MuiOutlinedInput-root": {
        padding: "0px",
        paddingLeft: "8px !important",
      },
      "& .MuiFormLabel-root": {
        fontSize: "14px !important",
        lineHeight: 0
      },
      "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-filled": {
        lineHeight: 1,
        color: "black",
        fontSize: "16px !important",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#00AAB5"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#00AAB5"
      }
    }
  });

  const classes = useStyles();

	return (
		<Autocomplete
      id="autocomplete"
      style={{ padding: 0 }}
      options={props.options}
      value={props.value}
      classes={{
        root: classes.styles,
      }}
      onChange={(event, newValue) => {
        props.onChange(newValue);
        props.setValue(newValue);
      }}
      autoHighlight
      getOptionLabel={(option) => option ? option.label : ""}
      renderOption={(option) => (
        <React.Fragment>
          {option.label}
        </React.Fragment>
      )}
      renderInput={(params : any) => (
        <TextField
          {...params}
          label={props.label}
          variant="outlined"
          onChange={(e: any) => props.onChange(e.target.value)}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
	);
};

export default AutoCompleteWrapper;

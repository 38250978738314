import pptxgen from 'pptxgenjs';

import { NineGLineChartRnRTitleModel } from '../../../models/PersonalHealth/NineGLineChart/NineGLineChartRnRTitleModel';
import { NineGLineChartRnRDataModel } from '../../../models/PersonalHealth/NineGLineChart/NineGLineChartRnRDataModel';
import { NineGLineChartCombinedTitleModel } from '../../../models/PersonalHealth/NineGLineChart/NineGLineChartCombinedTitleModel';

import { PHFirstSlideRenderer } from '../../Renderers/PHFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

import { filterInitDuplicateData } from "../../../../shared/utility";

export class NineGLineChartRnRService extends BasePptxService {
  protected chartQuestion: string = '';
  protected firstSlideRenderer: PHFirstSlideRenderer = new PHFirstSlideRenderer();
  protected titleSheet: string;
  protected dataSheet: string;

  constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
      super(view, chartTitle);
      this.titleSheet = titleSheet;
      this.dataSheet = dataSheet;
  }

  protected async setChartSlideLayout(slide: pptxgen.Slide) {
    // Override title's options and add filter's name in it.
    const titleData = await this.getMappedChartData(NineGLineChartRnRTitleModel, this.titleSheet);
    const subTitleData = await this.getMappedChartData(NineGLineChartCombinedTitleModel, 'BMC - 9G combined title');
    let subTitle: string = "";
    
    titleData.map( obj => {
      return this.chartTitle = obj.Title;
    });

    subTitleData.map( obj => {
        return subTitle = `${obj.Country} ${obj.Brand}`;
    });

    slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.45, bold: true });
    slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.75, italic: true });
  }

  protected async addChartSlide(chartSlide: pptxgen.Slide) {
    const mappedData = await this.getMappedChartData(NineGLineChartRnRDataModel, this.dataSheet);
    let filteredData = filterInitDuplicateData(mappedData);

    if(filteredData.length > 0) {
        filteredData.sort((a: any, b: any) => a.Rank - b.Rank);

        let averageLabels: string[] = [];
        let averageValues: number[] = [];
        const averageColors: string[] = [];

        let numberOfReviewsLabels: string[] = [];
        let numberOfReviewsValues: number[] = [];
        const numberOfReviewsColors: string[] = [];

        const averageRatingData = [
          {
            name: '',
            labels: averageLabels,
            values: averageValues,
          }
        ]

        const numberOfReviewsData = [
          {
            name: '',
            labels: numberOfReviewsLabels,
            values: numberOfReviewsValues,
          }
        ]

        filteredData.forEach((obj: any) => {
          if (obj.AverageRating) {
            averageRatingData[0].name = obj.Name;
            averageLabels.push(obj.Label);
            averageValues.push(obj.AverageRating);
            averageColors.push(obj.AverageRatingColor);
          } else if (obj.NumberOfReviews) {
            numberOfReviewsData[0].name = obj.Name;
            numberOfReviewsLabels.push(obj.Label);
            numberOfReviewsValues.push(obj.NumberOfReviews);
            numberOfReviewsColors.push(obj.NumberOfReviewsColor);
          } return false;
        })

        let chartTypes:pptxgen.IChartMulti[] = [
          {
            type: this.presentation.ChartType.line,
            data: averageRatingData,
            options: {
              barGrouping: "standard",
              dataLabelFormatCode: '0\\%',
              valAxisLabelFormatCode: '0\\%',
              chartColors: averageColors.filter(this.distinctRepeatables),
            },
          },
          {
            type: this.presentation.ChartType.line,
            data: numberOfReviewsData,
            options: {
              barGrouping: "standard",
              secondaryValAxis: true,
              secondaryCatAxis: true,
              dataLabelFormatCode: '0\\',
              valAxisLabelFormatCode: '0\\',
              chartColors: numberOfReviewsColors.filter(this.distinctRepeatables),
            },
          },
        ];
        
        let options: any = {
          x: 1,
          y: 1,
          w: "70%",
          h: "70%",
          showLegend: true,
          legendPos: 'b',
          dataLabelPosition: 't',
          valAxisLabelFontSize: 10,
          catAxisLabelFontSize: 10,
          valAxes: [
            {
              showValAxisTitle: true,
              valAxisTitle: "Average Rating",
              valAxisMinVal: 0,
            },
            {
              showValAxisTitle: true,
              valAxisTitle: "Number of Reviews",
              valGridLine: { style: "none" },
              valAxisMinVal: 0,
            },
          ],
          catAxes: [
            {
              catAxisTitle: "Average Rating",
            },
            {
              catAxisHidden: true,
              catAxisTitle: "Number of Reviews",
            },
          ],
        }

        chartSlide.addChart(
          chartTypes,
          options
        );
    };
  }

  protected labelRotateDegree(array: any) {
    let degree: number = 0;
    array.forEach((obj: any) => {
      if (obj.labels.length > 10) {
        degree = 45;
      } else {
        degree = 0;
      }
    });
    return degree;
  }

  private distinctRepeatables = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };
}

import pptxgen from 'pptxgenjs';
import { LineData } from '../../../';
import { HSFirstSlideRenderer } from '../../Renderers/HSFirstSlideRenderer';
import { BaseChartOptions } from '../../../philips-constants';
import { ChartPptxService } from '../../ChartPptxService';

export class NpsOverTimePptxService extends ChartPptxService {
  protected firstSlideRenderer = new HSFirstSlideRenderer();

  protected chartQuestion: string =
    'NPS question: Based on your experience on using their product and services, how likely are you to recommend this company?' +
    '\n' +
    'Brand Preference question: Among the brands that you would consider, what would be your first choice when choosing [Product] and related services for your hospital?';

  protected async addChartSlide(chartSlide: pptxgen.Slide) {
    const chartSheetData = await this.getChartData('Overview_Trend Line');
    const legendSheetData = await this.getChartData('Overview_Trend Line (legend)');
    
    if (!chartSheetData || !legendSheetData) {
      return;
    }

    const chartData: LineData[] = super.processChartData(
      chartSheetData,
      { brandIndex: 1, yearIndex: 2, valueIndex: 4, tokensIndex: 3 }
    );
    chartSlide.addChart(this.presentation.ChartType.line, chartData, {
      ...BaseChartOptions,
      x: 1,
      y: 1.1,
      w: 8,
      h: 3.5,
      showValue: true,
      dataLabelPosition: 't',
      dataLabelFormatCode: '0%',
      catAxisLabelColor: this.colors.default,
      valAxisLabelColor: this.colors.default,
      valAxisLabelFormatCode: '0%',
      catAxisLineShow: false,
      valAxisLineShow: false,
      valAxisMajorUnit: 0.2,
      chartColors: this.lineColors,
    });

    const legendData = super.processLegendData(
      legendSheetData,
      { valueIndex: 6, tokensStringIndex: 2 }
    );

    chartSlide.addText(
      legendData,
      { x: 1, y: '85%' }
    );
  }
}
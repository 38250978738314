import { IDataMapping } from '../../../../IDataMapping';

export class CommunicationChannelsChartTitleModel implements IDataMapping<CommunicationChannelsChartTitleModel> {
    public Title: string = '';
    public Period: string = '';
    public Country: string = '';

    public getMappings?() {
        return {
            Title: 'Calc Comm Channels TEXT',
            Period: 'Period',
            Country: 'Calc Country',
        };
    }
}

import pptxgen from 'pptxgenjs';

import { CommonPieChartTitleModel } from '../../../models/PersonalHealth/CommonPieChart/CommonPieChartTitleModel';
import { CommonPieChartDataModel } from '../../../models/PersonalHealth/CommonPieChart/CommonPieChartDataModel';

import { PHFirstSlideRenderer } from '../../Renderers/PHFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

export class CommonPieChartService extends BasePptxService {
    protected chartQuestion: string = '';
    protected firstSlideRenderer: PHFirstSlideRenderer = new PHFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;
    protected alternativeTitleSheet: string | undefined;
    protected alternativeDataSheet: string | undefined;

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string, alternativeTitleSheet?: string, alternativeDataSheet?: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
        if (alternativeTitleSheet && alternativeDataSheet) {
            this.alternativeTitleSheet = alternativeTitleSheet;
            this.alternativeDataSheet = alternativeDataSheet;
        }
    }

    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        // Override title's options and add filter's name in it.
        let titleData = await this.getMappedChartData(CommonPieChartTitleModel, this.titleSheet);
        if (titleData.length === 0 && this.alternativeTitleSheet) {
            titleData = await this.getMappedChartData(CommonPieChartTitleModel, this.alternativeTitleSheet);
        }

        let subTitle: string = "";

        titleData.map(obj => {
            this.chartTitle = obj.Title;
            return subTitle = obj.Country + " " + obj.Category;
        });
        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.35, bold: true });
        slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.58, italic: true });
    }

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        let data = await this.getMappedChartData(CommonPieChartDataModel, this.dataSheet);

        if (data.length === 0 && this.alternativeDataSheet) {
            data = await this.getMappedChartData(CommonPieChartDataModel, this.alternativeDataSheet);
        }
        const newArr: any = [];
        const lineColors: string[] = [];

        data = data.filter(d => d.Name !== null);
        data.forEach((obj: any) => {
            if (obj.Name) {
                lineColors.push(obj.Color)
                if (newArr.length > 0) {
                    newArr[0].values.push(parseFloat(obj.Value));
                    newArr[0].labels.push(obj.Label);
                } else {
                    newArr.push(
                        {
                            name: obj.Name,
                            labels: [obj.Label],
                            values: [parseFloat(obj.Value)],
                        }
                    )
                }
            }
        })

        chartSlide.addChart(
            this.presentation.ChartType.doughnut,
            newArr,
            {
                x: 1,
                y: 1,
                w: "70%",
                h: "70%",
                legendPos: "b",
                showLabel: false,
                showValue: false,
                showPercent: true,
                showLegend: true,
                showTitle: false,
                chartColors: lineColors,
                holeSize: 70
            }
        );
    }
}

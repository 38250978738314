import React, { useEffect, useState } from 'react';
import { CustomDownloadPptDialogProps } from './ICustomDownloadPptDialogProps';
import styles from './CustomDownloadPptDialog.module.scss';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ChartDownloadHelper } from '../../../philips/helpers/ChartDownloadHelper';
import { ProgressSpinner } from 'primereact/progressspinner';

export const CustomDownloadPptDialog = (props: CustomDownloadPptDialogProps): JSX.Element => {
  const { view, viewId, isVisible, handleDialogClose } = props;

  const [chartsForDownload, setChartsForDownload] = useState<string[]>([]);
  const [viewCharts, setViewCharts] = useState<string[]>([]);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const hasAllSelected = viewCharts.every((x: string) => chartsForDownload.indexOf(x) >= 0);

  const onSelectAllChartsChange = () => hasAllSelected ? setChartsForDownload([]) : setChartsForDownload([...viewCharts]);

  const onSelectChartsChange = (chartName: string) => {
    const selectedCharts = [...chartsForDownload];

    if (!selectedCharts.includes(chartName)) {
      selectedCharts.push(chartName);
    } else {
      selectedCharts.splice(chartsForDownload.indexOf(chartName), 1);
    }

    setChartsForDownload(selectedCharts);
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    await Promise.all(
      chartsForDownload.map(chartForDownload => {
        const service = ChartDownloadHelper.getChartPptxService(view, viewId, chartForDownload);
        if (service) {
          return service.generate();
        }
      })
    );

    setIsDownloading(false);
  };

  useEffect(() => {
    if (view) {
      setIsLoading(true);
      ChartDownloadHelper.getViewChartNames(view, viewId).then((chartNames: string[]) => {
        setViewCharts(chartNames);
        setIsLoading(false);
      });
    }
  }, []);

  return (
    <Dialog
      header='Download PPT'
      visible={isVisible}
      onHide={handleDialogClose}
      className={styles.ppt_dialog}
      blockScroll={true}
    >
      {!isLoading ?
        <>
          <div className={styles.view_option}>
            <label
              htmlFor={'selectAll'}
              className={hasAllSelected ? styles.checked : undefined}
            >
              <input
                checked={hasAllSelected}
                onChange={onSelectAllChartsChange}
                value='selectAll'
                type='checkbox'
                name='selectAll'
              />
              <div className={styles.label_wrapper} onClick={onSelectAllChartsChange}>Select all</div>
            </label>
          </div>
          <div className={styles.options_wrapper}>
            {
              viewCharts.map((chartName: string, i: number) => (
                <div key={i} className={styles.view_option}>
                  <label
                    htmlFor={chartName}
                    className={chartsForDownload.includes(chartName) ? styles.checked : undefined}
                  >
                    <input
                      checked={chartsForDownload.includes(chartName)}
                      onChange={() => onSelectChartsChange(chartName)}
                      value={chartName}
                      type='checkbox'
                      name={chartName}
                    />
                    <div
                      className={styles.label_wrapper}
                      onClick={() => onSelectChartsChange(chartName)}
                    >
                      {chartName}
                    </div>
                  </label>
                </div>
              ))
            }
          </div>
          <div className={styles.ppt_download}>
            <Button
              onClick={handleDownload}
              label={'Download'}
              className={styles.ppt_download_button}
              disabled={chartsForDownload.length === 0 || isDownloading}
            />
          </div>
        </>
        : <ProgressSpinner className={styles.small_Loading} />}
    </Dialog>
  );
};

import { IDataMapping } from '../../../IDataMapping';

export class SRCBSAnalysisTableHeaderRowModel implements IDataMapping<SRCBSAnalysisTableHeaderRowModel> {
    public Answer: string = '';

    public getMappings?() {
        return {
            Answer: 'Answer',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';


export class UnaidedRecallCommAverageModel implements IDataMapping<UnaidedRecallCommAverageModel> {
  public Segment: string = '';
  public AverageNegative: number | null = 0;
  public AveragePositive: number | null = 0;

  public getMappings?() {
    return {
      Segment: 'Segment',
      AveragePositive: 'AGG(Sample Size Color <50 BLACK)',
      AverageNegative: 'AGG(Sample Size Color <50 RED)',
    };
  }
}

import React, { useRef, useEffect } from 'react';
import { clone } from 'lodash';
import { SideMenu } from '../common/SideMenu/SideMenu';
import styles from './Layout.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../../store/IState';
import { Growl } from 'primereact/growl';
import {
  setWorkbooks,
  setWorkbooksWithGroupedViews,
} from '../../store/tableau/TableauActions';
import { showMessageAction } from '../../store/layout/LayoutActions';
import { setUserProfile, setUserDevice } from '../../store/user/UserActions';
import { ApplicationUserViewModel } from '../../models/User/ApplicationUserViewModel';
import authService from '../api-authorization/AuthorizeService';
import { TableauWorkbookViewModel } from '../../models/Tableau/TableauWorkbookViewModel';
import { WorkbookWithGroupedViews } from '../../models/Tableau/WorkbookWithGroupedViews';
import { UserDevice } from '../../shared/enums/UserDevice';
import { getDeviceType } from '../../shared/utility';
import { showTableauUserErrorAction } from '../../store/layout/LayoutActions';
import { Footer } from '../common/Footer/Footer';

export const Layout = (props: any): JSX.Element => {
  const growlRef: any = useRef();
  const dispatch = useDispatch();

  const appMessage: any = useSelector(
    (state: IState) => state.LayoutReducer.message
  );

  const workbooks: TableauWorkbookViewModel[] = useSelector(
    (state: IState) => state.TableauReducer.workbooks
  );

  const userProfile: ApplicationUserViewModel | undefined = useSelector(
    (state: IState) => state.UserReducer.userProfile
  );

  const getUserProfile = async () => {
    const userName = await authService.getUserName();
    const token = await authService.getAccessToken();
    if (userName) {
      const userProfileResponse: Response = await fetch(
        `Users/GetUserProfile/${userName}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );
      if (userProfileResponse.ok) {
        const userProfileObject: ApplicationUserViewModel = await userProfileResponse.json();
        dispatch(setUserProfile(userProfileObject));
      } else if (userProfileResponse.status === 401) {
        await authService.signIn(window.location.href);
      } else {
        const errorMessage = await userProfileResponse.text();
        dispatch(
          showMessageAction({
            severity: 'error',
            summary: 'Error!',
            detail: errorMessage,
            sticky: true,
          })
        );
      }
    }
  };

  const getWorkbooksForUser = async () => {
    if (userProfile && userProfile.email) {
      const token = await authService.getAccessToken();
      const response: Response = await fetch(
        `TableauServer/GetUserWorkbooks/${userProfile.email}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );

      if (response.ok) {
        const workbooksData: TableauWorkbookViewModel[] = await response.json();
        dispatch(setWorkbooks(workbooksData));
      } else if (response.status === 401) {
        await authService.signOut(window.location.href);
      } else {
        const errorMessage = await response.text();
        dispatch(showTableauUserErrorAction(true));
      }
    }
  };

  const getWorkbooksWithGroupedViews = async () => {
    const token = await authService.getAccessToken();

    const response = await fetch(`TableauServer/GetGroupedWorkbooks`, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    if (response.ok) {
      const result: WorkbookWithGroupedViews[] = await response.json();

      dispatch(setWorkbooksWithGroupedViews(result));
    }
  };

  useEffect(() => {
    const device: UserDevice = getDeviceType();
    dispatch(setUserDevice(device));
  }, []);

  useEffect(() => {
    if (appMessage) {
      const cloneOfMessage = clone(appMessage);
      growlRef.current.show(cloneOfMessage);
    }
  }, [appMessage]);

  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    if (workbooks.length === 0 && userProfile) {
      getWorkbooksForUser();
    }
  }, [userProfile]);

  useEffect(() => {
    if (userProfile && userProfile.userName) {
      getWorkbooksWithGroupedViews();
    }
  }, [userProfile]);

  return (
    <>
      <Growl ref={growlRef} />
      <SideMenu />
      <div className={styles.main_content}>
        <div>{props.children}</div>
      </div>
      <Footer />
    </>
  );
};

import { IDataMapping } from '../../../../IDataMapping';

export class BSPerformanceChartTitleModel implements IDataMapping<BSPerformanceChartTitleModel> {
    public Title:               string = '';
    public SubTitle:            string = '';
    public FilterBrandKPI:      string = '';

    public getMappings?() {
        return {
            Title:              'KPI Titles',
            SubTitle:           'Brand Strength Analysis Sub-Ttitle & Aud',
            FilterBrandKPI:     'Brand KPI'
        };
    }
}

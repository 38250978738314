import { IDataMapping } from '../../IDataMapping';

export class NineGLineChartTotalDataModel implements IDataMapping<NineGLineChartTotalDataModel> {
    public Label: string = '';
    public Name: string = '';
    public Value: number = Number(0);
    public Rank: number = Number(0);
    public Color: string = '';
    public getMappings?() {
        return {
            Label: 'QUARTER(Date_calc (R&R/MS))',
            Name: 'Brand Name',
            Value: 'MAX(Media Spend Euro)',
            Rank: 'SUM(R&R Kpi)',
            Color: 'Brand name color',
        };
    }
}

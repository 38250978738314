import pptxgen from 'pptxgenjs';
import { BasePptxService } from './BasePptxService';

export abstract class TablePptxService extends BasePptxService {
    protected bullets = {
        Up: { code: '2B08', color: '1a660f' },
        Down: { code: '2B0A', color: 'bf0103' }
    };

    protected arrowsByValue: any = {
        '1': this.bullets.Up,
        '-1': this.bullets.Down
    };

    protected getHeaderRow(headers: string[]) {
        return headers.map(k => {
            const border: [pptxgen.BorderProps, pptxgen.BorderProps, pptxgen.BorderProps, pptxgen.BorderProps] = [
                { type: 'solid', pt: 0.5, color: this.colors.border },
                { type: 'solid', pt: 0.5, color: this.colors.white },
                { type: 'solid', pt: 0.5, color: this.colors.border },
                { type: 'solid', pt: 0.5, color: this.colors.white },
            ];

            return {
                text: k,
                options: {
                    bold: true,
                    fill: { color: this.colors.default },
                    color: this.colors.white,
                    border
                }
            };
        });
    }
    protected addTable(chartSlide: pptxgen.Slide, rows: pptxgen.TableRow[], tableProps?: pptxgen.TableProps) {
        tableProps = Object.assign({
            x: '20%',
            y: 0.8,
            w: '60%',
            align: 'center',
            valign: 'middle',
            border: { type: 'solid', pt: 0.5, color: this.colors.border },
            fontSize: 7,
            rowH: 0.05,
            autoPage: true,
            autoPageRepeatHeader: true,
            newSlideStartY: 0.8,
            autoPageCharWeight: 1,
        }, tableProps);

        chartSlide.addTable(rows, tableProps);
    }
}

import pptxgen from 'pptxgenjs';
import { ContributorsDataModel } from '../../../models/PersonalHealth/Contributors/ContributorsDataModel';
import { ContributorsCategoryDataModel } from '../../../models/PersonalHealth/Contributors/ContributorsCategoryDataModel';
import { ContributorsTitleModel } from '../../../models/PersonalHealth/Contributors/ContributorsTitleModel';

import { PHFirstSlideRenderer } from '../../Renderers/PHFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

export class ContributorService extends BasePptxService {
    protected chartQuestion: string = '';
    protected firstSlideRenderer: PHFirstSlideRenderer = new PHFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
    }

    protected async setChartSlideLayout(slide: pptxgen.Slide) {
      // Override title's options and add filter's name in it.
      const titleData = await this.getMappedChartData(ContributorsTitleModel, this.titleSheet);
      let subTitle = "";
      titleData.map( obj => {
        this.chartTitle = obj.Level ? `${obj.Title} - ${obj.Level}` : obj.Title;
        return subTitle = obj.SubTitle;
      });
      slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.25, bold: true });
      slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.5, italic: true });
    }
  
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
      let data = [];
      if (this.dataSheet === "Category Contributors to Preference Change") {
        data = await this.getMappedChartData(ContributorsCategoryDataModel, this.dataSheet);
      } else {
        data = await this.getMappedChartData(ContributorsDataModel, this.dataSheet);
      }
      const filteredByEmptyValues = data.filter(el => el.ValueNegative !== null || el.ValuePositive !== null);
      filteredByEmptyValues.sort((a, b) => b.Rank - a.Rank);

      const negativeColors: string[] = [];
      const negativeLabels: string[] = [];
      const negativeValues: number[] = [];

      const positiveColors: string[] = [];
      const positiveLabels: string[] = [];
      const positiveValues: number[] = [];

      let negativeData = [
        {
          name: "",
          labels: negativeLabels,
          values: negativeValues,
        }
      ];

      let positiveData = [
        {
          name: "",
          labels: positiveLabels,
          values: positiveValues,
        }
      ];

      filteredByEmptyValues.forEach((obj) => {
        if (obj.Legend === "Negative") {
          negativeData[0].name = obj.Legend;
          negativeLabels.push(obj.LabelsNegative);
          negativeValues.push(obj.ValueNegative);
          negativeColors.push(obj.Color);
        } else if (obj.Legend === "Positive") {
          positiveData[0].name = obj.Legend;
          positiveLabels.push(obj.LabelsPositive);
          positiveValues.push(obj.ValuePositive);
          positiveColors.push(obj.Color);
        }
      });

      let absMinPositive = Math.min(...positiveData[0].values);
      let absMaxNegative = Math.abs(Math.max(...negativeData[0].values));

      let minValue = Math.min(absMinPositive, absMaxNegative);

      let absMaxPositive = Math.max(...positiveData[0].values);
      let absMinNegative = Math.abs(Math.min(...negativeData[0].values));

      let maxValue = Math.max(absMaxPositive, absMinNegative) + 0.002;

      // Add dummy data if one of the charts has less labels and values so we could even the bars.
      if (negativeLabels.length < positiveLabels.length) {
        do {
          negativeLabels.unshift("");
          negativeValues.unshift(-Number.MIN_VALUE);
        }
        while (negativeLabels.length < positiveLabels.length);
      } else if(positiveLabels.length < negativeLabels.length) {
        do {
          positiveLabels.unshift("");
          positiveValues.unshift(Number.MIN_VALUE);
        }
        while (positiveLabels.length < negativeLabels.length);
      }

      this.createNegativeChart(chartSlide, negativeData, negativeColors, minValue, maxValue);
      this.createPositiveChart(chartSlide, positiveData, positiveColors, minValue, maxValue);
    }

    private createNegativeChart = (chartSlide: pptxgen.Slide, data: any, colors: string[], minValue: number, maxValue: number) => {
      if(!data[0].name) {
        return false;
      }
      chartSlide.addChart(
        this.presentation.ChartType.bar,
        data,
        {
          x: 0.8,
          y: 1,
          w: "42%",
          h: "70%",
          barDir: "bar",
          barGrouping: "stacked",
          showLegend: true,
          valAxisHidden: true,
          legendPos: 'b',
          catAxisLabelPos: "low",
          catAxisLabelFontSize: 8,
          chartColors:  colors.filter(this.distinctColors),
          catGridLine: { style: "none" },
          valGridLine: { style: "none" },
          valAxisMinVal: -maxValue,
          valAxisMaxVal: 0,
        }
      );
    }

    private createPositiveChart = (chartSlide: pptxgen.Slide, data: any, colors: string[], minValue: number, maxValue: number) => {
      if(!data[0].name) {
        return false;
      }
      chartSlide.addChart(
        this.presentation.ChartType.bar,
        data,
        {
          x: 4.7,
          y: 1,
          w: "38%",
          h: "70%",
          barDir: "bar",
          showLegend: true,
          legendPos: 'b',
          valAxisHidden: true,
          catAxisLabelPos: "high",
          catAxisLabelFontSize: 8,
          barGrouping: "stacked",
          chartColors:  colors.filter(this.distinctColors),
          catGridLine: { style: "none" },
          valGridLine: { style: "none" },
          valAxisMinVal: 0,
          valAxisMaxVal: maxValue,
        }
      );
    }

    private distinctColors = (value: any, index: any, self: any) => {
      return self.indexOf(value) === index;
    };
}

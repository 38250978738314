import { IDataMapping } from '../../IDataMapping';

export class DemographicsPieChartDataModel implements IDataMapping<DemographicsPieChartDataModel> {
    public Name: string = '';
    public Label: string = '';
    public Color: string = '';
    public Value: number = Number(0);
    public getMappings?() {
        return {
            Name: 'Brand Most used brands',
            Label: 'Label',
            Color: 'AGG(DemographicsColors)',
            Value: 'AGG(Brands most used value)',
        };
    }
}

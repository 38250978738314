import React from 'react';
import styles from './ListViewControls.module.scss';
import { IListViewControlsProps } from './IListViewControlsProps';
import { Button } from 'primereact/button';
import { SearchControl } from '../SearchControl/SearchControl';

export const ListViewControls = (props: IListViewControlsProps): JSX.Element => {
    const { searchValue, addLabel, addButtonVisible, onSearch, onAdd } = props;

    const isButtonVisible = addButtonVisible === undefined ? true : addButtonVisible;

    return (
        <div className={styles.page_controls}>
            <SearchControl value={searchValue} onChange={onSearch} />
            {
                isButtonVisible && (
                    <Button
                        label={addLabel}
                        className={styles.add_button}
                        onClick={onAdd}
                    />
                )
            }
        </div>
    );
};

import pptxgen from 'pptxgenjs';
import { BaseChartOptions as BaseChartOptions } from '../../../../../philips-constants';

import { UnaidedChannelRecallChartTitleModel } from '../../../../../models/HealthSystems/StrategicPartnership/Marketing/UnaidedChannelRecallChart/UnaidedChannelRecallChartTitleModel';
import { UnaidedChannelRecallChartDataModel } from '../../../../../models/HealthSystems/StrategicPartnership/Marketing/UnaidedChannelRecallChart/UnaidedChannelRecallChartDataModel';

import { BasePptxService } from '../../../../BasePptxService';
import { HSStrategicFirstSlideRenderer } from '../../../../Renderers/HSStrategicFirstSlideRenderer';
import { PptxRenderHelper } from '../../../../../helpers/PptxRenderHelper';

export class UnaidedChannelRecallChartService extends BasePptxService {
    protected chartQuestion: string =
        "Q: Through which of the following channels have you seen, heard or read communications for [Brand] recently?";
    protected firstSlideRenderer: HSStrategicFirstSlideRenderer = new HSStrategicFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
    }

    // Override of a super class method
    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const mappedTitleData = await this.getMappedChartData(UnaidedChannelRecallChartTitleModel, this.titleSheet);

        mappedTitleData.forEach(obj => {
            this.chartTitle = `${obj.Title} ${obj.Country} - ${obj.Segment} - ${obj.Period}`;
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.55, bold: true });
        slide.addText([{ text: this.chartQuestion, options: { color: this.colors.black, fontSize: 7 } }], { x: 0.5, y: '95%', bold: false });
    }

    // Override of a super class method
    protected async addChartSlide(chartSlide: pptxgen.Slide): Promise<void> {
        const mappedChartData = await this.getMappedChartData(UnaidedChannelRecallChartDataModel, this.dataSheet);

        if (mappedChartData.length > 0) {
            const groupData: any[][] = [];

            // Sorting the mapped data in groups
            for (let obj of mappedChartData) {
                let makeNewGroupFlag = true;
                // Checks If there is group/array for this object
                for (let arr of groupData) {
                    for (let element of arr) {
                        if (element.Brand === obj.Brand) {
                            arr.push(obj);
                            makeNewGroupFlag = false;
                        }
                        break;
                    }
                }
                // Creates new group/array
                if (makeNewGroupFlag) {
                    groupData.push([obj]);
                }
            }

            // Creates new chart for every group
            groupData.forEach((group: any[], index: number) => {
                let width = 8 / groupData.length;
                let showCatAxisLabel = false;
                const x = index * width + (index >= 1 ? 1.5 : 0.5);

                if (index === 0) {
                    width += 1;
                    showCatAxisLabel = true;
                }

                const colors = PptxRenderHelper.getMappedChartDataColorsSP(group);

                const chartData = group.reduce((acc, curr) => ({
                    labels: [...acc.labels, curr.Answer],
                    values: [...acc.values, curr.Score],
                }), { labels: [] as string[], values: [] as number[] });

                const chartOptions: pptxgen.IChartOpts = {
                    ...BaseChartOptions,
                    x,
                    y: 1.2,
                    w: width,
                    h: 3.7,
                    barDir: 'bar',
                    showValue: true,
                    chartColors: colors,
                    barGapWidthPct: 15,
                    valAxisLineShow: false,
                    valAxisHidden: true,
                    catAxisLabelPos: showCatAxisLabel ? 'low' : undefined,
                    catAxisHidden: !showCatAxisLabel,
                    dataLabelFormatCode: '0%',
                    dataLabelPosition: 'outEnd',
                    dataLabelFontBold: true,
                    dataLabelColor: this.colors.default,
                }
                chartSlide.addChart(this.presentation.ChartType.bar, [chartData], chartOptions);

                // Adding Brand names and sample data
                let brandName = "";
                let color = "";
                let sampleData = 0;
                group.forEach((obj) => {
                    if (obj.Brand) {
                        brandName = obj.Brand;
                    }
                    if (obj.Color) {
                        color = obj.Color;
                    }
                    if (obj.Sample) {
                        sampleData = obj.Sample;
                    }
                });
                chartSlide.addText(brandName, {
                    x: x + (index === 0 ? width / 2.5 : 0) + (width * 0.05),
                    y: 1.15,
                    color: color,
                    bold: true,
                    fontSize: 12,
                });
                chartSlide.addText(`N = ${sampleData}`, {
                    x: x + (index === 0 ? width / 2.5 : 0) + (width * 0.05),
                    y: 5,
                    color: sampleData < 50 ? this.colors.negative : this.colors.default,
                    fontSize: 8
                });
            });
        }
    }
}

import { IDataMapping } from '../../IDataMapping';

export class CompetitorsGloballyNPSTitleModel implements IDataMapping<CompetitorsGloballyNPSTitleModel> {
    public Title: string = '';
    public SurveyType: string = '';

    public getMappings?() {
        return {
            Title: 'Title Table NPS',
            SurveyType: 'Survey',
        };
    }
}

import pptxgen from 'pptxgenjs';

import { BasePptxService } from '../../../BasePptxService';
import { PHBSFirstSlideRenderer } from '../../../Renderers/PHBSFirstSlideRenderer';

import { PHBSTotalChartTitleModel } from '../../../../models/PersonalHealth/BrandStrength/PHBSTotalChart/PHBSTotalChartTitleModel';
import { PHBSTotalChartDataModel } from '../../../../models/PersonalHealth/BrandStrength/PHBSTotalChart/PHBSTotalChartDataModel';

import { ChartsBase64 } from '../../../../../shared/enums/ChartsBase64';

export class PHBSTotalChartService extends BasePptxService {
    protected chartQuestion: string = '';
    protected firstSlideRenderer: PHBSFirstSlideRenderer = new PHBSFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
    }

    // Override of a super class method
    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const titleData = await this.getMappedChartData(PHBSTotalChartTitleModel, this.titleSheet);

        let subTitle: string = "";
        titleData.forEach((obj: any) => {
            this.chartTitle = `${obj.Title}`;
            subTitle = `${obj.SubTitle} - ${obj.Audience} - ${obj.Period} - ${obj.Filter}`;
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.55, bold: true });
        slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 12, lineSpacing: 12 } }], { x: 0.6, y: 0.9 });
    }

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const mappedData = await this.getMappedChartData(PHBSTotalChartDataModel, this.dataSheet);
        let circle: string = "";
        let circleValue: any = "";
        let KPIColor: string = "";
        let KPIValue: string = "";

        mappedData.forEach((obj: any) => {
            if (obj.KPIValue) {
                KPIValue = parseFloat(obj.KPIValue).toFixed(1);
            }

            if (obj.Color) {
                KPIColor = obj.Color;
            }

            if (obj.ValueNegative) {
                circle = ChartsBase64.NegativeCircle;
                circleValue = (parseFloat(obj.ValueNegative) / 100).toFixed();
            } else if (obj.ValuePositive) {
                circle = ChartsBase64.PositiveCircle;
                circleValue = (parseFloat(obj.ValuePositive) / 100).toFixed();
            } else if (obj.ValueZero) {
                circle = ChartsBase64.ZeroCircle;
                circleValue = (parseFloat(obj.ValueZero) / 100).toFixed();
            } else return false;
        })

        chartSlide.addImage({ x: 3.4, y: 1.9, w: 1.5, h: 1.5, data: circle });
        chartSlide.addText([{ text: circleValue, options: { color: KPIColor, fontSize: 16 } }], { x: 3.9, y: 3.6 });
        chartSlide.addText([{ text: KPIValue, options: { fontSize: 28 } }], { x: 5, y: 2.7 });
    }
}

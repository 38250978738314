import { IDataMapping } from '../../../../IDataMapping';

export class MainProductNPSChartTitleModel implements IDataMapping<MainProductNPSChartTitleModel> {
    public title: string = '';
    public period: string = '';

    public getMappings?() {
        return {
            title: 'Title_Main Product NPS Attributes per BG: Global',
            period: 'TitlePeriod_Main Product NPS Attributes per BG: Global'
        };
    }
}

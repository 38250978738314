import pptxgen from 'pptxgenjs';

import { NPSScoreOverTimeTitleModel } from '../../../models/PersonalHealth/NPSScoreOverTime/NPSScoreOverTimeTitleModel';
import { NPSScoreOverTimeBarDataModel } from '../../../models/PersonalHealth/NPSScoreOverTime/NPSScoreOverTimeBarDataModel';
import { NPSScoreOverTimeAdditionalBarDataModel } from '../../../models/PersonalHealth/NPSScoreOverTime/NPSScoreOverTimeAdditionalBarDataModel';
import { NPSScoreOverTimeLineDataModel } from '../../../models/PersonalHealth/NPSScoreOverTime/NPSScoreOverTimeLineDataModel';

import { PHFirstSlideRenderer } from '../../Renderers/PHFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

import { filterInitDuplicateData, addEmptyValuesToInitData } from "../../../../shared/utility";

export class NPSScoreOverTimeService extends BasePptxService {
  protected chartQuestion: string = '';
  protected firstSlideRenderer: PHFirstSlideRenderer = new PHFirstSlideRenderer();
  protected titleSheet: string;
  protected barChartDataSheet: string;
  protected additionalBarChartDataSheet: string;
  protected lineChartDataSheet: string;

  constructor(view: any, chartTitle: string, titleSheet: string, barChartDataSheet: string, additionalBarChartDataSheet: string, lineChartDataSheet: string) {
      super(view, chartTitle);
      this.titleSheet = titleSheet;
      this.barChartDataSheet = barChartDataSheet;
      this.additionalBarChartDataSheet = additionalBarChartDataSheet;
      this.lineChartDataSheet = lineChartDataSheet;
  }

  protected async setChartSlideLayout(slide: pptxgen.Slide) {
    // Override title's options and add filter's name in it.
    const titleData = await this.getMappedChartData(NPSScoreOverTimeTitleModel, this.titleSheet);
    let subTitle: string = "";
    
    titleData.map( obj => {
      this.chartTitle = `${obj.Title} - ${obj.ChartType}`
      return subTitle = `${obj.Level} ${obj.SubTitle}`;
    });
    slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.35, bold: true });
    slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.58, italic: true });
  };

  protected async addChartSlide(chartSlide: pptxgen.Slide) {
    const barChartData = await this.getMappedChartData(NPSScoreOverTimeBarDataModel, this.barChartDataSheet);
    const additionalBarChartData = await this.getMappedChartData(NPSScoreOverTimeAdditionalBarDataModel, this.additionalBarChartDataSheet);
    const lineChartData = await this.getMappedChartData(NPSScoreOverTimeLineDataModel, this.lineChartDataSheet);

    if (barChartData.length > 0 && additionalBarChartData.length > 0) {
      await this.addBarChart(chartSlide, barChartData, additionalBarChartData);
    } else if (lineChartData.length > 0) {
      await this.addLineChart(chartSlide, lineChartData);
    };
  }

  private async addLegend(npsOvertimeSlide: pptxgen.Slide, data: any) {
    const addData = await data;
    const legend: any = [];
    const newArr: any = [];

    addData.forEach((obj: any) => {
      newArr.push(
        {
          text: obj.IndicatorName,
          options: { fill: { color: obj.Color } }
        }
      )
    })

    const names = newArr.map((o: any) => o.text);
    const filteredNames = newArr.filter(({text}: any, index: any) => !names.includes(text, index + 1));
    legend.push(filteredNames.slice().reverse())

    const legendProps: pptxgen.TableProps = {
        x: '25%',
        y: '5',
        w: '50%',
        align: 'center',
        bold: true,
        fontSize: 10,
        rowH: 0.1,
        color: this.colors.white,
        border: { type: 'solid', color: this.colors.neutral, pt: 0.2 }
    };

    npsOvertimeSlide.addTable(legend, legendProps);
  }

  private generateTitleRows = (tableData: any) => {
    const sortedData = tableData.sort(function(a: any, b: any) { 
      return a.Rank - b.Rank || a.Date - b.Date;
    });
    const dates = sortedData.map((o: any) => o.Date);
    const result = [] as  any;
    dates.forEach((obj: any) => {
      result.push({ text: obj, options: { color: '#000000', fill: { color: '#FFFFFF'}}},)
    })
    
    const rowTitles: pptxgen.TableRow[] = []
    rowTitles.push(result)
    rowTitles[0].unshift({
      text: '',
    });

    return rowTitles;
  }

  private generateBodyRows = (tableData: any) => {
    const sortedData = tableData.sort(function(a: any, b: any) { 
      return a.Rank - b.Rank || a.Date - b.Date;
    });
    const values = sortedData.map((o: any) => o.Value);
    const result = [] as  any;
    values.forEach((obj: any) => {
      result.push({ text: `${(obj * 100).toFixed(1)}%`, options: { color: '#000000', fill: { color: '#FFFFFF'}}},)
    })
    
    const rowTitles: pptxgen.TableRow[] = []
    rowTitles.push(result)
    rowTitles[0].unshift({
      text: 'NPS Score',
      options: { color: '#000000', fill: { color: '#FFFFFF'}}
    });

    return rowTitles;
  }

  private generateFooterRows = (tableData: any) => {
    const sortedData = tableData.sort(function(a: any, b: any) { 
      return a.Rank - b.Rank || a.Date - b.Date;
    });
    const values = sortedData.map((o: any) => o.RelativeNps);
    const result = [] as  any;
    values.forEach((obj: any) => {
      result.push({ text: obj ? `${(obj * 100).toFixed(1)}%` : '', options: { color: '#000000', fill: { color: '#FFFFFF'}}},)
    })
    
    const rowTitles: pptxgen.TableRow[] = []
    rowTitles.push(result)
    rowTitles[0].unshift({
      text: 'Relative NPS',
      options: { color: '#000000', fill: { color: '#FFFFFF'}}
    });

    return rowTitles;
  }

  protected async addBarChart(slide: pptxgen.Slide, data: any, additionalData: any) {
    const addData = await additionalData;
    const newArr: any = [];
    const lineColors: string[] = [];
    const sortedBarChartData = data.sort(function(a: any, b: any) { 
      return a.Rank - b.Rank || a.Year - b.Year;
    });

    sortedBarChartData.forEach((obj: any) => {
      if (obj.IndicatorName) {
        const item = newArr.find((item: any) => item.name === obj.IndicatorName);
        lineColors.push(obj.Color)
  
        if (newArr && newArr.length > 0 && item) {
          item.values.push(parseFloat(obj.Value));
          item.labels.push(obj.Label);
        } else {
          newArr.push(
            {
              name: obj.IndicatorName,
              labels: [obj.Label],
              values: [parseFloat(obj.Value)],
            }
          )
        }
      }
    });

    const colors = lineColors.filter(this.distinctRepeatables)
    slide.addChart(
      this.presentation.ChartType.bar,
      newArr,
      {
        x: 1.2,
        y: 1,
        w: "80%",
        h: "50%",
        barDir: "col",
		    barGrouping: "percentStacked",
        showLegend: false,
        showValue: true,
        legendPos: 'b',
        dataLabelFormatCode: '0%',
        valAxisLabelFormatCode: '0%',
        valAxisLabelFontSize: 8,
        valAxisHidden: true,
        catAxisLabelPos: 'high',
        catAxisLabelFontSize: 8,
        dataLabelFontSize: 10,
        catGridLine: { style: "none" },
		    valGridLine: { style: "none" },
        chartColors: colors,
      }
    );

    const tableProps: pptxgen.TableProps = {
      x: 0.4,
      y: 3.8,
      w: '86.5%',
      h: '60%',
      align: 'center',
      fontSize: 10,
      valign: 'middle',
      rowH: 0.25,
      color: this.colors.white,
      autoPage: true,
      newSlideStartY: 1,
      border: { type: 'solid', color: this.colors.neutral, pt: 0.2 },
      autoPageLineWeight: 0.5
    };

    const createRowTitles = this.generateTitleRows(addData);
    const createRowBody = this.generateBodyRows(addData);
    const createFooterRows = this.generateFooterRows(addData);

    slide.addTable([...createRowTitles, ...createRowBody, ...createFooterRows], tableProps);
    await this.addLegend(slide, sortedBarChartData);
  }

  protected async addLineChart(slide: pptxgen.Slide, data: any) {
    if (this.lineChartDataSheet) {
      const titleData = await this.getMappedChartData(NPSScoreOverTimeTitleModel, this.titleSheet);
      let subTitle: string = "";

      titleData.map(obj => {
        this.chartTitle = obj.Title;
        return subTitle = `${obj.Level} ${obj.SubTitle}`;
      });

      slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.35, bold: true });
      slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.58, italic: true });

      const lineChartData = await data;

      const sortedLineChartData = lineChartData.sort(function (a: any, b: any) {
        return a.Rank - b.Rank;
      });
      let filteredData = filterInitDuplicateData(sortedLineChartData);

      const newArr: any = [];
      const lineColors: string[] = [];
      const getAllLabels: any = [];

      const sortedLineChartDataByTime = lineChartData.sort(function (a: any, b: any) {
        return a.Label.substring(2, 4) - b.Label.substring(2, 4) || a.Label.substring(6) - b.Label.substring(6);
      });
      
      sortedLineChartDataByTime.forEach((obj: any) => {
        getAllLabels.push(obj.Label);
      });

      const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

      filteredData.forEach((obj: any) => {
        lineColors.push(obj.Color)
        const item = newArr.find((item: any) => item.name === obj.Name);

        if (newArr.length > 0 && item) {
          if (item) {
            item.values.push(parseFloat(obj.Value));
            item.labels.push(obj.Label);
          }
        } else {
          newArr.push(
            {
              name: obj.Name,
              labels: [obj.Label],
              values: [parseFloat(obj.Value)],
            }
          )
        }
      });

      const finalLineChartData = addEmptyValuesToInitData(distinctedLabels, newArr);

      slide.addChart(
        this.presentation.ChartType.line,
        finalLineChartData,
        {
          x: 1,
          y: 1,
          w: "70%",
          h: "70%",
          showLegend: true,
          legendPos: 'b',
          dataLabelPosition: 't',
          dataLabelFormatCode: '0%',
          valAxisLabelFormatCode: '0%',
          catAxisLabelRotate: this.labelRotateDegree(newArr),
          chartColors: lineColors.filter(this.distinctRepeatables),
          valAxisLabelFontSize: 10,
          catAxisLabelFontSize: 10,
        }
      );
    }
  }

  protected labelRotateDegree(array: any) {
    let degree: number = 0;
    array.forEach((obj: any) => {
      if (obj.labels.length > 10) {
        degree = 45;
      } else {
        degree = 0;
      }
    });
    return degree;
  }

  private distinctRepeatables = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };
}

import React from 'react';
import { ISVGProps } from './ISVGProps';

export const CSVSvg = (props: ISVGProps) => {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 11C7.71667 11 7.479 10.904 7.28699 10.712C7.09567 10.5207 7 10.2833 7 10V7C7 6.71667 7.09567 6.479 7.28699 6.287C7.479 6.09567 7.71667 6 8 6C9 6 9 7 9 7H8V10H9C9 10 9 11 8 11Z" fill="var(--ribbon-button-color, #0055A6)" />
      <path d="M16 6H17L16 11H15L14 6H15L15.5 8L16 6Z" fill="var(--ribbon-button-color), #0055A6" />
      <path d="M12 6H11C10 6 10 7 10 7V8.5L12 9V10H10C10 10 10 11 11 11H12C13 11 13 10 13 10V8.5L11 8V7H13C13 7 13 6 12 6Z" fill="var(--ribbon-button-color, #0055A6)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6 16C5.45001 16 4.97934 15.8043 4.58801 15.413C4.19601 15.021 4 14.55 4 14V2C4 1.45 4.19601 0.979 4.58801 0.587C4.97934 0.195666 5.45001 0 6 0H18C18.55 0 19.021 0.195666 19.413 0.587C19.8043 0.979 20 1.45 20 2V14C20 14.55 19.8043 15.021 19.413 15.413C19.021 15.8043 18.55 16 18 16H6ZM6 2V14H18V2H6Z" fill="var(--ribbon-button-color, #0055A6)" />
      <path d="M0.588013 19.413C0.97934 19.8043 1.45001 20 2 20H16V18H2V4H0V18C0 18.55 0.196014 19.021 0.588013 19.413Z" fill="var(--ribbon-button-color, #0055A6)" />
    </svg>
  );
};

import { IDataMapping } from '../../IDataMapping';

export class PreferenceOverTimeComparisonDataModel implements IDataMapping<PreferenceOverTimeComparisonDataModel> {
    public Label: string = '';
    public NameGlobal: string = '';
    public NameCountry: string = '';
    public NameCategory: string = '';
    public ValueTooltip: number = Number(0);
    public Value: number = Number(0);
    public ColorGlobal: string = '';
    public ColorCountry: string = '';
    public ColorCategory: string = '';
    public RankGlobal: number = Number(0);
    public RankCountry: number = Number(0);
    public RankCategory: number = Number(0);
    public TimeRank: number = Number(0);
    public getMappings?() {
        return {
            Label: 'QUARTER(Date)',
            NameGlobal: 'Labels Global',
            NameCountry: 'Labels Comparison Country',
            NameCategory: 'Labels Comparison Category',
            ValueTooltip: "AVG(Value Tooltip)",
            Value: 'AVG(Value)',
            ColorGlobal: 'Comparison Colours Global',
            ColorCountry: 'Comparison Colours Country',
            ColorCategory: 'Comparison Colours Category',
            RankGlobal: 'ATTR(Rank Global)',
            RankCountry: 'AVG(Rank Country)',
            RankCategory: 'AVG(Rank Category)',
            TimeRank: 'MIN(index_period)',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class DemographicsPieChartTitleModel implements IDataMapping<DemographicsPieChartTitleModel> {
    public Title: string = '';
    public SubTitle: string = '';
    public Category: string = '';
    public Survey: string = '';
    public SampleDate: string = '';
    public SampleValue: number = Number(0);
    public getMappings?() {
        return {
            Title: 'DemogrTitle',
            SubTitle: 'Comments',
            Category: 'Category',
            Survey: 'Survey',
            SampleDate: 'Date',
            SampleValue: 'AVG(Count)',
        };
    }
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationUserViewModel } from '../../models/User/ApplicationUserViewModel';
import { IState } from '../../store/IState';
import { ApplicationUserDomainViewModel } from '../../models/User/ApplicationUserDomainViewModel';
import styles from './DomainsPage.module.scss';
import api from '../../helpers/Api';
import layoutMessage from '../../helpers/LayoutMessage';
import { CustomIcon } from '../common/Svgs/CustomIcon';
import { IconType } from '../../shared/enums/IconType';
import { ProgressSpinner } from 'primereact/progressspinner';
import { setSelectedWorkbook } from '../../store/tableau/TableauActions';

export const DomainsPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const userProfile: ApplicationUserViewModel | undefined = useSelector(
    (state: IState) => state.UserReducer.userProfile
  );

  const [userDomains, setUserDomains] = useState<
    ApplicationUserDomainViewModel[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getUserDomains = async () => {
    if (userProfile) {
      await api.get<ApplicationUserDomainViewModel[]>(
        `Users/GetUserDomains/${userProfile.email}`,
        (result: ApplicationUserDomainViewModel[]) => {
          setUserDomains(result);
          setIsLoading(false);
        },
        (errorMessage, status) => {
          layoutMessage.renderError(dispatch, errorMessage);
          setIsLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    getUserDomains();
  }, [userProfile]);

  useEffect(() => {
    dispatch(setSelectedWorkbook(undefined));
  }, []);

  if (isLoading) {
    return <ProgressSpinner className={styles.centered_on_screen} />;
  }

  return (
    <React.Fragment>
      <div className={styles.cards_container}>
        {userDomains.map((ud, index) => (
          <div className={styles.card} key={index} onClick={e => window.location.assign(ud.url)}>
            <div className={styles.card_header}>
              <p className={styles.site_name}>{ud.name}</p>
            </div>
            <div className={styles.card_footer}>
              <span>
                See all{' '}
                <CustomIcon
                  iconType={IconType.ArrowRight}
                  width={'16'}
                  height={'16.28'}
                />
              </span>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

import { IDataMapping } from '../../IDataMapping';

export class FirstChoiceTableDataModel implements IDataMapping<FirstChoiceTableDataModel> {
    public Category: string = '';
    public Market: string = '';
    public Table: number = Number(0);
    public Value: number = Number(0);
    public Count: string = '';
    public LowNumIndicator: string = '';
    public getMappings?() {
        return {
            Category: "Category",
            Market: "Market",
            Table: "Table",
            Value: "SUM(Value)",
            Count: "SUM(Count)",
            LowNumIndicator: "AGG(base*)",
        };
    }
}

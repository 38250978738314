
export enum IconType {
    None,
    Checkbox,
    NonCheckedCheckbox,
    CSV,
    Delete,
    Download,
    Ellipsis,
    Eye,
    Filter,
    List,
    PDF,
    PPT,
    Revert,
    Tiles,
    Excel,
    Word,
    File,
    Image,
    Video,
    Folder,
    ArrowRight,
}

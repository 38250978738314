import { IDataMapping } from '../../IDataMapping';

export class BMCSimulatorCombinedChartDataModel implements IDataMapping<BMCSimulatorCombinedChartDataModel> {
    public Label: string = '';
    public ChartTitle: string = '';
    public LineChartName: string = '';
    public BarChartName: string = '';
    public Rank: number = Number(0);
    public LineChartValue: number = Number(0);
    public BarChartValue: number = Number(0);
    public Color: string = '';
    public getMappings?() {
        return {
            Label: 'QUARTER(Date_calc (AA/Cons)_cont)',
            ChartTitle: 'Brand Name',
            LineChartName: 'Measure Names',
            BarChartName: 'Summary_MediaSpend_caption',
            Rank: 'SUM(Rank (9G 2nd level))',
            LineChartValue: 'Measure Values',
            BarChartValue: 'SUM(Summary_MediaSpend_EUR)',
            Color: 'ATTR(Brand name color)',
        };
    }
}

import pptxgen from 'pptxgenjs';

import { AppendixJapanTitleModel } from '../../../../models/SRC/Appendix/AppendixJapan/AppendixJapanTitleModel';
import { AppendixJapanPieDataModel }from '../../../../models/SRC/Appendix/AppendixJapan/AppendixJapanPieDataModel';
import { AppendixJapanTableDataModel } from '../../../../models/SRC/Appendix/AppendixJapan/AppendixJapanTableDataModel';

import { SRCFirstSlideRenderer } from '../../../Renderers/SRCFirstSlideRenderer';
import { BasePptxService } from '../../../BasePptxService';

export class AppendixJapanService extends BasePptxService {
  protected chartQuestion: string = '';
  protected firstSlideRenderer: SRCFirstSlideRenderer = new SRCFirstSlideRenderer();
  protected titleSheet: string;
  protected commentsSheet: string;
  protected pieDataSheet: string;
  protected tableDataSheet: string;
  protected sampleSizeNote: string;

  constructor(view: any, chartTitle: string, titleSheet: string, commentsSheet: string, pieDataSheet: string, tableDataSheet: string, sampleSizeNote: string) {
    super(view, chartTitle);
    this.titleSheet = titleSheet;
    this.commentsSheet = commentsSheet;
    this.pieDataSheet = pieDataSheet;
    this.tableDataSheet = tableDataSheet;
    this.sampleSizeNote = sampleSizeNote;
  }

  protected async setChartSlideLayout(slide: pptxgen.Slide) {
    // Override title's options and add filter's name in it.
    const titleData = await this.getMappedChartData(AppendixJapanTitleModel, this.titleSheet);
    const comments = await this.getMappedChartData(AppendixJapanTitleModel, this.commentsSheet);
    const sampleSizeNoteData = await this.getMappedChartData(AppendixJapanTitleModel, this.sampleSizeNote);
    let smapleNote: string = "";
    let commentsTitle: string = "";
    
    titleData.map( obj => {
      return this.chartTitle = obj.Title;
    });

    comments.map( obj => {
        return commentsTitle = obj.Comments;
    });

    sampleSizeNoteData.map( obj => {
      return smapleNote = obj.SampleSizeNote;
    });

    slide.addText([{ text: this.chartTitle, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.35, bold: true });
    slide.addText([{ text: commentsTitle, options: { color: this.colors.black, fontSize: 12 } }], { x: 0.5, y: 1.4, italic: true });
    
    if (smapleNote) {
      slide.addText([{ text: smapleNote, options: { color: '#000000', fontSize: 10 } }], { x: 5, y: 5, bold: false});
    };
  }

  protected async addChartSlide(chartSlide: pptxgen.Slide) {
    await this.addPieChart(chartSlide);
  }

  private async addPieChart(chartSlide: pptxgen.Slide) {
    const pieData = await this.getMappedChartData(AppendixJapanPieDataModel, this.pieDataSheet);
    const tableData = await this.getMappedChartData(AppendixJapanTableDataModel, this.tableDataSheet);

    if (pieData.length > 0) {
      const chartTitle = pieData[0].Label;
      const lineColors: string[] = [];

      pieData.forEach((obj: any) => {
          lineColors.push(obj.Color);
      })

      const dataChartPieStat = [
          {
              name: pieData[0].Label,
              labels: pieData.map((x: any) => x.Name),
              values: pieData.map((x: any) => (Math.round(x.Value ? (x.Value * 100) : 0)))
          }
      ];

      dataChartPieStat.forEach((obj: any) => {
        obj.labels = obj.labels.filter(this.distinctRepeatables);
        obj.values = obj.values.filter(this.distinctRepeatables);
      });

      chartSlide.addChart(
        this.presentation.ChartType.doughnut,
        dataChartPieStat,
        {
          showTitle: true,
          x: 0.5,
          y: 2,
          w: '30%',
          h: '50%',
          showLegend: true,
          showLeaderLines: true,
          showValue: true,
          title: chartTitle,
          chartColors: lineColors.filter(this.distinctRepeatables),
          dataLabelColor: "FFFFFF",
          dataLabelFontSize: 10,
          titleFontSize: 12,
          legendFontSize: 10,
          dataLabelPosition: "bestFit",
          showPercent: false,
          dataLabelFormatCode: '#\\%',
          legendPos: 'b',
        }
      );
    };

    const tableProps: pptxgen.TableProps = {
      x: pieData.length > 0 ? 3.5 : 0.5,
      y: 2.5,
      w: '60%',
      h: '70%',
      align: 'center',
      fontSize: 8,
      valign: 'middle',
      rowH: 0.25,
      color: this.colors.white,
      autoPage: true,
      newSlideStartY: 1,
      border: { type: 'solid', color: this.colors.neutral, pt: 0.2 },
      autoPageLineWeight: 0.5
    };

    const createTitleRows = this.generateTitleRows(tableData);
    const createBodyRows = this.generateBodyRows(tableData);
    chartSlide.addTable([...createTitleRows, ...createBodyRows], tableProps);
  };

  private generateTitleRows = (data: any) => {
    const rowTitles: pptxgen.TableRow[] = []
    const str: string[] = []
    const arr: any[] = []

    data.forEach((obj: any) => {
      str.push(obj.Category + '\r\n' + obj.CategoryVal);
    });
    
    const disctinctedTitles = str.filter(this.distinctRepeatables);

    disctinctedTitles.forEach((obj: any) => {
      arr.push({
        text: obj, options: { color: '#FFFFFF', fill: { color: '#0153A5' }, } ,
      })
    });

    arr.unshift({
      name: '',
      options: { color: '#FFFFFF', fill: { color: '#0153A5' }, },
    });

    rowTitles.push(arr);
    return rowTitles;
  }

  private generateBodyRows = (tableData: any) => {
    const createRows: any = [];
    const newArr: any = [];

    tableData.forEach((obj: any) => {
      const item = newArr.find((item: any) => item.name === obj.Brand);
      if(newArr.length > 0 && item) {
        if (item) {
          obj.Value ? item.values.push(parseFloat(obj.Value)) : item.values.push(0);
        }
      } else {
        newArr.push(
          {
            name: obj.Brand,
            values: [obj.Value ? parseFloat(obj.Value) : 0],
          }
        )
      }
    });

    newArr.sort(function(a: any, b: any) {
        return b.values[0] - a.values[0];
    });

    newArr.map((obj: any) => {
      const arr: any = [];
        arr.push({
          text: obj.name, options: { color: '#000000', fill: { color: obj.name === 'Philips Respironics' ? '#c5c5c5' : '#FFFFFF' }}
        });
        obj.values.forEach((valObj: any) => {
          arr.push({
            text: valObj ? `${(parseFloat(valObj)* 100).toFixed(0)}%` : '', options: { color: '#000000', fill: { color: obj.name === 'Philips Respironics' ? '#c5c5c5' : '#FFFFFF' }}
          });
        });
      return createRows.push(arr);
    })
    return createRows;
  }

  private distinctRepeatables = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };
};
import { IDataMapping } from '../../IDataMapping';

export class NineGLineChartCampaignsTitleModel implements IDataMapping<NineGLineChartCampaignsTitleModel> {
    public Title: string = '';
    public getMappings?() {
        return {
            Title: '9G Titles (Campaigns)',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class MCCLineChartDataModel implements IDataMapping<MCCLineChartDataModel> {
    public Label: string = '';
    public Name: string = '';
    public Value: number = Number(0);
    public Rank: number = Number(0);
    public Color: string = '';
    public getMappings?() {
        return {
            Label: 'QUARTER(Date_calculation)',
            Name: 'brand_name',
            Value: 'SUM(indicator_value)',
            Rank: 'AGG(Rank)',
            Color: 'brand_color',
        };
    }
}

import pptxgen from 'pptxgenjs';

import { ProductNPSLineChartTitleModel } from '../../../models/SRC/ProductNPSResultsLineChart/ProductNPSLineChartTitleModel';
import { ProductNPSLineChartDataModel } from '../../../models/SRC/ProductNPSResultsLineChart/ProductNPSLineChartDataModel';

import { ProductNPSLineChartMonthlyDataModel } from '../../../models/SRC/ProductNPSResultsLineChart/ProductNPSLineChartMonthlyDataModel';
import { ProductNPSLineChartNoteDataModel } from '../../../models/SRC/ProductNPSResultsLineChart/ProductNPSLineChartNoteDataModel';

import { SRCFirstSlideRenderer } from '../../Renderers/SRCFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

import { filterInitDuplicateData, addEmptyValuesToInitData } from "../../../../shared/utility";

export class ProductNPSLineChartService extends BasePptxService {
    protected chartQuestion: string = '';
    protected firstSlideRenderer: SRCFirstSlideRenderer = new SRCFirstSlideRenderer();
    protected titleSheet: string;
    protected lineNPSDataSheet: string;
    protected titleMonthlySheet: string;
    protected lineNPSMonthlyDataSheet: string;
    protected noteNPSMonthlyDataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, lineNPSDataSheet: string,
        titleMonthlySheet: string, lineNPSMonthlyDataSheet: string, noteNPSMonthlyDataSheet: string,) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.lineNPSDataSheet = lineNPSDataSheet;
        this.titleMonthlySheet = titleMonthlySheet;
        this.lineNPSMonthlyDataSheet = lineNPSMonthlyDataSheet;
        this.noteNPSMonthlyDataSheet = noteNPSMonthlyDataSheet;
    }

    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        // Override title's options and add filter's name in it.
        const titleData = await this.getMappedChartData(ProductNPSLineChartTitleModel, this.titleSheet);
        let subTitle: string = "";

        titleData.map(obj => {
            this.chartTitle = obj.Title;
            return subTitle = obj.TitlePPT;
        });

        slide.addText([{ text: subTitle, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.35, bold: true });
        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 12 } }], { x: 0.5, y: 0.70, bold: true });
    }

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const lineNPSData = await this.getMappedChartData(ProductNPSLineChartDataModel, this.lineNPSDataSheet);

        if (lineNPSData.length > 0) {
            await this.addLineChart(lineNPSData, chartSlide);
        };

        const monthlyData = await this.getMappedChartData(ProductNPSLineChartMonthlyDataModel, this.lineNPSMonthlyDataSheet);

        if (monthlyData.length > 0) {
            await this.addMonthlySlide();
        }
    }

    private async addMonthlySlide() {
        const slide = this.presentation.addSlide({ masterName: 'SRC_MONTHLY_CHART' });
        const titleData = await this.getMappedChartData(ProductNPSLineChartTitleModel, this.titleMonthlySheet);
        let subTitle: string = "";

        const lineNPSMonthlyData = await this.getMappedChartData(ProductNPSLineChartMonthlyDataModel, this.lineNPSMonthlyDataSheet);

        titleData.map(obj => {
            this.chartTitle = obj.Title;
            return subTitle = obj.TitlePPT;;
        });

        slide.addText([{ text: subTitle, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.35, bold: true });
        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 12 } }], { x: 0.5, y: 0.70, bold: true });

        const noteNPSMonthlyData = await this.getMappedChartData(ProductNPSLineChartNoteDataModel, this.noteNPSMonthlyDataSheet);
        const noteArr: any = [];
        noteNPSMonthlyData.forEach((obj: any) => {
            if (obj.Note && (!noteArr.includes(obj.Note))) {
                noteArr.push(obj.Note);
            }
        });
        if(noteArr){
            slide.addText([{ text: `${noteArr.reverse()}`, options: { color: '#000000', fontSize: 8 } }], { x: 0.5, y: 5.2, bold: false });
        }

        await this.addLineChart(lineNPSMonthlyData, slide);
    }

    private async addLineChart(array: any, chartSlide: pptxgen.Slide) {
        let filteredData = filterInitDuplicateData(array);

        const newArr: any = [];
        const lineColors: string[] = [];
        const getAllLabels: any = [];

        filteredData.forEach((obj: any) => {
            getAllLabels.push(obj.Label);
        });

        const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

        filteredData.forEach((obj: any) => {
            if (obj.Base) {
                lineColors.push(obj.Color);
            }
            const item = newArr.find((item: any) => item.name === obj.Name);
            if (newArr.length > 0 && item) {
                item.values.push(parseFloat(obj.Value));
                item.labels.push(obj.Label);
            } else {
                newArr.push(
                    {
                        name: obj.Name,
                        labels: [obj.Label],
                        values: [parseFloat(obj.Value)],
                    }
                )
            }
        });

        newArr.reverse();
        lineColors.reverse();

        const finalChartData = addEmptyValuesToInitData(distinctedLabels, newArr);
        chartSlide.addChart(
            this.presentation.ChartType.line,
            finalChartData,
            {
                x: 1,
                y: 1,
                w: "70%",
                h: "70%",
                showLegend: true,
                legendPos: 'b',
                showValue: true,
                dataLabelPosition: 't',
                dataLabelFormatCode: '0%',
                valAxisLabelFormatCode: '0%',
                catAxisLabelRotate: this.labelRotateDegree(newArr),
                chartColors: lineColors.filter(this.distinctRepeatables),
                valAxisLabelFontSize: 10,
                catAxisLabelFontSize: 10,
                dataLabelFontSize: 10,
            }
        );
    }

    protected labelRotateDegree(array: any) {
        let degree: number = 0;
        array.forEach((obj: any) => {
            if (obj.labels.length > 10) {
                degree = 45;
            } else {
                degree = 0;
            }
        });
        return degree;
    }

    private distinctRepeatables = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
    };
};
import { IDataMapping } from '../../../../IDataMapping';

export class HospitalIDNPieChartMainTitleModel implements IDataMapping<HospitalIDNPieChartMainTitleModel> {
    public Title: string = '';
    public Period: string = '';
    public Country: string = '';
    public Segment: string = '';
    public getMappings?() {
        return {
            Title: 'Pie charts Main Title_Hospital/IDN',
            Period: 'Period',
            Country: 'Calc Country',
            Segment: 'Segment',
        };
    }
}

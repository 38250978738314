import React from 'react';
import { format } from 'date-fns';
import { Card } from 'primereact/card';
import { ICardsViewProps } from './ICardsViewProps';
import styles from './CardsView.module.scss';
import { Routes } from '../../../shared/routes';
import { TableauWorkbookViewModel } from '../../../models/Tableau/TableauWorkbookViewModel';
import { setSelectedWorkbook } from '../../../store/tableau/TableauActions';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../history';
import { PhilipsEnvironments } from '../../../shared/constants';
import { IState } from '../../../store/IState';

export const CardsView = (props: ICardsViewProps): JSX.Element => {
  const { workbooks } = props;

  const dispatch = useDispatch();

  const goToDefaultView = (workbook: TableauWorkbookViewModel) => {
    if (workbook.views[0]) {
      history.push(Routes.tableauView.getPath(workbook.views[0].id));
      dispatch(setSelectedWorkbook(workbook));
    }
  };

  const host: string = useSelector(
    (state: IState) => state.LayoutReducer.host
  );

  const isHsDomain = Object.values(PhilipsEnvironments).includes(host);

  const getTiles = (): JSX.Element[] => {
    const tiles: JSX.Element[] = workbooks.map((wb) => {
      const src: string = `data:image/png;base64,${wb.thumbnail}`;
      const header = (
        <div style={{ padding: isHsDomain ? '0 1em' : undefined }}>
          <div className={styles.card_info} style={{ padding: isHsDomain ? '.5em 0' : undefined }}>
            <h4>{wb.name}</h4>
            <span>
              Last Modified: {format(new Date(wb.updatedAt), 'dd-MM-yyyy HH:mm')}
            </span>
          </div>
          <div className={styles.cardImgContainer}>
            <img
              alt='Thumbnail'
              src={src}
              key={`${wb.id}-img`}
              className={isHsDomain
                ? styles.thumbnail_hs
                : styles.thumbnail}
            />
          </div>
        </div>
      );

      const footer = wb.tags.map((t: string, i: number) => (
        <div className={styles.tag} key={`${wb.id}-tag-${i}`}>
          {t}
        </div>
      ));
      return (
        <React.Fragment key={wb.id}>
          <div
            onClick={() => goToDefaultView(wb)}
            className={styles.card_container}
          >
            <Card
              key={wb.id}
              header={header}
              subTitle={wb.description}
              id={wb.id}
            >
              <div className={styles.card_footer}>
              {footer}
              </div>
            </Card>
          </div>
        </React.Fragment>
      );
    });

    return tiles;
  };

  return (
    <div className={styles.tiles_container}>
      {getTiles()}
    </div>
  );
};

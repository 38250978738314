import pptxgen from 'pptxgenjs';
import { SPVendorModel } from '../../../';
import { PptxRenderHelper } from '../../../helpers/PptxRenderHelper';
import { DoughnutChartPptxService } from '../../DoughnutChartPptxService';
import { HSStrategicFirstSlideRenderer } from '../../Renderers/HSStrategicFirstSlideRenderer';

export class SPVendorPptxService extends DoughnutChartPptxService {
    protected chartQuestion: string = `Q: And within [-Clinical Segment] domain which vendor would you consider as your partner?`;
    protected firstSlideRenderer: HSStrategicFirstSlideRenderer = new HSStrategicFirstSlideRenderer();

    constructor(view: any, chartTitle: string) {
        super(view, chartTitle);
        this.setChartQuestion(chartTitle);
    }

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const data = await this.getMappedChartData(SPVendorModel, 'MIA Ology Pie');
        // Set "Others" to always have a silver color.
        data.forEach((element, index) => {
          if(element.Brand === "Others") {
            element.Color = "#bab0ac";
          }
        });

        let chartData = data.filter(d => d.AveragePositive === null && d.AverageNegative == null);
        chartData = this.getSortedByOrder(chartData);
        const dataChartPieStat = [
            {
                name: this.chartTitle,
                labels: chartData.map(x => x.Brand),
                values: chartData.map(x => (Math.round(x.Sum ? (x.Sum * 100) : 0)))
            }
        ];

        const chartColors = this.getMappedChartDataColors(chartData);
        const chartOptions: pptxgen.IChartOpts = {
            x: 3, y: 1.5, w: 4.5, h: 3,
            layout: { x: 0, y: 0, w: 0.7, h: 1 },
            chartColors
        };

        this.addChart(chartSlide, dataChartPieStat, chartOptions);
        const average = data.firstOrNull(d => d.AveragePositive !== null || d.AverageNegative !== null);
        PptxRenderHelper.addPieChartAvarageColorized(chartSlide, average, chartOptions);
    }

    private setChartQuestion(chartTitle: string | undefined) {
        let clinicalSegment = '';
        if (chartTitle) {
            const titleParts = chartTitle.split(this.chartTitle);
            if (titleParts.length > 1) {
                clinicalSegment = titleParts[1].split(':')[0];
            }
        }
        this.chartQuestion = this.chartQuestion.replace(' [-Clinical Segment]', clinicalSegment);
    }
}

import React from 'react';
import { ISVGProps } from './ISVGProps';

export const DeleteSvg = (props: ISVGProps) => {
  const { width, height } = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 20 22'
    >
      <defs>
        <style>
          {
            '.a{fill:none;stroke:#212529;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}'
          }
        </style>
      </defs>
      <g transform='translate(1 1)'>
        <path className='a' d='M3,6H21' transform='translate(-3 -2)' />
        <path
          className='a'
          d='M19,6V20a2,2,0,0,1-2,2H7a2,2,0,0,1-2-2V6M8,6V4a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2V6'
          transform='translate(-3 -2)'
        />
        <line className='a' y2='6' transform='translate(7 9)' />
        <line className='a' y2='6' transform='translate(11 9)' />
      </g>
    </svg>
  );
};

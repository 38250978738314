import { IDataMapping } from '../../../../IDataMapping';

export class PHBSEmployeeDeepDiveOverTimeDataModel implements IDataMapping<PHBSEmployeeDeepDiveOverTimeDataModel> {
    public Label:       string = '';
    public Color:       string = '';
    public Name:        string = '';
    public Value:       number = 0;
    public PeriodIndex: number = 0;
    public Factor:      string = '';
    public Statement:   string = '';

    public getMappings?() {
        return {
            Label:       'QUARTER(Date)',
            Color:       'ATTR(Market_color)',
            Name:        'Market & Global Employees',
            Value:       'AVG(Value)',
            PeriodIndex: 'AVG(Period Index)',
            Factor:      'ATTR(Factor)',
            Statement:   'ATTR(Statement)'
        };
    }
}

import pptxgen from 'pptxgenjs';

import { PurchaseFunnelTitleModel } from '../../../models/SRC/PurchaseFunnel/PurchaseFunnelTitleModel';
import { PurchaseFunnelDataModel } from '../../../models/SRC/PurchaseFunnel/PurchaseFunnelDataModel';
import { PurchaseFunnelTableDataModel } from '../../../models/SRC/PurchaseFunnel/PurchaseFunnelTableDataModel';

import { PurchaseFunnelMonthlyDataModel } from '../../../models/SRC/PurchaseFunnel/PurchaseFunnelMonthlyDataModel';
import { PurchaseFunnelTableMonthlyDataModel } from '../../../models/SRC/PurchaseFunnel/PurchaseFunnelTableMonthlyDataModel';

import { PurchaseFunnelSampleModel } from '../../../models/SRC/PurchaseFunnel/PurchaseFunnelSampleModel';

import { SRCFirstSlideRenderer } from '../../Renderers/SRCFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

export class PurchaseFunnelService extends BasePptxService {
  protected chartQuestion: string = '';
  protected firstSlideRenderer: SRCFirstSlideRenderer = new SRCFirstSlideRenderer();
  protected titleSheet: string;
  protected commentsSheet: string;
  protected commentsMonthlySheet: string;
  protected funnelDataSheet: string;
  protected extraFunnelDataSheet: string;
  protected firstTableDataSheet: string;
  protected secondTableDataSheet: string;
  protected thirdTableDataSheet: string;
  protected firstFamiliarity: string;
  protected secondFamiliarity: string;
  protected thirdFamiliarity: string;
  // Monthly data.
  protected titleMonthlySheet: string;
  protected funnelMonthlyDataSheet: string;
  protected extraFunnelMonthlyDataSheet: string;
  protected firstTableMonthlyDataSheet: string;
  protected secondTableMonthlyDataSheet: string;
  protected thirdTableMonthlyDataSheet: string;
  protected firstMonthlyFamiliarity: string;
  protected secondMonthlyFamiliarity: string;
  protected thirdMonthlyFamiliarity: string;
  protected sampleData: string;
  protected sampleMonthlyData: string;

  constructor(view: any, chartTitle: string, titleSheet: string, 
    commentsSheet: string, commentsMonthlySheet: string, funnelDataSheet: string, extraFunnelDataSheet: string,
    firstTableDataSheet: string, secondTableDataSheet: string, thirdTableDataSheet: string,
    firstFamiliarity: string, secondFamiliarity: string, thirdFamiliarity: string,
    // Monthly data.
    titleMonthlySheet:string, funnelMonthlyDataSheet: string, extraFunnelMonthlyDataSheet: string,
    firstTableMonthlyDataSheet: string, secondTableMonthlyDataSheet: string, thirdTableMonthlyDataSheet: string,
    firstMonthlyFamiliarity: string, secondMonthlyFamiliarity: string, thirdMonthlyFamiliarity: string,
    sampleData: string, sampleMonthlyData: string,) {
    super(view, chartTitle);
    this.titleSheet = titleSheet;
    this.commentsSheet = commentsSheet;
    this.commentsMonthlySheet = commentsMonthlySheet;
    this.funnelDataSheet = funnelDataSheet;
    this.extraFunnelDataSheet = extraFunnelDataSheet;
    this.firstTableDataSheet = firstTableDataSheet;
    this.secondTableDataSheet = secondTableDataSheet;
    this.thirdTableDataSheet = thirdTableDataSheet;
    this.firstFamiliarity = firstFamiliarity;
    this.secondFamiliarity = secondFamiliarity;
    this.thirdFamiliarity = thirdFamiliarity;
    // Monthly data.
    this.titleMonthlySheet = titleMonthlySheet;
    this.funnelMonthlyDataSheet = funnelMonthlyDataSheet;
    this.extraFunnelMonthlyDataSheet = extraFunnelMonthlyDataSheet;
    this.firstTableMonthlyDataSheet = firstTableMonthlyDataSheet;
    this.secondTableMonthlyDataSheet = secondTableMonthlyDataSheet;
    this.thirdTableMonthlyDataSheet = thirdTableMonthlyDataSheet;
    this.firstMonthlyFamiliarity = firstMonthlyFamiliarity;
    this.secondMonthlyFamiliarity = secondMonthlyFamiliarity;
    this.thirdMonthlyFamiliarity = thirdMonthlyFamiliarity;
    this.sampleData = sampleData;
    this.sampleMonthlyData = sampleMonthlyData;
  }

  protected async setChartSlideLayout(slide: pptxgen.Slide) {
    // Override title's options and add filter's name in it.
    const titleData = await this.getMappedChartData(PurchaseFunnelTitleModel, this.titleSheet);
    const comments = await this.getMappedChartData(PurchaseFunnelTitleModel, this.commentsSheet);
    
    titleData.map( obj => {
      return this.chartTitle = obj.Title + ' ' + obj.QuarterTitle;
    });

    slide.addText([{ text: this.chartTitle, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.20, bold: true });
    slide.addText([{ text: comments[0].Comment, options: { color: this.colors.black, fontSize: 12 } }], { x: 0.5, y: 0.45, italic: true });
    slide.addText([{ text: comments[0].SecondComment, options: { color: this.colors.black, fontSize: 12 } }], { x: 0.5, y: 0.90, italic: true });
  }

  protected async addChartSlide(chartSlide: pptxgen.Slide) {
    const funnelData = await this.getMappedChartData(PurchaseFunnelDataModel, this.funnelDataSheet);
    const extraFunnelDataSheet = await this.getMappedChartData(PurchaseFunnelDataModel, this.extraFunnelDataSheet);
    const sampleData = await this.getMappedChartData(PurchaseFunnelSampleModel, this.sampleData);

    const firstArrData = funnelData.filter(function(el) { return el.Name === 'Philips Respironics'; }); 
    const secondArrData = funnelData.filter(function(el) { return el.Name !== 'Philips Respironics'; });

    const firstTableData = await this.getMappedChartData(PurchaseFunnelTableDataModel, this.firstTableDataSheet);
    const firstFamiliarityData = await this.getMappedChartData(PurchaseFunnelTableDataModel, this.firstFamiliarity);

    const secondTableData = await this.getMappedChartData(PurchaseFunnelTableDataModel, this.secondTableDataSheet);
    const secondFamiliarityData = await this.getMappedChartData(PurchaseFunnelTableDataModel, this.secondFamiliarity);

    const thirdTableData = await this.getMappedChartData(PurchaseFunnelTableDataModel, this.thirdTableDataSheet);
    const thirdFamiliarityData = await this.getMappedChartData(PurchaseFunnelTableDataModel, this.thirdFamiliarity);

    if (firstArrData.length > 0) {
      await this.addFirstChart(firstArrData, chartSlide, firstTableData, firstFamiliarityData);
    };

    if (secondArrData.length > 0) {
      await this.addSecondChart(secondArrData, chartSlide, secondTableData, secondFamiliarityData);
    };

    if (extraFunnelDataSheet.length > 0) {
      await this.addThirdChart(extraFunnelDataSheet, chartSlide, thirdTableData, thirdFamiliarityData)
    };

    let lowValue: boolean = false;
    if (sampleData.length > 0 && sampleData[0]) {
      const arr: any = [];
      sampleData.forEach((obj: any) => {
        if (obj.SampleValue < 20) {
          lowValue = true;
        };
        arr.push(
          {
            text: `${obj.SampleDate} (n=${obj.SampleValue < 20 ? obj.SampleValue + '*' : obj.SampleValue})  `,
            options: { color: obj.SampleValue < 10 ? '#FF0000' : this.colors.default, fontSize: 9 },
          },
          {
            text: '   ',
            options: { color: obj.SampleValue < 10 ? '#FF0000' : '#000000', fontSize: 9 },
          }
        )
      })
      chartSlide.addText(
        arr,
        { x: 1.5, y: 3.7, italic: true }
      );
    }

    if (lowValue) {
      chartSlide.addText([{ text: "*Note: Scores could be influenced by small sample sizes.", options: { color: '#000000', fontSize: 8 } }], { x: 0.5, y: 4, bold: false});
    };

    const monthlyData = await this.getMappedChartData(PurchaseFunnelMonthlyDataModel, this.funnelMonthlyDataSheet);
    if (monthlyData.length > 0) {
      await this.addMonthlySlide();
    }
  }

  // Monthly.
  private async addMonthlySlide() {
    const slide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });
    const titleData = await this.getMappedChartData(PurchaseFunnelTitleModel, this.titleMonthlySheet);
    const comments = await this.getMappedChartData(PurchaseFunnelTitleModel, this.commentsMonthlySheet);
    const sampleData = await this.getMappedChartData(PurchaseFunnelSampleModel, this.sampleMonthlyData);
    let title: string = '';
    titleData.map( obj => {
      return title = obj.Title + ' ' + obj.MonthTitle;
    });

    slide.addText([{ text: title, options: { color: this.colors.black, fontSize: 14 } }], { x: 0.5, y: 0.20, bold: true });

    if (comments.length > 0) {
      slide.addText([{ text: comments[0].Comment, options: { color: this.colors.black, fontSize: 12 } }], { x: 0.5, y: 0.45, italic: true });
      slide.addText([{ text: comments[0].SecondComment, options: { color: this.colors.black, fontSize: 12 } }], { x: 0.5, y: 0.90, italic: true });
    };
    
    const funnelMonthlyData = await this.getMappedChartData(PurchaseFunnelMonthlyDataModel, this.funnelMonthlyDataSheet);
    const extraFunnelMonthlyDataSheet = await this.getMappedChartData(PurchaseFunnelMonthlyDataModel, this.extraFunnelMonthlyDataSheet);

    const firstArrData = funnelMonthlyData.filter(function(el) { return el.Name === 'Philips Respironics'; }); 
    const secondArrData = funnelMonthlyData.filter(function(el) { return el.Name !== 'Philips Respironics'; });

    const firstTableMonthlyData = await this.getMappedChartData(PurchaseFunnelTableMonthlyDataModel, this.firstTableMonthlyDataSheet);
    const firstMonthlyFamiliarityData = await this.getMappedChartData(PurchaseFunnelTableMonthlyDataModel, this.firstMonthlyFamiliarity);

    const secondTableMonthlyData = await this.getMappedChartData(PurchaseFunnelTableMonthlyDataModel, this.secondTableMonthlyDataSheet);
    const secondMonthlyFamiliarityData = await this.getMappedChartData(PurchaseFunnelTableMonthlyDataModel, this.secondMonthlyFamiliarity);
    
    const thirdTableMonthlyData = await this.getMappedChartData(PurchaseFunnelTableMonthlyDataModel, this.thirdTableMonthlyDataSheet);
    const thirdMonthlyFamiliarityData = await this.getMappedChartData(PurchaseFunnelTableMonthlyDataModel, this.thirdMonthlyFamiliarity);

    if (firstArrData.length > 0) {
      await this.addFirstChart(firstArrData, slide, firstTableMonthlyData, firstMonthlyFamiliarityData);
    };

    if (secondArrData.length > 0) {
      await this.addSecondChart(secondArrData, slide, secondTableMonthlyData, secondMonthlyFamiliarityData);
    };

    if (extraFunnelMonthlyDataSheet.length > 0) {
      await this.addThirdChart(extraFunnelMonthlyDataSheet, slide, thirdTableMonthlyData, thirdMonthlyFamiliarityData)
    };

    let lowValue: boolean = false;
    let note: string = '';
    if (sampleData.length > 0 && sampleData[0]) {
      const arr: any = [];
      sampleData.forEach((obj: any) => {
        if (obj.Note) {
          note += `${obj.Note}  `
        }
        if (obj.SampleValue < 20) {
          lowValue = true;
        };
        arr.push(
          {
            text: `${obj.SampleDate} (n=${obj.SampleValue < 20 ? obj.SampleValue + '*' : obj.SampleValue})  `,
            options: { color: obj.SampleValue < 10 ? '#FF0000' : this.colors.default, fontSize: 9 },
          },
          {
            text: '   ',
            options: { color: this.colors.default, fontSize: 9 },
          }
        )
      })
      slide.addText(
        arr,
        { x: 1.5, y: 3.7, italic: true }
      );
    };
    slide.addText([{ text: note, options: { color: this.colors.default, fontSize: 8 } }], { x: 0.5, y: 3.85, bold: false });

    if (lowValue) {
      slide.addText([{ text: "*Note: Scores could be influenced by small sample sizes.", options: { color: '#000000', fontSize: 8 } }], { x: 0.5, y: 4, bold: false});
    };
  }

  private async addFirstChart(array: any, chartSlide: pptxgen.Slide, firstTableData: any, firstFamiliarityData: any) {
    const slide = chartSlide;
    const newArr: any = [];
    const lineColors: string[] = [];

    const combineTableAndFamiliarity = [...firstFamiliarityData, ...firstTableData];

    array.sort((a: any, b: any) => b.LabelsRank - a.LabelsRank);
    array.forEach((obj: any) => {
      if (obj.Value) {
        lineColors.push(obj.Color);
      }
      const item = newArr.find((item: any) => item.rank === obj.Rank);
      if(newArr.length > 0 && item) {
        if (item) {
          item.values.push(parseFloat(obj.Value));
          item.labels.push(obj.Label);
        }
      } else {
        newArr.push(
          {
            rank: obj.Rank,
            values: [parseFloat(obj.Value)],
            labels: [obj.Label],
            color: obj.Color,
            name: obj.Name,
          }
        )
      }
    });

    const data: any = [];
    let chartTitle = "";
    
    newArr.forEach((obj: any) => {
      chartTitle = obj.name;
      const newObject = {
        name: obj.name,
        labels: [...obj.labels],
        values: [...obj.values]
      }

      const getObj = Object.assign({}, newObject);
      data.push(getObj);
    });

    slide.addChart(
      this.presentation.ChartType.bar,
      data,
      {
        x: 0.1,
        y: 1.2,
        w: "35%",
        h: "45%",
        showTitle: true,
        title: chartTitle,
        titleFontSize: 11,
        catAxisLabelFontSize: 8,
        barDir: "bar",
        showLegend: false,
        legendPos: 'b',
        dataLabelFormatCode: '0%',
        valAxisLabelFormatCode: '0%',
        valAxisLabelFontSize: 10,
        chartColors: lineColors.filter(this.distinctRepeatables),
        dataLabelPosition: "outEnd",
        showValue: true,
        valAxisHidden: true,
        dataLabelFontSize: 9,
        valGridLine: { style: "none" },
      }
    );
    
    const tableProps: pptxgen.TableProps = {
      x: 0.4,
      y: 4.12,
      w: "35%",
      h: "50%",
      align: 'center',
      fontSize: 6,
      valign: 'middle',
      rowH: 0.35,
      color: this.colors.white,
      autoPage: true,
      newSlideStartY: 1,
      border: { type: 'solid', color: this.colors.neutral, pt: 0.2 },
      autoPageLineWeight: 0.5
    };

    const createRowTitles = this.generateTitleRows(combineTableAndFamiliarity);
    const createRowBody = this.generateBodyRows(combineTableAndFamiliarity);

    chartSlide.addTable([...createRowTitles, ...createRowBody], tableProps);
    this.addArrows(this.presentation, chartSlide, '*', {  x: 3.6, y: 2.36 });
    this.addArrows(this.presentation, chartSlide, '**', {  x: 3.6, y: 2.91 });
  }

  private async addSecondChart(array: any, chartSlide: pptxgen.Slide, secondTableData: any, secondFamiliarityData: any) {
    const slide = chartSlide;
    const newArr: any = [];
    const lineColors: string[] = [];
    const combineTableAndFamiliarity = [...secondFamiliarityData, ...secondTableData];

    array.sort((a: any, b: any) => b.LabelsRank - a.LabelsRank);
    array.forEach((obj: any) => {
      if (obj.Value) {
      lineColors.push(obj.Color);
      }
      const item = newArr.find((item: any) => item.rank === obj.Rank);
      if(newArr.length > 0 && item) {
        if (item) {
          item.values.push(parseFloat(obj.Value));
          item.labels.push(obj.Label);
        }
      } else {
        newArr.push(
          {
            rank: obj.Rank,
            values: [parseFloat(obj.Value)],
            labels: [obj.Label],
            color: obj.Color,
            name: obj.Name,
          }
        )
      }
    });

    const data: any = [];
    let chartTitle = "";
    
    newArr.forEach((obj: any) => {
      chartTitle = obj.name;
      const newObject = {
        name: obj.name,
        labels: [...obj.labels],
        values: [...obj.values]
      }

      const getObj = Object.assign({}, newObject);
      data.push(getObj);
    });
    
    slide.addChart(
      this.presentation.ChartType.bar,
      data,
      {
        x: 4,
        y: 1.2,
        w: "25%",
        h: "45%",
        showTitle: true,
        title: chartTitle,
        titleFontSize: 11,
        catAxisLabelFontSize: 8,
        barDir: "bar",
        showLegend: false,
        legendPos: 'b',
        dataLabelFormatCode: '0%',
        valAxisLabelFormatCode: '0%',
        valAxisLabelFontSize: 10,
        chartColors: lineColors.filter(this.distinctRepeatables),
        catAxisHidden: true,
        dataLabelPosition: "outEnd",
        showValue: true,
        valAxisHidden: true,
        dataLabelFontSize: 9,
        valGridLine: { style: "none" },
      }
    );

    const tableProps: pptxgen.TableProps = {
      x: 4.2,
      y: 4.12,
      w: "25%",
      h: "50%",
      align: 'center',
      fontSize: 6,
      valign: 'middle',
      rowH: 0.35,
      color: this.colors.white,
      autoPage: true,
      newSlideStartY: 1,
      border: { type: 'solid', color: this.colors.neutral, pt: 0.2 },
      autoPageLineWeight: 0.5
    };

    const createRowTitles = this.generateTitleRows(combineTableAndFamiliarity);
    const createRowBody = this.generateBodyRows(combineTableAndFamiliarity);

    chartSlide.addTable([...createRowTitles, ...createRowBody], tableProps);
    this.addArrows(this.presentation, chartSlide, '*', {  x: 6.5, y: 2.36 });
    this.addArrows(this.presentation, chartSlide, '**', {  x: 6.5, y: 2.91 });
  }

  private async addThirdChart(array: any, chartSlide: pptxgen.Slide, thirdTableData: any, thirdFamiliarityData:any) {
    const slide = chartSlide;
    const newArr: any = [];
    const lineColors: string[] = [];

    const combineTableAndFamiliarity = [...thirdFamiliarityData, ...thirdTableData];

    array.sort((a: any, b: any) => b.LabelsRank - a.LabelsRank);
    array.forEach((obj: any) => {
      if (obj.Value) {
      lineColors.push(obj.Color);
      }
      const item = newArr.find((item: any) => item.rank === obj.Rank);
      if(newArr.length > 0 && item) {
        if (item) {
          item.values.push(parseFloat(obj.Value));
          item.labels.push(obj.Label);
        }
      } else {
        newArr.push(
          {
            rank: obj.Rank,
            values: [parseFloat(obj.Value)],
            labels: [obj.Label],
            color: obj.Color,
            name: obj.Name,
          }
        )
      }
    });

    const data: any = [];
    let chartTitle = "";
    
    newArr.forEach((obj: any) => {
      chartTitle = obj.name;
      const newObject = {
        name: obj.name,
        labels: [...obj.labels],
        values: [...obj.values]
      }

      const getObj = Object.assign({}, newObject);
      data.push(getObj);
    });
    
    slide.addChart(
      this.presentation.ChartType.bar,
      data,
      {
        x: 7,
        y: 1.2,
        w: "25%",
        h: "45%",
        showTitle: true,
        title: chartTitle,
        titleFontSize: 11,
        catAxisLabelFontSize: 8,
        barDir: "bar",
        showLegend: false,
        legendPos: 'b',
        dataLabelFormatCode: '0%',
        valAxisLabelFormatCode: '0%',
        valAxisLabelFontSize: 10,
        chartColors: lineColors.filter(this.distinctRepeatables),
        catAxisHidden: true,
        dataLabelPosition: "outEnd",
        showValue: true,
        valAxisHidden: true,
        dataLabelFontSize: 9,
        valGridLine: { style: "none" },
      }
    );

    const tableProps: pptxgen.TableProps = {
      x: 7,
      y: 4.12,
      w: "25%",
      h: "50%",
      align: 'center',
      fontSize: 6,
      valign: 'middle',
      rowH: 0.35,
      color: this.colors.white,
      autoPage: true,
      newSlideStartY: 1,
      border: { type: 'solid', color: this.colors.neutral, pt: 0.2 },
      autoPageLineWeight: 0.5
    };

    const createRowTitles = this.generateTitleRows(combineTableAndFamiliarity);
    const createRowBody = this.generateBodyRows(combineTableAndFamiliarity);

    chartSlide.addTable([...createRowTitles, ...createRowBody], tableProps);
    this.addArrows(this.presentation, chartSlide, '*', {  x: 9.5, y: 2.36 });
    this.addArrows(this.presentation, chartSlide, '**', {  x: 9.5, y: 2.91 });
  }

  private generateTitleRows = (tableData: any) => {
    tableData.sort((a: any, b: any) => a.Rank - b.Rank);
    const dates = tableData.map((o: any) => o.Date);
    const filteredDates = tableData.filter(({Date}: any, index: any) => !dates.includes(Date, index + 1));
    const result = [] as  any;
    const rowTitles: pptxgen.TableRow[] = [];
    let brand = '';

    filteredDates.forEach((obj: any) => {
      brand = obj.Name;
      result.push({ text: obj.Date, options: { color: '#000000', fill: { color: '#FFFFFF'}}},)
    })
    
    const reversedData = result.slice().reverse();
    rowTitles.push(reversedData);

    if (brand === 'Philips Respironics') {
      rowTitles[0].unshift({
        text: '',
      });
    }

    return rowTitles;
  }

  private generateBodyRows = (tableData: any) => {
    const reversedData = tableData.slice().reverse();
    const newArr: any = [];
    const lineColors: string[] = [];

    reversedData.forEach((obj: any) => {
      lineColors.push(obj.Color);
      const item = newArr.find((item: any) => item.name === obj.Label);
      if(newArr.length > 0 && item) {
        if (obj.FirstTableValue) {
          item.values.push(parseFloat(obj.FirstTableValue));
        } else if (obj.SecondTableValue) {
          item.values.push(parseFloat(obj.SecondTableValue));
        } else {
          item.values.push(parseFloat(obj.FamiliarityTableValue));
        };
      } else {
        newArr.push(
          {
            name: obj.Label,
            values: [function() { 
              if (obj.FirstTableValue) {
                return parseFloat(obj.FirstTableValue);
              } else if (obj.SecondTableValue) {
                return parseFloat(obj.SecondTableValue);
              } else {
                return parseFloat(obj.FamiliarityTableValue);
              }
            }()],
            brand: obj.Name,
          }
        )
      }
    });

    // Generate the rows.
    const createRows: any = [];
    newArr.map((obj: any, index: any) => {
      const arr: any = [];
      if (obj.brand === 'Philips Respironics') {
        arr.push({
          text: obj.name, options: { color: '#000000', fill: { color: '#FFFFFF'} }
        })
      };
      obj.values.forEach((val: any) => {
        arr.push({
          text: obj.name === 'Familiarity' ? (this.myRound(parseFloat(val), 1)).toFixed(1) : `${(parseFloat(val)* 100).toFixed(0)}%`, 
          options: { 
            color: '#000000', 
            fill: { color: '#FFFFFF'},
            //h: 5,
          }
        })
      });
      return createRows.push(arr);
    })
    
    return createRows;
  };

  private addArrows(
    presentation: pptxgen,
    chartSlide: pptxgen.Slide,
    label: string,
    options: { x: number; y: number }
) {
    const shapeOptions: pptxgen.ShapeProps = {
        x: options.x,
        y: options.y,
        w: 0.17,
        h: 0.42,
        line: { dashType: 'solid', color: this.colors.default, width: 0.5 },
        align: 'center',
    };
    chartSlide.addShape(presentation.ShapeType.curvedLeftArrow, shapeOptions);
    chartSlide.addText(label, { x: options.x + 0.05, y: options.y, valign: 'top', fontSize: 8, color: this.colors.default });
};

  private myRound(value: number, places: number) {
    const multiplier = Math.pow(10, places);
    return (Math.round(value * multiplier) / multiplier);
  };
  
  private distinctRepeatables = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };
};
import pptxgen from 'pptxgenjs';
import { BaseChartOptions } from '../../../../../philips-constants';

import { CommunicationChannelsChartTitleModel } from '../../../../../models/HealthSystems/StrategicPartnership/Marketing/CommunicationChannelsChart/CommunicationChannelsChartTitleModel';
import { CommunicationChannelsChartDataModel } from '../../../../../models/HealthSystems/StrategicPartnership/Marketing/CommunicationChannelsChart/CommunicationChannelsChartDataModel';

import { BasePptxService } from '../../../../BasePptxService';
import { HSStrategicFirstSlideRenderer } from '../../../../Renderers/HSStrategicFirstSlideRenderer';
import { PptxRenderHelper } from '../../../../../helpers/PptxRenderHelper';

export class CommunicationChannelsChartService extends BasePptxService {
    protected chartQuestion: string = 'Q: We`d like you to rate the different ways that you experienced these communication channels.' +
        'Please click on and drag the icon for at least 5 different experiences, to the place in the box that best reflects' +
        ' the Impact (Up and Down) and the Favourability (Left and Right) in terms of overall experience you had through this channel';
    protected firstSlideRenderer: HSStrategicFirstSlideRenderer = new HSStrategicFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
    }

    // Override of a super class method
    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const mappedTitleData = await this.getMappedChartData(CommunicationChannelsChartTitleModel, this.titleSheet);

        mappedTitleData.forEach(obj => {
            this.chartTitle = `${obj.Title}: ${obj.Country} - ${obj.Period}`;
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 17 } }], { x: 0.6, y: 0.55, bold: true });
        slide.addText([{ text: this.chartQuestion, options: { color: this.colors.black, fontSize: 7 } }], { x: 0.5, y: '95%', bold: false });
    }

    // Override of a super class method
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const mappedChartData = await this.getMappedChartData(CommunicationChannelsChartDataModel, this.dataSheet);

        if (mappedChartData.length > 0) {
            const sortedData = this.getSortedByOrder(mappedChartData, (a, b) => (a.Favourability as number) - (b.Favourability as number));

            // Sample
            const averageData = sortedData.find(obj => obj.Sample);
            chartSlide.addText(`N = ${averageData?.Sample}`, { x: 1, y: 1, fontSize: 10, color: this.colors.default });

            // Colors
            const colors = PptxRenderHelper.getMappedChartDataColorsSP(sortedData);

            // Finding the min and max values for the axes
            const impactValues = sortedData.map(obj => obj.Impact);
            const favourabilityValues = sortedData.map(obj => obj.Favourability);

            let minYValue = Math.trunc(Math.min(...impactValues) / 0.5) * 0.5;

            let minXValue = Math.trunc(Math.min(...favourabilityValues) / 0.5) * 0.5,
                maxXvalue = Math.ceil(Math.max(...favourabilityValues));


            const options: pptxgen.IChartOpts = {
                ...BaseChartOptions,
                x: 0.5, y: 1.2, w: 9, h: 3.8,
                showCatAxisTitle: true,
                catAxisTitle: 'Favourability',
                showValAxisTitle: true,
                valAxisTitle: 'Impact',
                showLegend: true,
                legendPos: 'r',
                lineSize: 0,
                lineDataSymbolSize: 15,
                chartColors: colors,
                valAxisMinVal: minYValue,
                catAxisMinVal: minXValue,
                catAxisMaxVal: maxXvalue,
            };

            const chartData = [
                {
                    name: "X-Axis",
                    values: sortedData.map(d => d.Favourability)
                },
                ...this.getChartSeries(sortedData)
            ]

            chartSlide.addChart(this.presentation.ChartType.scatter, chartData, options);
        }
    }

    private getChartSeries(data: CommunicationChannelsChartDataModel[]) {
        const initial: Array<number | null> = data.map(d => null);
        return data.map((d, i) => {
            const values: Array<number | null> = [...initial];
            values[i] = d.Impact;
            return {
                name: d.Answer,
                values
            };
        });
    }
}

import { IDataMapping } from '../../IDataMapping';

export class DriverAnalysisCommentsModel implements IDataMapping<DriverAnalysisCommentsModel> {
    public Comments: string = '';
    public Verbatims: string = '';
    public SampleName: string = '';
    public SampleValue: number = Number(0);    
    public SampleRank: number = Number(0);
    public getMappings?() {
        return {
            Comments: 'Comments',
            Verbatims: 'Verbatims',
            SampleName: 'Brand',
            SampleValue: 'AVG(KDA Base Count)',
            SampleRank: 'AGG(INDEX())',
        };
    }
}

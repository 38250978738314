import pptxgen from 'pptxgenjs';

import { BasePptxService } from '../../../../BasePptxService';
import { HSBSFirstSlideRenderer } from '../../../../Renderers/HSBSFirstSlideRenderer';
import { PptxRenderHelper } from '../../../../../helpers/PptxRenderHelper';

import { BSByStatementChartTitleModel } from '../../../../../models/HealthSystems/BrandStrength/MarketBrandStrength/BSByStatementChart/BSByStatementChartTitleModel';
import { BSByStatementChartDataModel } from '../../../../../models/HealthSystems/BrandStrength/MarketBrandStrength/BSByStatementChart/BSByStatementChartDataModel';

export class BSByStatementChartService extends BasePptxService {
    protected firstSlideRenderer: HSBSFirstSlideRenderer = new HSBSFirstSlideRenderer();
    protected chartQuestion: string = '';
    protected titleSheet: string;
    protected dataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
    }

    // Override of a super class method
    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const titleData = await this.getMappedChartData(BSByStatementChartTitleModel, this.titleSheet);

        let subTitle: string = "";
        titleData.forEach((obj: any) => {
            this.chartTitle = `${obj.Title}`;
            subTitle = `${obj.SubTitle} - ${obj.Audience}`;
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.55, bold: true, w: 8.6 });
        slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 12, lineSpacing: 12 } }], { x: 0.6, y: 0.9 });
        slide.addText([{ text: this.chartQuestion, options: { color: this.colors.black, fontSize: 7 } }], { x: 0.5, y: '95%', bold: false });
    }

    // Override of a super class method
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const mappedChartData = await this.getMappedChartData(BSByStatementChartDataModel, this.dataSheet);

        // sort all data by brand rank
        const sortedmappedChartData = mappedChartData.sort((a: any, b: any) => a.BrandRank - b.BrandRank);

        if (sortedmappedChartData.length > 0) {

            // filtering and sorting data
            const chartdata: BSByStatementChartDataModel[] = this.filterByCheckProperty(sortedmappedChartData);

            const groupData: BSByStatementChartDataModel[][] = this.groupByProperty(chartdata, "Answer");

            const slides: BSByStatementChartDataModel[][][] = this.splitRowsBySlides(groupData);

            const colors: string[] = this.getAllByProperty(groupData, "Color");
            const brands: string[] = this.getAllByProperty(groupData, "Brand");

            // execution
            slides.forEach((slide, index) => {
                const currentSlide = this.getCurrentSlide(chartSlide, index);

                // add table - part 1
                this.addTable(slide, currentSlide);

                // add scatter chart - part 2
                this.addChart(slide, currentSlide);

                this.addLegend(colors, brands, currentSlide);
            });
        }
    }

    private getCurrentSlide(chartSlide: pptxgen.Slide, index: number) {
        let currentSlide = chartSlide;

        if (index !== 0) {
            currentSlide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });
            this.setChartSlideLayout(currentSlide);
        }

        return currentSlide;
    }

    private filterByCheckProperty(data: BSByStatementChartDataModel[]) {
        let cleanData: BSByStatementChartDataModel[] = [];

        // Filtering
        cleanData = data.filter((obj) => {
            return obj.Check == null
        });

        // Sorting by rows
        cleanData.sort((a, b) => a.RowOrder - b.RowOrder)

        return cleanData;
    }

    private groupByProperty(array: BSByStatementChartDataModel[], property: string) {
        const groupData: BSByStatementChartDataModel[][] = [];

        // Grouping the data in rows
        for (let obj of array) {
            let flagMakeNewGroup = true;
            // Checks If there is group/array for this object
            for (let arr of groupData) {
                for (let element of arr) {
                    if (element[property as keyof BSByStatementChartDataModel] === obj[property as keyof BSByStatementChartDataModel]) {
                        arr.push(obj);
                        flagMakeNewGroup = false;
                    }
                    break;
                }
            }
            // Creates new group/array
            if (flagMakeNewGroup) {
                groupData.push([obj]);
            }
        }

        return groupData;
    }

    private splitRowsBySlides(groupData: BSByStatementChartDataModel[][]) {
        const slides: BSByStatementChartDataModel[][][] = [];

        // full slides
        const rowsPerSlide = 10;
        const numberOfFullSlides = Math.trunc(groupData.length / rowsPerSlide);
        for (let i = 0; i < numberOfFullSlides; ++i) {
            slides.push(groupData.slice((i * rowsPerSlide), (i * rowsPerSlide) + rowsPerSlide));
        }

        // last smaller slide
        const lastSlide = groupData.length % rowsPerSlide;
        if (lastSlide) {
            slides.push(groupData.slice((groupData.length - lastSlide), groupData.length));
        }

        return slides;
    }

    private getAllByProperty(data: BSByStatementChartDataModel[][], property: string) {
        let array: any[] = [];

        data.forEach((row) => {
            row.forEach((obj) => {
                if (!array.includes(obj[property as keyof BSByStatementChartDataModel])) {
                    array.push(obj[property as keyof BSByStatementChartDataModel]);
                }
            });
        });

        return array;
    }

    private addTable(data: BSByStatementChartDataModel[][], chartSlide: pptxgen.Slide) {
        let answers: string[] = [];
        let factors: string[] = [];

        data.forEach((row) => {
            row.forEach((obj) => {
                if (!answers.includes(obj.Answer)) {
                    answers.push(obj.Answer);
                    factors.push(obj.Factor);
                }
            });
        });

        let tableRows: pptxgen.TableRow[] = [];

        answers.forEach((answer, index) => {
            const tableCells: pptxgen.TableCell[] =
                [
                    {
                        text: answer,
                        options: { bold: false }
                    },
                    {
                        text: factors[index],
                        options: { align: "center" }
                    },
                    {
                        text: ''
                    }
                ];

            tableRows.push(tableCells);
        });

        const tableProps: pptxgen.TableProps = {
            x: 0.5, y: 1.38, w: '5%', colW: [3.2, 0.7, 5.1], rowH: 0.37,
            margin: [2, 0, 2, 2],
            align: 'left',
            valign: 'middle',
            autoPageCharWeight: 1.5,
            fontSize: 7,
            border: { type: 'solid', color: this.colors.neutral, pt: 0.2 },
        };

        chartSlide.addTable(tableRows, tableProps);
    }

    private addChart(data: BSByStatementChartDataModel[][], chartSlide: pptxgen.Slide) {
        const scatterData: any[] = [];

        let indexOfValues = 0;
        let numberOfValues = 0;

        data.forEach((row) => {
            numberOfValues += row.length;
        });

        data.forEach((row, rowIndex) => {
            row.forEach((obj) => {

                // x values
                if (scatterData.length === 0) {
                    scatterData.push(
                        {
                            name: "X-Axis",
                            values: Array(numberOfValues).fill(null)
                        }
                    );
                }
                scatterData.forEach((axis) => {
                    if (axis.name === "X-Axis") {
                        axis.values[indexOfValues] = obj.Value;
                    }
                });

                // y values
                let flagMakeNewAxis = true;

                scatterData.forEach((axis) => {
                    if (axis.name === obj.Brand) {
                        axis.values[indexOfValues] = rowIndex + 1;
                        flagMakeNewAxis = false;
                    }
                });

                if (flagMakeNewAxis) {
                    scatterData.push(
                        {
                            name: obj.Brand,
                            values: Array(numberOfValues).fill(null)
                        }
                    );

                    scatterData.forEach((axis) => {
                        if (axis.name === obj.Brand) {
                            axis.values[indexOfValues] = rowIndex + 1;
                        }
                    });
                }

                ++indexOfValues;
            });
        });

        const chartHeight = ((this.getAllByProperty(data, "Answer").length + 1) * 0.37) + 0.43;
        const colors: string[] = this.getAllByProperty(data, "Color");

        const options: pptxgen.IChartOpts = {
            x: 4.22, y: 0.92, w: 5.5, h: chartHeight,
            chartColors: colors,
            showLabel: false,
            catAxisMajorUnit: 10,
            catAxisLabelFrequency: 10,
            valAxisHidden: true,
            catAxisOrientation: 'minMax',
            valAxisOrientation: 'maxMin',
            valAxisMajorUnit: 1,
            lineDataSymbolSize: 12,
            lineSize: 0,
            catAxisMinVal: 0,
            catAxisMaxVal: 10,
            catAxisLabelFontSize: 8,
            catGridLine: { style: 'none' },
            valGridLine: { style: 'none' },
            dataLabelFontSize: 6,
            dataLabelFontBold: true,
        } as any;

        chartSlide.addChart(this.presentation.ChartType.scatter, scatterData, options);
    }

    private addLegend(colors: string[], brands: string[], chartSlide: pptxgen.Slide) {
        for (let i = 0; i < colors.length; ++i) {
            PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: brands[i], color: colors[i] }, { x: 1 + i, y: 5.2 });
        }
    }
}

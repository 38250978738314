import { TableauWorkbookViewModel } from '../../models/Tableau/TableauWorkbookViewModel';
import { WorkbookWithGroupedViews } from '../../models/Tableau/WorkbookWithGroupedViews';
import { AppSettings } from '../../models/AppSettings';

export enum TableauActionTypes {
  SET_WORKBOOKS = 'SET_WORKBOOKS',
  SET_APP_SETTINGS = 'SET_APP_SETTINGS',
  SET_SELECTED_WORKBOOK = 'SET_SELECTED_WORKBOOK',
  SET_WORKBOOKS_WITH_GROUPED_VIEWS = 'SET_WORKBOOKS_WITH_GROUPED_VIEWS',
}

export type TableauActions =
  | ISetWorkbooksAction
  | ISetAppSettingsAction
  | ISetSelectedWorkbookAction
  | ISetWorkbboksWithGroupedViewsAction;

export interface ISetWorkbooksAction {
  type: TableauActionTypes.SET_WORKBOOKS;
  workbooks: TableauWorkbookViewModel[];
}

export const setWorkbooks = (
  workbooks: TableauWorkbookViewModel[],
): ISetWorkbooksAction => ({
  type: TableauActionTypes.SET_WORKBOOKS,
  workbooks,
});

export interface ISetAppSettingsAction {
  type: TableauActionTypes.SET_APP_SETTINGS;
  settings: AppSettings;
}

export const setAppSettings = (
  settings: AppSettings
): ISetAppSettingsAction => ({
  type: TableauActionTypes.SET_APP_SETTINGS,
  settings,
});

export interface ISetSelectedWorkbookAction {
  type: TableauActionTypes.SET_SELECTED_WORKBOOK;
  workbook: TableauWorkbookViewModel | undefined;
}

export const setSelectedWorkbook = (
  workbook: TableauWorkbookViewModel | undefined
): ISetSelectedWorkbookAction => ({
  type: TableauActionTypes.SET_SELECTED_WORKBOOK,
  workbook,
});

export interface ISetWorkbboksWithGroupedViewsAction {
  type: TableauActionTypes.SET_WORKBOOKS_WITH_GROUPED_VIEWS;
  workbooks: WorkbookWithGroupedViews[];
}

export const setWorkbooksWithGroupedViews = (
  workbooks: WorkbookWithGroupedViews[]
): ISetWorkbboksWithGroupedViewsAction => ({
  type: TableauActionTypes.SET_WORKBOOKS_WITH_GROUPED_VIEWS,
  workbooks,
});

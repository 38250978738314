import { IDataMapping } from '../../../../IDataMapping';

export class SRCBSByStatementChartTitleModel implements IDataMapping<SRCBSByStatementChartTitleModel> {
    public Title: string = '';
    public SubTitle: string = '';    
    public Audience: string = '';

    public getMappings?() {
        return {
            Title:      'Brand Strength Analysis by Statement Title',
            SubTitle:   'Brand Strength Analysis Sub-Ttitle',            
            Audience: 'ATTR(Audience_tbl)',
        };
    }
}

import { IDataMapping } from '../../../../IDataMapping';

export class PHBSEmployeeDeepDiveTableLegendModel implements IDataMapping<PHBSEmployeeDeepDiveTableLegendModel> {
    public LegendText:  string = '';
    public Color:       string = '';
    public Order:       number = 0;

    public getMappings?() {
        return {
            LegendText: 'Color Brand Strength Analysis',
            Color:      'ATTR(Color Brand Strength Analysis (hex))',
            Order:      'ATTR(Color Brand Strength Analysis (rank))',
        };
    }
}

import { IDataMapping } from "../../../../IDataMapping";

export class PHBSGlobalStatementPerFactorChartTitleModel implements IDataMapping<PHBSGlobalStatementPerFactorChartTitleModel> {
    public Title:               string = '';
    public SubTitle:            string = '';
    public DataTypeFilter:      string = '';
    public AudienceFilter:      string = '';

    public getMappings?() {
        return {
            Title:              'Brand Strength by Statement per Factor Title',
            SubTitle:           'Brand Strength Analysis Subtitle',
            DataTypeFilter:     'Data Type Global 7',
            AudienceFilter:     'ATTR(Audience)'
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class PurchaseFunnelSampleModel implements IDataMapping<PurchaseFunnelSampleModel> {
    public SampleDate: string = '';
    public SampleMonth: string = '';
    public SampleValue: number = Number(0);
    public Note: string = '';
    public getMappings?() {
        return {
            SampleDate: 'Date',
            SampleMonth: 'Month',
            SampleValue: 'AVG(Count)',
            Note: 'ATTR(NoteRollingDate)',
        };
    }
}

import { CreateRolePermissionModel, getInitialRolePermission } from './CreateRolePermissionModel';

export type CreateRoleModel = {
    name: string | undefined;
    rolePermission: CreateRolePermissionModel;
};

export const getInitialFormRole = (): CreateRoleModel => {
    return {
        name: '',
        rolePermission: getInitialRolePermission()
    };
};

import pptxgen from 'pptxgenjs';

import { BasePptxService } from '../../../BasePptxService';
import { PHBSFirstSlideRenderer } from '../../../Renderers/PHBSFirstSlideRenderer';

import { PHBSTotalOverTimeChartTitleModel } from '../../../../models/PersonalHealth/BrandStrength/PHBSTotalOverTimeChart/PHBSTotalOverTimeChartTitleModel';
import { PHBSTotalOverTimeChartDataModel } from '../../../../models/PersonalHealth/BrandStrength/PHBSTotalOverTimeChart/PHBSTotalOverTimeChartDataModel';

export class PHBSTotalOverTimeChartService extends BasePptxService {
    protected firstSlideRenderer: PHBSFirstSlideRenderer = new PHBSFirstSlideRenderer();
    protected chartQuestion: string = '';
    protected titleSheet: string;
    protected dataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
    }

    // Override of a super class method
    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const titleData = await this.getMappedChartData(PHBSTotalOverTimeChartTitleModel, this.titleSheet);

        let subTitle: string = "";
        titleData.forEach(obj => {
            this.chartTitle = `${obj.Title}`;
            subTitle = obj.SubTitle;
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.55, bold: true });
        slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 12, lineSpacing: 12 } }], { x: 0.6, y: 0.9 });
        slide.addText([{ text: this.chartQuestion, options: { color: this.colors.black, fontSize: 7 } }], { x: 0.5, y: '95%', bold: false });
    }

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const mappedData = await this.getMappedChartData(PHBSTotalOverTimeChartDataModel, this.dataSheet);

        let groupedData = this.groupByProperty(mappedData, "Name").reverse();

        let lineChartData: any[] = [];
        let colors: string[] = [];
        groupedData.forEach((group: PHBSTotalOverTimeChartDataModel[]) => {
            group.sort((a, b) => a.BrandOrder - b.BrandOrder);
            group.sort((a, b) => a.PeriodIndex - b.PeriodIndex);

            let name: string = "";
            let color: string = "";
            let labels: string[] = [];
            let values: number[] = [];

            group.forEach((obj: PHBSTotalOverTimeChartDataModel) => {
                name = obj.Name;
                color = obj.Color;
                labels.push(obj.Label);
                values.push(obj.Value);
            });

            lineChartData.push({ name, labels, values });
            colors.push(color);
        });

        chartSlide.addChart(
            this.presentation.ChartType.line,
            lineChartData,
            {
                x: 1,
                y: 1.2,
                w: "70%",
                h: "70%",
                showLegend: true,
                legendPos: 'b',
                dataLabelPosition: 't',
                catAxisLabelRotate: this.labelRotateDegree(lineChartData),
                chartColors: colors,
                valAxisLabelFontSize: 10,
                catAxisLabelFontSize: 10,
                valAxisMaxVal: 100,
            }
        );
    }

    protected labelRotateDegree(array: any) {
        let degree: number = 0;
        array.forEach((obj: any) => {
            if (obj.labels.length > 10) {
                degree = 45;
            } else {
                degree = 0;
            }
        });
        return degree;
    }

    private groupByProperty(array: PHBSTotalOverTimeChartDataModel[], property: string) {
        const groupData: PHBSTotalOverTimeChartDataModel[][] = [];

        // Grouping the data
        for (let obj of array) {
            let flagMakeNewGroup = true;
            // Checks If there is group/array for this object
            for (let arr of groupData) {
                for (let element of arr) {
                    if (element[property as keyof PHBSTotalOverTimeChartDataModel] === obj[property as keyof PHBSTotalOverTimeChartDataModel]) {
                        arr.push(obj);
                        flagMakeNewGroup = false;
                    }
                    break;
                }
            }
            // Creates new group/array
            if (flagMakeNewGroup) {
                groupData.push([obj]);
            }
        }

        return groupData;
    }
}

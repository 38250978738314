import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { TableauWorkbookViewModel } from '../../../models/Tableau/TableauWorkbookViewModel';
import { IState } from '../../../store/IState';
import styles from './TableauView.module.scss';
import { find, some } from 'lodash';
import { TableauViewViewModel } from '../../../models/Tableau/TableauViewViewModel';
import authService from '../../api-authorization/AuthorizeService';
import { showMessageAction } from '../../../store/layout/LayoutActions';
import { UserDevice } from '../../../shared/enums/UserDevice';
import { setSelectedWorkbook } from '../../../store/tableau/TableauActions';
import { ApplicationUserViewModel } from '../../../models/User/ApplicationUserViewModel';
import { Routes } from '../../../shared/routes';
import history from '../../../history';
import { Toolbar } from '../toolbar/Toolbar';

export const TableauView = (): JSX.Element => {
  let view: any;
  const { viewId } = useParams() as any;
  const dispatch = useDispatch();

  const workbooks: TableauWorkbookViewModel[] = useSelector(
    (state: IState) => state.TableauReducer.workbooks
  );

  const selectedWorkbook: TableauWorkbookViewModel | undefined = useSelector(
    (state: IState) => state.TableauReducer.selectedWorkbook
  );

  const refs = selectedWorkbook
    ? selectedWorkbook.views.map((v) => ({ id: v.id, ref: null }))
    : [];

  const domainLogo = useSelector((state: IState) => state.LayoutReducer.logo);

  const userDevice: UserDevice = useSelector(
    (state: IState) => state.UserReducer.userDevice
  );

  const userProfile: ApplicationUserViewModel | undefined = useSelector(
    (state: IState) => state.UserReducer.userProfile
  );

  const [isLoading, setIsLoading] = useState(true);

  const [shouldPreLoadViews, setShouldPreLoadViews] = useState<boolean>(false);

  const [trustedTicket, setTrustedTicket] = useState<string | undefined>(
    undefined
  );

  const [
    trustedTicketIsRedeemed,
    setTrustedTicketIsRedeemed,
  ] = useState<boolean>(false);

  const [visibleView, setVisibleView] = useState<any>(undefined);

  const getTrustedTicket = async () => {
    if (userProfile && userProfile.email) {
      const token = await authService.getAccessToken();
      const response: Response = await fetch(
        `TableauServer/GetTrustedTicket/${userProfile.email}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` },
        }
      );

      if (response.ok) {
        const ticket: string = await response.text();
        setTrustedTicket(ticket);
      } else {
        const errorMessage = await response.text();
        dispatch(
          showMessageAction({
            severity: 'error',
            summary: 'Error!',
            detail: errorMessage,
            sticky: true,
          })
        );
      }
    }
  };

  const preLoadViews = async () => {
    if (selectedWorkbook) {
      for (const currentRef of refs) {
        const currentView: TableauViewViewModel | undefined = find(
          selectedWorkbook.views,
          (v) => v.id === currentRef.id
        );

        if (currentView && currentRef.ref && currentRef.id) {
          const options = {
            hideTabs: true,
            hideToolbar: true,
            device: userDevice,
          };
          const currentViewRef: any = currentRef.ref;
          const shouldPreloadView: boolean =
            currentViewRef.children && currentViewRef.children.length === 0;

          if (shouldPreloadView) {
            const trustedUrl = currentView.url.replace(
              '/t/',
              `/trusted/${trustedTicket}/t/`
            );
            let url = trustedTicketIsRedeemed ? currentView.url : trustedUrl;
            url = url + "?:render=false";
            try {
              const currentViewContainer = new (window as any).tableau.Viz(
                currentRef.ref,
                url,
                options,
              );
            } catch (ex) {
              //
            }

            if (!trustedTicketIsRedeemed) {
              setTrustedTicketIsRedeemed(true);
            }
          }
        }
      }
    }
  };

  const initView = async () => {
    const wbOfView = find(workbooks, (wb) =>
      some(wb.views, (v) => v.id === viewId)
    );

    if (wbOfView) {
      const viewToBeSet: TableauViewViewModel | undefined = find(
        wbOfView.views,
        (v) => v.id === viewId
      );
      const viewRef = find(refs, (r) => r.id === viewId);

      if (viewToBeSet && viewRef && viewRef.ref) {
        const views = (window as any).tableau.VizManager.getVizs();
        for (const embeddedView of views) {
          embeddedView.hide();
        }

        const vizContainer = viewRef.ref;
        const options = {
          hideTabs: true,
          hideToolbar: true,
          device: userDevice,
          onFirstInteractive: () => {
            if (!shouldPreLoadViews) {
              setShouldPreLoadViews(true);
            }
          },
        };

        const trustedUrl = viewToBeSet.url.replace(
          '/t/',
          `/trusted/${trustedTicket}/t/`
        );
        let url = trustedTicketIsRedeemed ? viewToBeSet.url : trustedUrl;
        url = url + "?:render=false";

        const currentViewRef: any = viewRef.ref;
        const shouldRenderNewView: boolean =
          currentViewRef.children && currentViewRef.children.length === 0;

        if (shouldRenderNewView) {
          view = new (window as any).tableau.Viz(vizContainer, url, options);
          setVisibleView(view);
        } else {
          for (const embeddedView of views) {
            const container = embeddedView.getParentElement();
            if (container && container.id === viewId) {
              setVisibleView(embeddedView);
              embeddedView.show();
            }
          }
        }

        if (!trustedTicketIsRedeemed) {
          setTrustedTicketIsRedeemed(true);
        }
      }
    }

    setIsLoading(false);
  };

  const handleViewChange = () => {
    const views = (window as any).tableau.VizManager.getVizs();
    for (const embeddedView of views) {
      const container = embeddedView.getParentElement();
      if (container && container.id === viewId) {
        setVisibleView(embeddedView);
      }
    }
  };

  useEffect(() => {
    if (trustedTicket) {
      handleViewChange();
    }
  }, [viewId]);

  useEffect(() => {
    if (trustedTicket) {
      initView();
    }
  }, [workbooks, trustedTicket, selectedWorkbook]);

  useEffect(() => {
    if (shouldPreLoadViews) {
      preLoadViews();
    }
  }, [shouldPreLoadViews]);

  useEffect(() => {
    return () => {
      const views = (window as any).tableau.VizManager.getVizs();
      for (const embeddedView of views) {
        embeddedView.dispose();
      }
    };
  }, [selectedWorkbook]);

  useEffect(() => {
    if (!trustedTicket && userProfile) {
      getTrustedTicket();
    }
  }, [userProfile]);

  useEffect(() => {
    if (viewId && !selectedWorkbook) {
      const wbOfView = find(workbooks, (wb) =>
        some(wb.views, (v) => v.id === viewId)
      );
      if (wbOfView) {
        dispatch(setSelectedWorkbook(wbOfView));
      }
    }
  }, [viewId, workbooks]);

  return (
    <React.Fragment>
      {isLoading ? (
        <div className={styles.centered_on_screen}>
          <ProgressSpinner />
        </div>
      ) : undefined}
      <div className={styles.logo_container}>
        <img
          src={domainLogo}
          onError={(e: any) => {
            if (e && e.target && e.target.src) {
              e.target.src = '/images/gemseek.png';
            }
          }}
          alt='Logo'
          className={styles.logo}
          onClick={() => history.push(Routes.workbooks.getPath())}
        />
      </div>
      {visibleView ? <Toolbar view={visibleView} viewId={viewId} /> : undefined}

      {refs.map((r) => (
        <div
          className={styles.viz_container}
          style={viewId !== r.id ? { width: '0', height: '0', marginTop: '0' } : undefined}
          ref={(el: any) => (r.ref = el)}
          key={r.id}
          id={r.id}
        ></div>
      ))}
    </React.Fragment>
  );
};

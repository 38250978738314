import React from 'react';
import { ISVGProps } from './ISVGProps';

export const FileSvg = (props: ISVGProps) => {
  const { width, height } = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 23.839 25.99'
    >
      <defs>
        <style>
          {
            '.a{fill:none;stroke:#212529;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}'
          }
        </style>
      </defs>
      <g transform='translate(1 1)'>
        <path
          className='a'
          d='M16.284,2H6.73A2.581,2.581,0,0,0,4,4.4V23.591a2.581,2.581,0,0,0,2.73,2.4H23.109a2.581,2.581,0,0,0,2.73-2.4V10.4Z'
          transform='translate(-4 -2)'
        />
        <path
          className='a'
          d='M13,2v9.555h9.555'
          transform='translate(-0.716 -2)'
        />
      </g>
    </svg>
  );
};

import pptxgen from 'pptxgenjs';
import { BaseFirstSlideRenderer } from './BaseFirstSlideRenderer';

export class HSStrategicFirstSlideRenderer extends BaseFirstSlideRenderer {
  private options = {
    x: '8%',
    color: this.color,
    fontSize: 11,
  };

  renderFirstSlide(presentation: pptxgen, chartTitle: string): void {
    const firstSlide = presentation.addSlide();
    firstSlide.background = { path: '/assets/pptx/images/Start Slide.jpg' };

    firstSlide.addText('Competitive Brand Health & NPS+', {
      x: '8%',
      y: '50%',
      color: 'ffffff',
      fontSize: 36,
    });

    firstSlide.addText('Marketing Insights & Analysis', { y: '70%', ...this.options });

    firstSlide.addText('Strategic Partnership', { y: '75%', ...this.options });

    firstSlide.addText(chartTitle, { y: '80%', ...this.options });
  }
}

import { IDataMapping } from '../../../../IDataMapping';

export class PHBSMarketAnalysisByBrandTableTitleModel implements IDataMapping<PHBSMarketAnalysisByBrandTableTitleModel> {
    public Title:           string = '';
    public SubTitle:        string = '';
    public AudienceFilter:  string = '';
    public Note:            string = '';

    public getMappings?() {
        return {
            Title:          'Brand Strength Analysis by Brand Title',
            SubTitle:       'Brand Strength Analysis Subtitle',
            AudienceFilter: 'ATTR(Audience)',
            Note:           'ATTR(Note External)',
        };
    }
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from "@material-ui/core/MenuItem";
import Select from '@material-ui/core/Select';

const SingleSelectWrapper = (props: any) => {
  const useStyles = makeStyles({
    labelRoot: {
      background: "white",
      color: "black !important",
    },
    select: {
      "& .MuiFormLabel-root": {
        fontSize: "14px !important",
      },
      "& .MuiOutlinedInput-input": {
        padding: "11px"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#00AAB5"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#00AAB5"
      },
      "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-filled": {
        fontSize: "16px !important",
      },
    }
  });

  const classes = useStyles();

	return (
		<FormControl variant="outlined" fullWidth>
      <InputLabel 
        classes={{ 
          root: classes.labelRoot,
        }}
        id="demo-simple-select-outlined-label">
          {props.label}
        </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={props.value}
        onChange={props.onChange}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        className={classes.select}
      >
        {props.options.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
        ))}
      </Select>
    </FormControl>
	);
};

export default SingleSelectWrapper;

import { IDataMapping } from '../../IDataMapping';

export class UnaidedRecallRadiologyCommModel implements IDataMapping<UnaidedRecallRadiologyCommModel> {
  public Brand: string = '';
  public Segment: string = '';
  public Color: string = '';
  public Order: number | null = -1;
  public Sum: number = 0;
  public Average: number = 0;
  

  public getMappings?() {
    return {
      Brand: 'Brand',
      Segment: 'Segment',
      Color: 'ATTR(Calc Brand Colors)',
      Order: 'ATTR(Calc Brand Order)',
      Sum: 'SUM(%)',
      Average: 'AGG("N= " + STR(AVG([Sample size])))',
    };
  }
}

import { clone } from 'lodash';
import produce from 'immer';
import { ITableauState } from './ITableauState';
import { TableauActions, TableauActionTypes } from './TableauActions';

const initialTableauState: ITableauState = {
  workbooks: [],
  workbooksLoaded: false,
  appSettings: undefined,
  selectedWorkbook: undefined,
  workbooksWithGroupedViews: [],
};

export const TableauReducer: (
  state: ITableauState | undefined,
  action: TableauActions
) => ITableauState = (
  state: ITableauState = initialTableauState,
  action: TableauActions
): ITableauState => {
  let newState: ITableauState = clone(state);
  switch (action.type) {
    case TableauActionTypes.SET_WORKBOOKS:
      newState = produce(state, (draftState: ITableauState) => {
        draftState.workbooks = action.workbooks;
        draftState.workbooksLoaded = true;
      });
      break;
    case TableauActionTypes.SET_APP_SETTINGS:
      newState = produce(state, (draftState: ITableauState) => {
        draftState.appSettings = action.settings;
      });
      break;
    case TableauActionTypes.SET_SELECTED_WORKBOOK:
      newState = produce(state, (draftState: ITableauState) => {
        draftState.selectedWorkbook = action.workbook;
      });
      break;
    case TableauActionTypes.SET_WORKBOOKS_WITH_GROUPED_VIEWS:
      newState = produce(state, (draftState: ITableauState) => {
        draftState.workbooksWithGroupedViews = action.workbooks;
      });
      break;
    default:
      return state;
  }

  return newState;
};

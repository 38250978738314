import { IDataMapping } from '../../../../IDataMapping';

export class RelativeProductNPSChartLegendModel implements IDataMapping<RelativeProductNPSChartLegendModel> {
    public text: string = '';

    public getMappings?() {
        return {
            text: 'Calc Matrix text legend',
        };
    }
}

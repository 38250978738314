import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IState } from '../../../store/IState';

import { IEditDialogProps } from './IEditDialogProps';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import TextFieldWrapper from "../../../shared/UI/TextFieldWrapper";
import AutoCompleteWrapper from "../../../shared/UI/AutoCompleteWrapper";
import MultiSelectWrapper from "../../../shared/UI/MultiSelectWrapper";
import SingleSelectWrapper from "../../../shared/UI/SingleSelectWrapper";

import validateHelper from "../../../shared/UI/validateHelper";

import styles from './EditDialog.module.scss';
import { ModelStateErrorModel } from '../../../models/ModelStateErrorModel';
import { clone, find } from 'lodash';
import { CountryOptions, UserStatusOptions } from '../../../shared/constants';
import { showMessageAction } from '../../../store/layout/LayoutActions';
import { useQueryCountries } from '../hooks/useQueryCountries';
import layoutMessage from '../../../helpers/LayoutMessage';
import api from '../../../helpers/Api';

import {
  setFirstName,
  setLastName,
  setEmail,
  setCompany,
  setDepartment,
  setPosition,
  setSite,
  setUserRoles,
  setUserStatus,
  setCountry,
  resetState
} from "../../../store/addUser/AddUserActions";

export const EditDialog = (props: IEditDialogProps): JSX.Element => {
  const dispatch = useDispatch();

  const {
    isOpen,
    roleOptions,
    selectedUser,
    handleCloseDialog,
    handleRefreshUsers,
  } = props;

  const [errors, setErrors] = useState<ModelStateErrorModel[]>([]);
  const [countryQuery, setCountryQuery] = useState<any>(undefined);
  const [countryValue, setCountryValue] = useState<string>("");
  const countriesResults = useQueryCountries(countryQuery, 400);

  const [validateFirstName, setValidateFirstName] = useState<boolean>(false);
  const [validateLastName, setValidateLastName] = useState<boolean>(false);
  const [validateEmail, setValidateEmail] = useState<boolean>(false);
  const [validateCompany, setValidateCompany] = useState<boolean>(false);
  const [validateUserRoles, setValidateUserRoles] = useState<boolean>(false);

  const AddUserReducer: any = useSelector(
    (state: IState) => state.AddUserReducer
  );

  const onCountryChange = (country: any) => {
    if(country === null) {
      setCountryQuery("");
      dispatch(setCountry(0));
    } else {
      if (typeof country === 'string') {
        setCountryQuery(country);
      } else {
        setCountryQuery(country.label);
        setCountryValue(country);
        dispatch(setCountry(country.value));
      }
    }
  };

  const onEditUserSuccess = () => {
    handleCloseDialog();
    handleRefreshUsers();
    dispatch(
      showMessageAction({
        severity: 'success',
        summary: 'Success!',
        detail: 'User Updated!',
        life: 3000,
      })
    );
  };

  const onModelStateErrors = (errorObjects: any) => {
    setErrors(errorObjects);
  };

  const editUser = async () => {
    dispatch(setFirstName(AddUserReducer.firstName));
    setValidateFirstName(true);
    dispatch(setLastName(AddUserReducer.lastName));
    setValidateLastName(true);
    dispatch(setEmail(AddUserReducer.email));
    setValidateEmail(true);
    dispatch(setCompany(AddUserReducer.company));
    setValidateCompany(true);
    dispatch(setSite(AddUserReducer.site));
    dispatch(setUserRoles(AddUserReducer.userRoles));
    setValidateUserRoles(true);

    const getBooleansFromReducer = Object.fromEntries(
      Object.entries(AddUserReducer).filter(([key, value]) => typeof value === "boolean") 
    )
    const isError = Object.keys(getBooleansFromReducer).some(k => getBooleansFromReducer[k]);

    if (isError) {
      return false
    } else {
      let userReducer = clone(AddUserReducer);
      Object.assign(userReducer, {id: selectedUser.id});
      api.post(
        'Users/Edit',
        JSON.stringify(userReducer),
        onEditUserSuccess,
        onModelStateErrors,
        (errorMessage) => layoutMessage.renderError(dispatch, errorMessage)
      );
    }
  };

  const onDialogHide = () => {
    dispatch(resetState(''));
    handleCloseDialog();
    setErrors([]);
  };

  const getErrors = () => {
    return (
      <div>
        {errors.map((e) =>
          e.errors.map((m) => <p className={styles.error_text}>{m}</p>)
        )}
      </div>
    );
  };

  const formFields = (
    <>
      {errors.length > 0 ? getErrors() : undefined}
      <div className={styles.form_row}>
        <div className={styles.form_col}>
          <TextFieldWrapper
            value={AddUserReducer.firstName}
            label="First Name*"
            onChange={(e: any) => {
              dispatch(setFirstName(e.target.value));
              setValidateFirstName(true);
            }}
            error={AddUserReducer.firstNameBool && validateFirstName}
            helperText={validateHelper('firstName', AddUserReducer.firstNameBool, validateFirstName)}
          />
        </div>
        <div className={styles.form_col}>
          <TextFieldWrapper
            value={AddUserReducer.lastName}
            label="Last Name*"
            onChange={(e: any) => {
              dispatch(setLastName(e.target.value));
              setValidateLastName(true);
            }}
            error={AddUserReducer.lastNameBool && validateLastName}
            helperText={validateHelper('lastName', AddUserReducer.lastNameBool, validateLastName)}
          />
        </div>
      </div>
      <div className={styles.form_row}>
        <div className={styles.form_col}>
          <TextFieldWrapper
            value={AddUserReducer.email}
            label="Email/Username*"
            onChange={(e: any) => {
              dispatch(setEmail(e.target.value));
              setValidateEmail(true);
            }}
            error={AddUserReducer.emailBool && validateEmail}
            helperText={validateHelper('email', AddUserReducer.emailBool, validateEmail)}
          />
        </div>
        <div className={styles.form_col}>
          <TextFieldWrapper
            value={AddUserReducer.company}
            label="Company*"
            onChange={(e: any) => {
              dispatch(setCompany(e.target.value));
              setValidateCompany(true);
            }}
            error={AddUserReducer.companyBool && validateCompany}
            helperText={validateHelper('company', AddUserReducer.companyBool, validateCompany)}
          />
        </div>
      </div>
      <div className={styles.form_row}>
        <div className={styles.form_col}>
          <TextFieldWrapper
            value={AddUserReducer.department}
            label="Department"
            onChange={(e: any) => {
              dispatch(setDepartment(e.target.value));
            }}
          />
        </div>
        <div className={styles.form_col}>
          <TextFieldWrapper
            value={AddUserReducer.position}
            label="Job Title"
            onChange={(e: any) => {
              dispatch(setPosition(e.target.value));
            }}
          />
        </div>
      </div>
      <div className={styles.form_row}>
        <div className={styles.form_col}>
          <AutoCompleteWrapper
            options={countriesResults}
            value={countryValue}
            onChange={onCountryChange}
            setValue={setCountryValue}
            label="Country"
          />
        </div>
        <div className={styles.form_col}>
          <MultiSelectWrapper
            htmlFor="userRoles"
            label="User Roles*"
            value={AddUserReducer.userRoles}
            onError={AddUserReducer.userRolesBool}
            validateError={validateUserRoles}
            validateHelper={validateHelper}
            roleOptions={roleOptions}
            setValidate={setValidateUserRoles}
            onChange={(event:React.ChangeEvent<{ value: any }>) => {
              dispatch(setUserRoles(event.target.value));
              setValidateUserRoles(true);
            }}
          />
        </div>
      </div>
      <div className={styles.form_row}>
        <div className={styles.form_col}>
          <TextFieldWrapper
            value={AddUserReducer.site}
            label={AddUserReducer.userStatus === 1 ? 'Site*' : 'Site'}
            onChange={(e: any) => {
              dispatch(setSite(e.target.value));
            }}
            error={AddUserReducer.siteBool}
            helperText={validateHelper('site', AddUserReducer.siteBool, null)}
          />
        </div>
        <div className={styles.form_col}>
          <SingleSelectWrapper
            label="Status"
            value={AddUserReducer.userStatus}
            onChange={(event:React.ChangeEvent<{ value: any }>) => {
              dispatch(setUserStatus(event.target.value));
            }}
            options={UserStatusOptions}
          />
        </div>
      </div>
    </>
  );

  const footer = (
    <div className={styles.button_group}>
      <Button
        label={'CANCEL'}
        onClick={onDialogHide}
        className={styles.cancel_button}
      />
      <Button
        label={'SAVE'}
        onClick={() => editUser()}
        className={styles.save_button}
      />
    </div>
  );

  useEffect(() => {
    dispatch(setFirstName(selectedUser.firstName));
    dispatch(setLastName(selectedUser.lastName));
    dispatch(setEmail(selectedUser.email));
    dispatch(setCompany(selectedUser.company));
    dispatch(setDepartment(selectedUser.department));
    dispatch(setPosition(selectedUser.position));
    dispatch(setCountry(selectedUser.country));
    dispatch(setUserRoles(selectedUser.userRoles));
    dispatch(setSite(selectedUser.site));
    dispatch(setUserStatus(selectedUser.userStatus));

    if (selectedUser && selectedUser.country) {
      const countryOption = find(
        CountryOptions,
        (c) => c.value === selectedUser.country
      );
      onCountryChange(countryOption);
    } 
  }, []);

  return (
    <React.Fragment>
      {selectedUser ? (
        <div>
          <Dialog
            onHide={onDialogHide}
            visible={isOpen}
            header={'Edit User'}
            footer={footer}
            className={styles.default_dialog}
          >
            {formFields}
          </Dialog>
        </div>
      ) : undefined}
    </React.Fragment>
  );
};

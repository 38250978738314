import { IDataMapping } from "../../../IDataMapping";

export class PHBSFactorOverTimeChartTitleModel implements IDataMapping<PHBSFactorOverTimeChartTitleModel> {
    public Title:           string = '';
    public SubTitle:        string = '';
    public BrandFilter:     string = '';
    public AudienceFilter:  string = '';

    public getMappings?() {
        return {
            Title:          'Brand Strength per Factor Over Time Title',
            SubTitle:       'Brand Strength Analysis Subtitle',
            BrandFilter:    'ATTR(Brand)',
            AudienceFilter: 'ATTR(Audience)'
        };
    }
}

import pptxgen from 'pptxgenjs';
import { PreferenceOverTimeDataModel } from '../../../models/PersonalHealth/PreferenceOverTime/PreferenceOverTimeDataModel';
import { PreferenceOverTimeTitleModel } from '../../../models/PersonalHealth/PreferenceOverTime/PreferenceOverTimeTitleModel';

import { PHFirstSlideRenderer } from '../../Renderers/PHFirstSlideRenderer';
import { BasePptxService } from '../../BasePptxService';

import { filterInitDuplicateData, addEmptyValuesToInitData } from "../../../../shared/utility";

export class PreferenceOverTimeService extends BasePptxService {
  protected chartQuestion: string = '';
  protected firstSlideRenderer: PHFirstSlideRenderer = new PHFirstSlideRenderer();
  protected titleSheet: string;
  protected dataSheet: string;

  constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
      super(view, chartTitle);
      this.titleSheet = titleSheet;
      this.dataSheet = dataSheet;
  }

  protected async setChartSlideLayout(slide: pptxgen.Slide) {
    // Override title's options and add filter's name in it.
    const titleData = await this.getMappedChartData(PreferenceOverTimeTitleModel, this.titleSheet);
    let subTitle: string = "";
    
    titleData.map( obj => {
      this.chartTitle = obj.Title;
      return subTitle = obj.SubTitle;
    });
    slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.5, y: 0.35, bold: true });
    slide.addText([{ text: subTitle, options: { color: this.colors.default, fontSize: 16 } }], { x: 0.5, y: 0.58, italic: true });
  }

  protected async addChartSlide(chartSlide: pptxgen.Slide) {
    const mappedData = await this.getMappedChartData(PreferenceOverTimeDataModel, this.dataSheet);
    
    const newArr: any = [];
    const lineColors: string[] = [];
    const getAllLabels: any = [];

    // Sorting the quarters
    const sortedLineChartDataByTime = mappedData.sort(function (a: any, b: any) {
      return a.TimeRank - b.TimeRank;
    });
    
    sortedLineChartDataByTime.forEach((obj: any) => {
      getAllLabels.push(obj.Label);
    });

    const distinctedLabels = getAllLabels.filter(this.distinctRepeatables);

    // Sorting the names/answers
    const sortedLineChartData = mappedData.sort(function (a: any, b: any) {
      const nameA = a.GlobalName ? a.GlobalName : a.Name;
      const nameB = b.GlobalName ? b.GlobalName : b.Name;
      return nameB.localeCompare(nameA);
    }); 

    let filteredData = filterInitDuplicateData(sortedLineChartData);

    filteredData.forEach((obj: any) => {
      lineColors.push(obj.Color)
      let item: any;
      
      if (obj.GlobalName) {
        item = newArr.find((item: any) => item.name === obj.GlobalName);
      } else {
        item = newArr.find((item: any) => item.name === obj.Name);
      }
      if (newArr.length > 0 && item) {
        item.values.push(parseFloat(obj.Value));
        item.labels.push(obj.Label);
      } else {
        newArr.push(
          {
            name: obj.GlobalName ? obj.GlobalName : obj.Name,
            labels: [obj.Label],
            values: [parseFloat(obj.Value)],
          }
        )
      }
    })

    const finalChartData = addEmptyValuesToInitData(distinctedLabels, newArr);
    chartSlide.addChart(
      this.presentation.ChartType.line,
      finalChartData,
      {
        x: 1,
        y: 1,
        w: "70%",
        h: "70%",
        showLegend: true,
        legendPos: 'b',
        dataLabelPosition: 't',
        dataLabelFormatCode: '0%',
        valAxisLabelFormatCode: '0%',
        catAxisLabelRotate: this.labelRotateDegree(newArr),
        chartColors: lineColors.filter(this.distinctRepeatables),
        valAxisLabelFontSize: 10,
        catAxisLabelFontSize: 10,
      }
    );
  }

  protected labelRotateDegree(array: any) {
    let degree: number = 0;
    array.forEach((obj: any) => {
      if (obj.labels.length > 10) {
        degree = 45;
      } else {
        degree = 0;
      }
    });
    return degree;
  }

  private distinctRepeatables = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };
}

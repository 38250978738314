import React, { useEffect, useState } from 'react';
import styles from './HelpCenterContainer.module.scss';
import { VideosComponent } from './VideosComponent/VideosComponent';
import { DocumentsTable } from './DocumentsTable/DocumentsTable';
import api from '../../helpers/Api';
import { FolderTree } from '../../models/FolderTree';
import { useDispatch } from 'react-redux';
import layoutMessage from '../../helpers/LayoutMessage';
import { FileModel } from '../../models/FileModel';
import { ProgressSpinner } from 'primereact/progressspinner';

export const HelpCenterContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [playerIsOpen, setPlayerIsOpen] = useState<boolean>(false);

  const [folderTree, setFolderTree] = useState<FolderTree | undefined>(
    undefined
  );

  const [openedVideo, setOpenedVideo] = useState<FileModel | undefined>(
    undefined
  );

  const handleTogglePlayer = (isOpen: boolean) => {
    setPlayerIsOpen(isOpen);
  };

  const handleSetVideo = (video: FileModel) => {
    setOpenedVideo(video);
  };

  const getFilesAndFolders = async () => {
    const folderTreeResponse = await api.get(
      'Files/GetTree',
      (result: FolderTree) => {
        setFolderTree(result);
        setIsLoading(false);
      },
      (errorMessage) => {
        setIsLoading(false);
        layoutMessage.renderError(dispatch, errorMessage)
      }
    );
  };

  useEffect(() => {
    getFilesAndFolders();
  }, []);

  if (isLoading) {
    return (
      <div className={styles.centered_on_screen}>
        <ProgressSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className={styles.help_center_container}>
        {playerIsOpen && openedVideo ? (
          <VideosComponent
            playerIsOpen={playerIsOpen}
            video={openedVideo}
            handleTogglePlayer={handleTogglePlayer}
          />
        ) : undefined}

        <DocumentsTable
          folderTree={folderTree}
          handleTogglePlayer={handleTogglePlayer}
          handleSetVideo={handleSetVideo}
        />
      </div>
    </React.Fragment>
  );
};

import { IDataMapping } from '../../IDataMapping';

export class CommonPieChartDataModel implements IDataMapping<CommonPieChartDataModel> {
    public Label: string = '';
    public Name: string = '';
    public Value: number = Number(0);
    public Color: string = '';
    public getMappings?() {
        return {
            Label: "indicator_name",
            Name:  "indicator_name",
            Value: "SUM(indicator_value)",
            Color: "Donut colors",
        };
    }
}

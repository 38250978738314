import { IDataMapping } from '../../IDataMapping';

export class NineGLineChartRnRDataModel implements IDataMapping<NineGLineChartRnRDataModel> {
    public Label: string = '';
    public Name: string = '';
    public Value: number = Number(0);
    public Rank: number = Number(0);
    public AverageRating: number = Number(0);
    public NumberOfReviews: number = Number(0);
    public AverageRatingColor: string = '';
    public NumberOfReviewsColor: string = '';
    public getMappings?() {
        return {
            Label: 'QUARTER(Date_calc (R&R/MS))',
            Name: 'Measure Names',
            Value: 'SUM(R&R Base Metric)',
            Rank: 'SUM(R&R Kpi)',
            AverageRating: 'MAX(R&R Kpi)',
            NumberOfReviews: 'MAX(R&R Base Metric)',
            AverageRatingColor: 'Rating color',
            NumberOfReviewsColor: 'Review color',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class PurchaseFunnelTitleModel implements IDataMapping<PurchaseFunnelTitleModel> {
    public Title: string = '';
    public TitlePPT: string = '';
    public Category: string = '';
    public QuarterTitle: string = '';
    public SubTitle: string = '';
    public SubTitlePer: string = '';
    public Comment: string = '';
    public SecondComment: string = '';
    public MonthTitle: string = '';
    public getMappings?() {
        return {
            Title: 'PH_Title Export',
            TitlePPT: 'PH_Title',
            Category: 'Category',
            QuarterTitle: 'Date',
            SubTitle: 'JapTitle3',
            SubTitlePer: 'Title Japan',
            Comment: "Comments",
            SecondComment: '●',
            MonthTitle: 'Date',
        };
    }
}

import React from 'react';
import { ISVGProps } from './ISVGProps';

export const TilesSvg = (props: ISVGProps) => {
  const { width, height } = props;
  return (
    <svg
      stroke={'currentColor'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox={`0 0 30 30`}
    >
      <defs>
        <style>
          {'.a{stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}'}
        </style>
      </defs>
      <g transform='translate(1 1)'>
        <g transform='translate(-3 -3)'>
          <rect
            className='a'
            width='11'
            height='11'
            transform='translate(3 3)'
          />
          <rect
            className='a'
            width='11'
            height='11'
            transform='translate(20 3)'
          />
          <rect
            className='a'
            width='11'
            height='11'
            transform='translate(20 20)'
          />
          <rect
            className='a'
            width='11'
            height='11'
            transform='translate(3 20)'
          />
        </g>
      </g>
    </svg>
  );
};

import { IDataMapping } from '../../IDataMapping';

export class CategoryUserProfileDataModel implements IDataMapping<CategoryUserProfileDataModel> {
    public Label: string = '';
    public Name: string = '';
    public Value: number = Number(0);
    public Color: string = '';
    public getMappings?() {
        return {
            Label: "Indicator Name",
            Name: "Indicator Name",
            Value: "SUM(Indicator value)",
            Color: "Donut Colours",
        };
    }
}

import { IDataMapping } from "../../../../IDataMapping";

export class SRCBSByStatementOverTimeChartDataModel implements IDataMapping<SRCBSByStatementOverTimeChartDataModel> {
    public Label:   string = '';
    public Name:    string = '';
    public Value:   number = Number(0);
    public Rank:    number = Number(0);
    public Color:   string = '';

    public getMappings?() {
        return {
            Label:  'QUARTER(Date)',
            Name:   'Answer',
            Value:  'AVG(Value)',
            Rank:   'MIN(index_period)',
            Color:  'statement_color_code',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class FirstChoiceTTLTitleModel implements IDataMapping<FirstChoiceTTLTitleModel> {
    public Title: string = '';
    public getMappings?() {
        return {
            Title: "1stChoiceTTLTitle",
        };
    }
}

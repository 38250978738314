import { IDataMapping } from '../../../IDataMapping';

export class SRCBSTotalChartDataModel implements IDataMapping<SRCBSTotalChartDataModel> {
    public Color: string = '';
    public KPIValue: string = '';
    public ValuePositive: string = '';
    public ValueZero: string = '';
    public ValueNegative: string = '';

    public getMappings?() {
        return {
            Color:          'KPI Shapes Color Brand Strength',
            KPIValue:       'SUM(KPI Value Brand Strength)',
            ValuePositive:  'vs. MAT/vs. Target ⯅ Brand Strength',
            ValueZero:      'vs. MAT/vs. Target ⯈ Brand Strength',
            ValueNegative:  'vs. MAT/vs. Target ⯆ Brand Strength',
        };
    }
}

import { IDataMapping } from '../../IDataMapping';

export class ClinicalAttributeModel  implements IDataMapping<ClinicalAttributeModel>{
    public Answer: string = '';
    public Segment: string = '';
    public Importance: number | null = 0;
    public Advantage: number | null = 0;
    public Average: number | null = 0;

    public getMappings?() {
        return {
            Answer: 'Answer',
            Segment: 'Prm Segment',
            Importance: 'SUM(Attribute Importance)',
            Advantage: 'SUM(Competitive Advantage)',
            Average: "AGG(Sample Size Color <50 BLACK)"
        };
    }
}

import pptxgen from 'pptxgenjs';

import { SPVendorPieChartTitleModel } from '../../../../../models/HealthSystems/StrategicPartnership/ClinicalSegment/SPVendorPieChart/SPVendorPieChartTitleModel';
import { SPVendorPieChartDataModel } from '../../../../../models/HealthSystems/StrategicPartnership/ClinicalSegment/SPVendorPieChart/SPVendorPieChartDataModel';

import { HSStrategicFirstSlideRenderer } from '../../../../Renderers/HSStrategicFirstSlideRenderer';
import { BasePptxService } from '../../../../BasePptxService';
import { PptxRenderHelper } from '../../../../../helpers/PptxRenderHelper';

export class SPVendorPieChartService extends BasePptxService {
    protected chartQuestion: string = "Q: And within [Clinical Segment] domain which vendor would you consider as your partner?";
    protected firstSlideRenderer: HSStrategicFirstSlideRenderer = new HSStrategicFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
    }

    // Override of a super class method
    protected async setChartSlideLayout(slide: pptxgen.Slide) {
        const mappedTitleData = await this.getMappedChartData(SPVendorPieChartTitleModel, this.titleSheet);

        mappedTitleData.forEach(obj => {
            this.chartTitle = `${obj.Title} ${obj.Country} - ${obj.Segment} - ${obj.Period}`;
        });

        slide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.55, bold: true });
        slide.addText([{ text: this.chartQuestion, options: { color: this.colors.black, fontSize: 7 } }], { x: 0.5, y: '95%', bold: false });
    }

    // Override of a super class method
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        await this.addPieChart(chartSlide);
    }

    private async addPieChart(chartSlide: pptxgen.Slide) {
        const mappedChartData = await this.getMappedChartData(SPVendorPieChartDataModel, this.dataSheet);

        if (mappedChartData.length > 0) {

            // Getting the sample data from object with an AverageNegative value or an AveragePositive value
            let sampleData = 0;
            mappedChartData.forEach((obj) => {
                if (obj.AverageNegative) {
                    sampleData = obj.AverageNegative;
                } else if (obj.AveragePositive) {
                    sampleData = obj.AveragePositive;
                }
            })

            // Chart methodology for sample values under 50
            chartSlide.addText([{ text: `N = ${sampleData}`, options: { color: sampleData < 50 ? this.colors.negative : this.colors.default, fontSize: 14, align: 'center', valign: 'middle' } }], { x: 3, y: 1.5, w: 3.15, h: 3, bold: false });


            // Creating an array without the object with null brand
            const filteredChartData = mappedChartData.filter((obj) => obj.Brand);

            const lineColors = PptxRenderHelper.getMappedChartDataColorsSP(filteredChartData);

            const dataChartPieStat = [
                {
                    name: this.chartTitle,
                    labels: filteredChartData.map(x => x.Brand),
                    values: filteredChartData.map(x => (Math.round(x.Sum ? (x.Sum * 100) : 0)))
                }
            ];

            chartSlide.addChart(this.presentation.ChartType.doughnut, dataChartPieStat,
                {
                    x: 3, y: 1.5, w: 4.5, h: 3,
                    layout: { x: 0, y: 0, w: 0.7, h: 1 },
                    holeSize: 80,
                    chartColors: lineColors,
                    dataBorder: { pt: 0.5, color: 'F1F1F1' },
                    showValue: true,
                    showPercent: false,
                    dataLabelFormatCode: '#\\%',
                    dataLabelPosition: 'outEnd',
                    dataLabelColor: "FFFFFF",
                    dataLabelFontSize: 6,
                    showLegend: true,
                    legendFontSize: 8,
                    legendPos: 'r',
                }
            );
        };
    };
}

import { IDataMapping } from '../../../../IDataMapping';

export class PHBSMarketPerformanceChartTitleModel implements IDataMapping<PHBSMarketPerformanceChartTitleModel> {
    public Title:               string = '';
    public SubTitle:            string = '';
    public FilterAudience:      string = '';
    public FilterPerformance:   string = '';

    public getMappings?() {
        return {
            Title:              'KPI Titles',
            SubTitle:           'Brand Strength Analysis Subtitle',
            FilterAudience:     'ATTR(Audience)',
            FilterPerformance:  'Performance',
        };
    }
}

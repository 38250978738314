import {
  applyMiddleware,
  combineReducers,
  createStore,
  Middleware,
  Store,
} from 'redux';
import immutableCheckMiddleWare from 'redux-immutable-state-invariant';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { UserReducer } from './user/UserReducer';
import { LayoutReducer } from './layout/LayoutReducer';
import { TableauReducer } from './tableau/TableauReducer';
import { AddUserReducer } from './addUser/AddUserReducer';

const loggerMiddleware: Middleware = createLogger();

const middlewares = [loggerMiddleware, immutableCheckMiddleWare()];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

export function configureStore(): any {
  const nextGenStore: Store<any, any> = createStore(
    combineReducers({ UserReducer, LayoutReducer, TableauReducer, AddUserReducer }),
    {},
    composedEnhancers
  );

  return nextGenStore;
}

import { IDataMapping } from '../../../../IDataMapping';

export class CAImportanceChartDataModel implements IDataMapping<CAImportanceChartDataModel> {
    public Answer: string = '';
    public Importance: number | null = 0;
    public Advantage: number | null = 0;
    public getMappings?() {
        return {
            Answer: 'Answer',
            Importance: 'SUM(Attribute Importance)',
            Advantage: 'SUM(Competitive Advantage)',
        };
    }
}

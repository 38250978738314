import { IDataMapping } from '../../../../IDataMapping';

export class PHBSEmployeeDeepDiveTableTitleModel implements IDataMapping<PHBSEmployeeDeepDiveTableTitleModel> {
    public Title:       string = '';
    public Note:        string = '';
    public DataType:    string = '';

    public getMappings?() {
        return {
            Title:      'Employee Factors by Statement Title',
            Note:       'ATTR(Note Internal)',
            DataType:   'ATTR(Factor Employee 1)'
        };
    }
}

import { IDataMapping } from "../../../../IDataMapping";

export class NPSTrendsLineChartTitleModel implements IDataMapping<NPSTrendsLineChartTitleModel> {
    public Title: string = '';
    public BG: string = '';
    public Country: string = '';
    public Modality: string = '';
    public Submodality: string = '';
    public getMappings?() {
        return {
            Title: 'Title_NPS Trends',
            BG: 'Business group',
            Country: 'Country (copy)',
            Modality: 'Modality',
            Submodality: 'Submodality',
        };
    }
}

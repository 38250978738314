import { IDataMapping } from '../../IDataMapping';

export class FamiliarityTitleModel implements IDataMapping<FamiliarityTitleModel> {
    public Title: string = '';
    public SubTitle: string = '';
    public getMappings?() {
        return {
            Title: "IPL Page Titles",
            SubTitle: "IPL Page Sub Titles",
        };
    }
}

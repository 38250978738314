import { IDataMapping } from '../../IDataMapping';

export class FirstChoiceTitleModel implements IDataMapping<FirstChoiceTitleModel> {
    public Title: string = '';
    public SubTitle: string = '';
    public getMappings?() {
        return {
            Title: "1stChoice%Title",
            SubTitle: '1stChoice%Title (2)',
        };
    }
}

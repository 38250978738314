import { IDataMapping } from '../../IDataMapping';

export class CompetitorsGloballyNPSFooterDataModel implements IDataMapping<CompetitorsGloballyNPSFooterDataModel> {
    public Survey: string = '';
    public TextFooter: string = '';

    public getMappings?() {
        return {
            Survey: "Survey",
            TextFooter: "Text in legend (NPS)",
        };
    }
}

export type ApplicationUserViewModel = {
    id: string;
    userName: string;
    email: string;
    firstName: string;
    lastName: string;
    position: string;
    department: string;
    userStatus: string;
    country: string;
    site: string;
    userRoles: RoleViewModel[];
};

type RoleViewModel = {
    id: string;
    name: string;
    rolePermission: RolePermissionViewModel;
};

export type RolePermissionViewModel = {
    usersSection: RoleActions;
    userRolesSection: RoleActions;
    systemLogSection: RoleActions;
    faqSection: RoleActions;
    trainingsSection: RoleActions;
};

export enum RoleActions {
    None = 0,
    View = 1,
    Add = 2,
    Edit = 4,
    Delete = 8
}

import React from 'react';
import { ISVGProps } from './ISVGProps';

export const EllipsisSvg = (props: ISVGProps) => {
  const { width, height } = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 4 18'
    >
      <defs>
        <style>
          {
            '.a{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}'
          }
        </style>
      </defs>
      <g transform='translate(-10 -3)'>
        <circle
          className='a'
          cx='1'
          cy='1'
          r='1'
          transform='translate(11 11)'
        />
        <circle className='a' cx='1' cy='1' r='1' transform='translate(11 4)' />
        <circle
          className='a'
          cx='1'
          cy='1'
          r='1'
          transform='translate(11 18)'
        />
      </g>
    </svg>
  );
};

import { IDataMapping } from '../../../../IDataMapping';

export class UnaidedRecallSegmentCommunicationsChartSampleModel implements IDataMapping<UnaidedRecallSegmentCommunicationsChartSampleModel> {
    public Segment: string = '';
    public SamplePositive: number = 0;
    public SampleNegative: number = 0;

    public getMappings?() {
        return {
            Segment: 'Segment',
            SamplePositive: 'AGG(Sample Size Color <50 BLACK)',
            SampleNegative: 'AGG(Sample Size Color <50 RED)',
        };
    }
}

import pptxgen from 'pptxgenjs';

import { BasePptxService } from '../../../../BasePptxService';
import { HSFirstSlideRenderer } from '../../../../Renderers/HSFirstSlideRenderer';
import { PptxRenderHelper } from '../../../../../helpers/PptxRenderHelper';

import { RelativeProductNPSChartDataModel } from '../../../../../models/HealthSystems/ProductLevelNPS/Overview/RelativeProductNPSChart/RelativeProductNPSChartDataModel';
import { RelativeProductNPSChartLegendModel } from '../../../../../models/HealthSystems/ProductLevelNPS/Overview/RelativeProductNPSChart/RelativeProductNPSChartLegendModel';
import { RelativeProductNPSChartFooterModel } from '../../../../../models/HealthSystems/ProductLevelNPS/Overview/RelativeProductNPSChart/RelativeProductNPSChartFooterModel';

/**
 * Note: If there is a row or a column completely empty - without any values, it won't be shown on
 *  the ppt table. That's because the incoming data is holding information only for the cells with
 *  values. And so the service doesn't know if the empty cells exist.
 */
export class RelativeProductNPSChartService extends BasePptxService {
    protected chartQuestion: string = 'NPS question: Based on your experience on using their product and services, how likely are you to recommend this company?';
    protected firstSlideRenderer = new HSFirstSlideRenderer();
    protected titleSheet: string;
    protected dataSheet: string;
    protected legendSheet: string;
    protected footerSheet: string;

    private colorsByValue: any = {
        'Leader': this.colors.positive,
        'Neutral': this.colors.white,
        'Follower': this.colors.negative
    };

    private bullets = {
        Up: { code: '2B08', color: '1a660f' },
        Down: { code: '2B0A', color: 'bf0103' }
    };

    private arrowsByValue: any = {
        '1': this.bullets.Up,
        '-1': this.bullets.Down
    };

    constructor(view: any, chartTitle: string, titleSheet: string, dataSheet: string, legendSheet: string, footerSheet: string) {
        super(view, chartTitle);
        this.titleSheet = titleSheet;
        this.dataSheet = dataSheet;
        this.legendSheet = legendSheet;
        this.footerSheet = footerSheet;
    }

    // Override of a super class method
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const mappedChartData = await this.getMappedChartData(RelativeProductNPSChartDataModel, this.dataSheet);
        const mappedLegendData = await this.getMappedChartData(RelativeProductNPSChartLegendModel, this.legendSheet);
        const mappedFooterData = await this.getMappedChartData(RelativeProductNPSChartFooterModel, this.footerSheet);

        if (mappedChartData.length > 0) {

            // separating the data
            const valueData = mappedChartData.filter((obj) => obj.value !== null);
            const arrowData = mappedChartData.filter((obj) => obj.value === null);

            // adding arrow values
            if (arrowData.length > 0) {
                arrowData.forEach((arrowObj) => {
                    valueData.forEach((valueObj) => {
                        if (arrowObj.businessGroup === valueObj.businessGroup && arrowObj.modality === valueObj.modality &&
                            arrowObj.submodality === valueObj.submodality && arrowObj.country === valueObj.country) {
                            valueObj.arrowValue = arrowObj.arrowValue;
                        }
                    });
                });
            }

            // tables
            const headerData = this.getHeaderTableRow(valueData);

            const countries = this.getCountries(valueData)
            const numberOfCountries = countries.length;

            const groupData = this.groupDataByRows(valueData);

            const tables = this.splitRowsByTables(groupData);

            // options
            const colW: number[] = [1.5, 1.5, 1, ...Array(numberOfCountries).fill(0.5)];
            const tableWidth = colW.reduce((a: number, b: number) => a + b, 0);
            const x = (10 - tableWidth) / 2;

            const options: pptxgen.TableProps = {
                x,
                y: 1,
                w: tableWidth,
                align: 'center',
                valign: 'middle',
                border: { type: 'solid', pt: 0.5, color: this.colors.border },
                fontSize: 7,
                rowH: 0.2,
                autoPage: true,
                autoPageRepeatHeader: true,
                newSlideStartY: 0.8,
                autoPageCharWeight: 1,
                margin: [0, 0, 0, 5],
                colW
            };

            // execution
            tables.forEach((table, index) => {
                const currentSlide = this.getCurrentSlide(chartSlide, index);
                const bodyData = this.getBodyTableRows(table, numberOfCountries, countries);

                currentSlide.addTable([...headerData, ...bodyData], options);
                this.addLegendAndFooter(currentSlide, x, mappedLegendData, mappedFooterData, arrowData.length);
            });
        }
    }

    private getCurrentSlide(chartSlide: pptxgen.Slide, index: number) {
        let currentSlide = chartSlide;

        if (index !== 0) {
            currentSlide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });
            this.setChartSlideLayout(currentSlide);
        }

        return currentSlide;
    }

    private groupDataByRows(data: RelativeProductNPSChartDataModel[]) {
        // sorting by rows
        data.sort((a, b) => a.rowOrder - b.rowOrder)

        const groupData: RelativeProductNPSChartDataModel[][] = [];

        // Grouping the data in rows
        for (let obj of data) {
            let flagMakeNewGroup = true;
            // Checks If there is group/array for this object
            for (let arr of groupData) {
                for (let element of arr) {
                    if (element.rowOrder === obj.rowOrder) {
                        arr.push(obj);
                        flagMakeNewGroup = false;
                    }
                    break;
                }
            }
            // Creates new group/array
            if (flagMakeNewGroup) {
                groupData.push([obj]);
            }
        }

        // sorting by columns
        groupData.forEach(arr => {
            arr.sort((a, b) => a.columnOrder - b.columnOrder);
        })

        return groupData;
    }

    private getCountries(data: RelativeProductNPSChartDataModel[]) {
        data.sort((a, b) => a.columnOrder - b.columnOrder)

        const countries: string[] = [];

        data.forEach(obj => {
            if (!countries.includes(obj.country)) {
                countries.push(obj.country);
            }
        });

        return countries;
    }

    private getHeaderTableRow(data: RelativeProductNPSChartDataModel[]) {
        const countries = ["Business group", "Modality", "SubModality", ...this.getCountries(data)];

        const tableRow: pptxgen.TableRow[] = [];
        const tableCells: pptxgen.TableCell[] = [];

        // header row
        countries.forEach((countryName: string) => {
            tableCells.push(
                {
                    text: countryName,
                    options: {
                        bold: true,
                        fill: { color: this.colors.default },
                        color: this.colors.white,
                        border:
                            [
                                { type: 'solid', pt: 0.5, color: this.colors.border },
                                { type: 'solid', pt: 0.5, color: this.colors.white },
                                { type: 'solid', pt: 0.5, color: this.colors.border },
                                { type: 'solid', pt: 0.5, color: this.colors.white },
                            ]
                    }
                }
            );
        });
        tableRow.push(tableCells);

        return tableRow;
    }

    private getBodyTableRows(groupData: RelativeProductNPSChartDataModel[][], numberOfCountries: number, countries: any) {
        let tableRows: pptxgen.TableRow[] = [];

        let usedBusinessGroup: string;
        let usedModality: string;

        groupData.forEach((row, index) => {
            let currentBusinessGroup: string = "";
            let currentModality: string = "";
            let currentSubModality: string = "";

            // array initialized with default/empty values, which will be filled with real values later
            const values: pptxgen.TableCell[] = Array(numberOfCountries).fill(
                {
                    text: "",
                    options: { margin: [0, 3, 0, 0], color: this.colors.black, fill: { color: this.colors.white }, align: 'center', bullet: false }
                }
            );

            // value rows
            row.forEach(obj => {
                if (obj.value || obj.value === 0) {

                    // bullet
                    if (obj.arrowValue) {
                        values[countries.indexOf(obj.country)] =
                        {
                            text: `${(obj.value * 100).toFixed()}%` !== '-0%' ? `${(obj.value * 100).toFixed()}%` : '0%',
                            options: {
                                bullet: { characterCode: this.arrowsByValue[obj.arrowValue].code, indent: 15 },
                                margin: [0, 3, 0, 0], color: this.arrowsByValue[obj.arrowValue].color, fill: { color: this.colorsByValue[obj.leadership] }, align: 'right'
                            }
                        }
                    } else {
                        values[countries.indexOf(obj.country)] =
                        {
                            text: `${(obj.value * 100).toFixed()}%` !== '-0%' ? `${(obj.value * 100).toFixed()}%` : '0%',
                            options: { margin: [0, 3, 0, 0], color: this.colors.black, fill: { color: this.colorsByValue[obj.leadership] }, align: 'right' }
                        }
                    }
                }

                currentBusinessGroup = obj.businessGroup;
                currentModality = obj.modality;
                currentSubModality = obj.submodality;
            });

            // Business group, modality, submodality
            if (tableRows.length === 0) {

                // first row only
                const rowspanBG = this.countRowspan(groupData, index, "businessGroup")
                const rowspanModality = this.countRowspan(groupData, index, "modality")
                const tableCells: pptxgen.TableCell[] =
                    [
                        {
                            text: currentBusinessGroup,
                            options: { fill: { color: this.colors.white }, align: 'left', rowspan: rowspanBG }
                        },
                        {
                            text: currentModality,
                            options: { fill: { color: this.colors.white }, align: 'left', rowspan: rowspanModality }
                        },
                        {
                            text: currentSubModality,
                            options: { fill: { color: this.colors.white }, align: 'left' }
                        },
                        ...values
                    ];

                tableRows.push(tableCells);

                usedBusinessGroup = currentBusinessGroup;
                usedModality = currentModality;

            } else {
                let tableCells: pptxgen.TableCell[] = [];

                if (currentBusinessGroup !== usedBusinessGroup) {
                    const rowspan = this.countRowspan(groupData, index, "businessGroup")
                    tableCells.push(
                        {
                            text: currentBusinessGroup,
                            options: { fill: { color: this.colors.white }, align: 'left', rowspan }
                        }
                    );
                }

                if (currentModality !== usedModality) {
                    const rowspan = this.countRowspan(groupData, index, "modality")
                    tableCells.push(
                        {
                            text: currentModality,
                            options: { fill: { color: this.colors.white }, align: 'left', rowspan }
                        }
                    );
                }

                tableCells.push(
                    {
                        text: currentSubModality,
                        options: { fill: { color: this.colors.white }, align: 'left' }
                    },
                    ...values
                );

                tableRows.push(tableCells);

                usedBusinessGroup = currentBusinessGroup;
                usedModality = currentModality;
            }
        });

        return tableRows;
    }

    private countRowspan(groupData: RelativeProductNPSChartDataModel[][], currentIndex: number, objectKey: string) {
        let rowspan = 1;

        let currentGroup;
        groupData[currentIndex].forEach(obj => {
            currentGroup = obj[objectKey as keyof RelativeProductNPSChartDataModel];
        })

        for (let row = (currentIndex + 1); row < groupData.length; ++row) {
            let nextGroup;
            groupData[row].forEach(obj => {
                nextGroup = obj[objectKey as keyof RelativeProductNPSChartDataModel];
            })

            if (currentGroup === nextGroup) {
                ++rowspan;
            }
        }

        return rowspan;
    }

    private splitRowsByTables(groupData: RelativeProductNPSChartDataModel[][]) {
        const tables: RelativeProductNPSChartDataModel[][][] = [];

        // full tables
        const rowsPerTable = 15;
        const numberOfFullTables = Math.trunc(groupData.length / rowsPerTable);
        for (let i = 0; i < numberOfFullTables; ++i) {
            tables.push(groupData.slice((i * rowsPerTable), (i * rowsPerTable) + rowsPerTable));
        }

        // last smaller table
        const lastTable = groupData.length % rowsPerTable;
        if (lastTable) {
            tables.push(groupData.slice((groupData.length - lastTable), groupData.length));
        }

        return tables;
    }

    private addLegendAndFooter(chartSlide: pptxgen.Slide, optionX: number, mappedLegendData: RelativeProductNPSChartLegendModel[], mappedFooterData: RelativeProductNPSChartFooterModel[], arrowDataLength: number) {
        // legend
        let legend: string | pptxgen.TextProps[] = [];
        mappedLegendData.forEach((obj: RelativeProductNPSChartLegendModel) => {
            legend = [{ text: `${obj.text}`, options: { breakLine: true } }]
        });
        chartSlide.addText(legend, { x: 6.6, y: 4.9, w: 6.5, fontSize: 8, color: this.colors.default });

        PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: `Neutral`, color: this.colorsByValue.Neutral }, { x: 6.7, y: 4.4 });
        PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: `Leader`, color: this.colorsByValue.Leader }, { x: 7.7, y: 4.4 });
        PptxRenderHelper.renderShapeSquareLegend(this.presentation, chartSlide, { label: `Follower`, color: this.colorsByValue.Follower }, { x: 8.7, y: 4.4 });

        // arrow legend
        if (arrowDataLength > 0) {
            let text: string | pptxgen.TextProps[] =
                [
                    {
                        text: 'Philips shows improved leadership status since last FY period (is no longer Follower or has become Leader)',
                        options: { bullet: { characterCode: this.bullets.Up.code, indent: 15 }, color: this.bullets.Up.color, breakLine: true }
                    },
                    {
                        text: 'Philips shows deteriorated leadership status since last FY period (is no longer Leader or has become Follower',
                        options: { bullet: { characterCode: this.bullets.Down.code, indent: 15 }, color: this.bullets.Down.color, breakLine: true }
                    },
                ];
            chartSlide.addText(text, { x: optionX, y: 4.5, w: 6.5, fontSize: 8, color: this.colors.default, bold: false });
        }

        // footer
        let footer: string | pptxgen.TextProps[] = [];
        mappedFooterData.forEach((obj: RelativeProductNPSChartFooterModel) => {
            footer = [{ text: `${obj.text}`, options: { breakLine: true } }]
        });
        chartSlide.addText(footer, { x: optionX, y: 5, w: 6.5, fontSize: 8, color: this.colors.default, bold: true });
    }
}

import { IDataMapping } from '../../../IDataMapping';

export class PHBSDeepDiveTitleModel implements IDataMapping<PHBSDeepDiveTitleModel> {
    public TitleMarket:     string = '';
    public TitleAudiences:  string = '';
    public SubTitle:        string = '';
    public Audience:        string = '';
    public Market:          string = '';
    public Filter:          string = '';

    public getMappings?() {
        return {
            TitleMarket:    'Deep Dive by Markets Title',
            TitleAudiences: 'Deep Dive by Audiences Title',
            SubTitle:       'Deep Dive Subtitle',
            Audience:       'ATTR(Audience)',
            Market:         'ATTR(Country / Market)',
            Filter:         'Total Brand Strength'
        };
    }
}

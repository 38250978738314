import pptxgen from 'pptxgenjs';
import { ClinicalAttributeModel } from '../../../';
import { TableauApiHelper } from '../../../helpers/TableauApiHelper';
import { BaseChartOptions, ChartPercentageOptions } from '../../../philips-constants';
import { BasePptxService } from '../../BasePptxService';
import { HSStrategicFirstSlideRenderer } from '../../Renderers/HSStrategicFirstSlideRenderer';

export class ClinicalAttributePptxService extends BasePptxService {
    protected chartQuestion: string = 'Please divide 100 points across these categories according to your willingness to invest in each one of them.' +
        'You can give one category all 100 points, or divide them as you please.';

    protected textFontSize: number = 10;
    protected firstSlideRenderer: HSStrategicFirstSlideRenderer = new HSStrategicFirstSlideRenderer();
    protected chartColors = ['0078C1', '26AB99', 'B7D02F', 'FFDD7E', 'F7A0B3', 'E0D0E5'];
    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const data = await this.getMappedChartData(ClinicalAttributeModel, 'Areas of Focus');
        const sampleSizeData = await this.getMappedChartData(ClinicalAttributeModel, 'Areas of Focus TEXT Sample Size');
        if (!data || !data.length) {
            return;
        }

        await this.addLayout(chartSlide, data, sampleSizeData);
        this.addChart(data, chartSlide);
    }

    private async addLayout(chartSlide: pptxgen.Slide, data: ClinicalAttributeModel[], sampleSizeData: ClinicalAttributeModel[]) {
        const segment = data.firstOrNull(d => d.Segment !== null)?.Segment;
        const brand = await TableauApiHelper.getFilterOptions(this.view, 'Areas of Focus', 'Brand');
        chartSlide.addText(`${brand} ·  ${segment}`, { x: 1, y: 1, fontSize: this.textFontSize, color: this.colors.default, align: 'right', bold: true });
        
        const average = sampleSizeData.firstOrNull(d => d.Average !== null);
        if (average) {
            chartSlide.addText(`N=${average.Average}`, { x: 1, y: 1, fontSize: this.textFontSize, color: this.colors.default, align: 'left', bold: true  });
        }

        const line = { color: this.colors.default, width: 0.5 };
        chartSlide.addShape(this.presentation.ShapeType.line, { x: 1.3, y: 2.9, w: 8, h: 0, line });
        chartSlide.addShape(this.presentation.ShapeType.line, { x: 3.7, y: 2.9, w: 3, h: 0, rotate: 90, line });

        const firstRow = 1.4;
        const secondRow = 4.2;
        const firstCol = 1.2;
        const secondCol = 8.6;
        chartSlide.addText('Maintain', { x: firstCol, y: firstRow, color: 'f68620', fontSize: this.textFontSize });
        chartSlide.addText('Leverage', { x: secondCol, y: firstRow, color: '469438', fontSize: this.textFontSize });
        chartSlide.addText('Observe', { x: firstCol, y: secondRow, color: 'ea2c31', fontSize: this.textFontSize });
        chartSlide.addText('Improve', { x: secondCol, y: secondRow, color: '7e0f6a', fontSize: this.textFontSize });
    }

    private addChart(data: ClinicalAttributeModel[], chartSlide: pptxgen.Slide) {
        data.reverse();
        const chartData = [
            { name: 'X-Axis', values: data.map(d => d.Importance) },
            ...this.getChartSeries(data)
        ];
        const advantageValues = data.map(d => Number(Number(d.Advantage).toFixed(2)));
        const valAxisMinVal = Math.min(...advantageValues) - 0.2;
        const valAxisMaxVal = Math.max(...advantageValues) + 0.2;
        const maxVal = Math.max(Math.abs(Number(valAxisMinVal.toFixed(1))), Math.abs(Number(valAxisMaxVal.toFixed(1))));

        const options: pptxgen.IChartOpts = {
            ...BaseChartOptions,
            ...ChartPercentageOptions,
            x: 0.5, y: 1.4, w: 9, h: 3.7,
            chartColorsOpacity: 80,
            chartColors: this.chartColors,
            showCatAxisTitle: true,
            catAxisTitle: 'Attribute Importance',
            catAxisMinVal: 0,
            catAxisMaxVal: 1,
            catAxisLineShow: false,
            showValAxisTitle: true,
            valAxisTitle: 'Competitive Advantage',
            valAxisMinVal: -1 * maxVal,
            valAxisMaxVal: maxVal,
            valAxisLineShow: false,
            showLabel: true,
            lineSize: 0,
            showLegend: true,
            legendPos: 'b',
            valAxisCrossesAt: -0.5
        };
        chartSlide.addChart(this.presentation.ChartType.scatter, chartData, options);
    }

    private getChartSeries(data: ClinicalAttributeModel[]) {
        const initial: Array<number | null> = data.map(d => null);
        return data.map((d, i) => {
            const values: Array<number | null> = [...initial];
            values[i] = d.Advantage;
            return {
                name: d.Answer,
                values
            };
        });
    }
}

import { LineData, LegendData } from '../';
import { BasePptxService } from './BasePptxService';
import { difference } from 'lodash';

export abstract class ChartPptxService extends BasePptxService {
    protected chartQuestion: string =
        'NPS question: Based on your experience on using their product and services, how likely are you to recommend this company?' +
        '\n' +
        'Brand Preference question: Among the brands that you would consider, what would be your first choice when choosing [Product] and related services for your hospital?';

    protected lineColors: string[] = [];

    protected processChartData(
        sheetData: any[],
        indexes: {
            brandIndex: number,
            yearIndex: number,
            valueIndex: number,
            tokensIndex: number
        }): LineData[] {
        const processedData: any[] = [];
        const { brandIndex, yearIndex, valueIndex, tokensIndex } = indexes;
        const chartLabels: string[] = [];

        sheetData.forEach((rowArray: any[]) => {
            const brand: string = rowArray[brandIndex].formattedValue;
            const year: string = rowArray[yearIndex].value;
            const value: string | null = rowArray[valueIndex].value !== '' ? rowArray[valueIndex].value : null;

            if (!chartLabels.includes(year)) {
                chartLabels.push(year);
            }

            let current = processedData.find(x => x.name === brand);
            if (!current) {
                current = {
                    name: brand,
                    rank: '',
                    points: { [year]: value },
                }

                processedData.push(current);
            }

            if (!Object.keys(current.points).find(key => key === year)) {
                Object.assign(current.points, { [year]: value });
            }

            const tokensString: string = rowArray[tokensIndex].formattedValue;
            if (tokensString !== '' && tokensString !== 'Null') {
                const tokens = tokensString.split('|');
                current.rank = tokens[0];
                current.color = tokens[2];
            }
        });

        processedData.sort((a, b) => a.rank.localeCompare(b.rank));

        return processedData.map(current => {
            if (!current.color) {
                current.color = '#FFF000';
            }

            if (!current.rank) {
                current.rank = '99999';
            }

            if (!this.lineColors.includes(current.color)) {
                this.lineColors.push(current.color);
            }

            let labels = Object.keys(current.points);
            const diff = difference(chartLabels, labels);

            if (diff.length > 0) {
                diff.forEach(k => current.points[k] = null);
            }

            labels = Object.keys(current.points);
            let values: Array<string | null> = Object.values(current.points);
            values = values.map(x => x === 'Null' || x === 'null' ? null : x);

            return {
                name: current.name,
                labels,
                values,
            }
        });
    }

    protected processLegendData(
        sheetData: any[],
        indexes: { valueIndex: number, tokensStringIndex: number }
    ): LegendData[] {
        const processedData: any[] = [];
        const { valueIndex, tokensStringIndex } = indexes;
        sheetData.forEach(current => {
            const value = current[valueIndex].value?.replace(/\.0+/, '');
            const tokensString: string = current[tokensStringIndex].value;
            const splittedTokens = tokensString.split('|');

            const [rank, brand, color] = splittedTokens.length === 3 ? splittedTokens : ['9999', '########', '#000000']; //TODO:

            processedData.push({ rank, brand, color, value });
        });

        processedData.sort((a, b) => a.rank.localeCompare(b.rank));

        const legendData: LegendData[] = [];
        processedData.forEach(current => {
            legendData.push(
                { text: `${current.brand} `, options: { color: current.color, fontSize: 8 } },
                { text: `(${current.value})    `, options: { color: this.colors.default, fontSize: 8 } }
            );
        });

        return legendData;
    }
}

import { IDataMapping } from '../../IDataMapping';

export class SingleSPMarketOverviewModel implements IDataMapping<SingleSPMarketOverviewModel>{
    public Country: string = '';
    public Brand: string = '';
    public Sum: number | null = 0;
    public AveragePositive: number | null = 0;
    public AverageNegative: number | null = 0;
    public Order: number | null = 0;
    public BrandOrder: number | null = 0;
    public Color: string = '';

    public getMappings?() {
        return {
            Country: 'Country',
            Brand: 'Calc Others Bars',
            Sum: 'SUM(%)',
            AveragePositive: 'AGG(Sample Size Color <50 BLACK)',
            AverageNegative: 'AGG(Sample Size Color <50 RED)',
            Order: 'ATTR(Calc Country Order)',
            BrandOrder: 'ATTR(Calc Brand Order)',
            Color: 'ATTR(Calc Brand Colors)'
        };
    }
}
import { IDataMapping } from '../../IDataMapping';

export class SummaryTitleModel implements IDataMapping<SummaryTitleModel> {
    public Title: string = '';
    public Market: string = '';
    public QuarterDate: string = '';
    public getMappings?() {
        return {
            Title: 'SummaryTitle',
            Market: 'Market',
            QuarterDate: 'Date',
        };
    }
}

import authService from '../components/api-authorization/AuthorizeService';
import { ConflictStatusCode } from '../shared/constants';
import { ModelStateErrorModel } from '../models/ModelStateErrorModel';

export class Api {
  public async get<T>(
    endpoint: string,
    onSuccess: (data: T) => void,
    onFail?: (error: string, status?: number) => void
  ) {
    const token = await authService.getAccessToken();
    const response: Response = await fetch(endpoint, {
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    });

    if (response.ok) {
      const data: T = await response.json();
      onSuccess(data);
    } else if (onFail) {
      const errorMessage = await response.text();
      onFail(errorMessage, response.status);
    }
  }

  public async post(
    endpoint: string,
    body:
      | string
      | Blob
      | ArrayBufferView
      | ArrayBuffer
      | FormData
      | URLSearchParams
      | ReadableStream<Uint8Array>
      | null
      | undefined,
    onSuccess?: () => void,
    onModelStateErrors?: (modelStateErrors: ModelStateErrorModel[]) => void,
    onFail?: (error: string) => void
  ) {
    const token = await authService.getAccessToken();
    const requestHeaders: Headers = new Headers();
    requestHeaders.append('Accept', 'application/json; text/javascript, */*');
    requestHeaders.append('Content-Type', 'application/json; charset=UTF-8');
    requestHeaders.append('Authorization', `Bearer ${token}`);
    const response = await fetch(endpoint, {
      method: 'POST',
      body,
      headers: requestHeaders,
    });
    if (response.ok && onSuccess) {
      onSuccess();
    } else if (response.status === ConflictStatusCode && onModelStateErrors) {
      const responseErrors = await response.json();
      const errorKeys: string[] = Object.keys(
        responseErrors.errors ? responseErrors.errors : responseErrors
      );
      const errorObjects: ModelStateErrorModel[] = [];
      for (const errorKey of errorKeys) {
        if (responseErrors.errors) {
          errorObjects.push({
            key: errorKey,
            errors: responseErrors.errors[errorKey].map((e: any) => e),
          });
        } else if (
          responseErrors[errorKey] &&
          responseErrors[errorKey].errors
        ) {
          errorObjects.push({
            key: errorKey,
            errors: responseErrors[errorKey].errors.map(
              (e: any) => e.errorMessage
            ),
          });
        } else {
          errorObjects.push({
            key: errorKey,
            errors: [
              'Something went wrong. Please contact your server administrator.',
            ],
          });
        }
      }

      onModelStateErrors(errorObjects);
    } else if (onFail) {
      const errorMessage = await response.text();
      onFail(errorMessage);
    }
  }
}

const api = new Api();

export default api;

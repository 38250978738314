import React from 'react';
import { ISearchControlProps } from './ISearchControlProps';
import styles from './SearchControl.module.scss';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

export const SearchControl: React.FC<ISearchControlProps> = ({ value, onChange }: ISearchControlProps): JSX.Element => {
  return (
    <div className={styles.search_input_container}>
      <div className='p-inputgroup'>
        <InputText
          onChange={onChange}
          value={value}
          onFocus={({ target }) => target.placeholder = ''}
          onBlur={({ target }) => target.placeholder = 'Search...'}
          placeholder='Search...'
          className={styles.search_input}
        />
        <Button icon='pi pi-search' className={styles.default_button} />
      </div>
    </div>
  );
};

import { IDataMapping } from '../../IDataMapping';

export class BMCPreferenceScoreCountryDataModel implements IDataMapping<BMCPreferenceScoreCountryDataModel> {
    public Text: string = '';
    public Value: string = '';
    public getMappings?() {
        return {
            Text: 'Country_title',
            Value: 'AVG(Value (Parameter))',
        };
    };
};

import pptxgen from 'pptxgenjs';
import { HSFirstSlideRenderer } from '../../Renderers/HSFirstSlideRenderer';
import { PptxRenderHelper } from '../../../helpers/PptxRenderHelper';
import { BaseChartOptions } from '../../../philips-constants';
import { ChartPptxService } from '../../ChartPptxService';
import { LegendModel } from '../../../models/LegendModel';

export class NpsOverviewPptxService extends ChartPptxService {
    protected firstSlideRenderer = new HSFirstSlideRenderer();
    protected chartQuestion: string =
        `NPS question: Based on your experience on using their product and services, how likely are you to recommend this company?`;

    protected lineColors: string[] = [];
    private colorsByValue = {
        'Detractor': this.colors.negative,
        'Passive': this.colors.neutral,
        'Promoters': this.colors.positive,
    }

    protected async addChartSlide(chartSlide: pptxgen.Slide) {
        const lineChartSheetData = await this.getChartData('Country_Trend Line');
        const legendSheetData = await this.getChartData('Country_Trend Line (legend)');
        const barChartSheetData = await this.getChartData('Country_Trend Stacked bar NPS+Leadership');

        if (lineChartSheetData && legendSheetData) {
            let lineChartData = super.processChartData(
                lineChartSheetData,
                { brandIndex: 1, yearIndex: 2, valueIndex: 6, tokensIndex: 3 }
            );

            chartSlide.addChart(this.presentation.ChartType.line, lineChartData, {
                ...BaseChartOptions,
                x: 1,
                y: 1.1,
                w: 8,
                h: 3.5,
                showValue: true,
                dataLabelPosition: 't',
                dataLabelFormatCode: '0%',
                valAxisLabelFormatCode: '0%',
                catAxisLineShow: false,
                valAxisLineShow: false,
                valAxisMajorUnit: 0.2,
                chartColors: this.lineColors,
                dataLabelColor: this.colors.default,
                dataLabelFontSize: 10,
            });

            let fixedValueLegendData = legendSheetData.map((arr: any) => {
                return arr.map((obj: any) => { return { ...obj } })
            });

            fixedValueLegendData.forEach((arr: any) => {
                arr[13].value = parseFloat(arr[13].value).toFixed(0);
            });

            const legendData = super.processLegendData(
                fixedValueLegendData, { valueIndex: 13, tokensStringIndex: 7 }
            );

            chartSlide.addText(
                legendData,
                { x: 1, y: '85%' }
            );
        }

        if (barChartSheetData.length > 0) {
            const barChartData = this.processBarChartData(barChartSheetData);
            this.addSecondChart(barChartData);
        }
    }

    private addSecondChart(data: any) {
        const chartSlide = this.presentation.addSlide({ masterName: 'PHILIPS_MASTER' });

        chartSlide.addChart(this.presentation.ChartType.bar, data.chartData, {
            ...BaseChartOptions,
            x: 2.5,
            y: 2,
            w: 5.7,
            h: 2,
            barDir: 'bar',
            barGrouping: 'stacked',
            showValue: true,
            valAxisLineShow: false,
            chartColors: [this.colorsByValue['Promoters'], this.colorsByValue['Passive'], this.colorsByValue['Detractor'], this.colors.white],
            dataLabelFormatCode: '0%',
            valAxisHidden: true,
            catAxisLabelFontSize: 8,
            dataLabelFontSize: 12,
            dataLabelFontBold: true,
            dataLabelColor: this.colors.white
        });

        chartSlide.addText([{ text: this.chartTitle, options: { color: this.colors.default, fontSize: 18 } }], { x: 0.6, y: 0.55, bold: true });
        chartSlide.addText([{ text: this.chartQuestion, options: { fontSize: 7 } }], { x: 0.5, y: '95%' });

        const legendDataArray = Object.entries(this.colorsByValue).map(([label, color]) => (
            { label: `% ${label}`, color } as LegendModel
        ));

        legendDataArray.forEach((legendData, index) => {
            PptxRenderHelper.renderSquareLegend(chartSlide, legendData, { x: 2.5 + index * 0.9, y: 4.7 });
        });

    }

    private processBarChartData(data: any[]) {
        const dataMatrix: { order: number, data: (string | null)[] }[] = [];
        const labels: { order: number, label: string }[] = [];

        let leader: string = '';

        for (const rowArray of data) {
            const brand = rowArray[0].value;

            const parseNull = (value: string) => {
                return value !== 'null' ? value : null;
            }

            const rank = rowArray[4].value;
            const detractor = parseNull(rowArray[5].value);
            const passive = parseNull(rowArray[6].value);
            const promoters = parseNull(rowArray[7].value);
            const sampleSize = parseNull(parseFloat(rowArray[8].value).toFixed(0));
            const value = parseNull(rowArray[10].value);
            const status = rowArray[1].value !== '%null%' ? rowArray[1].value : null;

            if (status) {
                leader = `${brand} - ${status}`;
            }

            if (detractor === null
                && passive === null
                && promoters === null
                && value === null
            ) {
                continue;
            }

            const percent = Math.round(parseFloat(value ?? '') * 100) + '%';
            labels.push({ order: rank, label: `${brand} (${sampleSize})\r\n\ ${percent}` });

            dataMatrix.push({ order: rank, data: [promoters, passive, detractor] });
        }

        labels.sort((kv1, kv2) => {
            return kv2.order - kv1.order;
        });

        dataMatrix.sort((kv1, kv2) => {
            return kv2.order - kv1.order;
        });

        const chartData: any[] = [];
        dataMatrix[0].data.forEach(() => chartData.push({ labels: labels.map(l => l.label), values: [] }));

        for (let rowIndex = 0; rowIndex < dataMatrix.length; rowIndex++) {
            const matrixRow = dataMatrix[rowIndex].data;

            for (let colIndex = 0; colIndex < matrixRow.length; colIndex++) {
                chartData[colIndex].values[rowIndex] = matrixRow[colIndex];
            }
        }

        return { chartData, leader };
    }
}
import React, { useEffect, useState } from 'react';
import { IToolbarProps } from './IToolbarProps';
import styles from './Toolbar.module.scss';
import { CustomViewContainer } from '../custom-views-dialog/CustomViewContainer';
import { TableauSheetType } from '../../../shared/enums/TableauSheetType';
import { FilterSvg } from '../../common/Svgs/FilterSvg';
import { PDFSvg } from '../../common/Svgs/PDFSvg';
import { RevertSvg } from '../../common/Svgs/RevertSvg';
import { CSVSvg } from '../../common/Svgs/CSVSvg';
import { PPTSvg } from '../../common/Svgs/PPTSvg';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { ApplicationUserViewModel } from '../../../models/User/ApplicationUserViewModel';
import { IState } from '../../../store/IState';
import { TableauWorkbookViewModel } from '../../../models/Tableau/TableauWorkbookViewModel';
import { find } from 'lodash';
import { CustomDownloadPptDialog } from '../custom-download-ppt-dialog/CustomDownloadPptDialog';
import { TableauApiHelper } from '../../../philips/helpers/TableauApiHelper';
import { PhilipsEnvironments, PersonalHealthPhilipsEnvironments, SrcPhilipsEnvironments } from '../../../shared/constants';

export const Toolbar = (props: IToolbarProps): JSX.Element => {
  const { view, viewId } = props;
    const customPptDownloadHostnames = [...Object.values(PhilipsEnvironments), ...Object.values(PersonalHealthPhilipsEnvironments), ...Object.values(SrcPhilipsEnvironments) ];

  const userProfile: ApplicationUserViewModel | undefined = useSelector(
    (state: IState) => state.UserReducer.userProfile
  );

  const selectedWorkbook: TableauWorkbookViewModel | undefined = useSelector(
    (state: IState) => state.TableauReducer.selectedWorkbook
  );

  const host: string = useSelector(
    (state: IState) => state.LayoutReducer.host
  );

  const selectedView = selectedWorkbook
    ? find(selectedWorkbook.views, (v) => v.id === viewId)
    : undefined;

  const [customViewDialogIsOpen, setCustomViewDialogIsOpen] = useState<boolean>(
    false
  );

  const [csvDialogIsOpen, setCsvDialogIsOpen] = useState<boolean>(false);

  const [selectedViewSheet, setSelectedViewSheet] = useState<
    string | undefined
  >(undefined);

  const [viewWidth, setViewWidth] = useState<string>('');

  const [pptDialogIsOpen, setPptDialogIsOpen] = useState<boolean>(false);

  const getCustomViews = async () => {
    if (view) {
      const viewSize = view.getVizSize();
      if (viewSize && viewSize.sheetSize && viewSize.sheetSize.maxSize) {
        setViewWidth(`${viewSize.sheetSize.maxSize.width}px`);
      }
    }
  };

  const exportToPdf = () => {
    if (view) {
      view.showExportPDFDialog();
      window.scrollTo(0, window.screen.height / 2);
    }
  };

  const exportToPowerPoint = () => {
    if (view) {
      view.showExportPowerPointDialog();
      window.scrollTo(0, window.screen.height / 2);
    }
  };

  const clearFilters = () => {
    if (view) {
      view.revertAllAsync();
    }
  };

  const getViewSheetsOptions = (): Array<{ label: string; value: string }> => {
    const result: Array<{ label: string; value: string }> = [];
    if (view) {
      try {
        const workbook = view.getWorkbook();
        const sheet = workbook.getActiveSheet();
        const sheetType = sheet.getSheetType();

        if (sheetType === TableauSheetType.Dashboard) {
          const sheets = sheet.getWorksheets();
          for (const currentSheet of sheets) {
            const sheetName = currentSheet.getName();
            result.push({ label: sheetName, value: sheetName });
          }
        } else if (sheetType === TableauSheetType.Story) {
          const activeStoryPoint = sheet.getActiveStoryPoint();
          const containedSheet = activeStoryPoint.getContainedSheet();
          const storySheets = containedSheet.getWorksheets();
          for (const currentSheet of storySheets) {
            const sheetName = currentSheet.getName();
            result.push({ label: sheetName, value: sheetName });
          }
        }
      } catch (ex) {
        return result;
      }
    }
    return result;
  };

  const onSelectedSheetChange = (e: any) => {
    setSelectedViewSheet(e.value);
  };

  const handleCSVDownload = async () => {
    if (view && selectedViewSheet) {
      const dataTable = await TableauApiHelper.getDataTable(view, selectedViewSheet);
      let csvContent = 'data:text/csv;charset=utf-8,';
      const columns = dataTable.getColumns();
      const headerRow = columns.map((c: any) => c.getFieldName()).join(',');

      csvContent += headerRow + '\r\n';
      dataTable.getData().forEach((rowArray: any[], index: number) => {
        const row = rowArray.map((r) => r.formattedValue).join(',');
        csvContent += row + '\r\n';
      });

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.href = encodedUri;
      link.style.display = 'none';
      link.download = `${selectedViewSheet}.csv`;
      document.body.appendChild(link);

      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      setTimeout(() => {
        link.remove();
      }, 100);

      setSelectedViewSheet(undefined);
      setCsvDialogIsOpen(false);
    }
  };

  useEffect(() => {
    if (view) {
      getCustomViews();
    }
  }, [view]);

  return (
    <React.Fragment>
      {view ? (
        <div className={styles.top_container}>
          <div className={styles.toolbar_container}>
            <div
              className={styles.action_button}
              onClick={(event) => {
                setCustomViewDialogIsOpen(true);
              }}
            >
              <i>
                <FilterSvg width={'24'} height={'22'} />
              </i>
              <span className={styles.action_label}>Quick Filters</span>
            </div>
            <div className={styles.action_button} onClick={clearFilters}>
              <i>
                <RevertSvg width={'24'} height={'22'} />
              </i>
              <span className={styles.action_label}>Reset Filters</span>
            </div>
            <div
              className={styles.action_button}
              onClick={customPptDownloadHostnames.includes(host)
                ? () => setPptDialogIsOpen(true)
                : exportToPowerPoint}
            >
              <i>
                <PPTSvg width={'24'} height={'22'} />
              </i>
              <span className={styles.action_label}>Download PPT</span>
            </div>
            <div className={styles.action_button} onClick={exportToPdf}>
              <i>
                <PDFSvg width={'24'} height={'22'} />
              </i>
              <span className={styles.action_label}>Download PDF</span>
            </div>
            {!customPptDownloadHostnames.includes(host)
              ? (<div
                className={styles.action_button}
                onClick={() => setCsvDialogIsOpen(true)}
              >
                <i>
                  <CSVSvg width={'24'} height={'22'} />
                </i>
                <span className={styles.action_label}>Download CSV</span>
              </div>)
              : null}
          </div>
        </div>
      ) : undefined}
      {customViewDialogIsOpen && view ? (
        <CustomViewContainer
          view={view}
          handleRefreshCustomViews={getCustomViews}
          handleDialogClose={() => setCustomViewDialogIsOpen(false)}
        />
      ) : undefined}
      {view ? (
        <Dialog
          header='Download CSV'
          visible={csvDialogIsOpen}
          onHide={() => {
            setSelectedViewSheet(undefined);
            setCsvDialogIsOpen(false);
          }}
          className={styles.csv_dialog}
          blockScroll={true}
        >
          <Dropdown
            className={styles.csv_dropdown}
            placeholder={'Select Sheet'}
            appendTo={document.body}
            value={selectedViewSheet}
            options={getViewSheetsOptions()}
            onChange={(e) => onSelectedSheetChange(e)}
          ></Dropdown>
          <div className={styles.csv_download}>
            <Button
              onClick={handleCSVDownload}
              label={'Download'}
              className={styles.csv_download_button}
              disabled={!selectedViewSheet}
            />
          </div>
        </Dialog>
      ) : undefined}
      { pptDialogIsOpen && view ?
        (
          <CustomDownloadPptDialog
            view={view}
            viewId={viewId}
            isVisible={pptDialogIsOpen}
            handleDialogClose={() => setPptDialogIsOpen(false)}
          />
        ) : undefined}
    </React.Fragment>
  );
};
